import React, {useEffect, useState} from 'react'
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getProjectsForAudit, getTotalProjects, getProjectsForAutohold } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import handleErrorMessage from '../../../helper/handleErrorMessage'

const ProjectQmf = (props) => {
    const getProjectsForAuditApi = useApi(getProjectsForAutohold)

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getProjectsForAuditApi.request(skip,limit,sort, search)
    },[currentPage, sort, search])

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "20%",
            disableSortBy: true
        },
        // {
        //     Header: 'Calls Made / Database',
        //     Cell: (cell) => {
        //         return <span>{cell.row.original.callsMade} / {cell.row.original.callsDatabase} </span>
        //     },
        //     width: "12%",
        //     disableSortBy: true
        // },
        // {
        //     Header: 'Calls Connected',
        //     accessor: "callsConcluded",
        //     Cell: ({value}) => ( value || 0),
        //     width: "9%",
        //     disableSortBy: true
        // },
        // {
        //     Header: 'Call Audits',
        //     accessor: "callsInQC",
        //     Cell: ({value}) => ( value || 0),
        //     width: "9%",
        // },
        // {
        //     Header: 'Callers for IQC',
        //     accessor: "iqcholdCallers",
        //     Cell: ({value}) => ( value || 0),
        //     width: "9%",

        // },
        {
            Header: 'Callers in Autohold',
            accessor: "autoHoldCallers",
            Cell: (cell) => {
                return cell.row.original.autoHoldCallers || 0
            },
            width: "9%",
        },
        // {
        //     Header: 'Callers for QC',
        //     accessor: "flaggedCallers",
        //     Cell: (cell) => {
        //         return cell.row.original.flaggedCallers || 0;
            
        //     },
        //     width: "9%",
        // },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <div className={styles.blue_link} 
            onClick={() =>{
                props.history.push({
                    pathname: props && props.location && props.location.pathname == '/audit/qmf' ?
                    `${props.location.pathname}/audit-project/${cell.row.original.id}` :
                    `/caller-audit/${cell.row.original.id}`,
                    state: {
                        teleprojectAudit: cell.row.original
                    }
                })
            }}
            >
            VIEW
            </div>
            ),
            width: "7%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    let {data, total} = getProjectsForAuditApi.data || {}
    const {loading, error} = getProjectsForAuditApi

    useEffect(()=>{
        if(error)
        toast(handleErrorMessage(error))
    },[error])


    return(
        <div className={props && props.location && props.location.pathname == '/audit/qmf' ? styles.page_wrapper : ''}>
        {props && props.location && props.location.pathname == '/audit/qmf' && <Heading text={'Projects - QMF'}/>}
        <div>
            <Table
            columns={props && props.location && props.location.pathname == '/audit/qmf' ? columns.filter(({accessor}) => accessor !== 'autoHoldCallers') : columns}
            data={data}
            totalCount={total}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            sortChange={sortChange}
            filterChange = {filterChange}
            searchBy='project title'
            isGlobalFilter = {true}
            />
        </div>
        <ToastContainer draggable={true} />
        </div>
    )
}

export  default ProjectQmf