import React, {useState, useRef, useEffect} from "react";
import { UpArrow, DownArrow } from "../../assets/images";
// import './accordian.scss'
import styles from './panel.module.scss'

//  accordionitem component
const AccordionItem = ({ title, action, body, isOpen = false, onClick }) => {
    // <div className={i.isFatal ? [styles['param-name'], styles['fatal']].join(' ') : styles['param-name']}>{i && i.behaviour}
    //                                                             <CustomTooltip placement={'bottom'} description={i.description || 'NA'}
    //                                                             component={<img src={InfoIcon} alt={i.description || 'NA'} />} />
    //                                                             </div>

    const contentHeight = useRef();
    return (
      <div className={styles.wrapper}>
        <div
            className={isOpen ? [styles.question_container, styles.active].join('') : styles.question_container}
            // className={[styles.question_container, isOpen ? styles.active : ''].join('')}
          // onClick={onClick}
        >
          {/* <RiArrowDropDownLine
            className={`arrow ${isOpen ? "active" : ""}`}
            onClick={onClick}
          /> */}
          <div className={styles.title} onClick={onClick}>
          <img src={DownArrow} className={isOpen ? styles.arrow_active : styles.arrow}/>
          <div className={styles.question_content}>{title}</div>
          </div>
          <div className={styles.question_content}>{action}</div>
         {/* {action ? <div>{action}</div> : null} */}
        </div>
  
        <div
          ref={contentHeight}
          className={isOpen ? styles.answer_container : styles.hide_ans_container}
          // style={
          //   isOpen
          //     ? { height: contentHeight.current.scrollHeight }
          //     : { height: "0px" }
          // }
        >
          <div className={styles.answer_content}>{body}</div>
        </div>
      </div>
    );
  };

const Accordion = ({title, body, action, id, data = ''}) => {
    // const [activeIndex, setActiveIndex] = useState(null);
    const [active, setActive] = useState(id == 0 ? true : false)
    useEffect(()=>{
      if(data && data.isExpand){
        setActive(true)
      }
    },[data && data.isExpand])

    const handleItemClick = (e) => {
      if(!['INPUT'].includes(e.target.tagName)){ //do not toggle the panel on click of the input element
        setActive(!active)
      }
        // setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div className={styles.accordion} key={id}>
            <AccordionItem
            id={id}
            title={title}
            body={body}
            action={action}
            // isOpen={activeIndex === index}
            isOpen={active}
            onClick={(e) => handleItemClick(e)}
            />
        </div>
    )
}

export default React.memo(Accordion)