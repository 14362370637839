import React, { useEffect } from 'react'
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import { getCallerProfile } from './service'
import useApi from '../../../hooks/useApi'

const CallerPreference = (props) => {
    const { rowData, callerPreference } = props
    const { 
        certifiedOn, 
        languages, 
        workPreferredSlots, 
        workPreferredDays 
    } = rowData

    const getCallerProfileApi = useApi(getCallerProfile)

    useEffect(()=>{
        const payload = {
            user: rowData.id
        }
        if(callerPreference){
            getCallerProfileApi.request(payload)
        }
    },[])

    let additionalData = {
        certified: certifiedOn,
        languages: languages && languages.map(({language}) => language),
        shift_time: workPreferredSlots,
        preferred_workdays: workPreferredDays
    }

    const capitalize = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join("");

    const modifyKey = (key) => {
        return capitalize(key.split('_').join(' '))
    }

    const getKeyValue = (key, value) => {
        switch (key) {
            case 'certified':
                return value ? moment(value).fromNow() : 'NA'
            case 'languages':
                return value ? String(value).replace(/,/g, ', ') : 'NA'
            case 'shift_time':
                return value ? String(value).replace(/,/g, ', ') : 'NA'
            case 'preferred_workdays':
                return value ? String(value).replace(/,/g, ', ') : 'NA'
            default:
                return value
        }
    }

    if(getCallerProfileApi.data){
        additionalData = {
            ...additionalData,
            languages: getCallerProfileApi.data.languages &&
            getCallerProfileApi.data.languages.map(({language}) => language),
            shift_time: getCallerProfileApi.data.workPreferredSlots,
            preferred_workdays: getCallerProfileApi.data.workPreferredDays
        }
    }
    return(
        <div className={[styles['caller-info-wrapper'], styles['caller-prefer-wrapper']].join(' ')}>
            {
                additionalData && Object.entries(additionalData).map(([key,value]) => {
                    return(
                    <div className={styles['tb-row']}>
                    <div>{modifyKey(key)}:</div>
                    <div>{getKeyValue(key, value)}</div>
                    </div>
                    )
                })
            }
        </div>
    ) 
}

export default CallerPreference