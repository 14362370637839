import { useState } from "react";

export default (apiFunc, extraParam = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const request = async (...args) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      if(extraParam){
        const transformData = extraParam(result)
        setData(transformData);
      }else{
        setData(result)
      }
    } catch (err) {
      if(err.response){
        setError(err.response && err.response.data || "Unexpected Error!")
      }else
      setError(err.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setData(null)
    setError("")
    setLoading(false)
  }

  return {
    data,
    error,
    loading,
    request,
    reset,
    setData,
    setLoading
  };
};