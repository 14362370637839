import React, { Component } from 'react';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FrappButton from '../../utilities/FrappButton';
import FrappModal from '../../utilities/FrappModal';
import constants from '../../../util/constants';
import { diff } from 'json-diff';
import JsonView from '../../utilities/JsonView'
import fromNow from 'moment-from-now';
import { DateRangePicker } from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';

import ApiActions from "./../../../actions/ApiActions"
class DraftTracking extends Component {
    state = {
        isExpanded: false,
        applicationEndDateFocused: false,
        endDate: new Date().toISOString(),
        startDate: new Date(new Date().getTime() - ((24 * 60 * 60 * 1000) * 30)),
        loading: false,
        panelText: "Last 30 days"
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        const { startDate, endDate } = this.state;
        this.submitBtn.startLoading();
        this.setState({ loading: true })
        ApiActions.getMissionBuffer({
            where: {
                "paymentDone": { "$in": [null, false] },
                "createdAt": { "$gte": startDate, "$lt": endDate },
                "payWall": true
            },
            sort: {
                "createdAt": -1
            },
            populate: ['brand', 'brandmanager']
        }).then(missionBuffer => {
            this.setState({ missionBuffer, loading: false })
            this.submitBtn.stopLoading();
        }).catch((err) => {
            this.setState({ loading: false })
            alert("something went wrong! try again")
            this.submitBtn.stopLoading();
        })
    }
    render() {
        let { missionBuffer, loading } = this.state;
        return (
            <div className="moderation no-padding" >
                <div className="container-fluid" >
                    {this.renderFilter()}
                    <PanelGroup id="moderation-list" className={loading ? 'deem' : ''}>
                        {missionBuffer && this.renderPanel({ label: 'Draft tracker', list: missionBuffer })}
                    </PanelGroup>
                    {this.renderModerationModal()}
                </div>
            </div>
        );
    }
    renderFilter = () => {
        let { startDate, endDate } = this.state;
        return (
            <div className="filter">
                <ControlLabel>Filter Results</ControlLabel>
                <div className="filter-container">
                    <DateRangePicker
                        startDateId="start-date"
                        endDateId="end-date"
                        focusedInput={this.state.dateRangeFocused}
                        onFocusChange={focused => { this.setState({ dateRangeFocused: focused }) }}
                        onDatesChange={this.setCampaignDates}
                        startDate={startDate ? moment(startDate) : null}
                        endDate={endDate ? moment(endDate) : null}
                        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat="DD MMM YYYY"
                    />
                    <FrappButton className="submit" handler={this.getFilteredData} ref={(c) => this.submitBtn = c}>Submit</FrappButton>
                </div>
            </div>
        )
    }

    getFilteredData = () => {
        this.fetchData()
    }
    setCampaignDates = (dates) => {
        let { startDate, endDate } = dates;
        this.setState({ startDate: startDate ? new Date(startDate).toISOString() : null, endDate: endDate ? new Date(endDate).toISOString() : null })
    }

    renderPanel = (data) => {
        var formattedList = this.formatList(data);
        var { label, list } = data;
        var isExpanded = this.state[`${label}Expanded`];

        return (
            <Panel expanded={isExpanded} onToggle={() => this.setState({ [`${label}Expanded`]: !isExpanded })} eventKey={label} >
                <Panel.Heading onClick={this.clickHeading} >
                    <Panel.Title toggle>{label} ({list.length})</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible >
                    {
                        formattedList.length === 0 ? (
                            <div className="empty-review" >
                                <span>there is nothing here</span>
                                <span role="img" aria-label="party" >😞</span>
                            </div>
                        ) :
                            <div className="list-wrapper" >
                                {formattedList.map(this.renderDataCard)}
                            </div>
                    }
                </Panel.Body>
            </Panel>
        )

    }
    clickHeading = (e) => {
        var target = e.target;

        var a = target.querySelector('a');
        if (a) {
            a.click();
        }
        e.stopPropagation();
    }

    setActiveModeration = (data, entity) => {
        this.setState({ activeModeration: data, activeModerationEntity: entity })
        this.moderationModal.toggle(true);
    }

    renderDataCard = (data, i) => {
        var { title, subTitle, logo, dependency, entityData, entity } = data;

        return (
            <div key={entityData.id} onClick={() => { this.setActiveModeration(entityData, entity) }} className={`data-card ${dependency ? 'locked' : ''}`} >
                <div className="logo-wrapper" >
                    <img src={logo} alt="" />
                </div>
                <div className="text-wrapper">
                    <div className="title ellipsis" >
                        {title}
                    </div>
                    <div className="sub-title ellipsis" >
                        {subTitle}
                    </div>
                    {data.isDummy && (<div className="dummy-tag" >Dummy Mission</div>)}
                    {this.renderStat(data)}
                </div>
                {
                    dependency ?
                        <div className="overlay" >
                            <span role="img" aria-label="woman no gesture" >🙅‍♀️</span>
                        </div> : null
                }
            </div>

        )
    }

    renderStat = (data) => {
        var { timeElapsed } = data;


        return (
            <div className="stat time-elapsed" >
                <span role="img" aria-label="time elapsed" >⏰</span>
                <span>{timeElapsed}</span>
            </div>
        )
    }
    getEditDiff = () => {
        var { activeModeration, internship } = this.state;
        var diffToCheck = diff(activeModeration, internship);
        let diffKeys = Object.keys(diffToCheck);
        diffKeys = diffKeys.filter(key => key.indexOf("added") === (-1) && key.indexOf("deleted") === (-1) && key.indexOf("updatedAt") === (-1))

        return diffKeys;
    }

    renderModerationModal = () => {
        var { activeModeration, activeModerationEntity, approveHandler, rejectHandler, showRejectFeedback } = this.state;

        return (
            <FrappModal ref={(c) => this.moderationModal = c} className="moderation-modal" >
                {activeModerationEntity && <h1>{window.UTIL.capitalize(activeModerationEntity.replace('buffer', ''))} Detail</h1>}
                {
                    constants.fieldsMetaInfo[activeModerationEntity] &&
                    <div className="detail-wrapper" >
                        <JsonView highlighted={this.getEditDiff()} data={activeModeration} fieldsMetaInfo={constants.fieldsMetaInfo[activeModerationEntity]} />
                    </div>
                }
            </FrappModal>
        )
    }


    checkMissionDummy = (item) => {
        let cond1 = item.description.indexOf("create a buzz") >= 0;
        let cond2 = item.name.indexOf('Promoters') >= 0;
        let cond3 = Number(item.maxSelected) === 25;
        let cond4 = Number(item.maxApplied) === 30;

        return cond1 && cond2 && cond3 && cond4
    }

    formatList = (data) => {
        var { label, list } = data;

        var formattedList = [];

        switch (label) {
            case 'Draft tracker':
                formattedList = list.map(item => {
                    return {
                        title: item.name,
                        subTitle: item.brand && item.brand.name,
                        logo: item.brand && item.brand.asseturl,
                        entity: 'missionbuffer',
                        isDummy: this.checkMissionDummy(item)
                    }
                })
                break;

            case 'Brands':
                formattedList = list.map(item => {
                    var { internshipBuffer } = this.state;
                    if (!internshipBuffer) {
                        return {}
                    }

                    let dependency = internshipBuffer.filter(i => i.brand.id === item.id).length > 0;

                    return {
                        title: item.name,
                        subTitle: item.brandmanager && item.brandmanager.firstname,
                        logo: item.asseturl,
                        dependency,
                        entity: 'brandbuffer',
                    }

                })
                break;

            case 'Internships':
                formattedList = list.map(item => {

                    return {
                        title: item.title,
                        subTitle: item.brand.name,
                        logo: item.brand.asseturl,
                        entity: 'internshipbuffer',
                    }
                })
                break;

            case 'Brand Associations':
                formattedList = list.map(item => {
                    var { brandbuffer, brandmanager } = item;
                    return {
                        title: brandbuffer.name,
                        subTitle: brandmanager.firstname + " " + brandmanager.lastname,
                        logo: brandbuffer.asseturl,
                        entity: 'brandassociate',
                    }
                })
                break;

            default:
                formattedList = [];
        }

        list.map((item, i) => {
            formattedList[i].id = item.id;
            formattedList[i].createdAt = item.createdAt;
            formattedList[i].updatedAt = item.updatedAt;
            formattedList[i].updatedAt = item.updatedAt;
            formattedList[i].entityData = item;

            if (item.brand) {
                formattedList[i].dependency = item.brand.moderation;
            }

            var timeToPass = item.createdAt !== item.updatedAt ? item.updatedAt : item.createdAt;
            formattedList[i].timeElapsed = fromNow(timeToPass);

            return null
        })

        return formattedList;
    }

}

export default DraftTracking;