import React, { useState } from "react";
//custom imports
import styles from './forminput.module.scss';
import useForm from "../../hooks/useForm";
import { QuestionMarkCircle } from "../../assets/images";
import CustomTooltip from "../../components/utilities/ToolTip";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, type = "text", ...inputProps } = props;

  //Custom hook call
  const { handleChange, values, errors, handleSubmit } = useForm();

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className={styles['formInput']}>
      <label>{label} {props.helperIcon ?
        <CustomTooltip component={<img src={QuestionMarkCircle} className={styles.helperIcon} />} placement={'right'}
          description={props.helperText} />
        : null}</label>
      {
        type == 'textarea' ?
          <textarea rows="3"
            {...inputProps}
            onChange={onChange}
          />
          :
          <input
            {...inputProps}
            onChange={onChange}
            // onBlur={(e) => {
            //   handleChange(e)
            //   handleFocus()
            // }}
            // onFocus={() => {
            //   inputProps.name === "confirmPassword" && 
            //   setFocused(true)
            //   }
            // }
            focused={focused.toString()}
          />
      }
      {errorMessage ? <div className={styles['errorMsg']}>{errorMessage}</div> : null}
      {/* <div>{errors[inputProps.name]}</div> */}
    </div>
  );
};

export default FormInput;