import React, { useState, useEffect } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import Table from '../../../uicomponents/table/Table'
import Heading from '../../../uicomponents/heading'
import Card from '../../../uicomponents/card'
import useApi from '../../../hooks/useApi'
import { raisePayment, checkPaymentStatus, getDuePayments } from './service'
import moment from 'moment'
import aggregateCount from './aggregateCount'
import ProjectTitle from '../../../components/common/ProjectTitle'
import FutworkButton from '../../../uicomponents/button/Button'
import AlertDialog from './alertDialog'
import Popup from '../../../uicomponents/popup'
import Tooltip from './tooltip'

const Payout = (props) => {
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [rowSelected, setRowSelected] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [isPaymentPending, setIsPaymentPending] = useState(false)
    const [requestTriggered, setRequestTriggered] = useState(false)
    const [paymentReqResult, setPaymentReqResult] = useState('')
    const getDuePaymentsApi = useApi(getDuePayments)

    useEffect(()=>{
        getDuePaymentsApi.request(props.location && props.location.state &&
            props.location.state.dateRange && props.location.state.dateRange.from, 
            props.location && props.location.state &&
            props.location.state.dateRange && props.location.state.dateRange.to)
    },[])

    const updateSelection = (rows = []) => {
        setRowSelected(rows)
    }

    const checkIfCallersSelected = () => {
        if(rowSelected && rowSelected.length > 0){
            return true
        }
        setShowAlert(true)
        return false
    }

    const checkIfPaymentAlreadyRaised = async() => {
        try{
            let result = await checkPaymentStatus()
            if(result && result.length > 0){
                setIsPaymentPending(true)
            }else{
                raisePaymentReq()
            }
        }catch (error) {
            let msg = ''
            if (error.response)
                msg = error.response && error.response.data || "Unexpected Error!"
            else
                msg = error.message || "Unexpected Error!"
        }
    }

    const raisePaymentReq = async () => {
        const { from, to } = props.location && props.location.state && props.location.state.dateRange
        setRequestTriggered(true)
        const payload = {
            from : from ? from : '',
            to: to ? to : '',
            callers: rowSelected && rowSelected.map(row => row._id)
        }
        try {
            let result = await raisePayment(payload)
            if(result.status == 200){
                props.history.push({
                    pathname: `/caller/payout/confirm`,
                    state:{
                        dateRange: props.location.state.dateRange,
                        paymentId: result.data && result.data.id,
                        totalamount: aggregateCount(rowSelected, 'totalEarnings'),
                        paymentDueToTotalCaller: data ? data.length : 0,
                        paymentDueToSelectedCaller: rowSelected ? rowSelected.length : 0,
                        callers: result.data && result.data.callers
                    }
                })
            }
        } catch (error) {
            let msg = ''
            if (error.response)
                msg = error.response && error.response.data || "Unexpected Error!"
            else
                msg = error.message || "Unexpected Error!"
                setPaymentReqResult(msg.error ? msg.error : msg)
                setShowAlert(true)
        }finally{
            setRequestTriggered(false)
        }
        setRequestTriggered(false)
    }

    const renderAlert = () => {
        const props = {
            title: paymentReqResult ? paymentReqResult : 'Please select the callers to raise payment request',
            cta: 'Okay',
            error: true,
            onClick: () => {
                setShowAlert(false)
            }
        }
        return <Popup
        show={showAlert}
        closePopup={()=>{
            setShowAlert(false)
        }}
        size='sm'
        heading={''}
         >
            <AlertDialog {...props}/>
        </Popup>
    }

    const renderPaymentCheck = () => {
        const ActionBtns = () => {
            return <div style={{display: 'flex', gap: '2em'}}>
                <FutworkButton buttonStyle={'primary--solid non-rounded'} buttonSize={'sm'}
                onClick={()=> {
                    setIsPaymentPending(false)
                    raisePaymentReq()
                }}>Yes</FutworkButton>

                <FutworkButton buttonStyle={'primary--solid non-rounded'} buttonSize={'sm'}
                onClick={()=> setIsPaymentPending(false)}>No</FutworkButton>

            </div>
        }

        const props = {
            title: 'The payment request has already been raised, do you still want to raise the request again',
            error: true
        }

        return <Popup
        show={isPaymentPending}
        closePopup={()=>{
            setIsPaymentPending(false)
        }}
        size='sm'
        heading={''}
        popupContent={<AlertDialog {...props}/>}
        popupFooter={<ActionBtns />}
        footerAlign='center'
         />
    }
    const handlePopups = () => {
        if(showAlert) return renderAlert()
        if(isPaymentPending) return renderPaymentCheck()
        else
        return null
    }

    const columns = [
        {
            Header: "Caller",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            width: '25%',
            disableSortBy: true
        },
        {
            Header: 'Phone',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '20%',
            disableSortBy: true
        },
        {
            Header: Tooltip('Base pay'),
            accessor: "dailyEarning",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true,
            width: '10%'
        },
        {
            Header: Tooltip('Present count'),
            accessor: "presentCount",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '12%'
        },
        {
            Header: Tooltip('Earning due'),
            accessor: "totalEarnings",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true,
            width: '10%'
        },
    ]

    let {data, loading, error} = getDuePaymentsApi || {}

    return(
        <div className={styles.payout_page_wrapper}>
             <ProjectTitle title={`Payouts for ${props.location && props.location.state && props.location.state.dateRange && moment(props.location.state.dateRange.from).format("DD-MM-YYYY")}
            to ${props.location && props.location.state && props.location.state.dateRange && moment(props.location.state.dateRange.to).format("DD-MM-YYYY")}`}
                    goBack={() => props.history.push({
                        pathname: `/caller/attendance-tracker`
            })} />
            {/* <Heading text={`Payouts for ${props.location && props.location.state && props.location.state.dateRange && moment(props.location.state.dateRange.from).format("DD-MM-YYYY")}
            to ${props.location && props.location.state && props.location.state.dateRange && moment(props.location.state.dateRange.to).format("DD-MM-YYYY")}`} /> */}

            <div className={styles.card_stats_row}>
            <Card color='voilet' type='custom' info={{title: 'Total callers', value: data ? data.length : 0}}/>
            <Card color='sky-blue' type='custom' info={{title: 'Total callers selected', value: rowSelected ? rowSelected.length : 0}}/>
            <Card color='peach' type='custom' info={{title: 'Total callers skipped', value: rowSelected && rowSelected.length > 0 && data && data.length > 0 
            ? data.length - rowSelected.length: 0}}/>
            <Card color='aqua-green' type='custom' info={{title: 'Total amount', value: aggregateCount(rowSelected, 'totalEarnings')}}/>
            </div>

            <Table
            columns={columns}
            data={data}
            loading={loading}
            totalCount={data ? data.length : 0}
            limit={limit}
            type = 'client'
            selectType='multi'
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            error={error}
            isGlobalFilter = {true}
            selectRow = {true}
            updateRowSelect={updateSelection}
            />

            <div className={styles.payout_footer}>
            <FutworkButton buttonStyle={'primary--solid non-rounded'} buttonSize={'sm'}
            disabled={requestTriggered}
            onClick={()=> {
                if(checkIfCallersSelected()){
                    checkIfPaymentAlreadyRaised()
                    //     raisePaymentReq()
                }
            }}>Raise payment request</FutworkButton>
            </div>
            {handlePopups()}
        </div>
    )
}

export default Payout