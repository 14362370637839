import React from 'react';
import ApiActions from '../../actions/ApiActions';

import Moderation from '../pages/Moderation';

class ModerationContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidUpdate(){
        var {brandBuffer,internshipBuffer,missionBuffer,brandAssociation,dataLoaded} = this.state;
        if(brandBuffer && internshipBuffer && missionBuffer && brandAssociation && !dataLoaded){
            this.setState({dataLoaded:true})
        }
    }
    
    componentDidMount(){
        this.fetchData();
    }

    listenToDB = () => {
        var minutes = (1000 * 60) * 5;
        setInterval(() => {

            Promise.all([
                ApiActions.getMissionBuffer({moderation:true,count:true}),
                ApiActions.getInternshipBuffer({moderation:true,count:true}),
                ApiActions.getBrandAssociation({count:true}),        
                ApiActions.getBrandBuffer({moderation:true,count:true})
            ])
            .then(this.checkNewDataAvailable)

        },minutes)
    }
    
    checkNewDataAvailable = (resp) => {
        var [missionCount,internshipCount,brandAssociationCount,brandCount] = resp;

        var {brandBuffer,internshipBuffer,missionBuffer,brandAssociation} = this.state;
        
        if(internshipBuffer.length !== internshipCount){
            this.displayNotification("New Internship came for moderation")
        }
        if(brandBuffer.length !== brandCount){
            this.displayNotification("New Brand came for moderation")
        }
        if(brandAssociation.length !== brandAssociationCount){
            this.displayNotification("New brand association came for moderation")
        }
        if(missionBuffer.length !== missionCount){
            this.displayNotification("New mission came for moderation")
        }
    }
    
    displayNotification = (title) => {
        Notification.requestPermission((status) => {
            if(status === 'granted'){
                var n = new Notification(title, { 
                    body: 'You have some moderation work out there!'
                }); 

                n.onclick = () => {
                    window.location.reload();
                    window.focus()
                }
            }else{
                alert("Allow us to send notifications!")
            }
        })

    }

    fetchData = () => {
        ApiActions.getMissionBuffer({moderation:true,moderationRequested:true,populate:['brand','brandmanager']}).then(missionBuffer => this.updateState({missionBuffer}))
        ApiActions.getInternshipBuffer({moderation:true,moderationRequested:true,populate:['brand','brandmanager'],limit:100}).then(internshipBuffer => this.updateState({internshipBuffer}))
        ApiActions.getBrandAssociation({populate:['brandbuffer','brandmanager']}).then(brandAssociation => this.updateState({brandAssociation}))        
        this.fetchBrandBuffer()
    }
    
    fetchBrandBuffer = () => {
        ApiActions.getBrandBuffer({moderationRequested:true})
        .then(this.fetchAndUpdateBrandManager)
        .then(brandBuffer => this.updateState({brandBuffer}))
        
    }

    fetchAndUpdateBrandManager = (brandBuffer) => {
        return new Promise((resolve,reject) => {
            Promise.all(
                brandBuffer.map(b => {
                    let brand = b.id;
                    return ApiActions.getBrandManager({brand})
                })
            )
            .then(brandManagersOfAllBrands => {
                brandBuffer = brandBuffer.map((b,i) => {
                    b.brandmanager = brandManagersOfAllBrands[i][0];
                    return b
                })
                
                resolve(brandBuffer)
            })
        
        })
    }

    updateState(state){
        this.setState(state)
    }

    render(){
        return <Moderation {...this.state} {...this.props} />
    }
}

export default ModerationContainer;