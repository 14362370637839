import React, { useState } from 'react'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import FormControl from 'react-bootstrap/lib/FormControl'
import FrappButton from '../utilities/FrappButton'
import ApiActions from '../../actions/ApiActions'
import { ToastContainer, toast } from "react-toastify";

function ChangePassword(props) {
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [passwordValidationStatus, setPasswordValidationStatus] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialchar: false
  })

  const { location } = props
  const { search } = location

  if (token.length <= 0 && search && search.indexOf('token') >= 0) {
    const tempToken = search.replace('?token=', '')

    if (tempToken) {
      setToken(tempToken)
    } else {
      props.history.push('/')
    }
  } else if (token.length <= 0) {
    props.history.push('/')
  }


  const validatePassword = (e) => {
    e.preventDefault()
    const pass = e.target.value
    setPassword(pass)

    const passwordValidationStatus = {}

    // Check length
    passwordValidationStatus.length = pass.length >= 10

    // Check for uppercase letter
    passwordValidationStatus.uppercase = /[A-Z]/.test(pass)

    // Check for lowercase letter
    passwordValidationStatus.lowercase = /[a-z]/.test(pass)

    // Check for digit
    passwordValidationStatus.digit = /\d/.test(pass)

    // Check for special characters
    passwordValidationStatus.specialchar = /[!@#$%^&*()_+}{?]/.test(pass)

    setPasswordValidationStatus(passwordValidationStatus)

  }

  const submitChangePassword = (e) => {
    e.preventDefault()
    const base64Password =  Buffer.from(password, 'binary').toString('base64')

    ApiActions.changePasword({ token, password: base64Password })
    .then((resp) => {
      props.history.push('/')
      toast.success('Your password has been updated successfully')
    })
    .catch((err) => {
      console.error(err.response)
      let message = 'An error occurred while updating your password.'
      if (err.response && err.response.data && err.response.data.error) {
        message = err.response.data.error
      }
      toast.error(message)
    })
  }

  return (
    <div className="change-password">
      <img src={require("../../assets/images/futworklogo.png")} alt="" className="logo" />
      <h1>Change Password</h1>
      <h3>
        Try not to forget next time!
        <span role="img" aria-label="smiley">
          😉
        </span>
      </h3>
      <form>
        <FormGroup>
          <ControlLabel>Enter a new password</ControlLabel>
          <FormControl
            type="password"
            onChange={(e) => validatePassword(e)}
            value={password || ''}
          />
          <div className={passwordValidationStatus.length ? 'valid' : 'invalid'}>Password must be at least 10 characters long.</div>
          <div className={passwordValidationStatus.uppercase ? 'valid' : 'invalid'}>Password must contain at least one uppercase letter (e.g A-Z)</div>
          <div className={passwordValidationStatus.lowercase ? 'valid' : 'invalid'}>Password must contain at least one lowercase letter (e.g a-z)</div>
          <div className={passwordValidationStatus.digit ? 'valid' : 'invalid'}>Password must contain at least one digit (e.g 0-9)</div>
          <div className={passwordValidationStatus.specialchar ? 'valid' : 'invalid'}>Password must contain at least special character (e.g !@#$%^&*{`()`}_+{`}{`}?)</div>
        </FormGroup>
        <FrappButton className="submit" id="new-btn" type="submit" disabled={!Object.values(passwordValidationStatus).every((value) => value === true)}  handler={(e) => submitChangePassword(e)}>Reset Password</FrappButton>
      </form>
      <ToastContainer draggable={true} />
    </div>
  )
}

export default ChangePassword
