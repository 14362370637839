import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import FormGroup from "react-bootstrap/lib/FormGroup";
import FrappButton from '../../utilities/FrappButton'
import FormControl from 'react-bootstrap/lib/FormControl';
import { ISContext } from './ISContext'
import ApiActions from '../../../actions/ApiActions';
import Dropdown from 'react-dropdown';
import cloneDeep from "lodash/cloneDeep";
import checkIsEmpty from '../../../helper/checkIsEmpty'
import AlwaysScrollToBottom from '../../common/AlwaysScrollToBottom'
import CustomTooltip from '../../utilities/ToolTip'
import Autocomplete from '../../utilities/Autocomplete'


const dummySMSTriggerAction = {
    title: "",
    api: "",
    tag: '',
    variables: [
        {
            name: '',
            value: ''
        }
    ]
}

const TriggerActionForm = (props) => {
    const { currentNodeData, setCurrentNodeData, allScripts, setAllScripts, setElements, setElements1, setAllScripts2 } = useContext(ISContext)
    const getScriptsData = props.formData
    const singleScriptData = getScriptsData[0]
    const actionsData = singleScriptData.nodeData.data.actions &&
        singleScriptData.nodeData.data.actions.length > 0 ?
        singleScriptData.nodeData.data.actions.map(act => {
            return {
                title: act.title,
                api: act.api,
                variables: Object.entries(act.bodyParams).map(([key, value1]) => {
                    return {
                        name: key,
                        value: value1
                    }
                }),
                tag: act.tag
            }
        })
        :
        [dummySMSTriggerAction]
    const [actions, setActions] = useState(actionsData)
    const [isError, setError] = useState('')
    const [isSuccess, setSuccess] = useState('')
    const [selectAction, setSelectedAction] = useState('')
    const [communType, setCommunType] = useState('SMS')
    const [checkIfLatest, setCheckIfLatest] = useState(false)

    useEffect(() => {
        if (singleScriptData.nodeData.data.actions && singleScriptData.nodeData.data.actions.length > 0) {
            setSelectedAction(singleScriptData)
        } else {
            setSelectedAction(singleScriptData)
        }
        setCheckIfLatest(true)
    }, [])

    const resetMessage = () => {
        if (isError) {
            setError('')
        }
        if (isSuccess) {
            setSuccess('')
        }
    }

    const toggle = (e) => {
        resetMessage()
        setSelectedAction(e)
        let r = getScriptsData.find(ee => ee.name == e.name)
        const actionsData = r.nodeData.data.actions &&
            r.nodeData.data.actions.length > 0 ?
            r.nodeData.data.actions : [dummySMSTriggerAction]
        const formattedActionsData = actionsData.map(act => {
            return {
                title: act.title,
                api: act.api,
                tag: act.tag,
                variables: act.variables ?
                    act.variables
                    :
                    Object.entries(act.bodyParams).map(([key, value1]) => {
                        return {
                            name: key,
                            value: value1
                        }
                    })

            }
        })
        setActions(formattedActionsData)
    }

    const renderActionUI = () => {
        return (
            <div className="outcome-wrapper">
                <div className="feedback-form">
                    {
                        actions.length > 0 ? (
                            renderTriggerActionForm(actions)
                        ) : null
                    }
                </div>
            </div>
        );
    };

    const triggerActionVal = () => {
        const isEmpty = actions.some(obj => {
            if (!obj.title || !obj.api || !obj.tag) {
                return true
            }
            const checkVariablesEmpty = obj.variables.some(it => {
                return checkIsEmpty(it)
            })
            return checkVariablesEmpty
        })
        return isEmpty
    }


    const addTriggerAction = () => {
        setCheckIfLatest(true)
        resetMessage()
        let newTriggerActionCount = [...actions, dummySMSTriggerAction]
        setActions(newTriggerActionCount)
    };

    const removeTriggerAction = (index) => {
        let copyTriggerActions = [...actions]
        const indexToRemoved = actions.findIndex((i, idx) => idx == index)
        copyTriggerActions.splice(indexToRemoved, 1)
        setActions(copyTriggerActions)
    }

    const addNewVariables = (index) => {
        setCheckIfLatest(false)
        const copyTriggerActions = actions.map((item, idx) => {
            if (idx == index) {
                let { variables } = item
                let newKey = `flowId${variables.length + 1}`
                return {
                    ...item,
                    variables: [...variables, { name: '', value: '' }]
                }
            }
            return item
        })
        setActions(copyTriggerActions)
    }

    const removeVariables = (index, varIndex) => {
        const copyTriggerActions = actions.map((item, idx) => {
            if (idx == index) {
                let { variables } = item
                const updatedVariables = variables.filter((item, id) => {
                    if (id !== varIndex)
                        return item
                })
                return {
                    ...item,
                    variables: updatedVariables
                }
            }
            return item
        })
        setActions(copyTriggerActions)
    }

    const removeTags = (index) => {
        setCheckIfLatest(false)
        const copyTriggerActions = cloneDeep(actions)
        copyTriggerActions.map((action, idx) => {
            if(idx == index){
                delete action.tag
                return action
            }
            return action
        })
        setActions(copyTriggerActions)
    }

    const updateActionData = (key, value, index, varIndex) => {
        setCheckIfLatest(false)
        const copyTriggerActions = cloneDeep(actions)
        if (key == 'name' || key == 'value') {
            let obj = copyTriggerActions[index]
            obj = {
                ...copyTriggerActions[index],
            }
            if (key == 'name') {
                obj.variables[varIndex].name = value
            }
            else {
                obj.variables[varIndex].value = value
            }
            copyTriggerActions[index] = obj
            setActions(copyTriggerActions)
        } else {
            let obj = copyTriggerActions[index]
            obj = {
                ...copyTriggerActions[index],
                [key]: value
            }
            copyTriggerActions[index] = obj
            setActions(copyTriggerActions)
        }
    }

    const addTags = (key,event, index) => {
        setCheckIfLatest(false)
        if (event.key === "Enter" && event.target.value !== "") {
            const copyTriggerActions = cloneDeep(actions)
            let obj = copyTriggerActions[index]
            obj = {
                ...copyTriggerActions[index],
                [key]: event.target.value
            }
            copyTriggerActions[index] = obj
            setActions(copyTriggerActions)
            event.target.value = "";
        }
    };

    const renderTags = (action, idx) => {
        return <div className="pill-wrapper">
        <div className="pill">{action.tag}
        <div className="del-icon">
            <img src={require("../../../assets/images/del-icon-taskprops.svg")} onClick={()=> removeTags(idx)} /></div>
        </div>
        </div>
    }

    const renderSmsForm = (actions1) => {
        return <Fragment>
            {actions1 &&
                actions1.map((action, idx) => {
                    return (
                        <div className='grey-box-wrapper' key={idx}>
                            <div className='text-content'>
                                <div className='form-data'>
                                    <FormGroup>
                                        <div className='group-label-input'>
                                        <span className='label-bold'>Title</span>
                                        <FormControl
                                            className="qs-title"
                                            name="question"
                                            value={action.title}
                                            onChange={(e) => updateActionData('title', e.target.value, idx)}
                                            placeholder="Enter title"
                                        />
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <div className='group-label-input'>
                                        <span className='label-bold'>Url
                                        {/* <CustomTooltip placement={'right'} description={'Paste the api for eg: https://us-central1-frapp-prod.cloudfunctions.net/gcf-meesho/sendsms'}
                                                component={<img src={require("../../../assets/images/black-info-icon.svg")} 
                                                style={{ width: '12px', marginLeft: '4px', marginBottom: '2px' }} />}>
                                        </CustomTooltip> */}
                                        </span>
                                        <FormControl
                                            className="qs-title"
                                            name="question"
                                            value={action.api}
                                            onChange={(e) => updateActionData('api', e.target.value, idx)}
                                            placeholder="Paste url"
                                        />
                                        </div>
                                       
                                    </FormGroup>

                                    {/* <div> */}
                                    <div className='group-label-input'>
                                    <span className='label-bold'>Tag</span>
                                    <div className='tags-section'>
                                   {!action.tag ? 
                                        <FormControl
                                        placeholder="Press enter to add a tag"
                                        name="assign-tag"
                                        type="text"
                                        onKeyUp={event => addTags('tag', event, idx)}
                                        style={{width: '30%'}}
                                    /> : null}
                                    {action.tag ? renderTags(action, idx) : null}
                                    </div>
                                    </div>
                                  
                                    {/* </div> */}

                                    <div className='name-value-section'>
                                        <div className='dotted-border bg-white'>
                                            <div className='contain'>
                                                {
                                                    action.variables.map(
                                                        (item, varIndex) => {
                                                            return <div className='name-value-row' key={varIndex}>
                                                                <div className='label-value'>
                                                                    <div className='label-bold'>{varIndex + 1}. Key</div>
                                                                    <FormControl
                                                                        className="qs-title"
                                                                        name="name"
                                                                        value={item.name}
                                                                        onChange={(e) => updateActionData('name', e.target.value, idx, varIndex)}
                                                                        placeholder="Enter name"
                                                                    />
                                                                </div>
                                                                <div className='label-value w-70'>
                                                                    <span className='label-bold w-10'>Value</span>
                                                                    <div style={{width: '100%'}}>
                                                                    <Autocomplete
                                                                     suggestions={props.taskProps.map(each => each.name)}
                                                                     value = {item.value}
                                                                     updateData = {(e) => updateActionData('value', e, idx, varIndex)}
                                                                     placeholder = {"Enter value"}
                                                                    />
                                                                    </div>
                                                                </div>
                                                                <span>
                                                                    <img src={require('../../../assets/images/del-icon-taskprops.svg')}
                                                                        className='delete-icon'
                                                                        onClick={() => removeVariables(idx, varIndex)} />
                                                                </span>
                                                            </div>
                                                        })
                                                }
                                            </div>
                                            <FrappButton
                                                className="dc-add-question-btn"
                                                handler={() => addNewVariables(idx)}
                                            >+ ADD VARIABLE </FrappButton>
                                            <CustomTooltip placement={'right'} description={`Variable means a combination of name and its corresponsing value, value are always id's`}
                                                component={<img src={require("../../../assets/images/black-info-icon.svg")} style={{ width: '12px', marginLeft: '4px', marginBottom: '2px' }} />}>
                                            </CustomTooltip>
                                        </div>
                                    </div>

                                </div>
                                {idx == 0 ? null : <img src={require('../../../assets/images/del-icon-taskprops.svg')}
                                    className='delete-icon'
                                    onClick={() => removeTriggerAction(idx)}
                                />}
                            </div>
                        </div>
                    );
                })
            }
            <span className='add-cta flex-end' onClick={() => addTriggerAction()}>+ADD MORE</span>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', gap: '10px' }}>
                {isError ? <span style={{ color: 'red', fontWeight: '600' }}>{isError}</span> : null}
                {isSuccess ? <span style={{ fontWeight: '600' }}>{isSuccess}</span> : null}
                <FrappButton
                    className="submit"
                    type="submit"
                    id="new-btn"
                    disabled={triggerActionVal()}
                    handler={(e) => submitForm(e)}
                >
                    Save
            </FrappButton>
            </div>
        </Fragment>
    }
    const renderTriggerActionForm = (actions1) => {
        return (
            <div style={{ marginTop: '2%' }}>
                {renderSmsForm(actions1)}
            </div>
        );
    };

    const submitForm = (e) => {
        e.preventDefault();
        resetMessage()

        let notANewNode = props.allScripts2.length > 0 ? props.allScripts2.find(e => e.id == props.formsDataCollection.nodeData.id) : ''
        if (!notANewNode) {
            alert('Please save the node first and then create the trigger action for it')
            return true
        }

        const formattedActions = actions.map(action => {
            let bodyParams = {}
            action.variables.forEach(element => {
                bodyParams[element.name] = element.value
            });
            return {
                title: action.title,
                api: action.api,
                bodyParams: bodyParams,
                tag: action.tag
            }
        })

        const dataToSend = {
            "script": selectAction.id,
            "nodeId": props.formsDataCollection.nodeData.id,
            "actions": formattedActions
        }

        ApiActions.createTriggerAction(dataToSend)
            .then(resp => {
                let result = allScripts.map(each => {
                    if (each.id == resp.data.id) {
                        each.script = resp.data.script
                        return each
                    } else {
                        return each
                    }
                })
                setAllScripts([...result])
                result.map(elem => {
                    if (elem.defaultScript) {
                        setElements(elem.script)
                        setElements1([...elem.script])
                        setAllScripts2(elem.script)
                    }
                })
                let b = currentNodeData.map((e) => {
                    if (e.id == dataToSend.script) {
                        e.nodeData.data.actions = dataToSend.actions
                        return e
                    }
                    return e
                })

                setCurrentNodeData([...b])
                setSuccess(`Trigger action for ${resp.data.scriptLanguage} was saved successfully`)
            })
            .catch(err => {
                if (err) {
                    setError(`Something went wrong while saving`)
                }
            })
    }
    const formRender = () => {
        const highestTriggerActionCount = Math.max(...getScriptsData.map(el => el.nodeData.data.actions ?
            el.nodeData.data.actions.length : 0))
        return <div className="additional-wrapper2" style={{ padding: '2%' }}>
            <div className='tab-wrapper'>
            <div className='tab-container'>
                {
                    getScriptsData.map((e, idx) => {
                        return <div className='dc single-tab' key={idx}>
                            <div onClick={(f) => {
                                toggle(e)
                            }}>
                                <a>
                                    <div className={selectAction.name == e.name ?
                                        `each-tab active` : highestTriggerActionCount > (e.nodeData.data.actions
                                            ? e.nodeData.data.actions.length : 0) ?
                                            `each-tab error` : `each-tab`}>{e.name}({e.nodeData.data.actions
                                                ? e.nodeData.data.actions.length : 0})
                                            {highestTriggerActionCount > (e.nodeData.data.actions && e.nodeData.data.actions.length ?
                                            e.nodeData.data.actions.length : 0) ?
                                            <img src={require('../../../assets/images/Interactive Script/redo_urgent.svg')} />
                                            : null
                                        }
                                    </div>
                                </a>
                            </div>
                        </div>
                    })
                }
            </div>
            <a className='doc-link' href='https://docs.google.com/document/d/1QNiT8EF13rvuzYcBmJE24kqi1HsQubGDKUNjsJkMnFQ/edit' target="_blank">Documentation</a>
            </div>
            {renderActionUI()}
            {checkIfLatest ? <AlwaysScrollToBottom /> : null}
        </div>
    }
    return formRender()
}

export default TriggerActionForm