import React, { useEffect, useState } from 'react';
// import './styles.css';

function AutoSuggestTextarea({value, required, name, suggestionslist, index, lang, newsetNodeLabel}) {
  const [inputValue, setInputValue] = useState(value ? value : '');
  const [showMenu, setShowMenu] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(null);

  useEffect(() => {
    setInputValue(value)
  }, [value])
  
  const handleInputChange = (event) => {
    const value = event.target.value;
    const cursorPos = event.target.selectionStart;
    setInputValue(value);
    setCursorPosition(cursorPos);
    const atIndex = value.lastIndexOf('@', cursorPos - 1);
    if (atIndex !== -1) {
      const searchText = value.substring(atIndex + 1, cursorPos);
      const newSuggestions = getSuggestions(searchText);
      setSuggestions(newSuggestions);
      if(newSuggestions && newSuggestions.length > 0)
      setShowMenu(true);
      else
      setShowMenu(false)
    } else {
      setShowMenu(false);
    }
    newsetNodeLabel(value, index, lang)
  };

  const getSuggestions = (searchText) => {
    // Here you would implement your logic to fetch suggestions based on the searchText
    // For demonstration, let's return some static suggestions
    const staticSuggestions = suggestionslist && suggestionslist.length > 0 ? suggestionslist.map(suggst => {
      return suggst.name
    }): []
    return staticSuggestions && staticSuggestions.length > 0 ? 
    staticSuggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(searchText.toLowerCase())
    ) : [];
  };

  const handleSuggestionClick = (suggestion) => {
    const atIndex = inputValue.lastIndexOf('@', cursorPosition - 1);
    const newText = inputValue.substring(0, atIndex) + suggestion + ' ' + inputValue.substring(cursorPosition);
    setInputValue(newText);
    newsetNodeLabel(newText, index, lang)
    setShowMenu(false);
    const textarea = document.getElementById('autoSuggestTextarea');
    textarea.focus();
    setTimeout(() => textarea.setSelectionRange(atIndex + suggestion.length + 1, atIndex + suggestion.length + 1), 0);
  };

  return (
    <div className='auto-suggest'>
    <div>
      <textarea 
        rows={4}
        id="autoSuggestTextarea" 
        className="auto-suggest-textarea" 
        placeholder="Type here..." 
        value={inputValue} 
        onChange={handleInputChange}
        name={name}
        required={required}
      />
      {showMenu && (
        <div className="auto-suggest-menu">
          {suggestions && suggestions.length > 0 ? suggestions.map((suggestion, index) => (
            <div 
              key={index} 
              className="auto-suggest-item" 
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </div>
          )) : <span>No task props found</span>}
        </div>
      )}
    </div>
    </div>
  );
}

export default AutoSuggestTextarea;