import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faPenToSquare} from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import FutworkButton from '../../../uicomponents/button'
import {updateBasePay} from './service'

const BasePay = ({rowData, updateMessage}) => {
    const { firstname, lastname, mobile, earnings, userId, id } = rowData
    const [resultMsg, setResultMsg] = useState('')
    const [pay, setPay] = useState(earnings === 'null' ? '' : earnings || '')
    const [isPayValid, setIsPayValid] = useState(earnings < 1000 ? true : false)
    const [isEarningDisabled, setIsEarningDisabled] = useState(earnings ? true : false)
    const [edit, setEdit] = useState(earnings > 0 ? true : false)
    const [isInteger, setIsInteger] = useState(true)

    
    const isWholeNumber = (value) => {
        if (value % 1 === 0) {
          setIsInteger(true)
        } else {
          setIsInteger(false)
        }
      }
    
    const changePay = (e) => {
        setPay(e)
        setIsPayValid(e < 1000 ? true : false)
        isWholeNumber(e)
    }

    const  updateEarnings = async() => {
        let payload = {
            earnings: Number(pay)
        }
        try{
            let result = await updateBasePay(userId || id, payload)
            const {data} = result
            if(result.status == 200){
                setResultMsg(`The earnings is updated to ${data && data.earnings}`)
                updateMessage(data)
            }
        }catch(err){
            if(err.response)
            setResultMsg((err.response && err.response.data)|| "Unexpected Error!")
            else
            setResultMsg(err.message || "Unexpected Error!")
        }
    }

    const checkIfAmountEntered = () => {
            if((pay <= 0) || !isPayValid || !isInteger){
                return true
            }
            return edit
    }

    return(
        <div className={styles['telegram-group-link-section']}>
            <div className={styles['title-coach']}>
            <p>Caller name: {`${firstname || 'NA'} ${lastname || 'NA'}`}</p>
            <p>Mobile: {mobile}</p>
            </div>
            <div className={styles['telegram-group-link']}>
            <div className={styles['row']}>
                <div className={styles['first']}>
                <div className={styles.currencyinput}>
                <input type="number" name="earning" id="earning"
                placeholder="/day"
                value = {pay}
                onChange = {(e) => {
                    changePay(e.target.value)
                    if(e.target.value > 0) setEdit(false)
                }}
                disabled={isEarningDisabled}
                className={isEarningDisabled ? styles['disabled'] : ''}
                required/>
                </div>
                </div>
                {earnings !== 'null' && isEarningDisabled && <FontAwesomeIcon icon={faPenToSquare} className={styles['color-blue']}
                 onClick={() => setIsEarningDisabled(false)} /> }
            </div>
            {resultMsg && <span>{resultMsg}</span>}
            {pay && !isPayValid ? <span className={styles['error-message']}>Error : Base pay cannot be set above ₹ 999/day. Please raise MT</span> : null}   
            {!isInteger ? <span className={styles['error-message']}>The base pay cannot be a decimal value. Please enter a whole number</span> : null}   
            </div>
            <FutworkButton buttonSize='sm' buttonStyle='navy--solid'
			disabled={checkIfAmountEntered()}
			onClick={() => updateEarnings()}
			>SAVE</FutworkButton>
        </div>
    )
}

export default BasePay