import React, {useState, useRef, useEffect} from "react";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { SingleDatePicker } from "react-dates";
import moment from 'moment'
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import FrappButton from '../../../components/utilities/FrappButton'
import {PlusIcon, DeleteIcon} from '../../../assets/images'
import {generateSmsReport} from './service'
import useApi from '../../../hooks/useApi'
import handleErrorMessage from '../../../helper/handleErrorMessage'

const SMSIntegration = ({data}) => {
    if(!data){
        return <ThreeDotLoader/>
    }
    const sendSmsReportApi = useApi(generateSmsReport)

    let emailRef = useRef()
    let submitSmsReport = useRef('')

    const [defaultStartDate, setDefaultStartDate] = useState(data.startDate.split('T')[0])
    const [defaultEndDate, setDefaultEndDate] = useState(data.endDate.split('T')[0])
    const [orginalStartDate, setOrginalStartDate] = useState(data.startDate.split('T')[0])
    const [originalEndDate, setOriginalEndDate] = useState(data.endDate.split('T')[0])
    const [startDateFocussed, setStartDateFocussed] = useState(false)
    const [endDateFocussed, setEndDateFocussed] = useState(false)
    const [reportSent, setReportSent] = useState(false)
    const [emailArray, setEmailArray] = useState([])

    useEffect(()=>{
        if(sendSmsReportApi.data){
            setReportSent(true)
        }
    },[sendSmsReportApi.data])

    const addEmail = (email) => {
        emailRef.current.value = ''
        const updateArray = [...emailArray, email]
        setEmailArray(updateArray)
    }

    const removeEmail = (email, idx) => {
        let updatedEmailArray = [...emailArray]
        updatedEmailArray.splice(idx, 1)
        setEmailArray([...updatedEmailArray])
    }

    const formatSmsType = (type) => {
        switch (type) {
            case 'smsSent':
                return 'SMS sent'
            case 'smsDelivered':
                return 'SMS delivered'
            case 'smsFailed':
                return 'SMS failed'
            default:
                return type
        }
    }

    let startDate = defaultStartDate
    ? moment(new Date(defaultStartDate))
    : null;
    let endDate = defaultEndDate
    ? moment(new Date(defaultEndDate))
    : null;

    const setDate= (date,start) => {
        let tempStartDate, tempEndDate
        if (start) 
        {     tempStartDate = date.format('YYYY-MM-DD');
              setDefaultStartDate(tempStartDate)
        }
        if (!start) 
        {     tempEndDate = date.format('YYYY-MM-DD');
              setDefaultEndDate(tempEndDate)
        }
    }

    const sendSmsReport = async() => {
        if(reportSent){
            setReportSent(false)
        }
        let dataToSend = {
            "teleproject": data.id,
            "emails": emailArray.toString(),
            "startDate": defaultStartDate,
            "endDate": defaultEndDate,
        }
        submitSmsReport.current.startLoading();
        try{
            sendSmsReportApi.request(dataToSend)
        }catch(err){
            console.error((err.response && err.response.data)|| "Unexpected Error!")
            if(err)
            toast(handleErrorMessage(err))
        }finally{
            submitSmsReport.current.stopLoading();
        }
    }

    let {error} = sendSmsReportApi || {}
    return(
        <div className={styles.sms_integration_wrapper}>
                       {data.outcomeWiseSms && data.outcomeWiseSms.length > 0  ? 
                          data.outcomeWiseSms.map((each, idx) => {
                           return <div className={styles.linked_outcomes} key={idx}>
                            <span className={styles.outcome_name}>{each._id}:</span>
                            <span className={styles.outcome_value}>{each.count}</span>
                            </div>
                        }) : <div className={styles.linked_outcomes}>No linked outcomes present yet</div>}
                        <div className={styles.sms_stats}>
                            {
                                data.stats && Object.keys(data.stats).map(function(keyName, keyIndex){
                                    // use keyName to get current key's name
                                    // and a[keyName] to get its value
                                    return  <div className={styles.details} key={keyIndex}>
                                    <span className={styles.stats_name}>{formatSmsType(keyName)}</span>
                                    <span className={styles.stats_value}>{data.stats[keyName]}</span>
                                   </div>
                                })
                            }
                        </div>

                        <div className={styles.email_section}>
                        <div className={styles.pill_wrapper}>
                            {emailArray.map((email, idx) => {
                            return <div className={styles.pill} key={idx}>
                            {email}
                            <div className={styles.del_icon}>
                                <img src={DeleteIcon} alt='delete-icon' onClick={() => removeEmail(email, idx)}/>
                            </div>
                            </div>;
                            })}
                        </div>
                        <div className={styles.email_field}>
                            <div className={styles.email_field_row}>
                            <div className={styles.email_text}>
                            <FormGroup>
                            <input  type='email' placeholder="Enter email address & press enter" name="email"  ref={emailRef} onKeyDown={(e) => {
                                if(e.key === 'Enter')
                                addEmail(e.target.value)
                            }}/>
                            </FormGroup>
                            </div>
                            <img src={PlusIcon} alt='plus_icon' onClick={()=>addEmail(emailRef.current.value)}/>
                            </div>
                        </div>
                        </div>

                        
                        <div className={styles.date_report}>
                        <div name="startDate" id="startdate">
                        <div className="info">Start Date</div>
                        <SingleDatePicker noBorder={true} id="startDateReport" date={startDate} 
                        focused={startDateFocussed} 
                            onFocusChange={(focused) => {
                            setStartDateFocussed(focused.focused)
                            }} placeholder="Select Start Date" 
                            onDateChange={(e) => setDate(e, 1)} 
                            displayFormat="DD/MM/YYYY" numberOfMonths={1} hideKeyboardShortcutsPanel
                            verticalSpacing={0}
                            isOutsideRange={date => date.isBefore(orginalStartDate, 'day') || date.isAfter(originalEndDate, 'day')}
                            />
                        </div>
                        <div className="endDate" id="enddate">
                        <div className="info">End Date</div>
                        <SingleDatePicker noBorder={true} id="endDateReport" date={endDate} focused={endDateFocussed} 
                            onFocusChange={(focused) => {
                            setEndDateFocussed(focused.focused)
                            }} placeholder="Select End Date" 
                            onDateChange={(e) => setDate(e, 0)} 
                            displayFormat="DD/MM/YYYY" numberOfMonths={1} hideKeyboardShortcutsPanel
                            verticalSpacing={0}
                            isOutsideRange={date => date.isBefore(orginalStartDate, 'day') || date.isAfter(originalEndDate, 'day')}
                            />
                        </div>
                        </div>
                        {/* <span className='text-muted optional-field'>Optional</span> */}

                        <div style={{ width: "100%" }}>
                        {reportSent ? <div className={styles.success_banner}>{sendSmsReportApi.data && sendSmsReportApi.data.data.msg}</div> : 
                         error ? <div className={styles.failure_banner}>
                         
                        {error && typeof error == 'object' && 
                        error !== null &&
                        !Array.isArray(error)
                        ? error.error || error.msg : error}

                         </div> : null
                        }
                        </div>
                        
                        <div className={styles.footer}>
                            <FrappButton className="submit" id="new-btn"
                            ref={submitSmsReport}
                            title={emailArray && emailArray.length <= 0 ? "Email is required to send report" : ''} disabled={emailArray && emailArray.length > 0 ? false : true} handler={() => sendSmsReport()}>
                                DOWNLOAD
                            </FrappButton>
                        </div>
                    <ToastContainer draggable={true} />
                    </div>
    )
}

export default SMSIntegration