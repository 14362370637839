import ApiActions from "../../../actions/ApiActions";

const getPaymentData = async() => {
    const result = await ApiActions.getTeleCallerNumbers('tmp')
    return result
}

const transformPaymentData = (result) => {
    const {currentOutstandingPayment} = result.data
    return {
        title: 'Outstanding Amount',
        value: currentOutstandingPayment.count
    }
}

const sendPaymentRequest = async() => {
    const result = await ApiActions.sendPaymentRequest()
    return result
}

const raiseBonusRequest = async(payload) => {
    const result = await ApiActions.raiseBonusRequest(payload)
    return result
}   

export{
    getPaymentData,
    transformPaymentData,
    sendPaymentRequest,
    raiseBonusRequest
}
