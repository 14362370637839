
import oboe from 'oboe';

class DataStreamer {
    constructor(){
        this.totalString = '';

        this._processorInstance = null;
    }

    init = (url,respCallback) => {
        let baseUrl = process.env.REACT_APP_API_URL;
        // baseUrl = "https://backbone.frapp.in";
        
        oboe({
            url:baseUrl+url,
            headers:{'Authorization':`Bearer ${localStorage.getItem('authToken')}`},
            responseType:'stream'
        })
        .node('!.*',(obj) => {
            respCallback(obj);
        })
    }
    process = (string) => {
        string.split("").map(char => {
            this._processorInstance.process(char);
            return null
        })
    }
}

export default DataStreamer