import React, {useRef} from 'react'
import Button from 'react-bootstrap/lib/Button';

import './button.scss'

const STYLES = [
    "primary--solid",
    "primary--solid non-rounded",
    "warning--solid",
    "danger--solid",
    "success--solid",
    "navy--solid",
    "navy--solid non-rounded",
    "primary--outline",
    "primary--outline non-rounded",
    "warning--outline",
    "danger--outline",
    "success--outline",
    "navy--outline non-rounded",
    "gradient-1--solid"
]

const SIZES = [
    "sm",
    "medium",
    "large",
    "sm w-100",
    "medium w-100",
    "x-large"
]

const FutworkButton = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    disabled,
    ref
}) => {
    const buttonRef = useRef('')
    const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize)
    ? buttonSize
    : SIZES[0];

    const checkDefaultState = disabled ? 'disabled' : ''

    return (
    <div className='futworkbutton'>
    <div 
    className={`btn ${checkDefaultState} ${checkButtonStyle} ${checkButtonSize}`} 
    onClick={checkDefaultState === 'disabled' ?  null : onClick} type={type}
    ref={buttonRef}
    >
     {children}
    </div>
    </div>
    )
}

export default FutworkButton