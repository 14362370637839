import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getProjectsForAudit = async (skip, limit, sort, search) => {
    let payload = generatePayload(skip, limit, sort, search)
    payload.filterStatus = 'active'
    return await ApiActions.getTeleProjectsForAudit(payload)
}

const getTotalProjects = async (search) => {
    return await ApiActions.getTeleProjectsForAuditCount(search)
}

const getAuditCallersList = async (skip, limit, sort, search) => {
    const payload = generatePayload(skip, limit, sort, search)
    return await ApiActions.getAuditCallersList(payload)
}

const getCallsOfCaller = async (skip, limit, sort, search, data) => {
    let payload = generatePayload(skip, limit, sort, search)
    payload = { ...payload, ...data }
    return await ApiActions.getCallsOfCaller(payload)
}

const getQmfscorerulebook = async () => {
    return await ApiActions.getQmfscorerulebook()
}

const getTeletaskCallHistory = async (payload) => {
    return await ApiActions.getTeletaskCallHistory(payload)
}

const getTeleProject = async (teleprojectId) => {
    return await ApiActions.getTeleProject(teleprojectId)
}

const submitQmfScore = async (payload) => {
    return await ApiActions.submitQmfScore(payload)
}

const getQmfCallsAuditReport = async(payload) => {
    return await ApiActions.getQmfCallsAuditReport(payload)
}

const getProjectsForAutohold = async (skip, limit, sort, search) => {
    let payload = generatePayload(skip, limit, sort, search)
    payload.filterStatus = 'active'
    return await ApiActions.getTeleprojectsForAutohold(payload)
}

export {
    getProjectsForAudit,
    getTotalProjects,
    getAuditCallersList,
    getCallsOfCaller,
    getQmfscorerulebook,
    getTeletaskCallHistory,
    getTeleProject,
    submitQmfScore,
    getQmfCallsAuditReport,
    getProjectsForAutohold
}