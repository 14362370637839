import React, { useState, useEffect, useRef } from 'react'
import Dropdown from 'react-dropdown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from "react-toastify";
import ApiActions from '../../actions/ApiActions';
import ThreeDotLoader from '../utilities/ThreeDotLoader';
import CustomerInfo from '../utilities/CustomerInfo';
import FrappButton from '../utilities/FrappButton';

const AuditRule1ACall = ({ row, close }) => {
    const customInput = useRef('')
    const submitBtn = useRef('')
    const { teletask } = row
    let options = [
        { label: "Approve", value: "approved" },
        { label: "Incorrect Handling", value: "incorrect handling" }
    ]
    let options_ = [
        { label: "QC Pass", value: "qcpass" },
        { label: "Retrain", value: "training" }
    ]
    const [checkboxFeedbackData, setCheckboxFeedbackData] = useState([])
    const [checkboxCallerAuditData, setCheckboxCallerAuditData] = useState([])
    const [checkBanReasons, setCheckBanReasons] = useState([])
    const [checkRetrainReasons, setCheckRetrainReasons] = useState([])
    const [auditAction, setAuditAction] = useState('')
    const [reason, setReason] = useState("Cleared in QC")
    const [callAuditAction, setCallAuditAction] = useState('')
    const [outcomeChanged, setOutcomeChanged] = useState(false)
    const [teleproject, setTeleproject] = useState('')

    const fetchReasons = async (rowData) => {
        try {
            const result = await ApiActions.getQAFeedbackReason()
            if (result) {
                setCheckboxFeedbackData(result.callFeedbacks.map((item) => {
                    return { "label": item, "checked": false }
                }))
                setCheckBanReasons(result.banReasons.map((item) => {
                    return { "label": item, "checked": false }
                }))
                setCheckRetrainReasons(result.retrainReasons.map((item) => {
                    return { "label": item, "checked": false }
                }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getProjectDetails = async () => {
        try {
            const result = await ApiActions.getTeleProject(row.teleprojectId)
            if (result) setTeleproject(result)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchReasons()
        getProjectDetails()
    }, [])

    const handleChangeCheckboxFeedback = (event) => {
        setCheckboxFeedbackData(checkboxFeedbackData.map((item) => {
            if (item.label === event.target.name) {
                item.checked = event.target.checked
                return item
            }
            return item
        }))
    };

    const handleChangeCheckboxCallerAction = (event) => {
        setCheckboxCallerAuditData(checkboxCallerAuditData.map((item) => {
            if (item.label === event.target.name) {
                item.checked = event.target.checked
                return item
            }
            return item
        }))
    }

    const renderCheckboxFeedback = (checked, label) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeCheckboxFeedback}
                        name={label}
                        color="primary"
                    />
                }
                label={<span style={{ fontSize: '13px', marginBottom: '0px !important' }}>{label}</span>}
            />
        )
    }

    const renderCheckboxCallerAction = (checked, label) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeCheckboxCallerAction}
                        name={label}
                        color="primary"
                    />
                }
                label={<span style={{ fontSize: '13px' }}>{label}</span>}
            />
        )
    }

    const enterPress = (event) => {
        if (event.key === "Enter") {
            setCheckboxFeedbackData([...checkboxFeedbackData, { label: event.target.value, checked: true }])
            customInput.current.value = ''
        }
    }


    const selectValueCallerActions = (val) => {
        setAuditAction(val.value)
        if (val.value === "qcpass") {
            // QC Pass
            setReason("Cleared in QC")
            setAuditAction("qcpass")
        }
        else if (val.value === "training") {
            // retrain
            setReason("Marked Retrain by agent")
            setAuditAction("training")
            setCheckboxCallerAuditData(checkRetrainReasons)
        }
        else {
            // Ban
            setReason("Marked Ban by agent")
            setAuditAction("ban")
            setCheckboxCallerAuditData(checkBanReasons)
        }
    }

    const selectValueCallAudit = (val) => {
        if (val.value === "approved" || val.value === "incorrect handling") {
            setCallAuditAction(val.value)
            setOutcomeChanged(false)
        } else {
            setCallAuditAction(val.value)
            setOutcomeChanged(true)
        }
    }

    const updateAction = () => {
        const { outcomes } = teleproject
        submitBtn.current.startLoading()
        let dataToSend;
        if (outcomeChanged) {
            var selectedOutcome = outcomes.filter(o1 => {
                return o1.title === callAuditAction
            })
            dataToSend = {
                teletaskHistoryAuditId: row._id,
                action: "outcome changed",
                outcome: selectedOutcome[0]
            }
        } else {
            dataToSend = {
                teletaskHistoryAuditId: row._id,
                action: callAuditAction
            }
        }
        let checkSelectedFeedback = checkboxFeedbackData.some((item) => {
            return item.checked === true
        })
        if (checkSelectedFeedback) {

            dataToSend.feedbacks = checkboxFeedbackData.map((item) => {
                if (item.checked === true) {
                    return item.label
                }
            }).filter(i => i)
        }

        ApiActions.updateCallAuditAction(dataToSend).then(resp => {
            submitBtn.current.stopLoading()
        }).then(() => {
            let checkSelectedCallerFeedback = checkboxCallerAuditData.some((item) => {
                return item.checked === true
            })
            if (auditAction !== "") {
                let dataToSendCallerAction = {
                    teleproject: teleproject.id,
                    user: teletask.user,
                    actionBy: "agent",
                    "action": auditAction,
                    "reason": reason
                }
                if (auditAction === "training") {

                    let retrainReasons = checkboxCallerAuditData.map((item) => {
                        if (item.checked === true) {
                            return item.label
                        }
                    }).filter(i => i)
                    dataToSendCallerAction = {
                        ...dataToSendCallerAction,
                        retrainReasons: retrainReasons
                    }
                } else if (auditAction === "ban") {
                    dataToSendCallerAction = {
                        ...dataToSendCallerAction,
                        banReasons: checkboxCallerAuditData.map((item) => {
                            if (item.checked === true) {
                                return item.label
                            }
                        }).filter(i => i)
                    }
                }
                ApiActions.updateCallerStatus(dataToSendCallerAction).then((response) => {
                    setCallAuditAction("")
                    setAuditAction("")
                }).catch((error) => {
                    submitBtn.current.stopLoading()
                    // this.callAuditModal.toggle(false);
                    close()
                    toast("Error")
                })
            }
        }).then(() => {
            toast("Success")
            // this.callAuditModal.toggle(false)
            close()
            // submitBtn.current.stopLoading()
        }).catch((error) => {
            // submitBtn.current.stopLoading()
            // this.callAuditModal.toggle(false);
            close()
            toast("Error")
        })
    }

    return (
        <div className="call-audit-modal-body">
            <div className="heading">Call audit</div>
            <CustomerInfo info={teletask} />

            <div className='action-feedback'>
                <div className="heading">Call action</div>
                <div className="dropdown-call-audit">
                    <Dropdown options={options}
                        onChange={(val) => selectValueCallAudit(val)}
                        value={callAuditAction}
                        placeholder="Select an option" />
                </div>
                <div className="heading">Feedback (Optional)</div>
                <div className="call-audit-checkbox">
                    {checkboxFeedbackData && checkboxFeedbackData.map((item) => {
                        return renderCheckboxFeedback(item.checked, item.label, item.id);
                    })}
                    <input type="text"
                        ref={customInput}
                        placeholder="Custom Input" className="custom-input"
                        onKeyDown={enterPress}
                    />
                </div>
            </div>

            <div className='action-feedback'>
                <div>
                    <div className="heading">Caller Actions (Optional)</div>
                    <div className="dropdown-call-audit">
                        <Dropdown options={options_}
                            onChange={(val) => selectValueCallerActions(val)}
                            value={auditAction}
                            placeholder="Select an option" />
                    </div>
                    {auditAction === "ban" ? <div className="call-audit-checkbox" >
                        {checkboxCallerAuditData.map((item) => {
                            return renderCheckboxCallerAction(item.checked, item.label);
                        })}
                    </div> : auditAction === "training" ? <div className="call-audit-checkbox" >
                        {checkboxCallerAuditData.map((item) => {
                            return renderCheckboxCallerAction(item.checked, item.label);
                        })}
                    </div> : null}
                </div>
            </div>

            <div className="call_audit-submit-button">
                <FrappButton ref={submitBtn} className="submit" id="bulk-status-btn" handler={() => updateAction()}
                    disabled={callAuditAction ? false : true}>
                    Submit
                </FrappButton>
            </div>

            <ToastContainer draggable={true} />

        </div>
    )
}

export default React.memo(AuditRule1ACall)