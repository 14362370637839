import React from "react";

import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";

import FrappTooltip from "../utilities/FrappTooltip";

class SearchInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            searchValue:this.props.searchValue || "",
            searchResults:[],
            returnValue:this.props.returnValue || ""
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return true;
    }
    
    componentWillReceiveProps(nextProps){
        const {error,searchValue,returnValue} = nextProps;
        
        var state = {};
        if(error){state.error = error};
        if(searchValue){state.searchValue = searchValue};
        if(returnValue){state.returnValue = returnValue};
        
        this.setState(state)
    }

    fetchData = (e) => {
        var searchValue = e.target.value;
        this.setState({searchValue})
        if(searchValue.length >= 1){
            const {getData,searchField,extraParams,whereParams,customParams} = this.props.data;
            var where = {
                $or:searchField.map(field => {return {[field]:{"$regex":searchValue,"$options":"i"}} }),
            }
            if(whereParams){where = {...where,...whereParams}};
            var finalParams = {where:JSON.stringify(where),limit:50,sort:{"createdAt":-1}};

            // var params = Object.assign(finalParams,extraParams);
            var params = customParams ? Object.assign({query:searchValue},customParams): Object.assign(finalParams,extraParams);
            getData(params)
            .then(this.handleDataResponse)
        }else{
            this.setState({searchResults:[]})
        }
    }
    
    handleDataResponse = (data) => {
        var searchResults = data.length === 0 ? ['Data not found'] : data;
        this.setState({searchResults})
    }

    render(){
        const {data} = this.props;
        
        return (
            <FormGroup className={`search-input-wrapper ${this.state.error ? 'form-error' : ''}`} >
                {data.label && <ControlLabel>{data.label}</ControlLabel>}
                {this.props.entity === "city" ? this.renderROIInfo() : null}
                <FormControl placeholder={this.props.placeholder} type="text"  onChange={this.fetchData} value={this.state.searchValue}/>
                {this.getErrorMessage() ? <FrappTooltip>{this.getErrorMessage()}</FrappTooltip> : null}
                {this.renderDropdown()}
            </FormGroup>
        )
    }

    renderDropdown = () => {
        var {searchResults} = this.state;
        const {customListItem} = this.props.data;
        
        return searchResults.length > 0 ? (
            <div className="custom-dropdown" >
                <ul>
                    {searchResults.map((item,i) => <li key={item.id || item.name || i} data-obj={JSON.stringify(item)} onMouseDown={this.setDropdownData} >{this.getSearchField(item)}</li> )}
                    {customListItem ? <li key="custom-list-item" onMouseDown={this.onClickCustomListItem} className="custom-list-item">{customListItem.label}</li>  : null}
                </ul>
            </div>
        ) : null
    }

    renderROIInfo = () => {
        var {searchResults} = this.state;
        
        return searchResults[0] && searchResults[0].name.toLowerCase() === "rest of india" 
        ? <div className="roi" >Can't find your city? Choose Rest of India.</div> : null
    }

    getSearchField = (obj) => {
        const {render} = this.props.data;
        return obj === 'Data not found' ? obj : render(obj);
    }

    setDropdownData = (e) => {
        var searchValue = e.target.innerText;
        if(searchValue.indexOf("not found") >= 0){

        }else{
            var dataObj = e.target.attributes[`data-obj`];
            var returnValue = JSON.parse(dataObj.value);
            this.setState({searchValue,searchResults:[],returnValue},() => {
                if(this.props.data.callback){
                    this.props.data.callback(searchValue,returnValue)
                }
            })
        }
    }

    getErrorMessage = () => {
        var {returnValue,searchValue} = this.state;
        var {errorMessage,label} = this.props.data;
        
        if(!errorMessage){
            return ""
        }

        if(!searchValue){
            return errorMessage
        }else if (!returnValue){
            return `Please select a ${label.toLowerCase()} from the list.`
        }
        return ""
    }

    onClickCustomListItem = (e) => {
        const {customListItem} = this.props.data;
        customListItem.onClick()
    }
}

export default SearchInput;