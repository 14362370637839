import ApiActions from "../../../actions/ApiActions";

const getCallAttendanceTracker = async(startDate, endDate, present = '', paid = '') => {
    const result = await ApiActions.getCallAttendanceTracker(startDate, endDate, present, paid)
    return result
}

const getCallerAttendance = async(from, to, userId = '', paid = '') => {
    const result = await ApiActions.getCallerAttendance(from, to, userId, paid)
    return result
}

const raisePayment = async(payload) => {
    return await ApiActions.raisePaymentRequest(payload)
}

const confirmPaymentRequest = async(payload) => {
    return await ApiActions.confirmPaymentRequest(payload)
}

const checkPaymentStatus = async() => {
    return await ApiActions.checkPaymentStatus()
}

const getDuePayments = async(startDate, endDate) => {
    return await ApiActions.getDuePayments(startDate, endDate)
}
export{
    getCallAttendanceTracker,
    getCallerAttendance,
    raisePayment,
    confirmPaymentRequest,
    checkPaymentStatus,
    getDuePayments
}