import React, { Component } from 'react'
import Link from 'react-router-dom/Link';
export default class RouteTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [],
            tabType: ''
        }
    }
    componentDidMount() {
        this.setState({ tabs: this.props.tabArray, activeTab: this.props.activeTab, tabType: this.props.tabType })
    }

    render() {
        let { tabs, tabType } = this.state;
        let { activeTab } = this.state;
        return (
            <div className={tabType == 'multi' ? "multi-tab" : 'single-tab'}>

                <div className="tab">

                    {tabs.map((tab, i) => {
                        return (
                            <Link to={{
                                pathname: `${tab.route}`,
                                state: tab.state
                              }}>
                                <div className=
                                    {`${activeTab.name === tab.name ? 'each-tab-active' : 'each-tab'}`}
                                    onClick={() =>  this.handleClick(tab, i) }> {tab.name}
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }


    handleClick = (tab, i) => {

        this.setState({ activeTab: tab })
    }
}
