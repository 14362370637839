import React, {useState} from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXmark } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from './popup.module.scss'
import TruncateString from '../../helper/TruncateString'

const Popup = (props) => {
    const {heading, show, popupContent, children, popupFooter, closePopup, size = 'sm', footerAlign = 'center', stickyHeader = false} = props
    const [isShow, setShow] = useState(false)

    const closeButtonClicked = () => {
        if(closePopup){
        closePopup()
        toggle(false)
        }else{
        toggle(false)
        }
    }

    const onHide = () => {
        if(props.onHide === false){
        }else{
            toggle(false)
        }
    }

    const toggle = (show) => {
        setShow(show)
    }

    const assignClassName = () => {
        switch (size) {
            case "xs":
                return 'extra-small'
            case "sm":
                return 'small'
            case "md":
                return 'medium'
            case "lg":
                return 'large'
            case "xlg":
                return 'extra-large'
            default:
                return 'small'
        }
    }
    
    const truncateHeading = (heading) => {
        const checkPopupSize = () =>{
            switch (size) {
            case "xs":
                return 15
            case "sm":
                return 45
            case "md":
                return 55
            case "lg":
                return 70
            default:
                return 45
            }
        }
        if(typeof heading == 'string')
        return TruncateString(heading, checkPopupSize())
        else
        return heading
    }

    const getPopupFooterAlignment = () => {
        switch (footerAlign) {
            case 'center':
                return 'footer-content-center'
            case 'left':
                return 'footer-content-left'
            case 'right':
                return 'footer-content-right'
            default:
                return 'footer-content-center'
        }
    }

    return (
        <div className={styles.custom_popup}> 
        <Modal
        show={show} 
        dialogClassName = {styles[assignClassName()]}
        onHide={onHide} 
        >
        <Modal.Header className={[styles['modal-header'], stickyHeader ? styles['sticky-header'] : ''].join(' ')}>
          {truncateHeading(heading) || ''}
          <FontAwesomeIcon onClick={closeButtonClicked} icon={faXmark} className={styles['close-btn']}/>
        </Modal.Header>
        <Modal.Body className={styles['modal-main']}>
         {popupContent ? popupContent : children}
        </Modal.Body>
        {popupFooter 
        && <div className={[styles['modal-footer'], styles[getPopupFooterAlignment()]].join(' ')}>
            {popupFooter}
        </div>
        }
      </Modal>
      </div>
    )
}


export default React.memo(Popup)