import React, {useEffect, useState} from 'react'
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getProjectList, getTotalProjects, getProjectData, updateProjectData, coachMockCallStats,
    getTeleProject, getQuizDetails } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import Popup from '../../../uicomponents/popup'
import {StatsIconRounded,PlayIcon, PauseIcon, TrainingMaterialIcon, YellowStarIcon, UploadVideoIcon, DownloadIcon} from '../../../assets/images'
import CustomTooltip from '../../../components/utilities/ToolTip'
import CoachMockStats from './coachMockStats'
import TelegramGroupLink from './telegramGroupLink'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import Tag from '../../../uicomponents/tag'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import withTabGroup from '../../../hocs/withTabGroup'
import Tab from '../../../uicomponents/tab'
import RefresherTrainingVideo from '../refresherTrainingVideo';
import DownloadReport from '../../project/main/downloadReport';

const ProjectsInHiring = (props) => {
    const TabGroup = withTabGroup(Tab, 'group');
    const getProjectListApi = useApi(getProjectList)
    const getTotalProjectsApi = useApi(getTotalProjects)
    const getTeleProjectApi = useApi(getTeleProject)
    const getQuizDetailsApi = useApi(getQuizDetails)

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    //deprecte the state for showing the coack stats
    const [showCoachStats, setShowCoachStats] = useState(false)
    //deprecate the state for handling telegram link
    const [showTelegramGroupLink, setShowTelegramGroupLink] = useState(false)
    const [showProjectInfo, setShowProjectInfo] = useState({
        title: '',
        status: false
    })
    const [showRefresher, setShowRefresher] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showReport, setShowReport] = useState(false)

    const TABS = [
        'Hiring Projects',
        'Hiring Projects - Stats'
    ]

    const [activeTab, setActiveTab] = useState(TABS[0])

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        // getTotalProjectsApi.request(search)
        getProjectListApi.request(skip,limit,sort, search, activeTab.toLocaleLowerCase())
    },[currentPage, sort, search])

    const switchTab = (data) => {
        setActiveTab(data)
        setCurrentPage(1)
        setSearch('')
        filterChange('')
        setSort([])
    }

    //deprecate the code for toggling the hiring switch
    // const toggleHiring = async(projectData) => {
    //     let projectDetails = ''
    //     const {id, pauseHiring} = projectData
    //     try{
    //         projectDetails = await getProjectData(id)
    //         delete projectDetails.updatedAt;
    //         delete projectDetails.createdAt;
    //         projectDetails.pauseHiring = !pauseHiring;
    //         if(projectDetails){
    //         getProjectListApi.setLoading(true)
    //         let isProjectUpdated = await updateProjectData(projectDetails, id)
    //         if(isProjectUpdated){
    //             data.map(item => {
    //                 if(item.id == id){
    //                     item.pauseHiring = projectDetails.pauseHiring
    //                     return item
    //                 }
    //                 return item
    //             })
    //             isProjectUpdated.data.pauseHiring ? toast("Hiring Paused") : toast("Hiring Resumed")
    //         }
            
    //         }
    //     }catch(err){
    //         if(err.response)
    //             toast((err.response && err.response.data)|| "Unexpected Error!")
    //         else
    //         toast(err.message || "Unexpected Error!")
    //     }finally{
    //         getProjectListApi.setLoading(false)
    //     }
    // }
    //deprecate the telegram link code
    // const telegramLink = async(projectData) => {
    //     let projectDetails = ''
    //     const {id} = projectData
    //     try{
    //         projectDetails = await getProjectData(id)
    //         delete projectDetails.updatedAt;
    //         delete projectDetails.createdAt;
    //         if(projectDetails){
    //             setSelectedRowData(projectDetails)
    //         }
    //     }catch(err){
    //         if(err.response)
    //             toast((err.response && err.response.data)|| "Unexpected Error!")
    //         else
    //         toast(err.message || "Unexpected Error!")
    //     }finally{
    //         setShowTelegramGroupLink(true)
    //     }
    // }

    const getProjectDetails = async(id, title) => {
        getTeleProjectApi.request(id, title)
        setShowProjectInfo({
            title,
            status: true
        });
    }

    const goToProjectHiringDetails = (rowData) => {
        props.history.push({
            pathname: `${props.location.pathname}/${rowData.id}`,
            state: {
                projectdata: data && [...data.filter(proj => proj.id == rowData.id)].shift()
            }
        })
    }

    const fetchReportData = async(projectData, type) => {
        let projectDetails = ''
        const {id} = projectData
        try{
            projectDetails = await getProjectData(id)
            projectDetails = {
                ...projectDetails,
                defaultStartDate:projectDetails.startDate.split('T')[0],
                defaultEndDate:projectDetails.endDate.split('T')[0],
                reportType: type
            }
            setSelectedRowData({...projectDetails})
        }catch(err){
            if(err.response)
                toast((err.response && err.response.data) || "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        }finally{
            setShowReport(true)
        }
    }

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({cell}) => {
                const checkColor = (status) => {
                    if(status === 'Live') return 'green'
                    else return 'red'
                }
                const data = cell.row.original
                const today = new Date(); 
                const checkProjectStatus = data && data.endDate ? data.endDate > today.toISOString () ? 'Live' : 'Expired' : ''
                return data.title ? <span>{data.title}&nbsp;&nbsp;<sup style={{color: checkColor(checkProjectStatus)}}>{checkProjectStatus}</sup></span> : "NA"
            },
            width: "20%",
            disableSortBy: true
        },
        {
            Header: 'Type',
            Cell: ({cell}) => {
                const value = cell.row.original
                return ((value && value.hasAllocatedDialing) || (value && value.dialer)) 
                ? <>{value.hasAllocatedDialing ? 'ALP' : 'Predictive'}</> : <>Default</>
                // return <Tag color={(((value && value.hasAllocatedDialing) || (value && value.dialer)) ) ? 'orange-tab-solid' : 'purple-tab-solid'}
                //  content={((value && value.hasAllocatedDialing) || (value && value.dialer)) 
                //     ? <>{value.hasAllocatedDialing ? 'ALP' : 'Predictive'}</> : <>Default</>}
                // />
            },
            width: "2%",
            disableSortBy: true
        },
        {
            Header: 'Coach',
            accessor: "coach",
            Cell: ({value}) => ( 
                value && (value.hasOwnProperty('firstname') || value.hasOwnProperty('lastname'))
                 ? `${value.firstname || ''} ${value.lastname || ''}` 
                 : 'NA'
            ),
            width: "9%",
            disableSortBy: true
        },
        {
            Header: '',
            accessor: 'action',
            Cell: (cell) => (
            <div style={{display: 'flex', gap: '2em', justifyContent: 'flex-end'}}>
            <div className={styles.action_row}>
                <CustomTooltip 
                placement='bottom'
                description='Download reports'
                component={
                    <DropdownButton
                    bsSize='small'
                    title={<img src={DownloadIcon} alt='download report'/>}
                    id="download"
                    >
                    <MenuItem eventKey="1" 
                    onSelect={() => fetchReportData(cell.row.original, 'Project Report')}
                    >Project Report</MenuItem>
                    </DropdownButton>
                }/>

                <CustomTooltip 
                placement='bottom'
                description='Project Training'
                component={
                    <DropdownButton
                    bsSize='small'
                    title={<img src={TrainingMaterialIcon} alt='project material'/>}
                    id="material"
                    >
                    <MenuItem eventKey="1" 
                    onSelect={() => {getProjectDetails(cell.row.original.id, 'Training Material')}}
                    >Training Material</MenuItem>
                    <MenuItem eventKey="2" 
                    onSelect={() => {getProjectDetails(cell.row.original.id, 'Call Outcomes')}}
                    >Call Outcomes</MenuItem>
                    <MenuItem eventKey="3" 
                    onSelect={() => {getProjectDetails(cell.row.original.id, 'Quiz')}}
                    >Quiz</MenuItem>
                    <MenuItem eventKey="4" 
                    onSelect={() => {getProjectDetails(cell.row.original.id, 'Audio test')}}
                    >Audio test</MenuItem>
                    </DropdownButton>
                }/>
                 <CustomTooltip 
                placement='bottom' 
                description={'Refresher training video'} 
                component={
                    <img src={UploadVideoIcon} onClick={()=> {setShowRefresher(true); setSelectedRowData(cell.row.original)}}/>
                }/>
                {/* deprecte the icons that are not required */}
                {/* <CustomTooltip 
                placement='bottom' 
                description={(cell.row.original.hasOwnProperty('telegramGroupLink') && cell.row.original.telegramGroupLink !== '') ? 'Edit link' : 'Create link'} 
                component={<FontAwesomeIcon icon={(cell.row.original.hasOwnProperty('telegramGroupLink') && cell.row.original.telegramGroupLink !== '') 
                ? faLink : faLinkSlash} 
                onClick={()=> telegramLink(cell.row.original)}
                />}/> */}

                {/* <CustomTooltip 
                placement='bottom' 
                description={cell.row.original.pauseHiring ? 'Resume Hiring' : 'Pause Hiring'} 
                component={<img src={cell.row.original.pauseHiring ? PlayIcon : PauseIcon} 
                alt={cell.row.original.pauseHiring ? 'hiring-on' : 'hiring-off'}
                onClick={()=> toggleHiring(cell.row.original)}
                />}/> */}
                
                {/* <CustomTooltip 
                placement='bottom' 
                description='Coaches mock stats'
                component={<img src={StatsIconRounded} alt='coach stats' onClick={()=> {
                    setSelectedRowData(cell.row.original)
                    setShowCoachStats(true)
                }
                }/>}/>                 */}
            </div>
            <div className={styles.blue_link} 
            onClick={() => goToProjectHiringDetails(cell.row.original)}
            >
            VIEW
            </div>
            </div>
            ),
            width: "9%",
            disableSortBy: true
        }
    ]

    const statsColumns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({cell}) => {
                const checkColor = (status) => {
                    if(status === 'Live') return 'green'
                    else return 'red'
                }
                const data = cell.row.original
                const today = new Date(); 
                const checkProjectStatus = data && data.endDate ? data.endDate > today.toISOString () ? 'Live' : 'Expired' : ''
                return data.title ? <span>{data.title}&nbsp;&nbsp;<sup style={{color: checkColor(checkProjectStatus)}}>{checkProjectStatus}</sup></span> : "NA"
            },
            width: "20%",
            disableSortBy: true
        },
        {
            Header: 'Applied Callers',
            accessor: "appliedCallers",
            Cell: ({value}) => ( value || 0),
            width: "9%"
        },
        {
            Header: 'Callers in training',
            accessor: "trainingCallers",
            Cell: ({value}) => ( value || 0),
            width: "9%"
        },
        {
            Header: 'Callers for mock',
            accessor: "trainingMockCallers",
            Cell: ({value}) => ( value || 0),
            width: "9%"
        },
        {
            Header: 'Selected callers',
            accessor: "assignedCallers",
            Cell: ({value}) => ( value || 0),
            width: "9%"
        },
        {
            Header: 'Retraining (IQC + QC + QMF)',
            accessor: 'retrainingCallers',
            Cell: ({value}) => ( value || 0),
            width: "9%"
        },
        {
            Header: '',
            accessor: 'action',
            Cell: (cell) => (
            <div style={{display: 'flex', gap: '2em', justifyContent: 'flex-end'}}>
            <div className={styles.blue_link} 
            onClick={() => goToProjectHiringDetails(cell.row.original)}
            >
            VIEW
            </div>
            </div>
            ),
            width: "9%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const updateMessage = (msg, result) => {
        if(result){
            data.map(item => {
                if(item.id == result.id){
                    item.telegramGroupLink = result.telegramGroupLink
                    return item
                }
                return item
            })
        }
        toast(msg)
        setSelectedRowData('')
        setTimeout(()=>{
        setShowTelegramGroupLink(false)
        },500)
    }
    //deprecate the popup to show the coach stats
    // const renderCoachStats = () => {
    //     return (
    //         <div className={styles['available-jobs']}>
    //         <Popup
    //         show={showCoachStats}
    //         closePopup={()=>{
    //             setSelectedRowData('')
    //             setShowCoachStats(false)
    //         }}
    //         size='lg'
    //         heading={`Coaches mock stats`}
    //         >
    //         <CoachMockStats id={selectedRowData.id}/>
    //         </Popup>
    //         </div>
    //     )
    // }

    //deprecte the code for showing the UI for telegram
    // const renderGroupLink = () => {
    //     return(
    //         <Popup
    //         show={showTelegramGroupLink}
    //         closePopup={()=>{
    //             setSelectedRowData('')
    //             setShowTelegramGroupLink(false)
    //         }}
    //         size='sm'
    //         heading={`Link Telegram Group URL`}
    //         >
    //         <TelegramGroupLink data={selectedRowData} updateMessage={updateMessage}/>
    //         </Popup>
    //     )
    // }


    const renderProjectInfo = () => {
        const { outcomes, scriptDocuments, quiz } = teleproject || {}
        
        const selectSize = (title) => {
            switch (title) {
                case 'Training Material':
                    return 'sm'
                case 'Audio test':
                    return 'sm'
                case 'Quiz':
                    return 'sm'
                default:
                    return 'lg'
            }
        }

        const renderUi = (title) => {
            switch (title) {
                case 'Training Material':
                    return <div className={styles.scripts}>
                    {scriptDocuments && scriptDocuments.length > 0 ?
                    scriptDocuments.map(({ title, url }) =>
                        <p className={styles.row}>{title} <a href={url} target="_blank" rel="noopener noreferrer"> Script Link</a></p>
                    ) : <p>⚠️ No scripts are attached</p>}
                    </div>
                case 'Call Outcomes':
                    return outcomes && outcomes.length > 0 ? outcomes.map(({ title, description, isWinning }) => {
                        return <div className={styles.outcomes}>
                            <div className={styles.outcome_title}>{title} {isWinning && <img src={YellowStarIcon}/>}</div>
                            <div>{description}</div>
                        </div>
                    }) : <div className={styles.outcomes}>⚠️ No outcomes present</div>
                case 'Quiz':
                    return quizLoading ? <ThreeDotLoader /> :
                    <div className={styles.mock_test}>
                        {
                            quiz && quiz.questions && quiz.questions.length > 0 
                            ?
                            quiz.questions.map((quiz, index) => {
                                return <div>
                                <p className={styles.question}>Q{index+1}. {quiz.questionText}</p>
                                {/* <p><span className={styles.label}>Type :</span> {mock.questionType}</p> */}
                                {
                                    quiz && quiz.options.length > 0 ?
                                    quiz.options.map((opt, i) => {
                                        return <div>{i+1}. {opt.optionText}</div>
                                    })
                                    // <div className={styles.options}>
                                    //     {quiz.options.map(opt => opt.optionText)}
                                    // </div>
                                    : null
                                }
                                </div>
                            })
                            :
                            <div className={styles.no_data}>⚠️ No quiz questions present</div>
                        }
                    </div>
                case 'Audio test':
                    return <div className={styles.mock_test}>
                        {
                            teleproject && teleproject.mockForm && teleproject.mockForm.length > 0 
                            ?
                            teleproject.mockForm.map((mock, index) => {
                                return <div>
                                    <p className={styles.question}>Q{index+1}. {mock.questionText}</p>
                                </div>
                            })
                            :
                            <div className={styles.no_data}>⚠️ No audio test present</div>
                        }
                            <div>

                            </div>
                    </div>
            }
        }
      
        return(
            <Popup
            show={showProjectInfo.status}
            closePopup={()=>{
                setSelectedRowData('')
                setShowProjectInfo({
                    title: '',
                    status: false
                })
            }}
            // size={showProjectInfo.title == ('Training Material' || 'Audio test') ? 'sm' : 'lg'}
            size={selectSize(showProjectInfo.title)}
            heading={showProjectInfo.title}
            >
                { teleprojectLoading ? <ThreeDotLoader /> : <>
                    {
                        renderUi(showProjectInfo.title)
                    }
                </> }
            </Popup>
        )
    }

    const renderRefresherTraining = () => {
        return(
            <Popup
            show={showRefresher}
            closePopup={()=>{
                setSelectedRowData('')
                setShowRefresher(false)
            }}
            size={'sm'}
            heading={`Refresher training`}
            >
                <p><b>Project id :  {selectedRowData && selectedRowData._id}</b></p>
                <RefresherTrainingVideo {...selectedRowData}/>
            </Popup>
        )
    }

    const renderDownloadReport = () => {
        return(
            <Popup
            show={showReport}
            closePopup={()=>{
                setShowReport(false)
                setSelectedRowData('')
            }}
            size='sm'
            heading={selectedRowData.reportType}
             >
            <DownloadReport data={selectedRowData} type={selectedRowData.reportType == 'Project Report' ? true : false}/>
            </Popup>
        )
    }

    const handlePopups = () => {
        //deprecate the coach stats popup
        // if(showCoachStats) return renderCoachStats()
        //deprecate shwing the telegram link popup
        // if(showTelegramGroupLink) return renderGroupLink()
        if(showProjectInfo.status) return renderProjectInfo()
        if(showRefresher) return renderRefresherTraining()
        if(showReport) return renderDownloadReport()
        else
        return null
    }

    let {data, total} = getProjectListApi.data || {}
    const {loading, error} = getProjectListApi

    const { data: teleproject, loading: teleprojectLoading, error: teleprojectError } = getTeleProjectApi || {}
    const { data: quizData, loading: quizLoading, error: quizError } = getQuizDetailsApi || {}

    useEffect(()=>{
        if(error)
        toast(handleErrorMessage(error))
    },[error])

    return(
        <div className={styles.page_wrapper}>
            <Heading text={'Hiring'}/>
            <TabGroup tabs={TABS} color='green-tab-outline' onClick={switchTab} activeTab={activeTab}/>
            <div>
                <Table
                columns={activeTab=='Hiring Projects'?columns:statsColumns}
                data={data}
                totalCount={total}
                limit={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                sortChange={sortChange}
                filterChange = {filterChange}
                searchBy='project id or title'
                isGlobalFilter = {true}
                />
            </div>
            {handlePopups()}
            <ToastContainer draggable={true} />
        </div>
    )
}

export default ProjectsInHiring