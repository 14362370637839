export default function checkIsEmpty(data) {
    const checkDataType = typeof data
    if(checkDataType == 'object'){
        const isArray = Array.isArray(data)
        if(!isArray){
            const isEmpty = Object.values(data).some(value => checkData(value));
            return isEmpty
        }else{
            const isEmpty = data && data.length == 0 ? true : false
            return isEmpty
        }
    }else {
        const isEmpty = checkData(data)
        return isEmpty
    }
  }
  
const checkData = (value) => {
    if (value === null || value === undefined || value === '') {
        return true;
      }
      return false;
}