import React, {useEffect, useState} from 'react'
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getProjectList, getTotalProjects } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import Popup from '../../../uicomponents/popup'
import { DuplicateIcon } from '../../../assets/images'
import CustomTooltip from '../../../components/utilities/ToolTip'
import DuplicateIS from './duplicateIS'
import handleErrorMessage from '../../../helper/handleErrorMessage'

const ScriptBuilder = (props) => {
    const getProjectListApi = useApi(getProjectList)
    const getTotalProjectsApi = useApi(getTotalProjects)

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    const [showScriptDuplicate, setShowScriptDuplicate] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getProjectListApi.request(skip,limit,sort, search)
    },[currentPage, sort, search])

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "30%",
            disableSortBy: true
        },
        {
            Header: 'Language',
            Cell: (cell) => {
                let languages = cell.row.original.languages && Array.isArray(cell.row.original.languages)
                ? cell.row.original.languages.map(lang => lang.name) : ''
                return languages && languages.length > 0 ?
                String(languages).replaceAll(',', ', ') : 'NA'
            },
            width: "20%",
            disableSortBy: true
        },
        {
            Header: '',
            accessor: 'action',
            Cell: (cell) => (
            <div style={{display: 'flex', gap: '8%', justifyContent: 'flex-end'}}>
            <div className={styles.blue_link} 
            onClick={() =>{
                props.history.push({
                    pathname: `${props.location.pathname}/${cell.row.original.id}`,
                    state: {
                        teleprojectAudit: cell.row.original
                    }
                })
            }}
            >
            VIEW
            </div>
            <CustomTooltip 
            placement = 'bottom'
            description = 'Duplicate script'
            component={
            <div className={styles.blue_link} 
            onClick={() =>{
                setSelectedRowData(cell.row.original)
                setShowScriptDuplicate(true)
            }}
            >
            DUPLICATE
            </div>
            }/>
            </div>
            ),
            width: "15%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const renderDuplicateIS = () => {
        return(
            <Popup
            show={showScriptDuplicate}
            closePopup={()=>setShowScriptDuplicate(false)}
            size='sm'
            heading={'Duplicate Script'}
            >
            <DuplicateIS from={selectedRowData}/>
            </Popup>
        )
    }

    const handlePopups = () => {
        if(showScriptDuplicate) return renderDuplicateIS()
        else
        return null
    }

    let {data, total} = getProjectListApi.data || {}
    const {loading, error} = getProjectListApi
 
    useEffect(()=>{
        if(error)
        toast(handleErrorMessage(error))
    },[error])

    return(
        <div className={styles.page_wrapper}>
        <Heading text={'Script list'}/>
        <div>
            <Table
            columns={columns}
            data={data}
            totalCount={total}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            sortChange={sortChange}
            filterChange = {filterChange}
            searchBy='project id or title'
            isGlobalFilter = {true}
            />
        </div>
        {handlePopups()}
        <ToastContainer draggable={true} />
        </div>
    )
}

export default ScriptBuilder