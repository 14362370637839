import ApiActions from "../../../actions/ApiActions";
import generatePayload from "../../../helper/generatePayloadForTable";

const fetchRebuttalList = async(skip, limit, sort, search, dynamicSearch = true) => {
    let payload = generatePayload(skip, limit, sort, search, dynamicSearch)
    return await ApiActions.getRebuttalCallsList(payload)
}

export {
    fetchRebuttalList
}