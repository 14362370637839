import ApiActions from "../../actions/ApiActions";


const getSystemQC = async(projectid) => {
    return await ApiActions.getTeleProject(projectid)
}

const getCallerQCdata = async(payload) => {
    return await ApiActions.getCallerQCdata(payload)
}

export{
    getSystemQC,
    getCallerQCdata
}