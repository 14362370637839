import React, { useState, createContext, useEffect, useContext, useRef } from 'react'


export const BulkActionContext = createContext();

export const BulkActionProvider = (props) => {

    const OFFER_LETTER = 'bulk_upload_offer_letter'
    const CERTIFY_AGENT = 'bulk_certify_caller'
    const ADD_BASE_PAY = 'bulk_upload_base_pay'
    const MATCH_AGENT = 'bulk_match_agent'
    const BULK_MODERATION_MANUAL_GRADES = 'bulk_moderation_manual_grades'

    const actionsList = [
        { label: 'Bulk Moderation with manual grades', value: BULK_MODERATION_MANUAL_GRADES },
        { label: 'Bulk Certify', value: CERTIFY_AGENT },
        { label: 'Bulk Upload Offer Letter URL', value: OFFER_LETTER },
        { label: 'Bulk Upload Base Pay', value: ADD_BASE_PAY },
        { label: 'Bulk Match', value: MATCH_AGENT }
    ]

    const [isComplete, setIsComplete] = useState(0)
    const [currentAction, setCurrentAction] = useState({
        label: '',
        value: ''
    })

    const circularProg = useRef('') //ref to handle the circle load
    const checkComplete = useRef('') //ref to handle the complete checkmark

    const providerValue = {
        isComplete, setIsComplete,
        circularProg,
        checkComplete,
        CERTIFY_AGENT, OFFER_LETTER, ADD_BASE_PAY, MATCH_AGENT, BULK_MODERATION_MANUAL_GRADES,
        actionsList,
        currentAction, setCurrentAction
    }

    return (
        <BulkActionContext.Provider value={providerValue}>
            {props.children}
        </BulkActionContext.Provider>
    )
}