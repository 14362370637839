import React from 'react'
import {AlertIcon} from '../../../assets/images'
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import FutworkButton from '../../../uicomponents/button/Button'

const CheckBasePayBeforeMatching = (props) => {
    return(
        <div className={styles.earning_alert}>
        <img src={AlertIcon} />
        <div className={styles.bold_text}>Base pay not defined</div>
        <div className={styles.description}>
            Defining caller's base pay is essential before matching them to projects
        </div>
        <FutworkButton buttonStyle={'primary--solid'} buttonSize={'sm'}
        onClick={()=> {
            props.setShowEarningAlert(false)
            props.setShowBasePayEdit(true)
        }}>Define base pay</FutworkButton>
        </div>
    )
}

export default CheckBasePayBeforeMatching