import React from 'react';
import ApiActions from '../../../actions/ApiActions';
import Switch from '../../utilities/Switch';
import FrappModal from '../../utilities/FrappModal';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import FrappButton from '../../utilities/FrappButton';
import pick from 'lodash.pick';
import Panel from '../../../uicomponents/panel'

import ProjectTitle from '../../common/ProjectTitle'

class WildCardTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTargeting: false,
            targetingFormData: {
                idle: false
            },
            formData: {
                active: true
            },
            wildcard: [],
            skip: 0

        };
    }

    componentDidMount() {
        //pre populate the targeting fields and toggle tageting switch
        this.fetchData();
        this.listenToScroll();
    }
    listenToScroll = () => {
        document.addEventListener("scroll", this.checkDataToBeLoaded);
    };
    checkDataToBeLoaded = () => {
        var scrolled = window.scrollY;
        var documentHeight = document.body.scrollHeight;
        var initialWindowHeight = window.innerHeight;
        var fetchingLazy = this.state.fetchingLazy;
        var skip = this.state.skip;

        const cond3 = scrolled > 0;
        //const cond2 = !fetchingLazy;

        const cond4 = scrolled + initialWindowHeight >= documentHeight - 300;
        if (cond4 && cond3) {
            this.setState({ fetchingLazy: true }, this.fetchWildCard);
        }
    };

    fetchWildCard = () => {
        this.fetchData();
    }
    fetchData = () => {
        let skip = this.state.skip;
        const setState = this.setState.bind(this);
        let wildcard = this.state.wildcard;
        ApiActions.getData("/wildcard", {
            _vortex: true,
            skip,
            limit: 50,
            where: {
                "userRelated": { "$nin": [true] }
            },
            sort: { "createdAt": -1 },

        })
            .then(resp => {
                var newList = wildcard.concat(resp);
                skip = skip + 50;

                this.setState({ wildcard: newList, skip })
            }).then(setState);
    }

    render() {
        return (
            <div className='tools'>
                <div className='container'>
                <div className="wildcard-tool">
                <ProjectTitle title={`Wild Card Tool`} goBack={() => this.props.history.push("/tools")} />
                {this.renderList()}
                {this.renderForm()}
            </div>
            </div>
            </div>
        )
    }

    renderForm = () => {
        let { formData, targetingFormData } = this.state;

        return (
            <FrappModal className="wildcard-form-modal" ref={(c) => this.modal = c} >
                <h3>{formData.id ? 'Edit' : 'Add'} Wildcard</h3>
                <form onSubmit={this.submitWildCard} >
                    <FormGroup>
                        <ControlLabel>External (Optional)</ControlLabel>
                        <FormControl value={formData.external || ""} onChange={this.setFormData} name="external" type="url" placeholder="Enter the link where you want send the students" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>App Banner</ControlLabel>
                        <FormControl value={formData.image || ""} onChange={this.setFormData} name="image" required type="url" placeholder="Banner image for the app" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Web Banner</ControlLabel>
                        <FormControl value={formData.webImage || ""} onChange={this.setFormData} name="webImage" required type="url" placeholder="Banner image for the web" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Deeplink (Optional)</ControlLabel>
                        <FormControl value={formData.deeplink || ""} onChange={this.setFormData} name="deeplink" type="url" placeholder="Enter deeplink if any" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Featured Position (Optional)</ControlLabel>
                        <FormControl value={formData.featured || ""} onChange={this.setFormData} name="featured" type="number" placeholder="Enter position of the card in the list" />
                    </FormGroup>
                    <Panel title={<span className={styles.sub_heading}>Target criteria (Optional)</span>}>
                        <div><h4>Idle&nbsp;&nbsp;&nbsp;<Switch onToggle={() => this.setData({'name': 'idle'})} checked={targetingFormData.idle} name="active" /></h4></div>
                    </Panel>
                    <FrappButton type="submit" className="submit" ref={(c) => this.submitBtn = c} >Submit</FrappButton>
                </form>
            </FrappModal>
        )
    }

    submitWildCard = (e) => {
        e.preventDefault();
        let { targetingFormData, formData } = this.state;
        this.postWildCard({ ...formData, targetCriteria: targetingFormData })
    }

    setData = (param) => {
        var { targetingFormData } = this.state;
        var { name } = param;

        targetingFormData[name] = !targetingFormData[name];
        this.setState({ targetingFormData })
    }

    renderList = () => {
        let { wildcard } = this.state;
        if (!wildcard) return;
        return (
            <div key={wildcard.id} className="list">
                {wildcard.map(this.renderCard)}
                {this.renderAddButton()}
            </div>
        )
    }

    renderAddButton = () => {
        return (
            <div onClick={() => { this.modal.toggle(true); this.setState({ formData: {} }) }} className="add-button">
                Add wilcard
            </div>
        )
    }

    renderCard = (wildcard) => {
        return (
            <div key={wildcard.id} className="wildcard-wrapper" >
                <div className={`wildcard ${wildcard.active ? '' : 'blur'}`} >
                    <img src={wildcard.image} alt="" />
                </div>
                <div className="actions">
                    <span>
                        <img onClick={() => { this.setWildCardData(wildcard) }} className="edit" src={require("../../../assets/images/edit-blue.svg")} alt="" />
                    </span>
                    <span>
                    Active&nbsp;&nbsp;&nbsp;
                    <Switch onToggle={this.setActiveData} checked={wildcard.active} name="active" data-id={wildcard.id} />
                    </span>
                </div>
            </div>
        )
    }

    setWildCardData = (wildcard) => {

        var { targetingFormData } = this.state

        if (wildcard.targetCriteria) {
            targetingFormData = wildcard.targetCriteria
        }

        let formData = pick(wildcard, ["active", "deeplink", "external", "featured", "id", "image", "webImage"])

        this.setState({ formData: formData, targetingFormData: targetingFormData, showTargeting: false });

        this.modal.toggle(true)
    }

    setFormData = (e) => {
        let { formData } = this.state;
        let { name, value } = e.target;
        formData[name] = value.trim();
        this.setState({ formData })
    }

    setActiveData = (e) => {
        let { wildcard } = this.state;
        let { attributes } = e.target;
        let id = attributes['data-id'].value;
        let currentWildCard = wildcard.filter(w => w.id === id)[0];
        currentWildCard.active = !currentWildCard.active
        wildcard = wildcard.map(w => {
            if(w.id === id) {
                w.active = currentWildCard.active
            }
            return w
        })

        this.setState({ wildcard }, () => this.postWildCard(currentWildCard));
    }

    postWildCard = (wildcard) => {
        //pre process the data before submission
        this.submitBtn && this.submitBtn.startLoading();
        if (wildcard.external === "")
            wildcard.external = null
        if (wildcard.id) {
            delete wildcard.updatedAt;
            delete wildcard.createdAt;
            ApiActions.putData(`/wildcard/${wildcard.id}`, { ...wildcard, _vortex: true })
                .then((resp) => this.formSuccess(resp.data))
                .catch(error => { this.modal.toggle(false);
                    this.submitBtn && this.submitBtn.stopLoading();
                    alert('Something went wrong')
                })
                .finally(() => {
                    let { targetingFormData }  = this.state
                    targetingFormData = {
                        idle: false
                    }
                    this.setState({targetingFormData})
                })
        } else {
            wildcard.active = true;
            ApiActions.postData("/wildcard", { ...wildcard, _vortex: true })
                .then((resp) => this.formSuccess(resp.data))
                .catch(error => { this.modal.toggle(false);
                    this.submitBtn && this.submitBtn.stopLoading();
                    alert('Something went wrong')
                })
                .finally(() => {
                    let { targetingFormData }  = this.state
                    targetingFormData = {
                        idle: false
                    }
                    this.setState({targetingFormData})
                })
        }
    }

    formSuccess = (newWildCard) => {

        this.modal.toggle(false);
        this.submitBtn && this.submitBtn.stopLoading();
        let { wildcard } = this.state;
        let ids = wildcard.map(s => s.id);
        const matchedIndex = ids.indexOf(newWildCard.id)
        if (matchedIndex >= 0) {
            wildcard[matchedIndex] = newWildCard
        } else {
            wildcard.push(newWildCard)
        }

        this.setState({ wildcard })
        alert("Wildcard was updated successfully!")
    }
}


export default WildCardTool;