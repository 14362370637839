import React, { useEffect } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import CallerPreference from './callerPreference'
import { getCallerQCData, getCallerTrainingData } from './service'
import useApi from '../../../hooks/useApi'

const CallerInfo = (props) => {
    const { rowData, callerPreference } = props
    let { manualGradeResult, testAudioUrl} = rowData
    let autoScore = manualGradeResult && manualGradeResult.score || ''

    const getCallerQCDataApi = useApi(getCallerQCData)
    const getCallerTrainingDataApi = useApi(getCallerTrainingData)

    useEffect(()=>{
        const payload = {
            user: rowData.id
        }
        if(callerPreference){
            getCallerTrainingDataApi.request(payload)
            getCallerQCDataApi.request(payload)
        }
    },[])

    let qa = {
        qcpass: '0',
        outofqcpass: '0',
        iqc: []
    }

    const capitalize = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join("");

    const formatKey = (key) => {
        if(key !== 'scriptPreparedness')
        return capitalize(key);
        else return 'Script Preparedness'
    }

    if(getCallerQCDataApi.data){
        qa = {
            qcpass: getCallerQCDataApi.data.qcpass,
            outofqcpass: getCallerQCDataApi.data.outofqcpass,
            iqc: getCallerQCDataApi.data.iqc
        }
    }

    if(getCallerTrainingDataApi.data){
        autoScore = getCallerTrainingDataApi.data && getCallerTrainingDataApi.data.manualGradeResult
        && getCallerTrainingDataApi.data.manualGradeResult.score
        testAudioUrl = getCallerTrainingDataApi.data && getCallerTrainingDataApi.data.testAudioUrl
    }

    return(
        <div className={styles['caller-info-wrapper']}>
            <div className={styles.audio}>
                <audio controls src={testAudioUrl}></audio>
            </div>
                <div className={styles.score}>
                    Auto Score : {autoScore ? autoScore : 'NA'}
                </div>
                {typeof manualGradeResult == Object && <div className={styles['tabular-data']}>
                    {manualGradeResult && Object.entries(manualGradeResult).map(([key,value]) => {
                        return (
                        key !== 'score' ? 
                        <div className={styles['tb-row']}>
                        <div>{formatKey(key)}</div>
                        <div>{value}</div>
                        </div> : null
                        )
                    })}
                </div>}
                {
                    callerPreference &&
                    <>
                    <div className={styles['tb-row']}>
                     <div>Total QC passed:</div>
                     <div className={styles.score}>{qa.qcpass} / {qa.outofqcpass}</div>
                    </div>
                    <div className={styles['tb-row']}>
                    <div>Last iqc score soft skills:</div>
                    <div className={styles.score}>{qa.iqc && qa.iqc.length > 0 ? qa.iqc[0].softSkills && qa.iqc[0].softSkills.score : 0}</div>
                    </div>
                    <div className={styles['tb-row']}>
                    <div>Last iqc score script check:</div>
                    <div className={styles.score}>{qa.iqc && qa.iqc.length > 0 ? qa.iqc[0].scriptCheck && qa.iqc[0].scriptCheck.score : 0}</div>
                    </div>
                    </>
                }
                {callerPreference ? <CallerPreference rowData={rowData} callerPreference={callerPreference}/> : null}
        </div>
    )
}

export default CallerInfo