import moment from 'moment'

const getPaymentCycle = () => {
    let now = new Date();
	let startDate;
	if([0,1,2,3,4].includes(now.getDay())){
		let lastThursday = (-7 + 4) - now.getDay(); // 7 = number of days in week, 4 = the thursdayIndex (0= sunday)
		let currentMs = now.getTime();
		startDate = new Date(currentMs + (lastThursday * 24 * 60 * 60 * 1000));
	}
	else{
		let lastThursday = 4 - now.getDay(); // 4 = the thursdayIndex (0= sunday)
		let currentMs = now.getTime();
		startDate = new Date(currentMs + (lastThursday * 24 * 60 * 60 * 1000));
	}

	let endDate = new Date(startDate)
	endDate.setDate(startDate.getDate()+6)
	
    return {
        startDate: moment(startDate).format('YYYY-MM-DD'), 
        endDate: moment(endDate).format('YYYY-MM-DD')
    }
}

export default getPaymentCycle