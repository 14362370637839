import React, { useState, createContext, useEffect, useContext } from 'react'
import { getAllExophones } from './service'
import useApi from "../../../hooks/useApi";
import Tab from '../../../uicomponents/tab/tab'
import withTabGroup from '../../../hocs/withTabGroup'

const networkOperator = [
    'Airtel', 'Jio', 'Vi', 'BSNL'
]

const exoRegion = [
    {
        label: 'Karnataka',
        value: 'KA'
    },
    {
        label: 'Madhya Pradesh',
        value: 'MP'
    },
    {
        label: 'Maharashtra',
        value: 'MH'
    },
    {
        label: 'Rajasthan',
        value: 'RJ'
    },
    {
        label: 'Delhi',
        value: 'DL'
    },
    {
        label: 'Andra Pradesh',
        value: 'AP'
    },
    {
        label: 'Gujarat',
        value: 'GJ'
    },
    {
        label: 'Kerala',
        value: 'KL'
    },
    {
        label: 'Mumbai',
        value: 'MU'
    },
    {
        label: 'Tamilnadu',
        value: 'TN'
    },
    {
        label: 'West Bengal',
        value: 'WB'
    }
]

const whiteListingOptions = [
    {
        label: 'YES',
        value: 'whiteListed'
    },
    {
        label: 'NO',
        value: 'not_whiteListed'
    }
]

const projectTabs = ['Linked Projects', 'All Projects']

export const ExoContext = createContext();

export const ExoProvider = (props) => {
    const exophonesListApi = useApi(getAllExophones)

    const [exotelAcc, setExotelAcc] = useState([])
    const [activeTab, setActiveTab] = useState({})
    const [viewExophones, setViewExophones] = useState({
        status: false,
        data: ''
    })
    const [createPool, setCreatePool] = useState({
        status: false,
        data: {}
    })
    const [deletePool, setDeletePool] = useState({
        status: false,
        data: {}
    })
    const [deleteExophone, setDeleteExophone] = useState({
        status: false,
        data: {}
    })

    const [projectList, setProjectList] = useState({
        status: false,
        data: {}
    })
    const [query, setQuery] = useState('')

    const [projectActiveTab, setProjectActiveTab] = useState(projectTabs[0])

    const TabGroup = withTabGroup(Tab, 'group');

    const goToExophoneList = (data) => {
        setViewExophones({
            status: true,
            data
        })
    }

    const goToPooList = () => {
        setViewExophones({
            status: false,
            data: ''
        })
    }

    const renderProjectTabs = () => {
        return <TabGroup tabs={projectTabs} color={'navy-blue-tab-bottom-border'}
            onClick={(value) => setProjectActiveTab(value)} activeTab={projectActiveTab}
        />
    }

    const providerValue = {
        exotelAcc, setExotelAcc,
        activeTab, setActiveTab,
        viewExophones, setViewExophones,
        goToExophoneList,
        exophonesListApi,
        createPool, setCreatePool,
        deletePool, setDeletePool,
        networkOperator,
        exoRegion,
        whiteListingOptions,
        goToPooList,
        projectList,
        setProjectList,
        projectActiveTab,
        setProjectActiveTab,
        renderProjectTabs,
        projectTabs,
        deleteExophone, setDeleteExophone,
        query, setQuery
    }

    return (
        <ExoContext.Provider value={providerValue}>
            {props.children}
        </ExoContext.Provider>
    )
}