import React, { useState, useContext, useEffect, useRef } from 'react'
import Table from '../../common/Table'
import ApiActions from '../../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import Chart from "react-apexcharts";
import moment from "moment";
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import capitalise from "../../../helper/Capitalise";
import ThreeDotLoader from "../../utilities/ThreeDotLoader"


const HiringDashboard = (props) => {
    const [isTableLoading, setTableLoading] = useState(false)
    const [overallCoachStats, setOverallCoachStats] = useState([])
    const [mockDueStats, setMockDueStats] = useState({
        mocksDueToday: '',
        mocksDueTomorrow: ''
    })
    const [mockAttemptStats, setMockAttemptStats] = useState('')
    const [columnChartData, setColumnChartData] = useState('')
    const [rawGraphData, setRawGraphData] = useState('')
    const [timeInterval, setTimeInterval] = useState('daily')
    const [coachStatsInterval, setCoachStatsInterval] = useState('daily')
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString('en-GB', {
        month: '2-digit', day: '2-digit', year: 'numeric'
    }))
    const [isColumnGrapTriggered, setIsColumnGrapTriggered] = useState(false)
    const [isLoader ,setIsLoader] = useState(false)
    const buttonRef = useRef('')

    const headerStyle = {
        backgroundColor: '#E7FAF2',
        fontSize: '12px',
        fontWeight: 'bold',
        boxShadow: 'none'
    }

    const cellStyle = {
        fontSize: '12px',
        color: 'black'
    }

    useEffect(() => {
        coachMockCallStats()
        mocksDueStats()
    }, [])

    const startOfMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    const coachMockCallStats = () => {
        setTableLoading(true)
        let from, to;
        setCoachStatsInterval(curr => {
            if (curr == 'daily') {
                from = currentDate
                to = currentDate
                callApi(from, to)
            } else if (curr = 'monthly') {
                from = startOfMonth(new Date()).toLocaleDateString('en-GB', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                })
                to = currentDate
                callApi(from, to)
            }
            return curr
        })

        const callApi = (from, to) => {
            ApiActions.coachMockCallStats(from, to).then(resp => {
                var newData = resp;
                setOverallCoachStats([...newData])
                setTableLoading(false)
            }).catch(err => {
                console.error(err)
                toast('Error')
                setTableLoading(false)
            });
        }
    }

    const mocksConducted = (type) => {
        setIsLoader(true)
        if (type == undefined) {
            const sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - 7));
            let from = moment(sevenDaysBeforeDate).format("DD/MM/YYYY")
            let to = currentDate
            ApiActions.mocksConducted(from, to).then(resp => {
                const data = resp.map(({ id, approvedData, rejectedData, remockData }) => {
                    return {
                        date: id,
                        approvedData,
                        rejectedData,
                        remockData
                    }
                })
                setRawGraphData(data)
                let sortedData = sortByDate(data)
                const chartData = returnDailyData(sortedData)
                setColumnChartData(chartData)
                setIsLoader(false)
            })
        } else {
            let from = startOfMonth(new Date()).toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' })
            let to = currentDate
            ApiActions.mocksConducted(from, to).then(resp => {
                const data = resp.map(({ id, approvedData, rejectedData, remockData }) => {
                    return {
                        date: id,
                        approvedData,
                        rejectedData,
                        remockData
                    }
                })
                setRawGraphData(data)
                setIsLoader(false)
                let sortedData = sortByDate(data)
                const chartData = returnMonthlyData(sortedData)
                setColumnChartData(chartData)
                setIsLoader(false)

            })
        }
    }

    const mocksDueStats = () => {
        let to = currentDate
        ApiActions.mockDueStats('', to).then(resp => {
            setMockDueStats({
                mocksDueToday: resp.mocksDueToday,
                mocksDueTomorrow: resp.mocksDueTomorrow
            })
        })
        ApiActions.mockAttemptStats('', to).then(resp => {
            setMockAttemptStats(resp.uniqueuMocksAttempted)
        })
    }

    const changeGraphOnTimeInterval = (type) => {
        if (type == 'daily') {
            mocksConducted()
        } else {
            mocksConducted(type)
        }
    }

    const returnDailyData = (data) => {
        let x_axis_arr_daily = data.map((item) => moment(item.date).format('L')).map(each => moment(each).format("DD/MM/YYYY"))
        let groupedApprovedData = data.map((item) => item.approvedData ? item.approvedData.count : undefined)
        let groupedRejectedData = data.map((item) => item.rejectedData ? item.rejectedData.count : undefined)
        let groupedRemockData = data.map((item) => item.remockData ? item.remockData.count : undefined)

        let finalData = {
            x_axis: x_axis_arr_daily,
            approvedData: groupedApprovedData.map(approved => approved === undefined ? 0 : approved),
            rejectedData: groupedRejectedData.map(rejected => rejected === undefined ? 0 : rejected),
            remockData: groupedRemockData.map(remock => remock === undefined ? 0 : remock)
        }
        return finalData
    }

    const returnMonthlyData = (data) => {
        const groupByMonths = (dates) => {
            return dates.reduce((acc, obj) => {
                const key = moment(obj.date).format("MMM YY")
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(obj)
                return acc;
            }, {});
        }

        //group dates by month
        let collatedMonthsObj = groupByMonths(data)

        //get the total count of each type of mock for a month
        const getTotalCountForTypeOfMock = (data, type) => {
            return data.reduce((accCount, obj) => {
                let newCount = obj[type] ? obj[type].count : 0
                return accCount + newCount
            }, 0)
        }

        let countOfAllTypesOfMock = Object.keys(collatedMonthsObj).map((obj) => {
            let part = collatedMonthsObj[obj]
            let approvedCount = getTotalCountForTypeOfMock(part, 'approvedData')
            let rejectedCount = getTotalCountForTypeOfMock(part, 'rejectedData')
            let remockCount = getTotalCountForTypeOfMock(part, 'remockData')
            return {
                month: obj,
                approvedData: approvedCount,
                rejectedData: rejectedCount,
                remockData: remockCount
            }
        });

        let x_axis_arr_monthly = Object.keys(collatedMonthsObj)
        let groupedApprovedData = countOfAllTypesOfMock.map((item) => item.approvedData)
        let groupedRejectedData = countOfAllTypesOfMock.map((item) => item.rejectedData)
        let groupedRemockData = countOfAllTypesOfMock.map((item) => item.remockData)

        let finalData = {
            x_axis: x_axis_arr_monthly,
            approvedData: groupedApprovedData,
            rejectedData: groupedRejectedData,
            remockData: groupedRemockData
        }
        return finalData
    }

    const sortByDate = (arr) => {
        arr.sort(function (a, b) {
            return Number(new Date(a.date)) - Number(new Date(b.date));
        });
        return arr;
    }

    const columns = [
        {
            title: "Coach name",
            field: "name",
            filtering: false,
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: "Mocks Due",
            field: "mocksDueCount",
            filtering: false,
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: 'Mocks Overdue',
            field: "mocksOverDueCount",
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: 'Mocks Attempted',
            field: 'mocksAttemptedCount',
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: "Mocks Completed",
            render: ({ mocksApprovedCount, mocksRejectedCount, mocksRemockCount }) => {
                return mocksApprovedCount + mocksRejectedCount + mocksRemockCount
            },
            filtering: false,
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: "Mocks Approved",
            field: 'mocksApprovedCount',
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: "Mocks Rejected",
            field: "mocksRejectedCount",
            filtering: false,
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        },
        {
            title: "Remock",
            field: "mocksRemockCount",
            filtering: false,
            cellStyle: cellStyle,
            headerStyle: headerStyle,
        }
    ]

    const propsForTable = {
        data: overallCoachStats,
        columns: columns,
        title: <span className='material-table-title'></span>,
        isTableLoading: isTableLoading,
        paginationType: 'client',
        filtering: false,
        search: false,
        exportButton: false
    }

    const series = [{
        name: 'Approved',
        data: columnChartData.approvedData
    }, {
        name: 'Rejected',
        data: columnChartData.rejectedData
    }, {
        name: 'Remock',
        data: columnChartData.remockData
    },
    ]

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false,
        },
        fill: { colors: ['#11D17E', '#8ADDDD', '#6374D3'] },
        colors: ['#11D17E', '#8ADDDD', '#6374D3'],
        responsive: [{
            breakpoint: 480,
            options: {
                // legend: {
                //   position: 'bottom',
                //   offsetX: -10,
                //   offsetY: 0
                // }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                endingShape: 'rounded'
                // borderRadius: 4
            },
        },
        xaxis: {
            //   type: 'datetime',
            categories: columnChartData.x_axis
        },
        // legend: {
        //   position: 'right',
        //   offsetY: 40
        // },
        fill: {
            opacity: 1
        }
    }


    const renderPage = () => {
          {/* // To change the loading indicator behavior */}
        // const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return (
            <div className='mock-call-stats-wrapper'>
                <div className='stats-section'>
                    {/* mock call conducted graph and mock stats section */}
                    <div className='bar-graph'>
                        <div className='mock-call-conducted'>
                            <div className="chart__header">
                                <img src={require("../../../assets/images/call_icon.svg")} />
                                <h4> Mocks Conducted : </h4>

                                <div className="chart__dropdown">
                                    {columnChartData ? <img className='refresh-icon' src={require("../../../assets/images/Icons_refresh.svg")} onClick={() => changeGraphOnTimeInterval(timeInterval)} /> : null}
                                    {columnChartData ? <DropdownButton
                                        bsSize="small"
                                        title={capitalise(timeInterval)}
                                        id="dropdown-size-small"
                                    >
                                        <MenuItem eventKey="daily" onSelect={e => { setTimeInterval(e); changeGraphOnTimeInterval(e) }}>Daily</MenuItem>
                                        <MenuItem eventKey="monthly" onSelect={e => { setTimeInterval(e); changeGraphOnTimeInterval(e) }}>Monthly</MenuItem>
                                    </DropdownButton> : null}
                                </div>
                            </div>
                          

                            <div className={"chart__div"}>
                                {columnChartData ?
                                    rawGraphData.length > 0 ?
                                            isLoader ? <div className='load-btn-wrapper'><ThreeDotLoader/></div>
                                            : <Chart options={options} series={series} type="bar" height={280} /> 
                                        
                                    : <div className='load-btn-wrapper'><p>No data available</p></div>
                                    : <div className='load-btn-wrapper'>
                                        {!isColumnGrapTriggered ? <p className='load-graph' onClick={() => mocksConducted()}>Load graph</p> : null}
                                    </div>
                                }
                            </div>

                        </div>

                        <div className="mock_stats__wrapper">
                            <div className="stats__box flex highlighted">
                                <div className="stats__heading">
                                    <h4>Mocks Due Today :</h4>
                                </div>
                                <div className="stats__numbers">
                                    <h4>{mockDueStats.mocksDueToday ? mockDueStats.mocksDueToday : '0'}</h4>
                                </div>
                            </div>
                            <div className="stats__box flex">
                                <div className="stats__heading">
                                    <h4>Mocks Due Tomorrow :</h4>
                                </div>
                                <div className="stats__numbers">
                                    <h4>{mockDueStats.mocksDueTomorrow ? mockDueStats.mocksDueTomorrow : '0'}</h4>
                                </div>
                            </div>
                            <div className="stats__box flex">
                                <div className="stats__heading">
                                    <h4>Mocks Attempted :</h4>
                                </div>
                                <div className="stats__numbers">
                                    <h4>{mockAttemptStats ? mockAttemptStats : '0'}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end of mock call conducted graph and mock stats section */}

                    {/* overall coach stats section */}
                    <div className='coach_stats'>
                        <div className="coach_stats_header">
                            <h4>Overall Coach Stats</h4>
                            <div>
                                <DropdownButton
                                    bsSize="small"
                                    title={capitalise(coachStatsInterval)}
                                    id="dropdown-size-small"
                                >
                                    <MenuItem eventKey="daily" onSelect={e => { setCoachStatsInterval(e); coachMockCallStats() }}>Daily</MenuItem>
                                    <MenuItem eventKey="monthly" onSelect={e => { setCoachStatsInterval(e); coachMockCallStats() }}>Monthly</MenuItem>
                                </DropdownButton>
                            </div>
                        </div>
                        <Table {...propsForTable} />
                    </div>
                    {/* end of overall coach stats section */}
                </div>
            </div>
        )
    }
    return renderPage()
}

export default HiringDashboard