import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import CreateQuiz from "../../pages/CreateQuiz";
import ApiActions from '../../../actions/ApiActions';
import FrappModal from "../../utilities/FrappModal";
import FrappButton from '../../utilities/FrappButton';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import MaterialTable, { MTablePagination } from "material-table";
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import ProjectTitle from '../../common/ProjectTitle';
import QuizListing from '../../../pages/tools/quizListing';
class QuizFinal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mission: null,
            missionId: "",
            missionData: [],
            missionModal: {},
            formData: {},
            questions: [],
              skip:0,
            quiz:{},
        }
    }


    componentDidMount() {
          let {skip}=this.state;
         let query={
            sort: { createdAt: -1 }
        }
        // ApiActions.getQuizList(query,skip).then(resp=>{
        //      skip=skip+50;
        //     this.setState({quizList:resp,quiz:{},skip})
        //  })
    }
     fetchData=()=>{
       let {skip,quizList}=this.state;
       let newData;
       let query={
            sort: { createdAt: -1 }
        }
       ApiActions.getQuizList(query,skip).then(resp=>{
        skip=skip+50;
        newData=quizList.concat(resp)
        this.setState({skip,quizList:newData})       
    
    })
   }
     componentWillUnmount() {
        this._isMounted = false;
         

    }
    render() {
        var { quizList,quiz } = this.state;
        return (
            <div className='tools'>
                <div className='quiz-listing'>
                <ProjectTitle title={`Quiz `} goBack={() => this.props.history.push("/tools")} />
                <div className="quiz-wrapper">
                      <FrappButton className="submit" id="create-new" ref={(c) => this.createNewBtn = c} handler={() => this.createNewQuiz()}  > Create </FrappButton>
                    {this.renderQuizTable()}
                    {this.state.createQuiz?this.renderForm():null}
                    {this.quizNameModal()}
                        <ToastContainer draggable={true} />
                </div>
                </div>
            </div>
        )
    }
    onMissionSelect = (mission) => {
        this.setState({ mission })

    }
    renderForm = () => {
       return (<CreateQuiz />)
    }


quizNameModal=()=>{
        let quiz = this.state.quiz;
        return (
            <FrappModal
                onCloseButton={true}
                className="quiz-modal"
               // show={this.state.showQuiz}
                ref={c => (this.namemodal = c)}
                closeModal={() => this.namemodal.toggle(false)}
            >
               {this.updateQuizName()}
            </FrappModal>
        )
   
}
updateQuizName=()=>{
    let quiz=this.state.quiz;
      let options=[
           { label: "Mission",value:"mission"},
           { label: "Teleproject",value:"teleproject"},
           { label: "Training",value:"training"}
        ]
    return( 
               <form onSubmit={(e)=>this.changeQuizName(e)}>
                    <div>
                         <FormGroup>
                                <ControlLabel>Quiz Name</ControlLabel>
                                <FormControl value={quiz.name || ""} onChange={this.setQuizName} name="name" required type="text" placeholder="Enter the name of Quiz" />
                            </FormGroup>
                             <FormGroup>
                                <ControlLabel>Category</ControlLabel>
                                <Dropdown options={options} 
                                  onChange={(val)=>this.setQuizCategory(val)} 
                                   value={quiz.category} 
                                   placeholder="Select an option" />
                            </FormGroup>
                             <FrappButton type="submit" className="submit" ref={(c) => this.submitBtnQuizName = c} >Submit</FrappButton>
                    </div>
               </form>

                    )
}
 setQuizName=(e)=>{
         let quiz = this.state.quiz;
         quiz.name=e.target.value;
         this.setState({quiz})
    }
 setQuizCategory=(val)=>{
        let { quiz } = this.state;
       quiz.category=val.value;

        this.setState({ quiz })
    }
changeQuizName=(e)=>{
    e.preventDefault();
    this.submitBtnQuizName.startLoading();
    let quiz=this.state.quiz;
    let dataToSend={
        "name":quiz.name,
        "category":quiz.category
    }
    ApiActions.changeQuizName(quiz.id,dataToSend).then(resp=>{
        this.submitBtnQuizName.stopLoading();
       toast("Quiz updated")
       this.namemodal.toggle(false)
    })
    }
   renderQuizTable=()=>{
        let {quizList}=this.state;
        
        return (
            <div>
                <QuizListing createNewQuiz={this.createNewQuiz} handleClickName={this.handleClickName}/>
            </div>
        )
    }
    
    createNewQuiz = (quiz) => {
        this.setState({createQuiz:true})
        if(quiz&&quiz.id){
              this.props.history.push({
                pathname: `/update-quiz/${quiz.id}`,
                state: {
                    formData: quiz,
                    createQuiz: false
                }
            })
        }
         else {
            this.props.history.push({
                pathname: `/create-quiz`,
                state: {
                    createQuiz: true
                }
                })
           
        }
        // if(quiz&&quiz.id)
        //     ApiActions.getQuiz(quiz.id).then(resp => {
        //         this.setState({ questions: [...resp.questions],quiz:quiz}, this.modal.toggle(true))

        //     })
        // else 
        // this.setState({ quiz:{}}, this.modal.toggle(true))
    }



      handleClickName = (quiz) => {
          if(quiz&&quiz.id)
          this.setState({ quiz:quiz}, this.namemodal.toggle(true))
    }

}
export default withRouter(QuizFinal) 