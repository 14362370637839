import React, { useEffect, useState } from "react";
import styles from '../../assets/stylesheets/pages/project/project.module.scss'
import useApi from "../../hooks/useApi";
import ApiActions from "../../actions/ApiActions";
import Table from '../../uicomponents/table/Table'

const ParameterWiseDefects = ({payload}) => {
    const getParameterWiseDefectsApi = useApi(ApiActions.getParameterWiseDefects)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getParameterWiseDefectsApi.request(payload)
    }, [])

    let {data, loading, error} = getParameterWiseDefectsApi

    const columns = [
        {
            Header: "Parameter wise defects %",
            accessor: 'behaviour',
            Cell: ({value}) => ( value ? value : 0),
            width: '60%',
            disableSortBy: true
        },
        {
            Header: "",
            accessor: 'defectPercent',
            Cell: ({value}) => {
                return <div>
                <label> {value}%: <progress max="100" value={value}>{value}%</progress></label>
                </div>
            },
            disableSortBy: true
        }
    ]

    return(
        <div className={styles.parameter_wise_defects_wrapper}>
            <Table 
            columns={columns}
            data={data}
            type = 'client'
            loading={loading}
            height = '50'
            />
        </div>
    )
}

export default ParameterWiseDefects