import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import ApiActions from '../../actions/ApiActions';
import jwt_decode from "jwt-decode";

const MagicLink = (props) => {
    const search = props.location.search;
    const uniqueId = props && props.location && props.location.pathname && props.location.pathname.split('/').length > 0 ?
    props.location.pathname.split('/')[2]
    : ''
    const token = new URLSearchParams(search).get('token');
    

    useEffect(() => {
        const isTokenPresent = checkForToken(uniqueId)
        return isTokenPresent ? loginVerifyWithToken(uniqueId) : null
    }, [])

    const checkForToken = (token) => {
        if (!token) {
            toast(`Token ID is missing`)
            return false
        }
        return true
    }

    //token verification triggered
    const loginVerifyWithToken = (token) => {
        ApiActions.loginVerification(token).then(resp => {
            var authToken = resp.headers['x-authorization-token'];
            //if token verification is success move to the next step of login to get the authToken for control and vortex
            localStorage.setItem('authToken', authToken)
        }).then(response => {
            return ApiActions.getUserName(jwt_decode(localStorage.authToken).base)
        }).then((response) => {
            localStorage.setItem('userName', `${response.firstname} ${response.lastname}`)
            window.location.href = '/home'
        }).catch(err => {
            console.error(err.response)
            toast(err.response.data.error ? err.response.data.error : 'Something went wrong');
        })
    }

    return <div className="login" >
        <div className="container" >
            <img src={require("../../assets/images/login.svg")} alt="" />
            <img src={require("../../assets/images/futwork-logo.svg")} alt="" className="logo" />
        </div>
        <ToastContainer draggable={true} />
    </div>
}

export default MagicLink