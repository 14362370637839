import React, {useContext,useState, useEffect} from 'react'
import styles from '../../../assets/stylesheets/pages/scriptbuilder/scriptbuilder.module.scss'
import AlwaysScrollToBottom from '../../common/AlwaysScrollToBottom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPenToSquare, faFloppyDisk, faTrash} from '@fortawesome/free-solid-svg-icons'
import ApiActions from '../../../actions/ApiActions'
import { ISContext } from './ISContext'
import handleErrorMessage from '../../../helper/handleErrorMessage'

const dummyLabel = {
    value: "",
    isSaved: false
}

const LabelCreation = (props) => {
	const { teleproject, setAllScripts, allScripts } = useContext(ISContext)

    let getLables = props.labels && props.labels.length > 0 ? props.labels : 
    [dummyLabel]
    const [labels, setLabels] = useState(getLables)
    const [checkIfLatest, setCheckIfLatest] = useState(false)

    useEffect(()=>{
        setCheckIfLatest(true)
    },[])

    const handleEdit = (value, type, index) => {
        let tempLabels = [...labels]
        tempLabels[index][type] = value
        setLabels(tempLabels)
    }

    const addLabel = () => {
        setCheckIfLatest(true)
        let newLabelCount = [...labels]
        newLabelCount.push({
            value: "",
            isSaved: false
        })
        setLabels([...newLabelCount])
    }

    const checkIfLabelEmpty = () => {
        let res = ''
        if(labels.length === 1){
           if(labels[0].value === '')
            return res
        }
        else{
            return res
        }
    }

    const turnOnEditMode = (idx) => {
        setCheckIfLatest(false)
        let tmpLabels = [...labels]
        tmpLabels.map((item, id) => {
            if(id == idx){
            item.isSaved = false
            return item
            }else{
                return item
            }
        })
        setLabels(tmpLabels)
    }

    const saveLabel = async(idx) => {
        let result = ''
        let getLabel = labels.find((item, id) => id == idx )
 
        let payload = {
            teleprojectId: teleproject,
        }
        if(getLabel.label && (getLabel.label !== getLabel.value)){
            payload.previousHeading = getLabel.label
            payload.newHeading = getLabel.value
        }else{
            payload.newHeading = getLabel.value
        }
        try{
            result = await ApiActions.createEditLabel(payload)
            if(result){
            let temp = allScripts.map(item => {
                item.scriptHeadings = result.data
                return item
            })
            labels[idx].isSaved = true
            setAllScripts(temp)
            let tempLabels = [...labels]
            tempLabels[idx].label = getLabel.value
            tempLabels[idx].value = getLabel.value
            setLabels(tempLabels)
            if(payload.previousHeading && payload.newHeading)
            props.getToast('Hot Flow label updated')
            else
            props.getToast('Hot Flow label created')
            }
        }catch(err){
            console.error(err.response.data.error)
            props.getToast(handleErrorMessage(err.response.data.error))
        }
    }

    const deleteLabel = async(idx) => {
        let result = ''
        let getLabel = labels.find((item, id) => id == idx )
        let payload = {
            teleprojectId: teleproject,
            heading: getLabel.label
        }
        try{
            result = await ApiActions.deleteLabel(payload)
            if(result){
            let temp = allScripts.map(item => {
                item.scriptHeadings = result.data
                return item
            })
            setAllScripts(temp)
            let tempLabels = [...labels]
            tempLabels.splice(idx, 1)
            setLabels(tempLabels)
            props.getToast('Hot Flow label deleted')
            }
        }catch(err){
            console.error(err.response.data.error)
            props.getToast(handleErrorMessage(err.response.data.error))
        }
    }

    return(
        <div className={styles['scroll']}>
        {
            labels.length > 0 ?
            labels.map((label, idx) => {
                let isEmpty = false;
                if(!label.value){
                    isEmpty = true
                }
                return <div className={styles['label-creation']}>
                    <div className={styles['first-child']}>
                    <input type='text' value={label.value}
                     placeholder={'Enter label name'}
                     onChange={(e) => handleEdit(e.target.value, 'value', idx)}
                     disabled={label.isSaved ? true: false}
                     className={label.isSaved ? styles['disabled'] : ''}/>
                    </div>
                    {label.isSaved ? <FontAwesomeIcon icon={faPenToSquare} className={styles['color-blue']}
                    onClick={() => turnOnEditMode(idx)}/> 
                    :
                    <FontAwesomeIcon icon={faFloppyDisk} className={[styles['color-blue'], (label.isSaved || isEmpty) ? styles['op-0'] : styles['op-1']].join(' ')}
                    onClick={() => saveLabel(idx)}/>
                    }
                    <FontAwesomeIcon icon={faTrash} className={[styles['color-blue'], label.isSaved ? styles['op-1'] : styles['op-0']].join(' ')}
                    onClick={() => deleteLabel(idx)}/>
                </div>
            })
            :
            null
        }
        <div className={styles['action-row']}>
        <div className={[styles['add-label'], styles[checkIfLabelEmpty()]].join(' ')} onClick={addLabel}>+ Add label</div>
        </div>
        {checkIfLatest ? <AlwaysScrollToBottom /> : null}
        </div>
    )
}

export default LabelCreation