import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import Table from '../../../uicomponents/table/Table'
import Heading from '../../../uicomponents/heading'
import useApi from '../../../hooks/useApi'
import {getCallAttendanceTracker, checkPaymentStatus} from './service'
import Popup from '../../../uicomponents/popup'
import AttendanceDetails from './attendanceDetails'
import Datepicker from '../../../uicomponents/date-picker'
import TruncateString from '../../../helper/TruncateString'
import Tooltip from './tooltip'
import getPaymentCycle from './getPaymentCycle'

const AttendanceTracker = (props) => {
    const [limit, setLimit] = useState(100)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState({
        data: '',
        isOpen: false
    })
    const [defaultDate, setDefaultDate] = useState({
        from: '', to: ''
    })
    const [isPaymentAlreadyRaised, setIsPaymentAlreadyRaised] = useState('')

    const getCallAttendanceTrackerapi = useApi(getCallAttendanceTracker)

    useEffect(()=>{
        let {startDate, endDate} = getPaymentCycle()
        setDefaultDate({from: startDate, to: endDate})
        getCallAttendanceTrackerapi.request(startDate, endDate, '', '')
        checkIfPaymentAlreadyRaised()
    },[])


    const checkIfPaymentAlreadyRaised = async() => {
        try{
            let result = await checkPaymentStatus()
            if(result && result.length > 0){
               result && result[0] && setIsPaymentAlreadyRaised(...result)
            }
        }catch (error) {
            let msg = ''
            if (error.response)
                msg = error.response && error.response.data || "Unexpected Error!"
            else
                msg = error.message || "Unexpected Error!"
        }
    }

    const renderAttendanceDetails = () => {
        const { data } = rowData
        return(
            <Popup
            show={rowData.isOpen}
            closePopup={()=>{
                setRowData({data: '', isOpen: false})
            }}
            size='sm'
            heading={<span>{data && TruncateString(data.firstname, 50) || 'NA'} {data && TruncateString(data.lastname, 50) || 'NA'} | {data && data.mobile || 'NA'}</span>}
            popupContent = {<AttendanceDetails callerDetails = {data} dateRange = {defaultDate} />}
            />
        )
    }

    const handlePopups = () => {
        if (rowData) return renderAttendanceDetails()
    }

    const getTimeFormat = (value) => {
        let totalSeconds = value
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        // console.log("hours: " + hours);
        // console.log("minutes: " + minutes);
        // console.log("seconds: " + seconds);

        // If you want strings with leading zeroes:
        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");

        // return moment(seconds*1000).format('HH:mm:ss')
        return `${hours}h ${minutes}m  ${seconds}s`
    }

    const columns = [
        {
            Header: "Caller",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            width: '15%',
            disableSortBy: true
        },
        {
            Header: 'Phone',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '12%',
            disableSortBy: true
        },
        {
            Header: Tooltip('Working time'),
            accessor: "productiveTime",
            Cell: ({value}) => ( value ? getTimeFormat(value) : 0),
            width: '11%',
        },
        {
            Header: Tooltip('Spoken mins'),
            accessor: "spokenMin",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: Tooltip('Attempts'),
            accessor: "attempts",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: Tooltip('Base pay'),
            accessor: "dailyEarning",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: Tooltip('Present count'),
            accessor: "presentCount",
            Cell: ({value}) => ( value ? value : 0),
            width: '11%'
        },
        {
            Header: Tooltip('Total earnings'),
            accessor: "totalEarnings",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "action",
            Cell: ({cell}) => {
                let rowcomponent = <div className={[styles['arrow']]}
                    onClick={() => setRowData({data: cell && cell.row && cell.row.original, isOpen: true})}>
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>

                return rowcomponent
            },
            width: "3%",
            disableSortBy: true
        }
    ]

    let {data, loading, error} = getCallAttendanceTrackerapi || {}

    const goToPayoutPage = () => {
        props.history.push({
            pathname: `/caller/payout`,
            state: {
                dateRange: defaultDate
            }
        })
    }

    const Calendar = () => {
        return <Datepicker
        placeholderText="Click to select a date"
        selectedDate={new Date()}
        isDateRange = {true}
        from={moment(defaultDate.from).toDate()}
        to={moment(defaultDate.to).toDate()}
        onChange={(daterange) => {
            const [from, to] = daterange
            if(from && to){
                setDefaultDate({
                    from: moment(from).format('YYYY-MM-DD'),
                    to: moment(to).format('YYYY-MM-DD')
                })
                getCallAttendanceTrackerapi.request(moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'))
            }
        }}
    />
    }

    return(
            <>
            <div className={styles.attendance_wrapper}>
                    <span onClick={()=> goToPayoutPage()}>Raise a payment request <FontAwesomeIcon icon={faArrowRight} /></span>
            </div>
           {isPaymentAlreadyRaised && <div className={styles.raised}>
                {`Payout request amount of  ₹${isPaymentAlreadyRaised && isPaymentAlreadyRaised.totalPaidAmount} 
                raised for ${isPaymentAlreadyRaised && isPaymentAlreadyRaised.totalCallers} callers`}
            </div>}
             <div className={styles.page_wrapper}>
            {error && <p>{typeof error == 'object' && 
            error !== null &&
            !Array.isArray(error)
            ? error.error || error.msg : error}</p>}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Heading text={'Attendance Tracker'} />
            <div className={styles.payment_cycle_selector}>
            {defaultDate.from && defaultDate.to && <><span className={styles.label}>Payment cycle:</span> <Calendar/></>}
            </div>
            </div>
            <Table
            columns={columns}
            data={data}
            totalCount={data ? data.length : 0}
            limit={limit}
            type = 'client'
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            error={error}
            isGlobalFilter = {true}
            manualSortBy = {false}
            />
            {handlePopups()}
            </div>
            </>
    )
}

export default AttendanceTracker