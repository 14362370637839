import React, { Component } from 'react'
import FrappModal from "../utilities/FrappModal";
import FrappButton from '../utilities/FrappButton';
import ApiActions from '../../actions/ApiActions';
import MaterialTable, { MTablePagination } from "material-table";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import { ToastContainer, toast } from "react-toastify";
import Dropdown from 'react-dropdown';
import checkIsEmpty from '../../helper/checkIsEmpty'
import { Link } from 'react-router-dom';


export default class TringModeration extends Component {

    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        this.state={
            list:[],
            dataToSend:{},
            skip:0,
         manualGradeResults:{
       "pronunciation":"",
       "tonality":"",
       "speed":"",
       "scriptPreparedness":""
         },
         currentPage: 0,
         isModelOpen: false,
         mobileNumbersToCertify: [],
         enteredMobileNumber: '',
         apiMsg: {
            type: '',
            msg: ''
         }
        }
    }


    componentDidMount() {
        let {skip}=this.state;
        ApiActions.getTringModerationList(skip).then(resp=>{
             skip=skip+50;
            this.setState({list:resp,skip})
        })
        
    }
    
    render() {
        return (
            <div className="additional-wrapper">
                <div className='underline-cta-wrapper'>
                   <img src={require("../../assets/images/Icons_arrow.svg")} 
                   onClick={()=>this.props.history.push("/caller")}/>
                    <div>
                        <Link className='underline-cta' target={"_blank"} to="/tools/call-dialer">Dialer</Link>
                        <span className='underline-cta' 
                            onClick={()=> this.setState({isModelOpen: true})}>
                            Direct Certify
                        </span>
                    </div>
                </div>
               {this.renderApplicantsTable()}
               {/* render direct certify caller popup */}
               {this.state.isModelOpen ? this.renderCertifyCaller() : null}
                <ToastContainer draggable={true} />
            </div>
        )
    }

    renderCertifyCaller = () => {
        const {apiMsg, enteredMobileNumber, mobileNumbersToCertify, isModelOpen} = this.state
        const addMobileNumber = () => {
            let temp = [...mobileNumbersToCertify]
            temp = [...temp, enteredMobileNumber]
            let unique_temp = [...new Set(temp)]
            this.setState({
                mobileNumbersToCertify: unique_temp,
                enteredMobileNumber: ''
            })
        }
        //fn to check if entered mobile no. is numeric
        const isNumeric = (str) => {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str))
        }
        const removeMobileNumber = (idx) => {
            let temp = [...mobileNumbersToCertify]
            temp.splice(idx, 1);
            this.setState({
                mobileNumbersToCertify: temp
            })
        }
        const handleDirectCertify = async () => {
            const dataToSend = {
                mobile: mobileNumbersToCertify
            }
            try {
                await ApiActions.directCertifyCallers(dataToSend).then(resp => {
                    const {unprocessedCount, processedCount} = resp.data
                    const customResponse = <div>
                    <span className='success-msg'>{processedCount} mobile number(s) got certified</span>
                    {unprocessedCount > 0 ? 
                    <>
                    <br/>
                    <span className='failure-msg'>{unprocessedCount} mobile number(s) failed to certify</span>
                    </>
                    : null}
                    </div>
                    this.setState({
                        apiMsg:{
                            type: 'success',
                            msg: customResponse
                        }
                    })
                })
            } catch (error) {
                console.error(error)
                const errorMsg = error.message ? error.message : error.response
                this.setState({
                    apiMsg:{
                        type: 'failure',
                        msg: errorMsg
                    }
                })
            }
        }
        const closeModal = () => {
            this.setState({
                isModelOpen: false,
                apiMsg: {
                    type: '',
                    msg: ''
                },
                mobileNumbersToCertify: []
            })
        }

        return <FrappModal
            className="custom-modal modal-xs"
            onCloseButton={true}
            bsSize={'small'}
            show={isModelOpen}
            closeButtonClicked={() => closeModal()}
        >
        <div className="heading">Certify callers</div>
        <div className='direct-certify'>
        <div className="pill-wrapper">
        {mobileNumbersToCertify && mobileNumbersToCertify.map((num, i) => {
            return <div className="pill">{num}
                <div className="del-icon" 
                onClick={() => removeMobileNumber(i)}
                >
                    <img src={require("../../assets/images/del-icon-taskprops.svg")} /></div>
            </div>
        })}
        </div>
        <div className='mobile-number-section'>
        <FormGroup>
        <FormControl
        name="mobile-number"
        type="text"
        minLength="10"
        maxLength="13"
        value={enteredMobileNumber}
        onChange={(e) =>this.setState({enteredMobileNumber: e.target.value})}
        onKeyUp={event => event.key == "Enter" &&
        enteredMobileNumber.length >= 10 &&
        isNumeric(enteredMobileNumber)
        ? addMobileNumber() : null}
        placeholder="Enter mobile number"
        />
        </FormGroup>
        <div className="plus-icon" >
            <button
            disabled={(enteredMobileNumber.length < 10
                || !isNumeric(enteredMobileNumber))
            ? true : false} 
            onClick={() => addMobileNumber()}>
                <img src={require("../../assets/images/plus-icon-taskprops.svg")}/>
            </button>
        </div>
        </div>
        {
            enteredMobileNumber !== '' ?
            isNumeric(enteredMobileNumber) ?
            null :
            <span className='error-msg'>Invalid mobile number entered</span>
            : null
        }
        {
        !checkIsEmpty(apiMsg) ?
            <div className={apiMsg.type == 'success' ?
                'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
            : null
        }
        <div className='modal-btn-footer'>
        <FrappButton className="submit" id="new-btn"
        disabled={
            mobileNumbersToCertify.length <= 0 ?
            true : false
        }
        handler={() => handleDirectCertify()}
        >CERTIFY</FrappButton>
        </div>
        </div>
        </FrappModal>
    }

    updateGrades=(data)=>{
           let {dataToSend, list}=this.state;
          ApiActions.updateManualGrading(dataToSend).then(resp=>{
            toast(" Application updated")
            let updatedList = list.filter(item => item.id !== dataToSend.applicationId)
            this.setState({list: updatedList})
            //   setTimeout(function () { window.location.reload(); }, 2000);
          })
    }
    _onSelect=(data,val,key)=>{
      let {dataToSend}=this.state;
      let {manualGradeResults}=this.state;
        Object.keys(manualGradeResults).forEach(function(keyOld) {
            if(keyOld==key)
            {
                manualGradeResults[keyOld]=val.value;
            }
        });
        this.setState({manualGradeResults})
        let updatedData={
            "applicationId":data.id,
            "ratingData":manualGradeResults
        }
       this.setState({dataToSend:updatedData})
    }
   fetchData=()=>{
       this.setState({currentPage: this.tableRef.current.state.currentPage})
       let {skip,list}=this.state;
       let newData;
       ApiActions.getTringModerationList(skip).then(resp=>{
        skip=skip+50;
        newData=list.concat(resp)
        this.setState({skip,list:newData})       
    
    })
   }
   renderApplicantsTable = () => {
     
      let options=[
           { label: "A ",value:"A"},
           { label: "B ",value:"B"},
           { label: "C",value:"C"},
           { label: "D",value:"D"}
        ]

        let { list ,skip} = this.state;
        let  oldData=[];
        return (
            <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsPerPage: '25'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                    tableRef={this.tableRef}
                    onSearchChange={(e)=>{
                        if(!e)
                        this.tableRef.current.onChangePage(null, this.state.currentPage);
                    }}
                    columns={[
                          {
                            title: "",
                            field: "user.picture",
                            render: rowData=> 
                      <div>
                       {rowData.user && rowData.user.picture ? <img src={rowData.user.picture} style={{width: 40, height:40,borderRadius: '50%'}}/> 
                       :<img src={require("../../assets/images/placeholder.png")} style={{width: 40, height:40,borderRadius: '50%'}}/>}
                           </div>
                             ,
                             filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                             headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filtering:false


                        },
                        {
                            title: "Name",
                            field: "user.firstname",
                            render: rowData => 
                         <div>
                          {rowData.user && rowData.user.firstname} {rowData.user && rowData.user.lastname}
                           </div>
                             ,
                             filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                             headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                            title: "Email",
                            field: "user.email",
                             filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                             headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                            title: "Mobile",
                            field: "user.mobile",
                             filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                             headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                       
                        {
                            title: "Audio", 
                            field:"testAudioUrl",
                            render: rowData => 
                      <div>
                         {/* <a href={rowData.testAudioUrl}> Audio </a> */}
                         <audio controls src={rowData && rowData.testAudioUrl}>  
                        </audio>
                           </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering:false
                        },
                        {
                            title: "Auto score",
                            field:"smartGradeResult.totalScore",
                            render: rowData => 
                      <div>
                          {rowData && rowData.smartGradeResult && rowData.smartGradeResult.totalScore}
                           </div>,
                             cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },
                        {
                            title: "Script Preparedness",
                            field:"manualGradeResult.scriptPreparedness",
                           render: rowData => 
                      <div>
                          {/* {rowData.manualGradeResults?rowData.manualGradeResults.scriptPreparedness:"A"} */}

                        <Dropdown options={options} 
                           onChange={(val)=>this._onSelect(rowData,val,"scriptPreparedness")} 
                           value={rowData && rowData.manualGradeResult ? rowData.manualGradeResult.scriptPreparedness:"Select"} 
                           placeholder="Select an option" />
                           </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },

                        {
                            title: "Pronunciation",
                             field:"manualGradeResults.pronunciation",
                           render: rowData => 
                      <div>
                          {/* {rowData.manualGradeResults?rowData.manualGradeResults.pronunciation:"A"} */}
                            <Dropdown options={options} 
                           onChange={(val)=>this._onSelect(rowData,val,"pronunciation")} 
                           value={rowData && rowData.manualGradeResult?rowData.manualGradeResult.pronunciation:"Select"} 
                           placeholder="Select an option" />
                           </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },
                        {
                            title: "Tonality",
                             field:"manualGradeResults.tonality",
                            render: rowData => 
                      <div>
                          {/* {rowData.manualGradeResults?rowData.manualGradeResults.tonality:"A"} */}
                           <Dropdown options={options} 
                           onChange={(val)=>this._onSelect(rowData,val,"tonality")} 
                           value={rowData && rowData.manualGradeResult?rowData.manualGradeResult.tonality:"Select"} 
                           placeholder="Select an option" />
                           </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filterCellStyle: {
                                padding: '1% 0% 2% 0%',
                                boxSizing: 'inherit'
                            },
                             filtering: false
                        },

                        {
                            title: "Speed",
                             field:"manualGradeResults.speed",
                           render: rowData => 
                      <div>
                          {/* {rowData.manualGradeResults?rowData.manualGradeResults.speed:"A"} */}
                           <Dropdown options={options} 
                           onChange={(val)=>this._onSelect(rowData,val,"speed")} 
                           value={rowData && rowData.manualGradeResult?rowData.manualGradeResult.speed:"Select"} 
                           placeholder="Select an option" />
                           </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },
                        {
                            title: "",
                           render: rowData => 
                        <div >
                           <FrappButton className="grey-button" id="tmp-moderation"
                           handler={()=>this.updateGrades(rowData)}>
                               Submit
                           </FrappButton>
                         {/* <img src={require("../../assets/images/green-tick.svg")}/> */}
                        </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },


                        
                        
                    ]}
                  data={list}
                    components={{
                        Pagination: props => {
                            const { classes,...other } = props;
                            return <div>
                                <MTablePagination rowsPerPage={50} {...other} />
                            </div>
                        }
                    }}
                    onChangePage={()=>this.fetchData()}
                    options={{
                        filtering: true,
                        // grouping: true,
                        exportButton: true,
                        doubleHorizontalScroll:true,
                        exportAllData:true,
                        columnsButton:true,
                        pageSize:25,
                        emptyRowsWhenPaging:false,
                       // pageSizeOptions:[25,50,75,100],
                        rowStyle: {
                            fontSize: "16px"
                        },
                        searchFieldStyle: {
                            boxShadow: 'none',
                            webkitboxShadow: 'none',
                        }
                    }}
                    title="Moderation"
                      

                />



            </div>
        )
    }
}
