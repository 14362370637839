import React, {useState, useEffect} from "react";
//custom imports
import styles from '../../assets/stylesheets/pages/project/project.module.scss'
import CustomTooltip from "../../components/utilities/ToolTip";
import Table from "../../uicomponents/table/Table";
import useApi from "../../hooks/useApi";
import ApiActions from "../../actions/ApiActions";
import generatePayload from "../../helper/generatePayloadForTable";

const QuizListing = (props) => {
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    const getQuizListApi = useApi(ApiActions.fetchQuizList)

    useEffect(() => {
        const skip = (currentPage - 1) * limit
        let dynamicSearch = true
        let payload = generatePayload(skip, limit, null, search, dynamicSearch)
        getQuizListApi.request(payload)
    },[currentPage, search])

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    let {data, total} = getQuizListApi.data || {}
    const {loading, error} = getQuizListApi

    const columns = [
        {
            Header: 'Quiz title',
            accessor: 'name',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "20%",
            disableSortBy: true
        },
        {
            Header: 'Category',
            accessor: 'category',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "10%",
            disableSortBy: true
        },
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: '',
            accessor: 'action',
            Cell: (cell) => (
            <div style={{display: 'flex', gap: '8%', justifyContent: 'flex-end'}}>
            <CustomTooltip 
            placement = 'bottom'
            description = 'Edit quiz questions'
            component={
                <div className={styles.blue_link} 
                onClick={() => props.createNewQuiz(cell.row.original)}>
                Edit Quiz Questions
                </div>
            }/>
            <CustomTooltip 
            placement = 'bottom'
            description = 'Edit quiz name or category'
            component={
            <div className={styles.blue_link} 
            onClick={() => props.handleClickName(cell.row.original)}>
            Edit Quiz Name or Category
            </div>
            }/>
            </div>
            ),
            width: "25%",
            disableSortBy: true
        }
    ]
    
   
    return(
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        // sortChange={sortChange}
        filterChange = {filterChange}
        searchBy='project id or title'
        isGlobalFilter = {true}
        />
    )
}

export default QuizListing