import React, { useState, useEffect } from "react";
import Table from '../../../uicomponents/table/Table'
import useApi from '../../../hooks/useApi'
import { getCallerAttendance } from './service'
import moment from 'moment'
import capitalise from "../../../helper/Capitalise";
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import Panel from '../../../uicomponents/panel'
import ApiActions from "../../../actions/ApiActions";

const AttendanceDetails = (props) => {
    const {callerDetails, dateRange} = props
    const {_id, firstname, lastname, mobile} = callerDetails
    const {from, to} = dateRange
    const [limit, setLimit] = useState(200)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const getCallerAttendanceapi = useApi(getCallerAttendance)
    const [projectsAssigned, setProjectsAssigned] = useState([])

    useEffect(()=>{
        getCallerAttendanceapi.request(from, to, _id)
        const payload = {
            user: _id,
        }
        ApiActions.getCallerCurrentJobs(payload).then(resp => {
            let result = resp && resp.length > 0 && resp.map(item => {
                if(item.teleproject)
                return item.teleproject.title
            })
            setProjectsAssigned(result)
        })
    },[])


    const getTimeFormat = (value) => {
        let totalSeconds = value
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
        // If you want strings with leading zeroes:
        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");
        // return moment(seconds*1000).format('HH:mm:ss')
        return `${hours}h ${minutes}m  ${seconds}s`
    }

    const columns = [
        {
            Header: "Date",
            accessor: "createdAt",
            Cell: ({cell}) => {
                return <span>{cell.row.original.createdAt ? moment(cell.row.original.createdAt).format("DD/MM/YYYY") : "NA"}</span>
            },
            width: '20%',
            disableSortBy: true
        },
        {
            Header: 'Attendance',
            accessor: "attendance",
            Cell: ({value}) => {
                return value ? <span className={[styles.attendance_status, value == 'absent' ? styles.absent : styles.present].join(' ')}>{capitalise(value)}</span> : 'NA'
            },
            width: '20%',
            disableSortBy: true
        },
        {
            Header: 'Working mins',
            accessor: "productiveTime",
            Cell: ({value}) => ( value ? getTimeFormat(value) : 0),
            width: '25%',
            disableSortBy: true
        },
        {
            Header: 'Payout status',
            accessor: "paid",
            width: '25%',
            Cell: ({value}) => {
                return value ? <span className={[styles.payment_status, styles.paid].join(' ')}>Paid</span> :
                <span className={[styles.payment_status, styles.unpaid].join(' ')}>Unpaid</span>
            },
            disableSortBy: true
        }
    ]

    const {data, loading} = getCallerAttendanceapi || {}

    return(
        <>
        <Panel title={<span className={styles.sub_heading}>Projects assigned {}</span>}>
         {
            projectsAssigned && projectsAssigned.length > 0 ?
            <ul className={styles.listing}>
                {
                    projectsAssigned && projectsAssigned.map(item => {
                        return <li>{item}</li>
                    })
                }
            </ul> : <p>No projects are assigned</p>
         }
        </Panel>
        <Table
        columns={columns}
        data={data}
        totalCount={data ? data.length : 0}
        limit={limit}
        type = 'client'
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        height = '50'
        />
        </>
    )
}

export default React.memo(AttendanceDetails)