import React from 'react'
import styles from './table.module.scss'

const NoData = ({columns, selectRow}) => {
    return (
        <tr className={styles.no_data}>
        <td colSpan={selectRow ? columns.length + 1 : columns.length}>No Data available</td>
        </tr>
    )
}

export default NoData