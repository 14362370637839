import moment from "moment";
import React, {Fragment} from 'react'
import FrappButton from '../../utilities/FrappButton'
import FrappModal from '../../utilities/FrappModal';
import Dropdown from 'react-dropdown';
import Tooltip from 'react-bootstrap/lib/Tooltip'
import TruncateString from '../../../helper/TruncateString'

const tableData = ['Out of script questions', 'Followed script', 'Keywords',
            'Situation handling', 'Quick pitch used', 'Acknowledging', 'Noise']

let parameterOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
]

let options = [
    { label: "Select", value: "inprogress" },
    { label: "Remock", value: "training" },
    { label: "Reject", value: "rejected" }
]

let optionsForRetraining = [
    { label: "Select",value:"inprogress"},
    { label: "Reject", value: "rejected" }
]

const getMockCallTitle = (fetchCallersBasedOnStage, props, activeTab) => {
    return <span className='mock-call-status-header'>Mock call<img src={require('../../../assets/images/Icons_refresh.svg')} onClick={()=> fetchCallersBasedOnStage(props.match.params.id, activeTab)} /></span>
}

const getMockCallStatus = (timeSlotObj, info) => {
    if(info.mockCallsPendingCount > 0){
        return <span class='mock_status done'>Done</span>
    }else{
        const currentDate  = moment().format('YYYY-MM-DD')
        const startDateFormat = moment(timeSlotObj.slotStart).format('YYYY-MM-DD')
        const nowDate = moment(currentDate);
        const startDate = moment(startDateFormat);
        const noOfDays = moment.duration(startDate.diff(nowDate)).asDays()
        //if the no of days is 1 or greater than 1 pending logic triggers
        if(noOfDays >= 1){
            return <span class='mock_status pending'>Pending</span>
        }else{
            //Due is for the mocks calls that are to be made today
            //Pending is for the mock calls that are to be made for tomorrow
            //If the slot start and slot end both are passed then we will conside it as Overdue
            let currentTime = new Date();
            let expireTimeStart = new Date(timeSlotObj.slotStart);
            let expireTimeEnd = new Date(timeSlotObj.slotEnd);
            let minutesStart = (expireTimeStart - currentTime) / (1000 * 60);
            minutesStart = minutesStart.toFixed(0)
            let minutesEnd = (expireTimeEnd - currentTime) / (1000 * 60);
            minutesEnd = minutesEnd.toFixed(0)

            if (minutesStart < 0 && minutesEnd < 0) {
                return <span class='mock_status overdue'>Overdue</span>
            }else if (minutesStart > 0 || minutesEnd >= 0) {
                return <span class='mock_status due'>Due</span>
            }
        } 
    }
}

const getMockCallStatusForCSV = (timeSlotObj, info) => {
    if(info.mockCallsPendingCount > 0){
        return 'Done'
    }else{
        const currentDate  = moment().format('YYYY-MM-DD')
        const startDateFormat = moment(timeSlotObj.slotStart).format('YYYY-MM-DD')
        const nowDate = moment(currentDate);
        const startDate = moment(startDateFormat);
        const noOfDays = moment.duration(startDate.diff(nowDate)).asDays()
        //if the no of days is 1 or greater than 1 pending logic triggers
        if(noOfDays >= 1){
            return 'Pending'
        }else{
            //Due is for the mocks calls that are to be made today
            //Pending is for the mock calls that are to be made for tomorrow
            //If the slot start and slot end both are passed then we will conside it as Overdue
            let currentTime = new Date();
            let expireTimeStart = new Date(timeSlotObj.slotStart);
            let expireTimeEnd = new Date(timeSlotObj.slotEnd);
            let minutesStart = (expireTimeStart - currentTime) / (1000 * 60);
            minutesStart = minutesStart.toFixed(0)
            let minutesEnd = (expireTimeEnd - currentTime) / (1000 * 60);
            minutesEnd = minutesEnd.toFixed(0)

            if (minutesStart < 0 && minutesEnd < 0) {
                return 'Overdue'
            }else if (minutesStart > 0 || minutesEnd >= 0) {
                return 'Due'
            }
        } 
    }
}

const mockCallButton = (rowData, callModal, callerChooseForMock) => {
    return <div className='table-data'>
            <div>
                <img title="Place a call" className='mock-call-button' src={require("../../../assets/images/Icons_calling-exotel.svg")}
                    onClick={() => {
                        callModal()
                        callerChooseForMock(rowData)
                    }
                    }
                    style={{ width: '36px', padding: '0', boxShadow: '0px 0px 8px #1e1e1ead', borderRadius: '50%' }} />
            </div>
            </div>
}

const mockCallDataFn = (rowData, getMockCallDetails) => {
    return <div className='table-data'>
        <div className="action-btns">
            <span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div>
                    <FrappButton className="audit-view-btn"
                        handler={() => {
                            getMockCallDetails(rowData)
                        }
                        }>View</FrappButton>
                </div>
            </span>
        </div>
    </div>
}


const renderConfirmCallModal = (confirmCallModal, closeConfirmCallModal, triggerMockCall) => {
    return (
        <FrappModal
            className="mock-meeting-link-modal"
            onCloseButton={true}
            bsSize={'small'}
            show={confirmCallModal}
            closeButtonClicked={() => {
                closeConfirmCallModal()
            }}
        >
            <div className="notif-popup">
                <div className="heading" style={{ marginBottom: '3%' }}>Are you sure you want to place the call?</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <FrappButton className="submit" id="new-btn" 
                handler={() => triggerMockCall()}
                >
                    Call
             </FrappButton>
                <FrappButton className="submit" id="transparent-btn" 
                handler={() => closeConfirmCallModal()}
                >
                    Dismiss
             </FrappButton>
            </div>
        </FrappModal>
    )
}

const renderSelectionPop = (showSelectionModal, closeShowSelectionModal, mockCallData, storeHiringDecision, hiringDecision, checkboxFeedbackData, selectFeedbacks, sendMockCallFeedback, activeTab, coachFeedback) => {
    return (
        <FrappModal
            className="mock-selection-modal modal-scrollbox"
            onCloseButton={true}
            bsSize={'small'}
            show={showSelectionModal}
            closeButtonClicked={() => {
                closeShowSelectionModal()
            }}
        >
            <div className="notif-popup">
                <div className="heading" style={{ marginBottom: '3%' }}>Mock selection</div>
                <div className='text' style={{ display: 'flex', flexFlow: 'column', gap: '17px' }}>

                    {mockCallData.length <= 0 ?
                        <h4>Hiring decision to be taken after the mock call</h4>
                        : 
                        <Fragment>
                            {
                                mockCallData.map((mock, idx) => {
                                    return <div>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                        <span>{idx+1})</span> 
                                        <span>Call status: {mock.callstatus}</span>
                                        </div>
                                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                        <audio controls src={mock.recordingUrl} className='audio-url'></audio>
                                        <img title="Copy link" className='mock-record-url' src={require("../../../assets/images/Icons_link-circle.svg")}
                                            onClick={(e) => copyToClipboard(mock.recordingUrl)}
                                            style={{ width: '26px', padding: '0' }}
                                        />
                                        <span>{moment(mock.createdAt).format("DD-MM-YYYY")} [{moment(mock.createdAt).format('LT')}]</span>
                                    </div>
                                    </div>
                                })
                            }
                           {activeTab && activeTab.value == 'ready_for_mock' ? <div className="iqc-form-wrapper">
                                <div className='sub-heading'>Parameters</div>
                                <div className="form-container">
                                    <table>
                                        <tbody>
                                            {tableData.map((item, idx) => {
                                                return (
                                                    <tr className="parameter-row" key={idx}>
                                                        <td className="td_text">{item}</td>
                                                        <td style={{ padding: '1%' }}>
                                                            <Dropdown options={parameterOptions}
                                                                placeholder="Select an option" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div> : null}
                            {
                                activeTab.value !== 'ready_for_mock' ? 
                                <div className='coach-feedback'>
                                {/* <span className='bolder-text'>Feedback for Coaches</span> */}
                                <div className='sub-heading'>Feedback for coaches</div>
                                <div className='feedback-for-coaches'>
                                {
                                    coachFeedback ? <span>{coachFeedback}</span> : <span>No Feedbacks</span>
                                }
                                </div>
                                </div>
                                : null
                            }
                            {activeTab.value == 'ready_for_mock' ? <div className='hiring-section'>
                                <div className='d-flex'>
                                    <span className='bolder-text'>Hiring decision</span>
                                    <Dropdown options={options}
                                        onChange={(val) => selectHiringDecision(val, storeHiringDecision, activeTab)}
                                        value={hiringDecision.status}
                                        placeholder="Select an option" />
                                </div>
                            </div> : 
                            <div className='hiring-section'>
                            <div className='d-flex'>
                            <span className='bolder-text'>Status</span>
                            <Dropdown options={optionsForRetraining}
                                onChange={(val) => selectHiringDecision(val, storeHiringDecision, activeTab)}
                                value={hiringDecision.status}
                                // value={rowData.status ? rowData.status:"Select"} 
                                placeholder="Select an option" />
                            </div>
                            </div>
                            }
                            {checkboxFeedbackData && checkboxFeedbackData.length > 0 ?
                                <div className='feedback-group'>
                                    <span className='bolder-text' style={{ paddingBottom: '5px' }}>Feedback</span>
                                    {checkboxFeedbackData && checkboxFeedbackData.map((item) => {
                                        return renderCheckboxFeedback(item, selectFeedbacks);
                                    })}
                                </div> : null}
                        </Fragment>
                        }
                </div>
            </div>
            <br />
            {mockCallData.length > 0 ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FrappButton className="submit" id="new-btn" 
                  handler={() => sendMockCallFeedback()}>SUBMIT</FrappButton>
            </div> : null}
        </FrappModal>
    )
}

//utility functions
const checkIfCoachLoggedIn = (loggedInUserRole, projectCoach) => {
    if (loggedInUserRole == projectCoach)
        return true
    else
        return false
}

const selectHiringDecision = (val, storeHiringDecision, activeTab) => {
    if(activeTab && activeTab.value == 'ready_for_mock'){
        if(val.value == 'training'){
            const checkboxArray = [
                { label: 'Voice modulation and tonality improvement required', id: '1', checked: false },
                { label: 'Error in key word pronunciation', id: '2', checked: false },
                { label: 'Voice modulation and energy on call required', id: '3', checked: false },
                { label: 'Need improvement in situation handlings', id: '4', checked: false },
                { label: 'Need to prepare well with the FAQ’s', id: '5', checked: false },
                { label: 'Outcome and call flow preparations needed', id: '6', checked: false },
            ]
            storeHiringDecision(val, checkboxArray)
        }else if(val.value == 'rejected'){
            const checkboxArray = [
                { label: 'Incorrect voice modulation', id: '1', checked: false },
                { label: 'Unable to pronounce key words', id: '2', checked: false },
                { label: 'Making errors in situation handlings', id: '3', checked: false },
                { label: 'Incorrect call flow used', id: '4', checked: false },
                { label: `Not prepared with FAQ’s`, id: '5', checked: false },
                { label: 'Fumbling, not well prepared', id: '6', checked: false }
            ]
            storeHiringDecision(val, checkboxArray)
        }else{
            const checkboxArray = [
                { label: `Prepare with FAQ's before you begin calling`, id: '1', checked: false },
                { label: 'Practise the call flows before you begin calling', id: '2', checked: false },
                { label: 'Read more about the brand and its benefits before you begin calling', id: '3', checked: false },
                { label: 'Work on improving key word pronunciations', id: '4', checked: false },
                { label: 'Practise voice modulation before you begin calling', id: '5', checked: false }
            ]
            storeHiringDecision(val, checkboxArray)
        }
    }else{
        if(val.value == 'inprogress') {
            const checkboxArray = [
                { label: `Prepare with FAQ's before you begin calling`, id: '1', checked: false },
                { label: 'Practise the call flows before you begin calling', id: '2', checked: false },
                { label: 'Read more about the brand and its benefits before you begin calling', id: '3', checked: false },
                { label: 'Work on improving key word pronunciations', id: '4', checked: false },
                { label: 'Practise voice modulation before you begin calling', id: '5', checked: false }
            ]
            storeHiringDecision(val, checkboxArray)
        }else{
            storeHiringDecision(val, [])
        }
    }
}

const copyToClipboard = (recordingUrl) => {
    navigator.clipboard.writeText(recordingUrl);
}

const renderCheckboxFeedback = (item, selectFeedbacks) => {
    return (
        <label key={item.id}><input type="checkbox" checked={item.checked} onChange={() => selectFeedbacks(item)} /> <span>{item.label}</span></label>
    )
}

const tooltip = (info) => {
    return (<Tooltip id="tooltip">
        {info}
    </Tooltip>)
};

const styleTheFeedback = (commaSepartedFeedback = '') => {
    let eachFeedback = commaSepartedFeedback && commaSepartedFeedback.split(',')
    let list;
    if (eachFeedback.length >= 2) {
        list =
            <div>
                <li>{TruncateString(eachFeedback[0], 20)}</li>
                <li class='text-muted'>View more</li>
            </div>
    } else {
        list = eachFeedback ? eachFeedback.map(feed => {
            return <div>
                    <li>{TruncateString(feed, 20)}</li>
                    {feed.length > 20 ? <li class='text-muted'>View more</li> : null}
                   </div>
        }) : 'None'
    }
    return <ul style={{ padding: '0' }}>{list}</ul>
}

const renderAllFeedbackPopup = (showFeedbackModal, closeFeedbackPopup, currentFeedback, type, coachFeedback) => {
    return (
        <FrappModal
            onCloseButton={true}
            className="tele-payment-modal feedback-modal"
            show={showFeedbackModal}
            closeButtonClicked={() => closeFeedbackPopup()}
        >
            {
                type == 'mockCall' ?
                <div>
                <div className="heading">Feedback</div>
                <div className="caller-history">
                    <div className='exisiting-job-container'>
                        {
                            currentFeedback && currentFeedback.length > 0 ?
                                currentFeedback.map(job => {
                                    return (
                                        <Fragment>
                                            <div className='existing-jobs-wrapper'>
                                                {job}
                                            </div>
                                        </Fragment>
                                    )
                                }) :
                                <Fragment>
                                    <div className='existing-jobs-wrapper'>
                                        <span>No Feedbacks</span>
                                    </div>
                                </Fragment>
                        }
                    </div>
                </div>
            </div> 
            :
            <div className='retrain-feedbacks'>
            <div className='sub-heading'>Feedback for callers</div>
            <div className='feedback-for-callers'>
            {
                currentFeedback && currentFeedback.length > 0 ?
                currentFeedback.map(feedback => <li>{feedback}</li>) : 
                <span>No Feedbacks</span>
            }
            </div>
            <div className='divider'></div>
            <div className='sub-heading'>Feedback for coaches</div>
            <div className='feedback-for-coaches'>
            {
                coachFeedback ? <span>{coachFeedback}</span> : <span>No Feedbacks</span>
            }
            </div>
            </div>
            }
            
        </FrappModal >
    )
}

export {
    getMockCallTitle,
    getMockCallStatus,
    mockCallButton,
    mockCallDataFn,
    renderConfirmCallModal,
    renderSelectionPop,
    checkIfCoachLoggedIn,
    tooltip,
    styleTheFeedback,
    renderAllFeedbackPopup,
    getMockCallStatusForCSV
}