import React, { Component } from 'react'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';

import SearchInput from '../../utilities/SearchInput';

import ApiActions from '../../../actions/ApiActions';

export default class BrandManagerSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        this.getBrandManagers();
    }

    render() {
        return (
            <div>
                <FormGroup className="mission-search" >
                    <ControlLabel></ControlLabel>
                    <div className="inputs-wrapper" >
                        {this.renderSearchBrandManager(this.props.brand)}
                    </div>
                </FormGroup>
            </div>
        )
    }

    getBrandManagers = () => {
        let brandId = this.props.brand

        this.setState({ bms: this.props.brandmanagers })
        ApiActions.getBrandManagerById(brandId).then(resp => {
            this.setState({ options: resp })
        })
    }

    renderSearchBrandManager = (brandId) => {
        return <Multiselect
            options={this.state.options} // Options to display in the dropdown
            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
            onSelect={this.onSelect} // Function will trigger on select event
            onRemove={this.onRemove} // Function will trigger on remove event
            displayValue="firstname" // Property name to display in the dropdown options
            selectedValues={this.state.bms}
        />
    }


    onSelect = (val) => {
        this.props.bmArray(val)
    }


}
