import json2csv from 'json2csv';

export default function ExportJsonToCsv(headers, data, filename) {

    let fields = headers
    let parser = new json2csv.Parser({ fields })
    let csv = parser.parse(data);
    
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    
    if (navigator.msSaveBlob) { // IE 10+
        
        navigator.msSaveBlob(blob, `${filename}`);
    
    } else {
        
        let link = document.createElement("a");
        
        if (link.download !== undefined) { // feature detection
        
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
        
            link.setAttribute("href", url);
            link.setAttribute("download", `${filename}`);
            link.style.visibility = 'hidden';
        
            document.body.appendChild(link);
        
            link.click();
            document.body.removeChild(link);
        }
    }
}