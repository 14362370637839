import React from 'react'
import styles from '../uicomponents/tab/tab.module.scss'

const TabGroup = (Component, variant='single') => {
    // do something
    // return a component that renders the component from the argument
    return (props) => {
    return <div className={styles[variant]}>
            {
                props.tabs.map((item, idx) => {
                    return <Component {...props} variant={variant} key={idx+1}>
                        {item}
                    </Component>
                })
            }
    </div>
   }
};

export default TabGroup