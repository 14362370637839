import Api from "../util/Api";
import axios from 'axios'
function infiniteFetcher(url, data) {
  return recursivePromise(url, data, [], 0);
}

function recursivePromise(url, data, responseData, skip) {
  data.skip = skip;

  return new Promise((resolve, reject) => {
    return Api.get(url, data).then(newResponseData => {
      responseData = responseData.concat(newResponseData);
      if (newResponseData.length === 50) {
        resolve(recursivePromise(url, data, responseData, skip + 50));
      } else {
        resolve(responseData);
      }
    });
  });
}

class ApiActions {
  getData = (url, data) => {
    data = data || {};
    if (data.infinite) {
      return infiniteFetcher(url, data);
    }

    return Api.get(`${url}`, data);
  };

  postData = (url, data) => {
    return Api.post(`${url}`, data);
  };


  putData = (url, data) => {
    return Api.put(`${url}`, data);
  };

  getStreamData = (url, data) => {
    return Api.getStream(`${url}`, data);
  };

  login(data) {
    data.fullResponse = true;
    return Promise.all([
      Api.post("/admin/login", data),
      // Api.post("admin/login", { ...data, _vortex: true })
    ]);
  }

  sendLoginLink(data){
    return Api.post(`/admin/sendLoginLink`, data)
  }

  loginVerification(token) {
    return Api.post(`/admin/loginVerify?tokenId=${token}`)
  }

  getBrandManager(data) {
    return Api.get("/brandmanager", data);
  }
  getBrandManagerForTring(id) {
    return Api.get(`/brandmanager/${id}`);
  }
  getBrandManagerGateway(data) {
    return Api.get("/brandmanager", data)
  }
  notifyBrand(data) {
    return Api.get("/brandmanager/adminAssist", data)
  }
  getBrandByName(data) {
    let query = JSON.stringify(data);
    return Api.get("/brandmanager/aggregate", { query })
  }
  getBrandBuffer(data) {
    return Api.get("/brandbuffer", data);
  }
  getBrand(data) {
    return Api.get(`/brand`, data);
  }
  getBrandManagerById(id) {
    return Api.get(`/brandmanager?brand=${id}&limit=100`);
  }
   getQuizList(data,skip){
    if(!skip)
    skip=0
    return Api.get(`/quiz?skip=${skip}`,data);
  }
  getBrandAssociation(data) {
    return Api.get("/brandassociate", data);
  }
  getBrandApp(brandAppId){
    return Api.get(`/brandapp/${brandAppId}`)
  }
  postBrandApp(data){
    return Api.post("/brandapp",data)
  }
  updateBrandApp(brand,data){
    return Api.put(`/brandapp/${brand}`,data)
  }
  getInternshipBuffer(data) {
    return Api.get("/internshipbuffer", data);
  }
  getMissionBuffer(data) {
    return Api.get("/missionbuffer", data);
  }
  getActiveTeleProjects = (data) => {
    return Api.get("/teleproject/activeprojects", data)
  }
  getActiveTeleProjectsStats = (data) => {
    return Api.get("/teleproject/activeprojectstats", data)
  }
  getActiveTeleProjectsForHiring = (data) => {
    return Api.get("/teleproject/hiringprojectstats", data)
  }
  getInactiveTeleProjects = (data) => {
    return Api.get("/teleproject/inactiveprojects", data)
  }
  googleTextToSpeech = (data) => {
    return Api.post("interactivescript/textToSpeech", data)
  }
  createScript=(data)=>{
       return Api.post("interactivescript/saveScript", data)
  }
  getScript=(data)=>{
    return Api.get(`/interactivescript?teleproject=${data}` )

  }
  createDuplicateScript=(data)=>{
    return Api.get(`/interactivescript/duplicateScript`, data)
  }

  createDataCollection = (data) => {
    return Api.post(`/interactivescript/addorupdatedatacollectionform`, data)
  }

  createTriggerAction = (data) => {
    return Api.post(`/interactivescript/addorupdateliveactions`, data)
  }

  createEditLabel = (data) => {
    return Api.post(`/interactivescript/addorupdateheading`, data)
  }

  deleteLabel = (data) => {
    return Api.post(`/interactivescript/deleteheading`, data)
  }

  deleteScript = (teleproject) => {
    return Api.delete(`/interactivescript/deleteAllScripts?teleproject=${teleproject}`)
  }

  addorUpdateFaqs = (data) => {
    return Api.post(`/interactivescript/addorupdatefaq`, data)
  }

  getTeleProjectsForAudit= (data) => {

    return Api.get("/qA/projectList", data)
  }

  getTeleprojectsForAutohold = (data) => {
    return Api.get(`/qA/projectlistautoholdcallers`, data)
  }

  getAuditCallersList = (data) => {
    return Api.get("/qA/qmfcallerslist", data)
  }

  getIqcCallersList = (data) => {
    return Api.get("/qA/iqcflaggedcallers", data)
  }

  getIqcFlaggedCallsV2 = (data) => {
    return Api.get("/qA/iqcflaggedcalls", data)
  }

  takeIqcAction = (data) => {
    return Api.get("/teleapplicationAudit/takeiqcaction", data)
  }

  updateqmfscore = (data) => {
    return Api.post("/teletaskHistoryAudit/updateqmfscore", data)
  }

  getCallsOfCaller = (data) => {
    return Api.get("/qA/qmfcallslist", data)
  }

  getQmfscorerulebook = () => {
    return Api.get('/system/qmfscorerulebook')
  }

  getTeleProjectsForAuditCount = (searchQuery = '') => {
    if(searchQuery)
    return Api.get(`/teleproject?count=true&where={"$aggregate":{"$text":{"$search":"${searchQuery}"}}}`)
    else
    return Api.get(`/teleproject?count=true`)
  }

  getCertifiedUserCount = (searchQuery = '') => {
    if(searchQuery)
    return Api.get(`/user?count=true&certifiedCaller=true&where={"$aggregate":{"$text":{"$search":"${searchQuery}"}}}`)
    else
    return Api.get(`/user?count=true&certifiedCaller=true`)
  }

  getComplianceTableData = (data) =>{
    const {rule, ...winningoutcomepayload} = data
    if (data.rule == "rule2A" || data.rule == "rule1A") return Api.get('teletaskHistoryAudit/getFlaggedCallsRule1A2A',winningoutcomepayload)
    else if (data.rule == "rule3A") return Api.get('teletaskHistoryAudit/getFlaggedCallsRule3A',data)
    else if (data.rule == "rule4A") return Api.get('teletaskHistoryAudit/getFlaggedCallsRule4A',data)
    else if (data.rule == "rule5A") return Api.get('teletaskHistoryAudit/getFlaggedCallsRule5A',data)
  }

  getAuditReports = (data) => {
    return Api.post(`teletaskHistoryAudit/getAuditReports`, data)
  }

  getComplianceHistoryTableData = (user,rule) => {
    return Api.get(`teletaskHistoryAudit/callerComplianceHistory?user=${user}&rule=${rule}`)
  }

  getComplianceHistoryCount = (user,rule) => {
    return Api.get(`teletaskHistoryAudit/callerComplianceHistory?user=${user}&rule=${rule}&count=true`)
  }

  calculateQmfScore = (data) => {
    return Api.post(`/teletaskHistoryAudit/qmfscore`, data)
  }

  submitQmfScore = (data) => {
    return Api.post(`/teletaskHistoryAudit/updateqmfscore`, data)
  }

  addBrandCredits = (data) => {
    return Api.post("brandcredit/addBrandCredits", data)

  }
  getCallerList = (skip,limit,searchQuery='',startDate,endDate, workStatus) => {
    return Api.get(`/telecaller/list?${skip ? `skip=${skip}` : null}${limit ? `&limit=${limit}` : ''}&sort={"createdAt":-1}${searchQuery ? `&mobile=${searchQuery}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${workStatus ? `&workStatus=${workStatus}` : ''}`)
  }
  getCountOfCallerWorkStatus = () => {
    return Api.get(`/telecaller/callerstatuswisecount`)
  }
  directCertifyCallers = (data) => {
    return Api.post(`telecaller/certifyByMobile`, data)
  }
  getTeleCallerNumbers = (menuType) => {
    return Api.get(`/teleproject/gettelecallerstatsbydate${menuType ? `?menu=${menuType}` : ''}`)
  }
  getTeleProject = (id,populate, populateQuiz) => {
    return Api.get(`/teleproject/${id}${populate ? `?populate=brand` : populateQuiz ? `?populate=quiz` : ''}`)
  }
  getApplicantList = (data) => {

    return Api.get("/teleapplication", data)
  }
  getteleprojectstatsbydate = () => {
    return Api.get("/teleproject/getteleprojectstatsbydate")

  }
  gettelecallerstatsbydate = () => {
    return Api.get("/teleproject/gettelecallerstatsbydate")

  }
  getBrandCreditList = () => {
    return Api.get("/brandcredit/list")
  }
  getbusinessstats = () => {
    return Api.get("/teleproject/getbusinessstatsbydate")
  }

  getbusinessstatsbydate = (from, to) => {
    return Api.get(`/teleproject/getbusinessstatsbydate?from=${from}&to=${to}`)
  }
  getTeleCallerDetails = (phone) => {
    return Api.get(`telecaller/search?mobile=${phone}`)
  }
  getUserDetails = (number) => {
    return Api.get(`/user?mobile=${number}`)
  }
  getAgentDetails = (number) => {
    return Api.get(`/user/getuser?mobile=${number}`)
  }

  directBanCaller = (data) => {
    return Api.post(`/user/ban`, data)
  }
  certifyUser = (data) => {
    return Api.post("/telecaller/certify", data)
  }
  sendPaymentRequest = () => {
    return Api.post("/teleproject/authorizePayment", { _vortex: false })
  }
  getTeleStats = (id) => {
    return Api.get(`/teleproject/getteleprojectstats?teleproject=${id}`)
  }

  getCallsStats = (data) => {
    return Api.get(`/teleproject/callsStats`, data)
  }
  getMinutesCreditsStats = (data) => {
    return Api.get(`/teleproject/minutesCreditsStats`, data)
  }
  getConcludedLeadsDistribution = (id) => {
    return Api.get(`/viewhistoricalleads/concludedleadsdistribution?teleproject=${id}`)
  }

  getTeleStatCallers = (id) => {
    return Api.get(`/teleproject/getteletaskers?teleproject=${id}`)

  }
  getTeletaskerStats = (projectId, userId) => {
    return Api.get(`/teleproject/getteletaskerstats?teleproject=${projectId}&user=${userId}`)
  }
  getProjectReports=(data)=>{
        // if(len===0)
        // return Api.get(`/teleproject/getprojectReports?teleproject=${id}&startDate=${startDate}&endDate=${endDate}`)
        // else
        // return Api.get(`/teleproject/getprojectReports?teleproject=${id}&emails=${emails}&startDate=${startDate}&endDate=${endDate}`)
        return Api.post(`/teleproject/getprojectReports`, data) 
      }

  getCallerReports = (data) => {
    return Api.post(`/telecaller/getCallerReports`, data) 
  }
  getUnprocessedReports = (data) => {
    return Api.post(`/teletask/getUnprocessedTasksReports`, data)
  }

  transferLeadsById = (data) => {
    return Api.post(`teletask/transferLeadsByIds`, data)
  }

  transferLeadsBySpecificLeadId = (data) => {
    return Api.post(`/teletask/alpleadstransferbyuserids`, data)
  }

  getLiveLeadsReports = (data) => {
    return Api.post(`/teletask/getLiveLeadsReports`, data)
  }

  getQmfCallsAuditReport = (data) => {
    return Api.post(`/qA/getqmfcallsauditreport`, data)
  }
  
  assignUsers = (data) => {
    return Api.post("/teleapplication/createTeleapplications", data)
  }

  directClearIqc =  (data) => {
    return Api.post("/teleapplicationAudit/directcleariqc", data)
  }

  callersReadyForMock = (id, mockoverDueDate) => {
    return Api.get(`/teleapplication/mockCallReadyCallers?teleproject=${id}${mockoverDueDate ? `&mockoverDueDate=${mockoverDueDate}` : ''}`)
  }

  bulkStatusChange = (data) => {
    return Api.post(`/teleapplication/bulkstatuschange`, data)
  }

  placeMockCall = (data) => {
    return Api.post(`/mockcall/call`, data)
  }

  getMockCallData = (data) => {
    return Api.post(`/mockcall/getmockdata`, data)
  }
  coachMockCallStats = (fromDate, toDate, projectId) => {
    if(fromDate && toDate)
    return Api.get(`/mockcall/coachStats?from=${fromDate}&to=${toDate}${projectId ? `teleproject=${projectId}` : ''}`)
    else
    return Api.get(`/mockcall/coachStats?teleproject=${projectId}`)
  }
  mocksConducted = (from, to, data) => {
    return Api.get(`/mockcall/mocksConducted?from=${from}&to=${to}`)
  }
  mockDueStats = (from, to) => {
    return Api.get(`/mockcall/mocksDueStats${from ? `?from=${from}&to=${to}` : `?to=${to}`}`)
  }
  mockAttemptStats = (from, to) => {
    return Api.get(`/mockcall/mocksAttemptedStats${from ? `?from=${from}&to=${to}` : `?to=${to}`}`)
  }
  sendTaskProps=(data)=>{
      return Api.post("teleproject/addOrUpdateTaskProps",data)
  }
  changeUserStatus = (data) => {

    return Api.put("/teleapplication/updateTeleapplications", data)

  }
  getBrands = (data) => {

    return Api.get("/brand?active=true", data)

  }

  getBrandName = (brandid) => {
    return Api.get(`/brand?id=${brandid}`)
  }

  getProjectManager = () => {
    return Api.get(`/admin?role=project_manager&sort={"firstname": 1}`)
  }

  getUsersBasedOnRole = (role) => {
    return Api.get(`/admin?role=${role}`)
  }

  postTeleProject = (data) => {
    return Api.post("/teleproject", data);


  }
  updateTeleProject = (data, id) => {
    return Api.put(`/teleproject/${id}`, data);

  }
  approveBrand(brandbuffer) {
    return Api.post("/brandbuffer/moderate", { brandbuffer });
  }

  rejectBrand(brandbuffer) {
    return Api.post("/brandbuffer/reject", { brandbuffer });
  }

  updateBrandBuffer(data) {
    try {
      delete data.lastlogin;
      delete data.mailfreq;
      delete data.mailactivation;
      delete data.emailtype;
      delete data.email;
    } catch (err) { }

    return Api.put(`/brandbuffer/${data.id}`, data);
  }

  updateInternshipBuffer = data => {
    return Api.put(`/internshipbuffer/${data.id}`, data);
  };

  updateMission = data => {
    return Api.put(`/mission/${data.id}`, data);
  };

  approveInternship(internshipbuffer) {
    return Api.post("/internshipbuffer/moderate", { internshipbuffer });
  }

  rejectInternship(internshipbuffer) {
    return Api.post("/internshipbuffer/reject", { internshipbuffer });
  }

  approveMission(missionId) {
    return Api.put(`/missionbuffer/${missionId}`, { moderation: false });
  }

  rejectMission(missionId) {
    return Api.delete(`/missionbuffer/${missionId}`, {});
  }

  getEvents() {
    return Api.get(`/fest`, {});
  }

  postEvent(data) {
    return Api.post(`/fest`, data);
  }
  postQuiz = (data) => {
    return Api.post(`/quiz`, data)

  }
  addQuizToMissionBuffer = (missionId, data) => {
    return Api.put(`/missionbuffer/${missionId}`, data)

  }
  addQuizToMission = (missionId, data) => {
    return Api.put(`/mission/${missionId}`, data)

  }
  changeQuizName=(quizId,data)=>{
    return Api.put(`/quiz/${quizId}`, data)
  }
  getQuiz = (quizId) => {
    return Api.get(`/quiz/${quizId}`);
  }
  updateQuestions = (data) => {
    return Api.post("quiz/updateQuestions", data)
  }
  addOrUpdateMockForm(data){
    return Api.post("teleproject/addOrUpdateMockForm", data)
  }
  postProjectQc(data){
    return Api.post("teleproject/addOrUpdateQC", data)
  }

  sendMissionToBuffer = (id, data) => {
    return Api.put(`/missionbuffer/${id}`, data)
  }
  liveMissionToBuffer = (id, data) => {
    return Api.put(`/mission/${id}`, data)

  }
  putEvent(data) {
    return Api.put(`/fest/${data.id}`, data);
  }

  moderateBrandAssociate(data) {
    return Api.delete(`/brandassociate/${data.id}`, data);
  }

  getInstagramReport = (type, data) => {
    return Api.post(`/${type}`, data);
  };

  getPartnerQuery = data => {
    return Api.get(`/partnerquery`, data);
  };

  getStudentQuery = data => {
    return Api.get(`/studentquery`, data);
  };

  getMissionApplication = data => {
    return Api.get(`/missionapplication`, data);
  };

  uploadLogo(data) {
    return Api.post("/missionbuffer/taskReferenceUpload", data);
  }

  markMissionAsPaid = mission => {
    return Api.post(`/missionapplication/issueRewards`, { mission });
  };

  getAmazonPendingReport = () => {
    return Api.get("/missionapplication/amazonPendingTransactionReport");
  };

  authorizePayment = mission => {
    return Api.post(`/missionapplication/authorizePayment`, { mission });
  };

  payAmazon = data => {
    return Api.post("wallet/payAmazon", data);
  };

  getPaymentMethods = () => {
    return Api.get("/system", { id: "paymentMethods"});
  };

  getSupportCase = data => {
    return Api.get("/supportcase", data);
  };
  setResolve = id => {
    return Api.put(`/supportcase/${id}?resolved=true`);
  };
  getSupportChat = id => {
    let url = "supportchat/get?supportcase=" + id;
    return Api.get(url);
  };
  setPaymentMethods = data => {
    return Api.put("/system/updatePaymentMethods");
  };
  applicationBulkAction(params, isMission) {
    return Api.post(
      `/${isMission ? "missionapplication" : "application"}/bulkStatusWrite`,
      params
    );
  }

  // deleteOpportunity = id => {
  //   return Api.delete(`/opportunity/${id}`, { _vortex: true });
  // };

  LockTask = data => {
    return Api.post("missionbuffer/taskEdit", data);
  };

  getAutomoderationType = () => {
    return Api.get("missionbuffer/tomRecipes");
  };

  getMissionCategory = () => {
    return Api.get("/missionCategory");
  };

  setMissionCategory = data => {
    return Api.post("/missionCategory", data);
  };

  updateMissionCategory = (id, data) => {
    return Api.put(`/missionCategory/${id}`, data);
  };

  uploadTeleTasks = (data) => {
    return Api.post("/teletask/uploadTeleTasks", data);
  };
  uploadCsvtoSqs = (data) => {
    return Api.post(`/teletask/uploadcsvtosqs`, data)
  }
  dropLeads = (data) => {
    return Api.post("/teletask/dropLeads", data)
  }

  assignLeads = (data) => {
    return Api.post(`/teletask/globalPoolToNCallersMobileInput`, data)
  }

  statsForAssignAllLeads = (data) => {
    return Api.get(`/teletask/statsforassignall`, data)
  }

  statsForAssignTo = (data) => {
    return Api.get(`teletask/statsforassignto`, data)
  }
  
  getProcessUnprocessedTask = (data) => {
    return Api.post(`teletask/processedAndUnprocessedStats`, data)
  }

  startUnMasking = (data) => {
    return Api.get(`teletask/startunmasking`, data)
  }

  unMaskedLeadsCount = (data) => {
    return Api.get(`teletask/getmaskedleadscount`, data)
  }

  updateCallAuditAction=(data)=>{
    return Api.post("/teletaskHistoryAudit/auditCallsRule1A", data);
  }

  transferOpenLeadsToManyAgents = (data) => {
    return Api.post(`/teletask/transferOpenLeadFromOneCallerToManyCaller`, data)
  }

  sendBulkWhatsappMsg = (data) => {
    return Api.post(`teletask/sendbulkwhatsappmessages`, data)
  }

  getTringModerationList=(skip)=>{
     return Api.get(`/trainingapplication?where={"testStatus":"auto-pass"}&populate=user&sort={"createdAt":1}&skip=${skip}&limit=50`);
  }
  getCallAuditList=(skip,id)=>{
     return Api.get(`teletaskHistoryAudit/getFlaggedCallsRule1A?teleproject=${id}&skip=${skip}&limit=50`);
  }
   getCallerAuditList=(id)=>{
     return Api.get(`qA/flaggedCallers?teleproject=${id}`);
  }
  updateCallerStatus=(data)=>{
    return Api.put(`qA/manualAuditCaller`, data);
  }
  updateFaq=(data)=>{
      return Api.post("/teleproject/addOrUpdateFaq", data);
  }
  getunFlaggedCalls=(teleproject,user)=>{
    return Api.get(`qA/unflaggedCalls?teleproject=${teleproject}&user=${user}`);
  }
  fetchAudioInspections=(data)=>{
    return Api.get(`qA/audioInspections`,data);
  }
  getRules=(teleproject,user)=>{
    return Api.get(`qA/callerRulesStats?teleproject=${teleproject}&user=${user}`);
  }
  updateManualGrading(data){
    return Api.post(`/trainingapplication/updateManualGradeData`,data);
  }
  raiseBonusRequest=(data)=>{
     return Api.post("/teleapplication/authorizeBonusPayments", data);
  }

  sendBulkStatusUpdate=(data)=>{
 return Api.put("/teleapplication/updateTeleapplications", data);
  }
  getTeleprojectReports = (data) => {
    return Api.get("teleproject/getTeleprojectReports", data)
  }
  getActiveCallersList=(id)=>{
    return Api.get(`teleapplication/selectedCallers?teleproject=${id}`)
  }
  getSelectedCallersList =(id, alpstage = 'preiqc', skip, limit) => {
    return Api.get(`teleapplication/selectedcallers?teleproject=${id}&alpstage=${alpstage}`)
  }
  getCallOutcomeInsights = (data) => {
    return Api.get("teleapplication/outcomeInsights", data)
  }
  getAppliedCallersList=(id,skip, limit, searchQuery='')=>{
    return Api.get(`teleapplication/appliedCallers?teleproject=${id}&skip=${skip}${limit ? `&limit=${limit}` : ''}${searchQuery ? `&mobile=${searchQuery}` : ''}`)
  }
  getRejectedCallersList = (id, skip, limit, searchQuery='') => {
    return Api.get(`teleapplication/rejectedCallers${id ? `?teleproject=${id}` : ''}&skip=${skip}${limit ? `&limit=${limit}` : ''}${searchQuery ? `&mobile=${searchQuery}` : ''}`)
  }
  getCallersCurrentJobs = (data) => {
    return Api.post("teleapplication/currentJobs", data)
  }
  getTrainingCallersList = (data) => {  
    return Api.get(`teleapplication/trainingCallers`, data)
  }
  getQmfRetrainingCallerList = (data) => {
    return Api.get(`teleapplication/qmfretrainingcallers`, data)
  }
  getIqcRetrainingCallerList = (data) => {
    return Api.get('/teleapplication/iqcretrainingcallers', data)
  }
  getUserAcess = () => {
    return Api.get('/useracess/showMenu')
  }
  getUserName = (admin_id) => {
    return Api.get(`/admin/${admin_id}`)
  }
  getQAFeedbackReason = () => {
    return Api.get(`/qA/getQAFeedbacksReasons`)
  }
  updateQAFeedbackReason = (data) => {
    return Api.put(`/teleapplication/updateTeleapplications`, data)
  }
  addUpdateQCFeedback = (data) => {
    return Api.post(`/qA/addOrUpdateQCFeedback`, data)
  }
  getQCFeedback = (data) => {
    return Api.get(`/qA/addOrUpdateQCFeedback`, data)
  }
  getTeletaskCallHistory = (data) => {
    return Api.get(`/teletaskHistory/callsHistory`, data)
  }
  getCallerDataToDownload = (projectId, userId) => {
    return Api.get(`/teletaskHistory?populate=teletask&teleproject=${projectId}&user=${userId}&where={"exotel.Details.ConversationDuration": {"$gt": 0} }`)
  }

  sendComplianceFeedback = (data) => {
    if(data.rule == "rule1A") return Api.post(`/teletaskHistoryAudit/auditCallsRule1A`, data)
    else if (data.rule == "rule2A") return Api.post(`/teletaskHistoryAudit/auditCallsRule2A`, data)
    else if (data.rule == "rule3A") return Api.post(`/teletaskHistoryAudit/auditCallsRule3A`, data)
    else if (data.rule == "rule4A") return Api.post(`/teletaskHistoryAudit/auditCallsRule4A`, data)
    else if (data.rule == "rule5A") return Api.post(`/teletaskHistoryAudit/auditCallsRule5A`, data)
  }

  sendConsequenceWarning = (data) => {
    return Api.post('/teleapplication/raiseWarning',data)
  }

  sendConsequenceRetrain = (data) => {
    return Api.post('/qA/manualAuditCaller',data)
  }

  getWinningOutcomeCount = () => {
    return Api.get('/teletaskHistoryAudit?where={"rule2A.active":true}&count=true')
  }

  getHighHTCount = () => {
    return Api.get('/teletaskHistoryAudit?where={"rule3A.active":true}&count=true')
  }

  getPerformaceAuditStats = (data) => {
    return Api.get(`/teletaskHistoryAudit/performanceauditstats`, data)
  }

  getParameterWiseDefects = (data) => {
    return Api.get(`/teletaskHistoryAudit/parameterwisedefects`, data)
  }

  getIQCAuditStats = (data) => {
    return Api.get(`teleapplicationAudit/iqcauditstats`, data)
  }

  // Freshdesk
  createTicketFreshdesk = (data) => {
    return Api.post_freshdesk('/tickets', data)
  }
  getIqcFlaggedCallers = (projectId) => {
    return Api.get(`/qA/iqcflaggedcallers?teleproject=${projectId}`)
  }
  getIqcFlaggedCalls = (data) => {
    return Api.get(`/qA/iqcflaggedCalls`, data)
  }
  getIqcParameters = () => {
    return Api.get("/system", { id: "iqcChecks"});
  };
  postIqcScriptCheck = (data) => {
    return Api.post("/qA/iqcCallCheck", data)
  }
  postIqcCallerCheck = (data) => {
    return Api.post('/qA/iqcCallerCheck', data)
  }
  postFinalAuditIQC = (data) => {
    return Api.post(`/qA/manualAuditCaller`, data)
  }

  getQCHistory = (projectId, userId) => {
    let params = ''
    if(projectId && userId){
      params = `?teleproject=${projectId}&user=${userId}`
    }else if(userId && !projectId){
      params = `?user=${userId}`
    }else if(!userId && projectId){
      params = `?teleproject=${projectId}`
    }
    return Api.get(`/qCHistory${params}${params ? `&sort={"createdAt":-1}&where={"rule":{"$exists": false}}` : `?sort={"createdAt":-1}&where={"rule":{"$exists": false}}` }`)
  }

  downloadBrandsCreditReport = (startDate, endDate) => {
    return Api.get(`/brand/minutesSpoken?from=${startDate}&to=${endDate}`)
  }


  // Freshdesk
  createTicketFreshdesk = (data) => {
    return Api.post_freshdesk('/tickets', data)
  }

  //chart api's
  getCallInsightsLineChart({id, startDate, endDate, callParam}) {
    return Api.get(`/brand/projectCallInsights?teleproject=${id}&callParam=${callParam}&startDate=${startDate}&endDate=${endDate}`)
  }

  getDataValidationArray = () => {
    return Api.get("/system", { id: "dataValidation"})
  }

  getExotelAccounts = () => {
    return Api.get(`/system?where={"exotelData":{"$exists": true}}`)
  }

  getExotelAccounts1 = () => {
    return Api.get(`/system/exotelData`)
  }

  updateExotelAccount = (data) => {
    return Api.put(`/system/exotelData`, data)
  }
  
  getCmmRules = () => {
    return Api.get(`/system/cmmRules`)
  }

  getBehaviourWiseCount = (data) => {
    return Api.post(`/teleapplicationAudit/getbehaviourwisecount`, data)
  }

  getCmmActionHistory = (data) => {
    return Api.post(`/teleapplicationAudit/cmmactionhistory`, data)
  }

  getCmmReports = (data) => {
    return Api.post(`teleapplicationAudit/getcmmactionreport`, data)
  }
  
  getCallerQCdata = (data) => {
    return Api.get(`/teleapplicationAudit`, data)
  }
  
  getAppliedCallerListReport = (projectId) => {
    return Api.get(`/teleapplication/appliedCallers?teleproject=${projectId}&skip=0&limit=1000000`)
  }
  getRejectedCallersListReport = (projectId) => {
    return Api.get(`teleapplication/rejectedCallers?teleproject=${projectId}&skip=0&limit=500`)
  }

  getReapplyFeedbacks = () => {
    return Api.get("/system", { id: "reapplyFeedbacks"})
  }

  getProjectListByExophone = () => {
    return Api.get(`/system/projectlistbyexophone`)
  }

  getApiEnabledStats = (data) => {
    return Api.get('/teleproject/postbackHealthStats', data)
  }

  getSmsStats = (projectId) => {
    return Api.get(`/smstemplate/smsStats?teleproject=${projectId}`)
  }

  sendSmsReport = (data) => {
    return Api.post(`/smstemplate/smsReport`, data)
  }

  createSmsTemplate = (data) => {
    return Api.post(`/smstemplate`, data)
  }

  updateSmsTemplate = (templateId, data) => {
    return Api.put(`/smstemplate/${templateId}`, data)
  }

  findSmsTemplates = (projectId) => {
    return Api.get(`/smstemplate?teleproject=${projectId}`)
  }

  testSmsTemplate = (data) => {
    return Api.post(`smstemplate/testSmsTemplate`, data)
  }

  linkOutcomeToTemplate = (data) => {
    return Api.post(`teleproject/addOrUpdateSmsTemplate`, data)
  }

  findWhatsappTemplate = (projectId) => {
    return Api.get(`whatsapptemplate?teleproject=${projectId}`)
  }

  createWhatsappTemplate = (data) => {
    return Api.post(`whatsapptemplate`, data)
  }

  updateWhatsappTemplate = (templateId, data) => {
    return Api.put(`whatsapptemplate/${templateId}`, data)
  }

  linkOutcomeToWhatsappTemplate = (data) => {
    return Api.post(`/teleproject/addOrUpdateWhatsappTemplate`, data)
  }

  getSupportCaseMission = missionId => {
    let url = "supportcase/?mission=" + missionId;
    return Api.get(url);
  };
  getSupportChats = id => {
    let url = "/supportchat/get?supportcase=" + id;
    return Api.get(url);
  };
  getSupportCaseForChat = (category,user) => {
    let url = `supportcase/?category=${category}&user=${user}`;
    return Api.get(url);
  };
  putSupportChats = data => {
    return Api.put("supportchat/put", data);
  };
  setResolveForChat = (id, resolve) => {
    let sendId = id;
    let url = `supportcase/${sendId}`;
    let data = {
      "resolved": resolve
    }
    return Api.put(url,data);
  };
  postSupportCase = (category) => {
    let data = {
      "category": category
    }
    return Api.post("supportcase", data);
  }

  getUserAccountDetails = () => {
    return Api.get(`/admin?skip=0&limit=100`)
  }
  createUserAccount = (data) => {
    return Api.post(`/admin`, data)
  }
  updateUserAccount = (data, userId) => {
    return Api.put(`/admin/${userId}`, data)
  }
  deleteUserAccount = (data) => {
    return Api.post(`/admin/destroytlsassociation`, data)
  }

  forgotPassword = (email) => {
    return Api.post(`/admin/sendresetpasswordlink`, { email })
  }

  changePasword = ({token, password}) => {
    return Api.post(`/admin/changepassword`, {token, password})
  }

  //caller onboarding/roastering api's for new TMP
  getCertifiedCallersList = (data) => {
    return Api.get(`/telecaller/trainee`, data)
  }
  getIdleCallersList = (data) => {
    return Api.get(`/telecaller/idle`, data)
  }
  getMatchedCallerList = (data) => {
    return Api.get(`/telecaller/matched`, data)
  }
  getWorkingCallerList = (data) => {
    return Api.get(`/telecaller/working`, data)
  }
  getAvailableJobs = (data) => {
    return Api.get(`/telecaller/availablejobs`, data)
  }
  matchTelecaller = (data) => {
    return Api.post(`/telecaller/matchtelecaller`, data)
  }
  getCallerCurrentJobs = (data) => {
    return Api.get(`/telecaller/currentjobs`, data)
  }
  getCallerJobHistory = (data) => {
    return Api.get(`/telecaller/previousjobs`, data)
  }
  getCallerQCData = (data) => {
    return Api.get(`/telecaller/qcdata`, data)
  }

  getCallerProfile = (data) => {
    return Api.get(`/telecaller/missionprofiledata`, data)
  }
  getCallerTrainingData = (data) => {
    return Api.get(`/telecaller/trainingdata`, data)
  }

  addUpdateBaseEarning = (data) => {
    return Api.post(`/telecaller/bulkupdateearnings`, data)
  }

  bulkCertify = (data) => {
    return Api.post(`/telecaller/bulkcertifycallers`, data)
  }

  bulkAddOfferLetter = (data) => {
    return Api.post(`/telecaller/bulkaddofferletters`, data)
  }

  bulkMatchAgents = (data) => {
    return Api.post(`/telecaller/bulkmatchtelecaller`, data)
  }

  bulkModeration = (data) => {
    return Api.post(`/telecaller/bulkmoderation`, data)
  }

  getLeadsInfo = (data) => {
    const {teleproject, skip = 0, limit = 1} = data
    return Api.get(`/teletask?teleproject=${teleproject}&skip=${skip}&limit=${limit}`)
  }

  getFollowupCallsList = (data) => {
    const {user, teleproject, marked, skip, limit} = data
    const whereQuery = `{"callstatus": {"$ne": "closed" }, "attempts": {"$gt":0}}`
    return Api.get(`/teletask?user=${user}&teleproject=${teleproject}&marked=${marked}&populate=lastcall&where=${whereQuery}&skip=${skip}&limit=${limit}&sort={"laterwhen":1}`)
  }

  getTotalFollowupCallsList = (data) => {
    const {user, teleproject, marked} = data
    const whereQuery = `{"callstatus": {"$ne": "closed" }, "attempts": {"$gt":0}}`
    return Api.get(`/teletask?user=${user}&teleproject=${teleproject}&marked=${marked}&populate=lastcall&where=${whereQuery}&count=1`)
  }

  getCallHistory = (teletaskId) => {
    return Api.get(`/teletaskHistory?teletask=${teletaskId}`)
  }

  getCallAttendanceTracker = (startDate, endDate, present, paid = '') => {
    let data = {
      from: startDate,
      to: endDate
    }
    if(present){
      data.attendance = present
    }
    if(paid !== ''){
      data.paid = false
    }
    return Api.get(`/telecaller/callerattendancetracker`, data)

    // return Api.get(`/telecaller/callerattendancetracker?from=${startDate}&to=${endDate}${present && '&attendance=present'}${paid && '&paid='}`)
  }

  getCallerAttendance = (startDate, endDate, userId = '') => {
    return Api.get(`/telecaller/callerattendancedetails?from=${startDate}&to=${endDate}&userId=${userId}`)
  }

  updateBasePay = (userId = '', data={}) => {
    return Api.put(`/user/${userId}`, data)
  }

  raisePaymentRequest = (data) => {
    return Api.post(`/payment/raisepayment`, data)
  }
  
  confirmPaymentRequest = (data) => {
    return Api.post(`/payment/confirmpayment`, data)
  }

  checkPaymentStatus = () => {
    return Api.get(`/payment?status=pending&where={"authorizeCode": {"$exists": true}}`)
  }

  getDuePayments = (startDate, endDate) => {
    let data = {
      from: startDate,
      to: endDate
    }
    return Api.get(`/telecaller/duepayments`, data)
  }

  getActiveTeleProjectsWithBrands = (currentdate) => {
    return Api.get(`/teleproject?where={"endDate": {"$gte": ${currentdate}}}&populate=brand&limit=1000&sort={"createdAt":-1}`)
  }

  getRefresherTrainingVideos = (teleprojectId) => {
    return Api.get(`/refreshertraining?teleproject=${teleprojectId}`)
  }

  createRefresherTrainingVideo = (data) => {
    return Api.post(`/refreshertraining`, data)
  }

  updateRefresherTrainingVideo = (data) => {
    return Api.post('/refreshertraining/updatetraining', data)
  }

  uploadRefresherTrainingVideo = (data) => {
    return Api.post(`refreshertraining/uploadvideo`, data)
  }

  getListOfExophones = () => {
    return Api.get('/exophones')
  }

  createUpdatePool = (data) => {
    return Api.post(`exophones/createorupdatepoolinfo`, data)
  }

  createUpdateExophones = (data) => {
    return Api.post(`exophones/createorupdateexophones`, data)
  }

  linkProjectToPool = (data) => {
    return Api.post(`exophones/teleprojectpoollink`, data)
  }

  projectLinkedToPool = (data) => {
    return Api.get(`teleproject`, data)
  }
  
  getActiveExophones = (data) => {
    return Api.get(`exophones/getteleprojectactivepoolconfig`, data)
  }


  //training session apis
  fetchSessionOfProject = (data) => {
    return Api.get(`teleproject/trainingmeetingparticipantstats`, data)
  }
  fetchAgentsForInvite = (projectid) => {
    return Api.get(`teleapplication?teleproject=${projectid}&populate=user&where={"status": {"$in": ["registered", "applied", "pending"]} }&limit=500`)
  }
  fetchCountsOfAgentsForInvite = (projectid) => {
    return Api.get(`/teleapplication?teleproject=${projectid}&count=true`)
  }
  createUpdateTrainingMeetings = (data) => {
    return Api.post(`teleproject/addorupdatetrainingmeetings`, data)
  }
  inviteAgentsToSession = (data) => {
    return Api.post(`teleapplication/invitetotrainingmeeting`, data)
  }
  fetchSessionParticipantList = (data) => {
    return Api.get(`teleapplication/trainingmeetingparticipantlist`, data)
  }
  markAttendanceForAgent = (data) => {
    return Api.post(`teleapplication/marktrainingmeetingattendance`, data)
  }

  bulkMatchAgentToProject = (data) => {
    return Api.post(`telecaller/bulkmatchtelecaller`, data)
  }

  findWhatsappTemplate = (projectId) => {
    return Api.get(`whatsapptemplate?teleproject=${projectId}`)
  }

  fetchNormalizedHeadersByProjectId = (projectId) => {
    return Api.get(`viewhistoricalreportheaders/getnormalizedheadersbyprojectid?teleprojectId=${projectId}`)
  }

  getMissionProfile = (userId) => {
    return Api.get(`missionProfile?user=${userId}`)
  }

  addUpdateMetadata = (data) => {
    return Api.post(`missionProfile/addorupdatemetadata`, data)
  }

  // rebuttal module apis

  //rebuttals api list
  getRebuttalCallsList = (data) => {
    return Api.get(`/teletaskHistoryAudit/rebuttalcalls`, data)
  }
  // find the teletask 
  getTeletask = (id) => {
    return Api.get(`/teletask/${id}`)
  }
  // find the teletaskhistoryy and populate teleproject 
  getTeletaskHistory = (id) => {
    return Api.get(`/teletaskHistory/${id}?populate=teleproject`)
  }
  // update the rebuttalStatus for a feedback with feedbackId 
  auditRebuttalFeedback = (data) => {
    return Api.post(`/teletaskHistoryAudit/auditrebuttalfeedback`, data)
  }

  fetchQuizList = (data) => {
    return Api.get(`quiz/fetch`, data)
  }
}

export default new ApiActions();
