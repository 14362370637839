import React, { useEffect, useState } from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import useSystemExotelApi from '../../../hooks/useSystemExotelApi'
import ThreeDotLoader from "../../utilities/ThreeDotLoader"
import FrappButton from '../../utilities/FrappButton';
import ApiActions from '../../../actions/ApiActions';
import checkIsEmpty from '../../../helper/checkIsEmpty';
import Popup from '../../../uicomponents/popup'
import {RefreshIcon} from '../../../assets/images/index'
import ProjectTitle from '../../common/ProjectTitle';
import { HeaderDeleteIcon } from '../../../assets/images/index';

const ExotelAccountSetup = (props) => {
    const [accountNum, setAccountNumber] = useState([])
    const [isLoading, data, setIsLoading, getSystemExotelData] = useSystemExotelApi()
    const [updateCount, setUpdateCount] = useState(0)
    const [apiMsg, setApiMsg] = useState({
        type: '',
        msg: ''
    })
    const [projectLinkToExotel, setProjectLinkToExotel] = useState([])
    const [show, setShow] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const [showWarning, setShowWarning] = useState(false)
    const [understoodText, setUnderstoodText] = useState('')
    const [showSubmit, setShowSubmit] = useState(false)

    useEffect(() => {
        arrangeExotelData()
    }, [data, updateCount])

    const arrangeExotelData = () => {
        let {projectList} = data
        if (data.exotelData && data.exotelData.length > 0) {
            let result = data.exotelData.map(each => {
                const { sid, account } = each
                const phoneKeyNames = Object.keys(each).filter(function (propertyName) {
                    return propertyName.indexOf("phone") === 0;
                })
                let createObjDynamically = [...phoneKeyNames].map(element => {
                    return { 'number': element, ...each[element], isEdit: false, teleprojects: [], newEntry:false,
                    serial: Number(element.replace(/[a-z]*/gi, '')) }
                }).sort((a,b)=> a.label.localeCompare(b.label));
                if(projectList){
                let projectsOnCallingNumber = projectList.map(list => {
                  if(list.exophone.callingAccount == sid){
                    list =  {
                      teleprojects: list.teleprojects,
                      callingNumber: list.exophone.callingNumber
                    }
                    return list
                  }
                  }).filter(Boolean)
                    createObjDynamically.map(obj => {
                      let projectTitles = projectsOnCallingNumber.map(a => {
                        if(a.callingNumber == obj.number)
                        return a.teleprojects
                      }).filter(Boolean)[0]
                      if(!projectTitles){
                        projectTitles = []
                      }
                      obj.teleprojects = projectTitles
                      return obj
                    })
                }
                return {
                    account,
                    id: sid,
                    [sid]: createObjDynamically
                }
            })
            setAccountNumber(result)
        }
    }

    const addNumber = ({ id }) => {
        const updateAccountNum = accountNum && accountNum.map(item => {
            let nextNumber, getLastElem = ''
            if (item.id == id) {
                let phoneNumbers = item[id].sort((a,b)=>a.number.localeCompare(b.number))
                let order = phoneNumbers && phoneNumbers.slice().sort((a, b) => a.serial - b.serial)
                //if phone numbers dont exist already
                if(checkIsEmpty(phoneNumbers)){
                    nextNumber = "phone1"
                }else{
                    // getLastElem = phoneNumbers[phoneNumbers.length - 1]
                    getLastElem = order && order.slice(-1)[0].serial
                    // nextNumber = "phone" + (parseInt(getLastElem.number.replace("phone", "")) + 1)
                    nextNumber = `phone${getLastElem+1}`
                }
                const obj = {
                    number: nextNumber,
                    callerid: '',
                    label: '',
                    newEntry:true,
                    isEdit:true,
                    teleprojects: [],
                    serial: getLastElem+1
                }
                phoneNumbers = [...phoneNumbers.sort((a,b)=>a.label.localeCompare(b.label)), obj]
                const extractNewValues = phoneNumbers && phoneNumbers.filter(i => i.newEntry)
                const extracOldValues = phoneNumbers && phoneNumbers.filter(i => !i.newEntry)
                const mergeArray = [...extracOldValues, ...extractNewValues]
                item[id] = mergeArray
                return item
            }
            return item
        })
        setAccountNumber(updateAccountNum)
    }

    const editPhone = (number, id) => {
        const updateAccountNum = accountNum.map(item => {
            if (item.id == id) {
                item[id].map(each => {
                    if (each.number == number) {
                        each.isEdit = true
                        return each
                    }
                    return each
                })
            }
            return item
        })
        setAccountNumber(updateAccountNum)
    }

    const updateData = (value, type, accountId, phone) => {
        const updateAccountNum = accountNum.map(item => {
            if (item.id == accountId) {
                item[accountId].map(each => {
                    if (each.number == phone) {
                        console.log('each:',each)
                        each[type] = value
                    }
                })
            }
            return item
        })
        setAccountNumber(updateAccountNum)
    }

    const updateExotelNumbers = async () => {
        let temp = [...data.exotelData]
        let newData = temp.map(item => {
            let id = item.sid
            let obj = {
                account: item.account,
                sid: item.sid,
                key: item.key,
                token: item.token,
                countrycode: item.countrycode,
            }
            let phoneNumbers = accountNum.map(acc => {
                if (acc.id == item.sid)
                    return acc[id]
            }).filter(Boolean)[0]

            phoneNumbers.forEach((num, id) => {
                //check if empty callerid & label is entered for exotel account
                if(checkIsEmpty(num)){
                    return 
                }else{
                    obj[num.number] = {
                        "callerid": num.callerid,
                        "label": num.label
                    }
                }
            })
            return obj
        })

        let dataToSend = {
            exotelData: newData
        }
        try {
            await ApiActions.updateExotelAccount(dataToSend).then(resp => {
                data.exotelData = resp.data.exotelData
                setUpdateCount(count => count + 1)
                if(resp.status == 200){
                    setApiMsg({
                        type: 'success',
                        msg: 'Exotel account setup is updated successfully'
                    })
                }
                removeApiMsg()
            })
        } catch (error) {
            console.error(error.message)
            setApiMsg({
                type: 'failure',
                msg: error.response && error.response.data ? error.response.data.error : error.message
            })
            removeApiMsg()
        }
    }

    const removeApiMsg = () => {
        setTimeout(() => {
            setApiMsg({
                type: '',
                msg: ''
            })
        }, 2000);
    }

    const showExotelLinkedProjects = () => {
        return(
            <Popup
            show={show}
            closePopup={()=>{
                setSelectedData([])
                setShow(false)
            }}
            size='sm'
            heading= {`Projects linked with ${selectedData.exophone}`}
            >
                <div className='exotel-linked-projects'>
                {
                    selectedData.teleprojects && selectedData.teleprojects.length > 0 ? 
                        selectedData.teleprojects.map((data, idx) => {
                        return <p className='project-list-name'>{data.title}</p>
                    })
                    :
                    <p>No teleprojects are linked</p>
                }
                </div>
            </Popup>
        )
    }

    const showDeleteWarning = () => {
        console.log('text:',understoodText)
        return (
            <Popup
            show={showWarning}
            heading='Warning!'
            closePopup = {()=>{
                setSelectedData([])
                setShowWarning(false)
                setUnderstoodText('')
            }}
            >
                <div className='delete-warning'>
                    This action might stop calling on linked projects.<br/>
                    If you understand the risk and wish to proceed anyway, please type 'I UNDERSTAND' in the textbox below.<br/><br/>
                    <input 
                        type='text'
                        value={understoodText}
                        onChange={event=>{setUnderstoodText(event.target.value); setShowSubmit(event.target.value=='I UNDERSTAND')}}
                    /><br/><br/>
                    <FrappButton className="submit" id="new-btn" disabled={!showSubmit} handler={()=>deleteNumber()}>
                        Submit
                    </FrappButton>
                </div>
            </Popup>
        )
    }

    const deleteNumber = () => {
        const updateAccountNum = accountNum.map(item => {
            if (item.id == selectedData.item.id) {
                let phoneNumbers = item[selectedData.item.id]
                item[selectedData.item.id] = phoneNumbers.filter(phone => phone.callerid !== selectedData.exophone)
                return item
            }
            return item
        })
        setAccountNumber(updateAccountNum)
        setShowWarning(false)
        setUnderstoodText('')
        setSelectedData([])
    }

    return isLoading ? <ThreeDotLoader /> :
            <div className='exotel-container'>
            <div className='title'><ProjectTitle title={`Exotel Account Setup`} goBack={() => props.history.push("/tools")}/></div>
            <div className='exotel-acc-wrapper'>
            <div className='refresh-task-count' onClick={() =>{setIsLoading(true); getSystemExotelData()}}>
            <img src={RefreshIcon} alt='refresh'/>
            Refresh
            </div>
            <div className='crud-form'>
                {
                    accountNum.map((item, idx) => {
                        const id = item.id
                        return <div key={idx + 1}>
                            <h3>{item.account} ({item[id].length})</h3>
                            <div className='grey-box-wrapper w75 p-0 account-setting'>
                                <div className='dotted-border p-4'>
                                    {
                                        item[id].map((ph, idx) => {
                                            return <div className='label-num-row' key={idx + 1}>
                                                <div className='first-name'>
                                                <FormGroup>
                                                    <ControlLabel>Name</ControlLabel>
                                                    <FormControl
                                                        required
                                                        placeholder="Enter name"
                                                        name="name"
                                                        value={ph.label}
                                                        disabled = {ph.newEntry?!ph.newEntry:true}
                                                        onChange={(e) => updateData(e.target.value, 'label', item.id, ph.number)}
                                                    />
                                                </FormGroup>
                                                </div>
                                                <div className='first-phone'>
                                                <FormGroup>
                                                    <ControlLabel>Phone no.</ControlLabel>
                                                    <FormControl
                                                        required
                                                        type='number'
                                                        placeholder="Phone no"
                                                        name="callerId"
                                                        value={ph.callerid}
                                                        disabled={ph.newEntry?!ph.newEntry: !ph.isEdit}
                                                        onChange={(e) => updateData(e.target.value, 'callerid', item.id, ph.number)}
                                                    />
                                                </FormGroup>
                                                </div>
                                                <div className={`underline-cta-blue ${ph.isEdit ? 'opa-0' : 'opa-1'}`}
                                                    disabled={ph.isEdit}
                                                    onClick={() => editPhone(ph.number, item.id)}>Edit</div>
                
                                                <div className={`underline-cta-blue projectsCount ${ph.isEdit ? 'opa-0' : 'opa-1'}`}
                                                onClick={ph.isEdit ? () => {} 
                                                :
                                                ()=>{
                                                    setSelectedData({teleprojects: ph.teleprojects, exophone: ph.callerid})
                                                    setShow(true)
                                                }}>
                                                    Linked Projects ({ph.teleprojects && ph.teleprojects.length || 0})
                                                </div>

                                                <div className='del-icon' 
                                                onClick={()=>{
                                                    setSelectedData({teleprojects: ph.teleprojects, exophone: ph.callerid, item:item})
                                                    setShowWarning(true)
                                                }}>
                                                    <img src={HeaderDeleteIcon} style={{color:'red'}}/>
                                                </div>

                                            </div>
                                        })
                                    }
                                    <div className='underline-cta-navyblue'
                                        onClick={() => addNumber(item)}>+Add Number</div>
                                </div>
                            </div>
                        </div>
                    })
                }
            <div className='cta-section'>
            <FrappButton className="submit" id="new-btn"
            handler={() => updateExotelNumbers()}>SAVE</FrappButton>
            {
            !checkIsEmpty(apiMsg) ?
            <div className={apiMsg.type == 'success' ?
            'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
            : null
            }
            </div>
            </div>
            {show ? showExotelLinkedProjects() : null}
            {showWarning ? showDeleteWarning() : null}
            </div>
            </div>
}

export default ExotelAccountSetup