import React, {useState} from 'react'
import styles from './upload.module.scss'
import TruncateString from '../../helper/TruncateString'

const Upload = (props) => {
    const [filename, setFilename] = useState('')

    const changeHandler=(e)=>{
        if (e.target.files.length > 0) {
         let filename = e.target.files[0].name;
          setFilename(filename)
          return props.onChange(e)
        }
    }

    return(
        <div>
        <label className={styles.file}>
        <input type="file" id="file" aria-label="File browser example" onChange={changeHandler}/>
        <span className={styles.file_custom}>{filename ? TruncateString(filename, '45') : props.placeholder}</span>
        </label>
        </div>
    )
}

export default Upload