import React, {useState, useEffect} from 'react'
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { CallButton, ErrorIcon, RefreshIcon } from '../../assets/images'
import ApiActions from '../../actions/ApiActions';
import jwt_decode from "jwt-decode";
import capitalise from '../../helper/Capitalise'
import ProjectTitle from '../../components/common/ProjectTitle';

const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) && !isNaN(parseFloat(str))
}

const callReasonsCollection = [
    {
        label: 'Pre placement',
        value: 'prePlacement'
    },
    {
        label: 'Training',
        value: 'training'
    },
    {
        label: 'Work fulfillment',
        value: 'workFulfillment'
    }
]
const CallDialer = (props) => {
    const [mobileNumber, setMobileNumber] = useState('')
    const [callReason, setCallReason] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [controlUser, setControlUser] = useState('')
    const [successMsg, setSuccessMsg] = useState('')

    useEffect(() => {
        getControlUserDetails()
    }, [])

    const getControlUserDetails = async() => {
        try{
            let result = await ApiActions.getUserName(jwt_decode(localStorage.authToken).base)
            setControlUser(result)
        }catch(err){
            console.error(err.response)
        }
    }

    const checkIfRegMobNum = async() => {
        if(!callReason){
            setErrMsg('Please select the call reason')
        }else{
            setErrMsg('')
            try{
                let result = await ApiActions.getTeleCallerDetails(mobileNumber)
                if(result)
                placeCall()
            }catch(err){
                console.error('error', err.response.status)
                setErrMsg('Please enter registered mobile number')
            }
        }
    }

    const placeCall = async() => {
        const payload = {
            userMobile: mobileNumber,
            teamCallAccount: "ops",
            callReason: callReason.value
        }
        try{
            let result = await ApiActions.placeMockCall(payload)
            if(result)
            setSuccessMsg('Caller will receive the call once you receive the call from exotel account')
        }catch(err){
            console.error(err.response)
        }
    }

    const resetState = () => {
        setMobileNumber('')
        setSuccessMsg('')
        setErrMsg('')
        setCallReason('')
    }

    return(
        <div className='container'>
            <div className={styles['call-dialer']}>
            <div className={styles['top']}>
            {/* <div className={styles['header']}>Dialer</div> */}
            <ProjectTitle title={`Dialer`} goBack={() => props.history.push("/tools")} />
            <div className={styles['call-type']}>
                <span className={styles['label']}>Call Reason</span>
                <DropdownButton
                bsSize="medium"
                title={callReason ? callReason.label : 'Select'}
                >
                {
                    callReasonsCollection.map((reason, i) => {
                        return <MenuItem eventKey={i+1} onSelect={() => setCallReason(reason)}>{reason.label}</MenuItem>
                    })
                }
                </DropdownButton>
            </div>
            </div>
            <div className={styles['dialer-box']}>
            <div className={styles['first-section']}>
                <span className={styles['label']}>Call placed to</span>
                <div className={styles['mobile-field']}>
                <input type='text'  minLength="10" maxLength="13"
                value={mobileNumber} onChange={(e) => {
                    if(e.target.value){
                        setMobileNumber(e.target.value)
                    }else{
                        setErrMsg('')
                        setMobileNumber('')
                    }
                }}/>
                <img src={CallButton} alt='Call button' onClick={() => checkIfRegMobNum()}/>
                <img src={RefreshIcon} alt='refresh' className={styles['refresh']} onClick={resetState}/>
                </div>
                {
                mobileNumber !== '' ?
                isNumeric(mobileNumber) ?
                null :
                <><img src={ErrorIcon} alt='alert' style={{width: '22px', marginRight: '3px'}}/><span className={styles['error-msg']}>Invalid number</span></>
                : null
                }
                {
                    errMsg ? <span className={styles['error-msg']}>
                    <img src={ErrorIcon} alt='alert' style={{width: '22px', marginRight: '3px'}}/>{errMsg}</span> : null
                }
                {
                    successMsg ? <span className={styles['success-msg']}>{successMsg}</span> : null
                }
            </div>
           
            <span className={styles['label']}>Call placed by: {controlUser.mobile} | {capitalise(controlUser.firstname) || 'NA'}</span>
            </div>
            <div className={styles['user-note']}>
            *All calls made via this dialer are recorded and stored for review
            </div>
        </div>
        </div>
    )
}

export default CallDialer