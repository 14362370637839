import React from 'react';
import Col from 'react-bootstrap/lib/Col';


const brandManagerMetaInfo = {
	firstname: {
		label: 'First Name',
		type: 'text'
	},
	lastname: {
		label: 'Last Name',
		type: 'text'
	},
	phone: {
		label: 'Mobile No',
		type: 'text'
	},
	email: {
		label: 'Email',
		type: 'text'
	},
	brandmanagerProof: {
		label: "Brand Manager Proof",
		type: "link"
	},
	id: {
		label: 'ID',
		type: 'text'
	},
	fieldsToExclude: ['createdAt', 'updatedAt', 'emailVerified', 'brand']
}
const brandMetaInfo = {
	name: {
		label: 'Name',
		type: 'text'
	},
	description: {
		label: 'Description',
		type: 'text'
	},
	asseturl: {
		label: 'Logo',
		type: 'image'
	},
	websiteurl: {
		label: 'Website',
		type: 'link'
	},
	brandmanager: {
		label: 'Brand Manager',
		type: 'object'
	},
	id: {
		label: 'ID',
		type: 'text'
	},
	fieldsToExclude: ['moderation', 'createdAt', 'updatedAt', 'mailfreq', 'emailtype', 'mailactivation', 'active', 'lastlogin', 'moderationRequested', 'moderationFeedback', 'liveState']
}

const constants = {
	loadingMessages: {
		'Add Filter': 'Add Filter',
		'Submit': 'Submitting..',
		'SUBMIT': 'SUBMITTING..',
		'submit upload_CSV': 'Uploading CSV..',
		'Save': 'Saving...'
	},
	missionApplicationStatuses: [{
		label: "Pending",
		value: "pending"
	}, {
		label: "Selected",
		value: "selected"
	}, {
		label: "Shortlisted",
		value: "waitlisted"
	}, {
		label: "Completed",
		value: "completed"
	}, {
		label: "Rejected",
		value: "rejected"
	}],
	taskStatuses: [{
		label: "Registered",
		value: "registered"
	}, {
		label: "Under Review",
		value: "underreview"
	}, {
		label: "Redo",
		value: "redo"
	}, {
		label: "Overdue",
		value: "overdue"
	}, {
		label: "Rejected",
		value: "rejected"
	}, {
		label: "Approved",
		value: "approved"
	}, {
		label: "Failed",
		value: "failed"
	}],
	database: {
		models: [
			'Brandmanagers', 'Brands', 'Internships', 'Missions',
			'Applications', 'Missionapplications', 'Colleges', 'Users', 'City'
		],
		// This is for report file
		fields: {
			brandmanager: ['id', 'createdAt', 'email', 'phone', 'firstname', 'lastname', 'brand'],
			brand: ['id', 'createdAt', 'name'],
			internship: ['id', 'createdAt', 'title', 'citiesvalid', 'brand', 'categories'],
			mission: ['id', 'createdAt', 'name', 'cities', 'brand'],
			application: ['id', 'createdAt', 'status', 'internship', 'user'],
			missionapplication: ['id', 'createdAt', 'status', 'mission', 'tasks.status', 'user'],
			user: ['id', 'createdAt', 'college', 'status', 'city', 'firstname', 'lastname', 'gender', 'email', 'phone', 'mobile'],
			college: ['id', 'createdAt', 'name', 'city'],
			city: ['id', 'createdAt', 'name']
		},
		// This is for table view
		fieldsMetaInfo: {
			brandmanager: {
				type: 'exclude',
				list: ['createdAt', 'updatedAt', 'brandmanagerProof']
			},
			internship: {
				type: 'exclude',
				list: ['createdAt', 'updateAt', 'id']
			},
			missionapplication: {
				type: 'include',
				list: ['id', 'status', 'mission']
			},
			mission: {
				type: 'include',
				list: ['id', 'name']
			},
			user: {
				type: 'include',
				list: ['firstname', 'lastname', 'city', 'status', 'gender', 'college', 'mobile', 'collegeInput']
			},
			brand: {
				type: 'include',
				list: ['id', 'name']
			},
			college: {
				type: 'include',
				list: ['id', 'name', 'city']
			}
		}
	},
	//This is for moderation page
	fieldsMetaInfo: {
		brandassociate: {
			brandbuffer: {
				label: 'Brand',
				type: 'object'
			},
			brandmanager: {
				label: 'Brand Manager',
				type: 'object'
			},
			fieldsToExclude: ['createdAt', 'updatedAt', 'id']
		},
		brandmanager: brandManagerMetaInfo,
		brandbuffer: brandMetaInfo,
		brand: brandMetaInfo,
		userDetails: {
			email: {
				label: 'Email',
				type: 'mail'
			},
			phone: {
				label: 'Phone',
				type: 'text'
			},
			city: {
				label: 'City',
				type: 'text'
			},
			fieldsToExclude: []
		},
		tasks: {
			title: {
				label: 'Title',
				type: 'text'
			},
			description: {
				label: 'Description',
				type: 'html'
			},
			timeToDo: {
				label: "Time to do",
				type: 'function',
				callback: (value) => value / (60 * 60 * 24)
			},

			taskStartDate: {
				label: 'Task Start Date',
				type: 'date'
			},
			taskEndDate: {
				label: 'Task End Date',
				type: 'date'
			},
			category: {
				label: 'Category',
				type: 'text'
			},
			proofType: {
				label: 'Proof Type',
				type: 'text'
			},
			presetId: {
				label: "Preset Id",
				type: 'text'
			},
			target: {
				label: "Target",
				type: 'text'
			},
			taskReference: {
				label: 'Reference Image',
				type: 'image'
			},
			taskDemo: {
				label: 'Task Demo',
				type: 'url'
			},
			multiProofLimit: {
				label: 'Multiproofs to be submitted',
				type: 'text'
			},
			fieldsToExclude: ['createdAt', 'updatedAt', 'id', 'share', 'iconurl', 'moderation', 'active']
		},
		missionbuffer: {
			name: {
				label: 'Name',
				type: 'text'
			},
			description: {
				label: 'Description',
				type: 'html'
			},
			terms: {
				type: 'function',
				callback: (value) => {
					return <Col dangerouslySetInnerHTML={{ __html: value.brandmanager }} xs={12} sm={12} md={12} className={`value`} />
				},
				label: 'Terms'
			},
			questions: {
				type: 'array',
				label: 'Questions'
			},
			cities: {
				type: 'array',
				label: 'cities'
			},
			gender: {
				type: 'array',
				label: 'Gender'
			},
			colleges: {
				type: 'array',
				label: 'Colleges'
			},
			payWall: {
				label: 'PayWall Exists',
				type: 'html'
			},

			paymentDone: {
				label: 'Payment Done',
				type: 'html'
			},
			otherRequirements: {
				label: 'Other Requirements',
				type: 'text'
			},
			otherBenefits: {
				label: 'Other Benefits',
				type: 'text'
			},
			maxSelected: {
				label: 'Max Selected',
				type: 'text'
			},
			maxApplied: {
				label: 'Max Applied',
				type: 'text'
			},
			reward: {
				label: 'Reward',
				type: 'text'
			},
			applicationEndDate: {
				label: 'Application End Date',
				type: 'date'
			},
			campaignStartDate: {
				label: 'Campaign Start Date',
				type: 'date'
			},
			campaignEndDate: {
				label: 'Campaign End Date',
				type: 'date'
			},
			tasks: {
				type: 'array',
				label: 'Tasks'
			},
			id: {
				type: 'string',
				label: 'ID'
			},
			brand: {
				label: 'Brand',
				type: 'object'
			},
			brandmanager: {
				label: 'Brand Manager',
				type: 'object'
			},
			multiProofLimit: {
				label: 'Multiple Proof Limit',
				type: 'number'
			},
			fieldsToExclude: ['createdAt', 'updatedAt', 'id', 'platform', 'minimumrequiredstatus', 'certificate', 'moderation', 'published', 'moderationRequested', 'moderationFeedback', 'liveState']
		},
		internshipbuffer: {
			title: {
				label: 'Title',
				type: 'text'
			},
			description: {
				label: 'Description',
				type: 'html'
			},
			categories: {
				label: 'Categories',
				type: 'array'
			},
			skills: {
				label: 'Skills required',
				type: 'array'
			},
			requirements: {
				label: 'Requirements',
				type: 'html'
			},
			benefits: {
				label: 'Benefits',
				type: 'html'
			},
			workPlace: {
				label: 'Work Place',
				type: 'text'
			},
			workxstarting: {
				label: 'Internship Start Date',
				type: 'text'
			},
			period: {
				label: 'Internship Duration',
				type: 'text'
			},
			applicationEndDate: {
				label: 'Last date to apply',
				type: 'date'
			},
			positions: {
				label: 'No of positions',
				type: 'text'
			},
			questions: {
				label: 'Interview Questions',
				type: 'array'
			},
			stipend: {
				label: 'Stipend',
				type: 'text'
			},
			citiesvalid: {
				label: 'Cities valid',
				type: 'array'
			},
			views: {
				label: 'Views',
				type: 'text'
			},
			brand: {
				label: 'Brand',
				type: 'object'
			},
			brandmanager: {
				label: 'Brand Manager',
				type: 'object'
			},
			fieldsToExclude: ['applicationCount', 'createdAt', 'updatedAt', 'id', 'moderation', 'position', 'internshipStartDate', 'active', 'minimumrequiredstatus', 'stipend', 'moderationFeedback', 'moderationRequested', 'liveState']
		}
	},
	roles:[
		{label: 'Admin', value: 'admin'},
		{label: 'Account Manager', value: 'project_manager'},
		{label: 'Content Manager', value: 'content_manager'},
		{label: 'Coach', value: 'coach'},
		// {label: 'POD Lead', value: 'pod_manager'},
		{label: 'QA Agent', value: 'qa_agent'},
		{label: 'Support', value: 'support'},
		{label: 'TMP', value: 'tmp'},
		{label: 'Team Lead', value: 'team_lead'}
	],
	customRole: {
		ADMIN: 'admin',
		PROJECT_MANAGER: 'project_manager',
		CONTENT_MANAGER: 'content_manager',
		QA_AGENT: 'qa_agent',
		SUPPORT: 'support',
		TMP: 'tmp',
		COACH: 'coach',
		TEAM_LEAD: 'team_lead'
	},
	scriptConstants: {
		START: 'Start',
		WRONG_NUMBER: 'Wrong Number',
		LANG_BARRIER: 'Language Barrier',
		NOT_INTERESTED: 'Not Interested',
		SCHEDULE_CALL_BACK: 'Schedule Call Back',
		REDIAL: 'Redial',
		BUSY_IMPRPR_RESP: 'Busy or Improper Response',
		TECH_ISSUE: 'Technical Issue',
		INTRODUCTION: 'Introduction',
		QUICK_PITCH: 'Quick Pitch',
		MAIN_PITCH: 'Main Pitch',
		CLOSING: 'Closing'
	},
	standardOutcomes: [
		{
			title: 'Wrong Number',
			description: 'Select this when the name and other details do not match or the receiver says it is a wrong number',
			isWinning: false,
			isFollowup: false
		},
		{
			title: 'Language Barrier',
			description: 'Select this if the person you are speaking to cannot understand or speak in Hindi/English and you are unable to communicate in any other language they understand',
			isWinning: false,
			isFollowup: false
		},
		//deprecate the not interested as standard outcome from April 2024
		// {
		// 	title: 'Not Interested',
		// 	description: 'Select this if the lead is not interested and has clearly mentioned the reason as well',
		// 	isWinning: false,
		// 	isFollowup: false
		// },
		{
			title: 'Schedule Call Back',
			description: 'Select this outcome only when you have pitched to the customer, built a rapport with them and they have requested for a call back at a specific date and time.',
			isWinning: false,
			isFollowup: false
		},
		{
			title: 'Redial',
			description: 'Select this outcome only when you need to instantly call the customer again to close the call successfully. Ex. Missed collecting 1 data point or phone got cut but customer was interested.',
			isWinning: false,
			isFollowup: false
		},
		{
			title: 'Busy or Improper Response',
			description: 'Select this outcome if the customer didn’t give a proper response, or if no other outcome is suitable.',
			isWinning: false,
			isFollowup: false
		},
		{
			title: 'Not Connected',
			description: 'Select this outcome if the call is not connected',
			isWinning: false,
			isFollowup: false
		},
		{
			title: 'Voicemail',
			description: 'Select this outcome if the call goes to automatic voicemail service',
			isWinning: false,
			isFollowup: false
		},
	],
	banReasons: [
		{label: 'Calling malpractice committed', value: 'Calling malpractice committed'},
		{label: 'Call dragging and duration malpractice', value: 'Call dragging and duration malpractice'},
		{label: 'Flouting norms and policy', value: 'Flouting norms and policy'},
		{label: 'Speaking rudely or improperly on call', value: 'Speaking rudely or improperly on call'},
		{label: 'Duplicate account created or account shared', value: 'Duplicate account created or account shared'}
	],
	statuses: [
		//teleapplication
		{label: 'Registered', value: 'registered'},
		{label: 'Applied', value: 'applied'},
		{label: 'Pending', value: 'pending'},
		{label: 'Selected', value: 'inprogress'},
		{label: 'IQC Hold', value: 'iqchold'},
		{label: 'Quit', value: 'quit'},
		{label: 'Reapply', value: 'reapply'},
		{label: 'On Hold', value: 'onhold'},
		{label: 'Training', value: 'training'},
		{label: 'Rejected', value: 'rejected'},
		//teletask
		{label: 'Claimed', value: 'claimed'},
		{label: 'Inprogress', value: 'in-progress'},
		{label: 'Closed', value: 'closed'},
		{label: 'Call Later', value: 'call-later'},
		{label: 'Forbidden', value: 'forbidden'},
		{label: 'Invalid', value: 'invalid'},
		{label: 'Abandoned', value: 'abandoned'},
		{label: 'Failed', value: 'failed'},
		{label: 'No-Answer', value: 'no-answer'},
		{label: 'Busy', value: 'busy'},
		//teleapplication & teletask
		{label: 'Completed', value: 'completed'}

	],
	projectCategories: [
		{
			category: 'Logistics',
			subcategories: [
				'NDR Management',
				'Confirmation calls - Address/Delivery/Pincode/COD'
			]
		},
		{
			category: 'Sales',
			subcategories: [
				'Pre-Sales',
				'End to End Sales'
			]
		},
		{
			category: 'Operations',
			subcategories: [
				'Customer support',
				'Process completion',
				'Data collection'
			]
		}
	]
}

export default constants;