import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from "../utilities/FrappButton";
import cloneDeep from "lodash/cloneDeep";
export default class ProjectQc extends Component {
     constructor(props){
        super(props);
        this.state={
            formData:{},
            isLoading:true,
            qualityCheck:{},
            rule1A:{},
            winningOutcomes:{},
            winningOutcomesMHT:[],
            auditPercentage: 0
        }
    }

        componentDidMount() {
              this._isMounted = true;
             
               if(this._isMounted){
                     let id = this.props.match.params.id;
                      ApiActions.getTeleProject(id).then(resp=>{
                       var winning= resp&&resp.outcomes.filter(data=>{
                            return data.isWinning
                        })
                        var testOutcome={};
                      winning.map(win=>{
                          testOutcome[win.title]=""
                          this.setState({rule1A:testOutcome})
                      })
                        this.setState({formData:resp,isLoading:false,winningOutcomes:winning})
                        if(resp.qc){
                          this.setState({qualityCheck:resp.qc})
                           if(resp.qc.rule1A){
                                var originalRule1A=cloneDeep(resp.qc.rule1A)
                               this.setState({originalRule1A:originalRule1A})
                               Object.keys(testOutcome).map(ot=>{
                                winning.map(op=>{
                                    if(op.title===ot)
                                    {  
                                        testOutcome[op.title]=originalRule1A[ot];
                                        this.setState({rule1A:testOutcome})
                                    }
                                })
                                })
                            }

                            if(resp.qc.rule2A){
                                var originalEligibility = cloneDeep(resp.qc.rule2A.eligibility)
                                this.setState({auditPercentage:originalEligibility})
                            }

                            if(resp.maxTimeLimit){
                                 let maxTimeLimit=resp.maxTimeLimit
                            this.setState({maxTimeLimit})
                           }
                        }
                      })
               }
        }

         componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
    let {formData,isLoading,auditPercentage}=this.state;
        if(!isLoading)
        return (
            <form onSubmit={(e)=>this.submitQC(e)}>
                <div className="qc-form-wrapper" >
                     <div className="heading">
                          <div className="arrow" onClick={() => this.handleBack()}>  
                      <img src={require("../../assets/images/back-arrow.png")} /> 
                     <div className="back">Back</div>
                     </div>
                         <div><h4>{formData.title}</h4></div><h5>Step 5: Project QC </h5>
                    </div>
                  {this.renderQCFields(formData)}
                     <div className="heading-new">  Minimum Handling Time For Winning Outcome</div>
                     {/* <div className="note"> Select Winning Outcomes to add MHT</div> */}
                  {this.renderMHTfields()}
                        <ToastContainer draggable={true} />
                        <div>
                        <FormGroup className="each-form">
                            <div className="heading">Winning Outcome Audit Percentage</div>
                            <FormControl 
                             value={auditPercentage} 
                            onChange={(e) => this.setAuditPercentage(e)} name="auditPercentage" type="number" placeholder="Call Audit %" />
                        </FormGroup>
                        </div>
                          <div className="btn-wrapper">
                       
                         <FrappButton type="submit" className="submit" ref={(c)=>this.submitQCBtn=c} >Save</FrappButton>

                          <FrappButton className="submit" id="new-btn-2" 
                       ref={(c) => this.nextBtn = c} 
                       handler={() =>   this.props.history.push({
                        pathname: `/communication-template/${this.props.match.params.id}`,
                        state: {
                            formData
                        }
                    })}> Skip</FrappButton>
                  </div>
                </div>
            </form>

        )
         else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
    }

    renderMHTfields=()=>{
        let {winningOutcomes,rule1A}=this.state;
        return(
            <div className="pill-wrapper"> 
           
                {/* <div className="pills">
                    {
                       winningOutcomes&&winningOutcomes.map(outcome=>{
                            return <div className={outcome.mhtAdded?"pill":"pill-empty"} onClick={()=>this.addMht(outcome)}>
                                {outcome.title}</div>
                        })
                    }
                </div> */}

                <div className="pills">
                    {
                     Object.keys(rule1A).map(outcome=>{
                            return(
                             <FormGroup id="mht" className="each-form">
                                 <div className="mht-name"> {outcome}</div>
                            <FormControl value={rule1A[outcome]}
                            onChange={(e)=>this.setMHT(e,outcome)} type="number" placeholder="Min Handling Time" />
                            </FormGroup>
                            )
                        })
                         
                    }
                 
                </div>
           

            </div>
        )
    }
    setMHT=(e,outcome)=>{
        let {rule1A}=this.state;

        rule1A[outcome]=parseInt(e.target.value);
        this.setState({rule1A})
    }
    submitQC=(e)=>{
        e.preventDefault();
        this.submitQCBtn.startLoading();

        let {qualityCheck,rule1A,formData,auditPercentage}=this.state;
        let {maxTimeLimit}=this.state;
        var mhtToSend={};
          // If both not added
        Object.keys(rule1A).map(prop=>{
            if (rule1A[prop]!==null&&!isNaN(rule1A[prop]))
           mhtToSend[prop]=rule1A[prop]
            
        })
        if((Object.keys(mhtToSend).length === 0 && mhtToSend.constructor === Object||typeof mhtToSend == 'undefined')&&(typeof maxTimeLimit == 'undefined'||isNaN(maxTimeLimit)))
        {
     alert("Nothing to Add")     
            var dataToSend={}
        }
        // If no winning outcome MHT added but Max Time Limit added
        if((Object.keys(mhtToSend).length === 0 && mhtToSend.constructor === Object||typeof mhtToSend == 'undefined')&&(typeof maxTimeLimit != 'undefined'&& !isNaN(maxTimeLimit)))
        {
       dataToSend={
            teleproject: formData.id,
            maxTimeLimit: maxTimeLimit
            }   
        if (auditPercentage) {
            dataToSend.qc = {
                rule2A : {
                    eligibility:auditPercentage 
                }
            }
        } else{
            dataToSend.qc = {
                rule2A : {
                    eligibility: isNaN(auditPercentage) ? 0 : auditPercentage
                }
            }
        }
        }
        //If MHT added but no Max Time Limit
         else if(Object.keys(mhtToSend).length != 0 && mhtToSend.constructor === Object&&(typeof maxTimeLimit == 'undefined'||isNaN(maxTimeLimit))){
            dataToSend={
            teleproject: formData.id,
            qc: {
                rule1A: mhtToSend
            }
            }
            if (auditPercentage) {
                dataToSend.qc = {
                    ...dataToSend.qc,
                    rule2A : {
                        eligibility:auditPercentage 
                    }
                }
            }else{
                dataToSend.qc = {
                    ...dataToSend.qc,
                    rule2A : {
                        eligibility: isNaN(auditPercentage) ? 0 : auditPercentage 
                    }
                }
            }
        } 
        // If both added
           else if(Object.keys(mhtToSend).length != 0 && mhtToSend.constructor === Object&&(typeof maxTimeLimit != 'undefined'&& !isNaN(maxTimeLimit)))
        { 
            dataToSend={
            teleproject: formData.id,
            maxTimeLimit:maxTimeLimit,
            qc: {
                rule1A: mhtToSend
            }
            }
            if (auditPercentage) {
                dataToSend.qc = {
                    ...dataToSend.qc,
                    rule2A : {
                        eligibility:auditPercentage 
                    }
                }
            }else{
                dataToSend.qc = {
                    ...dataToSend.qc,
                    rule2A : {
                        eligibility: isNaN(auditPercentage) ? 0 : auditPercentage
                    }
                }
            }
         } 
        if (Object.keys(dataToSend).length != 0 && dataToSend.constructor === Object)
        ApiActions.postProjectQc(dataToSend).then(resp=>{
            this.submitQCBtn.stopLoading()
            toast("QC added")
            this.props.history.push({
                pathname: `/communication-template/${resp.data.data.id}`,
                state: {
                    formData
                }
            })
        }
            ).catch(err=>{
                toast("Error")
            })
    }
    addMht=(outcome)=>{
        let {winningOutcomes,rule1A}=this.state;
        let mht=outcome.mhtAdded
        outcome.mhtAdded=!mht;
        this.setState({winningOutcomes})
        let title=outcome.title 
        if (outcome.mhtAdded==true)
       rule1A[outcome.title]=0;
       else 
       delete rule1A[outcome.title]

       this.setState({rule1A})
        }
    renderQCFields=(formData)=>{
        let {qualityCheck,maxTimeLimit}=this.state;
        return(
        <div>
                <FormGroup className="each-form">
                    <div className="heading">Max Project Handling Time </div>
                    <FormControl  value={maxTimeLimit } onChange={(e) => this.setFormData(e)} name="maxTimeLimit" type="number" placeholder="Max Project Handling Time" />
                    </FormGroup>

        </div>
        )
    }
    setFormData=(e)=>{
        let {qualityCheck,maxTimeLimit}=this.state;
     maxTimeLimit=parseInt(e.target.value);
     this.setState({maxTimeLimit})
    }
    setAuditPercentage = (e) => {
        let auditPercentage = parseFloat(e.target.value)
        this.setState({auditPercentage})
    }
      handleBack = () => {
          let {formData}=this.state;
        this.props.history.push(`/define-leads/${formData.id}`)
      //  this.setState({ createProject: false })
    }
}
