import React, {useState, useEffect} from 'react'
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getAvailableJobs } from './service'
import useApi from '../../../hooks/useApi'

const AvailableJobs = ({userData, passProjectSelected}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')

    const [rowSelected, setRowSelected] = useState('')

    const getAvailableJobsApi = useApi(getAvailableJobs)

    useEffect(()=>{
        getAvailableJobsApi.request(null,null,null,search)
    },[search])

    const columns = [
        {
            Header: "Project name",
            accessor: "title",
            Cell: ({cell}) => {
                return <span>{Number(cell.row.id) + 1}.&nbsp;&nbsp;{cell.row.original.title || 'NA'}</span>
            },
            disableSortBy: true
        },
        {
            Header: 'Callers in training',
            accessor: "callersInTraining",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: 'Total callers',
            accessor: "totalCallers",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: <div style={{width: '120px'}}>{'Time slots'}</div>,
            accessor: "workTime",
            Cell: ({value}) => {
                return(
                    <>
                    {
                        value && value.startTime && value.endTime ?
                        <div>{moment(value.startTime, "HH:mm").add({hours:5,minutes:30}).format('LT')} - {moment(value.endTime, "HH:mm").add({hours:5,minutes:30}).format('LT')}</div>
                        : 'NA'
                    }
                    </>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'Script Language',
            accessor: "languages",
            Cell: ({value}) => {
                let languages = value && value.map(lang => lang.name)
                return languages && languages.length > 0 ?
                String(languages) : 'NA'
            },
            disableSortBy: true
        },
        //unlocked in version 2
        // {
        //     Header: 'Data Collection points',
        //     Cell:(cell) =>('NA')
        // },
        // {
        //     Header: 'Live Trigger',
        //     Cell:(cell) =>('NA')
        // }
    ]

    // const sortChange = (d) => {
    //     setSort(d)
    // }
    
    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    let {data, total} = getAvailableJobsApi || {}
    const {loading} = getAvailableJobsApi

    return(
        <div>
        {getAvailableJobsApi.error && <p>{typeof getAvailableJobsApi.error == 'object' && 
        getAvailableJobsApi.error !== null &&
        !Array.isArray(getAvailableJobsApi.error)
        ? getAvailableJobsApi.error.error || getAvailableJobsApi.error.msg : getAvailableJobsApi.error}</p>}
         {/* <span className={styles['total-count']}>Total projects: {data && data.length}</span> */}
            <Table
            columns={columns}
            data={data}
            totalCount={total}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            selectRow = {true}
            updateRowSelect={passProjectSelected}
            setRowSelected1={setRowSelected}
            rowSelected1={rowSelected}
            type = 'client'
            height = '50'
            isGlobalFilter = {true}
            filterChange = {filterChange}
            searchBy = {'project title'}
            />
        </div>
    )

}

export default AvailableJobs