import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import QuizSearch from './Tools/QuizSearch';
import FrappButton from '../utilities/FrappButton';
import { ToastContainer, toast } from "react-toastify";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';

class AddQuiz extends Component {
    constructor(props){
        super(props);
        this.state={
            formData:{},
            isLoading:true,
        }
    }

        componentDidMount() {
              this._isMounted = true;
               if(this._isMounted){
                     let id = this.props.match.params.id;
                      ApiActions.getTeleProject(id).then(resp=>{
                        this.setState({formData:resp,minScore:resp.minimumQuizScore})
                        if(resp.quiz){
                           ApiActions.getQuiz(resp.quiz).then(resp2=>{
                               this.setState({quiz:resp2,isLoading:false})
                           })
                        }
                        else 
                        this.setState({isLoading:false})
                      })
               }
        }

         componentWillUnmount() {
        this._isMounted = false;
    }
    onQuizSelect=(quiz)=>{
       this.setState({quiz:quiz.id})
    }
     handleBack = () => {
         let {formData}=this.state;
         let brandmanagers = [];
        //  this.props.history.push(`/update-project/${formData.id}`)
         this.props.history.push({
            pathname: `/update-project/${formData.id}`,
            state: {
                formData:formData,
                brandmanagers: brandmanagers
            }
        })
    }
    render() {
        let {formData,quiz}=this.state;
        let isLoading = this.state.isLoading;
        if(!isLoading)
        return (
            <div className="link-quiz-wrapper">
                  <div className="heading">
                    <div className="arrow" onClick={() => this.handleBack()}>  
                      <img src={require("../../assets/images/back-arrow.png")} /> 
                     <div className="back">Back</div>
                     </div>
                    <div><h4>{formData.title}</h4></div><h5>Step 2: Add Quiz </h5> </div>
                 
               {quiz? <div className="linked"><h5>Currently Linked Quiz </h5> &nbsp;<p>{quiz.name}</p></div>:null}
                <form onSubmit={this.linkQuiz}>
                     <div className="quiz-search">
                         <h3>Link Quiz</h3>
                                <QuizSearch onQuizSelect={this.onQuizSelect}
                                ref={(c) => this.quizsearch = c} />
                                
                    </div>
                     <FormGroup id="qs-text">
                            <ControlLabel>Min Score</ControlLabel>
                            <FormControl required style={{width:"23%"}} value={this.state.minScore} onChange={(e) => this.setFormData(e)} name="minScore" type="number" placeholder="Enter Min Score" />
                        </FormGroup>
                         <div className="btn-wrapper">
                  <FrappButton className="submit" id="new-btn" type="submit" ref={(c) => this.submitBtn = c} >
                     Save
                    </FrappButton>
                   <FrappButton className="submit" id="new-btn-2" 
                   ref={(c) => this.nextBtn = c} 
                   handler={() => this.props.history.push(`/mock-test/${formData.id}`)}> Skip</FrappButton>
           </div>
                </form>
          
            </div>
        )
          else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
    }
    setFormData=(e)=>{
         this.setState({ minScore:e.target.value});
    }

    linkQuiz=(e)=>{
        e.preventDefault();
        let {formData,quiz,minScore}=this.state;
        formData.quiz=quiz;
        if(minScore){
            formData.minimumQuizScore= minScore
        }
        if (this.submitBtn) this.submitBtn.startLoading();
            delete formData.updatedAt;
            delete formData.createdAt;
            ApiActions.updateTeleProject(formData, formData.id).then((resp) => {
                this.props.history.push({
                    pathname: `/mock-test/${resp.data.id}`,
                    state: {
                        formData
                    }
                })
                if (this.submitBtn) this.submitBtn.stopLoading();
                toast("Done");
            }).catch(err => {
                toast("Error")
            })
    }
}

export default AddQuiz;
