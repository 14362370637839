import React, { useEffect, useMemo, useState, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons'
import { useTable, useSortBy, useFilters, usePagination, useGlobalFilter, useRowSelect } from "react-table";
import { Table } from "react-bootstrap";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from './table.module.scss'
import Pagination from './Pagination'
import NoData from './NoData'
import Radio from "../radio/radio";
import { ExportIcon } from "../../assets/images"; 

const emptyArray = []
const ReactTable = ({ columns, data, type = 'server', height, 
  rowSelected1, setRowSelected1, updateRowSelect= null, selectRow = false, 
  selectType = 'single', isLoading, manualPagination = false, title = 'NA',
  changePage, totalCount, limit = 10, currentPage, setCurrentPage, loading, 
  LoadingComponent, sortChange, filterChange, isGlobalFilter = false, 
  searchBy = 'mobile number' , onRowClicked, search = '', error = '', manualSortBy = true, serverReport = '',
  isExport = false
  }) => {
  const [searchKey, setSearchKey] = useState(search ? search : '')
  const [rowSelected, setRowSelected] = useState('')

  const getTableSize = () => {
    switch (height) {
      case '50':
          return `table-height-50`
      case '60':
        return `table-height-60`
      default:
        return null
    }
  }

  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
      
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if(rowData && rowData.length <= 0){
       toast.error('No data to export as CSV')
       return false
    }
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      const blob = new Blob([csvString], { type: 'text/csv' });
      // generate a URL for the Blob.
      const blobUrl = URL.createObjectURL(blob);
      // create a link and set its href attribute to the generated URL.
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${title}.csv`; // Set the desired file name here
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Release the object URL to prevent memory leaks
      URL.revokeObjectURL(blobUrl);
    }
    
    // else if (fileType === "xlsx") {
    //   // XLSX example
  
    //   const header = columns.map((c) => c.exportValue);
    //   const compatibleData = data.map((row) => {
    //     const obj = {};
    //     header.forEach((col, index) => {
    //       obj[col] = row[index];
    //     });
    //     return obj;
    //   });
  
    //   let wb = XLSX.utils.book_new();
    //   let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    //     header
    //   });
    //   XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    //   XLSX.writeFile(wb, `${fileName}.xlsx`);
  
    //   // Returning false as downloading of file is already taken care of
    //   return false;
    // }
    // //PDF example
    // if (fileType === "pdf") {
    //   const headerNames = columns.map((column) => column.exportValue);
    //   const doc = new JsPDF();
    //   doc.autoTable({
    //     head: [headerNames],
    //     body: data,
    //     margin: { top: 20 },
    //     styles: {
    //       minCellHeight: 9,
    //       halign: "left",
    //       valign: "center",
    //       fontSize: 11
    //     }
    //   });
    //   doc.save(`${fileName}.pdf`);
  
    //   return false;
    // }
  
    // Other formats goes here
    return false;
  }

  // useEffect(()=>{
  //   if(type == 'client'){
  //     // if(currentPage == 2){
  //     //   console.log((currentPage - 1) * limit)
  //     //   console.log(rowData[(currentPage - 1) * limit])
  //     //   console.log(rowData.slice(5, 6))
  //     // }

      
  //   const indexOfLastPage = (currentPage) * limit
  //   const indexOfFirstPage = indexOfLastPage - limit
  //   console.log('indexOfLastPage', indexOfLastPage)
  //   console.log('indexOfFirstPage', indexOfFirstPage)

  //   console.log(rowData && rowData.slice(indexOfFirstPage, indexOfLastPage))
  //   rowData && setClientData(rowData.slice(indexOfFirstPage, indexOfLastPage))
  //   }

  // },[currentPage, data])

  const isHexadecimal = (string) => {
    return /^[0-9a-fA-F]+$/.test(string);
  }

  const isNaturalNumber = (string) => {
      return /^[0-9]+$/.test(string);
  }

  const getRowId = React.useCallback((row, idx) => {
    if(row && row.id){
      if(isHexadecimal(row.id)){
          return idx
      }else{
        return row.id
      }
    }else{
      return idx
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    exportData,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { sortBy, selectedRowIds, pageIndex, pageSize  }
  } = useTable({
    columns: columnData,
    data: rowData || emptyArray,
    getRowId,
    manualPagination,
    autoResetPage: false,
    manualSortBy: manualSortBy,
    disableMultiSort: false,
    getExportFileBlob,
    isMultiSortEvent: () => {
      return true;
    },
    initialState: { pageIndex: parseInt(currentPage - 1), pageSize: parseInt(limit) },
    // autoResetSelectedRows: false,
    // autoResetSelectedCell: false,
    // autoResetSelectedColumn: false,
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExportData,
  type == 'client' && usePagination,
  selectRow && selectType == 'multi' && useRowSelect,
  hooks => {
    selectRow && data && data.length > 0 && hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
         selectType == 'multi' && <div>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>     
          {selectType  == 'multi' ?
          <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}/>
          </div>
          :
          <Radio 
           name="radio-group"
           value={row.original.id} 
           id={row.original.id}
           selectedValue = {rowSelected1.id}
           handleChange={()=>{
            setRowSelected(row.original)
            setRowSelected1(row.original)
           }}
           />}
          </div>
        ),
        width: '3%'
      },
      ...columns,
    ])
  },
  // loading,
  // LoadingComponent
  );

  useEffect(()=>{
    if(type == 'server'){
      if(sortBy.length > 0) sortChange && sortChange(sortBy)
      else sortChange && sortChange('')
    }
  },[sortBy])

  useEffect(()=>{
    if(selectedRowIds && Object.keys(selectedRowIds).length > 0){
    let rows = []
    const extractRowIds = Object.keys(selectedRowIds).filter(row => {
      if(isNaturalNumber(row)){
        if(selectedRowIds[row])
        rows.push(data[row])
        return data[row]
      }else if(isHexadecimal(row)){
        const getData = data.find(each => each.id == row)
        rows.push(getData)
        return getData
      }
    })
    updateRowSelect(rows)
    }else if(typeof selectedRowIds == 'object'){
      updateRowSelect([])
    }

    
  },[selectedRowIds])

  useEffect(()=>{
    if(updateRowSelect){
      updateRowSelect(rowSelected)
    }
  },[rowSelected])

  let decidedData = type == 'client' ? totalCount > limit ? page : rows : rows
  return (
    <>
      {
      (
        <>
        {isGlobalFilter ? <div className={styles['global-filter']}>
        <div className={styles['tool-bar']}>
        {<span>Total Records: {!loading ? totalCount || (data && data.length) : 0}</span>}
        {isExport && <button onClick={() => type == 'server' && serverReport ? serverReport() : exportData("csv", true)}>Export as CSV 
        <img src={ExportIcon}/>
        </button>}
        </div>
        <input
          onChange={e => {
            setSearchKey(e.target.value)
            if(type == 'client'){
               filterChange ? e.target.value.length <= 0 && filterChange('') : setGlobalFilter(e.target.value)
            }else{
               e.target.value.length <= 0 && filterChange('')
            }
          }}
          value={searchKey}
          onKeyUp={(event) => {
            if(event.key === 'Enter'){
              filterChange && filterChange(searchKey)
            }
          }}
          placeholder={`Search by ${searchBy} & hit Enter...`}
        />
        </div> : <div className={styles['global-filter']}>
        <div className={styles['tool-bar']}>
        <span>Total Records: {!loading ? totalCount || (data && data.length) : 0}</span>
        {isExport && <button onClick={() => type == 'server' && serverReport ? serverReport() : exportData("csv", true)}>Export as CSV
        <img src={ExportIcon}/>
        </button>}
        </div>
        </div>}

       <div className={styles['whole']}>
        <section className={[styles['table'], styles[getTableSize()]].join(' ')}>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return <th width={column.width} minwidth={column && column.minWidth} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span style={{display: 'flex', gap:'1rem'}}>
                      {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? 
                    <div>
                      <FontAwesomeIcon icon={faSortDown}/>
                      </div>
                    : 
                    <div>
                      <FontAwesomeIcon icon={faSortUp} />
                    </div>
                    ) :
                    column.canSort ? <FontAwesomeIcon icon={faSort} className={styles.highlight}/> : ''
                    }
                </span>
                    </th>
                  })}
                </tr>
              ))}

            </thead>
            {loading ?
            <SkeletonTable columns={columns} limit={limit} selectRow={selectRow}/>
            :
              <tbody {...getTableBodyProps()}>
              {
              decidedData && decidedData.length > 0  ? 
              decidedData.map((row, i) => {
                prepareRow(row);
                const { isDisabled } = row.original || {}
                return (
                  <tr className={isDisabled ? styles.disabled_row : undefined} {...row.getRowProps({
                    onClick: e => onRowClicked && onRowClicked(row, e),
                    })}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps({
                          style: {
                            minWidth: cell.column && cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: cell.column && cell.column.maxWidth
                          },
                        })}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
              :
              <NoData columns={columns} selectRow={selectRow}/>
              }
            </tbody>           
            }
          </Table>
        </section>
        {type == 'client' && totalCount > limit ?
          // null 
          <Pagination
          totalRows={totalCount}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={limit}
          currentPage={currentPage}
          type = 'client'
          nextPage = {nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          />
        :<Pagination
          totalRows={totalCount}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={limit}
          currentPage={currentPage}
          />}
        </div>
        </>
      )
      }
      <ToastContainer draggable={true} />
    </>
  );
};


const SkeletonTable = ({columns, limit, selectRow}) => {
  return (
        <tbody>
          {
            Array(limit).fill().map((row, rowIdx) =>
            <tr key={rowIdx}>
            {Array(selectRow ? columns.length + 1 : columns.length).fill().map((col, colIdx) =>
                <td key={colIdx}>
                <div className={[styles.skeleton, styles['skeleton-data']].join(' ')}></div>
                </td>
            )}
            </tr>
            )
          }
        </tbody>
  )
}

export default React.memo(ReactTable);