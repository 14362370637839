import React, {useEffect, useState} from 'react'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Dropdown from 'react-dropdown';
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import { banCallerAction } from './service'
import useApi from '../../../hooks/useApi'
import FrappButton from '../../../components/utilities/FrappButton'
import constants from '../../../util/constants';
import checkIsEmpty from "../../../helper/checkIsEmpty"

//global default
const defaultBanData = {
    mobileNum: '',
    reason: '',
    apiMsg:{
        type: '',
        msg: ''
    }
}

const BanCaller = () => {
    const [banCallerData, setBanCallerData] = useState(defaultBanData)
    const { apiMsg } = banCallerData
    const options = constants.banReasons

    const banCallerActionApi = useApi(banCallerAction)

    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }

    const banValidation = () => {
        if(checkIsEmpty(banCallerData.reason) || banCallerData.mobileNum.length < 10){
            return true
        }else if(!isNumeric(banCallerData.mobileNum)){
            return true
        }
        return false
    }

    const handleDirectBan = () => {
        banCallerActionApi.reset()
        setBanCallerData({
            ...banCallerData,
            apiMsg: defaultBanData.apiMsg
        })
        const dataToSend = {
            mobile: banCallerData.mobileNum,
            banReason: banCallerData.reason
        }
        banCallerActionApi.request(dataToSend)
    }

    const {data, error} = banCallerActionApi

    useEffect(()=>{
        if(data && data.data){
            const {msg} = data.data
            //get the mobile number from api response to show a custom message
            const strArray = msg.split(" ");
            const lastWord =  strArray[strArray.length-1]
            setBanCallerData({
                ...banCallerData,
                apiMsg:{
                type: 'success',
                msg: `${lastWord} is banned`
                } 
            })
        }
        if(error){
            setBanCallerData({
                ...banCallerData,
                apiMsg:{
                type: 'failure',
                msg: error.msg || error
                } 
            })
        }
    },[data, error])

    return(
        <div className={styles['direct-ban']}>
        <div className={styles['mobile-number-section']}>
        <FormGroup>
        <FormControl
        name="mobile-number"
        type="text"
        minLength="10"
        maxLength="13"
        value={banCallerData.mobileNum}
        onChange = {(e) => {
            setBanCallerData({
                ...banCallerData,
                mobileNum: e.target.value
            })
        }}
        placeholder="Enter mobile number"
        />
        </FormGroup>
        </div>
        {
            banCallerData.mobileNum !== '' ?
            isNumeric(banCallerData.mobileNum) ?
            null :
            <span className={styles['error-msg']}>Invalid mobile number entered</span>
            : null
        }
        <Dropdown options={options} 
        className={styles['ban-reason']}
        onChange={(val) => {
            setBanCallerData({
                ...banCallerData,
                reason: val.value
            })
        }}
        placeholder="Select a reason" />
        {
        !checkIsEmpty(apiMsg) ?
            <div className={apiMsg.type == 'success' ?
                'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
            : null
        }
        <div className={styles['btn-footer']}>
        <FrappButton className="submit" id="new-btn"
        disabled={banValidation()}
        handler={() => handleDirectBan()}
        >BAN</FrappButton>
        </div>
        </div>
    )
}

export default BanCaller