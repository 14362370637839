import React from 'react';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

import SearchInput from '../../utilities/SearchInput';

import ApiActions from '../../../actions/ApiActions';


class QuizSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <FormGroup className="mission-search" >
                {/* <ControlLabel><p>Search for the Quiz..</p></ControlLabel> */}
                <div className="inputs-wrapper" >
                    {this.renderSearchQuizInput()}
                </div>
            </FormGroup>
        )
    }

    renderSearchQuizInput = () => {
       
        var data = {
            getData: ApiActions.getQuizList,
            searchField: ["name"],
            callback: (searchValue, obj) => { this.props.onQuizSelect(obj) },
            render: (obj) => <React.Fragment>{obj.name} &mdash; {obj.category} &mdash; {window.UTIL.formatDate(obj.createdAt)}</React.Fragment>
        }

        return <SearchInput placeholder="Search the Quiz" ref={(c) => this.searchInput = c} data={data} />
    }

    
    getId = () => {
        let id = this.searchInput.state.returnValue.id;
        return id;
    }
}

export default QuizSearch;