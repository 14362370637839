import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import FrappButton from '../utilities/FrappButton';
import FrappModal from "../utilities/FrappModal";
import ProjectTitle from "../common/ProjectTitle"
export default class QualityAudit extends Component {
    constructor(props){
        super(props)
        this.state={
            teleproject:{},
            isLoading:true,
            scriptDocuments:[],
            outcomes:[],
            totalCount:0,
            totalDuration:0,
            mht:0
        }
    }
       componentDidMount() {
           let {scriptDocuments}=this.state;
        this._isMounted = true;
         this.setState({auditProject:this.props.location.state.teleprojectAudit})
        if (this._isMounted) {
            if (this.props.match.params.id) {
                ApiActions.getTeleProject(this.props.match.params.id).then(resp => {
                    this.setState({ teleproject: resp,isLoading:false })
                    this.setState({scriptDocuments:resp.scriptDocuments,outcomes:resp.outcomes})
                    if(resp.qc)
                    this.calculateTotal(resp.qc)

                })
            }
            this.setState(this.props.history.location.state);
        }

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    calculateTotal=(qc)=>{
    let {totalCount,totalDuration,mht}=this.state;
    if(qc.outcomeMHT){
      this.setState({mht:qc.outcomeMHT})
    }
       if(qc.count)
      Object.keys(qc.count).map(obj=>{
        totalCount+=qc.count[obj]
        this.setState({totalCount})
      })

       if(qc.duration)
      Object.keys(qc.duration).map(obj2=>{
        totalDuration+=qc.duration[obj2]
        this.setState({totalDuration})
      })
     
      }

    
    render() {
        let {teleproject,auditProject,scriptDocuments,outcomes,totalDuration,totalCount,mht}=this.state;
          let isLoading = this.state.isLoading;
          let outcomeMHT,allOutcomes,duration;
          let {qc}=teleproject;
          if(qc)
          {
            outcomeMHT=qc.outcomeMHT||[];
            allOutcomes=qc.count||[];
            duration=qc.duration||[];
          }
          
          
        const goBack = () => {
            this.props.history.push("/audit/qmf")
        }
          
      if(!isLoading)
                return (
            <div className="audit-project" >
            {this.renderScriptPopup()}
                {this.renderOutcomePopup()}
                <div className="project-details">
                   <div className="head">
                        {/* <div className="title"> {teleproject.title}    </div> */}
                        {teleproject ? <ProjectTitle title={teleproject.title} goBack={goBack}/> : null}
                          <div className="btn-wrapper">
                              <div>
                                  <FrappButton className="audit-btn-project" handler={()=>this.showScriptPopup(scriptDocuments)}> View Script 
                                </FrappButton>
                              </div>
                              <div>
                                  <FrappButton className="audit-btn-project"  handler={()=>this.showOutcomePopup(outcomes)}> View Outcomes
                                </FrappButton>  
                              </div>
                          </div>  
                   </div>     
                   <div className="card-detail"> 
                    <div className="card-box"> 
                          <div className="section1">
                                 <div className="data">
                                 <div className="title">Main goal </div>    
                                 <div className="info">{teleproject.mainGoal} </div>    

                                  </div>    
                                 <div className="data">  
                                  <div className="title"> Total calls</div>    
                                 <div className="info"> {auditProject&&auditProject.callsDatabase}</div>    
                                     </div>    
                                 <div className="data"> 
                                   <div className="title"> Calls completed</div>    
                                 <div className="info">{auditProject&&auditProject.callsConnected} </div>    

                                 </div>    
                                 <div className="data">
                                   <div className="title">Callers assigned </div>    
                                 <div className="info">{auditProject&&auditProject.assignedCallers} </div>    

                                  </div>    
                            </div>
                   <div className="section1">
                                 <div className="data">
                                 <div className="title">Call attempts </div>    
                                 <div className="info">{auditProject&&auditProject.callsMade}  </div>    

                                  </div>    
                                 <div className="data">  
                                  <div className="title"> Winning outcome</div>    
                                 <div className="info"> 
                                 
                {
                    outcomeMHT&&Object.keys(outcomeMHT).map(obj=>{
                        return(
                           <div className="data" id="win-oc"> {obj}</div>
                        )
                    })
                }
                
                
                                 
                                 
                                 
                                 </div>    
                                     </div>    
                                 <div className="data"> 
                                   <div className="title"> Call audits</div>    
                                 <div className="info">{auditProject.callsInQC} </div>    

                                 </div>    
                                 <div className="data">
                                   <div className="title">Callers for QC </div>    
                                 <div className="info">{auditProject.flaggedCallers} </div>    

                                  </div>    
                            </div>
                    
                    </div>
                       
                </div>          
                 <div className="head">
                        <div className="title"> QC Insights    </div>
                  
                   </div>    
                   <div className="card-detail">
                       <div className="insights-card">
                            <div className="qc-table">
                        <table> 
                          <tbody>
    
                        <tr className="header-row">
                        <td id="outcome-header">Outcomes </td>    
                        <td>Average ratio(sys) </td>    
                        <td>Average duration(secs) </td>    
                        <td>Min. Handling time(secs) </td>    
                         </tr>
                     {teleproject.qc&&qc.count?
                      Object.keys(allOutcomes).map((outcome)=>{
                          return(
                            <tr className="common-row">
                        <td>{outcome} </td>    
                        {/* <td>{allOutcomes[winningOutcome]}</td>     */}
                        <td>{((allOutcomes[outcome]/totalCount)*100).toFixed(0)}%</td>    
                        <td>{((duration[outcome]/qc.count[outcome])).toFixed(2)}</td>    
                        <td>{outcomeMHT[outcome]||"--"}</td>    
                         </tr>
                          )
                      }):
                      <div className="banner">Insufficient QC Data for Insights</div>
                    }
                    </tbody>
                             </table>
                   </div>
                           
                         </div>
                   </div>
                 </div>
                {/* <div className="bottom-buttons">
                      <FrappButton className="call-audit-btn" handler={()=>this.props.history.push({
                    pathname: `/call-audit/${teleproject.id}`,
                    state: {
                        teleproject: teleproject,
                        mht:mht,
                        ...this.props.location.state
                    }
                })}> Call Audits </FrappButton>
                      <FrappButton className="call-audit-btn" id="green-iqc" handler={()=>this.props.history.push({
                    pathname: `/caller-iqc/${teleproject.id}`,
                    state: {
                        teleproject: teleproject,
                        mht:mht,
                        ...this.props.location.state
                    }
                })}> Caller IQC </FrappButton>
                      <FrappButton className="call-audit-btn" id="blue-audit" handler={()=>this.props.history.push({
                    pathname: `/caller-audit/${teleproject.id}`,
                    state: {
                        teleproject: teleproject,
                        mht:mht,
                        ...this.props.location.state
                    }
                })}> Caller Audits </FrappButton>

                </div> */}
            </div>
        )
         else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
    }
    showScriptPopup=(scriptDocuments)=>{
      if(scriptDocuments)
        this.setState({scriptDocuments},()=>this.scriptPopup.toggle(true))
        else this.scriptPopup.toggle(true)
    }
    showOutcomePopup=(outcomes)=>{
      if(outcomes)
        this.setState({outcomes},()=>this.outcomePopup.toggle(true))
        else this.outcomePopup.toggle(true)
    }
    renderScriptPopup=()=>{
        let {teleproject}=this.state;
        let {scriptDocuments}=this.state
        if(scriptDocuments)
          return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.scriptPopup = c)}
                closeModal={() => this.scriptPopup.toggle(false)}
            >
              <div>
                  <h3>Script Documents</h3>
                   {scriptDocuments&&scriptDocuments.map(doc=>{
    
                     return(
                        <div className="script-popup">
                             <div className="title"> <h2>{doc.title}</h2><a href={doc.url} target="_blank" rel="noopener noreferrer"> Script Link</a></div>
                        </div>
                     )
                   })}


               
              </div>
            </FrappModal >
        )
    }
      renderOutcomePopup=()=>{
          let {outcomes}=this.state;
          return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.outcomePopup = c)}
                closeModal={() => this.outcomePopup.toggle(false)}
            >
                 <div>
                  <h3>Outcomes</h3>
                   {outcomes&&outcomes.map(outcome=>{
    
                     return(
                        <div className="outcome-popup">
                             <div className="outcome-title"> {outcome.title} {outcome.isWinning?<p>&nbsp;-Winning Outcome</p>:null}</div>
                             <div className="outcome-desc"> {outcome.description}</div>
                        </div>
                     )
                   })}


               
              </div>
            </FrappModal >
        )
    }
}
