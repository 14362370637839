import React from 'react'
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import CustomTooltip from '../../../components/utilities/ToolTip'
import { InfoIcon } from '../../../assets/images'

const Tooltip = (text) => {
    const getDescription = (text) => {
        switch ((text.replace(/ /g,"_")).toLowerCase()) {
            case 'working_time':
                return 'We track the time callers spend dialing & speaking with customers. Achieving target working minutes is essential for earning day’s payout'
            case 'total_earnings':
                return 'The total earnings made by the caller in this payment cycle'
            case 'spoken_mins':
                return 'The number of mins spoken in this payment cycle'
            case 'attempts':
                return 'The number of call attempts in this payment cycle'
            case 'present_count':
                return 'The number of days the caller completed their working time target in this payment cycle'
            case 'base_pay':
                return 'The caller’s daily pay rate for every present day'
            case `due_day's`:
                return 'The number of days eligible for payment in this payment cycle'
            case `earning_due`:
                return 'The earnings due to the caller in this payment cycle'
            default:
                return 'Description not defined'
        }
    }
    return(
        <div className={styles.column_info}>
            <div>{text}</div>
            {text && <CustomTooltip placement={'bottom'} description={getDescription(text)}
            component={<img src={InfoIcon} alt={getDescription(text)} />} />}
        </div>
    )
}

export default Tooltip