export default {
    hasOnlyFutworkEmails: (emails) => {
        if (!Array.isArray(emails)) {
            emails = [emails]
        }

        // Check if emails have only 'futwork.com' domain
        return emails.every(email => {
            const domain = email.split('@')[1];
            return domain === 'futwork.com';
        })
    }
}