import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath } from "react-router-dom";
import InteractiveScriptPlayGround from '../components/pages/IS/index'
import ScriptBuilder from '../pages/scriptbuilder/main'
// import Projects from '../pages/project/main'

const ScriptBuilderRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    return <Switch>
        <Route exact path={`${path}`}
        render={() => <ScriptBuilder {...customProps} />}
        />

        <Route exact path={`${path}/:id`}
        render={() => <InteractiveScriptPlayGround {...customProps} />}
        />

        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default ScriptBuilderRouter