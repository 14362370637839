import React, { Component } from 'react';
import RouteTab from '../../utilities/RouteTab';
import ApiActions from '../../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import FrappModal from "../../utilities/FrappModal";
import Dropdown from 'react-dropdown';
import CustomTab from '../../utilities/CustomTab'
import { Fragment } from 'react';
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import ProjectTitle from '../../common/ProjectTitle'
import jwt_decode from "jwt-decode";
import { userRoleReason, userRoleAction } from './HiringFunctions'
import Table from '../../common/Table'
import CustomTable from '../../../uicomponents/table/Table'
import capitalise from '../../../helper/Capitalise'
import { mockCallButton, mockCallDataFn, renderConfirmCallModal, renderSelectionPop, renderCallInfo, checkIfCoachLoggedIn, tooltip, styleTheFeedback, renderAllFeedbackPopup} from './UIFunctions';
import { UserIcon, DataCollection, TrackHistory, FeedbackIcon, AttachmentIcon, AnswerIcon } from '../../../assets/images'
import CustomTooltip from '../../../components/utilities/ToolTip'
import Popup from '../../../uicomponents/popup'
import ThreeDotLoader from '../../utilities/ThreeDotLoader';
import CustomerInfo from '../../../components/utilities/CustomerInfo'
import Panel from '../../../uicomponents/panel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ReactTable from '../../../uicomponents/table/Table';

class JobApplicationsTraining extends Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.loggedInUser = jwt_decode(localStorage.authToken)
        this.state={
            skip:0,
            shortlistedCallers:[],
            iqcRetrainingCallers:[],
            qcRetrainingCallers:[],
            qmfRetrainingCallers: [],
            forShortlistedCallers: 0,
            forIQCRetrainingCallers: 0,
            forQCRetrainingCallers: 0,
            forQMFRetrainingCallers: 0,
            activeTab: {
                label: this.tabsForStage[0].label,
                value: this.tabsForStage[0].value
            },
            callerHistoryActiveTab:{
                label: this.tabsForCalleryHistory[0].label,
                value: this.tabsForCalleryHistory[0].value
            },
            callInfoActiveTab: {
                    label: this.tabsForCallInfo[0].label,
                    value: this.tabsForCallInfo[0].value
            },
            showCallInfo: false,
            showQmfFdbk: false,
            showIqcFdbk: false,
            currentPageIqcCallAudit: 1,
            cmmActionHistoryLoading: true,
            cmmActionHistoryData: [],
            isTableLoadingShortlisted: false,
            isTableLoadingIQCRetraining: false,
            isTableLoadingQCRetraining: false,
            isTableLoadingQMFRetraining: false,
            callerHistory: [],
            coachFeedback: '',
            confirmCallModal: false,
            callerSelectedForMock: '',
            showSelectionModal: false,
            mockCallData: [],
            coachesData: [],
            hiringDecision: {
                "status": '',
                "trainingStage": '',
                "actionBy": userRoleAction(this.loggedInUser.auth),
                "reason": '',
                "feedback": '',
                "callReason": ''
            },
            checkboxFeedbackData: [],
            showFeedbackModal: false,
            activeRow: '',
            currentPage: 1
        }
    }


    componentDidMount() {
        const { title } = this.props.location.state.projectdata
        if(this.props.match.params.id){
            ApiActions.getTeleProject(this.props.match.params.id).then(resp => {
                this.props.location.state.projectdata = resp
            }).then(() => {
                ApiActions.getUsersBasedOnRole('coach').then(resp => {
                    this.setState({coachesData: [...resp]})
                    if (this.loggedInUser) {
                        const findCoach = [...resp].find(elem => elem.id == this.props.location.state.projectdata.coach)
                        if (findCoach) {
                            const isLoggedInMemberCoach = checkIfCoachLoggedIn(this.loggedInUser.auth, findCoach.role)
                            if (isLoggedInMemberCoach)
                            this.setState({currentCoach: [...resp].find(elem => elem.id == this.loggedInUser.base)})
                            else
                            this.setState({currentCoach: findCoach})
                        }
                    }
                })
            })
            .then(() => this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab))
            .catch(err => console.error(err.response))
        }
    }

    cellStyle = {
        fontSize: '12px',
        color: 'black',
    }

    headerStyle = {
        backgroundColor: '#E7FAF2',
        fontSize: '12px',
        fontWeight: 'bold',
        boxShadow: 'none'
    }

    filterCellStyle = {
        padding: '1% 0% 1% 1%'
    }

    tabsForStage = [
        {
            label: 'IQC Retraining',
            value: 'iqcTraining'
        },
        {
            label: 'QC Retraining',
            value: 'qcRetraining'
        },
        {
            label: 'QMF Retraining',
            value: 'qmfRetraining'
        }
    ]

    tabsForCalleryHistory = [
        {
            label: 'Caller History',
            value: 'callerHistory'
        },
        {
            label: 'CMM Action History',
            value: 'cmmActionHistory'
        }
    ]

    tabsForCallInfo = [
        {
            label: 'Data collection',
            value: 'data_collection'
        },
        {
            label: 'Call history',
            value: 'call_history'
        },
        {
            label: 'Customer info',
            value: 'customer_info'
        }
    ]

    fetchCallersBasedOnStage = (id, activeTab) => {
        if(activeTab.value == 'shortlisted'){
            this.setState({isTableLoadingShortlisted: true})
            const data = {
                "teleproject": id,
                "stages": [`${activeTab.value}`]
            }
            ApiActions.getTrainingCallersList(data).then(resp => {
                this.setState({shortlistedCallers: resp.records, forShortlistedCallers: resp.totalTrainingCallers})
                this.setState({isTableLoadingShortlisted: false})
            }).catch(err =>{
                this.setState({isTableLoadingShortlisted: true})
                toast(err.message)
                console.error(err)
            })
        }

        if(activeTab.value == 'iqcTraining'){
            this.setState({isTableLoadingIQCRetraining: true})
            const data = {
                "teleproject": id,
                "stages": [`${activeTab.value}`]
            }

            ApiActions.getIqcRetrainingCallerList({"teleproject": id}).then(resp => {
                this.setState({iqcRetrainingCallers: resp.records, forIQCRetrainingCallers: resp.totalTrainingCallers})
                this.setState({isTableLoadingIQCRetraining: false})
            }).catch(err => {
                this.setState({isTableLoadingIQCRetraining: true})
                toast(err.message)
                console.error(err)
            })
        }

        if(activeTab.value == 'qcRetraining'){
            this.setState({isTableLoadingQCRetraining: true})
            const data = {
                "teleproject": id,
                "stages": [`qcRetraining`,`cmmRetraining`]
            }
            ApiActions.getTrainingCallersList(data).then(resp => {
                this.setState({qcRetrainingCallers: resp.records, forQCRetrainingCallers: resp.totalTrainingCallers})
                this.setState({isTableLoadingQCRetraining: false})
            }).catch(err =>{
                this.setState({isTableLoadingQCRetraining: true})
                toast(err.message)
                console.error(err)
            })
        }

        if(activeTab.value == 'qmfRetraining'){
            this.setState({isTableLoadingQMFRetraining: true})
            const data = {
                "teleproject": id,
            }
            ApiActions.getQmfRetrainingCallerList(data).then(resp => {
                this.setState({qmfRetrainingCallers: resp.records, forQMFRetrainingCallers: resp.totalTrainingCallers})
                this.setState({isTableLoadingQMFRetraining: false})
            }).catch(err =>{
                this.setState({isTableLoadingQMFRetraining: true})
                toast(err.message)
                console.error(err)
            })
        }
    }

    callModal = () => {
        //check if coach mobile number is present
        const {currentCoach} = this.state
        if(!currentCoach)
        toast('Please assign a coach to the project to continue calling')
        else
        this.setState({confirmCallModal: true})
    }

    closeConfirmCallModal = () => {
        this.setState({confirmCallModal: false})
    }

    callerChooseForMock = (data) => {
        this.setState({callerSelectedForMock: data })
    }

    getMockCallDetails = (rowData) => {
        this.setState({coachFeedback: rowData.coachFeedbacks ? rowData.coachFeedbacks : ''})
        const { id, userId } = rowData
        const {activeTab} = this.state
        const data = {
            "teleproject": this.props.location.state.projectdata.id,
            "user": id || userId,
            "mockCallStatus": 'pending',
            'callReason': activeTab && activeTab.value
        }
        ApiActions.getMockCallData(data).then(resp => {
            this.setState({mockCallData: [...resp.data]})
        }).catch(err => console.error(err.response))
        this.setState({showSelectionModal: true})
    }

    sendMockCallFeedback = () => {
        const {checkboxFeedbackData, hiringDecision, activeTab, mockCallData} = this.state
        const getCheckedData = [...checkboxFeedbackData].filter(item => item.checked).map(each => each.label)
        let data = hiringDecision
        data = { ...data, teleproject: this.props.match.params.id, user: mockCallData[0].user, feedback: getCheckedData && getCheckedData.length > 0 ? getCheckedData.toString() : '' }
        ApiActions.changeUserStatus(data).then(resp => {
            if(resp.data.status == ("success" || 200)) {
                    toast("Application status was changed successfully")
                    this.closeShowSelectionModal()
                    if(activeTab.value == 'iqcTraining'){
                        this.setState({isTableLoadingIQCRetraining: true})
                        const updatedCallerList = this.state.iqcRetrainingCallers.filter(caller =>
                            data.user !== caller.id
                        )
                        this.setState({iqcRetrainingCallers: []})                
                        this.setState({iqcRetrainingCallers: updatedCallerList})
                        this.setState({isTableLoadingIQCRetraining: false})
                    }else if (activeTab.value == 'qcRetraining'){
                        this.setState({isTableLoadingQCRetraining: true})
                        const updatedCallerList = this.state.qcRetrainingCallers.filter(caller =>
                            data.user !== caller.id
                        )
                        this.setState({qcRetrainingCallers: []})                
                        this.setState({qcRetrainingCallers: updatedCallerList})
                        this.setState({isTableLoadingQCRetraining: false})
                    }
            }
        }).catch(err => {
            console.error(err.response)
            toast(err && err.response && err.response.data && err.response.data.msg ? err.response.data.msg : 'Error')
        })
    }
    

    closeShowSelectionModal = () => {
        const role= this.loggedInUser.auth
        this.setState(
            {
               showSelectionModal: false, 
               checkboxFeedbackData: [],
               hiringDecision: {
                "status": '',
                "trainingStage": '',
                "actionBy": userRoleAction(role),
                "reason": '',
                "feedback": '',
                'callReason': ''
               } 
            })
    }

    triggerMockCall = () => {
        const { coach, id } = this.props.location.state.projectdata
        const {activeTab, callerSelectedForMock, currentCoach} = this.state
        const data = {
            "userMobile": callerSelectedForMock.mobile,
            "teleproject": id,
            "callReason": activeTab && activeTab.value,
            "teamCallAccount": "ops"
        }
        ApiActions.placeMockCall(data).then(resp => {
            this.closeConfirmCallModal()
            toast(`Call being placed to ${callerSelectedForMock && callerSelectedForMock.firstname} (${callerSelectedForMock && callerSelectedForMock.mobile})`)
        }).catch(err => {
            console.error(err.response)
        })
    }

    storeHiringDecision = (val, checkboxFeedbackData) => {
        this.setState({hiringDecision: {...this.state.hiringDecision, 'trainingStage': this.state.activeTab.value, 'status': val.value, 'reason': `${val.value == 'training' ? 'remock' : val.value} by ${userRoleReason(this.loggedInUser.auth)}`, 'callReason': this.state.activeTab.value}})
        this.setState({checkboxFeedbackData})
    }

    selectFeedbacks = (item) => {
        let checkedFeedbacks = this.state.checkboxFeedbackData.map(elem => {
            if (elem.id == item.id) {
                elem.checked = !elem.checked
            }
            return elem
        })
        this.setState({checkboxFeedbackData: checkedFeedbacks})
    }

    render() {
        const {title} = this.props.location.state.projectdata
        const {currentCoach, activeTab} = this.state
        
        let tabs = [
            {
                "name": "Applied Callers",
                "route": `/hiring/applied-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            },
            {
                "name": "Hiring",
                "route": `/hiring/${this.props.match.params.id}`,
                "state":  this.props.location.state
            },
            {
                "name": "Retraining",
                "route": `/hiring/for-training-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            }

        ]

        let selectedCallerTab = [
            {
                "name": "Selected Callers",
                "route": `/hiring/selected-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            }
        ]

        const setActiveTabFn = (data) => {
            this.setState({activeTab: {label: data.label, value: data.value}})
            // this.tableRef.current.onChangePage(null, 0);
            this.fetchCallersBasedOnStage(this.props.match.params.id, data)
        }

        const goBack = () => {
            this.props.history.push("/hiring")
        }

        return (
            <div className="job-applications">
            <ProjectTitle title={title} goBack={goBack} />
                <div className="bulk-status-row">
                    <div className="section-1">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <RouteTab tabType='multi' tabArray={tabs} activeTab={tabs[2]} />
                            <RouteTab tabType='single' tabArray={selectedCallerTab} activeTab={''} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '30%', justifyContent: `${activeTab.value == 'under_training' ? 'space-between' : 'flex-end'}` }}>
                            {
                                currentCoach ?
                                    <div className='coach-section'>
                                        <Fragment><span className='label-heading'>Calls placed by:</span><span className='coach-name'>{currentCoach.firstname} ({currentCoach.mobile})</span></Fragment>
                                    </div> :
                                    <div className='coach-section-none'></div>
                            }
                            {/* {
                                activeTab.value == 'under_training' ?
                                    <FrappButton className='renew-mock-link' handler={() => setShowMockLinkModal(true)}>Renew Link</FrappButton>
                                    : null
                            } */}
                        </div>
                </div>
                <CustomTab tabs={this.tabsForStage} setActiveTabFn={setActiveTabFn}/>
                {this.renderTable(this.state.activeTab.value)}
                {/* {this.renderShortlistedCallersTable()} */}
                {this.renderCallerHistoryPopup()}
                {this.state.showFeedbackModal ? renderAllFeedbackPopup(this.state.showFeedbackModal, this.closeFeedbackPopup, this.state.currentFeedback, 'retrain', this.state.coachFeedback) : null}
                {this.state.confirmCallModal ? renderConfirmCallModal(this.state.confirmCallModal, this.closeConfirmCallModal, this.triggerMockCall) : null}
                {this.state.showSelectionModal ? renderSelectionPop(this.state.showSelectionModal, this.closeShowSelectionModal, this.state.mockCallData, this.storeHiringDecision, this.state.hiringDecision, this.state.checkboxFeedbackData, this.selectFeedbacks, this.sendMockCallFeedback, this.state.activeTab, this.state.coachFeedback) : null}
                {this.state.showCallInfo ? this.renderCallInfo() : null}
                {this.state.showQmfFdbk ? this.renderQmfFdbk() : null}
                {this.state.showIqcFdbk ? this.renderIqcFdbk() : null}
                <ToastContainer draggable={true} />
            </div>
        )
    }

    showCallerHistoryPopup = (callerHistory, e, rowData) => {
        const projectId = this.props.match.params.id
        this.setState({activeRow: rowData})
        if(rowData.trainingStage == 'cmmRetraining'){
            ApiActions.getCmmActionHistory({
                userId: rowData.id,
                teleprojectId: projectId
            }).then(resp => {
                this.setState({
                    cmmActionHistoryData: resp.data,
                    cmmActionHistoryLoading: false
                })

            }).catch(err => {
                this.setState({
                    cmmActionHistoryLoading: false
                })
            })
        }
        e.stopPropagation()
        this.setState({callerHistory})
        this.setState({
            callerHistoryActiveTab: {
                label: this.tabsForCalleryHistory[0].label,
                value: this.tabsForCalleryHistory[0].value
            }
        })
        this.callerAuditModal.toggle(true);
    }

    getRowData = async(rowData) => {
        const {activeRow} = this.state
        if (activeRow) {
            this.setState({
                showCallInfo: true
            })
        }else{
            try {
                let result = await ApiActions.getTeletaskCallHistory({
                    teletaskHistory: rowData && rowData.teletaskhistory
                })
                rowData['callHistory'] = result
                this.setState({
                    activeRow: result
                })
            } catch (err) {
                console.error((err.response && err.response.data) || "Unexpected Error!")
            } finally {
                this.setState({
                    activeRow: rowData,
                    showCallInfo: true
                })
            }
        }
    }

    getAllQMfFdbk = (rowData) => {
        this.setState({
            activeRow: rowData,
            showQmfFdbk: true
        })
    }

    _onSelect=(data,val)=>{
        const projectId = this.props.match.params.id
            ApiActions.changeUserStatus(
                {
                    "teleproject": projectId,
                    "user": data.id,
                    "status": val.value,
                    "reason":`${val.value} by ${userRoleReason(this.loggedInUser.auth)}`,
                    "actionBy": userRoleAction(this.loggedInUser.auth)
                }
            ).then(resp => {
                if(resp.data.status == ("success" || 200)) {
                    toast(resp.data.msg || "Application status was changed successfully")
                        const updatedCallerList = this.state.appliedCallers.filter(caller =>
                            caller.id !== data.id
                        )
                        this.setState({appliedCallers: []})                
                        this.setState({appliedCallers: updatedCallerList})                
                }
            }).catch(err => {
                toast(err.response && err.response.data ? err.response.data.msg : 'Error' )
            });
    }

    showAllTheFeedbacks = (rowData, e) => {
        this.setState({currentFeedback: rowData.feedbacks && Array.isArray(rowData.feedbacks) ? rowData.feedbacks.map(({text}) => {
            return  text
        }): [], coachFeedback: rowData.coachFeedbacks ? rowData.coachFeedbacks : ''})
        this.setState({showFeedbackModal: true})
     }
    
     closeFeedbackPopup = () => {
        this.setState({showFeedbackModal: false, coachFeedback: '', currentFeedback: []})
    }

     renderAllFeedbackPopup = () => {
        const { currentFeedback, coachFeedback } = this.state
        return (
           <FrappModal
               onCloseButton={true}
               className="tele-payment-modal retrain-feedback-modal"
               ref={c => (this.feedbackModal = c)}
               closeModal={() => {
                   this.feedbackModal.toggle(false)
                   this.setState({coachFeedback: '', currentFeedback: []})
                }}
           >
               <div className='retrain-feedbacks'>
                <div className='sub-heading'>Feedback for callers</div>
                <div className='feedback-for-callers'>
                  {
                    currentFeedback && currentFeedback.length > 0 ?
                    currentFeedback.map(feedback => <li>{feedback}</li>) : 
                    <span>No Feedbacks</span>
                  }
                </div>
                <div className='divider'></div>
                <div className='sub-heading'>Feedback for coaches</div>
                <div className='feedback-for-coaches'>
                  {
                      coachFeedback ? <span>{coachFeedback}</span> : <span>No Feedbacks</span>
                  }
                </div>
               </div>
           </FrappModal >
       )
    }
 
    renderShortlistedCallersTable = () => {
        let { shortlistedCallers } = this.state;
        let options = [
            { label: "Select",value:"inprogress"},
            { label: "Reject", value: "rejected" },
        ]
        const cellStyle = {...this.cellStyle}
        const headerStyle = {...this.headerStyle}
        const filterCellStyle = {...this.filterCellStyle}
    
        const shortListedColumns = [
            {
                title: "Name", 
                field: "firstname",
                filtering: true,
                render: rowData =>
                <div>
                    {rowData.firstname} {rowData.lastname}
                </div>,
                cellStyle: cellStyle,
                headerStyle: headerStyle,
                filterCellStyle: filterCellStyle
            },
            {
                title: "Phone", 
                field: "mobile",
                filtering: true, 
                cellStyle: cellStyle,
                headerStyle: headerStyle,
                filterCellStyle: filterCellStyle,
            },
            {
                title: "Email", 
                field: "email", 
                cellStyle: cellStyle, 
                headerStyle: headerStyle,
                filterCellStyle: filterCellStyle,
            },
            {
                title: "Calls Attempted", 
                field: "callsAttemptedCount", 
                cellStyle: cellStyle, 
                headerStyle: headerStyle,
                filterCellStyle: filterCellStyle, 
                filtering: false
            },
            {
                title: "Feedback",
                render: (rowData => {
                    return <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={(e)=>this.showAllTheFeedbacks(rowData, e)}>
                        <OverlayTrigger placement="bottom" overlay={tooltip('Click to view feeback')}>
                        <span>{styleTheFeedback(rowData.feedback)}</span>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={tooltip('Caller history')}>
                        <img src={require('../../../assets/images/Icons_history.svg')} style={{width: '22px', cursor: 'pointer'}} onClick={(e)=>this.showCallerHistoryPopup(rowData.statusChangeLog, e, rowData)}/>
                        </OverlayTrigger>
                    </div>
                }),
                cellStyle: {...cellStyle, backgroundColor: '#FFDBDB'}, 
                headerStyle: headerStyle, 
                filterCellStyle: filterCellStyle,
                filtering: false
            },
            {
                title: "Status", field: "status", 
                 render: rowData => 
            <div>
                <Dropdown options={options} 
                onChange={(val)=>this._onSelect(rowData,val)} 
                value={rowData.status?rowData.status:"Select"} 
                placeholder="Select an option" />
                </div>,
                cellStyle: cellStyle, 
                headerStyle: headerStyle,
                filterCellStyle: filterCellStyle
            }
        ]

        const tableprops = {
            data: shortlistedCallers,
            columns: shortListedColumns,
            // totalCount: totalCount,
            // query: query,
            // setQuery: setQuery,
            title: `Callers under ${this.state.activeTab.label} \u00A0\u00A0 [ ${this.state.forShortlistedCallers} ]`,
            // pageChanged: pageChanged,
            // setPageChanged: setPageChanged,
            // fetchDataForTable: fetchDataForTable,
            isTableLoading: this.state.isTableLoadingShortlisted,
            paginationType: 'client',
            filtering: true
        }

        return (
            <div style={{ maxWidth: "100%" }}>
                <Table {...tableprops} />
            </div>
        )
    }

    renderIQCRetrainingCallersTable = () => {
        let { iqcRetrainingCallers, activeTab } = this.state;
        let options = [
            { label: "Select",value:"inprogress"},
            { label: "Reject", value: "rejected" }
        ]

        const columns = [
            {
                Header: "Name",
                accessor: "firstname",
                Cell: ({row}) => {
                    const {original: {firstname, lastname, trainingStage}} = row
                    return <div>
                    <span style={{marginRight: '2px'}}>{firstname || 'NA'} {lastname || 'NA'}</span>
                    {trainingStage == 'cmmRetraining' && <sup style={{color: 'red', fontWeight: '600', fontSize: '11px'}}>CMM</sup>}
                    </div>
                },
                width: '10%',
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: "mobile",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '5%',
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: "email",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '12%',
                disableSortBy: true
            },
            {
                Header: 'Calls Attempted',
                accessor: "callsAttemptedCount",
                Cell: ({value}) => value || 0,
                width: '12%'
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: ({row}) => {
                const value = row.original
                return <CustomTooltip placement={'bottom'} description={'Feedbacks'}
                    component={
                    <img src={FeedbackIcon}
                    alt='Feedbacks' className='icon-btn'
                    onClick={() => {
                        this.setState({
                            activeRow: value,
                            showIqcFdbk: true
                        })
                    }}
                    style={{width: '38px'}}
                    // onClick={()=> this.getAllQMfFdbk(rowData)}
                     />
                }
                ></CustomTooltip>
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Actions',
                accessor: "actions",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return mockCallButton(value, this.callModal, this.callerChooseForMock, this.state.confirmCallModal)
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: '',
                accessor: "view",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return mockCallDataFn(value, this.getMockCallDetails)
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            }
        ]

        return (
            <div style={{ maxWidth: "100%" }}>
                <ReactTable
                columns={columns}
                data={iqcRetrainingCallers}
                totalCount={iqcRetrainingCallers ? iqcRetrainingCallers.length : 0}
                limit={10}
                type = 'client'
                currentPage={this.state.currentPage}
                // setCurrentPage={(page) => this.setState({currentPage: page})}
                loading={this.state.isTableLoadingIQCRetraining}
                // error={error}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'any field'}
                title='IQC-Retraining'
                isExport={true}
                />
            </div>
        )
    }

    downloadCsv = (data, fileName) => {
        const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        a.setAttribute("download", finalFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    listFeedbacks = (feedbacks) => {
        const feedbackText = feedbacks && feedbacks.length > 0 && feedbacks.map(({text}) => {
            return  text
        })
        let list = ''
        list =
        <div>
            <li>{feedbackText[0]}</li>
            {feedbackText.length >= 2 && <li class='text-muted'>View more</li>}
        </div>
        return <ul style={{ padding: '0' }}>{list}</ul>
    }

    renderQCRetrainingCallersTable = () => {
        let { qcRetrainingCallers, activeTab } = this.state;
        let options = [
            { label: "Select",value:"inprogress"},
            { label: "Reject", value: "rejected" },
        ]

        const columns = [
            {
                Header: "Name",
                accessor: "firstname",
                Cell: ({row}) => {
                const {original: {firstname, lastname, trainingStage}} = row
                return <div>
                <span style={{marginRight: '2px'}}>{firstname || 'NA'} {lastname || 'NA'}</span>
                {trainingStage == 'cmmRetraining' && <sup style={{color: 'red', fontWeight: '600', fontSize: '11px'}}>CMM</sup>}
                </div>
                },
                width: '10%',
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: "mobile",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '5%',
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: "email",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '12%',
                disableSortBy: true
            },
            {
                Header: 'Calls Attempted',
                accessor: "callsAttemptedCount",
                Cell: ({value}) => value || 0,
                width: '12%'
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: ({row}) => {
                const {original: {feedbacks}} = row
                return <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={(e)=>this.showAllTheFeedbacks(row.original, e)}>
                        <OverlayTrigger placement="bottom" overlay={tooltip('Click to view feeback')}>
                        <span>{feedbacks && Array.isArray(feedbacks) ? feedbacks.length > 0 ? this.listFeedbacks(feedbacks) : 'None' : feedbacks || 'NA'}</span>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={tooltip('Caller history')}>
                        <img src={require('../../../assets/images/Icons_history.svg')} style={{width: '22px', cursor: 'pointer'}} onClick={(e)=>this.showCallerHistoryPopup(row.original.statusChangeLog, e, row.original)}/>
                        </OverlayTrigger>
                    </div>
                },
                width: '2%',
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {feedbacks}} = row
                    const feedbackText = feedbacks && Array.isArray(feedbacks) ? 
                     feedbacks && feedbacks.length > 0 && feedbacks.map(({text}) => {
                        return  text
                    }) : feedbacks || 'NA'
                    return feedbackText.toString()
                },
            },
            {
                Header: 'Actions',
                accessor: "actions",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return mockCallButton(value, this.callModal, this.callerChooseForMock, this.state.confirmCallModal)
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: '',
                accessor: "view",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return mockCallDataFn(value, this.getMockCallDetails)
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            }
        ]

        return (
            <div style={{ maxWidth: "100%" }}>
                <ReactTable
                columns={columns}
                data={qcRetrainingCallers}
                totalCount={qcRetrainingCallers ? qcRetrainingCallers.length : 0}
                limit={10}
                type = 'client'
                currentPage={this.state.currentPage}
                setCurrentPage={(page) => this.setState({currentPage: page})}
                loading={this.state.isTableLoadingQCRetraining}
                // error={error}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'any field'}
                title='QC-Retraining'
                isExport={true}
                />
            </div>
        )
    }

    renderQMFRetrainingCallersTable = () => {
        const { qmfRetrainingCallers, forQMFRetrainingCallers} = this.state
    
        const columns = [
            {
                Header: "Name",
                accessor: "firstname",
                Cell: ({row}) => {
                const {original: {firstname, lastname}} = row
                return <span style={{marginRight: '2px'}}>{firstname || 'NA'} {lastname || 'NA'}</span>
                },
                width: '10%',
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: "mobile",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '5%',
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: "email",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '12%',
                disableSortBy: true
            },
            {
                Header: 'Audit Score',
                accessor: "auditScore",
                Cell: ({value}) => <span>{value || 0}%</span>,
                width: '10%',
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return <CustomTooltip placement={'bottom'} description={'Feedbacks'}
                component={
                    <img src={FeedbackIcon}
                    alt='Feedbacks' className='icon-btn'
                    onClick={()=> this.getAllQMfFdbk(value)} 
                    style={{width: '38px'}}/>
                }
                ></CustomTooltip>
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Call details',
                accessor: "calldetails",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return <div style={{ display: 'flex', gap: '2em', alignItems: 'flex-start' }}>
                <audio controls src={value && value.recordingUrl ? value.recordingUrl : ''} style={{height: '42px'}}/>
                 <CustomTooltip placement={'bottom'} description={'Data collection'}
                     component={<img src={require('../../../assets/images/data-collection.svg')} width='23px'
                         alt='Data collection' className='icon-btn'
                         onClick={(e)=>this.getRowData(value)}
                         />}
                 ></CustomTooltip>
                 <CustomTooltip placement={'bottom'} description={'Call history'}
                     component={<img src={TrackHistory} width='23px'
                         alt='Call history' className='icon-btn'
                         onClick={(e)=>this.getRowData(value)} />}>
                 </CustomTooltip>
                 <CustomTooltip placement={'bottom'} description={'Customer info'}
                     component={<img src={UserIcon} width='23px'
                         alt='Customer info' className='icon-btn'
                         onClick={(e)=>this.getRowData(value)} />}>
                 </CustomTooltip>
             </div>
                },
                width: '40%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Actions',
                accessor: "actions",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return <CustomTooltip
                placement={'bottom'} description={'Place a call'}
                component={mockCallButton(value, this.callModal, this.callerChooseForMock, this.state.confirmCallModal)}>
                </CustomTooltip>
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: '',
                accessor: "view",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return mockCallDataFn(value, this.getMockCallDetails)
                },
                width: '2%',
                disableSortBy: true,
                disableExport: true
            }
        ]

        return (
            <div style={{ maxWidth: "100%" }}>
                <ReactTable
                columns={columns}
                data={qmfRetrainingCallers}
                totalCount={qmfRetrainingCallers ? qmfRetrainingCallers.length : 0}
                limit={10}
                type = 'client'
                currentPage={this.state.currentPage}
                setCurrentPage={(page) => this.setState({currentPage: page})}
                loading={this.state.isTableLoadingQMFRetraining}
                // error={error}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'any field'}
                title='QMF-Retraining'
                isExport={true}
                />
            </div>
        )
    }

    renderTable = (activeTab) => {
        switch (activeTab) {
            case 'shortlisted':
                return this.renderShortlistedCallersTable()
                break;
            case 'iqcTraining':
                return this.renderIQCRetrainingCallersTable()
                break;
            case 'qcRetraining':
                return this.renderQCRetrainingCallersTable()
                break;
            case 'qmfRetraining':
                return this.renderQMFRetrainingCallersTable()
            default:
                break;
        }
    }

    cmmActionHistory = () => {
        let {cmmActionHistoryData, cmmActionHistoryLoading} = this.state
        let formattedData = []
        cmmActionHistoryData && cmmActionHistoryData.map(history => {
            history.cmmActionLog && history.cmmActionLog.map(action => {
                formattedData.push({
                    ...action,
                    title: history.title
                })   
            })
        })

        const columns = [
            {
                Header: "Project",
                accessor: 'title',
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.title ?
                    data.title || 'NA'
                    : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Date',
                accessor: 'actionTakenDate',
                Cell: (cell) => {
                   let data = cell.row.original
                   return data && data.actionTakenDate ?
                   moment(data.actionTakenDate).format("DD/MM/YYYY"): 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'CMM action',
                Cell: (cell) => {
                    let data = cell.row.original
                    return `${capitalise(data.action)} (${capitalise(data.actionType)})`
                },
                disableSortBy: true
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: (cell) => {
                    let data = cell.row.original
                    if(data && data.feedback){
                        const feedbacks = data.feedback.split(";")
                        return <ul>{feedbacks.map((i)=>{
                        return <li key={i+1}>{i}</li>
                        })}</ul>
                    }else{
                        return 'NA'
                    }
                },
                disableSortBy: true
            }
        ]

        return(
            <>
            <div style={{padding: '0 2% 2% 2%'}}>Total Records: {formattedData && !cmmActionHistoryLoading ? formattedData.length : 0}</div>
            <div style={{padding: '2%'}}>
            <CustomTable
            columns={columns}
            data={formattedData}
            loading={cmmActionHistoryLoading}
            type = 'client'
            height = '55'
            />
            </div>
            </>
        )
    }

    qmfFeedbkTable = () => {
        const {activeRow, showQmfFdbk} = this.state
        const { qmfGrades } = activeRow

        const columns = [
            {
                Header: "Category",
                accessor: 'title',
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.category ?
                    data.category || 'NA'
                    : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Parameter',
                accessor: 'behaviour',
                Cell: (cell) => {
                   let data = cell.row.original
                   return data && data.behaviour ?
                   data.behaviour : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Weightage',
                accessor: 'weightage',
                disableSortBy: true
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.feedback ? data.feedback : 'NA'
                },
                disableSortBy: true
            }
        ]

        return(
            <CustomTable
            columns={columns}
            data={qmfGrades}
            loading={false}
            type = 'client'
            height = '55'
            />
        )
    }

    iqcFeedbkTable = (currentData) => {
        const {activeRow, showQmfFdbk} = this.state
        const { qmfGrades } = currentData

        const columns = [
            {
                Header: "Category",
                accessor: 'title',
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.category ?
                    data.category || 'NA'
                    : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Parameter',
                accessor: 'behaviour',
                Cell: (cell) => {
                   let data = cell.row.original
                   return data && data.behaviour ?
                   data.behaviour : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Weightage',
                accessor: 'weightage',
                disableSortBy: true
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.feedback ? data.feedback : 'NA'
                },
                disableSortBy: true
            }
        ]

        return(
            <CustomTable
            columns={columns}
            data={qmfGrades}
            loading={false}
            type = 'client'
            height = '50'
            />
        )
    }

    renderCallerHistoryPopup = () => {
       const {callerHistory, activeRow} = this.state
       const reservedRedFlagStatus = ['ban', 'reapply', 'rejected', 'quit']
       const customStatusName = (prevStatus, previousTrainingStage) => {
           let status = prevStatus.toLowerCase()
           switch (status) {
               case 'applied':
                  return 'Audio test'
                  break;
               case 'pending':
                   return 'Quiz passed'
                   break;
               case 'onhold':
                   return 'Hold'
                   break;
                case 'quit':
                    return 'Quit'
                    break;
                case 'completed':
                    return 'Completed'
                    break;
                case 'inprogress':
                    return 'Selected'
                    break;
                case 'iqchold':
                    return 'IQC'
                    break;
                case 'training':
                    if(previousTrainingStage == 'remock')
                    return 'Remock'
                    if(previousTrainingStage == 'iqcTraining')
                    return 'IQC retraining'
                    if(previousTrainingStage == 'qcRetraining')
                    return 'QC retraining'
                    if(previousTrainingStage == 'mockCall')
                    return 'Ready for Mock'
                    if(previousTrainingStage == 'cmmRetraining')
                    return 'CMM retraining'
                    else
                    return 'Training'
                    break;
                case 'ban':
                    return 'Caller banned'
                    break;
                case 'reapply': 
                    return 'Audio test failed'
                    break;
                case 'rejected': 
                    return 'Rejected'
                    break;
               default:
                   return status
                   break;
           }
       }

        const setHistoryTabFn = (data) => {
            this.setState({callerHistoryActiveTab: {label: data.label, value: data.value}})
        }

        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal call-history-modal"
                // show={this.state.showCertifyModal}
                // show={this.callerAuditModal}
                ref={c => (this.callerAuditModal = c)}
                closeModal={() => {
                    this.setState({callerHistory: [], cmmActionHistoryData: [], activeRow: ''})
                    this.setState({
                        callerHistoryActiveTab: {
                            label: this.tabsForCalleryHistory[0].label,
                            value: this.tabsForCalleryHistory[0].value
                        }
                    })
                    this.callerAuditModal.toggle(false)
                }}
            >
                <div>
                    <div className="heading">
                        {
                            activeRow.trainingStage == 'cmmRetraining' ?
                            <CustomTab tabs={this.tabsForCalleryHistory} setActiveTabFn={setHistoryTabFn} className={'navy-blue'}/>

                            :
                            `Caller history`
                        }
                    </div>
                    
                    {
                        this.state.callerHistoryActiveTab.value == 'callerHistory' ?
                        <div className="caller-history">
                        <ul className="events">
                        {
                            callerHistory && callerHistory.length > 0 ?
                            callerHistory.map(timeline => {
                                return(
                                    <Fragment>
                                    <li>
                                    <time data-after-content={`${moment(timeline.updatedAt).format("DD-MM-YYYY")}      [${moment(timeline.updatedAt).format('LT')}]`}></time>
                                    <span><div className={reservedRedFlagStatus.includes(timeline.previousStatus) ? 'desc red-flag' : 'desc'}>{customStatusName(timeline.previousStatus, timeline.previousTrainingStage)}</div></span>
                                    </li>
                                    </Fragment>
                                )
                            }) :
                            <Fragment>
                                <div>No history found</div>
                            </Fragment>
                        }
                        </ul>
                        </div>
                        :
                        this.cmmActionHistory()
                    }
                </div>
            </FrappModal >
        )
    }

    renderCallInfo = () => {
        const copyToClipboard = (recordingUrl) => {
            navigator.clipboard.writeText(recordingUrl);
        }

        const {activeRow, showCallInfo, callInfoActiveTab} = this.state
        const { outcomeMarked, correctedOutcome} = activeRow

        const setActiveTabFn = (activeTab, activeRow) => {
            this.setState({
                callInfoActiveTab:{
                    label: activeTab.label,
                    value: activeTab.value
                }
            })
        }

        
        const showDC = () => {
            let dataCollection = activeRow && ((activeRow.outcome && activeRow.outcome.responses) || activeRow.responses)
                ? activeRow.responses
                    ? activeRow.responses
                    : activeRow.outcome && activeRow.outcome.responses
                : false;
            return dataCollection && dataCollection.length > 0
                ? <div className='data-collection-wrap'>
                <div className='data-collection-question'>
                    {
                        dataCollection.map((item, index) => {
                            return (
                                <div key={index} className="question-div">
                                    <p className="question" style={{ fontWeight: "bold" }}>{`Q.${index + 1} ${item.question}`}</p>
                                    <p className="answer">Ans: {item.answer}</p>
                                </div>
                            )
                        })
                    }
                </div>
                </div>
                : <span>No Data collection present</span>
        }
        const showCustInfo = () => {
            return <CustomerInfo info={activeRow} />
        }
        const showCallHistory = () => {
            return activeRow && activeRow.callHistory &&
                activeRow.callHistory.length > 0 ?
                <div className="audio-wrap">
                    {
                        activeRow.callHistory.map((ai, i) => {
                            return (
                                <div className='call-attempted'>
                                    <div className='details'>
                                        <span>Attempt {ai.attempts}: {
                                            ai.outcome && ai.outcome.title ? ai.outcome.title : ai.callstatus
                                        }</span>
                                        {
                                            ai.recordingUrl ? <audio controls src={ai.recordingUrl}></audio> : null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> : <h6>No audio files</h6>
        }

        return(
            <Popup
            show={showCallInfo}
            closePopup={() => {
                this.setState({
                    activeRow: '',
                    callInfoActiveTab:{
                        label: this.tabsForCallInfo[0].label,
                        value: this.tabsForCallInfo[0].value
                    },
                    showCallInfo: false
                })
            }}
            size='sm'
            heading={<CustomTab tabs={this.tabsForCallInfo} setActiveTabFn={(data) => setActiveTabFn(data, activeRow)} className='navy-blue' />}
            >
                 <div className='call-audio-info'>
                    <div className='audio_copy_link'>
                        <audio controls src={activeRow.recordingUrl} />
                        <CustomTooltip placement={'bottom'} description={'Copy audio link'}
                            component={
                                <img title="Copy link" className='mock-record-url' src={AttachmentIcon}
                                    onClick={() => copyToClipboard(activeRow.recordingUrl)}
                                    alt='copy audio link' />
                            }
                        />
                    </div>
                    <div className='info'>Outcome marked by caller: {outcomeMarked || 'NA'} | Correct outcome by QA agent: {correctedOutcome || 'NA'}</div>
                    <div className='info'>Call Date: {<span>{moment(activeRow.createdAt).format("DD/MM/YYYY")} at {moment(activeRow.createdAt).format('HH:mm')}</span> || 0} | Disconnected by: {activeRow.disconnectedBy}</div>
                </div>
                <div className='horizontal-divider'></div>
                {
                    activeRow ? <div>
                        {callInfoActiveTab.value == 'data_collection' ? showDC() : null}
                        {callInfoActiveTab.value == 'customer_info' ? showCustInfo() : null}
                        {callInfoActiveTab.value == 'call_history' ? showCallHistory() : null}
                    </div> : <ThreeDotLoader />
                }
            </Popup>
        )
    }

    renderQmfFdbk = () => {
        return(
            <Popup
            show={this.state.showQmfFdbk}
            closePopup={() => {
                this.setState({
                    activeRow: '',
                    showQmfFdbk: false
                })
            }}
            size='lg'
            heading={'Feedbacks'}
            >
                {this.qmfFeedbkTable()}
            </Popup>
        )
    }

    renderIqcFdbk = () => {
        const { activeRow, currentPageIqcCallAudit } = this.state
        const currentData = activeRow && activeRow.callWiseDetails && activeRow.callWiseDetails.length > 0 ? 
        activeRow.callWiseDetails[currentPageIqcCallAudit-1] : {}
       
        const copyToClipboard = (recordingUrl = '') => {
            navigator.clipboard.writeText(recordingUrl);
        }

        return(
            <Popup
            show={this.state.showIqcFdbk}
            closePopup={() => {
                this.setState({
                    currentPageIqcCallAudit:1,
                    activeRow: '',
                    showIqcFdbk: false
                })
            }}
            size='lg'
            heading={'Audit Report'}
            >
            <div className='retraining-call-audited'>

            <div className='patition'>
            <div className='call_recording'>
                <div className='call_recording_row'>
                <span className='sub_heading'>Call Details</span>
                <span className='link'
                onClick={() => copyToClipboard(currentData && currentData.recordingUrl)}
                >Copy audio link</span>
                </div>
                <div className='audio'>
                    <audio controls src={currentData.recordingUrl} />
                </div>
                <p className='sub_heading'>Call Date: {<span>{moment(currentData.callDate).format("DD/MM/YYYY")} at {moment(currentData.callDate).format('HH:mm')}</span> || 0}</p>
                <p className='sub_heading'>Disconnected by: {currentData.disconnectedBy}</p>
                <p className='sub_heading'>Outcome marked by caller: {currentData && currentData.outcomeMarked || 'NA'}</p>
                <p className='sub_heading'>Correct outcome by QA agent: {currentData && currentData.correctedOutcome || 'NA'}</p>
            </div>

            <div className='panel_collection'>
                <Panel title={<span className='sub_heading'>Customer Info</span>}>
                        <div className='customer_info'>
                        {
                            currentData && currentData.data ? Object.entries(currentData.data).map(
                                ([key, value], idx) => (
                                    <div className='info' key={idx + 1}>
                                        {`${capitalise(key)}: ${value}`}
                                    </div>
                                )
                            ) : <div>⚠️ No Customer Data</div>
                        }
                        </div>

                </Panel>
                <Panel title={<span className='sub_heading'>Data collected</span>}>
                    {
                        currentData && currentData.responses && currentData.responses.length > 0 ? currentData.responses.map(data => {
                            return <div className='data_collected'>
                                <div className='qa_ans'>
                                    <p className='qa'>Q: {data ? data.question : 'NA'}</p>
                                    <p><img src={AnswerIcon} /> {data ? data.answer : 'NA'}</p>
                                </div>
                            </div>
                        }) : <div> ⚠️ No Data Collected</div>
                    }
                </Panel>
            </div>
            </div>
            {this.iqcFeedbkTable(currentData)}

            <div className='page-controls'>
            <button
                onClick={() => this.setState({currentPageIqcCallAudit: currentPageIqcCallAudit - 1})}
                disabled={currentPageIqcCallAudit == 1 ? true : false}
                className='page-arrow-btn'
              >
                <FontAwesomeIcon icon={faAngleLeft} className='angle-icons'/>
            </button>
            <div className='page-num'>{currentPageIqcCallAudit} &nbsp;&nbsp;of {'10'}</div>
            <button
                onClick={() => this.setState({currentPageIqcCallAudit: currentPageIqcCallAudit + 1})}
                disabled={activeRow && activeRow.callWiseDetails && activeRow.callWiseDetails.length == currentPageIqcCallAudit ? true : false}
                className='page-arrow-btn'
              >
                <FontAwesomeIcon icon={faAngleRight} className='angle-icons'/>
            </button>
            </div>
            </div>
            </Popup>
        )
    }
}

export default JobApplicationsTraining
