import moment from "moment";

const currentDateIndianFormat = () => {
    return moment(new Date()).format("DD/MM/YYYY")
    // OR
    // return new Date().toLocaleDateString('en-GB', {
    //     month: '2-digit', day: '2-digit', year: 'numeric'
    // })
}

export default currentDateIndianFormat