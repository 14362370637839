import React, { useEffect, useState } from 'react'
import AvailableJobs from './availableJobsTable'
import FrappButton from '../../../components/utilities/FrappButton'
import Upload from '../../../uicomponents/upload/upload'
import styles from '../../../assets/stylesheets/pages/direct_match.module.scss'
import checkIsEmpty from '../../../helper/checkIsEmpty'
import capitalise from '../../../helper/Capitalise'
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import { CSVLink } from "react-csv";
import { DownloadIcon } from '../../../assets/images'
import { ToastContainer, toast } from 'react-toastify'
import { bulkMatchAgentToProject } from './service'
import useApi from '../../../hooks/useApi'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import Popup from '../../../uicomponents/popup'
import Table from '../../../uicomponents/table/Table'
import timeStamp from '../../../helper/timeStamp'

const BulkMatch = () => {
    const bulkMatchAgentToProjectApi = useApi(bulkMatchAgentToProject)
    const [projectData, setProjectData] = useState("")
    const [file, setFile] = useState('')
    const [fileLimit, setFileLimit] = useState(false)
    const [apiMsg, setApiMsg] = useState({ type: '', msg: '' })

    const [ viewFailed, setViewFailed ] = useState({
        status: false,
        msg: []
    })

    const [ payload, setPayload ] = useState('')

    const matchToJob = () => {
        setApiMsg({ type: '', msg: '' })

        if(projectData.id === undefined) {
            // toast("Invalid selection, please reselect project")
            setApiMsg({
                type: 'error',
                msg: 'Invalid selection, please reselect project'
            })
            return  null
        }
        const formData = new FormData();
        formData.append('teleprojectId', projectData.id);
        formData.append('file', file);
        bulkMatchAgentToProjectApi.request(formData)
    }

    const ActionBtns = () => {
        return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
    }

    const passProjectSelected = (data) => {
        setApiMsg({ type: '', msg: '' })
        setProjectData(data)
    }

    const changeHandler = (e) => {
        setApiMsg({ type: '', msg: '' })
        setFileLimit(false)
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) {
            if (e.target.files.length > 0) {
                setFile(file)
            }

        }else{
            setApiMsg({
                type: 'error',
                msg: 'Only a file with .csv extension can be uploaded'
            })
            setFile('')
        }
    }

    const { data: result, loading, error } = bulkMatchAgentToProjectApi || {}
    useEffect(() => {
        if(loading !== true && result !== null){
            const { data: {processed, unprocessed}} = result
            const onlyUnprocessed = (unprocessed = []) => {
                return <span>{unprocessed && unprocessed.length} agents failed to match
                <span className={styles.link} onClick={() => {
                setViewFailed({status: true,msg: unprocessed})
                }}>Click here to view</span></span>
            }

            const processedUnprocessed = (processed = [], unprocessed = []) => {
                return <span>
                    {processed && processed.length} agents got matched & <span className={styles['error-msg']}>{unprocessed && unprocessed.length} failed to match</span>.
                    {unprocessed && unprocessed.length > 0 ? <span className={styles.link}
                    onClick={() => {setViewFailed({status: true, msg: unprocessed})
                    }}>Click here to view</span>: null}
                </span>
            }

            if(unprocessed && unprocessed.length > 0 && processed && processed.length <=0){
                setApiMsg({
                    type: 'error',
                    msg: onlyUnprocessed(unprocessed)
                })
                return
            }
            setApiMsg({
                type: 'success',
                msg: processedUnprocessed(processed, unprocessed)
            })
        }
    }, [result, loading])

    useEffect(() => {
        if(error){
            console.error(handleErrorMessage(error))
            setApiMsg({
                type: 'error',
                msg: handleErrorMessage(error)
            })
        }
    }, [error])


    const showFailedAgents = () => {
        const { msg, status } = viewFailed
        const columns = [
            {
                Header: 'Mobile',
                accessor: 'mobile',
                disableSortBy: true
            },
            {
                Header: 'Reason',
                accessor: 'reason',
                disableSortBy: true
            }
        ]
        return(
            <Popup
            show={status}
            closePopup={()=>{
                setViewFailed({status: false, msg: []})
            }}
            size='sm'
            heading={`View unproccessed`}
            >
                <Table
                data={msg}
                columns={columns}
                totalCount={msg.length || 0}
                loading={false}
                type='client'
                currentPage={1}
                limit={5}
                height='50'
                title={`list-of-agents-failed-to-match-${timeStamp()}`}
                isExport={true}
                />
            </Popup>
        )
    }

    const handlePopup = () => {
        if(viewFailed.status) return showFailedAgents()
    }

    return(
        <div>
            <div className={styles['upload-csv']}>
                <ControlLabel className={styles["csv"]}>
                <div className={styles['header-csv']}>
                CSV headers must be as per the defined Schema 
                <CSVLink data={[{mobile:" "}]} filename={`bulk-match.csv`} >
                {/* <div className={styles["download-btn"]}>
                <img title="Download Template" src={DownloadIcon} alt='download'/>
                </div> */}
                </CSVLink>
                </div>
                <table className={styles["headers-table"]}><tr>{
                <>
                <th className={styles["headers-list"]}>mobile<span style={{color:"red"}}>*</span></th>
                <th className={styles["headers-list"]}>earnings<span style={{color:"red"}}></span></th>
                </>

                }</tr></table>
                </ControlLabel>
            </div>

            <div className={styles.bulk_matc_csv_wrapper}>
                <div className={styles.file_upload}>
                    <Upload placeholder='Select CSV file to upload' onChange={changeHandler}/>
                </div>
            </div>
           
            {
                !checkIsEmpty(apiMsg) ?
                    <div className={apiMsg.type == 'success' ?
                        styles['success-msg'] : styles['error-msg']}>{apiMsg.msg}</div>
                    : null
            }

           {file && 
           <>
            <AvailableJobs passProjectSelected={passProjectSelected}/>
            <ActionBtns/>
            </>
            }
        <ToastContainer draggable={true} />
        {handlePopup()}
        </div>
    )
}

export default React.memo(BulkMatch)