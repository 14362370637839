import React from 'react'
import MaterialTable, { MTablePagination, MTableHeader, MTableToolbar } from "material-table";

const OutcomeInsights = ({ outcomeInsights }) => {

    const calculateDeviation = (data) => {
        const {isWinning} = data

        const assignPosNegSign = (callerAvg, sysAvg, deviation) => {
            //assign positive if callerAvg > sysAvg
            if(callerAvg > sysAvg){
                return deviation
            }
            //assign negative if callerAvg < sysAvg
            else if(callerAvg < sysAvg){ 
                return deviation
            }
            //deviation is 0 if callerAvg == sysAvg
            else{
                return deviation
            }
        }

        const acceptableRange = (sysAvg, isWinning) => {
            //use this range if its winning outcome
            const winningRange = {
                low: 15,
                high: 15
            }
            //use this range if its non-winning outcome
            const nonWinningRange = {
                low: 20,
                high: 20
            }

            //acceptable range calculated if its a winning outcome
            if(isWinning){
                return{
                    low: sysAvg - winningRange.low,
                    high: sysAvg + winningRange.high
                }
            }
            //acceptable range calculated if its a non-winning outcome
            else{   
                return{
                    low: sysAvg - nonWinningRange.low,
                    high: sysAvg + nonWinningRange.high
                }
            }
        }
        
        const checkSuspect = (callerAvg, acceptableLimit) => {
            if((callerAvg >= acceptableLimit.low) && (callerAvg <= acceptableLimit.high)){
                return false
            }else{
                //if the above condition returns false then the caller is a suspect
                return true
            }
        }

        const assignAlertImage = (isSuspect, deviation) => {
            if(isSuspect){
                return <span class='deviation-wrap'>{deviation}%<img src={require('../../assets/images/Icons-alert.svg')}/></span>
            }else{
                return <span class='deviation-wrap'>{deviation == 0 ? '-' : `${deviation}%`}<img/></span>
            }
        }

        const callerAvg = Math.round(data.userAvg)
        const systemAvg = Math.round(data.sysAvg)
        let deviation = callerAvg - systemAvg
        //assign the positive or negative sign to deviation
        deviation = assignPosNegSign(callerAvg, systemAvg, deviation)
        //get the acceptable range to check with systemAvg
        const limitRange = acceptableRange(systemAvg, isWinning)
        //check if the caller average is suspect
        const isSuspect = checkSuspect(callerAvg, limitRange)
        return (
            <div className={`call-outcome ${isSuspect ? 'suspect' : 'not-suspect'}`}>{assignAlertImage(isSuspect, deviation)}</div>
        )
      }

    return <MaterialTable
        localization={{
            body: {
                emptyDataSourceMessage: 'No records to display',
                // filterRow: {
                //     filterTooltip: 'Filter'
                // }
            }
        }}
        columns={[
            {
                title: "Outcomes",
                field: "outcome",
                render: (rowData => {
                    return <div className='call_outcome_winning_outcome'>{rowData.outcome}{rowData.isWinning == true ? <img src={require("../../assets/images/Icons_yellow star.svg")} className='call_outcome_winning_outcome_star' /> : null}</div>
                }),
                width: "25%",
            },

            {
                title: "System Average",
                field: "sysAvg",
                width: "25%",
                render: rowData =>
                    <div>
                        {Math.round(rowData.sysAvg)}%
           </div>
            },
            {
                title: "Caller Average", field: "userAvg",
                width: "25%",
                render: rowData =>
                    <div>
                        {Math.round(rowData.userAvg)}%
           </div>,
            },

            {
                title: "Deviation",
                width: "25%",
                render: rowData => <span>{calculateDeviation(rowData)}</span>
            },

        ]}
        data={outcomeInsights}
        actions={[]}
        components={{}}
        // tableRef={this.tableRef}
        isTableLoading={true}
        options={{
            // selection:true,
            sorting: false,
            filtering: false,
            grouping: false,
            toolbar: false,
            paging: false,
            exportButton: false,
            exportAllData: false,
            columnsButton: true,
            emptyRowsWhenPaging: false,
            cellStyle: {
                fontSize: '14px',
                color: 'black'
            }, headerStyle: {
                backgroundColor: '#E7FAF2',
                fontSize: '16px',
                fontWeight: 'bold',
                boxShadow: 'none'

            }
        }}
    />
}

export default OutcomeInsights