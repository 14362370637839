import React, { Fragment, useContext, useEffect, useState } from 'react'
import FormGroup from "react-bootstrap/lib/FormGroup";
import FrappButton from '../../utilities/FrappButton'
import FormControl from 'react-bootstrap/lib/FormControl';
import { ISContext } from './ISContext'
import Switch from "../../utilities/Switch"
import Papa from 'papaparse';
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import ApiActions from '../../../actions/ApiActions';
import { InfoIcon, DownloadIcon } from '../../../assets/images'
import CustomTooltip from '../../utilities/ToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import FutworkButton from '../../../uicomponents/button/Button';

const DataCollectionForm = (props) => {
    const { currentNodeData, setCurrentNodeData, dcOutcomes, setDCOutcomes, validationArray, allScripts, setAllScripts, setElements, setElements1, setAllScripts2, teleprojectdata } = useContext(ISContext)
    const formQs = props.formData[0].nodeData.data.form && props.formData[0].nodeData.data.form.length > 0 ? props.formData[0].nodeData.data.form : []
    //     label: "",
    //     inputType: "text",
    //     validationType: "simple_input",
    //     validation: "",
    //     isOptional: false
    // }]
    const [questionCount, addQuestionCount] = useState(formQs)
    const [isError, setError] = useState('')
    const [isSuccess, setSuccess] = useState('')
    const [selectedDC, setSelectedDC] = useState('')
    const [movingIndex, setMovingIndex] = useState(null);
    const [direction, setDirection] = useState(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);

    useEffect(() => {
        if (props.formData[0].nodeData.data.form && props.formData[0].nodeData.data.form.length > 0) {
            setSelectedDC(props.formData[0])
        } else {
            setSelectedDC(props.formData[0])
        }
    }, [])

    const resetMessage = () => {
        if (isError) {
            setError('')
        }
        if (isSuccess) {
            setSuccess('')
        }
    }

    const toggle = (e) => {
        resetMessage()
        setSelectedDC(e)
        let r = props.formData.find(ee => ee.name == e.name)
        const formQs = r.nodeData.data.form && r.nodeData.data.form.length > 0 ? r.nodeData.data.form : []
        addQuestionCount(formQs)
    }

    const renderOutcomeForm = (outcome, i) => {
        return (
            <div className="outcome-wrapper">
                <div className="feedback-form">
                    {
                        // questionCount.length > 0 ? (
                            renderAdditionalFeedbackForm(questionCount)
                        // ) : null
                    }
                </div>
            </div>
        );
    };

    const setQuestionText = (e, i, i2) => {
        let additionalFeedback = questionCount;
        additionalFeedback[i2].label = e.target.value;
        addQuestionCount([...additionalFeedback])
    };

    // handle csv formate and popup
    const setCsv = (e, i, i2) => {
        questionCount[i2].options = [];
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            processCsvFile(e.target.result, i, i2);
        };
        reader.readAsText(file);
    };

    const processCsvFile = (result, i, i2) => {
        let { data } = Papa.parse(result, { header: true });
        if (!data[0].hasOwnProperty('options')) {
            alert("csv must have single header i.e. 'options' ")
            return
        }
        delete questionCount[i2].validation;
        if (data[0].options) {
            data.map((opt, index) => {
                if (opt.options !== "") {
                    let options = questionCount[i2].options;
                    let option = {
                        optionText: opt.options,
                    };

                    if (options && options.length > 0) {
                        options.push(option);
                    } else {
                        let arr = [];
                        arr.push(option);
                        questionCount[i2].options = arr;
                    }
                    addQuestionCount([...questionCount])
                }
            })

        }
    }

    const setDefault = (e, i, i2) => {
        questionCount[i2].isOptional = e.target.checked;
        addQuestionCount([...questionCount])
    }

    const setOption = (e, i, i2, i3) => {
        delete questionCount[i2].validation;
        delete questionCount[i2].validationType;
        delete questionCount[i2].customLength;
        delete questionCount[i2].isCustom;
        let options = questionCount[i2].options;
        options[i3].optionText = e.target.value;
        addQuestionCount([...questionCount])
    };

    const checkForTextValidation = () => {
        const isValid = questionCount.some(each => each.label !== '')||questionCount.length==0
        if (isValid) {
            return false
        } else {
            return true
        }
    }

    const renderOptionsForm = (options, i2, i) => {
        return (
            options &&
            options.map((option, i3) => {
                return (
                    <div className="input-option-div">
                        <FormGroup className="label">
                            <FormControl
                                className="qs-title"
                                //   required
                                name="options"
                                value={option.optionText}
                                onChange={(e) => setOption(e, i, i2, i3)}
                                placeholder="Option Text"
                            />
                        </FormGroup>
                        <div className="delete-icon">
                            <img width="20px" src={require("../../../assets/images/Icons_delete.svg")} onClick={() => deleteOption(i, i2, i3)} />
                        </div>
                    </div>
                );
            })
        );
    };
    const renderOptionsCSV = (options) => {
        return (
            options &&
            options.map((option, index) => {
                return (
                    <div key={index}>{`${index+1}) ${option.optionText}`}</div>
                );
            })
        );
    }

    const deleteOption = (i, i2, i3) => {
        let feedback = questionCount;
        let option = feedback[i2].options;
        option.splice(i3, 1);
        addQuestionCount([...questionCount])
    };

    const addOptions = (i, i2) => {
        let options = questionCount[i2].options;
        let option = {
            optionText: "",
        };
        if (options && options.length > 0) {
            options.push(option);
        } else {
            let arr = [];
            arr.push(option);
            questionCount[i2].options = arr;
        }
        addQuestionCount([...questionCount])
    };

    const toggleRemoteOptionsUrl = (i, i2, e) => {
        if(e.target.checked){
            questionCount[i2].options = []
            questionCount[i2].remoteOptionsUrl = ''
            addQuestionCount([...questionCount])
        }else{
            let option = {
                optionText: "",
            };
            questionCount[i2].options = [option]
            delete questionCount[i2].remoteOptionsUrl
            addQuestionCount([...questionCount])
        }
    }

    const setRemoteOptionsUrl = (i, i2, e) => {
        questionCount[i2].remoteOptionsUrl = e.target.value
        addQuestionCount([...questionCount])
    }

    const setCustomValidation = (e, i, i2) => {
        let validationVal = questionCount[i2].validation
        validationVal = validationVal.replace(/ *\{[^)]*\} */g, "{}")
        validationVal = validationVal.slice(0, -1) + e.target.value + validationVal.slice(-1)
        questionCount[i2].validation = validationVal
        questionCount[i2].customLength = e.target.value
        addQuestionCount([...questionCount])
    }
    const renderInput = (i, i2) => {
        return (
            <FormGroup className="custom-valdation">
                <FormControl
                    className="qs-title"
                    name="custom_length"
                    type="number"
                    value={questionCount[i2].customLength}   //custom length will get from bckend
                    onChange={(e) => setCustomValidation(e, i, i2)}
                    placeholder="Option Text"
                />
            </FormGroup>
        )
    }

    const addQuestionTypeOnDateTime = (af, i2, type, i) => {
        delete questionCount[i2].validation;
        delete questionCount[i2].validationType;
        delete questionCount[i2].customLength;
        delete questionCount[i2].isCustom;
        let additionalFeedback = questionCount;
        additionalFeedback[i2].inputType = type;
        addQuestionCount([...questionCount])
    }

    const deleteQuestion = (i, i2) => {
        resetMessage()
        let feedback = [...questionCount];
        feedback.splice(i2, 1);
        addQuestionCount([...feedback])
    };

    const addQuestion = () => {
        resetMessage()
        let feedback = [...questionCount];
        let feedbackToPush = {
            label: "",
            inputType: "text",
            validationType: "simple_input",
            validation: "",
            isOptional: false
        };
        feedback.push(feedbackToPush);
        addQuestionCount([...feedback])
    };

    const addValidationOntext = (i, i2, val) => {
        let additionalFeedback = [...questionCount];
        additionalFeedback[i2].isCustom = val.isCustom
        additionalFeedback[i2].validation = val.validation;
        additionalFeedback[i2].customLength = val.customLength || 0;
        additionalFeedback[i2].validationType = val.validationType;
        addQuestionCount([...additionalFeedback])
    }

    const getValidationTitle = (val) => {
        if (validationArray && validationArray.length > 0) {
            let validationObj = validationArray.find((item) => {
                return item.validationType === val.validationType
            })
            if (validationObj && validationObj.question) {
                return validationObj.question
            } else {
                return "Select"
            }
        }
    }

    const setQuestionType = (af, i2, type, i) => {
        questionCount[i2].inputType = type;
        addQuestionCount([...questionCount])
        if (
            type === "text" &&
            questionCount[i2].options &&
            questionCount[i2].options.length > 0
        ) {
            delete questionCount[i2].options;
            addQuestionCount([...questionCount])
        }
        if (type === "singleselect" || type === "multiselect") {
            delete questionCount[i2].validation;
            addQuestionCount([...questionCount])
            addOptions(i, i2);
        }
        if (type === "date-time" || type === "time" || type === "date") {
            delete questionCount[i2].options;
            addQuestionCount([...questionCount])
            addQuestionTypeOnDateTime(af, i2, type, i)
        }
    };

    const reorder = (array, sourceIndex, destinationIndex) => {
        const smallerIndex = Math.min(sourceIndex, destinationIndex);
        const largerIndex = Math.max(sourceIndex, destinationIndex);
    
        return [
            ...array.slice(0, smallerIndex),
            ...(sourceIndex < destinationIndex
                ? array.slice(smallerIndex + 1, largerIndex + 1)
                : []),
            array[sourceIndex],
            ...(sourceIndex > destinationIndex
                ? array.slice(smallerIndex, largerIndex)
                : []),
            ...array.slice(largerIndex + 1),
        ];
    }

    const moveUp = (index) => {
        if(index > 0){
            setMovingIndex(index);
            setDirection('up');
            setTimeout(() => {
                const rearrangedOrder = reorder(questionCount, index, index-1)
                addQuestionCount([...rearrangedOrder])
                setMovingIndex(null);
                setDirection(null);
                setHighlightedIndex(index - 1);
            }, 300); // match the transition duration in SCSS
        }
    }

    const moveDown = (index) => {
        if (index < questionCount.length - 1) {
            setMovingIndex(index);
            setDirection('down');
            setTimeout(() => {
                const rearrangedOrder = reorder(questionCount, index, index+1)
                addQuestionCount([...rearrangedOrder])
                setMovingIndex(null);
                setDirection(null);
                setHighlightedIndex(index + 1);
              }, 300); // match the transition duration in SCSS
        }
    }


    useEffect(() => {
        const handleClick = () => setHighlightedIndex(null);
        document.addEventListener('click', handleClick);
    
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, []);

    const downloadOptionsCsv = (datacollection) => {
        const {options, label} = datacollection
        var csvVal = Papa.unparse(options, {
            header: false,
            columns: [
              "optionText",
            ], //or array of strings
          });
        var headersVal = Papa.unparse({ fields: ['options'], data: [] });
        var result = headersVal + csvVal;

        const getFormattedTime = () => {
            var today = new Date();
            var y = today.getFullYear();
            // JavaScript months are 0-based.
            var m = today.getMonth() + 1;
            var d = today.getDate();
            var h = today.getHours();
            var ampm = h >= 12 ? 'pm' : 'am'
            var mi = today.getMinutes();
            var s = today.getSeconds();
            return `${d}-${m}-${y}-${h}-${mi}-${ampm}`;
        }

        const blob = new Blob([result], { type: 'text/csv' });
        // generate a URL for the Blob.
        const blobUrl = URL.createObjectURL(blob);
        // create a link and set its href attribute to the generated URL.
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${teleprojectdata.title}-${label}-options-list-${getFormattedTime()}.csv`; // Set the desired file name here
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Release the object URL to prevent memory leaks
        URL.revokeObjectURL(blobUrl);
    }
    const renderAdditionalFeedbackForm = (feedback, i) => {
        return (
            <div>
                {feedback &&
                    feedback.map((af, i2) => {
                        return (
                            <div
                            className={`question-form ${movingIndex === i2 ? (direction === 'up' ? 'moving-up' : 'moving-down') : ''} ${highlightedIndex === i2 ? 'highlighted' : ''}`}
                            >
                            <div className="each-feeback">
                                <div className='title-section'>
                                    <div>Question {i2 + 1}</div>
                                    <div className='re-order-section'>
                                    <FontAwesomeIcon icon={faAngleUp} className={i2 == 0 ? 'disabled' : undefined} onClick={()=> moveUp(i2)}/>
                                    <FontAwesomeIcon icon={faAngleDown} className={i2 == feedback.length-1 ? 'disabled' : undefined} onClick={()=> moveDown(i2)}/>
                                    </div>
                                </div>
                                <FormGroup className="label">
                                    <FormControl
                                        className="qs-title"
                                        //   required
                                        name="question"
                                        value={af.label}
                                        onChange={(e) => setQuestionText(e, i, i2)}
                                        placeholder="Question text"
                                    />
                                </FormGroup>
                                <div className="tabs-wrapper">
                                    <div className="datetime-dropdown">
                                    <DropdownButton
                                        // bsStyle={title.toLowerCase()}
                                        title={
                                            af.inputType === "singleselect"
                                                ? "Single Select" : af.inputType === "multiselect" ? 'Multi Select' : af.inputType === "text"
                                                    ? "Text" :  (af.inputType === "date-time" || af.inputType === "date" || af.inputType ===  "time")
                                                        ? "Date & Time" : null
                                        }
                                        // key={i}
                                        id='dropdown-basic-1'
                                    >
                                        <MenuItem eventKey="dt1" onSelect={() => setQuestionType(af, i2, "singleselect", i)}>Single Select</MenuItem>
                                        <MenuItem eventKey="dt1" onSelect={() => setQuestionType(af, i2, "multiselect", i)}>Multi Select</MenuItem>
                                        <MenuItem eventKey="dt2" onSelect={() => setQuestionType(af, i2, "text", i)}>Text</MenuItem>
                                        <MenuItem eventKey="dt3" onSelect={() => setQuestionType(af, i2, "date-time", i)}>Date & Time</MenuItem>
                                    </DropdownButton>
                                    </div>

                                    {
                                    af.inputType === "text" ? (
                                        <div className="text-validation">
                                            <DropdownButton
                                                // bsStyle={title.toLowerCase()}
                                                title={getValidationTitle(questionCount[i2])}
                                                // key={i}
                                                id='dropdown-basic'
                                            >
                                                {
                                                    validationArray.map((value, index) => {
                                                        return <MenuItem key={`dropdown-${index}`} eventKey={index + 1} onSelect={() => addValidationOntext(i, i2, value)}>{value.question}</MenuItem>
                                                    })
                                                }
                                            </DropdownButton>
                                            {
                                                questionCount[i2] && questionCount[i2].validationType && questionCount[i2].validationType.includes("custom")
                                                    ? renderInput(i, i2)
                                                    : null
                                            }
                                        </div>
                                    ) : null
                                }
                                {
                                    af.inputType === "date-time" || af.inputType === "date" || af.inputType === "time" ? (
                                        <div className="datetime-dropdown">
                                            <DropdownButton
                                                // bsStyle={title.toLowerCase()}
                                                title={
                                                    questionCount[i2].inputType === "date"
                                                        ? "Date" : questionCount[i2].inputType === "time"
                                                            ? "Time" : questionCount[i2].inputType === "date-time"
                                                                ? "Date & Time" : null
                                                }
                                                // key={i}
                                                id='dropdown-basic-2'
                                            >
                                                <MenuItem eventKey="dt1" onSelect={() => addQuestionTypeOnDateTime(af, i2, "date", i)}>Date</MenuItem>
                                                <MenuItem eventKey="dt2" onSelect={() => addQuestionTypeOnDateTime(af, i2, "time", i)}>Time</MenuItem>
                                                <MenuItem eventKey="dt3" onSelect={() => addQuestionTypeOnDateTime(af, i2, "date-time", i)}>Date & Time</MenuItem>
                                            </DropdownButton>
                                        </div>
                                    ) : null
                                }

                                    {
                                        af.inputType === "singleselect" &&
				                        <label style={{margin: '0'}}><input type="checkbox"
                                        checked={af.hasOwnProperty("remoteOptionsUrl") ? true : false}
                                        onChange={(e) => toggleRemoteOptionsUrl(i, i2, e)}/> <span>Bulk list search</span>
                                        <CustomTooltip placement={'bottom'} description={'Enable this list to choose an externally hosted bulk list such as pincodes'}
                                         component={<img src={InfoIcon} alt={'info'} width='16px' style={{marginLeft: '0.5em'}}/>} />
                                        </label>                                     

                                    }
                                    {(af.inputType === "singleselect" || af.inputType === "multiselect") ? (
                                        af && !af.hasOwnProperty("remoteOptionsUrl") && <div className="upload-csv">
                                            <input type="file" id="faqcsv"
                                                onChange={(e) => setCsv(e, i, i2)}
                                            />
                                        </div>
                                    ) : null}
                                    {
                                        (af.inputType === "singleselect" || af.inputType === "multiselect") && af.options.length > 0
                                         ? 
                                         
                                         <CustomTooltip placement={'bottom'} 
                                         component={<img src={DownloadIcon} onClick={() => downloadOptionsCsv(af)} className='download-csv'/>}
                                         description={'Download options list as CSV'}/>
                                         : null
                                    }
                                    
                                            
                                </div>

                                {(af.inputType === "singleselect" || af.inputType === "multiselect") && af.options.length <= 20 ? (
                                    af.hasOwnProperty("remoteOptionsUrl") ?
                                    <div className="input-option-div" style={{marginTop: '20px'}}>
                                    <FormGroup className="label">
                                    <FormControl
                                    className="qs-title"
                                    //   required
                                    name="remoteoptionsurl"
                                    value={af.remoteOptionsUrl}
                                    onChange={(e) => setRemoteOptionsUrl(i, i2, e)}
                                    placeholder="Enter the pincode or city url"
                                    />
                                    </FormGroup>
                                    </div>
                                    :
                                    <Fragment>
                                        <div className="options-form-wrapper">
                                            {
                                                af.options && af.options.length > 0
                                                    ? renderOptionsForm(af.options, i2, i)
                                                    : null
                                            }
                                        </div>
                                        <FrappButton
                                            className="grey-button"
                                            handler={() => addOptions(i, i2)}
                                        >
                                            Add options
                                        </FrappButton>
                                    </Fragment>
                                ) :
                                    (af.inputType === "singleselect" && !af.hasOwnProperty("remoteOptionsUrl") ? (<div className="csv-preview">
                                        {
                                            af.options && af.options.length > 0
                                                ? renderOptionsCSV(af.options, i2, i)
                                                : null
                                        }
                                    </div>) : null)
                                }
                            </div>
                            <div className="bottom-btns">
                                <div className="switch-optional">
                                        <p>Optional</p>
                                        <Switch
                                            checked={questionCount[i2].isOptional}
                                            onToggle={(e) => setDefault(e, i, i2)}
                                            name={`checked${i - i2}`}
                                        />

                                </div>
                                    <FrappButton
                                        className="grey-button delete-dc-question"
                                        handler={() => deleteQuestion(i, i2)}
                                        >
                                        Delete Question
                                    </FrappButton>
                                </div>
                            </div>
                        );
                    })}
                <FrappButton
                    className="dc-add-question-btn"
                    handler={() => addQuestion(i)}
                // id="add-qs-ar"
                >
                    + Add Question
                </FrappButton>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', gap: '10px' }}>
                    {isError ? <span style={{ color: 'red', fontWeight: '600' }}>{isError}</span> : null}
                    {isSuccess ? <span style={{ fontWeight: '600' }}>{isSuccess}</span> : null}
                    <FrappButton
                        className="submit"
                        type="submit"
                        id="new-btn"
                        disabled={checkForTextValidation()}
                        handler={(e) => submitForm(e)}
                    >
                        Save
					</FrappButton>
                </div>

            </div>
        );
    };

    const submitForm = (e) => {
        e.preventDefault();
        resetMessage()
        const dataToSend = {
            "script": selectedDC.id,
            "nodeId": props.formsDataCollection.nodeData.id,
            "form": questionCount
        }

        let notANewNode = props.allScripts2.length > 0 ? props.allScripts2.find(e => e.id == props.formsDataCollection.nodeData.id) : ''
        if (!notANewNode) {
            alert('Please save the script first and then create the data collection for it')
            return true
        }

        ApiActions.createDataCollection(dataToSend)
            .then(resp => {
                // props.closeDCPopup()
                let result = allScripts.map(each => {
                    if (each.id == resp.data.id) {
                        each.script = resp.data.script
                        return each
                    } else {
                        return each
                    }
                })
                setAllScripts([...result])
                result.map(elem => {
                    // if (elem.defaultScript) {
                        setElements(elem.script)
                        setElements1([...elem.script])
                        setAllScripts2(elem.script)
                    // }
                })
                let b = currentNodeData.map((e) => {
                    if (e.id == dataToSend.script) {
                        e.nodeData.data.form = dataToSend.form
                        return e
                    }
                    return e
                })

                setCurrentNodeData([...b])
                setSuccess(`Data collection for ${resp.data.scriptLanguage} was saved successfully`)
            })
            .catch(err => {
                if (err) {
                    setError(`Something went wrong while saving`)
                }
            })
    }
    const formRender = () => {
        const highestQuestions = Math.max(...props.formData.map(el => el.nodeData.data.form ? el.nodeData.data.form.length : 0))
        return <div className="additional-wrapper2" style={{ padding: '2%' }}>
            <div style={{ display: 'flex', flexFlow: 'row', width: '100%', gap: '10px', marginBottom:'20px'}}>
                {
                    props.formData.map(e => {
                        return <div className='dc single-tab'>
                            <div onClick={(f) => {
                                toggle(e)
                            }}>
                                <a>
                                    <div className={selectedDC.name == e.name ? `each-tab active` : highestQuestions > (e.nodeData.data.form ? e.nodeData.data.form.length : 0) ? `each-tab error` : `each-tab`}>{e.name}({e.nodeData.data.form ? e.nodeData.data.form.length : 0})
                    {highestQuestions > (e.nodeData.data.form && e.nodeData.data.form.length ? e.nodeData.data.form.length : 0) ?
                                            <img src={require('../../../assets/images/Interactive Script/redo_urgent.svg')} />
                                            : null
                                        }
                                    </div>
                                </a>
                            </div>
                        </div>
                    })
                }
            </div>
            {renderOutcomeForm()}
        </div>
    }
    return formRender()
}

export default DataCollectionForm