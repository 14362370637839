import React from 'react'
import capitalise from "../../helper/Capitalise";

const CustomerInfo = ({info}) => {
    return <div className="customer-infos">
        <p id="heading" > ☆ Customer Info</p >
        <div className="customer-infos-div">
            {
                info && info.data
                    ? Object.entries(info.data).map(
                        ([key, value]) => (
                            <div>
                                {`${capitalise(key)}: ${value}`}
                            </div>
                        )
                    ) : <div> ⚠️ No Customer Data  </div>
            }
            <div>

            </div>
        </div>
    </div>
}

export default CustomerInfo