import ApiActions from "../../../actions/ApiActions";
import generatePayload from "../../../helper/generatePayloadForTable";

const createUpdateTrainingMeetings = async(payload) => {
    return await ApiActions.createUpdateTrainingMeetings(payload)
}

const getListOfAgents = async(teleprojectId) => {
    return await ApiActions.fetchAgentsForInvite(teleprojectId)
}

const getCountOfListOfAgents = async(teleprojectId) => {
    return await ApiActions.fetchCountsOfAgentsForInvite(teleprojectId)
}

const inviteAgentsToSession = async(payload) => {
    return await ApiActions.inviteAgentsToSession(payload)
}

const getSessionsOfProject = async(payload) => {
    return await ApiActions.fetchSessionOfProject(payload)
}

const getSessionParticipantList = async(payload) => {
    return await ApiActions.fetchSessionParticipantList(payload)
}

const markAttendanceForAgent = async(payload) => {
    return await ApiActions.markAttendanceForAgent(payload)
}

const getTeleProject = async(teleprojectId) => {
    return await ApiActions.getTeleProject(teleprojectId)
}


export {
    createUpdateTrainingMeetings,
    getListOfAgents,
    getCountOfListOfAgents,
    inviteAgentsToSession,
    getSessionsOfProject,
    getSessionParticipantList,
    markAttendanceForAgent,
    getTeleProject
}