import React, { useEffect, useState } from 'react'
import FutworkSetTimer from '../utilities/FutworkSetTimer'
import moment from 'moment';
import { Fragment } from 'react';
import { hidden } from 'chalk';

const WorkingHours = ({ getWorkTiming, formData, removeSecondTimeSlot }) => {
    let { workTime } = formData
    const [errorMsg, setErrorMsg] = useState('')

    const parentFunction = (f, label) => {
        if (label.toLowerCase().includes('start')) {
            workTime.startTime = f._i
            getWorkTiming(workTime)
        }
        if (label.toLowerCase().includes('end')) {
            workTime.endTime = f._i
            getWorkTiming(workTime)
        }
        checkTime()
    }

    const parentFunction2 = (f, label) => {
        if (label.toLowerCase().includes('start')) {
            workTime.startTime2 = f._i
            getWorkTiming(workTime)
        }
        if (label.toLowerCase().includes('end')) {
            workTime.endTime2 = f._i
            getWorkTiming(workTime)
        }
        checkTime2()
    }

    const checkTime = () => {
        let startTime = moment(workTime.startTime, 'h:mm');
        let endTime = moment(workTime.endTime, 'h:mm');
        if(startTime.isBefore(endTime)) {
            setErrorMsg('')
        } else {
           setErrorMsg('')
        //    setErrorMsg('Error. Start Time is more than End Time')
        }
    }

    const checkTime2 = () => {
        let startTime = moment(workTime.startTime2, 'h:mm');
        let endTime = moment(workTime.endTime2, 'h:mm');
        if(startTime.isBefore(endTime)) {
            setErrorMsg('')
        } else {
           setErrorMsg('')
        //    setErrorMsg('Error. Start Time is more than End Time')
        }
    }

    return (
        <Fragment>
        <div className='working-hours-wrapper'>
            <FutworkSetTimer label={'Start time'} time={workTime.startTime} parentFunction={parentFunction} />
            <FutworkSetTimer label={'End time'} time={workTime.endTime} parentFunction={parentFunction} />
            <img src={require("../../assets/images/del-icon-taskprops.svg")}
            className='red-delete-img'
            onClick={()=>removeSecondTimeSlot()}
            style={{visibility: 'hidden'}}
            />
        </div>
        {
            workTime.startTime2 && workTime.endTime2 
            //&& ((workTime.startTime2 || workTime.endTime2) !== 'Invalid date') 
            ?
            <div className='working-hours-wrapper'>
            <FutworkSetTimer label={'Start time'} time={workTime.startTime2} parentFunction={parentFunction2} />
            <FutworkSetTimer label={'End time'} time={workTime.endTime2} parentFunction={parentFunction2} />
            <img src={require("../../assets/images/del-icon-taskprops.svg")}
            className='red-delete-img'
            onClick={()=>removeSecondTimeSlot()}
            />
            </div> : null
        }
        {errorMsg ? <span style={{fontSize: '12px', color: 'red', display: 'flex', padding: '2% 0' }}>{errorMsg}</span> : null}
        </Fragment>
    )
}

export default WorkingHours