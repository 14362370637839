import React, { Fragment } from 'react'
import moment from 'moment'
import styles from '../../assets/stylesheets/pages/hiring/history.module.scss'
import Panel from '../../uicomponents/panel'
import CustomerInfo from '../../components/utilities/CustomerInfo'
import DataCollection from '../../uicomponents/data-collection'

const SUMMARIZE_CALL_FOR_FUTURE = "Summarize the call for future reference"
const CALLBACK_DATE_TIME = "Note down the date and time to call back later"
const SUMMARIZE_CALL_FOR_FOLLOW_UP = "Summarize call discussion for follow up"
const FOLLOW_UP_DATE_TIME = "Note down the date and time for follow up"
const MARKED_CALL_BACK = "callback"
const MARKED_FOLLOW_UP = "followup"

const CallHistory = ({data}) => {
    const { data: customer, callRecords } = data

    const followupNote = (responses) => {
        let followups = responses && responses.map(note => {
            return SUMMARIZE_CALL_FOR_FOLLOW_UP == note && note.question ?
            note : undefined
        }).filter(Boolean)
        return followups && followups.length > 0 ?
        followups.map(followup => <span>{followup.answer}</span>) : <span>NA</span>
    }

    const scbSummary = (responses) => {
        let scb = responses && responses.map(note => {
            return SUMMARIZE_CALL_FOR_FUTURE === note.question ?
            note : undefined
        }).filter(Boolean)
        return scb && scb.length > 0 ?
        scb.map(scb => <span>{scb.answer}</span>) : <span>NA</span>
    }

    const followupSchedule = (responses) => {
        let followups = responses && responses.map(note => {
            return FOLLOW_UP_DATE_TIME == note && note.question ?
            note : undefined
        }).filter(Boolean)
        return followups && followups.length > 0 ?
        followups.map(followup => followup.answer) : ''
    }

    const scbSchedule = (responses) => {
        let scb = responses && responses.map(note => {
            return CALLBACK_DATE_TIME === note.question ?
            note : undefined
        }).filter(Boolean)
        return scb && scb.length > 0 ?
        scb.map(scb => scb.answer) : ''
    }

    return(
        <>
        {customer && <Fragment>
        <Panel title='Customer info'>
        <CustomerInfo info={{data:{...customer}}} />
        </Panel>
        <div className={styles['horizontal_divider']} />
        </Fragment>}

        <div className={styles['call_attempt_wrapper']}>
        {
            callRecords && callRecords.length > 0 ?
            callRecords.map(call => {
                let scheduledtime = ''
                if(data && data.marked == MARKED_FOLLOW_UP){
                    scheduledtime = call.responses && call.responses.length > 0 &&
                    followupSchedule(call.responses)
                }
                if(data && data.marked == MARKED_CALL_BACK){
                    scheduledtime = call.responses && call.responses.length > 0 &&
                    scbSchedule(call.responses)
                }
                return <div className={styles['attempts_card']}>
                    <div className={styles['call_details']}>
                    <div className={styles['attempt_name']}>
                     <div>Attempt {call.attempts}</div>
                     <div className={styles['timestamp']}>{call.createdAt ? <span>{moment(call.createdAt).format("DD/MM/YYYY")} | {moment(call.createdAt).format('hh:mm A')}</span> || 0 
                     : 'NA'}</div>
                    </div>
                    <audio controls src={call.exotel ? call.exotel.RecordingUrl : ''}/>
                    <div className={styles['call_info']}>
                        <p>Call Outcome: {call.outcome ? call.outcome.title || 'NA' : 'NA'}</p>
                        {
                            data && data.marked ?  
                            (data.marked == MARKED_FOLLOW_UP || data.marked == MARKED_CALL_BACK) &&
                            scheduledtime && <p>Scheduled for: {scheduledtime}</p>
                            : null
                        }
                        {
                             data && data.marked ?  
                             (data.marked == MARKED_CALL_BACK) && 
                             call.responses && call.responses.length > 0 ?
                             <p>Call summary:&nbsp;&nbsp;{scbSummary(call.responses)}</p> : null
                          
                             || (data.marked == MARKED_FOLLOW_UP) && 
                             call.responses && call.responses.length > 0 ? 
                             <p>Note:&nbsp;&nbsp;{followupNote(call.responses)}</p> : null
                             : null
                        }
                    </div>
                    </div>
                    
                    {call.responses && call.responses.length > 0 ? 
                    <Panel title='Data collection'>
                    <DataCollection datacollection={call.responses}/>
                    </Panel> : null}

                </div>
            })
            : null
        }
        </div>

        </>
    )
}

export default React.memo(CallHistory)