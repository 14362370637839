import React, { useState } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import ProjectTitle from '../../../components/common/ProjectTitle'
import FutworkButton from '../../../uicomponents/button/Button'
import Popup from '../../../uicomponents/popup'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import { YellowStarIcon } from '../../../assets/images'

const defaultGoBack = () => {
    window.history.back()
}

const QmfHeader = (props) => {
    const { teleproject, caller, goBack = defaultGoBack } = props
    const [showOutcome, setShowOutcome] = useState(false)
    const [showScript, setShowScript] = useState(false)

    const handlePopups = () => {
        if (showOutcome) return renderOutcome()
        if (showScript) return renderScript()
        else
            return null
    }

    const renderScript = () => {
        const { scriptDocuments = [] } = props.teleprojectDetails || {}

        return (
            <Popup
                show={showScript}
                closePopup={() => {
                    setShowScript(false)
                }}
                size='sm'
                heading='Script documents'
            >
                {props.teleprojectDetailsLoading ? <ThreeDotLoader /> : <div className={styles.scripts}>
                    {
                        scriptDocuments && scriptDocuments.length > 0 ?
                            scriptDocuments.map(({ title, url }) =>
                                <p className={styles.row}>{title || 'NA'} <a href={url} target="_blank" rel="noopener noreferrer"> Script Link</a></p>
                            ) : <p>⚠️ No scripts are attached</p>
                    }
                </div>}
            </Popup>
        )
    }

    const renderOutcome = () => {
        const { outcomes = [] } = props.teleprojectDetails || {}
        return (
            <Popup
                show={showOutcome}
                closePopup={() => {
                    setShowOutcome(false)
                }}
                size='lg'
                heading='Outcomes'
            >
                <>
                    {
                        props.teleprojectDetailsLoading ? <ThreeDotLoader /> :
                            outcomes && outcomes.length > 0 ? outcomes.map(({ title, description, isWinning }) => {
                                return <div className={styles.outcomes}>
                                    <div className={styles.outcome_title}>{title} {isWinning && <img src={YellowStarIcon} />}</div>
                                    <div>{description}</div>
                                </div>
                            }) : <div className={styles.outcomes}>⚠️ No outcomes present</div>
                    }
                </>
            </Popup>
        )
    }

    return (
        <div>
            <header className={styles.qmf_form_header}>
                <ProjectTitle title={
                    <ul className={styles.breadcrumbs}>
                        <li>QMF</li>
                        <li>{teleproject || 'NA'}</li>
                        <li>{caller || 'NA'}</li>
                    </ul>
                }
                    goBack={() => goBack()} />
                <div className={styles.button_links}>
                    <FutworkButton
                        buttonSize='x-large'
                        onClick={() => setShowOutcome(true)}
                    >View Outcomes</FutworkButton>
                    <FutworkButton
                        buttonSize='x-large'
                        onClick={() => setShowScript(true)}
                    >View Script</FutworkButton>
                </div>
            </header>
            {handlePopups()}
        </div>
    )
}

export default React.memo(QmfHeader)