import React, { useEffect } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import useApi from '../../../hooks/useApi'
import {getCallerCurrentJobs} from './service'
import Capitalise from '../../../helper/Capitalise'

const CurrentJobs = ({rowData}) => {
    const getCallerCurrentJobsApi = useApi(getCallerCurrentJobs)

    useEffect(()=>{
        const {id} = rowData || {}
        const payload = {
            user: id,
        }
        getCallerCurrentJobsApi.request(payload)
    },[])

    const columns = [
        {
            Header: 'Projects name',
            accessor: (row) => row.teleproject.title,
            Cell: ({cell}) => {
                return <span>{Number(cell.row.id) + 1}.&nbsp;&nbsp;{cell.row.original.teleproject.title}</span>
            },
            width: '90%',
            disableSortBy: true
        },
        {
            Header: 'Stage',
            accessor: (row) => Capitalise(row.status),
            width: '10%',
            disableSortBy: true
        },
    ]

    const {data} = getCallerCurrentJobsApi || {}
    const {loading} = getCallerCurrentJobsApi

    return(
        <>
        {getCallerCurrentJobsApi.error && <p>{typeof getCallerCurrentJobsApi.error == 'object' && 
        getCallerCurrentJobsApi.error !== null &&
        !Array.isArray(getCallerCurrentJobsApi.error)
        ? getCallerCurrentJobsApi.error.error || getCallerCurrentJobsApi.error.msg : getCallerCurrentJobsApi.error}</p>}
        <p className={styles['total-count']}>Total jobs: {data && data.length}</p>
        <Table
        columns={columns}
        data={data && data.hasOwnProperty('msg') ? [] : data}
        totalCount={0}
        limit={2}
        currentPage={1}
        loading={loading}
        type = 'client'
        />
        </>
    )
}

export default CurrentJobs