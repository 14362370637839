import React, { useEffect, useState } from 'react'
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import checkIsEmpty from '../../helper/checkIsEmpty'
import FormInput from '../../uicomponents/form-input/forminput';
import FutworkButton from '../../uicomponents/button/Button';
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import Switch from "react-switch";
import Select from 'react-select'
import { QuestionMarkCircle } from '../../assets/images';
import CustomTooltip from '../utilities/ToolTip';

const WhatsappTemplateForm = ({ removeTemplate, idx, getFormData, item, saveTemplate, showSMSTestingModal, projectData, removeKeyValue, templatesCount, addTemplate, outcomeTemplate, addOutcomeToTemplate}) => {
    const [data, setData] = useState('')
    const [templateLinked, setTemplateLinked] = useState(false) 
    const [outcomeLinkEdited, setOutcomeLinkEdited] = useState(false)

    // set value for default selection
    const [selectedValue, setSelectedValue] = useState([]);

    const [options, setOptions] = useState([])

    const outcomesAlreadyUsed = outcomeTemplate && outcomeTemplate.map(out => out.outcome)

    const filterOutcomesLinked = outcomeTemplate && outcomeTemplate.filter(out => {
        if(out.templateId == item.id)
        return out.outcome
    })

    useEffect(() => {
        if(filterOutcomesLinked && filterOutcomesLinked.length > 0){
            setTemplateLinked(true)

        }
    }, [filterOutcomesLinked])


    useEffect(() => {
        setSelectedValue([...filterOutcomesLinked].map(each => each.outcome))
    }, [templateLinked])

   

    const outcomesLinkedArray = [...filterOutcomesLinked].map(item => {
        return item.outcome
    })

    useEffect(() => {
        let tempItem  = {...item}
        let variables = []
        let tempvariables = []
        Object.entries(tempItem).forEach(([key, value]) => {
                if(!['teleproject','active','id','tag', 'isEdited', 'title', 'isSaving', 'errorMsg', 'tag', 'wa_template_id', 'wa_from'].includes(key)) {
                    variables.push({
                        name: key, value: value
                    })
                    tempvariables = [...variables]
                }
        })

        setData({
            title: item.title,
            wa_from: item.wa_from,
            wa_template_id: item.wa_template_id,
            variables: tempvariables
        })


        setOptions(projectData && projectData.outcomes && projectData.outcomes.length > 0 ? 
        projectData.outcomes.map((outcome, idx) => {
        return {id: idx, value: outcome.title, label: outcome.title, disabled: outcomesAlreadyUsed && outcomesAlreadyUsed.some(out => out == outcome.title)}
        })
        :
        [])
    }, [])

   const addVariable = (idx) => {
    setData({
        title: item.title,
        wa_template_id: item.wa_template_id,
        wa_from: item.wa_from,
        active: item.active,
        variables: [...data.variables, {name: '', value: ''}]
    })
   }

    const [templateSaving, setTemplateSaving] = useState(false)

    const tempSaveClassName = () => {
        const { isEdited } = item
        const isFormValid = !isEdited ? !isEdited : checkIsEmpty(item)
        return isFormValid ? `transparent-btn disabled` : `transparent-btn`
    }

    // const showTestSMS = () => {
    //     const isTemplatePresent = item.id && !item.isEdited
    //     if (!isTemplatePresent)
    //         return `add-cta underline f-sz-14 disappear`
    //     else
    //         return `add-cta underline f-sz-14`
    // }
    const smsActiveclassName = (projectData, item) => {
        const linkedTemplates = projectData.whatsapp ? 
        Object.entries(projectData.whatsapp).map(([key, value]) => {
            return value
        }) : []
        if(checkIsEmpty(item.id) || checkIsEmpty(projectData.whatsapp) || !linkedTemplates.includes(item.id))
        return 'template-active-row disabled'
        else
        return 'template-active-row'
    }

    // handle onChange event of the dropdown
    const handleChange = (e) => {
        const tempList = Array.isArray(e) ? e.map(x => x.value) : []
        const newOptionAdded = tempList.filter(x => !selectedValue.includes(x)); // calculates diff
        let difference = selectedValue.filter(x => !tempList.includes(x)); // calculates diff
        const updateOptions = options && options.length > 0 ?
        options.map(opt => {
            if(difference.includes(opt.value)){
                opt.disabled = false
            }
            if(newOptionAdded.includes(opt.value)){
                opt.disabled = true
            }
            return opt
        }) : []

        setOptions(updateOptions)
        setOutcomeLinkEdited(true)
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    return (
        <>
        <div className='template_container' key={idx}>
            <div className='action_row'>
            <DropdownButton
            bsSize='small'
            title={<FontAwesomeIcon icon={faEllipsisVertical} />}
            id="action"
            >
            <MenuItem eventKey="1" 
            >Edit</MenuItem>
            <MenuItem eventKey="2" 
            disabled={item.id ? true : false}
            onSelect={() => !item.id && removeTemplate(idx)}
            >Delete</MenuItem>
            </DropdownButton>
            </div>
            
        <div className='template_form'>
                    <FormInput
                    label="Whatsapp Title"
                    helperIcon={true}
                    helperText={'Title is used to identify the template'}
                    key={'title'}
                    onChange={(e) => {
                        getFormData('title', e.target.value, idx, data, '', 'value')
                    }}
                    value={item.title}
                    />
                    <FormInput
                    label="Whatsapp Template ID"
                    key={'wa_template_id'}
                    helperText={'Template id is required to register the id mentioned in the exotel dashboard to create a template'}
                    helperIcon={true}
                    onChange={(e) => {
                        getFormData('wa_template_id', e.target.value, idx, data, '', 'value')
                    }}
                    value={item.wa_template_id}
                    />
                    <FormInput
                    label="Whatsapp Number"
                    key={'wa_from'}
                    helperText={'The number from which the template message will be sent to the clients'}
                    helperIcon={true}
                    onChange={(e) => {
                        getFormData('wa_from', e.target.value, idx, data, '', 'value')
                    }}
                    value={item.wa_from}
                    />
                 
                    {
                        data && data.variables.map((each, i) => {
                            return <div className='variables_row'>

                            <div className='input_fields'>
                                <FormInput
                                label="Key"
                                helperIcon={true}
                                helperText={'The key name to be added is mentioned in the exotel dashboard'}
                                onChange={(e) => getFormData(each.name, e.target.value, idx, data, i, 'name')}
                                value={each.name}
                                />

                                <FormInput
                                label="Value"
                                helperIcon={true}
                                helperText={'The value name to be added is mentioned in the exotel dashboard'}
                                onChange={(e) => getFormData(each.name, e.target.value, idx, data, i, 'value')}
                                value={each.value}
                                />
                            </div>
                  
                            <img src={require('../../assets/images/grey-trash-icon.svg')}
                            onClick={() => {
                                removeKeyValue(idx, data, i)
                            }}
                            />
                        </div>
                        })
                    }
                    <button onClick={()=>addVariable(idx)} className='addvar_cta'>+ Add Variable</button>
                    <CustomTooltip placement={'right'} component={<img src={QuestionMarkCircle}  className='helper_icon'/>}
                    description={'Variables are the extra information to be added based on the requirement. Do note that they are not mandatory'}/>
                    
                    <div className={smsActiveclassName(projectData, item)}>
                            <label>Whatsapp Active </label><Switch onChange={(e) => {
                             getFormData('active', !item.active, idx, data, '', 'value')
                            }} checked={item.active}
                            />
                    </div>
        
            <div className='form-footer'>
                        <div>
                           {item.id && templateLinked ?  filterOutcomesLinked && filterOutcomesLinked.length > 0 ? 
                           <div className='outcome_link_label'>
                           <label>Outcomes Linked</label>
                           <CustomTooltip placement={'right'} component={<img src={QuestionMarkCircle}  className='helper_icon'/>}
                           description={'List of outcomes that are linked to the template'}/>
                           </div> :
                           <div className='outcome_link_label'>
                           <label>Link to outcome</label>
                           <CustomTooltip component={<img src={QuestionMarkCircle}  className='helper_icon'/>} placement={'right'}
                           description={'Click on the dropdown to get the list of outcomes'}/>
                           </div>
                           : null}
                        </div>
                        
                        {
                           
                             item.id && templateLinked == true ? 
                                <Select options={options}
                                key={item.id}
                                 isOptionDisabled={(option) => option.disabled}
                                 value={options.filter(obj => selectedValue.includes(obj.value))} // set selected values
                                 isMulti
                                 name="outcome-linked"
                                 classNamePrefix="select"
                                 onChange={handleChange}
                                 /> : (item.id && templateLinked == false) ? <div className='save_template_wrapper'>
                                 <FutworkButton buttonStyle="primary--solid" buttonSize="sm"
                                 onClick={() => setTemplateLinked(true)}>
                                     Link Outcome
                                 </FutworkButton> </div> : null
                        }


                        {
                            item.isSaving ?
                            <div className={`transparent-btn ${tempSaveClassName()}`}>Saving...</div>
                            :
                        (item.isEdited || outcomeLinkEdited) && <div className='save_template_wrapper'>
                            <FutworkButton buttonStyle="primary--solid" buttonSize="sm"
                            // disabled={item.isEdited ? false : true}
                            onClick={() => {
                                if(outcomeLinkEdited){
                                    const getTheOutcomeOfCurrentTemplate = outcomeTemplate && outcomeTemplate.map(each => {
                                        if(each.templateId == item.id){
                                            if(selectedValue.includes(each.outcome)){
                                                return each
                                            }
                                        }
                                    }).filter(Boolean)

                                    const formatSelectedValue = selectedValue && selectedValue.map(each => {
                                        return {
                                            outcome: each,
                                            templateId: item.id
                                        }
                                    })

                                    const updateTheoutcomeOfCurrentTemplate = getTheOutcomeOfCurrentTemplate.length > 0 ?
                                    [...getTheOutcomeOfCurrentTemplate, ...formatSelectedValue] : formatSelectedValue

                                    const getTheoutcomeOfOtherTemplate = outcomeTemplate && outcomeTemplate.filter(each => {
                                        if(each.templateId !== item.id)
                                        return each
                                    })

                                    const finalDataSet = [
                                        ...updateTheoutcomeOfCurrentTemplate,
                                        ...getTheoutcomeOfOtherTemplate
                                    ]


                                    const jsonObject = finalDataSet.reduce((acc, obj) => {
                                        acc[obj.outcome] = obj.templateId;
                                        return acc;
                                      }, {});

                                     
                                      addOutcomeToTemplate(jsonObject)
                                      setOutcomeLinkEdited(false)
                                    return
                                }
                                setTemplateSaving(true)
                                saveTemplate(idx)
                            }}>SAVE</FutworkButton>
                        </div>
                        }
                        
            </div>
            {item.errorMsg ? <div className='error-section'>
                {item.errorMsg}
            </div> : null}
        </div>
        {templatesCount == idx ? <div className='footer_cta' onClick={addTemplate}>+Add Template</div> : null}
        </div>
        </>
    )
}

export default WhatsappTemplateForm