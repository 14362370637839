import React, { useEffect, useState, useContext } from "react";
//custom imports
import PoolPanel from "./poolPanel";
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import FutworkButton from "../../../uicomponents/button"
import Popup from "../../../uicomponents/popup";
import CreatePool from "./createPool";
import DeletePool from "./deletePool";
import { EmptyCollection } from "../../../assets/images";
import { ExoContext } from "./exoContext";


const PoolManagement = (props) => {
    const { activeTab: accountSelected, setActiveTab,
        createPool, setCreatePool,
        deletePool, setDeletePool,
        setExotelAcc, exotelAcc,
        query, setQuery,
        goToExophoneList
    } = useContext(ExoContext)

    const [poolList, setPoolList] = useState('')
    // const [query, setQuery] = useState('')

    useEffect(() => {
        const { value, data } = accountSelected || {}

        if(value){
            setPoolList(data && data.pool ? data.pool : [])
        }else{
            setPoolList([])
        }
    }, [accountSelected])

    const updateList = (data, brandName) => {
        const updatePool = exotelAcc.map(accnt => {
            if (accnt.data && accnt.data.id == data.id) {
                accnt.data.pool = data.pool
            }
            return accnt
        })
        setExotelAcc(updatePool)
        const { value, label } = accountSelected || {}
        setActiveTab({
            label,
            value,
            data: data
        })


        if(brandName && data && data.pool && data.pool.length > 0){
            const {pool} = data
            const lastAddedPool = pool[pool.length - 1]
            goToExophoneList({ accountId: id, ...lastAddedPool, brandName: brandName })
        }
    }

    const renderCreatePool = () => {
        const { data: { poolId, brandname } } = createPool
        return (
            <Popup
                show={createPool.status}
                closePopup={() => {
                    setCreatePool({ status: false, data: {} })
                }}
                size='sm'
                heading={poolId ? 'Edit Pool' : 'New Pool'}
            >
                <CreatePool updateList={updateList} brandname={brandname} />
            </Popup>
        )
    }

    const renderDeletePool = () => {
        const { data: { poolId } } = deletePool
        return (
            <Popup
                show={deletePool.status}
                closePopup={() => {
                    setDeletePool({ status: false, data: {} })
                }}
                size='sm'
                heading={''}
            >
                <DeletePool updateList={updateList} />
            </Popup>
        )
    }

    const handlePopups = () => {
        if (createPool.status) return renderCreatePool()
        if (deletePool.status) return renderDeletePool()
    }

    const { data: { id, sid: callingAccount } } = accountSelected || {}

    const getFilteredPool = () => {
        if(query && poolList && poolList.length > 0){
            // Create copy of item list
            let updatedList = [...poolList];
            // Include all elements which includes the search query
            updatedList = updatedList.filter((item) => {
                return (
                    item.poolName.toLowerCase().includes(query.toLowerCase().trim()) ||
                    item.numbers.some(
                      (number) =>
                        (number.primary.toLowerCase().includes(query.toLowerCase().trim())
                        ||
                        number.backup.toLowerCase().includes(query.toLowerCase().trim())
                        )
                      
                    )
                  );
            })
            return updatedList
        }else{
            return poolList
        }
    }

    return (
        <div className={styles.pool_panel_wrapper}>
            {
                poolList && poolList.length > 0 &&
                    <div className={styles.cta_action_left}>
                    <input type="text" onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter the number or pool name to search" value={query}/>
                        <FutworkButton
                            buttonSize='sm'
                            buttonStyle='primary--solid'
                            onClick={() => {
                                setCreatePool({
                                    ...createPool,
                                    status: true
                                })
                            }}
                        >Create new pool</FutworkButton>
                    </div>
            }
            {
                poolList && poolList.length > 0 ?
                    query && getFilteredPool().length <= 0 ?
                    <div className={styles.fallback_wrapper}>
                        <div className={styles.fallback_section}>
                            <img src={EmptyCollection} />
                            <p className={styles.text}>No pool / number found</p>
                        </div>
                    </div>
                    :
                    getFilteredPool().map(pool => {
                        return <PoolPanel pool={{ accountId: id, callingAccount, ...pool }} />
                    })
                    :
                    <div className={styles.fallback_wrapper}>
                        <div className={styles.fallback_section}>
                            <img src={EmptyCollection} />
                            <p className={styles.text}>No pool exists under this account</p>
                        </div>
                    </div>
            }

           {poolList && poolList.length <= 0 && 
            <div className={styles.cta_action_bottom}>
                <FutworkButton
                    buttonSize='sm'
                    buttonStyle='primary--solid'
                    onClick={() => {
                        setCreatePool({
                            ...createPool,
                            status: true
                        })
                    }}
                >Create new pool</FutworkButton>
            </div>}
            {handlePopups()}
        </div>
    )
}

export default PoolManagement