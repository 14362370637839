import React, { useEffect, useState, useContext } from 'react'

//custom imports
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import FutworkButton from "../../../uicomponents/button"
import useApi from '../../../hooks/useApi';
import { createUpdatePool } from './service';
import BrandSearch from '../../../components/pages/Tools/BrandSearch';
import ApiActions from '../../../actions/ApiActions';
import { ExoContext } from './exoContext';

const CreatePool = (props) => {
    const { activeTab: accountSelected,
        createPool: poolDetails, setCreatePool
    } = useContext(ExoContext)

    const { data: account, value } = accountSelected
    const { data: { poolId, poolType, truecallerIdentityLabelId } } = poolDetails
    const createUpdatePoolApi = useApi(createUpdatePool)

    const [inputValue, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [brandName, setBrandName] = useState(props.brandname)
    const [brandSelected, setBrandSelected] = useState('')

    const findPool = (key) => {
        const findPoolRes = account.pool.find(item => {
            if (item.poolId == poolId)
                return item
        })
        return findPoolRes[key]
    }

    const [inputFields, setInputFields] = useState({
        accountId: account && account.id,
        poolName: poolId ? findPool('poolName') : '',
        brand: poolId ? findPool('brand') : '',
        poolId: poolId ? poolId : undefined,
        poolType: poolType ? poolType : '',
        truecallerIdentityLabelId:  truecallerIdentityLabelId ? truecallerIdentityLabelId : undefined
    })

    const { data, loading, error } = createUpdatePoolApi || {}

    useEffect(() => {
        if (data) {
            setInputFields({
                accountId: account && account.id,
                poolName: '',
                brand: ''
            })
            setCreatePool({
                status: false,
                data: {}
            })
            props.updateList(data.data, brandSelected)
        }
    }, [!loading && data !== null])


    const loadOptions = async (inputValue) => {
        if (inputValue) {
            // return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
            var where = {
                $or: ["name"].map(field => { return { [field]: { "$regex": inputValue, "$options": "i" } } }),
            }
            var finalParams = { where: JSON.stringify(where), limit: 50, sort: { "createdAt": -1 } };

            try {
                let result = await ApiActions.getBrands(finalParams)
                return result && result.map(i => {
                    return {
                        label: i.name,
                        value: i.id
                    }
                })
            } catch (error) {
                console.error(error.response)
            }
        }



    }


    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
    }

    const addPoolName = (e) => {
        setInputFields({
            ...inputFields,
            poolName: e.target.value
        })
    }
    const addPoolType = (e) => {
        setInputFields({
            ...inputFields,
            poolType: e.target.value
        })
    }

    const addTrueCallerLAbelId = (e) => {
        setInputFields({
            ...inputFields,
            truecallerIdentityLabelId: e.target.value
        })
    }

    const onBrandSelect = (brand) => {
        setBrandSelected(brand.name)
        setBrandName('')
        setInputFields({
            ...inputFields,
            brand: brand.id
        })
    }

    const createPool = () => {
        createUpdatePoolApi.request(inputFields)
    }

    const checkIfValid = () => {
        const { poolName, brand } = inputFields
        if (poolName && brand) return false
        else return true
    }

    return (
        <div className={styles.create_pool}>

            <div className={styles.form_row}>
                <div className={styles.form_label}>Pool type</div>
                <select name="pool-label" id="label-select" className={styles.pool_label} value={inputFields.poolType}
                onChange={(e) => addPoolType(e)}>
                <option value="" hidden={inputFields.poolType}>Select pool type</option>
                <option value="Dialout">Dialout</option>
                <option value="Incoming">Incoming</option>
                </select>
                {/* <DropdownButton
                // bsSize='medium'
                title={''}
                id='dropdown-basic-1'
                >
                <MenuItem eventKey="1">Audit Report</MenuItem>
                <MenuItem eventKey="2">CMM Action Report</MenuItem>
                </DropdownButton> */}
            </div>

            <div className={styles.form_row}>
                <div className={styles.form_label}>Pool title</div>
                <input type='text' className={styles.pool_name} placeholder='Enter pool title here' onChange={addPoolName}
                    value={inputFields.poolName} />
            </div>

            <div className={styles.form_row}>
                <div className={styles.form_label}>Brand</div>
                <BrandSearch onBrandSelect={onBrandSelect}
                    placeholder={'Type to search the brand'}
                    searchValue={brandName}
                // ref={(c) => this.brandsearch = c}
                />
            </div>

            <div className={styles.form_row}>
                <div className={styles.form_label}>Truecaller Identity Label ID <i>(optional)</i></div>
                <input type='text' className={styles.pool_name} placeholder='Paste the Truecaller label id copied from Truecaller dashboard' onChange={addTrueCallerLAbelId}
                    value={inputFields.truecallerIdentityLabelId} />
            </div>

            <div className={styles.cta_footer}>
                <FutworkButton buttonSize='sm' buttonStyle='primary--outline' onClick={() => setCreatePool({ status: false, data: {} })}>Cancel</FutworkButton>
                <FutworkButton buttonSize='sm' buttonStyle='primary--solid' onClick={createPool}
                    disabled={checkIfValid()}>Save</FutworkButton>
            </div>
        </div>
    )
}

export default React.memo(CreatePool)