import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import Heading from "../../../uicomponents/heading/heading";
import Table from "../../../uicomponents/table/Table";
import useApi from "../../../hooks/useApi";
import { fetchRebuttalList } from './service'
import { RebuttalContext } from "./rebuttalContext";

const Rebuttal = (props) => {
    const { setCallDetails } = useContext(RebuttalContext)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const fetchRebuttalListApi = useApi(fetchRebuttalList)

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    useEffect(() => {
        const skip = (currentPage - 1) * limit
        //logic run on the first page render
        fetchRebuttalListApi.request(skip, limit, null, search)
    }, [currentPage, search])
    //table column schema
    const columns = [
        {
            Header: 'Project ID',
            accessor: 'projectId',
            // width: "1%",
            Cell: ({ value }) => {
                return <span>{value}</span>
            },
            width: "8%",
            disableSortBy: true
        },
        {
            Header: 'Project',
            id: 'title',
            accessor: 'title',
            Cell: ({ value }) => {
                return value || 'NA'
            },
            width: "30%",
            disableSortBy: true
        },
        {
            Header: 'Agent',
            accessor: 'callerName',
            Cell: ({ value }) => `${value || 'NA'}`,
            // width: "8%",
            disableSortBy: true
        },
        {
            Header: 'QA',
            accessor: 'qaAgent',
            Cell: ({ value }) => `${value || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: 'Parameters',
            accessor: 'feedbacks',
            Cell: (cell) => {
                return cell.row.original.feedbacks && cell.row.original.feedbacks.length > 0 ?
                    cell.row.original.feedbacks.length : 0
            }
        },
        {
            Header: 'Raised on',
            id: 'rebuttalRaisedOn',
            accessor: 'rebuttalRaisedOn',
            Cell: ({ value }) => {
                const [first] = value
                return <span>{value &&
                    (value !== '' || value !== 'NA')
                    ? moment(value).format("DD/MM/YYYY") : 'NA'}</span>
            },
            disableSortBy: false
        },
        {
            id: "expander",
            Header: '',
            accessor: "action",
            Cell: (cell) => {
                let rowcomponent = <div className={[styles['arrow']].join('')}
                    onClick={() => {
                        setCallDetails(cell.row.original)
                        props.history.push({
                            pathname: `${props.location.pathname}/qmf-form`,
                            state: {
                                callDetails: cell.row.original
                            }
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
                return rowcomponent
            },
            width: "3%",
            disableSortBy: true
        }
    ]

    const formattedList = (list = []) => {
        return list.length > 0 ?
            list.map(item => {
                let updatedFeedbacks = item.feedbacks.filter((obj) => obj.hasOwnProperty('rebuttalStatus') && obj.rebuttalStatus == 'rebutted')
                item.feedbacks = updatedFeedbacks
                return item
            })
            :
            list
    }
    
    const { data, loading, error } = fetchRebuttalListApi || {}
    const { data: list, total } = data || {}

    return (
        <div className={styles.page_wrapper}>
            {/* mention the heading */}
            <Heading text={'Rebuttals'} />
            {/* list the rebuttals received */}
            <Table
                columns={columns}
                data={formattedList(list)}
                totalCount={total}
                limit={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                // sortChange={sortChange}
                filterChange={filterChange}
                searchBy='project id / title / agent name / QA name'
                isGlobalFilter={true}
                manualSortBy={false}
            />
        </div>
    )
}

export default Rebuttal