import React, { Component } from 'react'
import jwt_decode from "jwt-decode";
import axios from "axios";
import FrappButton from '../utilities/FrappButton'
import ApiActions from '../../actions/ApiActions'

class SupportChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chat: [],
      message: "",
      showMore: false,
      next: null,
      idChat: "",
      missionId: "",
      teleproject: "",
      userId: "",
      supportCaseId: "",
      supportCase: {},
      clickHereBanner: false,
      checkIfMessage: false,
      submitSuccess: false,
      showTextbox: true,
      category: ""
    };
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  componentDidMount() {
    this.scrollToBottom();

    let authToken = localStorage.getItem("authToken");
    let supportCaseIdFromUrl = this.props.supportCaseId
    const userId = jwt_decode(authToken).base
    let admin = this.props.admin
    let category = this.props.category
    let projectId = this.props.teleproject || this.props.mission

    if (authToken && admin && supportCaseIdFromUrl) {

      if ((category === "mission" || category === "telecall") && projectId) {
        ApiActions.getSupportCaseMission(projectId).then(resp => {
          this.setState({
            checkIfMessage: true,
            supportCaseId: supportCaseIdFromUrl
          });
          let id = resp && resp.data && resp.data[0] && resp.data[0].id;
          let resolveCheck = resp && resp.data && resp.data[0] && resp.data[0].resolved ? resp.data[0].resolved : ''
          this.setState({ supportCase: resp && resp.data && resp.data[0] ? resp.data[0] : '' });

          ApiActions.getSupportChats(supportCaseIdFromUrl).then(response => {
            let chats = response && response.data ? response.data : [];
            this.setState({ chat: chats });
          })
        })
      }

      else if (projectId) {
        ApiActions.getSupportCaseMission(projectId).then(resp => {
          this.setState({
            checkIfMessage: true,
            supportCaseId: supportCaseIdFromUrl
          });
          let resolveCheck = resp && resp.data && resp.data[0] && resp.data[0].resolved ? resp.data[0].resolved : ''
          this.setState({ supportCase: resp && resp.data && resp.data[0] ? resp.data[0] : '' });

          ApiActions.getSupportChats(supportCaseIdFromUrl).then(response => {
            let chats = response && response.data ? response.data : [];
            // this.setState({ chat: chats.length > 0 ? [...chats, ...chats] : [] });
            this.setState({ chat: chats });
          })
        })
      }

      else if (!projectId && category) {
        ApiActions.getSupportCaseForChat(category, userId).then(resp => {
          this.setState({
            checkIfMessage: true,
            supportCaseId: supportCaseIdFromUrl
          });
          let resolveCheck = resp && resp.data && resp.data[0] && resp.data[0].resolved ? resp.data[0].resolved : ''
          this.setState({ supportCase: resp && resp.data && resp.data[0] ? resp.data[0] : '' });

          ApiActions.getSupportChats(supportCaseIdFromUrl).then(response => {
            let chats = response && response.data ? response.data : [];
            this.setState({ chat: chats });
          })
        })
      }
    }

  }

  renderWindow() {
    let chats = this.state.chat;
    let checkIfMessage = this.state.checkIfMessage;

    if (checkIfMessage)
      return (
        <>
          <div className="chat-wrapper" id="scroll">
            {chats
              .slice(0)
              .reverse()
              .map((chat, i) => (
                <div key={i}> {this.renderDetails(chat)}</div>
              ))}
            {this.renderBanner(chats)}
          </div>

          <div
            ref={el => {
              this.messagesEnd = el;
            }}
          >
            {" "}
            {this.renderInputBox(chats)}
          </div>
        </>
      );
    else if (this.state.category && !checkIfMessage)
      return (
        <div className="first-screen">
          {this.state.showTextbox ? (
            <div className="textbox">
              <textarea
                className="text-in"
                rows="5"
                name="chat"
                value={this.state.message}
                onChange={this.setData}
                placeholder="Type us your query in full detail"
              />
            </div>
          ) : null}

          {this.state.showTextbox ? <div className="grey-banner">
            Our team will do its best to respond to you as soon as
            possible.&nbsp;Please ensure you have read the terms and
            instructions of the Mission properly
            <div
              style={{
                fontSize: "12px",
                color: "#807979",
                paddingTop: "5%",
                fontWeight: "900"
              }}
            >
              {" "}
              <img src={require("../../assets/images/Support-chat/support-clock.svg")} />
              The Futwork support team usually replies within 2-3 working days
            </div>
          </div> : null}

          <div>
            {" "}
            {this.state.submitSuccess ? (
              this.renderBanner(chats)
            ) : (
              <FrappButton
                className="submit-button"
                ref={c => (this.submitBtn = c)}
                disabled={this.checkMsg()}
                handler={this.handleSubmitFirst}
              >
                Submit
              </FrappButton>
            )}
          </div>
        </div>
      );
    else return null;
  }


  renderBanner = chats => {
    if (chats && chats[0] && !chats[0].admin)
      return (
        <div className="support-banner">
          <div className="banner-wrap">
            <div> Your Query has been submitted.</div>
            <div> Our team will reach out to you within 2-3 working days</div>
          </div>
        </div>
      );
    return null;
  };
  scrollToBottom = () => {
    if (this.state.checkIfMessage)
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  renderDetails = chat => {
    let userId = this.state.userId;

    if (chat.admin != null)
      return (
        <div>
          <div
            className={
              chat.admin.id === userId ? "detail-right" : " detail-left"
            }
          >
            <div
              className={
                chat.admin.id === userId ? "detail-in-right" : "details"
              }
            >
              {" "}

              {" "}
              <img id="logo-img" src={require("../../assets/images/Support-chat/supportlogo2.png")} />
              {" "}
              <h3>Team Futwork</h3>{" "}
              <img
                style={{ height: "30%", marginLeft: "-2px" }}
                src={require("../../assets/images/Support-chat/correct.svg")}
              />
            </div>{" "}
            {this.renderChatBox(chat)}
          </div>
        </div>
      );

    return (
      <div>
        <div
          className={chat.user.id === userId ? "detail-right" : " detail-left"}
        >
          <div
            className={chat.user.id === userId ? "detail-in-right" : "details"}
          >
            {" "}
            <img
              src={
                chat.user.picture
                  ? chat.user.picture
                  : require("../../assets/images/Support-chat/user.svg")
              }
            />{" "}
            <h3>{chat.user.firstname}</h3>
          </div>{" "}
          {this.renderChatBox(chat)}
        </div>
      </div>
    );
  };

  renderChatBox = chat => {
    return (
      <div className="wrap-chat">
        {" "}
        <div className="chatbox">
          <div className="msg">
            {chat.message.split("\n").map(function (item, idx) {
              return (
                <span key={idx} style={{ wordBreak: "normal" }}>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>
          {this.renderTime(chat)}
        </div>
      </div>
    );
  };

  renderTime = chats => {
    function timeAgo(input) {
      const date = (input instanceof Date) ? input : new Date(input);
      const formatter = new Intl.RelativeTimeFormat('en');
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    }

    let d = new Date(chats.createdAt);
    var n = d.getTime();

    return (
      <div>
        <div className="time">
          {timeAgo(chats.createdAt)}
          {/* <TimeAgo date={n} live={false} /> */}
        </div>
      </div>
    );
  };

  setData = event => {
    let message = this.state.message;
    message = event.target.value;
    this.setState({ message });
  };

  checkMsg = () => {
    let message = this.state.message;
    message = message.trim();
    if (!message) return true;
    //flag logic here->disable chat
  };

  handleSubmit = event => {
    let supportCaseId = this.state.supportCaseId;
    event.preventDefault();

    var { message } = this.state;

    this.submitBtn.startLoading();
    //  var messageSend="&message="+message
    let sendMessage = {
      supportcase: supportCaseId,
      message: message
    };

    ApiActions.putSupportChats(sendMessage).then(resp => {
      ApiActions.setResolveForChat(supportCaseId, false)
    }).then(resp1 => {
      ApiActions.getSupportChats(supportCaseId).then(resp2 => {
        let chatRefresh = resp2.data;
        this.setState({ chat: chatRefresh });
        // window.scrollBy(0, 2000);
      })
      this.setState({ message: "", submitSuccess: true, showTextbox: false });
      //window.location.reload();
      if (this.submitBtn) this.submitBtn.stopLoading();
    })
  };

  handleSubmitFirst = async (event) => {
    event.preventDefault();
    let supportCaseId = this.state.supportCaseId;
    var { message } = this.state;
    this.submitBtn.startLoading();
    const { category } = this.state
    let result = await ApiActions.postSupportCase(category)
    if (result) {
      let sendMessage = {
        supportcase: result.data.id,
        message: message
      };
      await ApiActions.putSupportChats(sendMessage)
        .then(resp => ApiActions.setResolveForChat(supportCaseId, false))
        .then(resp1 => {
          ApiActions.getSupportChats(supportCaseId).then(resp2 => {
            let chatRefresh = resp2.data;
            this.setState({ chat: chatRefresh });
            // window.scrollBy(0, 2000);
          });

          this.setState({ message: "", submitSuccess: true, showTextbox: false });
          window.location.reload();
          if (this.submitBtn) this.submitBtn.stopLoading();
        });
    }

  };

  renderInputBox = chats => {
    let clickHereBanner = this.state.clickHereBanner;
    if (!clickHereBanner)
      return (
        <div>
          <div className="input-box">
            <form>
              <div className="chat-enter">
                <textarea
                  rows="5"
                  name="chat"
                  value={this.state.message}
                  onChange={this.setData}
                  placeholder="Type something..."
                />
                <div style={{ paddingLeft: "11px" }}>
                  <div className="enter">
                    <FrappButton
                      type="submit"
                      ref={c => (this.submitBtn = c)}
                      disabled={this.checkMsg()}
                      handler={this.handleSubmit}
                    >
                      <img
                        src={require("../../assets/images/Support-chat/arrow-send.svg")}
                      />
                      {/* <div className="img-enter"> &#11166;</div> */}
                    </FrappButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    else
      return (
        <div className="resolved-banner">
          <p>
            Query resolved.
            <div
              onClick={() => {
                this.setState({ clickHereBanner: false });
              }}
              className="click-here"
            >
              &nbsp;Click Here &nbsp;
            </div>{" "}
            to reopen
          </p>
        </div>
      );
  };

  childMethod() {
    let supportCaseId = this.state.supportCaseId;
    ApiActions.getSupportChats(supportCaseId).then(resp2 => {
      let chatRefresh = resp2.data;
      this.setState({ chat: chatRefresh });
      // window.scrollBy(0, 2000);
    });
  }

  render() {
    return <div className='chat-window'>
      <div className='messages'>
        {this.renderWindow()}
      </div>
    </div>
  }
}

export default SupportChat;
