const generatePayload = (skip = 0,limit = 10,sort = [],search = '', dynamicSearch = false) => {
    const sortObj = sort ? sort.length > 1 ? sort : sort[0] : null
    let orderBy = sortObj ? sortObj.length > 1 ? 
    sortObj.map(item => item.desc ? 'desc' : 'asc') : sortObj.desc ? ['desc'] : ['asc'] : undefined 
    let sortBy = sortObj ? sortObj.length > 1 ? 
    sortObj.map(item => item.id) : [sortObj.id] : undefined 

    return {
        skip: skip >= 0 ? skip : undefined,
        limit: limit >= 0 ? limit : undefined,
        orderBy: orderBy,
        sortBy: sortBy,
        [dynamicSearch ? 'dynamicSearchQuery' : 'searchQuery']: search ? search : undefined,
    }
}

export default generatePayload