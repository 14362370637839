import React from 'react';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

import SearchInput from '../../utilities/SearchInput';

import ApiActions from '../../../actions/ApiActions';


class BrandSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <FormGroup className="mission-search" >
                <ControlLabel></ControlLabel>
                <div className="inputs-wrapper" >
                    {this.renderSearchBrandInput()}
                </div>
            </FormGroup>
        )
    }

    renderSearchBrandInput = () => {

        var data = {
            getData: ApiActions.getBrands,
            searchField: ["name"],
            callback: (searchValue, obj) => { this.props.onBrandSelect(obj) },
            render: (obj) => <React.Fragment>{obj.name} &mdash; {obj.description} &mdash; {window.UTIL.formatDate(obj.createdAt)}</React.Fragment>
        }

        return <SearchInput placeholder={this.props.placeholder || "Update Brand ..."} ref={(c) => this.searchInput = c} data={data} searchValue={this.props.searchValue} />
    }

    getId = () => {
        let id = this.searchInput.state.returnValue.id;
        return id;
    }
}

export default BrandSearch;