import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import WildCardTool from './WildCardTool';
import DraftsTracking from './DraftsTracking';
import AccountCreation from './AccountCreation'
import ExotelAccountSetup from './ExotelAccountSetup'
import CallDialer from '../../../pages/tools/callDialer'
import QuizFinal from './QuizFinal';
import DashboardGateway from './DashboardGateway';
import "react-toggle/style.css";
import Toggle from 'react-toggle'
import checkRole from '../../../helper/checkRole'

class Tools extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            autoSelectFormData: {},
            activeTool: "quiz",
            maxApplied: "",
            festivalFormData: {
                title: '',
                description: '',
                amount: '',
                darkMode: false
            }
        };
    }

    renderDownloadCSV = () => {
        let csvDownloadProps = { href: `data:text/csv;charset=utf-8,${escape(this.state.exportCsvData)}`, download: `Mission Count Report.csv`, id: "download-csv" }
        return <a style={{ display: 'none' }} {...csvDownloadProps}>none</a>
    }

    render() {
        return (
            <div className="tools" id={this.state.darkMode ? "darkTrueTools" : "darkFalseTools"}>
                <div className="container"  >
                    <div className="darkmode">
                        <Toggle
                            id='dark-mode'
                            defaultChecked={this.state.darkMode}
                            onChange={this.changeDarkMode} />
                        <label htmlFor='cheese-status'>Dark Mode</label>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} >
                        <FormGroup className="tool-input" >
                            <ControlLabel>Choose a tool you'd like to use.</ControlLabel>
                            <FormControl
                                onChange={(e) => this.setState({ activeTool: e.target.value })}
                                name="activeTool" value={this.state.activeTool}
                                componentClass="select" >
                                <option disabled value="">Select a tool</option>
                                {checkRole() == 'admin' ?
                                <option value="accountCreation">Account creation</option> : null}
                                {/* <option value="draftsTracking">Draft tracking</option> */}
                                <option value="dashboardGateway" >Dashboard Gateway</option>
                                <option value="exotelAccountSetup">Exotel Account Setup</option>
                                <option value="quiz">Quiz</option>
                                <option value="wildcardTool" >Wildcard Tool</option>
                                <option value="call-dialer">Call Dialer</option>
                            </FormControl>
                        </FormGroup>
                        {this.renderActiveTool()}
                    </form>
                </div>
            </div>
        )
    }
    changeDarkMode = () => {
        let darkMode = this.state.darkMode;
        this.setState({ darkMode: !darkMode })
    }

    renderActiveTool = () => {
        var { activeTool } = this.state;

        var toolsMapping = {
            "accountCreation": <AccountCreation />,
            "wildcardTool": <WildCardTool />,
            "dashboardGateway": <DashboardGateway />,
            "draftsTracking": <DraftsTracking />,
            "quiz": <QuizFinal />,
            "exotelAccountSetup": <ExotelAccountSetup/>,
            "call-dialer": <CallDialer/>
        }

        return toolsMapping[activeTool]
    }
}

export default Tools;