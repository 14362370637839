import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'
import Table from '../../../uicomponents/table/Table'
import FutworkButton from "../../../uicomponents/button/Button";
import { getListOfAgents, getCountOfListOfAgents, inviteAgentsToSession } from './service'
import useApi from '../../../hooks/useApi';
import { TrainingSessionContext, TrainingSessioProvider } from './trainingSessionContext'
import handleErrorMessage from '../../../helper/handleErrorMessage';
import { ToastContainer, toast } from 'react-toastify';
import ThreeDotLoader from "../../../components/utilities/ThreeDotLoader"
import Message from './message';
import TrainingMeeting from './trainingMeeting';

const InviteAgents = ({ updateList }) => {
    const getListOfAgentsApi = useApi(getListOfAgents)
    const getCountOfListOfAgentsApi = useApi(getCountOfListOfAgents)
    const inviteAgentsToSessionApi = useApi(inviteAgentsToSession)

    const { projectData, selectedRow } = useContext(TrainingSessionContext)

    const [inviteList, setInviteList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [apiMessage, setApiMessage] = useState({
        status: '',
        msg: ''
    })

    const [selectedAgents, setSelectedAgents] = useState([])

    useEffect(() => {
        const { id } = projectData
        const skip = (currentPage - 1) * limit
        // getCountOfListOfAgentsApi.request(id)
        getListOfAgentsApi.request(id)
        if (apiMessage.msg) {
            setApiMessage({ status: '', msg: '' })
        }
    }, [currentPage, search])


    const checkTrainingId = (meetings = []) => {
        return meetings && meetings.length > 0 ? meetings.includes(selectedRow.id) : false
    }

    const groupMeetingsIds = (trainingMeetings) => {
        let meetingIds = ''
        if (trainingMeetings && trainingMeetings.length > 0) {
            meetingIds = trainingMeetings.map(item => item.id)
        }
        return meetingIds ? meetingIds : []
    }

    const checkIfValid = () => {
        return selectedAgents && selectedAgents.length > 0 ? false : true
    }

    const columns = [
        {
            Header: 'Agent name',
            accessor: 'fullname',
            width: '25%',
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: 'mobile',
            width: '20%',
            disableSortBy: true
        },
        {
            Header: 'Stage',
            accessor: 'status',
            Cell: ({ cell }) => {
                const { value } = cell || {}
                return value == 'pending' || value == 'registered' || value == 'applied' ? 'Applied' : 'NA'
            },
            width: '5%',
            disableSortBy: true
        },
        {
            Header: 'Trainings',
            accessor: 'trainingMeetings',
            Cell: ({ cell }) => {
                const { value } = cell || []
                return <TrainingMeeting meetings={value} />
            },
            width: '40%',
            disableSortBy: true
        }
    ]

    const sendInvite = () => {
        const { id: teleprojectId } = projectData
        const { id: trainingMeetingId } = selectedRow

        const teleapplicationIds = selectedAgents && selectedAgents.length > 0
            ? selectedAgents.map(agent => agent.teleapplicationId) : []

        const payload = {
            teleprojectId,
            trainingMeetingId,
            teleapplicationIds
        }
        inviteAgentsToSessionApi.request(payload)
    }

    const { loading, error } = getListOfAgentsApi || {}
    let { data } = getListOfAgentsApi || {}

    const { loading: countLoading, data: count, error: countError } = getCountOfListOfAgentsApi || {}

    const { loading: inviteLoading, data: inviteData, error: inviteError } = inviteAgentsToSessionApi || {}

    useEffect(() => {
        if (loading == false && data !== null) {
            const checking = data && data.map(item => {
                const { user, trainingMeetings, id: teleapplicationId } = item
                item = {
                    id: user && user.id,
                    fullname: `${user && user.firstname} ${user && user.lastname}`,
                    mobile: user.mobile,
                    teleapplicationId,
                    trainingMeetings: trainingMeetings || undefined,
                    status: item.status,
                    isDisabled: checkTrainingId(groupMeetingsIds(trainingMeetings))
                }
                return item
            })
            setInviteList(checking)
        }
    }, [loading, data])

    useEffect(() => {
        if (inviteLoading == false && inviteData !== null) {
            const { data: result, status } = inviteData
            // toast(result && result.msg)
            if (status == 200) {
                setApiMessage({
                    status: 'pass',
                    msg: result && result.msg
                })
                let temp = [...inviteList]
                temp.map(each => {
                    if (selectedAgents.some(agent => agent.id == each.id)) {
                        each.isDisabled = true
                    }
                    return each
                })
                setInviteList(temp)
                updateList()
            }
        }

        if (inviteError) {
            // toast(handleErrorMessage(inviteError))
            setApiMessage({
                status: 'fail',
                msg: handleErrorMessage(inviteError)
            })
        }
    }, [inviteLoading, inviteData, inviteError])


    const showMessage = () => {
        const { status, msg } = apiMessage || {}

        const checkStatus = () => {
            switch (status) {
                case 'pass':
                    return 'success'
                case 'fail':
                    return 'error'
                default:
                    return ''
            }
        }

        const variant = checkStatus()
        return msg && status && <Message variant={variant} children={msg} />
    }

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
        window.history.replaceState({}, document.title)
    }

    return <div className={styles.invite_agents_wrapper}>
        {!inviteLoading && <>
            <Table
                data={inviteList}
                columns={columns}
                totalCount={inviteList.length || 0}
                loading={loading}
                type='client'
                selectRow={true}
                selectType='multi'
                updateRowSelect={(rows) => setSelectedAgents(rows)}
                currentPage={currentPage}
                // setCurrentPage={setCurrentPage}
                limit={limit}
                // filterChange = {filterChange}
                height='50'
                isGlobalFilter={true}
                searchBy={'by name / mobile'}
            // search={search}
            />
            <div className={styles.cta_center}>
                <FutworkButton buttonSize='sm' buttonStyle='primary--solid'
                    onClick={() => sendInvite()}
                    disabled={checkIfValid()}
                >Invite Agents / Submit</FutworkButton>
            </div>
        </>}
        {inviteLoading && <div className={styles.loading_session_wrapper}>
            <ThreeDotLoader />
            <div className={styles.session_message_wrapper}>Invite is getting generated for {selectedAgents && selectedAgents.length} agents, please wait...</div>
        </div>}
        {showMessage()}
    </div>
}

export default InviteAgents