import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath } from "react-router-dom";
import ProjectsInHiring from '../pages/hiring/main'
import JobApplicationsHiring from '../components/pages/Hiring/JobApplicationsHiring'
import JobApplicationsActive from '../components/pages/Hiring/JobApplicationsActive'
import JobApplicationsApplied from '../components/pages/Hiring/JobApplicationsApplied'
import JobApplicationsTraining from '../components/pages/Hiring/JobApplicationsTraining'
import HiringDashboard from '../components/pages/Hiring/HiringDashboard'
import FollowUpCallsList from '../pages/hiring/followupCallsList'
import jwt_decode from "jwt-decode";
import TrainingSession from '../pages/hiring/project-training-session';

const HiringRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    const checkRoleForHiring = (props) => {
		if(localStorage && localStorage.authToken){
			const role =jwt_decode(localStorage.authToken).auth
			if(role == 'project_manager'){
				return <Redirect to="/home" />
			}else{
				return <HiringDashboard {...props} />
			}
		}else return <Redirect to="/" />
	}

    return <Switch>
        <Route exact path={`${path}`}
        render={() => <ProjectsInHiring {...customProps} />}
        />

        <Route exact path={`${path}/dashboard`}
            render={() => <HiringDashboard {...props}/>
                // checkRoleForHiring(customProps)
            } 
        />

        <Route exact path={`${path}/:id`}
            render={(props) => <JobApplicationsHiring {...props} />} 
        />

        <Route exact path={`${path}/selected-callers/:id`}
            render={(props) => <JobApplicationsActive {...props} />} 
        />

        <Route exact path={`${path}/applied-callers/:id`} 
            render={(props) => 
                <JobApplicationsApplied {...props} />} 
        />

        <Route exact path={`${path}/for-training-callers/:id`} 
            render={(props) => <JobApplicationsTraining {...props} /> } 
        />

        <Route exact path={`${path}/follow-up-calls/:id`} 
            render={(props) => <FollowUpCallsList {...props} /> } 
        />

        <Route exact path={`${path}/training-session/:id`} 
            render={(props) => <TrainingSession {...props} /> } 
        />

        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default HiringRouter