import React from 'react';
import ApiActions from '../../../actions/ApiActions';
import dayjs from 'dayjs';
import FrappButton from '../../utilities/FrappButton';
import FrappModal from "../../utilities/FrappModal";
import ProjectTitle from '../../common/ProjectTitle';

class DashboardGateway extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandmanagers: [],
            search: '',
            searchBrands: false,
            showModal: false,
            notifSent: false,
            response: ""

        };

    }



    componentDidMount() {
        this.fetchBrandManagers()

    }

    fetchBrandManagers = () => {

        return ApiActions.getBrandManagerGateway({ projection: { "email": 1, "firstname": 1, "lastname": 1, "adminAssist": 1, "brand": 1, "createdAt": 1 }, sort: { "updatedAt": -1 }, populate: "brand", where: { "brand": { "$exists": true } } }).then((resp) => {

            this.setState({ brandmanagers: resp })
        })
    }



    render() {

        return (
            <div className='tools'>
                <div className='container'>
                <ProjectTitle title={`Dashboard Gateway`} goBack={() => this.props.history.push("/tools")} />
                    <div className="dashboard-gateway">
                    {this.renderList()}
                    {this.renderModal()}
                    {this.renderChooseDashboard()}

                    </div>
                </div>
            </div>
        )
    }


    renderList() {
        let { brandmanagers, selectedOption, searchBrands } = this.state;
        if (!brandmanagers) return;
        let filteredManagers;

        var arrayBrands;
        return (
            <div  >
                <input placeholder="Search" type="text" value={this.state.search} onChange={this.updateSearch} />
                {/* <FrappButton ref={(c) => this.filterBtn = c} handler={() => this.changeFilter(searchBrands)} > {searchBrands ? "Search Brands Managers Instead" : "Search Brands Instead"}</FrappButton> */}
                {/* <label className="checkbox-gateway"> Search by Brand<input className="gateway-checkbox" type="checkbox" name="searchBrand" checked={searchBrands} onChange={() => this.changeFilter(searchBrands)} /> <span class="checkmark"></span> </label> */}
                <div style={{ marginBottom: "2%", marginTop: "2%", display: "flex", justifyContent: "space-evenly" }}><FrappButton ref={(c) => this.submitBrand = c} handler={() => this.handleSubmitBrand()} > Search by Brand </FrappButton>
                    <FrappButton ref={(c) => this.submitBrandmanager = c} handler={() => this.handleSubmit()} > Search by Brand manager</FrappButton>
                </div>
                {brandmanagers.length > 0 ? <div className="wrap-card">
                    {this.state.searchBrands ? filteredManagers = brandmanagers.filter(bm => {
                        return bm.brand && bm.brand.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                    }).map(this.renderCard) : filteredManagers = brandmanagers.filter(bm => {
                        return bm.firstname.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                    }).map(this.renderCard)}
                </div> : <div style={{ display: "flex", justifyContent: "center" }}><p> Check if the brand is under moderation.Brands should be approved for the Dashboard Gateway</p></div>}



            </div>
        )

    }
    changeFilter = (searchBrands) => {

        this.setState({ searchBrands: !searchBrands })
    }
    handleSubmit = () => {
        let search = this.state.search;
        let query = [{ "$match": { "firstname": { "$regex": search, "$options": "i" } } }, { "$project": { "email": 1, "firstname": 1, "lastname": 1, "adminAssist": 1, "brand": 1, "updatedAt": 1 } },
        { "$sort": { "updatedAt": -1 } },
        { "$lookup": { "from": "brand", "localField": "brand", "foreignField": "_id", "as": "brand" } },
        { "$unwind": { "path": "$brand" } }]

        ApiActions.getBrandByName(query).then(resp => {
            this.setState({ brandmanagers: resp, search: "" })
        })

    }
    handleSubmitBrand = () => {
        let search = this.state.search;
        let query = [{ "$lookup": { "from": "brand", "localField": "brand", "foreignField": "_id", "as": "brand" } }, { "$unwind": { "path": "$brand" } }, { "$match": { "brand.name": { "$regex": search, "$options": "i" } } }]
        ApiActions.getBrandByName(query).then(resp => {

            this.setState({ brandmanagers: resp, search: "" })
        })
    }
    updateSearch = (event) => {
        this.setState({ search: event.target.value.substr(0, 20) })
    }
    renderModal = () => {
        return (
            <div>
                <FrappModal

                    className="gateway-modal"
                    // show={this.state.showModal}
                    ref={c => (this.modal = c)}
                // onHide={false}
                //  closeModal={() => this.setState({ showModal: false })}
                >
                    {this.state.notifSent ? <div className="notif">Notification sent to the Brand Manager</div> : <div className="notif"> Notifying Brand Managers of Dashboard Co-management by Frapp.&nbsp;Click Ok to proceed.</div>}
                    {this.state.notifSent ? null : <div className="okbtn"> <FrappButton ref={(c) => this.okbtn = c} handler={this.sendNotif} > OK</FrappButton></div>}


                </FrappModal>
            </div>)
    }
    sendNotif = () => {

        let bmClicked = this.state.bmClicked;
        let sendBmData = {
            "email": bmClicked.email,
            "enable": true
        }


        ApiActions.notifyBrand(sendBmData, this.chooseDashboard(bmClicked)).then(resp => {
            this.setState({ notifSent: true, response: resp })
        })

    }
    chooseDashboard = (brandmanager) => {
        this.whichDashboard.toggle(true)

    }
    renderChooseDashboard = () => {
        let bmClicked = this.state.bmClicked;
        return (
            <div>
                <FrappModal

                    className="gateway-modal"
                    // show={this.state.showModal}
                    ref={c => (this.whichDashboard = c)}
                // onHide={false}
                //  closeModal={() => this.setState({ showModal: false })}
                >
                    <div className="notif" id="choose"> <div style={{ marginBottom: "2%" }}>Choose Dashboard</div>
                        <div className="wrapper">
                            <div className="okbtn" id="choose-btn"> <FrappButton ref={(c) => this.okbtnNew = c}
                                handler={() => this.navigateDashboard(true, bmClicked)} >
                                New Dashboard</FrappButton></div>
                            <img src={require("../../../assets/images/choose.gif")} />
                            <div className="okbtn" id="choose-btn"> <FrappButton ref={(c) => this.okbtnOld = c}
                                handler={() => this.navigateDashboard(false, bmClicked)} >
                                Old Dashboard</FrappButton></div>
                        </div>

                    </div>

                </FrappModal>
            </div>
        )
    }
    navigateDashboard = (isIttrue, bmClicked) => {
        this.setState({ newDashboard: isIttrue }, () => this.enterDashboard(bmClicked))

    }

    handleClick = (brandmanager) => {
        { brandmanager.adminAssist ? this.whichDashboard.toggle(true) : this.modal.toggle(true) }
        //this.modal.toggle(true)

        this.setState({ bmClicked: brandmanager, notifSent: false })
    }
    renderCard = (brandmanager) => {
        return (
            <div onClick={() => this.handleClick(brandmanager)} key={brandmanager.id} className="card">
                <div className="name">
                    {brandmanager.firstname} &nbsp;{brandmanager.lastname}

                    {brandmanager && brandmanager.brand ? <img src={brandmanager.brand.asseturl} /> : null}
                </div>
                {brandmanager && brandmanager.brand && brandmanager.brand.moderationRequested ? <div className="moderation">Brand under Moderation</div> : null}
                <div className="bm-details" >
                    <span>
                        {brandmanager && brandmanager.brand && brandmanager.brand.name}
                    </span>
                    <span>
                        {brandmanager.email}
                    </span>

                </div>
            </div>
        )
    }

    enterDashboard = (brandmanager) => {
        let newDashboard = this.state.newDashboard;
        let url, oldurl;
        if (process.env.NODE_ENV === 'development') {
            url = 'https://staging.business.futwork.com'
            oldurl = 'https://staging.olddashboard.frapp.in'
        }

        if (process.env.NODE_ENV === 'production') {
            url = 'https://business.futwork.com'
            oldurl = 'https://olddashboard.frapp.in'
        }

        Promise.all([
            ApiActions.getData("/brandmanager/generateToken", { email: brandmanager.email, _getFullResponse: true }),
            brandmanager.id ? ApiActions.getData(`/brandmanager/${brandmanager.id}`) : (brandmanager._id ? ApiActions.getData(`/brandmanager/${brandmanager._id}`) : null)

        ])
            .then(resp => {
                let authToken = resp[0].headers['x-authorization-token'];
                let metaData = resp[1];
                if(newDashboard) {
                    let newWnd = window.open(encodeURI(`${url}?authToken=${authToken}&metaData=${JSON.stringify(metaData)}&controlAccess=true`)) 
                    newWnd.opener = null
                }else{
                    let newWnd = window.open(encodeURI(`${oldurl}?authToken=${authToken}&metaData=${JSON.stringify(metaData)}&controlAccess=true`)) 
                    newWnd.opener = null
                }
            })
        this.whichDashboard.toggle(false)

    }
}

export default DashboardGateway;