import React, {useState, useEffect} from 'react'
import jwt_decode from "jwt-decode";

const AuthGuard = ({component}) => {
    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkToken();
    }, [component])


    const checkToken = () => {
        if ((!localStorage.authToken) ||  checkTokenExpiry()) {
            return window.location.href = '/'
        }
        setStatus(true);
        return;
    }

    const checkTokenExpiry = () => {
		if (localStorage.authToken && jwt_decode(localStorage.authToken).exp < Date.now() / 1000) {
			localStorage.clear();
			return true;
		}
		else
			return false
	}

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard