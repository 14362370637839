import React from 'react';


import Table from 'react-bootstrap/lib/Table';

// import fromPairs from 'lodash/fromPairs';
// import toPairs from 'lodash/toPairs';
// import sortBy from 'lodash/sortBy';


class FrappTable extends React.Component{
    render(){
        var {columns,data} = this.props;
        
        return (
            <Table condensed hover responsive >
                <thead>
                    <tr>
                        <th>#</th>
                        {columns.map(this.renderHeaderColumn)}
                    </tr>
                </thead>
                <tbody>
                    {data.map(this.renderRow)}
                </tbody>
            </Table>
        )
    }

    renderHeaderColumn = (item) => {
        let itemToRender = typeof item === "object" ? item.value : item;
        return <th key={item} >{itemToRender}</th>
        
    }

    renderRow = (item,i) => {
        var values = Object.values(item);
        
        return (
            <tr key={item.id+i} >
                <td>{i+1}</td>
                {values.map(this.renderCol)}
            </tr>
        )
    }

    renderCol = (value) => {
        if(!value)return <td key={Math.random()} >N.A.</td>
        if (typeof value === "function"){
            return <td key={Math.random()} >{value()}</td>
        }
        if (typeof value === "object"){
            return <td value={value.id || Math.random()} >{JSON.stringify(value)}</td>
        }
        if ( value === true || value === false){
            return <td key={value+Math.random()} >{value.toString()}</td>
        }
        
        if ( value === 0 ){
            return <td key={Math.random()} >0</td>
        }
        return value ? (
            <td key={value+Math.random()} >{value.toString()}</td>
        ) : <td key={Math.random()} >N.A.</td>
    }

    
}

export default FrappTable;