import React, { useState } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Heading from '../../../uicomponents/heading'
import Tab from '../../../uicomponents/tab'
import withTabGroup from '../../../hocs/withTabGroup'
import CertifiedTable from './certifiedTable'
import IdleTable from './idleTable'
import MatchTable from './matchTable'
import WorkingTable from './workingTable'
import Popup from '../../../uicomponents/popup'
import BanCaller from './banCaller'

const TABS = [
    'Certifed Callers',
    'Idle Callers',
    'Caller Roster',
]

const SUB_TABS = [
    'Matched - Training',
    'Matched - Working'
]

const Roster = (props) => {
    const tabNames = TABS
    const subTabNames = SUB_TABS
    const TabGroup = withTabGroup(Tab, 'group');
    const SubTabGroup = withTabGroup(Tab, 'group');

    const [activeTab, setActiveTab] = useState(TABS[0])
    const [activeSubTab, setActiveSubTab] = useState(SUB_TABS[0])
    const [showBanCaller, setShowBanCaller] = useState(false)

    const switchTab = (data) => {
        setActiveTab(data)
    }

    const switchSubTab = (data) => {
        setActiveSubTab(data)
    }

    const showSubTab = () => {
        return activeTab === TABS[2] ?
        <div className={styles.switch_row}> 
        <SubTabGroup tabs={subTabNames} color={'purple-tab-bottom-border'} 
         onClick={switchSubTab} activeTab={activeSubTab}/>
        {showBanCallerLink()}
         </div>
        : null
    }

    const goToCertifyCallersPage = () => {
        props.history.push({
            pathname: `/tring-moderation`,
        })
    }

    const goToMatchViaMobilePage = () => {
        props.history.push({
            pathname: `/match-via-mobile`
        })
    }

    const showRightSideLink = () => {
        return activeTab == TABS[0] ? 
        <div className={styles.link_wrapper}>
            <a className={styles.blue_link} onClick={() => goToMatchViaMobilePage()}>Match Agents</a> 
            <a className={styles.blue_link} onClick={() => goToCertifyCallersPage()}>Certify Callers</a> 
        </div>
        : null
    }

    const showBanCallerLink = () => {
        return(
        <div className={styles.link_wrapper}>
            <a className={styles.red_link} onClick={() => setShowBanCaller(true)}>Ban Caller</a>
        </div> 
        )
    }

    const renderBanCaller = () => {
        return(
            <Popup
            show={showBanCaller}
            closePopup={()=>{
                setShowBanCaller(false)
            }}
            size = 'xs'
            heading = 'Ban Caller'
            popupContent = {<BanCaller/>}
            >
            </Popup>
            )
    }

    const renderTable = () => {
        switch (activeTab) {
            case TABS[0]:
                return <CertifiedTable/>
            case TABS[1]:
                return <IdleTable/>
            case TABS[2]:
                switch (activeSubTab) {
                    case SUB_TABS[0]:
                        return <MatchTable/>
                    case SUB_TABS[1]:
                        return <WorkingTable/>       
                    default:
                        return <h2>Please refresh the page</h2>
                }
            default:
                return <h2>Please refresh the page</h2>
        }
    }

    return <div className={styles.page_wrapper}>
        <Heading text={'Caller Rostering and Placement'} />

        <div className={styles.switch_row}>
            <TabGroup tabs={tabNames} color={'green-tab-outline'} onClick={switchTab} activeTab={activeTab} />
            {showRightSideLink()}
        </div>

        {showSubTab()}

        <div className={styles.table_section}>
            {renderTable()}
        </div>
        {showBanCaller ? renderBanCaller() : null}
    </div>
}

export default React.memo(Roster)