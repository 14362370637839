import React, { Component, useState } from 'react'

import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

const CustomTooltip = ({placement, component, description}) => {

    const tooltip = (info) => {
        return (<Tooltip id="tooltip">
            <p style={{ textAlign: 'left', padding: '0', margin: '0' }}>{info.description}</p>
        </Tooltip>)
    };


    return <OverlayTrigger placement={placement} overlay={tooltip({description})}>
        {component}
    </OverlayTrigger>
}

export default CustomTooltip