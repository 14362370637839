import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import MaterialTable, { MTablePagination, MTableToolbar, MTableAction } from "material-table";
import FrappButton from '../utilities/FrappButton';
import SearchBarMaterialTable from '../utilities/SearchBarMaterialTable'
import { CsvBuilder } from 'filefy';

export default class QualityAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teleprojectsdata: [],
            teleprojectsdata2: [],
            skip: 0,
            limit: 10,
            teleproject: {},
            isLoading: true,

            query: {
                page: 1,
                pageSize: 10,
            },
            totalCount: 0,
            pageChanged: true,
            searchMode: false,
            isGeneratingCSV: false,
            isTableLoading: false,
            searchText: ''
        }
    }

    getTotalCountOfRecordsForMaterialTable = () => {
        ApiActions.getTeleProjectsForAuditCount().then(resp => {
            this.totalPages = resp
            this.setState({ totalCount: resp })
        }).catch(err => console.error(err.response))
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.fetchData();
            this.getTotalCountOfRecordsForMaterialTable();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchData = () => {
        this.fetchTeleProjects();
    }

    getPaginatedData = (additionalState) => {
        this.setState({isTableLoading: true})
        const { query, totalCount, searchText } = this.state
        let skip = (query.page - 1) * 10
        this.setState({ skip: skip })
        ApiActions.getTeleProjectsForAudit({
            skip: skip,
            limit: query.pageSize,
            title: searchText ? searchText : null,
            sort: {
                "createdAt": -1
            }
        }).then(resp => {
            this.setState({isTableLoading: false})
            this.setState({ ...additionalState, teleprojectsdata2: resp, totalCount: totalCount > 0 ? totalCount : this.totalPages});
        }).catch(err => {
            this.setState({isTableLoading: false})
            toast("Error");
            console.error(err)
        })
    }

    fetchTeleProjects = () => {
        this.setState({isTableLoading: true})
        const { query, totalCount } = this.state
        const setState = this.setState.bind(this);
        ApiActions.getTeleProjectsForAudit({
            skip: 0,
            limit: 10,
            sort: {
                "createdAt": -1
            }
        }).then(resp => {
            this.setState({isTableLoading: false})
            this.setState({ teleprojectsdata: resp, teleprojectsdata2: resp, isLoading: false });
        }).then(setState).catch(err => {
            console.error(err)
            this.setState({isTableLoading: false})
            toast("Error");
        });
    }

    render() {
        return (
            <div className="additional-wrapper">
                {this.renderProjectsAuditTableServerSide()}

                <ToastContainer draggable={true} />
            </div>
        )
    }

    searchBtnTriggered = (value) => {
        this.setState({searchMode: true, isTableLoading: true})
        let {skip, query} = this.state
        this.setState({searchText: value})
        ApiActions.getTeleProjectsForAuditCount(value).then(resp => {
            this.totalPages = resp
            this.setState({ totalCount: resp })
        }).catch(err => console.error(err.response))

        ApiActions.getTeleProjectsForAudit({
            skip: 0,
            limit: query.pageSize,
            title: value,
            sort: {
                "createdAt": -1
            }
        }).then(resp => {
            this.setState({teleprojectsdata2: [...resp]})
            this.setState({ totalCount: this.totalPages })
            this.setState({isTableLoading: false})
        }).catch(err => {
            toast("Error");
            this.setState({isTableLoading: false})
            console.error(err)
        })

    }


    refreshTable = () => {
        this.setState({isTableLoading: true})
        this.setState({
            searchMode: false, 
            searchText: '',
            skip: 0,
            limit: 10, 
            query: {
                page: 1,
                pageSize: 10,
            }
        })
        this.getTotalCountOfRecordsForMaterialTable()
        this.fetchTeleProjects()
    }
    
    renderProjectsAuditTableServerSide = () => {
        const { totalCount, query, data, pageChanged, teleprojectsdata2 } = this.state
        // handling server side generation of csv
        const handleExportCsv = (allColumns, allData) => {
            this.setState({isGeneratingCSV: true})
            let csvColumns = allColumns.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false; 
            })
            let listOfAllProjectAudits;
            //get all audits from server
             ApiActions.getTeleProjectsForAudit({
                sort: {
                "createdAt": -1
                }
             }).then(resp =>{
                 listOfAllProjectAudits  = resp
                 const data = listOfAllProjectAudits.map(rowData =>
                    csvColumns.map(columnDef => {
                        return rowData[columnDef.field]
                 })
                 )  
                 const builder = new CsvBuilder(('Quality-Audit') + '.csv')
                .setDelimeter(',')
                .setColumns(csvColumns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile()
                this.setState({isGeneratingCSV: false})
            }).catch(err => {
                console.error(err)
                this.setState({isGeneratingCSV: false})
                toast('Something went wrong while generating CSV')
            })
        }
        return (
            <div style={{paddingBottom: '2%'}} id='project-table'>
            <MaterialTable
                title="Projects"
                columns={[
                    {
                        title: "Id",
                        field: "id",
                        // filtering: true,
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        },
                        headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            boxShadow: 'none'

                        },
                    },
                    {
                        title: "Title",
                        field: "title",
                        // filtering: true,
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black',
                            width: 500,
                            // whiteSpace: 'nowrap'
                        },
                        headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            boxShadow: 'none'

                        },
                        // filterCellStyle: {
                        //     padding: '1% 0% 1% 1%'
                        // },
                    },

                    {
                        title: "Calls Completed",
                        render: rowData =>
                            <div >
                                <p>{rowData.callsMade} / {rowData.callsDatabase} </p>
                                {/* <img src={require("../../assets/images/green-tick.svg")}/> */}
                            </div>,
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        },
                        filtering: false
                    },
                    {
                        title: "Calls connected",
                        field: "callsConcluded",
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        },
                        filtering: false
                    },
                    {
                        title: "Call Audits",
                        field: "callsInQC",
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        },
                        filtering: false
                    },

                    {
                        title: "Callers for QC [Autohold]",
                        render: (rowData) => {
                            if (rowData.autoHoldCallers > 0) {
                                return `${rowData.flaggedCallers} [${rowData.autoHoldCallers}]`;
                            } else {
                                return `${rowData.flaggedCallers}`;
                            }
                        },
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        },
                        filtering: false,
                        customSort: (a, b) => a.autoHoldCallers - b.autoHoldCallers
                    },
                    {
                        title: "Callers for IQC",
                        field: "iqcholdCallers",
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        },
                        filtering: false
                    },
                    {
                        title: "",
                        render: rowData =>
                            <div >
                                <FrappButton className="audit-view-btn"
                                    handler={() => this.getProjectDetails(rowData)}>
                                    View
                                </FrappButton>
                                {/* <img src={require("../../assets/images/green-tick.svg")}/> */}
                            </div>,
                        cellStyle: {
                            fontSize: '12px',
                            color: 'black',
                            width: '0.5%',
                            maxWidth: '0.5%'
                        }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            width: '0.5%',
                            maxWidth: '0.5%'
                        },
                        filtering: false
                    },
                ]}
                data={teleprojectsdata2}
                components={{
                    Pagination: (props) => (
                        <div style={{ fontSize: "30px" }}>
                            <MTablePagination
                                {...props}
                                count={totalCount}
                                page={query.page - 1} rowsPerPage={query.pageSize}
                            />
                        </div>
                    ),
                    Toolbar: props => (
                        <div className='custom-toolbar w-120'>
                        <div className='first'>
                        <div style={{padding: '2%'}}>{props.title}</div>
                        </div>
                        <div className='second'>
                        <SearchBarMaterialTable
                        searchBtnTriggered = {this.searchBtnTriggered}
                        placeholder = 'Search by title'
                        searchMode={this.state.searchMode}
                        refreshTable={this.refreshTable}
                        showFilter = {this.state.searchText}
                        isGeneratingCSV={this.state.isGeneratingCSV}
                        {...props}
                        />
                        <div className='toolbar'>
                        <MTableToolbar {...props}/>
                        </div>
                        </div>
                        </div>
                    )
                }}
                onChangePage={(page) => {
                    if (pageChanged) {
                        this.setState(
                            { query: { ...query, page: page + 1 }, pageChanged: false }, () => this.getPaginatedData({ pageChanged: true })
                        );
                    }
                }}
                // onChangeRowsPerPage={(pageSize) => {
                //     this.setState({ query: { ...query, pageSize } }, this.getPaginatedData);
                // }}
                isLoading={this.state.isTableLoading}      
                options={{
                    filtering: false,
                    showTitle: false,
                    search: false,
                    // grouping: true,
                    loadingType: 'overlay',
                    exportButton: { csv: true, pdf: false },
                    // debounceInterval: 1500,
                    doubleHorizontalScroll: true,
                    exportAllData: true,
                    exportCsv: handleExportCsv,
                    columnsButton: true,
                    pageSize: 10,
                    // padding: 'dense',
                    emptyRowsWhenPaging: false,
                    //  pageSizeOptions:[25,50,75,100],
                    rowStyle: {
                        fontSize: "16px"
                    },
                    searchFieldStyle: {
                        boxShadow: 'none',
                        webkitboxShadow: 'none',
                    }
                }}
            />
            </div>
        )
    }

    getProjectDetails = (activeProject) => {
        console.log('pr', this.props.location.pathname)
        this.props.history.push({
            pathname: `${this.props.location.pathname}/audit-project/${activeProject.id}`,
            state: {
                teleprojectAudit: activeProject
            }
        })
    }
}
