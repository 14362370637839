import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath } from "react-router-dom";
import Tools from '../pages/tools'
import AccountCreation from '../components/pages/Tools/AccountCreation'
import WildCardTool from '../components/pages/Tools/WildCardTool'
import QuizFinal from '../components/pages/Tools/QuizFinal'
import DashboardGateway from '../components/pages/Tools/DashboardGateway'
import ExotelAccountSetup from '../components/pages/Tools/ExotelAccountSetup'
import CallDialer from '../pages/tools/callDialer'
import jwt_decode from "jwt-decode";
import { intersection } from 'lodash';

const ToolRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }
    let allowedRoutes = []

    const isArrayWithLength = (arr) => {
        return (Array.isArray(arr) && arr.length)
    }
    const getAllowedRoutes = (routes) => {
        const roles = localStorage.authToken ? [jwt_decode(localStorage.authToken).auth] : [];
        return routes.filter(({ permission, children }) => {
            if(!permission) return true;
            else if(!isArrayWithLength(permission)) return true;
            else return intersection(permission, roles).length;
        });
    }

    allowedRoutes = props.children &&  getAllowedRoutes(props.children)


    return <Switch>
        <Route exact path={`${path}`}
        render={() => <Tools {...customProps} />}
        />

        {
            allowedRoutes && allowedRoutes.length > 0 && allowedRoutes.map(({path, component: Component}) => {
                return <Route exact path={path}
                render={() => <Component {...customProps} />}
                />
            })
        }

        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/tools" />} />
    </Switch>
}

export default ToolRouter