import React from 'react';


import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';

import fromNow from 'moment-from-now';

import FrappButton from '../utilities/FrappButton';
import FrappModal from '../utilities/FrappModal';
import constants from '../../util/constants';

import JsonView from '../utilities/JsonView'
import ApiActions from '../../actions/ApiActions';

import { diff } from 'json-diff';

import dayjs from 'dayjs';
import { FormControl } from 'react-bootstrap';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import "react-toggle/style.css";
import Toggle from 'react-toggle'

class Moderation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeModeration: null,
            moderationFeedback: 'Please update all details correctly.',
            editProof: false,
            darkMode: false
        };
    }

    fetchActionModerationLiveData = (id) => {
        let { activeModerationEntity } = this.state;
        if (activeModerationEntity === 'internshipbuffer') {
            this.fetchInternship(id)
        }
    }
    fetchInternship = (id) => {
        ApiActions.getData(`/internship/${id}`, { populate: 'brand' })
            .then(internship => internship && !internship.id ? null : internship)
            .then(internship => this.setState({ internship }))
            .catch(err => {

            })
    }

    componentWillMount() {
        this.setState({ ...this.props })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ ...nextProps })
    }

    clickHeading = (e) => {
        var target = e.target;

        var a = target.querySelector('a');
        if (a) {
            a.click();
        }
        e.stopPropagation();
    }

    render() {
        var { brandAssociation, missionBuffer, brandBuffer, internshipBuffer } = this.state;

        return (
            <div className="moderation" id={this.state.darkMode ? "darkTrue" : "darkFalse"}>
                <div className="darkmode">
                    <Toggle
                        id='dark-mode'
                        defaultChecked={this.state.darkMode}
                        onChange={this.changeDarkMode} />
                    <label htmlFor='cheese-status'>Dark Mode</label>
                </div>
                <div className="container-fluid" >
                    {this.renderTip()}
                    <PanelGroup id="moderation-list" >
                        {brandAssociation && this.renderPanel({ label: 'Brand Associations', list: brandAssociation })}
                        {brandBuffer && this.renderPanel({ label: 'Brands', list: brandBuffer })}
                        {internshipBuffer && this.renderPanel({ label: 'Internships', list: internshipBuffer })}
                        {missionBuffer && this.renderPanel({ label: 'Missions', list: missionBuffer })}
                    </PanelGroup>
                    {this.renderModerationModal()}
                </div>
            </div>
        )
    }

    renderTip = () => {
        return (
            <div className="tips-wrapper" >
                <h4>Tips:- </h4>
                <p>1. While approving internships make sure the respective brand is approved first.</p>
                <p>2. While rejecting brands make sure their respective internships are rejected first.</p>
            </div>
        )
    }
    changeDarkMode = () => {
        let darkMode = this.state.darkMode;
        this.setState({ darkMode: !darkMode })
    }

    renderPanel = (data) => {
        var formattedList = this.formatList(data);
        var { label, list } = data;
        var isExpanded = this.state[`${label}Expanded`];

        return (
            <Panel expanded={isExpanded} onToggle={() => this.setState({ [`${label}Expanded`]: !isExpanded })} eventKey={label} >
                <Panel.Heading onClick={this.clickHeading} >
                    <Panel.Title toggle>{label} ({list.length})</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible >
                    {
                        formattedList.length === 0 ? (
                            <div className="empty-review" >
                                <span>Nothing to review !</span>
                                <span role="img" aria-label="party" >🎉 </span>
                            </div>
                        ) :
                            <div className="list-wrapper" >
                                {formattedList.map(this.renderDataCard)}
                            </div>
                    }
                </Panel.Body>
            </Panel>
        )
    }

    renderDataCard = (data, i) => {
        var { title, subTitle, logo, dependency, entityData, entity } = data;

        return (
            <div key={entityData.id} onClick={() => { this.setActiveModeration(entityData, entity) }} className={`data-card ${dependency ? 'locked' : ''}`} >
                <div className="logo-wrapper" >
                    <img src={logo} alt="" />
                </div>
                <div className="text-wrapper">
                    <div className="title ellipsis" >
                        {title}
                    </div>
                    <div className="sub-title ellipsis" >
                        {subTitle}
                    </div>
                    {data.isDummy && (<div className="dummy-tag" >Dummy Mission</div>)}
                    {this.renderStat(data)}
                </div>
                {
                    dependency ?
                        <div className="overlay" >
                            <span role="img" aria-label="woman no gesture" ><img src={require("../../assets/images/noentry.jpg")} />‍</span>
                        </div> : null
                }
            </div>

        )
    }

    renderStat = (data) => {
        var { timeElapsed } = data;


        return (
            <div className="stat time-elapsed" >
                <span role="img" aria-label="time elapsed" >⏰</span>
                <span>{timeElapsed}</span>
            </div>
        )
    }

    renderModerationModal = () => {
        var { activeModeration, activeModerationEntity, approveHandler, rejectHandler, showRejectFeedback } = this.state;

        return (
            <FrappModal ref={(c) => this.moderationModal = c} className="moderation-modal" >
                {this.renderEditedTag()}
                {activeModerationEntity && <h1>{window.UTIL.capitalize(activeModerationEntity.replace('buffer', ''))} Detail</h1>}
                {activeModeration && activeModeration.payWall === true ? (activeModeration.paymentDone === true ?
                    <div className="paid-mission"><h3>Payment has been received</h3> <img src={require("../../assets/images/payment-done.gif")} />
                        <div className="banner">This Mission is self-serve.</div></div>
                    : <div className="paid-mission" ><h3 id="paid-not">Payment has not been received</h3> <img src={require("../../assets/images/payment-notdone.gif")} />
                        <div className="banner">This Mission is self-serve.</div></div>)
                    :
                    (activeModeration && activeModeration.payWall === false) ? <div className="paid-mission" ><h3 id="paid-not">Payment has not been received</h3> <img src={require("../../assets/images/payment-notdone.gif")} />
                        <div className="banner">Payment for this mission needs to be collected.</div></div> : null}
                {

                    constants.fieldsMetaInfo[activeModerationEntity] &&
                    <div className="detail-wrapper" >
                        <JsonView highlighted={this.getEditDiff()} data={activeModeration} fieldsMetaInfo={constants.fieldsMetaInfo[activeModerationEntity]} />
                    </div>
                }
                <div className={`cta-wrapper ${showRejectFeedback ? 'reject-shown' : ''}`} >
                    {showRejectFeedback && activeModeration && this.renderRejectFeedback(activeModeration.id)}
                    <div className="ctas">
                        <FrappButton handler={() => approveHandler().then(this.moderateSuccess)} >Approve</FrappButton>
                        {
                            activeModerationEntity === 'missionbuffer' &&
                            <FrappButton ref={(c) => this.editBtn = c} handler={() => this.editMissionBuffer(activeModeration)} >Edit</FrappButton>
                        }
                        <FrappButton handler={() => activeModerationEntity === 'brandassociate' ? rejectHandler().then(this.moderateSuccess) : rejectHandler()} >Reject</FrappButton>
                    </div>
                </div>
            </FrappModal>
        )
    }

    renderRejectFeedback = (id) => {
        let { activeModerationEntity, activeModeration } = this.state;

        let live, notLive;
        switch (activeModerationEntity) {
            case 'internshipbuffer':
                live = () => this.rejectInternship(id, "live").then(this.moderateSuccess)
                notLive = () => this.rejectInternship(id, "notlive").then(this.moderateSuccess)
                break;
            case 'missionbuffer':
                live = () => this.rejectMission(id, "live").then(this.moderateSuccess)
                notLive = () => this.rejectMission(id, "notlive").then(this.moderateSuccess)
                break;
            case 'brandbuffer':
                live = () => this.rejectBrand(id, "live").then(this.moderateSuccess)
                notLive = () => this.rejectBrand(id, "notlive").then(this.moderateSuccess)
                break;
            default:
            //
        }

        return (
            <div className="reject-feedback" >
                <FormControl placeholder={"What is wrong with this posting?"} value={this.state.moderationFeedback} onChange={(e) => this.setState({ moderationFeedback: e.target.value })} />
                {activeModeration.liveState && <FrappButton handler={live} >Reject Changes</FrappButton>}
                <Checkbox className="checkbox" onChange={() => this.handleCheckBox()} style={{ color: "red" }}
                ><h5>Edit Brand Proof</h5></Checkbox>
                <FrappButton handler={notLive} >Edits Needed</FrappButton>
            </div>
        )

    }
    handleCheckBox = () => {
        const editProof = this.state.editProof;

        let temp = editProof;
        temp = !editProof
        this.setState({ editProof: temp })

    }

    renderEditedTag = () => {
        var { internship } = this.state;

        if (!internship) {
            return null
        }

        return (
            <div className="edited" >
                EDITED
            </div>
        )
    }

    getEditDiff = () => {
        var { activeModeration, internship } = this.state;
        var diffToCheck = diff(activeModeration, internship);
        let diffKeys = Object.keys(diffToCheck);
        diffKeys = diffKeys.filter(key => key.indexOf("added") === (-1) && key.indexOf("deleted") === (-1) && key.indexOf("updatedAt") === (-1))

        return diffKeys;
    }

    moderateSuccess = () => {
        var { activeModeration, activeModerationEntity } = this.state;

        let entityToBeFiltered, key;

        switch (activeModerationEntity) {
            case 'internshipbuffer':
                entityToBeFiltered = this.state.internshipBuffer;
                key = 'internshipBuffer';
                break;
            case 'missionbuffer':
                entityToBeFiltered = this.state.missionBuffer;
                key = 'missionBuffer';
                break;
            case 'brandbuffer':
                entityToBeFiltered = this.state.brandBuffer;
                key = 'brandBuffer';
                break;
            case 'brandassociate':
                entityToBeFiltered = this.state.brandAssociation;
                key = 'brandAssociation';
                break;
            default:
            //
        }

        entityToBeFiltered = entityToBeFiltered.filter(i => i.id !== activeModeration.id);

        this.moderationModal.toggle(false)
        this.setState({ [key]: entityToBeFiltered, activeModeration: null, activeModerationEntity: null })

    }

    setActiveModeration = (data, entity) => {
        this.moderationModal.toggle(true);

        var approveHandler, rejectHandler;
        var { id } = data;
        switch (entity) {
            case 'internshipbuffer':
                approveHandler = () => ApiActions.approveInternship(id)
                rejectHandler = () => this.setState({ showRejectFeedback: true })
                break;
            case 'missionbuffer':
                approveHandler = () => this.approveMission(data);
                rejectHandler = () => this.setState({ showRejectFeedback: true })
                break;
            case 'brandbuffer':
                approveHandler = () => ApiActions.approveBrand(id)
                rejectHandler = () => this.setState({ showRejectFeedback: true })
                break;
            case 'brandassociate':
                approveHandler = () => ApiActions.moderateBrandAssociate({ decision: 'accept', id })
                rejectHandler = () => ApiActions.moderateBrandAssociate({ decision: 'reject', id })
                break;

            default:
            //
        }


        this.setState({ internship: null, activeModeration: data, activeModerationEntity: entity, approveHandler, rejectHandler }, () => this.fetchActionModerationLiveData(id))
    }

    checkMissionDummy = (item) => {
        let cond1 = item && item.description && item.description.indexOf("create a buzz") >= 0;
        let cond2 = item.name.indexOf('Promoters') >= 0;
        let cond3 = Number(item.maxSelected) === 25;
        let cond4 = Number(item.maxApplied) === 30;

        return cond1 && cond2 && cond3 && cond4
    }

    formatList = (data) => {
        var { label, list } = data;

        var formattedList = [];

        switch (label) {
            case 'Missions':
                formattedList = list.map(item => {
                    return {
                        title: item.name,
                        subTitle: item.brand.name,
                        logo: item.brand.asseturl,
                        entity: 'missionbuffer',
                        isDummy: this.checkMissionDummy(item)
                    }
                })
                break;

            case 'Brands':
                formattedList = list.map(item => {
                    var { internshipBuffer } = this.state;
                    if (!internshipBuffer) {
                        return {}
                    }

                    let dependency = internshipBuffer.filter(i => i.brand.id === item.id).length > 0;

                    return {
                        title: item.name,
                        subTitle: item.brandmanager && item.brandmanager.firstname,
                        logo: item.asseturl,
                        dependency,
                        entity: 'brandbuffer',
                    }

                })
                break;

            case 'Internships':
                formattedList = list.map(item => {

                    return {
                        title: item.title,
                        subTitle: item.brand.name,
                        logo: item.brand.asseturl,
                        entity: 'internshipbuffer',
                    }
                })
                break;

            case 'Brand Associations':
                formattedList = list.map(item => {
                    var { brandbuffer, brandmanager } = item;
                    return {
                        title: brandbuffer.name,
                        subTitle: brandmanager.firstname + " " + brandmanager.lastname,
                        logo: brandbuffer.asseturl,
                        entity: 'brandassociate',
                    }
                })
                break;

            default:
                formattedList = [];
        }

        list.map((item, i) => {
            formattedList[i].id = item.id;
            formattedList[i].createdAt = item.createdAt;
            formattedList[i].updatedAt = item.updatedAt;
            formattedList[i].updatedAt = item.updatedAt;
            formattedList[i].entityData = item;

            if (item.brand) {
                formattedList[i].dependency = item.brand.moderation;
            }

            var timeToPass = item.createdAt !== item.updatedAt ? item.updatedAt : item.createdAt;
            formattedList[i].timeElapsed = fromNow(timeToPass);

            return null
        })

        return formattedList;
    }

    approveMission = (data) => {
        if (this.checkMissionDummy(data)) {
            return new Promise((resolve, reject) => {
                ApiActions.getData(`/mission`, { id: data.id })
                    .then(missions => {
                        let mission = missions[0];

                        if (mission) {
                            return ApiActions.approveMission(data.id)
                                .then(resp => {
                                    resolve()
                                })
                        } else {
                            let { id } = data;
                            let currentMission = this.state.missionBuffer.filter(s => s.id === id)[0];
                            let missionData = {}
                            missionData.campaignStartDate = dayjs();
                            missionData.campaignStartDate = missionData.campaignStartDate.set('h', 0).set('m', 0).set('s', 0).set('ms', 0).add(1, 'day').toISOString();

                            missionData.campaignEndDate = dayjs();
                            missionData.campaignEndDate = missionData.campaignEndDate.set('h', 0).set('m', 0).set('s', 0).set('ms', 0).add(6, 'day').toISOString();


                            missionData.applicationEndDate = dayjs();
                            missionData.applicationEndDate = missionData.applicationEndDate.set('h', 0).set('m', 0).set('s', 0).set('ms', 0).add(4, 'day').toISOString();



                            missionData.tasks = currentMission.tasks;
                            missionData.tasks[0].taskStartDate = missionData.campaignStartDate;
                            missionData.tasks[0].taskEndDate = missionData.campaignEndDate;


                            ApiActions.putData(`/missionbuffer/${id}`, missionData)
                                .then(resp => resp.data)
                                .then(mission => {
                                    let taskData = {};

                                    taskData.id = mission.tasks[0].id;

                                    ApiActions.approveMission(mission.id)
                                        .then(resp => {
                                            resolve();
                                        })

                                })
                        }
                    })

            })
        } else {
            return ApiActions.approveMission(data.id);
        }
    }

    rejectBrand = (id, type) => {
        return new Promise((resolve, reject) => {
            let { moderationFeedback, editProof } = this.state;
            if (type === 'live') {
                resolve(
                    ApiActions.postData('/brandbuffer/moderationRevert', { moderationFeedback, brandbuffer: id })
                )
            } else {
                resolve(
                    ApiActions.putData(`/brandbuffer/${id}`, { moderationFeedback, editProof: editProof, moderationRequested: false })
                )
            }
        })
    }


    rejectMission = (id, type) => {
        return new Promise((resolve, reject) => {
            let { moderationFeedback } = this.state;
            if (type === 'live') {
                resolve(
                    ApiActions.postData('/missionbuffer/moderationRevert', { moderationFeedback, missionbuffer: id })
                )
            } else {
                resolve(
                    ApiActions.putData(`/missionbuffer/${id}`, { moderationFeedback, moderationRequested: false })
                )
            }
        })
    }

    rejectInternship = (id, type) => {
        return new Promise((resolve, reject) => {
            let { moderationFeedback } = this.state;
            if (type === 'live') {
                resolve(
                    ApiActions.postData('/internshipbuffer/moderationRevert', { moderationFeedback, internshipbuffer: id })
                )
            } else {
                resolve(
                    ApiActions.putData(`/internshipbuffer/${id}`, { moderationFeedback, moderationRequested: false })
                )
            }
        })
    }

    editMissionBuffer = (missionBuffer) => {

        this.editBtn.startLoading();
        let url;
        if (process.env.NODE_ENV === 'development') {
            url = 'https://staging.dashboard.frapp.in'
        }

        if (process.env.NODE_ENV === 'production') {
            url = 'https://dashboard.frapp.in'
        }

        Promise.all([
            ApiActions.getData("/brandmanager/generateToken", { email: missionBuffer.brandmanager.email, _getFullResponse: true }),
            ApiActions.getData(`/brandmanager/${missionBuffer.brandmanager.id}`)
        ])
            .then(resp => {
                let authToken = resp[0].headers['x-authorization-token'];
                let metaData = resp[1];

                this.editBtn.stopLoading();
                let newWnd = window.open(encodeURI(`${url}/dashboard.frapp.in/login?authToken=${authToken}&metaData=${JSON.stringify(metaData)}`))
                newWnd.opener = null
            })
    }
}


export default Moderation;