import React from 'react'
//custom imports
import styles from './datacollection.module.scss';
import { AnswerIcon } from '../../assets/images'

const DataCollection = ({datacollection = []}) => {
    return datacollection && datacollection.length > 0 ? datacollection.map(data => {
        return <div className={styles.data_collected}>
            <div className={styles.qa_ans}>
                <p className={styles.qa}>Q: {data ? data.question : 'NA'}</p>
                <p><img src={AnswerIcon} /> {data ? data.answer : 'NA'}</p>
            </div>
        </div>
    }) : <div> ⚠️ No Data Collected</div>
}

export default DataCollection