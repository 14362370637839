import React, {useEffect, useState} from 'react'
import moment from 'moment'
//custom imports
import styles from '../../assets/stylesheets/pages/hiring/hiring.module.scss'
import Heading from '../../uicomponents/heading'
import useApi from '../../hooks/useApi'
import { getFollowupCallsList, getTotalFollowupCallsList, getCallHistory } from './service'
import Table from '../../uicomponents/table/Table'
import CustomTooltip from '../../components/utilities/ToolTip'
import { TrackHistory } from '../../assets/images'
import Popup from '../../uicomponents/popup'
import CallHistory from './callHistory'
import ThreeDotLoader from "../../components/utilities/ThreeDotLoader"
import ProjectTitle from '../../components/common/ProjectTitle';

const followupQuestions = [
    'Summarize call discussion for follow up'
]

const FollowUpCallsList = (props) => {
    const {id, firstname, lastname} = props.location && props.location.state && props.location.state.callerDetails|| {}
    const {title: projectTitle} = props.location.state.projectdata
    
    const getFollowupCallsListApi = useApi(getFollowupCallsList)
    const getTotalFollowupCallsListApi = useApi(getTotalFollowupCallsList)
    const getCallHistoryApi = useApi(getCallHistory)

    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1);
    const [showHistory, setShowHistory] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')

    useEffect(()=>{
        const data = {     
            user: id,
            teleproject:  props.match.params.id,
            marked: 'followup',
        }
        getTotalFollowupCallsListApi.request(data)
    },[])

    useEffect(()=>{
        const data = {     
            user: id,
            teleproject:  props.match.params.id,
            marked: 'followup',
        }
        const skip = (currentPage - 1) * limit
        getFollowupCallsListApi.request(skip,limit,sort, search, data)
    },[currentPage, sort, search])


    const callHistory = (row) => {
        // getCallHistoryApi.request('63ee0fc48d7716d8e85b5973')
        getCallHistoryApi.request(row.id)
    }

    const followupNote = (responses) => {
        let followups = responses && responses.map(note => {
            return followupQuestions.includes(note.question) ?
            note : undefined
        }).filter(Boolean)
        return followups && followups.length > 0 ?
        followups.map(followup => <div>{followup.answer}</div>) : <span>NA</span>
    }

    const checkDate = (data) => {
        let {laterwhen} = data
        if(laterwhen){
            const currentDate = moment().toISOString()

            const dateIsAfter = moment(laterwhen).isAfter(moment(currentDate));
            const dateIsSame = moment(laterwhen).isSame(moment(currentDate));
            const dateIsBefore = moment(laterwhen).isBefore(moment(currentDate));

            return(
                <div className={styles.upcoming_call_status}>
                    {
                        dateIsAfter ? <div className={styles.scheduled}><span className={styles.dot} />Scheduled</div> : 
                        <div className={styles.overdue}><span className={styles.dot} />Overdue</div>
                    }
                </div>
            )
        }else{
            return <span>NA</span>
        }
    }

    const maskMobileNumber = (mobileNum) => {
        return mobileNum ? `XXXXX${mobileNum.toString().substring(5)}`: ''
    }

    const columns = [
        {
            Header:'Lead',
            accessor: "mobile",
            Cell: ({value}) => ( maskMobileNumber(value) || 'NA'),
            width: "5%",
            disableSortBy: true
        },
        {
            Header: 'Last call date',
            Cell: ({cell}) => {
                const {lastcall} = cell.row.original
                const {createdAt} = lastcall || {}
                return lastcall ? 
                <span>{createdAt ? moment(createdAt).format("DD/MM/YYYY") : 'NA'}</span>
                : 'NA'
            },
            width: "5%",
            disableSortBy: true
        },
        {
            Header: 'Last call audio',
            Cell: ({cell}) => {
                const {lastcall} = cell.row.original
                const {exotel} = lastcall || {}
                const value = exotel ? exotel.RecordingUrl : ''
                return lastcall ? <audio controls src={value}/> : 'NA'
            },
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Last call outcome',
            Cell: ({cell}) => {
                const {lastcall} = cell.row.original
                const {outcome} = lastcall || {}
                return lastcall ?
                outcome ? outcome.title ? outcome.title : 'NA' : 'Call was not connected hence outcome is not available'
                : 'NA'
            },
            width: "10%",
        },
        {
            Header: 'Follow up time',
            accessor: "laterwhen",
            Cell: ({ value }) => (<span>{moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')}</span> || 'NA'),
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Follow up note',
            Cell: ({cell}) => {
                const {lastcall} = cell.row.original
                const {responses} = lastcall || {}
                return lastcall ? 
                responses ? followupNote(responses)
                : `The customer's follow up could not be completed`
                : 'NA'
            },
            width: "15%",
        },
        {
            Header: 'Status',
            Cell: ({cell}) => (
                checkDate(cell.row.original)
            ),
            width: "5%"
        },
        {
            Header: 'Call History',
            Cell: ({cell}) => (
                <CustomTooltip placement={'bottom'} description={'Call history'}
                component={<img src={TrackHistory} width='18px'
                    alt='Call history' onClick={() => {
                        setSelectedRowData(cell.row.original)
                        setShowHistory(true)
                        callHistory(cell.row.original)
                    }}/>}>
                </CustomTooltip>
            ),
            width: "8%"
        }
    ]

    const {data, loading, error} = getFollowupCallsListApi || {}
    const {data: total} = getTotalFollowupCallsListApi || {}
    const {data: callRecords, loading: callRecordLoading} = getCallHistoryApi || {}

    const renderCallHistory = () => {
        return(
            <Popup
            show={showHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowHistory(false)
            }}
            size='sm'
            heading={`Call history`}
            >
                {callRecordLoading ? <ThreeDotLoader/> :
                    <CallHistory data={
                        {...selectedRowData, 
                            callRecords: callRecords && callRecords.sort((a,b) => b.attempts - a.attempts) 
                        }} />
                }
            </Popup>
        )
    }

    const handlePopups = () => {
        if(showHistory) return renderCallHistory()
        else
        return null
    }

    return(
        <div className={styles.page_wrapper}>
             <Heading text={
                <ProjectTitle title={
                    <ul className={styles.breadcrumbs}>
                        <li>{projectTitle}</li>
                        <li>{firstname} {lastname}</li>
                        <li>Follow up</li>
                    </ul>
                }
                    goBack={() => {
                        props.history.push({
                            pathname: `/hiring/selected-callers/${props.match.params.id}`,
                            state:{
                                projectdata: props.location.state.projectdata
                            }
                        })
                    }} />
            } />

            <div>
            <Table
            columns={columns}
            data={data}
            totalCount={total || ''}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            // sortChange={sortChange}
            // filterChange = {filterChange}
            // searchBy='project title'
            isGlobalFilter = {false}
            />
        </div>
        {handlePopups()}
        </div>
    )
}

export default FollowUpCallsList