import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath } from "react-router-dom";
import Projects from '../pages/project/main'
import NewTringStatsScreen from '../components/pages/NewTringStatsScreen'

const ProjectRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    const match = matchPath(props.location.pathname, {
        path: '/projects/stats/:id',
        exact: true,
        strict: false
     })
     if(match) {
         customProps.match = match
     }

    return <Switch>
         <Route exact path={`${path}`}
        render={() => <Projects {...customProps} />}
        />

        <Route exact path={`${path}/stats/:id`}
        render={() => <NewTringStatsScreen {...customProps} />}
        />

        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default ProjectRouter	