import React, { useState, useEffect } from "react";
import styles from './table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const SINGLE_PAGE = 1;

const Pagination = ({ pageChangeHandler, totalRows = 0, rowsPerPage, currentPage, type = 'server', previousPage, nextPage,
    pageIndex, canNextPage, canPreviousPage, gotoPage
    }) => {
    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);
    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];
    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
  
    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);
  
    // Onclick handlers for the butons
    // const onPageSelect = (pageNo) => setCurrentPage(pageNo);
    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
      if (noOfPages === currentPage) {
        setCanGoNext(false);
      } else {
        setCanGoNext(true);
      }
      if (currentPage === 1) {
        setCanGoBack(false);
      } else {
        setCanGoBack(true);
      }
    }, [noOfPages, currentPage]);

     //   const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    return (
      <>
        {noOfPages > SINGLE_PAGE ? (
          <div className={styles['pagination-footer']}>
            <div className={styles['page-controls']}>
              <button
                onClick={() => type == 'client' ? previousPage() && pageChangeHandler(currentPage - 1) : pageChangeHandler(currentPage - 1)}
                disabled={type == 'client' ? !canPreviousPage : !canGoBack}
                className={styles.pagArrowBtn}
              >
                <FontAwesomeIcon icon={faAngleLeft} className={styles.angleIcons}/>
              </button>
              {<div className={styles.pageNum}>Page &nbsp;<input type="number" key={type == 'client' ? pageIndex + 1 : currentPage} 
              defaultValue={type == 'client' ? pageIndex + 1 : currentPage} 
              min="1" max={noOfPages} onKeyDown={(e) => {
                if(e.key === "Enter" && e.target.value){
                  if(type == 'client'){
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }else{
                    if(e.target.value > noOfPages){
                      currentPage = Number(noOfPages)
                    }else if(e.target.value < 1){
                      currentPage = 1
                    }else{
                      currentPage = Number(e.target.value)
                    }
                    pageChangeHandler(Number(currentPage))
                  }
                }
              }}/>&nbsp;&nbsp;of {noOfPages}</div>}
              <button
                onClick={() =>  type == 'client' ? nextPage() : pageChangeHandler(currentPage + 1)}
                disabled={type == 'client' ? !canNextPage : !canGoNext}
                className={styles.pagArrowBtn}
              >
                <FontAwesomeIcon icon={faAngleRight} className={styles.angleIcons}/>
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

export default React.memo(Pagination);