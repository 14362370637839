import React from 'react'
import styles from './tab.module.scss'

const TAB_COLORS = [
    'default-tab',
    'green-tab-solid',
    'orange-tab-solid',
    'blue-tab-solid',
    'red-tab-solid'
]

const ToggleSwitch = (props) => {
    let {color, tabs} = props || {}
    const checkStyle = TAB_COLORS.includes(color) ? color : TAB_COLORS[0]

    const handleClick = (event) => {
        props.onClick(event.target.innerText)
    }

    return(
        <div className={styles.switch_group}>
            {
                tabs && tabs.map((tab, i) => {
                    return <div className={`${styles.switch_tab} ${tab === props.activeTab && styles[checkStyle]}`}
                    onClick={(e) => handleClick(e)} key={i+1}>
                    {tab}
                    </div>
                })
            }
        </div>
    )
}

export default React.memo(ToggleSwitch)