import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import {getIdleCallers, matchTelecaller} from './service'
import useApi from '../../../hooks/useApi'
import FrappButton from '../../../components/utilities/FrappButton'
import Popup from '../../../uicomponents/popup'
import JobsHistory from './jobHistory'
import AvailableJobs from './availableJobsTable'
import CallerInfo from './callerInfo'
import {UserIcon, TrackHistory} from '../../../assets/images'

const IdleTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    const getIdleCallerapi = useApi(getIdleCallers)
    const matchTelecallerApi = useApi(matchTelecaller)

    const [showCallerInfo, setShowCallerInfo] = useState(false)
    const [showCallerHistory, setShowCallerHistory] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showAvailableJobs, setShowAvailableJobs] = useState(false)

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getIdleCallerapi.request(skip,limit,sort,search)
    },[currentPage, sort, search])

    const formatCallerStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `Looking for a job`
            case 'working':
                return `Working`
            case 'on_a_break':
                return `On a break`
            default:
                return status || 'NA'
        }
    }


    const columns = [
        {
            Header: 'Name',
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Caller status',
            accessor: "workStatus",
            Cell: ({value}) => (value ? formatCallerStatus(value) : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Mins spoken',
            accessor: "totalWorkMinutes",
            Cell: ({value}) => ( value ? value : 0),
        },
        // {
        //     Header: 'Last call made',
        //     accessor: "lastCallMade",
        //     Cell: ({value}) => (
        //        value !== ('NA' || '') ? moment(value).fromNow() : value
        //     )
        //     Cell: ({value}) => (value ? moment(value).fromNow() : 'NA') 
        // },
        {
            Header: 'Last seen',
            accessor: "lastSeen",
            Cell: ({value}) => (value ? moment(value).fromNow() : 'NA')
        },
        {
            Header: 'Caller info',
            Cell: (cell) => (
                <img src={UserIcon} alt='Caller info'
                onClick={()=>{
                    setShowCallerInfo(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true

        },
        {
            Header: 'Job History',
            Cell: (cell) => (
                <img src={TrackHistory} alt='Job History'
                onClick={()=>{
                    setShowCallerHistory(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <div className={styles.blue_link} onClick={() =>{
                setShowAvailableJobs(true)
                setSelectedRowData(cell.row.original)
            }}>
            ASSIGN JOB
            </div>
            ),
            disableSortBy: true
        }
    ]

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const {data, total} = getIdleCallerapi.data || {}
    const {loading} = getIdleCallerapi


    const renderCallerInfo = () => {
        const { firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerInfo}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerInfo(false)
            }}
            size='sm'
            heading={`Caller profile and information of ${firstname} ${lastname}`}
            popupContent = {<CallerInfo rowData={selectedRowData} callerPreference={true}/>}
            />
        )
    }

    const renderJobHistory = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerHistory(false)
            }}
            size='lg'
            heading={`Job history of ${firstname} ${lastname}`}
            popupContent = {<JobsHistory rowData={selectedRowData}/>}
            />
        )
    }

    const closeAvailableJobs = () => {
        setSelectedRowData('')
        setShowAvailableJobs(false)
        matchTelecallerApi.reset()
    }

    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.data && matchTelecallerApi.data.data.status == 200){
            let findIndex = data.findIndex(user => user.id == selectedRowData.id)
            if(findIndex !== -1){
                data.splice(findIndex, 1);
            }
            toast(matchTelecallerApi.data.data.msg)
            setTimeout(() => {
                closeAvailableJobs()
            }, 2000);
        }

        if(matchTelecallerApi && matchTelecallerApi.error){
            toast(matchTelecallerApi.error.msg)
        }
    },[matchTelecallerApi.data, matchTelecallerApi.error])

    const renderMatchJobs = () => {
        let projectData = ''


        const matchToJob = () => {
            const payload = {
                teleproject: projectData.id,
                user: selectedRowData.id
            }
            matchTelecallerApi.request(payload)
        }

        const ActionBtns = () => {
            return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
        }
        
        const passProjectSelected = (data) => {
            projectData = data
        }

        return(
            <Popup
            show={showAvailableJobs}
            closePopup={()=>{
                closeAvailableJobs()
            }}
            size='lg'
            heading={'Jobs currently open for hiring'}
            popupContent = {<AvailableJobs userData={selectedRowData} passProjectSelected={passProjectSelected}/>}
            popupFooter = {<ActionBtns/>}
            footerAlign = 'right'
            />
        )
    }

    const handlePopups = () => {
        if(showCallerInfo) return renderCallerInfo()
        if(showCallerHistory) return renderJobHistory()
        if(showAvailableJobs) return renderMatchJobs()
        else
        return null
    }

    return(
        <>
        {getIdleCallerapi.error && <p>{typeof getIdleCallerapi.error == 'object' && 
        getIdleCallerapi.error !== null &&
        !Array.isArray(getIdleCallerapi.error)
        ? getIdleCallerapi.error.error || getIdleCallerapi.error.msg : getIdleCallerapi.error}</p>}
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        isGlobalFilter = {true}
        />
        {handlePopups()}
        <ToastContainer draggable={true} />
        </>
    )
}

export default IdleTable
