import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter } from "react-router-dom";

const UnAuthGuard = ({component}) => {
    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkToken();
    }, [component]);


    const checkToken = () => {
        if(!localStorage.authToken){
            localStorage.removeItem("authToken")
        }else{
            return <Redirect to="/" />
        }
        setStatus(true)
    }

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default  UnAuthGuard