import React, { useEffect, useState } from 'react'
import MaterialTable, { MTablePagination, MTableToolbar } from "material-table";
import SearchBarMaterialTable from '../utilities/SearchBarMaterialTable'
import Dropdown from 'react-dropdown';

const Table = (props) => {
    const { data, columns, totalCount, query, setQuery,
        title, searchPlaceHolder, pageChanged, setPageChanged,
        fetchDataForTable, isTableLoading, titleClassName,
        searchBtnTriggered, searchMode, refreshTable,
        paginationType, filtering, search, exportButton, searchText, isDropDown = false, dropDownOptions, dropDownSelected, dropDownValue , dropDownPlaceholder, exportCsv} = props

    const[showFilter, setShowFilter] = useState('')



    useEffect(()=>{
        if(searchText){
            setShowFilter(searchText)
        }
    },[searchText])


    return paginationType == 'client' ? <div className={!search || !title ? 'client-custom-table' : ''}>
    <MaterialTable
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsPerPage: '25'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: "Actions"
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        title={title}
        columns={columns}
        data={data}
        components={{
            Pagination: (props) => {
                const { classes,...other } = props;
                return <div style={{ fontSize: "30px" }}>
                    <MTablePagination rowsPerPage={50} {...other} />
                </div>
            },
        }}
        isLoading={isTableLoading}
        options={{
            actions: false,
            filtering: filtering,
            // grouping: true,
            search: typeof search === 'undefined' ? true : search,
            exportButton: typeof exportButton === 'undefined' ? true : exportButton,
            exportCsv: exportCsv,
            loadingType: 'overlay',
            // export: false,
            exportAllData: true,
            columnsButton: true,
            pageSize: 25,
            emptyRowsWhenPaging: false,
            columnsButton: false,
            rowStyle: {
                fontSize: "16px"
            },
            searchFieldStyle: {
                boxShadow: 'none',
                webkitboxShadow: 'none',
            }
        }}
    />
    </div> : <div id="custom-table">
    <MaterialTable
        localization={{
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            }
        }}
        title={title}
        columns={columns}
        data={data}
        components={{
            Pagination: (props) => {
                const { classes,...other } = props;
                return <div style={{ fontSize: "30px" }}>
                    <MTablePagination
                        {...other}
                        count={totalCount}
                        page={query.page - 1} rowsPerPage={query.pageSize}
                    />
                </div>
            },
            Toolbar: props => (
                <>
                    <div className='custom-toolbar'>
                        <div className='first'>
                            <MTableToolbar {...props} searchFieldVariant="standard" />
                        </div>
                        <div className='second'>
                        <SearchBarMaterialTable
                            searchBtnTriggered={searchBtnTriggered}
                            placeholder={searchPlaceHolder}
                            searchMode={searchMode}
                            refreshTable={refreshTable}
                            searchText={searchText}
                            handleExportCsv={() => console.log('search')}
                            showFilter={showFilter}
                            //  isGeneratingCSV={this.state.isGeneratingCSV}
                            {...props}
                        />
                        {isDropDown && 
                            <Dropdown
                                options={dropDownOptions}
                                placeholder = {dropDownPlaceholder}
                                onChange={(val)=>
                                    dropDownSelected(val.value)
                                }
                                className="dropdown"
                            />}
                        </div>
                    </div>
                </>
            )
        }}
        onChangePage={(page) => {
            if (pageChanged)
                {
                setPageChanged(false)
                fetchDataForTable({ pageChanged: true, query: { ...query, page: page + 1 } })
                }
            }
        }
        isLoading={isTableLoading}
        options={{
            search: false,
            loadingType: 'overlay',
            export: false,
            columnsButton: true,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            columnsButton: false,
            rowStyle: {
                fontSize: "16px"
            },
            searchFieldStyle: {
                boxShadow: 'none',
                webkitboxShadow: 'none',
            }
        }}
    />
    </div>
}

export default Table