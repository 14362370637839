import axios from 'axios'

class Api {

    getBaseURL = (params, url) => {
        let baseUrl;
        baseUrl = process.env.REACT_APP_API_URL;
        // let instaReportLinks = ["/instaEngagement", "/missionLinkEngagement", "/instagramVisionForStory"];
        // if (params._vortex) {
        //     baseUrl = process.env.REACT_APP_API_URL_VORTEX;
        //     // baseUrl = "https://dev.frapp.in";
        //     // baseUrl = "https://vortex.frapp.in";
        // }
        // if (instaReportLinks.indexOf(url) >= 0) {
        //     baseUrl = process.env.REACT_APP_API_URL_INSTASCRAPER;
        // } else {
        //     baseUrl = process.env.REACT_APP_API_URL;
        //     // baseUrl = "http://54.169.233.100:1441";
        //     // baseUrl = "https://backbone.frapp.in";
        // }

        return baseUrl;
    }

    addAuth = (params) => {
        var tokenVar = 'authToken'
        // var tokenVar = params._vortex ? 'authTokenVortex' : 'authToken';
        var token = localStorage.getItem(tokenVar);
        // token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJiYXNlIjoiNWM2MTRlMjZjOTM5NGUwMDE2YWNiMDhkIiwiaWF0IjoxNTUwNDg0OTIyLCJleHAiOjE1NTE2OTQ1MjJ9.avKdWpeKidnlACs-QIHk2Se8vPaY1sCOdSYekhOMlrjKjC-oW712nzHMBAN3Dvzsf03WheOuk8AN5-IzIXouyQ";
        var headers = {
            'Authorization': `Bearer ${token}`
        }
        headers = params && params.public ? {} : headers;

        return localStorage.getItem('authToken') ? headers : {};
    }

    addParams = (params, url) => {
        let fileUploadLinks = ["/teletask/uploadTeleTasks", "/teletask/uploadcsvtosqs", "refreshertraining/uploadvideo", "teletask/transferLeadsByIds", "telecaller/bulkmatchtelecaller",
            "/telecaller/bulkupdateearnings", "/telecaller/bulkcertifycallers", "/telecaller/bulkaddofferletters", "/telecaller/bulkmatchtelecaller", "/telecaller/bulkmoderation",
            "/teletask/alpleadstransferbyuserids"
        ];
        if (fileUploadLinks.includes(url)) {  /// BECAUSE IT'S FORM DATA AND YOU DON'T WANA MESS WITH IT 


            return params
        } else {
            var newParams = Object.assign({}, params);
            if (params) {
                try {
                    delete newParams.public;
                    delete newParams.infinite;
                    delete newParams.fullResponse;
                    delete newParams._vortex;
                    delete newParams._getFullResponse
                } catch (err) {
                    // do nothing
                }
            }

            return newParams;

        }
    }

    get = (url, params) => {
        params = params || {};

        var promise = axios({
            url: `${url}`,
            method: 'get',
            baseURL: this.getBaseURL(params),
            headers: this.addAuth(params),
            params: this.addParams(params)
        })

        if (params._getFullResponse) {
            return promise
        }
        return promise
            .then((resp) => resp.data)
            .catch(err => { console.log(err); throw err })


    }

    getStream = (url, params) => {
        var promise = axios({
            url: `${url}`,
            method: 'get',
            baseURL: this.getBaseURL(params),
            responseType: 'stream',
            params: this.addParams(params),
            headers: this.addAuth(params)
        })


        return promise
    }

    post = (url, data) => {
        return axios({
            url: `${url}`,
            method: 'post',
            baseURL: this.getBaseURL(data, url),
            data: this.addParams(data, url),
            headers: this.addAuth(data)
        })
    }

    put = (url, data) => {
        return axios({
            url: `${url}`,
            method: 'put',
            baseURL: this.getBaseURL(data),
            data: this.addParams(data),
            headers: this.addAuth(data)
        })
    }

    delete = (url, data) => {
        return axios({
            url: `${url}`,
            method: 'delete',
            baseURL: this.getBaseURL(data),
            data: data,
            headers: this.addAuth(data)
        })
    }




    // Freshdesk [ FD ] Requests
    addParams_freshdesk = (data) => {
        return JSON.stringify(data)
    }

    addAuth_freshdesk = () => {
        let username = process.env.REACT_APP_API_KEY_FRESHDESK;
        // let username = 'Y9qob1FHazXMnqKAp5m';
        let password = 'X'

        const token = `${username}:${password}`;
        const encodedToken = Buffer.from(token).toString('base64');

        if(localStorage.getItem('authToken')) {
            return { 
                'Authorization': 'Basic '+ encodedToken,
                'Content-Type': 'application/json',
                // 'Cookie': '_x_m=x_c; _x_w=2'
            }
        }
        else {
            return {}
        }

    }

    post_freshdesk = (url,data) => {
        let baseURL = process.env.REACT_APP_API_URL_FRESHDESK
        return axios({
            method: 'post',
            url: `${url}`,
            baseURL: baseURL,
            // baseURL: "https://newaccount1623918089734.freshdesk.com/api/v2",
            data: this.addParams_freshdesk(data),
            headers: this.addAuth_freshdesk()
        })
    }
}

axios.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    // document.body.classList.add('loading-indicator');

    const token = window.localStorage.token;
    if (token) {
        config.headers.Authorization = `token ${token}`
    }
    return config
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response, err) {
    //  document.body.classList.remove('loading-indicator');

    if (err && err.response) {
        let data = err.response.data;


        const isProd = process.env.NODE_ENV === "production";
        if (data.code === 4300 && isProd) {
            window.location.reload();
            localStorage.clear();
        }
        if (data.status === 404) {
            throw err;
        } else {
            if (data && data.msg) {
                console.log(data.msg);
            } else {
                console.log(data.msg);
            }
            throw err;
        }
    }

    else {
        // spinning hide
        // UPDATE: Add this code to hide global loading indicator
        // document.body.classList.remove('loading-indicator');

        return response;

    }

})

// function handleRefreshToken(){
//     var user = JSON.parse(localStorage.getItem("user"));
//     var {refreshToken} = user;

//     var api = new Api();
//     api.get(`/user/refreshAccessToken`,{refreshToken})
//     .then(resp => {
//         var data = resp.data;
//         user.token = data.token;

//         debugger
//         window.location.reload();
//         localStorage.setItem("user",data.token)
//         localStorage.setItem("authToken",data.refreshToken)

//     })
//     .catch(err => {
//         console.log(err)
//     })

//     debugger
// }

// function getBrowserSpecs(type){
//     var ua = navigator.userAgent, tem, 
//     M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
//     if(/trident/i.test(M[1])){
//         tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
//         return {name:'IE',version:(tem[1] || '')};
//     }
//     if(M[1]=== 'Chrome'){
//         tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
//         if(tem != null) return {name:tem[1].replace('OPR', 'Opera'),version:tem[2]};
//     }
//     M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
//     if((tem = ua.match(/version\/(\d+)/i))!= null)
//         M.splice(1, 1, tem[1]);

//     return type === "name" ? M[0] : M[1];
// }

export default new Api()