import React, { useState, useContext, Fragment, useEffect, useLayoutEffect, useCallback, useRef } from 'react'
import IS from './IS'
import {ISProvider} from './ISContext'
  
const InteractiveScriptPlayGround = (props) => {
    return <ISProvider>
		<div>
			<IS {...props}/>
		</div>
	</ISProvider> 
}

export default InteractiveScriptPlayGround