import React, { useEffect } from 'react'
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import useApi from '../../../hooks/useApi'
import {getCallerJobHistory} from './service'
import Capitalise from '../../../helper/Capitalise'
 
const JobsHistory = ({rowData}) => {
    const getCallerJobHistoryApi = useApi(getCallerJobHistory)

    useEffect(()=>{
        const {id} = rowData || {}
        const payload = {
            user: id,
        }
        getCallerJobHistoryApi.request(payload)
    },[])


    const columns = [
        {
            Header: 'Projects matched',
            accessor: (row) => row.teleproject.title,
            Cell: ({cell}) => {
                return <span>{Number(cell.row.id) + 1}.&nbsp;&nbsp;{cell.row.original.teleproject.title}</span>
            },
            width: '60%',
            disableSortBy: true
        },
        {
            Header: 'Stage left',
            accessor: (row) => Capitalise(row.status),
            width: '20%',
            disableSortBy: true
        },
        {
            Header: 'Project end date',
            accessor: (row) => row.teleproject && 
            row.teleproject.endDate ? moment(row.teleproject.endDate).format("DD/MM/YYYY") : "NA",
            disableSortBy: true
        },
    ]


    const {data} = getCallerJobHistoryApi || {}
    const {loading} = getCallerJobHistoryApi


    return(
        <>
        {getCallerJobHistoryApi.error && <p>{typeof getCallerJobHistoryApi.error == 'object' && 
        getCallerJobHistoryApi.error !== null &&
        !Array.isArray(getCallerJobHistoryApi.error)
        ? getCallerJobHistoryApi.error.error || getCallerJobHistoryApi.error.msg : getCallerJobHistoryApi.error}</p>}
        <p className={styles['total-count']}>Total jobs: {data && data.length > 0 ? data.length : '0'}</p>
        <Table
        columns={columns}
        data={data && data.hasOwnProperty('msg') ? [] : data}
        totalCount={0}
        limit={2}
        currentPage={1}
        loading={loading}
        type = 'client'
        />
        </>
    )
}

export default JobsHistory