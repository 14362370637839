import React, { useState, useEffect } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import ProjectTitle from '../../../components/common/ProjectTitle'
import Table from '../../../uicomponents/table/Table'
import FutworkButton from '../../../uicomponents/button/Button'
import AlertDialog from './alertDialog'
import Popup from '../../../uicomponents/popup'
import { confirmPaymentRequest } from './service'
import Tooltip from './tooltip'

const ConfirmPayout = (props) => {
    let {dateRange, paymentId, totalamount, paymentDueToTotalCaller, paymentDueToSelectedCaller, callers} = props.location.state
    const [showAlert, setShowAlert] = useState(false)
    const [requestTriggered, setRequestTriggered] = useState(false)
    const [resultMsg, setResultMsg] = useState('')
    const [paymentReqResult, setPaymentReqResult] = useState('')

    useEffect(()=>{
        if(!paymentId) props.history.push({
            pathname: `/caller/payout`,
            state:{
                dateRange: dateRange
            }
        })
    }, [])

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1);

    const closePopup = () => {
        setShowAlert(false)
        props.history.push({
            pathname: `/caller/attendance-tracker`,
        })
    }

    const renderAlert = () => {
        const props = {
            title: resultMsg || paymentReqResult || 'Payment request raised successfully',
            cta: 'Okay',
            error: false,
            onClick: () => {
                closePopup()
            }
        }
        return <Popup
        show={showAlert}
        closePopup={()=>{
            closePopup()
        }}
        size='sm'
        heading={''}
         >
            <AlertDialog {...props}/>
        </Popup>
    }

    const handlePopups = () => {
        if(showAlert) return renderAlert()
        else
        return null
    }


    const confirmPaymentReq = async() => {
        const payload = {
            paymentId : paymentId
        }
        try {
            let result = await confirmPaymentRequest(payload)
            if(result.status == 200){
                setResultMsg(result.data && result.data.message)
                setShowAlert(true)
            }
        } catch (error) {
            let msg = ''
                if (error.response)
                    msg = error.response && error.response.data || "Unexpected Error!"
                else
                    msg = error.message || "Unexpected Error!"
                setPaymentReqResult(msg.error ? msg.error : msg)
        }finally{
            setRequestTriggered(false)
            setShowAlert(true)
        }
        setRequestTriggered(false)
    }

    const columns = [
        {
            Header: "Sl. No",
            Cell: ({cell}) => {
                return <span>{Number(cell.row.id) + 1}.&nbsp;&nbsp;</span>
            },
            width: '5%',
            disableSortBy: true
        },
        {
            Header: "Caller",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            width: '30%',
            disableSortBy: true
        },
        {
            Header: 'Phone',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '30%',
            disableSortBy: true
        },
        {
            Header: Tooltip('Base pay'),
            accessor: "dailyEarning",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: Tooltip('Present count'),
            accessor: "presentCount",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: Tooltip('Earning due'),
            accessor: "totalEarnings",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
    ]

    let {data, loading, error} =  {}

    return(
        <div className={styles.payout_page_wrapper}>
            <ProjectTitle title={`Confirm details `}
            goBack={() => props.history.push({
                pathname: '/caller/payout',
                state:{
                    dateRange: dateRange
                }

            })} />

            <div className={styles.confirm_details_row}>
                <div className={styles.grid_item}>
                    <div>Payout duration</div>
                    <div>{dateRange && dateRange.from || ''} - {dateRange && dateRange.to || ''}</div>
                </div>
                <div className={styles.grid_item}>
                    <div>Payment due to</div>
                    <div>{paymentDueToTotalCaller || 0}</div>
                </div>
                <div className={styles.grid_item}>
                    <div>Callers being paid</div>
                    <div>{paymentDueToSelectedCaller || 0}</div>
                </div>
                <div className={styles.grid_item}>
                    <div>Callers being skipped</div>
                    <div>NA</div>
                </div>
                <div className={styles.grid_item}>
                    <div>Total amount</div>
                    <div>{totalamount || 0}</div>
                </div>
            </div>

            <Table
            columns={columns}
            data={callers || []}
            totalCount={data ? data.length : 0}
            limit={limit}
            type = 'client'
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            />
            <div className={[styles.payout_footer, styles.center].join(' ')}>
            <FutworkButton buttonStyle={'primary--solid non-rounded'} buttonSize={'sm'}
             disabled={requestTriggered}
            onClick={()=> confirmPaymentReq()}>Confirm & Proceed</FutworkButton>
            </div>
            {handlePopups()}
        </div>
    )
}

export default ConfirmPayout