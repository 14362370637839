import React, { useContext } from "react";
//custom imports
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import FutworkButton from "../../../uicomponents/button/Button";
import { ExoContext } from "./exoContext";
import { AlertIcon } from "../../../assets/images";

const DeleteExophone = (props) => {
    const { deleteExophone, setDeleteExophone } = useContext(ExoContext)

    const { data } = deleteExophone

    return (
        <div className={styles.delete_pool}>
            <div>
                {
                    data && data.active ?
                        <>
                            <img src={AlertIcon} className={styles.alert} alt="alert icon" />
                            <div className={styles.header}>
                                You can't delete an active exophone. Please turn it off to delete the exophone
                            </div>
                        </>
                        :
                        <>
                            <p className={styles.header}>Are you sure you want to delete this exophone?</p>
                        </>
                }
            </div>

            {data && !data.active && <div className={styles.cta_footer}>
                <FutworkButton buttonSize='sm' buttonStyle='primary--outline'
                    onClick={() => setDeleteExophone({ status: false, data: {} })}>Cancel</FutworkButton>

                <FutworkButton buttonSize='sm' buttonStyle='danger--solid' onClick={() => props.callDeleteFn(data)}>Delete</FutworkButton>
            </div>}
        </div>
    )
}

export default DeleteExophone