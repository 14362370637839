import React from "react";
import { Route, Switch } from "react-router-dom";
import UnAuthGuard from "../guards/unauthGuard";
import Login from '../components/pages/Login';
import MagicLink from '../components/pages/MagicLink'
import ForgotPassword from '../components/pages/ForgotPassword'
import ChangePassword from "../components/pages/ChangePassword"

const UnAuthRoutes = () => {
    return <Switch>
        <Route key="Login" exact path="/" render={() => <UnAuthGuard component={<Login />} />} />
        <Route key="magic-link" path="/magiclink" render={(props) => <UnAuthGuard component={<MagicLink {...props} />} />} />
        <Route key="forgot-password" path={"/forgot-password"} render={() => <UnAuthGuard component={<ForgotPassword />} />} />
        <Route key="change-password" path={"/change-password"} render={(props) => <UnAuthGuard component={<ChangePassword {...props} />} />} />
    </Switch>
}

export default UnAuthRoutes;