import React from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import FrappButton from '../utilities/FrappButton';
import ApiActions from '../../actions/ApiActions';

import FrappTable from '../utilities/FrappTable';
import DataSuccessScreen from './Tools/DataSuccessScreen';

import json2csv from 'json2csv';
import Papa from 'papaparse';

import dayjs from 'dayjs';

import DataStreamer from '../../util/stream/index';

class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCounts: [],
            paymentHistoryOfMobile: "",
            data: [],
            upcomingPaymentMissions: [],
            paymethod: ""
        }
        this.setState = this.setState.bind(this);
    }

    componentDidMount() {
        this.fetchTotalMoneyOwed();
        this.fetchAmazonPendingReport();
        this.fetchUpcomingPayments();
        this.fetchPaymentMethods()
    }

    fetchUpcomingPayments = () => {
        let endToCheck = dayjs().subtract(45, 'days').set({ hour: 0, seconds: 0, minutes: 0 }).toISOString();

        let streamInstance = new DataStreamer();

        let query = [
            {
                $match: {
                    campaignEndDate:
                    {
                        $gte: endToCheck // 45 days back date
                    },
                    reward: { $exists: true }
                }
            },
            {
                $lookup: {
                    from: "brand",
                    as: "brand",
                    localField: "brand",
                    foreignField: "_id"
                }
            },
            {
                $unwind: "$brand"
            },
            {
                $lookup: {
                    from: "missionapplication",
                    as: "missionapplication",
                    localField: "_id",
                    foreignField: "mission"
                }
            },
            {
                $unwind: "$missionapplication"
            },
            {
                $match: {
                    "missionapplication.status": "completed",
                    "missionapplication.rewardIssued": {
                        $nin: [true]
                    }
                }
            },
            {
                $group: {
                    _id: "$_id",
                    mission: { "$first": "$$ROOT" },
                    count: { "$sum": 1 }
                }
            },
            {
                $sort: { "mission.campaignEndDate": 1 }
            }, {
                $limit: 100
            }
        ];


        streamInstance.init(`/mission/aggregate?query=${JSON.stringify(query)}`, (resp) => {
            // let a = JSON.parse(resp);
            let a = resp;
            if (a) {
                let { upcomingPaymentMissions } = this.state;

                let ids = upcomingPaymentMissions.map(d => d._id);
                if (ids.indexOf(a.mission._id) === (-1)) {
                    upcomingPaymentMissions.push(a.mission)
                    this.setState({ upcomingPaymentMissions })
                }
            }
        })
    }

    fetchAmazonPendingReport = () => {
        ApiActions.getAmazonPendingReport()
            .then(csv => this.setState({ export_csv_data: csv }))
    }

    fetchPaymentMethods = () => {
        ApiActions.getPaymentMethods()
            .then(paymentMethods => this.setState({ paymentMethods: paymentMethods[0].methods }))
    }

    fetchPaymentHistory = (e) => {
        e.preventDefault();
        this.paymentHistorySubmitBtn.startLoading();

        var { paymentHistoryOfMobile } = this.state;

        ApiActions.getData('/user', { _vortex: true, mobile: paymentHistoryOfMobile })
            .then(users => {
                if (users[0]) {
                    var userId = users[0].id
                    ApiActions.getData('/missionapplication', { infinite: true, user: userId, status: "completed", populate: "mission" })
                        .then(this.fetchMissionsAndUpdateRewardData)
                        .then(completedMissionApplications => {
                            this.paymentHistorySubmitBtn.stopLoading();
                            this.setState({ data: completedMissionApplications })
                        })
                } else {
                    this.paymentHistorySubmitBtn.stopLoading();
                    alert("User not found with the given mobile number")
                }
            })

    }

    fetchTotalMoneyOwed = () => {
        this.fetchToBeLiable()
            .then(() => {
                let totalBalanceQuery = [
                    {
                        "$group": {
                            "_id": null,
                            "count": { "$sum": "$balance" }
                        }
                    }
                ]

                let streamInstance = new DataStreamer()

                streamInstance.init(`/wallet/aggregate?query=${JSON.stringify(totalBalanceQuery)}`, (resp) => {
                    // var obj = JSON.parse(resp);
                    let obj = resp;
                    if (obj && obj._id === null) {
                        this.setState({ totalLiability: obj.count })
                    }
                })

            })

    }

    fetchToBeLiable = () => {
        return new Promise((resolve, reject) => {
            let query = [
                { "$lookup": { "from": "missionapplication", "localField": "_id", "foreignField": "mission", "as": "missionapplications" } },
                { "$unwind": "$missionapplications" },
                {
                    "$match": {
                        "createdAt": { "$gte": new Date("2019-01-01T00:00:00.000Z") },
                        "reward": { "$exists": true },
                        "missionapplications.status": "completed",
                        "missionapplications.rewardIssued": { "$nin": [true] }
                    }
                },
                {
                    "$group": {
                        "_id": "$_id", "mission": { "$first": "$$ROOT" }, "maCount": { "$sum": 1 }
                    }
                },
                {
                    "$group": { "_id": null, "liability": { $sum: { $multiply: ["$maCount", "$mission.reward"] } } }
                }
            ]
            let streamInstance = new DataStreamer()

            streamInstance.init(`/mission/aggregate?query=${JSON.stringify(query)}`, (resp) => {
                if (resp !== 'null') {
                    // var obj = JSON.parse(resp);
                    let obj = resp;
                    this.setState({ toBeLiable: obj.liability });
                    resolve();
                }
            })
        })
    }

    fetchMissionsAndUpdateRewardData = (completedMissionApplications) => {
        if (completedMissionApplications.length === 0) {
            alert("Student has not completed any missions yet!")
        } else {
            completedMissionApplications = completedMissionApplications.filter(app => Boolean(app.mission.reward));
            return new Promise((resolve, reject) => {
                let rewardNotIssuedApps = completedMissionApplications.filter(app => !app.rewardIssued);
                let rewardIssuedApps = completedMissionApplications.filter(app => app.rewardIssued);
                var promises = rewardNotIssuedApps.map(app => {
                    let { mission } = app;
                    return ApiActions.getData('/missionapplication', { rewardIssued: true, mission: mission.id, count: true })
                })
                Promise.all(promises)
                    .then(appCounts => {
                        let updatedRewardNotIssuedApps = appCounts.map((appCount, i) => {
                            let rewardNotIssuedApp = rewardNotIssuedApps[i];
                            rewardNotIssuedApp.rewardProcessed = appCount > 0;
                            return rewardNotIssuedApp;
                        })

                        rewardIssuedApps = rewardIssuedApps.map(app => {
                            app.rewardProcessed = true;
                            return app;
                        })
                        let data = rewardIssuedApps.concat(updatedRewardNotIssuedApps);
                        this.setState({ data })
                    })
            })
        }
    }

    renderDownloadCSV = () => {
        let csvDownloadProps = { href: `data:text/csv;charset=utf-8,${escape(this.state.export_csv_data)}`, download: `Mission Report.csv`, id: "download-csv" }
        return <a style={{ display: 'none' }} {...csvDownloadProps}>none</a>
    }

    render() {
        return (
            <div className="mission-payments" >
                <div className="container">
                    {this.renderTotalMoneyOwed()}
                    <h3>Mission Payments</h3>
                    <Tabs onSelect={(key) => { this.setState({ missions: "", paymentMarkerData: null }) }} defaultActiveKey={3} id="payments-tab" >
                        <Tab eventKey={1} title="Payment Marker (Old)" >
                            {this.renderPaymentMarker("old")}
                        </Tab>
                        <Tab eventKey={2} title="Payment Marker (New)" >
                            {this.renderPaymentMarker("new")}
                        </Tab>
                        <Tab eventKey={3} title="Amazon Handling">
                            {this.renderAmazonPayments()}
                        </Tab>
                        <Tab eventKey={4} title="Upcoming Payments" >
                            {this.renderUpcomingPayments()}
                        </Tab>
                        <Tab eventKey={5} title="Payment History">
                            {this.renderPaymentHistory()}
                        </Tab>
                        <Tab eventKey={6} title="Transaction History" >
                            {this.renderTransactionHistory()}
                        </Tab>
                        <Tab eventKey={7} title="Payment Methods">
                            <div className="row">
                                {this.state.paymentMethods ? this.state.paymentMethods.map((method, i) =>
                                    <div>{this.renderPaymentToggle(method, i)}</div>) : null}
                            </div>
                            <br />
                            <FrappButton ref={(c) => this.updatePaymentMethod = c} className="submit p-10" handler={this.handleUpdatePaymentMethod}>Update Payment Methods</FrappButton>
                        </Tab>
                    </Tabs>
                    <DataSuccessScreen headerText="Missions are being marked as paid..." ref={(c) => this.dataSuccessScreen = c} />
                    {this.renderDownloadCSV()}
                </div>
            </div>
        )
    }

    handleUpdatePaymentMethod = () => {
        var result = window.confirm("Want to update payment method?");
        if (result) {
            this.updatePaymentMethod.startLoading();
            const { paymentMethods } = this.state;
            ApiActions.setPaymentMethods(paymentMethods)
                .then(resp => {
                    this.updatePaymentMethod.stopLoading()
                    alert("Payment methods updated successfully")
                }
                    , (resp) => {
                        this.updatePaymentMethod.stopLoading()
                        alert("something went wrong")
                    });

        } else {
            alert("update event cancelled")
        }
    }

    renderPaymentToggle = (method, i) => {

        return (
            <div className="col-md-3 col-xs-8 ">
                <div className="task-card-lock">
                    {method.active ? (<FormGroup className="payment-tab" bsSize="small">
                        <img src={method.logo} />
                        <Checkbox className="checkbox" onChange={() => this.handleCheckbox(i)}
                            checked={method.active}><h5>{method.name}{(method.active === true) ? " (Active)" : ""}</h5></Checkbox>
                        <span>Minimum withdrawal amount: </span><FormControl value={method.limit} onChange={(e) => this.handleFormControl(e, i)}
                            type="number" min="0" max="200" step="5" />
                    </FormGroup>) : (<FormGroup className="payment-tab-inactive" bsSize="small">
                        <img src={method.logo} />
                        <Checkbox className="checkbox" onChange={() => this.handleCheckbox(i)}
                            checked={method.active}><h5>{method.name}{(method.active === true) ? " " : " (Inactive)"}</h5></Checkbox>
                        <span>Minimum withdrawal amount:</span><FormControl value={method.limit} onChange={(e) => this.handleFormControl(e, i)}
                            type="number" min="0" max="200" step="5" disabled />
                    </FormGroup>)}
                </div>
            </div>
        )
    }
    handleCheckbox = (i) => {
        const { paymentMethods } = this.state;
        let temp = paymentMethods;
        temp[i].active = !paymentMethods[i].active;
        this.setState({ paymentMethods: temp })
    }
    handleFormControl = (e, i) => {
        const { paymentMethods } = this.state;
        let temp = paymentMethods;
        temp[i].limit = (e.target.value >= 0) ? e.target.value : 0;
        this.setState({ paymentMethods: temp })

    }

    renderTotalMoneyOwed = () => {
        var { toBeLiable } = this.state;

        return (
            <div className="total-money-owed" >
                To Be Liable: <span>{toBeLiable}</span>
                <br /><br />
                Current Liability: <span>{this.state.totalLiability}</span>
            </div>
        )
    }

    renderPaymentMarker = (type) => {
        var handler = type === 'old' ? this.fetchOldPaymentMarkerApplications : this.fetchPaymentMarkerApplications;

        return (
            <div className="payment-marker" >
                <FormGroup>
                    <ControlLabel>Paste the mission ids in the below box</ControlLabel>
                    <FormControl rows="6" name="missions" value={this.state.missions} onChange={this.setData} type="text" componentClass="textarea" />
                </FormGroup>
                {type === 'old' ? this.renderOldPaymentMarkerTable() : this.renderPaymentMarkerTable()}
                {
                    !this.state.paymentMarkerData &&
                    <FrappButton ref={(c) => this.submitBtnMarker = c} className="submit" handler={handler} >submit</FrappButton>
                }
            </div>
        )
    }

    renderOldPaymentMarkerTable = () => {
        var { paymentMarkerData } = this.state;

        if (!paymentMarkerData) {
            return null
        }

        var columns = ["Mission Name", "Completed Students", "Reward Issued To", "Amazon", "Paytm", { name: "renderMarkPaidBtn", value: "Mark as Paid" }];

        let renderMarkBtn = (i) => {
            let currPaymentData = paymentMarkerData[i];
            if (this.state[`paymentBtn${i}Marked`] || (currPaymentData[1] === currPaymentData[2])) {
                return <div className="marked" >Marked</div>
            }

            return (
                <FrappButton
                    ref={(c) => this[`missionPaymentMarkerBtn${i}`] = c}
                    handler={() => this.markMissionAsPaid(i, "old")} >
                    Mark As Paid
                </FrappButton>
            )
        }

        let data = paymentMarkerData.map((datum, i) => {

            let x = {
                "Mission Name": datum[0],
                "Completed Students": datum[1],
                "Reward Issued To": datum[2],
                "Amazon": datum[3],
                "Paytm": datum[4],
                "renderMarkPaidBtn": () => renderMarkBtn(i)
            }
            return x;
        })

        return (
            <FrappTable columns={columns} data={data} />
        )
    }

    renderPaymentMarkerTable = () => {
        var { paymentMarkerData } = this.state;

        if (!paymentMarkerData) {
            return null
        }

        var columns = ["Mission Name", "Completed Students", { name: "renderMarkPaidBtn", value: "Mark as Paid" }];

        let renderMarkBtn = (i) => {
            let currPaymentData = paymentMarkerData[i];
            if (this.state[`paymentBtn${i}Marked`] || (currPaymentData[1] === currPaymentData[2])) {
                return <div className="marked" >Marked</div>
            }

            return (
                <FrappButton
                    ref={(c) => this[`missionPaymentMarkerBtn${i}`] = c}
                    handler={() => this.markMissionAsPaid(i, "new")} >
                    Mark As Paid
                </FrappButton>
            )
        }

        let data = paymentMarkerData.map((datum, i) => {

            let x = {
                "Mission Name": datum[0],
                "Completed Students": datum[1],
                "renderMarkPaidBtn": () => renderMarkBtn(i)
            }
            return x;
        })

        return (
            <FrappTable columns={columns} data={data} />
        )
    }

    renderPaymentHistory = () => {
        return (
            <div className="payment-history" >
                <FormGroup>
                    <ControlLabel>Enter the mobile number of the user</ControlLabel>
                    <FormControl type="number" onChange={(e) => this.setState({ paymentHistoryOfMobile: e.target.value })} />
                </FormGroup>
                {this.renderPaymentHistoryTable()}
                <FrappButton ref={(c) => this.paymentHistorySubmitBtn = c} className="submit" handler={this.fetchPaymentHistory} >submit</FrappButton>
            </div>
        )
    }

    renderTransactionHistory = () => {
        return (
            <div className="transaction-history" >
                <FormGroup>
                    <ControlLabel>Enter the transaction Ids</ControlLabel>
                    <FormControl type="text" componentClass="textarea" value={this.state.transactions} onChange={(e) => this.setState({ transactions: e.target.value })} />
                </FormGroup>
                {this.renderTransactionHistoryTable()}
                <FrappButton ref={(c) => this.transactionHistorySubmitBtn = c} className="submit" handler={this.fetchTransactionHistory} >submit</FrappButton>
            </div>
        )
    }

    renderUpcomingPayments = () => {
        let { upcomingPaymentMissions } = this.state;
        if (!upcomingPaymentMissions) return;
        const getDaysLeftToPay = (campaignEndDate) => {
            let lastDateToPay = dayjs(campaignEndDate).add(14, 'days')
            let now = dayjs();
            let daysLeftToPay = lastDateToPay.diff(now, 'days');
            return daysLeftToPay;
        }

        return (
            <div className="upcoming-payments" >
                {
                    upcomingPaymentMissions.map(mission => (
                        <div key={mission._id} className={`upcoming-payment-card ${getDaysLeftToPay(mission.campaignEndDate) <= 0 ? 'red' : ''}`} >
                            <div className="logo-wrapper" >
                                <img src={mission.brand.asseturl} alt="" />
                            </div>
                            <div className="content-wrapper">
                                <div className="name">
                                    {mission.name}
                                </div>
                                <div className="days-passed" >
                                    <span>{getDaysLeftToPay(mission.campaignEndDate)}</span>
                                    <span>days left to pay</span>
                                </div>
                                <div className="id">{mission._id}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    renderAmazonPayments = () => {
        return (
            <div className="amazon-payments">
                <FrappButton className="submit" ref={(c) => this.amazonBtn = c} handler={() => document.getElementById("download-csv").click()} >Download the Amazon Report</FrappButton>
                <div className="or">OR</div>
                <FormGroup>
                    <ControlLabel>Upload the amazon CSV</ControlLabel>
                    <FormControl type="file" onChange={this.setAmazonReportData} />
                </FormGroup>
                <FrappButton className="submit" ref={(c) => this.amazonUploadBtn = c} handler={this.postAmazonReport}  > Upload Report </FrappButton>
            </div>
        )
    }

    renderPaymentHistoryTable = () => {
        var columns = ["Mission Name", "Rewards Processed", "Paid", "id"];
        var { data } = this.state;

        if ((!data) || (data && data.length === 0)) return;


        return (
            <div className="table" >
                <FrappTable data={this.getTableData(data)} columns={columns} />
            </div>
        )
    }

    renderTransactionHistoryTable = () => {
        var columns = ["Date", "Transaction ID", "Category", "Amount", "Mission Name", "Status"];
        var { transactionData } = this.state;
        if ((!transactionData) || (transactionData && transactionData.length === 0)) return;

        return (
            <div className="table">
                <FrappTable data={this.getTransactionHistoryTableData(transactionData)} columns={columns} />
            </div>
        )
    }

    getTableData = (data) => {

        var newData = data.map(datum => ({
            "Mission Name": datum.mission.name,
            "Rewards Processed": datum.rewardProcessed || false,
            "Paid": datum.rewardIssued || false,
            "id": datum.id
        }))

        return newData
    }

    getTransactionHistoryTableData = (data) => {
        var newData = data.map(datum => ({
            "Date": window.UTIL.formatDate(datum.createdAt),
            "Transaction ID": datum.transactionId,
            "Category": datum.category,
            "Amount": Math.abs(datum.amount),
            "Mission Name": datum.missionapplication ? datum.missionapplication.mission : "N.A",
            "Status": datum.status
        }))
        return newData;
    }
    setData = (e) => {
        var { name, value } = e.target;
        this.setState({ [name]: value })
    }

    fetchOldPaymentMarkerApplications = () => {
        var { missions } = this.state;
        this.submitBtnMarker.startLoading();
        missions = missions.split("\n").map(e => e.trim());
        let promises = missions.map(mission => {
            let commonData = { status: "completed", mission, populate: "mission", count: true, where: { "rewardMethod": { "$exists": true } } }
            return Promise.all([
                ApiActions.getData("/mission", { id: mission }),
                ApiActions.getData("/missionapplication", commonData),
                ApiActions.getData("/missionapplication", { ...commonData, rewardIssued: true }),
                ApiActions.getData("/missionapplication", { ...commonData, rewardMethod: 'voucher' }),
                ApiActions.getData("/missionapplication", { ...commonData, rewardMethod: 'money' })
            ])
        })

        Promise.all(promises)
            .then(paymentMarkerData => {
                this.submitBtnMarker.stopLoading();

                // extract mission name in all arrays;
                paymentMarkerData = paymentMarkerData.map(datum => {
                    datum[0] = datum[0][0].name;
                    return datum;
                })

                this.setState({ paymentMarkerData })
            })

    }

    fetchPaymentMarkerApplications = () => {
        var { missions } = this.state;
        this.submitBtnMarker.startLoading();
        missions = missions.split("\n").map(e => e.trim());
        let promises = missions.map(mission => {
            let commonData = { status: "completed", mission, populate: "mission", count: true, where: { "rewardIssued": { "$nin": [true] } } }

            return Promise.all([
                ApiActions.getData("/mission", { id: mission }),
                ApiActions.getData("/missionapplication", commonData)
            ])
        })

        Promise.all(promises)
            .then(paymentMarkerData => {
                this.submitBtnMarker.stopLoading();
                paymentMarkerData = paymentMarkerData.map(datum => {
                    datum[0] = datum[0][0].name;
                    return datum;
                })

                this.setState({ paymentMarkerData })
            })

    }

    fetchTransactionHistory = () => {
        var { transactions } = this.state;
        transactions = transactions.split("\n");

        ApiActions.getData("/transaction", {
            where: { transactionId: { $in: transactions } }
        })
            .then((transactionData) => this.setState({ transactionData }))
    }

    markMissionAsPaid = (index, type) => {
        this[`missionPaymentMarkerBtn${index}`].startLoading();
        var { missions, paymentMarkerData } = this.state;
        missions = missions.split("\n").map(e => e.trim());

        let missionId = missions[index];


        var markPaid = type === 'old' ? ApiActions.markMissionAsPaid : ApiActions.authorizePayment;

        markPaid(missionId)
            .then(resp => {
                let currPaymentData = paymentMarkerData[index];
                currPaymentData[2] = currPaymentData[1];
                paymentMarkerData[index] = currPaymentData;

                this.setState({ [`paymentBtn${index}Marked`]: true, paymentMarkerData })
            })
            .catch(err => this[`missionPaymentMarkerBtn${index}`].stopLoading())

    }

    getAmazonReport = () => {
        this.amazonBtn.startLoading();

        this.fetchTransactions()
    }

    fetchTransactions = () => {
        ApiActions.getData("/transaction", { status: "pending", infinite: true, where: { "profile.method": "Amazon Voucher" } })
            .then(transactions => {
                this.setState({ transactions })
            })
    }

    generateAmazonReport = (transactions) => {
        var fields = ["Transaction Id", "Email", "Amount"]
        var data = [];

        for (let transaction of transactions) {
            var jsonToConvert = {
                "Transaction Id": transaction.id,
                "Email": transaction.user.email,
                "Amount": transaction.amount
            }
            data.push(jsonToConvert)
        }

        var parser = new json2csv.Parser({ fields });
        var csv = parser.parse(data);


        this.setState({ export_csv_data: csv }, () => {
            var downloadCSVEl = document.getElementById('download-csv');
            this.amazonBtn.startLoading();
            downloadCSVEl.click();
        })
    }

    setAmazonReportData = (e) => {
        var file = e.target.files[0];

        var reader = new FileReader();

        reader.onload = (e) => {
            this.processAmazonUploadFile(e.target.result)
        }

        reader.readAsText(file);
    }

    processAmazonUploadFile = (result) => {
        var { data } = Papa.parse(result);

        var mapping = {
            "Success": "successful",
            "Bounced": "failed"
        }

        var dataArray = data.map((datum, i) => {
            if (i === 0) return null;
            let retVal = {
                transactionId: datum[0],
                status: datum[5]
            };

            return retVal;
        }).filter(x => Boolean(x))
            .map(transaction => {
                transaction.status = mapping[transaction.status];
                return transaction;
            })

        let amazonPaymentData = {
            transactions: dataArray
        }

        this.setState({ amazonPaymentData })

    }

    postAmazonReport = () => {
        ApiActions.payAmazon(this.state.amazonPaymentData)
            .then(resp => {
                alert("Your report is successfully uploaded")
            })
    }
}

export default Wallet;