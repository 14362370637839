import React, { useEffect, useState } from 'react'
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
import ProjectTitle from '../../components/common/ProjectTitle';
import FormInput from "../../uicomponents/form-input/forminput";
import FutworkButton from "../../uicomponents/button/Button";
import ApiActions from '../../actions/ApiActions'
import ThreeDotLoader from '../../components/utilities/ThreeDotLoader';
import { GreyTrashIcon, InfoIcon } from '../../assets/images'
import handleErrorMessage from '../../helper/handleErrorMessage';

const AgentMetadataManagement = (props) => {
    const [inputValue, setInputValue] = useState('')
    const [agentData, setAgentData] = useState({
        data: '',
        loading: false
    })
    const [agentProfile, setAgentProfile] = useState({
        data: '',
        loading: false
    })
    const [variable, setVariable] = useState([])
    const [isMetadataEdited, setIsMetadataEdited] = useState(false)
    const [apiResponse, setApiResponse] = useState({
        status: '',
        msg: '',
        loading: false
    })
    const [errorMsg, setErrorMsg] = useState({
       input_value: ''
    })


    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }

    const checkIfValid = () => {
        let validationErrors = {};
        // Simple validation for empty fields
        if(inputValue.length < 10 || !isNumeric(inputValue)){
            validationErrors['input_value'] = 'Please enter a valid 10 digit mobile number'
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            setAgentData({data: '', loading: false})
            setAgentProfile({data: '', loading: false})
            return false;
        } else {
            return true
        }
    }

    const getDetails = () => {
        setAgentData({
            data: '', loading: true
        })
        setVariable([])
        setAgentProfile({
            data: '', loading: true
        })
        setApiResponse({
            status: '',
            loading: false,
            msg: ''
        })
        ApiActions.getAgentDetails(inputValue)
            .then(resp => {
                return resp
            })
            .then(caller => {
                setAgentData({
                    data: caller,
                    loading: false
                })
                ApiActions.getMissionProfile(caller.id).then(resp => {
                    const [first] = resp
                    if (first && first.metadata) {
                        let y = Object.entries(first.metadata).map(([key, value]) => {
                            return {
                                name: key,
                                value
                            }
                        })
                        setVariable(y)
                    } else {
                        setVariable([])
                        setAgentProfile({
                            data: '',
                            loading: false
                        })
                    }
                    setAgentProfile({
                        data: resp,
                        loading: false
                    })
                })
            })
            .catch(err => {
                const { data, status } = err.response
                setAgentData({
                    data: '', loading: false
                })
                setAgentProfile({
                    data: '', loading: false
                })
                setApiResponse({
                    status,
                    loading: false,
                    msg: handleErrorMessage(data)
                })

            })
    }

    const addVariable = (idx) => {
        setIsMetadataEdited(true)
        setVariable(
            [...variable, { name: '', value: '' }]
        )
    }

    const updateVariable = (i, key, value) => {
        setIsMetadataEdited(true)
        let copyVariable = [...variable]
        copyVariable[i][key] = value
        setVariable(copyVariable)
    }

    const removeKeyValue = (i) => {
        setIsMetadataEdited(true)
        let copyTemplates = [...variable]
        copyTemplates.splice(i, 1)
        setVariable(copyTemplates)
    }

    useEffect(() => {
        if (isMetadataEdited) {
            setApiResponse({
                status: '',
                msg: '',
                loading: false
            })
        }
    }, [isMetadataEdited])

    const submitMetaData = () => {
        const jsonObject = variable.reduce((acc, obj) => {
            if(acc[obj.name] !== '' && obj.value !== '')
            acc[obj.name] = obj.value;
            return acc;
        }, {});
        setApiResponse({
            ...apiResponse,
            loading: true
        })
        ApiActions.addUpdateMetadata({
            user: agentData.data.id,
            metadata: jsonObject
        }).then(resp => {
            setApiResponse({
                status: resp.status,
                loading: false,
                msg: resp.data.message
            })
            setIsMetadataEdited(false)
        }).catch(err => {
            console.error(err)
            setApiResponse({
                status: 400,
                loading: false,
                msg: 'Something went wrong'
            })
        })
    }

    return (
        <div className='container'>
            <div className={styles.coupon_code_wrapper}>
                <ProjectTitle title={`Back`} goBack={() => props.history.push("/tools")} />
                <div className={styles.title_row}>Agent Metadata Tool</div>

                <div className={styles.crud_box}>
                    <div className={styles.crud_form}>
                        <div className={styles.search_row}>
                            <FormInput
                                label="Agent Mobile Number"
                                helperIcon={true}
                                helperText={'Search for an agent using their mobile number'}
                                key='mob_num'
                                value={inputValue}
                                placeholder="Enter the 10 digit number & hit Enter key or hit the 'Search' button"
                                onChange={(e) => {
                                       // Clear error for a field once it has been changed
                                        if (errorMsg['input_value']) {
                                            setErrorMsg(prevErrors => ({
                                                ...prevErrors,
                                                ['input_value']: null
                                            }));
                                        }
                                    setInputValue(e.target.value)
                                    if (e.target.value == '') {
                                        setAgentData({
                                            data: '',
                                            loading: false
                                        })
                                        setAgentProfile({
                                            data: '',
                                            loading: false
                                        })
                                        setApiResponse({
                                            status: '',
                                            msg: '',
                                            loading: false
                                        })
                                    }
                                }}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter') {
                                        if(checkIfValid())
                                        getDetails()
                                    }
                                }}
                            />
                            <FutworkButton buttonStyle="primary--solid" onClick={() => {
                                if(checkIfValid())
                                getDetails()
                            }}>SEARCH</FutworkButton>
                        </div>
                        
                        {
                            agentProfile.loading && agentData.loading ?
                                <div className={styles.agent_details}>
                                    <h4 className={styles.text_center}>Agent details</h4>
                                    <ThreeDotLoader />
                                    <p className={styles.text_center}>Fetching agent information..</p>
                                </div>
                                :
                                agentData.data && <div className={styles.agent_details}>
                                    <h4 className={styles.text_center}>Agent details</h4>

                                    <div className={styles['user_row']}>
                                        <div>Name: {agentData.data.firstname} {agentData.data.lastname}</div>
                                        <div>Email: {agentData.data.email} </div>
                                        <div>Certified caller: {agentData.data.certifiedCaller ? 'Yes' : 'No'} </div>
                                    </div>

                                    <div className={styles['template_form']}>
                                        <>
                                            {variable && variable.length > 0 ?
                                                <div>
                                                    <h4 className={styles.text_center}>Metadata Variables</h4>
                                                    <div className={styles.note}>
                                                        <div>
                                                        <img src={InfoIcon}/>
                                                        <u><b>Note:</b></u>
                                                        </div>
                                                        <p><i>The usecase for the Metadata can be various but here is an example on how the Metadata can be used for coupon code management</i></p>
                                                        <p><i>For example if our client <b>(Adda247)</b> is providing a <b>coupon code</b> to be availed for our agents then we can add it in the respective agents record
                                                            by mentioning the coupon code name <b>adda247_state_coupon</b> as Key and <b>FWV32</b> as its value</i>
                                                        </p>
                                                    </div>
                                                </div> : null}
                                            {
                                                variable && variable.length > 0 &&
                                                variable.map((each, i) => {
                                                    return <div className={styles['variables_row']}>
                                                        <div className={styles.input_fields}>
                                                            <FormInput
                                                                label="Key"
                                                                helperIcon={true}
                                                                helperText={'The name of the Metadata variable'}
                                                                onChange={(e) => updateVariable(i, 'name', e.target.value)}
                                                                value={each.name}
                                                            />

                                                            <FormInput
                                                                label="Value"
                                                                helperIcon={true}
                                                                helperText={'The value of the Metadata variable'}
                                                                onChange={(e) => updateVariable(i, 'value', e.target.value)}
                                                                value={each.value}
                                                            />
                                                        </div>
                                                        <img src={GreyTrashIcon} onClick={() => removeKeyValue(i)} />
                                                    </div>
                                                })
                                            }
                                        </>
                                    </div>
                                    <button onClick={() => addVariable()} className={styles['addvar_cta']}>+ Add Metdata Variable</button>

                                    <div className={styles['text_center']} >
                                        {isMetadataEdited ? <FutworkButton buttonStyle="primary--solid"
                                            disabled={apiResponse.loading}
                                            onClick={submitMetaData}>SUBMIT</FutworkButton> : null}
                                    </div>

                                    {apiResponse.msg && <div className={apiResponse.status == 200 ? [styles['success-msg'],
                                    styles['text_center']].join(' ') :
                                        [styles['error-msg'], styles['text_center']].join(' ')
                                    }>{apiResponse.msg}</div>}
                                </div>
                        }
                        {
                            apiResponse.status !== 200 ?
                                <div className={`${styles['error-msg']} ${styles['text_center']}`}>
                                    {apiResponse.msg}
                                </div> : null
                        }
                        {
                            errorMsg && errorMsg.input_value ? 
                            <div  className={`${styles['error-msg']} ${styles['text_center']}`}>{errorMsg.input_value}</div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentMetadataManagement