import React, { Component, Fragment } from 'react'

const ThreeDotLoader = () => {
    return (
        <div className="loading">
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}


export const NoMoreData = ({msg}) => {
    return (
        <div className="loading">
            <p className='text-muted'>{msg}</p>
        </div>
    )
}

export default ThreeDotLoader