import React, { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
//custom imports
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import CustomTab from "../../../components/utilities/CustomTab";
import PoolManagement from "./poolManagement";
import ExophoneList from "./exophoneList";
import handleErrorMessage from "../../../helper/handleErrorMessage";
import ThreeDotLoader from "../../../components/utilities/ThreeDotLoader";
import { EmptyCollection } from "../../../assets/images";
import { ExoProvider, ExoContext } from "./exoContext";

const MainPage = () => {
    const { viewExophones,
        activeTab, setActiveTab,
        exotelAcc, setExotelAcc,
        setQuery,
        exophonesListApi
    } = useContext(ExoContext)


    useEffect(() => {
        exophonesListApi.request()
    }, [])



    const setActiveTabFn = (account) => {
        const { label, value } = account
        const fetchData = exotelAcc && exotelAcc.find((each) => {
            const { label, data } = each
            if (account.label == label) {
                return data
            }
        })
        setQuery('')
        setActiveTab({
            label,
            value,
            data: fetchData && fetchData.data
        })
    }

    const { data, loading, error } = exophonesListApi || {}

    useEffect(() => {
        setExotelAcc(
            data && data.map(({ account, ...rest }) => {
                return {
                    label: account,
                    value: account.toLowerCase().replace(/ /g, "_"),
                    data: rest
                }
            })
        )
        if (data && data.length > 0) {
            const [first] = data
            const { account, ...rest } = first
            setActiveTab({
                label: account,
                value: account && account.toLowerCase().replace(/ /g, "_"),
                data: rest
            })
        }
    }, [data])

    useEffect(() => {
        if (error)
            toast(handleErrorMessage(error))
    }, [error])

    return (
        <div className={styles['exotel_management_wrapper']}>
            {
                viewExophones.status ? <ExophoneList />
                    :
                    !loading && data !== null ?
                        exotelAcc && exotelAcc.length > 0 ?
                            <>
                                <CustomTab tabs={exotelAcc} setActiveTabFn={setActiveTabFn}
                                    currentTab={activeTab}
                                    className={'royal-blue tab-space big-font-size'} />
                                <PoolManagement />
                            </> :
                            <div className={styles.fallback_wrapper}>
                                <div className={styles.fallback_section}>
                                    <img src={EmptyCollection} />
                                    <p className={styles.text}>No exotel futwork account exists</p>
                                </div>
                            </div>
                        :
                        <ThreeDotLoader />
            }

        </div>
    )
}


const ExophoneManagement = () => {
    return <ExoProvider>
        <MainPage />
    </ExoProvider>
}

export default ExophoneManagement