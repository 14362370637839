import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import { TrashIcon, DeleteIcon, EditIcon } from '../../../assets/images'
import ExophoneList from "./exophoneList";
import useApi from "../../../hooks/useApi";
import { getBrandName } from "./service";
import { ExoContext } from "./exoContext";
import { EmptyCollection } from "../../../assets/images";

const PoolPanel = (props) => {
    const { activeTab: accountSelected,
        goToExophoneList, exophonesListApi,
        createPool, setCreatePool,
        deletePool, setDeletePool
    } = useContext(ExoContext)
    const { pool } = props
    const getBrandNameApi = useApi(getBrandName)

    useEffect(() => {
        getBrandNameApi.request(pool.brand)
    }, [pool && pool.brand])

    const checkIfExophoneActive = (numbers) => {
        const activenumber = [...numbers].find(number => number.active == true)
        if (activenumber) {
            const { label, primary } = activenumber || {}
            return `${primary} (${label})` || 'NA'
        }
        else return 'NA'
    }

    const { data: brand, loading, error } = getBrandNameApi || {}
    const [first] = brand || []

    return (
        <div className={styles.pool_panel}>
            <div className={styles.pool_header}>
                <div className={styles.pool_name}>
                    <div>
                    {pool.poolname || pool.poolName}
                    </div>
                    <div className={[styles.tag, pool && pool.poolType ? styles[pool.poolType.toLowerCase()] : undefined].join(' ')}>
                        {pool.poolType}
                    </div>
                </div>
                <div className={styles.icon_wrapper}>
                    <img src={EditIcon} onClick={() => setCreatePool({ status: true, data: { ...pool, brandname: first['name'] } })} />
                    <img src={TrashIcon} onClick={() => setDeletePool({ status: true, data: pool })} />
                </div>
            </div>


            <div className={styles.pool_details}>

                <div className={styles.pool_info}>
                    <div>Brand: {pool && pool.brand ? !loading && brand ? first.name : <span>loading brand name...</span> : 'NA'}</div>
                    <div>Projects linked: {pool && pool.linkedProjects || 0}</div>
                    <div>Exophones: {pool.exophonescount || pool.numbers && pool.numbers.length}</div>
                    <div>Active exophone: {pool.numbers ? checkIfExophoneActive([...pool.numbers]) : 'NA'}</div>
                </div>


                <div className={styles.right_chevron} onClick={() => goToExophoneList({ ...pool, brandName: first && first.name })}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </div>

        </div>
    )
}

export default PoolPanel