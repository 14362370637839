import React, {useState, useEffect} from 'react'
import styles from '../../../assets/stylesheets/pages/direct_match.module.scss'
import useApi from '../../../hooks/useApi'
import AvailableJobs from './availableJobsTable'
import { matchTelecaller } from './service'
import Heading from '../../../uicomponents/heading'
import FrappButton from '../../../components/utilities/FrappButton'
import { ToastContainer, toast } from 'react-toastify'
import ApiActions from '../../../actions/ApiActions'
import Capitalise from '../../../helper/Capitalise'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Popup from '../../../uicomponents/popup'
import BasePay from './basePay'
import CheckBasePayBeforeMatching from './checkBasePayBeforeMatching'
import Radio from '../../../uicomponents/radio'
import BulkMatch from './bulkMatch'

const matchTypes = [
    {label: 'Match via mobile', value: 'match_via_mobile'},
    {label: 'Bulk Match', value: 'bulk_match'}
]

const MatchViaMobile = () => {
    // let projectData = ''
    const [mobile, setMobile] = useState("")
    const [projectData, setProjectData] = useState("")
    const [userData, setUserData] = useState({})
    const [showBasePayEdit, setShowBasePayEdit] = useState(false)
    const [showEarningAlert, setShowEarningAlert] = useState(false)
    const [matchType, setMatchType] = useState(matchTypes[0])

    const mobileHandler = (e) => {
        setMobile(e.target.value)
        e.target.value.length <= 0 && setUserData({})
    }

    const getUser = async () => {
        setUserData({})
        if(mobile.length !== 10) {
            toast("Mobile number is invalid")
            return  null
        }
        ApiActions.getTeleCallerDetails(mobile).then((resp) => {
            if(!resp.earnings){
                resp.earnings = 0
            }
            setUserData(resp)
        }).catch(err => {
            if(err.response)
                toast((err.response && err.response.data && err.response.data.msg)|| "Unexpected Error!")
            else
            toast(err.message || "Unexpected Error!")
        })
    }

    const matchTelecallerApi = useApi(matchTelecaller)
    const matchToJob = () => {
        if(mobile.length !== 10) {
            toast("Mobile number is invalid")
            return  null
        }

        if(projectData.id === undefined) {
            toast("Invalid selection, please reselect project")
            return  null
        }

        if((userData && userData.earnings && userData.earnings == 0) ||
        userData && !userData.earnings){
            setShowEarningAlert(true)
        }else{
            const payload = {
                teleproject: projectData.id,
                mobile: mobile
            }
            // debugger
            matchTelecallerApi.request(payload)
        }

    }


    const ActionBtns = () => {
        return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
    }
    
    const passProjectSelected = (data) => {
        setProjectData(data)
    }

    const renderUserDetails = () => {
        let keys = Object.keys(userData).filter(data => data !== 'id')

        return (
        <>
        <div className={styles.userdetails__wrapper}>
            <p><strong>Name: </strong>{userData && userData.firstname}  {userData && userData.lastname}</p>
            <p><strong>Mobile: </strong>{userData && userData.mobile}</p>
            <p><strong>Email: </strong>{userData && userData.email}</p>
            <p><strong>Certified caller: </strong>{userData && userData.certifiedCaller ? 'Yes' : 'No'}</p>
        </div>

        <div className={styles.userdetails__wrapper}>
            <p><strong>Total minutes spoken: </strong>{userData && userData.totalMinutesSpoken}</p>
            <p><strong>Projects worked: </strong>{userData && userData.projectsWorked}</p>
            <p><strong>Earnings: </strong>{userData && userData.earnings} 
            <FontAwesomeIcon icon={faPenToSquare} className={styles.earnings_edit}
            onClick={() => {
                setShowBasePayEdit(true)
            }}/>
            </p>
        </div>
        </>
        )
    }

    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.data && matchTelecallerApi.data.data.status == 200){
            toast(matchTelecallerApi.data.data.msg)
        }
        setProjectData('')
    },[matchTelecallerApi.data])

    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.error){
            toast(matchTelecallerApi.error.error || matchTelecallerApi.error.msg)
        }
        setProjectData('')
    },[matchTelecallerApi.error])


    const renderBasePay = () => {
        return <Popup
        show={showBasePayEdit}
        closePopup={()=>{
            setShowBasePayEdit(false)
        }}
        size='sm'
        heading={`Edit base pay`}
         >
            <BasePay rowData={userData} updateMessage={updateMessage}/>
        </Popup>
    }

    const updateMessage = (result = '') => {
        userData.earnings = result.earnings
        setTimeout(()=>{
            setShowBasePayEdit(false)
        },500)
    }

    
    const renderAlert = () => {
        const props = {
            setShowEarningAlert,
            setShowBasePayEdit
        }
        return <Popup
        show={showEarningAlert}
        closePopup={() => {
            setShowEarningAlert(false)
        }}
        size='sm'
        heading = ''
        >
            <CheckBasePayBeforeMatching {...props}/>
        </Popup>
    }
    
    const handlePopups = () => {
        if(showBasePayEdit) return renderBasePay()
        if(showEarningAlert) return renderAlert()
        else
        return null
    }

    return (
        <div className={styles.page_wrapper}>
            <Heading text={'Match Agents'} />

            <div className={styles.select_match_type}>
                {
                    matchTypes.map(type => {
                        return(
                            <Radio 
                            name="radio-group-match-type"
                            value={type.value} 
                            id={type.value}
                            handleChange={(e)=>{
                                setMatchType(matchTypes.find(each => each.value == e.target.value))
                            }}
                            selectedValue={matchType.value}
                            label={type.label}
                            />
                        )
                    })
                }
            </div>

            {matchType.value == matchTypes[0].value && 
                <>
                    <div className={styles.mobile__wrapper}>
                        <div className={styles.mobile__input}>
                            <p>Caller Mobile Number</p> 
                            <input type='tel' value={mobile} 
                            onChange={mobileHandler.bind(this)}
                            />
                        </div>
                        <FrappButton className="submit" id="new-btn" handler={getUser}>Search</FrappButton>
                    </div>

                    {
                        Object.keys(userData).length > 0
                        ? renderUserDetails(userData)
                        : null 
                    }
                    
                    {Object.keys(userData).length > 0 && 
                    <>
                    <AvailableJobs passProjectSelected={passProjectSelected}/>
                    <ActionBtns/>
                    </>}
                </>
            }
            {
                matchType.value == matchTypes[1].value &&
                <BulkMatch />
            }
            <ToastContainer draggable={true} />
            {handlePopups()}
        </div>
    )

}

export default MatchViaMobile





