import React, { useState, useRef, useEffect } from "react";
import FormInput from "../../uicomponents/form-input/forminput";
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
import FutworkButton from "../../uicomponents/button/Button";
import ProjectTitle from '../../components/common/ProjectTitle';
import { CSVLink } from "react-csv";
import { DownloadIcon, AlertIcon, RefreshIcon, InfoIcon } from "../../assets/images"
import Upload from "../../uicomponents/upload/upload";
import Papa from 'papaparse';
import checkIsEmpty from "../../helper/checkIsEmpty";
import ApiActions from "../../actions/ApiActions";
import useApi from '../../hooks/useApi';
import handleErrorMessage from "../../helper/handleErrorMessage";

const headers1 = [
    {
        "lead id": "",
    },
    {
        "variable 1": "",
    },
    {
        "variable 2": "",
    },
    {
        "variable n": "",
    }
]


const headers2 = [
    {
        "mobile": "",
    },
    {
        "variable 1": "",
    },
    {
        "variable 2": "",
    },
    {
        "variable n": "",
    }
]

const tableHeaders= [
    {
        "name": "lead id",
        "mandatory": true
    },
    {
        "name": "mobile",
        "mandatory": true
    },
    {
        "name": "variable 1",
        "mandatory": false
    },
    {
        "name": "variable 2",
        "mandatory": false
    },
    {
        "name": "variable n",
        "mandatory": false
    }
]

const templateExample = `Hello {firstname}, you can confirm your order after payment on this {payment link}`
const csvExample = `| mobile | firstname | payment link |`

const WhatsappBulkSend = (props) => {
    const whatsappBulkSendApi = useApi(ApiActions.sendBulkWhatsappMsg)

    const [file, setFile] = useState('')
    const [isComplete, setIsComplete] = useState(0)
    const [inputValue, setInputValue] = useState({
        wa_from: '', wa_template_id: '', fileData: ''
    })
    const [errorMsg, setErrorMsg] = useState({
        fileData: '',
        wa_from: '',
        wa_template_id: '',
        api_error: ''
    })

    const circularProg = useRef('')
    const checkComplete = useRef('')

    const changeHandler = (e) => {
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) {
            if (e.target.files.length > 0) {
                setFile(file)
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true, // removes empty rows from the csv
                    complete: function (results) {
                        const sanitizedResult = results.data.map(obj => {
                            //remove empty columns from the csv
                            let santizedObject = Object.fromEntries(Object.entries(obj).filter(([key, v]) => {
                                if(key == "" || key == null){
                                    return false
                                }
                                return true
                            }));
                            obj = {...santizedObject}
                            return obj
                            if (!checkIsEmpty(obj)) {
                                return obj
                            }
                        }).filter(Boolean)
                        setInputValue({
                            ...inputValue,
                            fileData: sanitizedResult
                        })
                    }
                }
                )
            }
            const temp = { ...errorMsg }
            temp.fileData = ''
            setErrorMsg(temp)
        } else {
            setFile('')
            const temp = { ...errorMsg }
            temp.fileData = 'Only a file with .csv extension can be uploaded'
            setErrorMsg(temp)
        }
    }

    const checkIfValid = (csvheaders) => {
        let validationErrors = {};
        const multipleMandatoryHeaders = []
        csvheaders && csvheaders.forEach(item => {
            if (item.toLowerCase() == 'lead id' || item.toLowerCase() == 'mobile') multipleMandatoryHeaders.push(item)
        });
        // Simple validation for empty fields
        for (const key in inputValue) {
            if (key == 'wa_from' && (inputValue[key].length < 10 || !isNumeric(inputValue[key]))) {
                validationErrors[key] = 'Please enter a valid 10 digit mobile number'
            }
            if (key == 'fileData' && inputValue[key].length > 5000) {
                validationErrors[key] = 'Please make sure the CSV contains less than 5000 rows of data.'
            }
            if (key == 'fileData' && csvheaders.find(head => (head.toLowerCase() == 'lead id' || head.toLowerCase() == 'mobile')) == undefined) {
                validationErrors[key] = 'Please ensure that the csv includes either the leadId or mobile.'
            }
            if (key == 'fileData' && multipleMandatoryHeaders && multipleMandatoryHeaders.length == 2) {
                validationErrors[key] = `Please ensure that the lead id OR mobile is mentioned in the csv schema, do not upload both headers.`
            }
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            return false;
        } else {
            return true
        }
    }

    const triggerBulkSend = () => {
        const [first] = inputValue.fileData
        const body_vars = first ? Object.keys(first).map(item => item) : []
        if (checkIfValid(body_vars)) {
            const payload = {
                wa_from: inputValue.wa_from.trim(),
                wa_template_id: inputValue.wa_template_id.trim(),
                wa_body_vars: body_vars,
                leadsAr: inputValue.fileData
            }
            whatsappBulkSendApi.request(payload)
            setIsComplete(1)
        }
    }

    const reset = () => {
        setErrorMsg({ fileData: '', wa_from: '', wa_template_id: '', api_error: '' })
        setInputValue({ wa_from: '', wa_template_id: '', fileData: '' })
        setIsComplete(0)
        circularProg.current.classList.toggle(styles['circle_loader'])
        checkComplete.current.style.display = 'none'
    }

    const showResult = () => {
        return <div className={styles.fallback_wrapper}>
            {
                errorMsg && errorMsg.api_error ?
                    <div className={styles.error_wrapper}>
                        <img src={AlertIcon} className={styles.error_icon} />
                        <h4>{errorMsg.api_error}</h4>
                    </div> :
                    <div className={styles['circle_loader']} ref={circularProg}>
                        <div className={[styles['checkmark'], styles['draw']].join(' ')} ref={checkComplete}></div>
                    </div>
            }
            <h4>{loading && isComplete == 1 ? 'Uploading the leads CSV...' : isComplete == 2 ? `Campaign started successfully for ${data && data.data && data.data.count} leads, usually it takes 5-10 mins to complete the campaign` : null}</h4>
            {!loading && isComplete > 0 ?
                <div className={styles.reset_row}>
                    <img src={RefreshIcon} onClick={reset} className={styles.reset_icon} />
                    <span onClick={reset}>Reload</span>
                </div>
                : null}
        </div>
    }

    const updateFormData = (key, value) => {
        const tempObj = { ...inputValue }
        tempObj[key] = value
        setInputValue(tempObj)
        // Clear error for a field once it has been changed
        if (errorMsg[key]) {
            setErrorMsg(prevErrors => ({
                ...prevErrors,
                [key]: null
            }));
        }
    }

    const checkIfDataPresent = () => {
        const { wa_from, wa_template_id, fileData } = inputValue
        if (wa_from && wa_template_id && fileData) return false
        else return true
    }

    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }


    const { data, loading, error } = whatsappBulkSendApi || {}

    useEffect(() => {
        setErrorMsg({
            ...errorMsg,
            api_error: handleErrorMessage(error)
        })
    }, [error])

    useEffect(() => {
        if (loading == false && (data && data.data)) {
            setIsComplete(2)
            setTimeout(() => {
            circularProg.current.classList.toggle(styles['load_complete'])
            checkComplete.current.style.display = 'block'
            }, 1000);
        }
    }, [loading, data])

    return <div className="container">
        <div className={styles.bulk_send_wrapper}>
            <ProjectTitle title={`Back`} goBack={() => props.history.push("/tools")} />
            <div className={styles.trigger_bulk_title_row}>
               WhatsApp On-Demand Campaign
            </div>
            <div className={styles.crud_box}>
                {
                    isComplete > 0 ? showResult()
                        :
                        <div className={styles.crud_form}>
                            <div className={styles.csv}>
                                <div className={styles['header_csv']}>
                                    CSV headers must be as per the defined Schema
                                   
                                </div>

                                <div className={styles.schema_option}>
                                <div className={styles.display_schema}>
                                <table className={styles.headers_table}>
                                    <tr>
                                        {
                                            tableHeaders.map(header => {
                                                if(header.name.toLowerCase() !== 'mobile')
                                                return <th className={styles.headers_list}>{header.name}{ header.mandatory ?<span className={styles.mandatory}>*</span> : null }</th>
                                            })
                                        }
                                    </tr>
                                </table>
                                <CSVLink data={headers1}
                                        filename={`sample_bulk_send.csv`}
                                    >
                                        <div className={styles.download_btn}>
                                            <img title="Download Template" src={DownloadIcon} alt='download' />
                                        </div>
                                </CSVLink>
                                </div>
                                
                                <div className={styles.divider}>OR</div>

                                <div className={styles.display_schema}>
                                <table className={styles.headers_table}>
                                    <tr>
                                    {
                                            tableHeaders.map(header => {
                                                if(header.name.toLowerCase() !== 'lead id')
                                                return <th className={styles.headers_list}>{header.name}{ header.mandatory ?<span className={styles.mandatory}>*</span> : null }</th>
                                            })
                                        }
                                    </tr>
                                </table>
                                <CSVLink data={headers2}
                                        filename={`sample_bulk_send.csv`}
                                    >
                                        <div className={styles.download_btn}>
                                            <img title="Download Template" src={DownloadIcon} alt='download' />
                                        </div>
                                </CSVLink>
                                </div>
                                </div>
                                <div><img src={InfoIcon} className={styles.note}/> <u><b>Note:</b></u></div>
                                <ol>
                                    <li>
                                        <i>Use <b>lead id</b> if <b>mobile</b> of the person is not available.</i>
                                    </li>
                                    <li>
                                      <i>
                                        <div>Variables defined in the template should be added as headers in the csv.</div>
                                        <u>Example - Template:</u> 
                                        <div>{templateExample}</div>
                                        <u>CSV :</u>
                                        <div>{csvExample}</div>
                                        <b>Note: The order of the variables is important. firstname comes before the payment link in the csv.</b>
                                      </i>
                                    </li>
                                </ol>
                            </div>

                            <div style={{ marginBottom: '0.7em' }}>
                                <label style={{ color: '#1E1E1E', fontWeight: '600', margin: '1em 0', fontSize: '14px' }}>Upload CSV</label>
                                <Upload placeholder='Select CSV file to upload' onChange={changeHandler} />
                                {
                                    errorMsg && errorMsg.fileData ? <span className={styles.csv_error}>{errorMsg.fileData}</span> : null
                                }
                            </div>
                            <FormInput
                                label="Whatsapp Template ID"
                                helperIcon={true}
                                helperText={'You can find the ID in the Exotel WhatsApp Dashboard where the template was created.'}
                                key='template_id'
                                value={inputValue.wa_template_id}
                                onChange={(e) => updateFormData('wa_template_id', e.target.value)}
                                errorMessage={errorMsg.wa_template_id}
                            />

                            <FormInput
                                label="Whatsapp Number"
                                helperIcon={true}
                                helperText={'Enter the mobile number that will be used to send WhatsApp message'}
                                key='from_number'
                                value={inputValue.wa_from}
                                minLength="10"
                                maxLength="13"
                                onChange={(e) => updateFormData('wa_from', e.target.value)}
                                errorMessage={errorMsg.wa_from}
                            />

                            <div className={styles.trigger_bulk_send_row}>
                                <FutworkButton buttonStyle="primary--solid" buttonSize="medium"
                                    disabled={checkIfDataPresent()}
                                    onClick={triggerBulkSend}>SUBMIT</FutworkButton>
                            </div>
                            
                        </div>
                }

            </div>
        </div>
    </div>
}

export default WhatsappBulkSend