import React, { lazy, Suspense } from 'react';
import './assets/stylesheets/app.scss';
import './util/window';
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter } from "react-router-dom";
import ToolsContainer from './components/containers/Tools';
import Login from './components/pages/Login';
import MagicLink from './components/pages/MagicLink'
import Header from './components/common/Header';
import TringDashboard from './components/pages/TringDashboard';
import Category from './helper/Category';
import AuthRoutes from './routes/authRoute';
import UnAuthRoutes from './routes/unauthRoute';

// Event route handling
const Event = lazy(() => import('./components/pages/Events'));

function renderEventsPage() {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Event />
		</Suspense>
	);
}

class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
						<Switch>
						{localStorage.authToken && <AuthRoutes /> }
						<UnAuthRoutes />
						</Switch>
				</div>
			</Router>
		);
	}
	
	checkTokenExpiry = () => {
		if (jwt_decode(localStorage.authToken).exp < Date.now() / 1000) {
			localStorage.clear();
			return true;
		}
		else
			return false
	}

	renderHeader = (props) => {
		var url_string = window.location.href;
		var url = new URL(url_string);
		var c = url.pathname
		return localStorage.authToken && localStorage.accessTabs && !this.checkTokenExpiry()? <Header {...props}/> : null
	}

	renderHome = () => {
		return localStorage.authToken && localStorage.accessTabs && !this.checkTokenExpiry() ? <TringDashboard /> : <Login />;
	}

	renderMagicLinkPage = (props) => {
		return localStorage.authToken && localStorage.accessTabs && !this.checkTokenExpiry() ? <Redirect to="/" /> : <MagicLink {...props} />; 
	}

	renderPrivateRoute = (component, category) => {
		let subTabs = localStorage.accessSubTabs && JSON.parse(localStorage.accessSubTabs).tabs.length > 0 ? JSON.parse(localStorage.accessSubTabs).tabs : null
		subTabs = subTabs && subTabs.map(each => {
			return Object.values(each)[0]
		}).join(",").split(",")
	
		// const acessUser = localStorage.authToken ? jwt_decode(localStorage.authToken).auth : null;
		let Tabs = localStorage.accessTabs && JSON.parse(localStorage.accessTabs).tabs.length > 0 ? JSON.parse(localStorage.accessTabs).tabs : null;

		if(subTabs){
			Tabs = [...Tabs, ...subTabs]
		}
		const grantPermission = Tabs ? Tabs.some((tab) => tab === category) : false;
		return localStorage.authToken && !this.checkTokenExpiry() && grantPermission ? component : <Redirect to="/" />;

	}
}

export default App;

