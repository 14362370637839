import React, {useEffect, useState } from 'react'
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import constants from '../../util/constants';
import cloneDeep from "lodash/cloneDeep";

const standardOutcomeTitle = constants.standardOutcomes.map(ot => ot.title)
let description = {}
for (let item of constants.standardOutcomes) {
    description[item.title] = item.description;
}

const Outcome = (props) => {
    const outcomesTitle = props.outcomes && props.outcomes.map(out => out.title)
    const [indexOfCurrentInput, setIndexOFCurrentInput] = useState()
    const [customOutcomes, setCustomOutcomes] = useState(
        props.outcomes.filter(each => {
            if(!standardOutcomeTitle.includes(each.title)){
                if(!each.isFollowup){
                    //add the followup flag for custom outcomes if it doesn't exist
                    each.isFollowup = false
                }
                return each
            }
        })
    )
    const [suggestions, setSuggestions] = useState([]);

    let defaultOutcomes = standardOutcomeTitle.map(defOutcome => {
        let result = !outcomesTitle.includes(defOutcome)
        if(result){
        const present = props.outcome && props.outcomes.find(t => {
            return t.title == defOutcome
        })
        return present ? present : {
            title: defOutcome,
            description: description[defOutcome],
            isWinning: false,
            isFollowup: false
        }
        }else{
         return props.outcomes.find(e => {
             if(e.title == defOutcome)
             return e
         })
        }
    })

    useEffect(()=>{
        props.updateOutcome(defaultOutcomes, customOutcomes)
      }, [defaultOutcomes, customOutcomes])

    const addOutcomeField = () => {
        let tempData = cloneDeep(customOutcomes)
        tempData.push({
            title: '',
            description: '',
            isWinning: false,
            isFollowup: false
        })
        setCustomOutcomes(tempData)
    }

    const removeOutcomeField = (idx) => {
        let tempData = cloneDeep(customOutcomes)
        tempData.splice(idx, 1)
        setCustomOutcomes(tempData)
    }
    
    const updateCustomOutcome = (e, outcome, idx) => {
        if(e.target.value !== ''){
            const filteredSuggestions = ['Not Interested'].filter(suggestion =>
                suggestion.toLowerCase().startsWith(e.target.value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        }else{
            setSuggestions([])
        }
        let tempData = cloneDeep(customOutcomes)
        let changedOutcome = outcome;
        if(e.target.type == 'text' || e.target.type == 'textarea'){
        changedOutcome[e.target.name] = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
        tempData[idx] = changedOutcome
        }else if(e && e.target && e.target.type == 'checkbox'){
            if(e.target.name == 'isWinning')
            changedOutcome['isWinning'] = !outcome.isWinning
            if(e.target.name == 'isFollowup')
            changedOutcome['isFollowup'] = !outcome.isFollowup
            tempData[idx] = changedOutcome
        }
        setCustomOutcomes(tempData)
    }

      // Function to handle suggestion click
    const handleSuggestionClick = (value, idx) => {
        let tempCustomOutcomes = [...customOutcomes]
        if(value.toLowerCase() == "not interested"){
            tempCustomOutcomes[idx] = {
			title: 'Not Interested',
			description: 'Select this if the lead is not interested and has clearly mentioned the reason as well',
			isWinning: false,
			isFollowup: false
		    }
            setCustomOutcomes(tempCustomOutcomes)
        }
        // setInputValue(value);
        setSuggestions([]);
    };

    return <div className='outcome-section-wrapper'>
         {/* default outcome */}
        <div className='heading'>Standard Defaults</div>
        <div className='default-outcomes-wrapper'>
        <div className='rows'>
           <div className='outcome-list'>
           {
                   defaultOutcomes && defaultOutcomes.length > 0 ?
                   defaultOutcomes.map((outcome, idx) => {
                    return <FormGroup  key={idx+1}>
                    <ControlLabel>{`${idx+1}. ${outcome.title}`}</ControlLabel>
                     <FormControl
                     type="text"
                     readOnly
                     value={outcome.description}
                     />
                     </FormGroup>
                   }) : null
            }
           </div>
        </div>
        </div>

        {/* custom outcome */}
        {customOutcomes.length > 0 ? <div className='heading'>Custom Outcome</div>
        : null}
        <div className='custom-outcome-wrapper'>
            {
                customOutcomes && customOutcomes.length > 0 ?
                customOutcomes.map((outcome, idx) => {
                    return <div className='custom-outcome' key={idx+1}>
                    <FormControl id="outcome"
                    required
                    className="outcome-t"
                    name="title"
                    type="text"
                    value={outcome.title}
                    placeholder="Enter outcome name"
                    onChange={(e) => {
                        setIndexOFCurrentInput(idx+1)
                        updateCustomOutcome(e, outcome, idx)
                    }
                    }
                    />
                   {/* Suggestions */}
                    {suggestions.length > 0 && outcome.title !== 'Not Interested' && indexOfCurrentInput == idx+1 && (
                        <ul className='suggestion-list' key={idx+1}>
                        {suggestions.map((suggestion, index) => (
                            <li key={index} onClick={() => handleSuggestionClick(suggestion, idx)}>
                            {suggestion}
                            </li>
                        ))}
                        </ul>
                    )}
                    <FormControl componentClass="textarea"
                    id="outcome"
                    className="outcome-d" 
                    name="description"
                    placeholder="Outcome description"
                    value={outcome.description}
                    onChange={(e) => updateCustomOutcome(e, outcome, idx)}
                    />
    
                    <div className='checkbox-row'>
                    <label className='checkbox-title'><input type="checkbox"
                    name="isWinning"
                    checked={outcome.isWinning}
                    onChange={(e) => updateCustomOutcome(e, outcome, idx)}
                    />Winning outcome</label>

                    <label className='checkbox-title'><input type="checkbox"
                    name="isFollowup"
                    checked={outcome.isFollowup}
                    onChange={(e) => updateCustomOutcome(e, outcome, idx)}
                    />Followup outcome</label>
                    </div>
    
                    <div className='delete-outcome'>
                    <img src={require("../../assets/images/del-icon-taskprops.svg")}
                    className='red-delete-img'
                    onClick={()=>removeOutcomeField(idx)}
                    />
                    </div>
                    
                </div>
                }) : null
            }
        </div>

        <div className='add-outcome'
        onClick={() => addOutcomeField()}>
        + Add Outcome
        </div>
    </div>
}

export default Outcome