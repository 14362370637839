import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import {getCertifiedCallers, matchTelecaller} from './service'
import useApi from '../../../hooks/useApi'
import FrappButton from '../../../components/utilities/FrappButton'
import Popup from '../../../uicomponents/popup'
import AvailableJobs from './availableJobsTable'
import CallerInfo from './callerInfo'
import CallerPreference from './callerPreference'
import BasePay from './basePay'
import {ScholarHat, UserIcon} from '../../../assets/images'
import CheckBasePayBeforeMatching from './checkBasePayBeforeMatching'

const CertifiedTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')

    const getCertifiedCallerapi = useApi(getCertifiedCallers)
    const matchTelecallerApi = useApi(matchTelecaller)

    const [currentPage, setCurrentPage] = useState(1);
 
    const [showTrainingInfo, setShowTrainingInfo] = useState(false)
    const [showCallerPrefer, setShowCallerPrefer] = useState(false)
    const [showAvailableJobs, setShowAvailableJobs] = useState(false)
    const [showBasePayEdit, setShowBasePayEdit] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showEarningAlert, setShowEarningAlert] = useState(false)

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getCertifiedCallerapi.request(skip,limit,sort, search)
    },[currentPage, sort, search])


    const columns = [
        {
            Header: "Q no.",
            accessor: "queueNo",
            width: '7%'
        },
        {
            Header: 'Name',
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Training Score',
            accessor: "manualGradeScore"
        },
        {
            Header: 'Caller Info',
            Cell: (cell) => (
                <img src={ScholarHat} alt='Training Info' 
                onClick={()=>{
                    setShowTrainingInfo(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            )
        },
        {
            Header: 'Caller preference',
            Cell: (cell) => (
                <img src={UserIcon} alt='Caller Onboarding preference'
                onClick={()=>{
                    setShowCallerPrefer(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true

        },
        {
            Header: 'Base pay',
            accessor: 'earnings',
            width: '10%',
            Cell: (cell) => {
              const value = cell.row.original && cell.row.original.earnings ? cell.row.original.earnings : ''
               return <div className={styles.earnings}>{value ? <div className={styles.earings_present}>₹ {value}</div> : <div>₹ 0</div> }
               <FontAwesomeIcon icon={faPenToSquare} className={styles.earnings_edit} onClick={() => {
                setShowBasePayEdit(true)
                setSelectedRowData(cell.row.original)
               }}/></div>
            },
            disableSortBy: true

        },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <div className={styles.blue_link} 
            onClick={() =>{
                if((cell.row.original && cell.row.original.earnings && cell.row.original.earnings == 0) ||
                cell.row.original && !cell.row.original.earnings){
                    setShowEarningAlert(true)
                    setSelectedRowData(cell.row.original)
                }else{
                    setShowAvailableJobs(true)
                    setSelectedRowData(cell.row.original)
                }
            }}>
            MATCH JOB
            </div>
            ),
            disableSortBy: true
        }
    ]

    let {data, total} = getCertifiedCallerapi.data || {}
    const {loading} = getCertifiedCallerapi

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const renderTrainingInfo = () => {
        const {firstname, lastname} = selectedRowData
        return (
        <div className={styles['available-jobs']}>
        <Popup
        show={showTrainingInfo}
        closePopup={()=>{
            setSelectedRowData('')
            setShowTrainingInfo(false)
        }}
        size='sm'
        heading={`App Training information of ${firstname} ${lastname}`}
         >
            <CallerInfo rowData={selectedRowData}/>
        </Popup>
        </div>
        )
    }

    const renderCallerPreference = () => {
        const {firstname, lastname} = selectedRowData || {}
        return (
            <Popup
            show={showCallerPrefer}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerPrefer(false)
            }}
            size='sm'
            heading={`Caller preferences of ${firstname} ${lastname}`}
             >
                <CallerPreference rowData={selectedRowData}/>
            </Popup>
        )
    }


    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.data && matchTelecallerApi.data.data.status == 200){
            // let findIndex = data.findIndex(user => user.userId == selectedRowData.userId)
            // if(findIndex !== -1){
            //     data.splice(findIndex, 1);
            // }
            const skip = (currentPage - 1) * limit
            getCertifiedCallerapi.request(skip,limit,sort)
            toast(matchTelecallerApi.data.data.msg)
            setTimeout(() => {
                closeAvailableJobs()
            }, 2000);
        }

        if(matchTelecallerApi && matchTelecallerApi.error){
            toast(matchTelecallerApi.error.msg)
        }
    },[matchTelecallerApi.data, matchTelecallerApi.error])


    const renderAvailableJobs = () => {
        const {firstname, lastname} = selectedRowData
        let projectData = ''

        const matchToJob = () => {
            const payload = {
                teleproject: projectData.id,
                user: selectedRowData.userId
            }
            matchTelecallerApi.request(payload)
        }

        const ActionBtns = () => {
            return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
        }
        
        const passProjectSelected = (data) => {
            projectData = data
        }

        return(
        <Popup
        show={showAvailableJobs}
        closePopup={()=>{
            closeAvailableJobs()
        }}
        size = 'lg'
        heading = {`Jobs currently open for hiring [Caller Name: ${firstname} ${lastname}]`}
        popupContent = {<AvailableJobs userData={selectedRowData} passProjectSelected={passProjectSelected}/>}
        popupFooter = {<ActionBtns/>}
        footerAlign = 'right'
        >
        </Popup>
        )
    }

    const closeAvailableJobs = () => {
        setSelectedRowData('')
        setShowAvailableJobs(false)
        matchTelecallerApi.reset()
    }

    const renderBasePay = () => {
        return <Popup
        show={showBasePayEdit}
        closePopup={()=>{
            setSelectedRowData('')
            setShowBasePayEdit(false)
        }}
        size='sm'
        heading={`Edit base pay`}
         >
            <BasePay rowData={selectedRowData} updateMessage={updateMessage}/>
        </Popup>
    }

    const updateMessage = (result = '') => {
        if(result){
            data.map(item => {
                console.log(item)
                if((item.userId == result.id) || (item.id == result.id)){
                    item.earnings = result.earnings
                    return item
                }
                return item
            })
        }
        setSelectedRowData('')
        setTimeout(()=>{
            setShowBasePayEdit(false)
        },500)
    }

    const renderAlert = () => {
        const props = {
            setShowEarningAlert,
            setShowBasePayEdit
        }
        return <Popup
        show={showEarningAlert}
        closePopup={() => {
            setSelectedRowData('')
            setShowEarningAlert(false)
        }}
        size='sm'
        heading = ''
        >
            <CheckBasePayBeforeMatching {...props}/>
        </Popup>
    }

    const handlePopups = () => {
        if(showTrainingInfo) return renderTrainingInfo()
        if(showCallerPrefer) return renderCallerPreference()
        if(showAvailableJobs) return renderAvailableJobs()
        if(showBasePayEdit) return renderBasePay()
        if(showEarningAlert) return renderAlert()
        else
        return null
    }

    return(
        <>
         <div>
        {getCertifiedCallerapi.error && <p>{typeof getCertifiedCallerapi.error == 'object' && 
        getCertifiedCallerapi.error !== null &&
        !Array.isArray(getCertifiedCallerapi.error)
        ? getCertifiedCallerapi.error.error || getCertifiedCallerapi.error.msg : getCertifiedCallerapi.error}</p>}
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        selectRow = {false}
        isGlobalFilter = {true}
        />
        </div>
        {handlePopups()}
        <ToastContainer draggable={true} />
        </>
    )
}

export default React.memo(CertifiedTable)