import React, { useState, useEffect } from 'react'
import CustomTab from '../../utilities/CustomTab'
import RouteTab from '../../utilities/RouteTab';
import Table from '../../common/Table'
import ApiActions from '../../../actions/ApiActions';
import FrappButton from '../../utilities/FrappButton'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import FrappModal from '../../utilities/FrappModal';
import FormControl from 'react-bootstrap/lib/FormControl';
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import FutworkSetTimer from '../../utilities/FutworkSetTimer'
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import { Fragment } from 'react';
import ProjectTitle from '../../common/ProjectTitle'
import jwt_decode from "jwt-decode";
import { userRoleReason, userRoleAction } from './HiringFunctions'
import {getMockCallTitle, getMockCallStatus, mockCallButton, mockCallDataFn, renderConfirmCallModal, renderSelectionPop, checkIfCoachLoggedIn, tooltip, styleTheFeedback, renderAllFeedbackPopup, getMockCallStatusForCSV} from './UIFunctions'
import Tag from '../../../uicomponents/tag'
import ReactTable from '../../../uicomponents/table/Table';

const JobApplicationsHiring = (props) => {
    if (!props.location.state) {
        window.location.href = `/hiring`
    }
    const loggedInUser = jwt_decode(localStorage.authToken)
    const { coach, title } = props.location.state.projectdata

    let tabs = [
        {
            "name": "Applied Callers",
            "route": `/hiring/applied-callers/${props.match.params.id}`,
            "state": props.location.state
        },
        {
            "name": "Hiring",
            "route": `/hiring/${props.match.params.id}`,
            "state": props.location.state
        },
        {
            "name": "Retraining",
            "route": `/hiring/for-training-callers/${props.match.params.id}`,
            "state": props.location.state
        }

    ]

    let selectedCallerTab = [
        {
            "name": "Selected Callers",
            "route": `/hiring/selected-callers/${props.match.params.id}`,
            "state": props.location.state
        }
    ]

    let tabsForStage = [
        // {
        //     label: 'Under training',
        //     value: 'under_training'
        // },
        {
            label: 'Ready for mock',
            value: 'ready_for_mock'
        }
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(
        {
            label: tabsForStage[0].label,
            value: tabsForStage[0].value
        }
    )
    const [totalCount, setTotalCount] = useState(0)
    const [query, setQuery] = useState({
        page: 1,
        pageSize: 10
    })
    const [teleprojectsdata, setTeleprojectsdata] = useState([])
    const [showMockLinkModal, setShowMockLinkModal] = useState(false)
    const [showSelectionModal, setShowSelectionModal] = useState(false)
    const [confirmCallModal, setConfirmCallModal] = useState(false)
    const [dateOfMeeting, setDateOfMeeting] = useState('')
    const [timeOfMeeting, setTimeOfMeeting] = useState('')
    const [meetingURL, setMeetingURL] = useState('')
    const [videoURL, setVideoURL] = useState('')
    const [videoTitle, setVideoTitle] = useState('')
    const [dateFocused, setDateFocused] = useState('')
    const [pageChanged, setPageChanged] = useState(true)
    const [isTableLoading, setTableLoading] = useState(false)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [totalTrainingCallers, setTotalTrainingCallers] = useState('')
    const [totalReadyForMockCallers, setTotalReadyForMockCallers] = useState('')
    const [totalOverdueMockCallers, setTotalOverdueMockCallers] = useState('')
    const [totalScriptPaths, setTotalScriptPaths] = useState('')
    const [trainingCallersRecords, setTrainingCallersRecords] = useState([])
    const [readyForMockCallersRecords, setReadyForMockCallersRecords] = useState([])
    const [currentFeedback, setCurrentFeedback] = useState('')
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)
    const [showCallerAuditModal, setShowCallerAuditModal] = useState(false)
    const [callerHistory, setCallerHistory] = useState([])
    const [callerStatus, setCallerStatus] = useState('')
    const [callerSelectedForMock, setCallerSelectedForMock] = useState('')
    const [coachesData, setCoachesData] = useState([])
    const [currentCoach, setCurrentCoach] = useState()
    const [trainingTypeSelected, setTrainingTypeSelected] = useState(false)
    const [isTrainingVideo, setIsTrainingVideo] = useState(false)
    const [isTrainingMeeting, setIsTrainingMeeting] = useState(false)
    const [mockCallData, setMockCallData] = useState([])
    const [checkboxFeedbackData, setCheckboxFeedbackData] = useState([])
    const [hiringDecision, setHiringDecision] = useState({
        "status": '',
        "trainingStage": '',
        "actionBy": userRoleAction(loggedInUser.auth),
        "reason": '',
        "feedback": '',
        'callReason': ''
    })
    const [currentPage, setCurrentPage] = useState(1)

    let cellStyle = {
        fontSize: '12px',
        color: 'black',
    }

    let headerStyle = {
        backgroundColor: '#E7FAF2',
        fontSize: '12px',
        fontWeight: 'bold'
    }

    const fetchCallersBasedOnStage = (id, activeTab) => {
        if (activeTab.value == 'shortlisted' || activeTab.value == 'under_training') {
            setReadyForMockCallersRecords([])
            setTotalReadyForMockCallers('')
            setTableLoading(true)
            const data = {
                "teleproject": id,
                "stages": ['shortlisted', 'remock']
            }
            const { trainingMeetings, trainingVideos } = props.location.state.projectdata
            if (trainingMeetings && trainingMeetings.length > 0) {
                setTrainingTypeSelected(true)
                setIsTrainingMeeting(true)
                setMeetingURL(trainingMeetings[0].url)
                setDateOfMeeting(moment.utc(new Date(trainingMeetings[0].meetingDate)))
            }
            ApiActions.getTrainingCallersList(data).then(resp => {
                setTrainingCallersRecords([...resp.records])
                setIsLoading(false)
                setTableLoading(false)
                setTotalTrainingCallers(resp.totalTrainingCallers)
                setTotalScriptPaths(resp.totalScriptPaths)
            }).catch(err => {
                setTableLoading(true)
                toast.error(err.message)
                console.error(err)
            })
        }
        // ready_for_mock
        if (activeTab.value == 'ready_for_mock') {
            setTrainingCallersRecords([])
            setTotalTrainingCallers('')
            setTableLoading(true)
            ApiActions.callersReadyForMock(id).then(resp => {
                setReadyForMockCallersRecords([...resp.records])
                setTableLoading(false)
                setTotalOverdueMockCallers('')
                setTotalReadyForMockCallers(resp.totalMockReadyCallers)
                setTotalScriptPaths(resp.totalScriptPaths)
            }).catch(err => {
                setTableLoading(false)
                toast.error(`Status code : ${err.response.status}, Status text: ${err.response.statusText}`)
                console.error(err.response)
            })
        }

        if (activeTab.value == 'mock_overdue') {
            const now = moment.utc().format()
            setTrainingCallersRecords([])
            setTotalTrainingCallers('')
            setTableLoading(true)
            ApiActions.callersReadyForMock(id, now).then(resp => {
                setReadyForMockCallersRecords([...resp.records])
                setTableLoading(false)
                setTotalOverdueMockCallers(resp.totalMockReadyCallers)
                setTotalScriptPaths(resp.totalScriptPaths)
            }).catch(err => {
                setTableLoading(false)
                toast.error(`Status code : ${err.response.status}, Status text: ${err.response.statusText}`)
                console.error(err.response)
            })
        }
    }

    const underTrainingColumns = [
        {
            title: "Name",
            field: "firstname",
            filtering: true,
            render: rowData => {
                return rowData && rowData.firstname ? rowData.firstname : 'NA'
            },
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
        },
        {
            title: "Phone",
            field: "mobile",
            render: rowData => {
                return rowData && rowData.mobile ? rowData.mobile : 'NA'
            },
            filtering: true,
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
        },
        {
            title: "Email",
            field: 'email',
            filtering: true,
            render: rowData => {
                return rowData && rowData.email ? rowData.email : 'NA'
            },
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
        },
        {
            title: "Calls Attempted",
            field: "callsAttemptedCount",
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
            filtering: false
        },
        {
            title: "IS practice",
            render: rowData => {
                return rowData && rowData.scriptProgress && rowData.scriptProgress.length > 0 ? getScriptPathPercentage(rowData.scriptProgress) : 'NA'
            },
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
            filtering: false
        },
        {
            title: "Feedback",
            render: (rowData => {
                return <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={(e) => showAllTheFeedbacks(rowData, e)}>
                    <OverlayTrigger placement="bottom" overlay={tooltip('Click to view feeback')}>
                        <span>{styleTheFeedback(rowData.feedback ? rowData.feedback : "None")}</span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip('Caller history')}>
                        <img src={require('../../../assets/images/Icons_history.svg')} style={{ width: '22px', cursor: 'pointer' }} onClick={(e) => showCallerHistoryPopup(rowData.statusChangeLog, e, rowData)} />
                    </OverlayTrigger>
                </div>
            }),
            cellStyle: { ...cellStyle, backgroundColor: '#FFDBDB' },
            headerStyle: { ...headerStyle, boxShadow: 'none', backgroundColor: '#FFDBDB' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
            filtering: false
        },
        {
            title: `${[...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift() == 'trainingVideos' ?
                "Training video" : "Training session"}`,
            render: rowData => {
                const typeOfTraining = [...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift()
                return typeOfTraining == 'trainingVideos' ?
                    rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? <img style={{ width: '28px' }} src={require("../../../assets/images/green-tick.svg")} /> : 'Not viewed'
                    : rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? 'Attended' : 'Not attended'
            },
            cellStyle: cellStyle,
            headerStyle: { ...headerStyle, boxShadow: 'none' },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            },
            filtering: false
        },
        {
            title: "Status", field: "status",
            render: rowData =>
                <div>
                    <Dropdown options={[{ label: "Reject", value: "rejected" }]}
                        onChange={(val) => rejectUnderTrainingCaller(rowData, val)}
                        // value={rowData.status ? rowData.status : "Select"}
                        placeholder="Select an option" />
                </div>,
            cellStyle: {
                fontSize: '12px',
                color: 'black'
            }, headerStyle: {
                backgroundColor: '#E7FAF2',
                fontSize: '12px',
                fontWeight: 'bold',
                boxShadow: 'none'

            },
            filterCellStyle: {
                padding: '1% 0% 1% 1%'
            }
        }
    ]

    const callModal = () => {
        //check if coach mobile number is present
        if(!currentCoach)
        toast('Please assign a coach to the project to continue calling')
        else
        setConfirmCallModal(true)
    }

    const closeConfirmCallModal = () => {
        setConfirmCallModal(false)
    }

    const callerChooseForMock = (data) => {
        setCallerSelectedForMock(data)
    }

    const getMockCallDetails = (rowData) => {
        const { id } = rowData
        const data = {
            "teleproject": props.location.state.projectdata.id,
            "user": id,
            "mockCallStatus": 'pending',
            "callReason": 'mockCall'
        }
        ApiActions.getMockCallData(data).then(resp => {
            setMockCallData([...resp.data])
        }).catch(err => console.error(err.response))

        setShowSelectionModal(true)
    }

    const columns = [
        {
            Header: "Name",
            accessor: 'firstname',
            Cell: ({row}) => {
            const {original: {firstname, lastname}} = row
            return <div>
            <span style={{marginRight: '2px'}}>{firstname || 'NA'} {lastname || 'NA'}</span>
            </div>
            },
            width: '10%',
            disableSortBy: true,
            getCellExportValue: (row) => {
                const {original: {firstname, lastname}} = row
                return `${firstname} ${lastname}`
            }
        },
        {
            Header: 'Phone',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '5%',
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            width: '10%',
            disableSortBy: true
        },
        {
            Header: 'Mock type',
            accessor: "trainingStage",
            Cell: ({row}) => {
              const value = row.original
                let status = value.trainingStage == 'remock'
                ? 'Remock' : value.trainingStage == 'shortlisted' ? 'Under training' : 'Mock'
                return <Tag color={status.toLowerCase() === 'remock' ? 'orange-tab-solid' :  status.toLowerCase() === 'under training' ? 'green-tab-solid' : 'purple-tab-solid'}
                content={status}
                />  
            },
            getCellExportValue: (row) => {
                const {original: {trainingStage}} = row
                return trainingStage == 'remock'
                ? 'Remock' : trainingStage == 'shortlisted' ? 'Under training' : 'Mock'
            },
            width: '8%',
            disableSortBy: true
        },
        {
            Header: 'Calls Attempted (last 6 months)',
            accessor: "callsAttemptedCount",
            Cell: ({value}) => value || 0,
            width: '7%',
            disableSortBy: true
        },
        {
            Header: 'IS practice',
            accessor: "scriptProgress",
            Cell: ({row}) => {
                const value = row.original
                return value && value.scriptProgress && value.scriptProgress.length > 0 ? getScriptPathPercentage(value.scriptProgress) : 'NA'
            },
            getCellExportValue: (row) => {
                const {original: {scriptProgress}} = row
                return scriptProgress && scriptProgress.length > 0 ? (() => {
                    let newArray = scriptProgress.map((script) => {
                        let percentage = Math.ceil(
                          (script.successScriptProgressCount / 138) * 100
                        ).toFixed(0);
                        const obj = {
                          percentage,
                          language: script.scriptLanguage
                        };
                        return obj;
                      });
                    return newArray.map(e => `${e.language} : ${e.percentage > 100 ? 100 : e.percentage}%`).toString()
                })() : 'NA'
            },
            width: '5%',
            disableSortBy: true
        },
        {
            Header: 'Training session/video',
            accessor: "trainingMock",
            Cell: (row) => {
                const value = row.original
                const typeOfTraining = [...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift()
                return typeOfTraining == 'trainingVideos' ?
                    value && value.trainingMock && value.trainingMock.preparedForMockCall ? <img style={{ width: '28px' }} src={require("../../../assets/images/green-tick.svg")} /> : 'Not viewed'
                    : value && value.trainingMock && value.trainingMock.preparedForMockCall ? 'Attended' : 'Not attended'
            },
            getCellExportValue: (row) => {
                const {original: {trainingMock}} = row
                const typeOfTraining = [...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift()
                return typeOfTraining == 'trainingVideos' ?
                trainingMock && trainingMock.preparedForMockCall ? 'Viewed' : 'Not viewed'
                : trainingMock && trainingMock.preparedForMockCall ? 'Attended' : 'Not attended'
                
            },
            width: '5%',
            disableSortBy: true
        },
        {
            Header: 'Feedback',
            accessor: "feedbacks",
            Cell: ({row}) => {
                const value = row.original
                return <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={(e)=> showAllTheFeedbacks(value, e)}>
                <OverlayTrigger placement="bottom" overlay={tooltip('Click to view feeback')}>
                <span>{value.feedbacks && Array.isArray(value.feedbacks) ? listFeedbacks(value.feedbacks): value.feedbacks || 'NA'}</span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={tooltip('Caller history')}>
                <img src={require('../../../assets/images/Icons_history.svg')} style={{width: '22px', cursor: 'pointer'}} onClick={(e)=> showCallerHistoryPopup(value.statusChangeLog, e, value)}/>
                </OverlayTrigger>
                </div>
            },
            getCellExportValue: (row) => {
                const {original: {feedbacks}} = row
                const feedbackText = feedbacks && Array.isArray(feedbacks) ? 
                 feedbacks && feedbacks.length > 0 && feedbacks.map(({text}) => {
                    return  text
                }) : feedbacks || 'NA'
                return feedbackText.toString()
            },
            getProps:  (state, rowInfo) => {
                return {
                    style: {
                      background: "red" 
                    }
                  };
            },
            width: '8%',
            disableSortBy: true
        },
        {
            Header: 'Actions',
            accessor: "actions",
            Cell: ({cell}) => {
            const value = cell.row.original;
            return mockCallButton(value, callModal, callerChooseForMock, confirmCallModal)
            },
            width: '1%',
            disableSortBy: true,
            disableExport: true
        },
        {
            Header: '',
            accessor: "view",
            Cell: ({cell}) => {
            const value = cell.row.original;
            return mockCallDataFn(value, getMockCallDetails)
            },
            width: '1%',
            disableSortBy: true,
            disableExport: true
        }
    ]

    const rejectUnderTrainingCaller = (data, val) => {
        const role = loggedInUser.auth
        ApiActions.changeUserStatus(
            {
                "teleproject": props.location.state.projectdata.id,
                "user": data.id,
                "status": val.value,
                "reason": `${val.value} by ${userRoleReason(role)}`, 
                "actionBy": userRoleAction(role)
            }
        ).then(resp => {
            if (resp.data.status == ("success" || 200)) {
                toast(resp.data.msg || "Application status was changed successfully")
                    if (activeTab.value == 'under_training') {
                        const updatedCallerList = trainingCallersRecords.filter(caller =>
                                    caller.id !== data.id
                        )
                        setTrainingCallersRecords([])
                        setTrainingCallersRecords([...updatedCallerList])
                        setTotalTrainingCallers(totalTrainingCallers - 1)
                    }
            }
        }).catch(err => {
            toast(err.response && err.response.data ? err.response.data.msg : 'Error' )
        })
    }

    const getScriptPathPercentage = (scriptProgress) => {
        let newArray = scriptProgress.map(script => {
            let percentage = Math.ceil((script.successScriptProgressCount / totalScriptPaths) * 100).toFixed(0);
            const obj = {
                percentage,
                language: script.scriptLanguage
            }
            return obj
        })
        return (<ul style={{ padding: '0' }}>
            {newArray.map((e, i) => {
                return <li key={i+1}>{e.language} : {e.percentage > 100 ? 100 : e.percentage}%</li>
            })}
        </ul>)
    }

    const sendMockCallFeedback = () => {
        const getCheckedData = [...checkboxFeedbackData].filter(item => item.checked).map(each => each.label)
        let data = hiringDecision
        data = { ...data, trainingStage: 'mockCall', callReason: 'mockCall', teleproject: props.match.params.id, user: mockCallData[0].user, feedback: getCheckedData && getCheckedData.length > 0 ? getCheckedData.toString() : '' }
        ApiActions.changeUserStatus(data).then(resp => {
            toast('Feedback submitted')
            setShowSelectionModal(false)
            setTableLoading(true)
            fetchCallersBasedOnStage(props.match.params.id, { value: 'ready_for_mock' })
        }).catch(err => console.error(err))
    }

    const showAllTheFeedbacks = (rowData, e) => {
        setCurrentFeedback(rowData.feedbacks && Array.isArray(rowData.feedbacks) ? rowData.feedbacks.map(({text}) => {
            return  text
        }): [])
        setShowFeedbackModal(true)
    }

    const listFeedbacks = (feedbacks) => {
        const feedbackText = feedbacks && feedbacks.length > 0 && feedbacks.map(({text}) => {
            return  text
        })
        let list = '' || 'NA'
        list =
        <div>
            <li>{feedbackText[0]}</li>
            {feedbackText.length >= 2 && <li class='text-muted'>View more</li>}
        </div>
        return <ul style={{ padding: '0' }}>{list}</ul>
    }

    const showCallerHistoryPopup = (callerHistory, e, rowData) => {
        e.stopPropagation()
        setCallerHistory(callerHistory)
        setCallerStatus(rowData)
        setShowCallerAuditModal(true)
    }

    const renderCallerHistoryPopup = () => {
        const reservedRedFlagStatus = ['ban', 'reapply', 'rejected', 'quit']
        const customStatusName = (prevStatus, previousTrainingStage) => {
            let status = prevStatus.toLowerCase()
            switch (status) {
                case 'applied':
                    return 'Audio test'
                    break;
                case 'pending':
                    return 'Quiz passed'
                    break;
                case 'onhold':
                    return 'Hold'
                    break;
                case 'quit':
                    return 'Quit'
                    break;
                case 'completed':
                    return 'Completed'
                    break;
                case 'inprogress':
                    return 'Selected'
                    break;
                case 'iqchold':
                    return 'IQC'
                    break;
                case 'training':
                    if (previousTrainingStage == 'remock')
                        return 'Remock'
                    if (previousTrainingStage == 'iqcTraining')
                        return 'IQC retraining'
                    if (previousTrainingStage == 'qcRetraining')
                        return 'QC retraining'
                    if (previousTrainingStage == 'mockCall')
                        return 'Ready for Mock'
                    else
                        return 'Training'
                    break;
                case 'ban':
                    return 'Caller banned'
                    break;
                case 'reapply':
                    return 'Audio test failed'
                    break;
                case 'rejected':
                    return 'Rejected'
                    break;
                default:
                    return status
                    break;
            }
        }
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal call-history-modal"
                show={showCallerAuditModal}
                closeButtonClicked={() => {
                    setShowCallerAuditModal(false)
                    setCallerStatus('')
                    setCallerHistory('')
                }}
            >
                <div>
                    <div className="heading">Caller history</div>
                    <div className="caller-history">
                        <ul className="events">
                            {
                                callerHistory && callerHistory.length > 0 ?
                                    callerHistory.map(timeline => {
                                        return (
                                            <Fragment>
                                                <li>
                                                    <time data-after-content={`${moment(timeline.updatedAt).format("DD-MM-YYYY")}      [${moment(timeline.updatedAt).format('LT')}]`}></time>
                                                    <span><div className={reservedRedFlagStatus.includes(timeline.previousStatus) ? 'desc red-flag' : 'desc'}>{customStatusName(timeline.previousStatus, timeline.previousTrainingStage)}</div></span>
                                                </li>
                                            </Fragment>
                                        )
                                    }) :
                                    <Fragment>
                                        <div>No history found</div>
                                    </Fragment>
                            }
                        </ul>
                    </div>
                </div>
            </FrappModal >
        )
    }

    const closeFeedbackPopup = () => {
        setShowFeedbackModal(false)
        setCurrentFeedback('')
    }
   
    const getTimeSlotInFormat = (timeSlotObj) => {
        return <ul style={{ padding: '0' }}>
            <li>{moment(timeSlotObj.slotStart).format("Do MMM")}</li>
            <li>{timeSlotObj.slotDetails}</li>
        </ul>
    }

    useEffect(() => {
        if (!props.location.state.projectdata) {
            window.reload('/hiring')
        }
        if (props.match.params.id){
            ApiActions.getTeleProject(props.match.params.id).then(resp => {
                props.location.state.projectdata = resp
            }).then(() => {
                ApiActions.getUsersBasedOnRole('coach').then(resp => {
                    setCoachesData([...resp])
                    if (loggedInUser) {
                        const findCoach = [...resp].find(elem => elem.id == props.location.state.projectdata.coach)
                        if (findCoach) {
                            const isLoggedInMemberCoach = checkIfCoachLoggedIn(loggedInUser.auth, findCoach.role)
                            if (isLoggedInMemberCoach)
                                setCurrentCoach([...resp].find(elem => elem.id == loggedInUser.base))
                            else
                                setCurrentCoach(findCoach)
                        }
                    }
                })
            })
            .then(() => fetchCallersBasedOnStage(props.match.params.id, activeTab))
            .catch(err => console.error(err.response))
        }
    }, [])


    const getTotalCountOfRecordsForMaterialTable = () => {
        ApiActions.getTeleProjectsForAuditCount().then(resp => {
            setTotalCount(resp)
        }).catch(err => console.error(err.response))
    }

    const renderTable = (activeTab) => {
        switch (activeTab) {
            case 'under_training':
                return renderUnderTrainingCallersTable()
                break;
            case 'ready_for_mock':
                return renderReadyForMockCallersTable()
                break;
            default:
                break;
        }
    }

    const exportCsvForTraining = (columns, data) => {
        columns = columns.filter((item, idx) => {
            if(item.title !== 'Status' && item.title !== '' && item.title !== 'Feedback')
            return item
        })
        // Turn headers into array of strings
        const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.children[0];
            }
            return col.title;
          }).filter(Boolean);

         // Turn data into an array of string arrays
        const dataRows = data.map(rowData =>
            columns.map(columnDef => {
                if(columnDef.title == 'IS practice'){
                    return rowData && rowData.scriptProgress && rowData.scriptProgress.length > 0 
                    ? getScriptPathPercentage(rowData.scriptProgress) : 'NA'
                }else if(columnDef.title == 'Training session'){
                const typeOfTraining = [...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift()
                return typeOfTraining == 'trainingVideos' ?
                rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? <img style={{ width: '28px' }} src={require("../../../assets/images/green-tick.svg")} /> : 'Not viewed'
                : rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? 'Attended' : 'Not attended'
                }
                return rowData[columnDef.field]
            })
        );
        // // const { exportDelimiter } = this.selectedTableRef.current.props.options;
        const delimiter = ",";
        const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
        const csvFileName = `Under training table`;
        downloadCsv(csvContent, csvFileName);
    }

    const renderUnderTrainingCallersTable = () => {
        const tableprops = {
            data: trainingCallersRecords,
            columns: underTrainingColumns,
            totalCount: totalCount,
            query: query,
            setQuery: setQuery,
            title: <span className='material-table-title'>Under training callers [{totalTrainingCallers}]</span>,
            searchPlaceHolder: `Search by mobile number`,
            pageChanged: pageChanged,
            setPageChanged: setPageChanged,
            isTableLoading: isTableLoading,
            paginationType: 'client',
            filtering: true,
            search: true,
            exportButton: {
                csv: true,
                pdf: false,
            },
            exportCsv: exportCsvForTraining
        }
        return (
            <div style={{ maxWidth: "100%" }}>
                <Table {...tableprops} />
            </div>
        )
    }

    const downloadCsv = (data, fileName) => {
        const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        a.setAttribute("download", finalFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const exportCsv = (columns, data) => {
        columns = columns.filter((item, idx) => {
            if(item.title !== 'Actions' && item.title !== '' && item.title !== 'Feedback')
            return item
        })
        // Turn headers into array of strings
        const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.children[0];
            }
            return col.title;
          }).filter(Boolean);

         // Turn data into an array of string arrays
        const dataRows = data.map(rowData =>
            columns.map(columnDef => {
                if(columnDef.title == 'Mock type'){
                    let status = rowData.statusChangeLog
                    const mockStatus = status && status && status.length > 0 
                    && status[status.length - 1] &&
                    status[status.length - 1].previousTrainingStage === 'remock'
                    ? 'Remock' : 'Mock'
                    return mockStatus
                }else if(columnDef.title == 'IS practice'){
                    return rowData && rowData.scriptProgress && rowData.scriptProgress.length > 0 
                    ? getScriptPathPercentage(rowData.scriptProgress) : 'NA'
                }else if(columnDef.title == 'Training session/video'){
                const typeOfTraining = [...Object.keys(props.location.state.projectdata)].filter(e => e == 'trainingVideos' || e == 'trainingMeetings').shift()
                return typeOfTraining == 'trainingVideos' ?
                rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? <img style={{ width: '28px' }} src={require("../../../assets/images/green-tick.svg")} /> : 'Not viewed'
                : rowData && rowData.trainingMock && rowData.trainingMock.preparedForMockCall ? 'Attended' : 'Not attended'
                }else if(columnDef.title == 'Time slot'){
                    return rowData && rowData.trainingMock && rowData.trainingMock.preference ?
                    `${moment(rowData.trainingMock.preference.slotStart).format("Do MMM")} [${[rowData.trainingMock.preference.slotDetails]}]`
                    : 'NA'
                }else if(typeof columnDef.title === 'object' && columnDef.title.props.children[0] == 'Mock call'){
                    return rowData && rowData.trainingMock && rowData.trainingMock.preference ? 
                    getMockCallStatusForCSV(rowData.trainingMock.preference, rowData) : 'NA'
                }else if(columnDef.title == 'Feedback'){
                    return 'NA'
                }
                return rowData[columnDef.field]
            })
        );
        // const { exportDelimiter } = this.selectedTableRef.current.props.options;
        const delimiter = ",";
        const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
        const csvFileName = `Ready for mock table`;
        downloadCsv(csvContent, csvFileName);
    }

    const renderReadyForMockCallersTable = () => {      
        return (
            <div style={{ maxWidth: "100%" }}>
                <ReactTable
                columns={columns}
                data={readyForMockCallersRecords}
                totalCount={readyForMockCallersRecords ? readyForMockCallersRecords.length : 0}
                limit={10}
                type = 'client'
                currentPage={currentPage}
                // setCurrentPage={(page) => this.setState({currentPage: page})}
                loading={isTableLoading}
                // error={error}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'any field'}
                title = 'Hiring'
                isExport={true}
                />
            </div>
        )
    }

    const setActiveTabFn = (data) => {
        setActiveTab({
            label: data.label,
            value: data.value
        })
        // this.tableRef.current.onChangePage(null, 0);
        fetchCallersBasedOnStage(props.match.params.id, data)
    }

    const parentFunction = (f, label) => {
        setTimeOfMeeting(f._i)
    }

    const renderRenewMockLinkModal = () => {
        const { trainingMeetings } = props.location.state.projectdata
        const triggerDatePicker = () => {
            setDateFocused(true)
        }

        const setMeetingDate = (date) => {
            let tempStartDate, tempEndDate;
            tempStartDate = date.format('YYYY-MM-DD');
            setDateOfMeeting(tempStartDate)
        }

        const getTheDate = () => {
            if (dateOfMeeting) {
                return moment(new Date(dateOfMeeting))
            }
        }
        const renderFormField = () => {
            if (isTrainingMeeting) {
                const { trainingMeetings } = props.location.state.projectdata
                return <Fragment>
                    <FormControl
                        id="input-edit"
                        className='share-link'
                        required
                        type="text"
                        placeholder='Enter link'
                        value={meetingURL ? meetingURL : ''}
                        onChange={(e) => setMeetingURL(e.target.value)}
                    />
                    <div name="startDate" onClick={() => triggerDatePicker()} id="meetingdate">
                        <SingleDatePicker
                            noBorder={true}
                            placeholder={'Select date'}
                            block={true}
                            date={getTheDate()} // momentPropTypes.momentObj or null
                            onDateChange={e => setMeetingDate(e)} // PropTypes.func.isRequired
                            focused={dateFocused} // PropTypes.bool
                            isOutsideRange={() => false}
                            onFocusChange={({ focused }) => setDateFocused(focused.focused)} // PropTypes.func.isRequired
                            displayFormat="DD/MM/YYYY"
                            numberOfMonths={1}
                            hideKeyboardShortcutsPanel
                            verticalSpacing={0}
                        />
                    </div>
                    <FutworkSetTimer label={''} time={trainingMeetings && trainingMeetings[0].meetingDate ? `${moment(trainingMeetings[0].meetingDate).format("HH:mm")}` : `${moment().format('HH:mm')}`} parentFunction={parentFunction} />
                </Fragment>
            }
        }

        const renderFormButton = () => {
            if (isTrainingMeeting) {
                return <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FrappButton className="submit" id="new-btn" disabled={dateOfMeeting && timeOfMeeting && meetingURL ? false : true} handler={() => generateMeetingLink('meeting')}>
                        CREATE
                    </FrappButton>
                </div>
            }
        }


        return (
            <FrappModal
                className="mock-meeting-link-modal"
                onCloseButton={true}
                bsSize={'small'}
                show={showMockLinkModal}
                closeButtonClicked={() => {
                    setShowMockLinkModal(false)

                    if (!trainingTypeSelected) {
                        setTrainingTypeSelected(false)
                        setIsTrainingVideo(false)
                        setIsTrainingMeeting(false)
                        setMeetingURL('')
                        setVideoTitle('')
                        setDateOfMeeting('')
                        setVideoURL('')
                    }
                }}
            >
                <div className="notif-popup">
                    <div className="heading" style={{ marginBottom: '3%' }}>Create</div>
                    {/* {trainingTypeSelected ? <span className='reset_form' onClick={() => { setTrainingTypeSelected(false); setIsTrainingMeeting(false); setIsTrainingVideo(false) }}>(Go Back)</span> : null} */}
                    <div className='text' style={{ display: 'flex', flexFlow: 'column', gap: '17px' }}>
                        {
                            !trainingTypeSelected ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#636466', fontWeight: '900' }}>
                                        <span style={{ textAlign: 'center' }}>Create link for a group training session</span>
                                        <FrappButton className="submit" id="navy-blue-btn" handler={() => { setIsTrainingMeeting(true); setTrainingTypeSelected(true) }}>
                                            CREATE
                                </FrappButton>
                                    </div>
                                    {/* <div style={{ padding: '4% 0' }}>or</div>
                                    <div style={{ width: '45%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#636466', fontWeight: '900' }}>
                                        <span style={{ textAlign: 'center' }}>Upload a  <br /> training video</span>
                                        <FrappButton className="submit" id="aqua-blue-btn" handler={() => { setIsTrainingVideo(true); setTrainingTypeSelected(true) }}>
                                            UPLOAD
                                </FrappButton>
                                    </div> */}
                                </div>
                                :
                                renderFormField()
                        }
                    </div>
                </div>
                <br />
                {
                    !trainingTypeSelected ? null : renderFormButton()
                }
            </FrappModal>
        )
    }

    const convertISOToLocalDate = (isoDate) => {
        return moment(isoDate).format("YYYY-MM-DD")
    }

    const generateMeetingLink = (type) => {
        let data = {}
        if (type == 'meeting') {
            let date = moment(`${convertISOToLocalDate(dateOfMeeting)} ${timeOfMeeting}`).toISOString()
            let url = meetingURL
            data = {
                trainingMeetings: [
                    {
                        url,
                        meetingDate: date
                    }
                ]
            }
        }

        if (type == 'video') {
            let url = videoURL
            let title = videoTitle
            data = {
                trainingVideos: [
                    {
                        url,
                        title
                    }
                ]
            }
        }
        // let date = moment.utc(moment(`${dateOfMeeting} ${timeOfMeeting}:00`)).format()
        ApiActions.updateTeleProject(data, props.match.params.id).then(resp => {
            if (type == 'meeting') {
                props.location.state.projectdata.trainingMeetings = resp.data.trainingMeetings
            }
            if (type == 'video') {
                props.location.state.projectdata.trainingVideos = resp.data.trainingVideos
            }
            setShowMockLinkModal(false)
        }).catch(err => console.err(err.response))
    }

    const triggerMockCall = () => {
        const { coach, id } = props.location.state.projectdata
  
        const data = {
            "userMobile": callerSelectedForMock.mobile,
            "teleproject": id,
            "callReason": 'mockCall',
            "teamCallAccount": "ops"
        }
        
        ApiActions.placeMockCall(data).then(resp => {
            closeConfirmCallModal()
            toast(`Call being placed to ${callerSelectedForMock.firstname} (${callerSelectedForMock.mobile})`)
        }).catch(err => {
            console.error(err.response)
        })
    }

    const closeShowSelectionModal = () => {
        const role= loggedInUser.auth
        setShowSelectionModal(false)
        setCheckboxFeedbackData([])
        setHiringDecision({
            "status": '',
            "trainingStage": '',
            "actionBy": userRoleAction(role),
            "reason": '',
            "feedback": '',
            'callReason': ''
        })
    }

    const storeHiringDecision = (val, checkboxFeedbackData) => {
        setHiringDecision({...hiringDecision, 'trainingStage': activeTab.value, 'status': val.value, 'reason': `${val.value == 'training' ? 'remock' : val.value} by ${userRoleReason(loggedInUser.auth)}`, 'callReason': activeTab.value})
        setCheckboxFeedbackData(checkboxFeedbackData)
    }

    const selectFeedbacks = (item) => {
        let checkedFeedbacks = checkboxFeedbackData.map(elem => {
            if (elem.id == item.id) {
                elem.checked = !elem.checked
            }
            return elem
        })
        setCheckboxFeedbackData(checkedFeedbacks)
    }

    const renderPage = () => {
        const goBack = () => {
            props.history.push("/hiring")
        }
        return (
            <div className="job-applications">
                <ProjectTitle title={title} goBack={goBack} />
                <div className="bulk-status-row">
                    <div className="section-1">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <RouteTab tabType='multi' tabArray={tabs} activeTab={tabs[1]} />
                            <RouteTab tabType='single' tabArray={selectedCallerTab} activeTab={''} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '30%', justifyContent: `${activeTab.value == 'under_training' ? 'space-between' : 'flex-end'}` }}>
                        {
                            currentCoach ?
                                <div className='coach-section'>
                                    <Fragment><span className='label-heading'>Calls placed by:</span><span className='coach-name'>{currentCoach.firstname} ({currentCoach.mobile})</span></Fragment>
                                </div> :
                                <div className='coach-section-none'></div>
                        }
                        {
                            activeTab.value == 'under_training' ?
                                <FrappButton className='renew-mock-link' handler={() => setShowMockLinkModal(true)}>Renew Link</FrappButton>
                                : null
                        }
                    </div>
                </div>
                {renderTable(activeTab.value)}
                {showMockLinkModal ? renderRenewMockLinkModal() : null}
                {showSelectionModal ? renderSelectionPop(showSelectionModal, closeShowSelectionModal, mockCallData, storeHiringDecision, hiringDecision, checkboxFeedbackData, selectFeedbacks, sendMockCallFeedback, activeTab) : null}
                {confirmCallModal ? renderConfirmCallModal(confirmCallModal, closeConfirmCallModal, triggerMockCall) : null}
                {showFeedbackModal ? renderAllFeedbackPopup(showFeedbackModal, closeFeedbackPopup, currentFeedback, 'mockCall') : null}
                {showCallerAuditModal ? renderCallerHistoryPopup() : null}
                <ToastContainer draggable={true} />
            </div>
        )
    }
    return renderPage()
}

export default JobApplicationsHiring