import React, {useState, useRef, useEffect} from 'react'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ApiActions from '../../../actions/ApiActions.jsx'
import { SingleDatePicker } from "react-dates";
import { Multiselect } from 'multiselect-react-dropdown';
import Select, { components } from 'react-select';
import moment from 'moment'
import { ToastContainer, toast } from "react-toastify";
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
//custom imports
import FrappButton from '../../../components/utilities/FrappButton';
import {PlusIcon, DeleteIcon} from '../../../assets/images'
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import {getProjectReports, getCallerReports, getUnprocessedReports,getAttemptedLeadsReports} from './service'
import {getQmfCallsAuditReport} from '../../audit/qmf/service'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import Datepicker from '../../../uicomponents/date-picker/datepicker';
import { defaultTheme } from "react-select";
import emailHelper from '../../../helper/email.js';

const callStatus = [
    {name: 'DND', value: 'dnd'},
    {name: 'Invalid', value: 'invalid'},
    {name: 'Duplicate', value: 'duplicate'}
]

const HISTORIC_PROJECT_REPORT = 'historic_project_report'
const REGULAR_REPORT = 'project_report'
const { colors } = defaultTheme;

const DownloadReport = (props) => {
    // console.log(moment(props.data.endDate.split('T')[0]).toDate())
    // if(props.data && props.data.endDate && moment(props.data.endDate.split('T')[0]).toDate()){
    //     console.log('Y', moment().toDate() > moment(props.data.endDate.split('T')[0]).toDate() ? 
    //     moment(props.data.endDate.split('T')[0]).toDate() : moment().toDate())
    // }

    const getDate = () => {
        if(props.data && props.data.endDate){
            return moment().toDate() > moment(props.data.endDate.split('T')[0]).toDate() ? 
            moment(props.data.endDate.split('T')[0]).toDate() : moment().toDate()
        }
    }
    let emailRef = useRef()
    let submitBtnReport = useRef('')

    const [emailArray, setEmailArray] = useState([])
    const [reportSent, setReportSent] = useState(false)
    const [outcomesArrayToSend, setOutcomesArrayToSend] = useState([])
    const [callStatusToSend, setCallStatusToSend] = useState([])
    const [startDateFocussed, setStartDateFocussed] = useState(false)
    const [endDateFocussed, setEndDateFocussed] = useState(false)
    const [reportError, setReportError] = useState({
        status: false,
        msg: ''
    })
    const [invalidEmailError, setinvalidEmailError] = useState(false)
    // const [reportStartDate, setReportStartDate] = useState(props.data ? props.data.endDate && 
    //     moment().toDate() > moment(props.data.endDate.split('T')[0]).toDate() ? moment(props.data.endDate.split('T')[0]).toDate() :
    //     moment().toDate
    //     : moment().toDate())
    const [reportStartDate, setReportStartDate] = useState(getDate())
    // const [reportEndDate, setReportEndDate] = useState(props.data ? props.data.endDate && 
    //     moment().toDate() > moment(props.data.endDate.split('T')[0]).toDate() ? moment(props.data.endDate.split('T')[0]).toDate() :
    //     moment().toDate
    //     : moment().toDate())
    const [reportEndDate, setReportEndDate] = useState(getDate())
    const [reportType, setReportType] = useState(REGULAR_REPORT)
    const [headers, setHeaders] = useState([])
    const [selectedHeaders, setSelectedHeaders] = useState([])

    const [isDropdownDisabled, setIsDropdownDisabled] = useState(true)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMenuIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchAndSetHeaders = async () => {
            try {
                const headersFetched = await ApiActions.fetchNormalizedHeadersByProjectId(props.data.id)
                const groupedOptions = Object.keys(headersFetched).map(headerType => ({
                    label: headerType,
                    options: headersFetched[headerType].map(header => ({
                        category: headerType,
                        label: header,
                        value: header
                    }))
                }));

                const totalHeaderCount = groupedOptions && groupedOptions.flatMap(group => group.options).length ?
                groupedOptions.flatMap(group => group.options).length : 0
                setHeaders(groupedOptions)
                // disable dropdown if no options are available
                setIsDropdownDisabled(totalHeaderCount <= 0 ? true : false)
            } catch (error) {
                console.error("Error fetching normalized headers:", error)
            }
        }

        fetchAndSetHeaders()
    }, [props.data.id])

    useEffect(() => {
        if (headers.length > 0) {
            setSelectedHeaders(headers.flatMap(group => group.options))
        }
    }, [headers])

    const CheckboxOption = (props) => {
        return (
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    readOnly
                    style={{ marginRight: 10 }}
                />
                {props.label}
            </components.Option>
        );
    };

    const CustomGroupHeading = (props) => {
        return (
            <components.GroupHeading {...props}>
                <div style={{
                    fontWeight: 'bolder',  // Makes the text bold
                    color: 'black',       // Changes text color to black
                    padding: '8px 0px 8px 0px',  // Adds padding
                }}>
                    {props.children}
                </div>
            </components.GroupHeading>
        );
    };

    const totalHeaderCount = headers && headers.flatMap(group => group.options).length ?
    headers.flatMap(group => group.options).length : 0
    const selectHeaderCount = selectedHeaders && selectedHeaders.length > 0 ? 
    selectedHeaders && selectedHeaders.length : 0

    const CustomInput = (props) => {
        const totalHeaderCount = headers && headers.flatMap(group => group.options).length ?
        headers.flatMap(group => group.options).length : 0
        const selectHeaderCount = selectedHeaders && selectedHeaders.length > 0 ? 
        selectedHeaders && selectedHeaders.length : 0
        return (
        <components.Input
            {...props}
            placeholder={!isDropdownDisabled ? `${selectHeaderCount} / ${totalHeaderCount} headers selected`:''}
            style={{
                display:'block',
                border: 'none',
                boxSizing: 'border-box',
                margin: '0',
                fontSize: '14px',
            }}
        />
    )}

    const orginalStartDate = props.data.startDate  ? props.data.startDate && props.data.startDate.split('T')[0] : ''
    const originalEndDate = props.data.endDate ? props.data.endDate && props.data.endDate.split('T')[0] : ''
    let app;
    if(props.data && props.data.outcomes){
        app = props.data.outcomes.map((item, id)=>{
            return ({
                "id": id,
                "outcome": item.title
            })
        })
    }

    const triggerStartDatePicker = () => {
        setStartDateFocussed(true)
    };

    const triggerEndDatePicker = () => {
        setEndDateFocussed(true)
    };

    let startDate = props.data.defaultStartDate
    ? moment(new Date(props.data.defaultStartDate))
    : null;
    let endDate =props.data.defaultEndDate
    ? moment(new Date(props.data.defaultEndDate))
    : null;


    const addEmail = (email) => {

        if(!emailHelper.hasOnlyFutworkEmails([email])) {
            setinvalidEmailError(true)
            return
        }

        emailRef.current.value = ''
        const updateArray = [...emailArray, email]
        setEmailArray(updateArray)
    }

    const removeEmail = (idx) => {
        let updatedEmailArray = [...emailArray]
        updatedEmailArray.splice(idx, 1)
        setEmailArray([...updatedEmailArray])
    }

    const setReportDate= (date,start) => {
        let tempStartDate,tempEndDate
        if(!date){
            return false
        }
        if (start) 
        {     tempStartDate = date.format('YYYY-MM-DD');
              props.data.defaultStartDate = tempStartDate
        }
        if (!start) 
        {     tempEndDate = date.format('YYYY-MM-DD');
            props.data.defaultEndDate = tempEndDate
        }
    }

    const chooseReport = (type) => {
        switch (type) {
            case 'Caller Report':
                return sendReport('caller_report')
            case 'Project Report':
                return sendReport('project_report')
            case 'Unprocessed Leads Report':
                return sendReport('unprocessed_leads_report')
            case 'Live Leads Report':
                return sendReport('live_leads_report')
            case 'IQC Audit Report':
                return sendReport('iqc_audit_report')
            case 'Performance Audit Report':
                return sendReport('performance_audit_report')
            default:
                return sendReport('project_report')
        }
    }

    const sendReport= async(type)=>{
        let {id, defaultStartDate, defaultEndDate} = props.data ;
        let dataToSend;

        if(type === 'project_report'){  
            if(emailArray.length === 0) {
                dataToSend = {
                    "teleproject": id,
                    "startDate": moment(reportStartDate).format('YYYY-MM-DD'),
                    "endDate": moment(reportEndDate).format('YYYY-MM-DD'),
                    "outcomes": outcomesArrayToSend
                }
            }else{
                dataToSend = {
                    "teleproject": id,
                    "startDate": moment(reportStartDate).format('YYYY-MM-DD'),
                    "endDate": moment(reportEndDate).format('YYYY-MM-DD'),
                    "outcomes": outcomesArrayToSend,
                    "emails": JSON.stringify(emailArray)
                }
            }
            if(reportType == HISTORIC_PROJECT_REPORT){
                dataToSend.isLite = true
                dataToSend.visibleHeaders = selectedHeaders.reduce((result, {category, value}) => {
                    result[category] ? result[category].push(value) : result[category] = [value]
                    return result
                }, {})
            }
        }else if (type === 'caller_report'){
            if(emailArray.length === 0) {
                dataToSend = {
                    "teleproject": id,
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate,
                }
            }else{
                dataToSend = {
                    "teleproject": id,
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate,
                    "emails": JSON.stringify(emailArray)
                }
            }
        }else if(type === 'unprocessed_leads_report'){
            if(emailArray.length === 0) {
                dataToSend = {
                    "teleproject": id,
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate
                }
                if(callStatusToSend.length > 0){
                    dataToSend.callstatus = callStatusToSend
                }
            }else{
                dataToSend = {
                    "teleproject": id,
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate,
                    "emails": JSON.stringify(emailArray)
                }
                if(callStatusToSend.length > 0){
                    dataToSend.callstatus = callStatusToSend
                }
            }
        }else if(type === 'live_leads_report'){
            if(emailArray.length === 0) {
                dataToSend = {
                    "teleproject": id,
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate
                }
            }else{
                dataToSend = {
                    "teleproject": id,
                    "emails": JSON.stringify(emailArray),
                    "startDate": defaultStartDate,
                    "endDate": defaultEndDate,
                }
            }
        }else if(type === 'iqc_audit_report'){
            dataToSend = {
                "auditType": "iqc",
                "startDate": defaultStartDate,
                "endDate": defaultEndDate,
                "emails": JSON.stringify(emailArray)
            }
        }else if(type === 'performance_audit_report'){
            dataToSend = {
                "auditType": "performanceAudit",
                "startDate": defaultStartDate,
                "endDate": defaultEndDate,
                "emails": JSON.stringify(emailArray)
            }
        }

        submitBtnReport.current.startLoading()

        let result = ''

        const checkIfReportSent = (result) => {
            if(result) setReportSent(true)
            else return false
        }

        try{
            if(type === 'project_report'){
                result = await getProjectReports(dataToSend)
                checkIfReportSent(result)
            }else if(type === 'caller_report'){
                result = getCallerReports(dataToSend)
                checkIfReportSent(result)
            }else if(type === 'unprocessed_leads_report'){
                result = getUnprocessedReports(dataToSend)
                checkIfReportSent(result)
            }else if(type === 'live_leads_report'){
                result = getAttemptedLeadsReports(dataToSend)
                checkIfReportSent(result)
            }else if(type === 'iqc_audit_report'){
                result = getQmfCallsAuditReport(dataToSend)
                checkIfReportSent(result)
            }else if(type === 'performance_audit_report'){
                result = getQmfCallsAuditReport(dataToSend)
                checkIfReportSent(result)
            }
        }catch(err){
            console.error((err.response && err.response.data)|| "Unexpected Error!")
            if(type === 'project_report'){
                checkIfReportSent('')
                if(err && err.response && err.response.data && err.response.data.code === 'MUL_REP_REQ_ERR'){
                    setReportError({
                        status: true,
                        msg: err.response.data.msg
                    })
                }
                return true
            }
            if(err)
            toast(handleErrorMessage(err))
        }finally{
            submitBtnReport && submitBtnReport.current && submitBtnReport.current.stopLoading()
        }
    }

    // styled components

    const Menu = (props) => {
        const shadow = "hsla(218, 50%, 10%, 0.1)";
        return (
        <div
            css={{
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
            marginTop: 8,
            position: "absolute",
            zIndex: 2,
            }}
            {...props}
        />
        );
    };
    const Blanket = (props) => (
        <div
        css={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: "fixed",
            zIndex: 1,
        }}
        {...props}
        />
    );
    const Dropdown = ({ children, isOpen, target, onClose }) => (
        <div css={{ position: "relative" }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
        </div>
    );

    const toggleOpen = () => {
        setMenuIsOpen(!menuIsOpen)
    };

    const selectStyles = {
        control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
        menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
      };

      const Svg = (p) => (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          role="presentation"
          {...p}
        />
      );
      const DropdownIndicator = () => (
        <div css={{ color: colors.neutral20, height: 2, width: 32 }}>
          <Svg>
            <path
              d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </Svg>
        </div>
      );
      const ChevronDown = () => (
        <Svg style={{ marginRight: -6 }}>
          <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </Svg>
      );
    return(
        <div className="report-modal">
        <div className='project-title'>Project : {props && props.data && props.data.title}</div>
        {props && props.type ? <div className='report-type'>
            <span className='heading'>Report type: </span>
        <DropdownButton
        bsSize="small"
        title={reportType == HISTORIC_PROJECT_REPORT ? 'Historic report' : 'Regular report'}
        id="dropdown-size-small"
        >
        <MenuItem eventKey={HISTORIC_PROJECT_REPORT} onSelect={(e) => {
            setReportEndDate(getDate())
            setReportStartDate(getDate())
            setReportType(e)
        }}>Historic report</MenuItem>
        <MenuItem eventKey={REGULAR_REPORT} onSelect={(e) => {
             setReportEndDate(getDate())
             setReportStartDate(getDate())
             setReportType(e)
            }}>Regular report</MenuItem>
        </DropdownButton>
        </div> : null}
        {
            reportType == HISTORIC_PROJECT_REPORT ? 
            <div ref={dropdownRef}>
                <div className='report-type'>
                <span className='heading'>Choose headers to display in the report</span>
                </div>
                {/* <Dropdown
                isOpen={menuIsOpen}
                onClose={toggleOpen}
                target={
                <Button
                iconAfter={<ChevronDown />}
                onClick={toggleOpen}
                isSelected={menuIsOpen}
                >
                {`headers selected`}
                </Button>
                }
                ></Dropdown> */}
                <div className="headers-for-report">
                <DropdownButton
                disabled={isDropdownDisabled ? true : false}
                title={isDropdownDisabled ? `No Headers Available` : `${selectHeaderCount} / ${totalHeaderCount} headers selected`}
                >
                    <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                    Option: CheckboxOption,
                    GroupHeading: CustomGroupHeading,
                    // Input: CustomInput,
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={(selectedOptions) => {
                        setSelectedHeaders(selectedOptions);
                    }}
                    options={headers}
                    placeholder="Search..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={selectedHeaders}
                    isDisabled={isDropdownDisabled} // Disable dropdown if no headers
                    isSearchable={!isDropdownDisabled} // Disable search input if no headers
                    isMulti
                    />
                </DropdownButton>
                </div>
                {/* <Select
                        options={headers}
                        value={selectedHeaders}
                        onChange={(selectedOptions) => {
                            setSelectedHeaders(selectedOptions);
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        backspaceRemovesValue={false}
                        placeholder="Searches..."
                        isMulti
                        isDisabled={isDropdownDisabled} // Disable dropdown if no headers
                        isSearchable={!isDropdownDisabled} // Disable search input if no headers
                        // placeholder={isDropdownDisabled ? "No Headers Available":''}
                        menuIsOpen={menuIsOpen && !isDropdownDisabled}
                        onMenuOpen={() => !isDropdownDisabled && setMenuIsOpen(true)}
                        closeMenuOnSelect={false} // Keeps the menu open after selection
                        hideSelectedOptions={false} // Shows selected options in the dropdown
                        components={{ Option: CheckboxOption, 
                            // Input: CustomInput, 
                            GroupHeading: CustomGroupHeading }} // Use custom option component
                        styles={{
                            group: (provided) => ({
                                ...provided,
                                fontweight: 'bolder',
                                color: 'black'
                            }),
                            control: (provided) => ({
                                ...provided,
                                width: '250px',
                                maxWidth: '250px'
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                padding: 0,
                                width: '100%',
                                display: 'flex',
                                justifyContent:'center',
                                alignItems: 'center',
                                flexWrap: 'nowrap'                 
                            }),
                            input: (provided) => ({
                                ...provided,
                                margin: 0,
                                boxSizing: 'border-box',
                                width: '100%',
                                flexGrow: 1
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                display: 'none', // Hides selected chips from the input box
                            }),
                            clearIndicator: (provided) => ({
                                ...provided,
                                display: 'none', // Hides the clear icon
                            }),
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                cursor:'pointer',
                                // paddingLeft: 0, // Adjust padding
                                // marginRight: 8, // Space from the input
                            }),
                        }}
                    /> */}
            </div> : ''
        }
        <div className={styles.email_section}>
            <div className={styles.pill_wrapper}>
                {emailArray.map((email, idx) => {
                return <div className={styles.pill} key={idx}>
                {email}
                <div className={styles.del_icon}>
                    <img src={DeleteIcon} alt='delete-icon' onClick={() => removeEmail(idx)}/>
                </div>
                </div>;
                })}
            </div>
            <div className={styles.email_field}>
                <div className={styles.email_field_row}>
                <div className={styles.email_text}>
                <FormGroup>
                <input  type='email' placeholder="Enter email address & press enter" name="email"  ref={emailRef} onKeyDown={(e) => {
                    if(e.key === 'Enter' && e.target.value !== '')
                    addEmail(e.target.value)
                }}   onChange={(e) => { setinvalidEmailError(false) }}/>
                </FormGroup>
                </div>
                <img src={PlusIcon} alt='plus_icon' onClick={(e)=> {
                    if(emailRef.current.value !== '') {
                        addEmail(emailRef.current.value)
                    }
                }}/>
                </div>
                {invalidEmailError ? <div className={styles['error-message']}>Invalid email domain. Please use your futwork.com email.</div> : ''}
            </div>
        </div>
          <div>
            {
                props.data.reportType == 'Project Report' ? 
                <div className="date-report">
                <div name="startDate" id="startdate">
                    {
                        reportType == HISTORIC_PROJECT_REPORT ? 
                        <Datepicker
                        dateFormat="dd/MM/yyyy"
                        selectedDate={reportStartDate}
                        placeholderText='Select start date'
                        // minDate={moment(orginalStartDate).toDate()}
                        //the start date range for a historic report can be fetched from the 1st Jan of the current year
                        minDate={moment().startOf('year').toDate()}
                        maxDate={moment().toDate() > moment(originalEndDate).toDate() ? 
                        moment(originalEndDate).toDate() : moment().toDate()}
                        onChange={date => {
                        setReportStartDate(date)
                        setReportEndDate(moment(date).add(90,'days').toDate() > moment(originalEndDate).toDate() ?
                        moment(originalEndDate).toDate() : moment(date).add(90,'days').toDate())
                        }}
                        />
                        :
                        <Datepicker
                        dateFormat="dd/MM/yyyy"
                        selectedDate={reportStartDate}
                        placeholderText='Select start date'
                        // minDate={moment(orginalStartDate).toDate()}
                        minDate={moment().startOf('year').toDate()}
                        maxDate={moment().toDate() > moment(originalEndDate).toDate() ? 
                        moment(originalEndDate).toDate() : moment().toDate()}
                        onChange={date => {
                        setReportStartDate(date)
                        setReportEndDate(moment(date).add(90,'days').toDate() > moment(originalEndDate).toDate() ?
                        moment(originalEndDate).toDate() : moment(date).add(90,'days').toDate())
                        }}
                        />
                    }
                  <div className="info">Start Date</div>
                </div>
                <div className="endDate" id="enddate">
                    {
                        reportType == HISTORIC_PROJECT_REPORT ? 
                        <Datepicker
                        dateFormat="dd/MM/yyyy"
                        selectedDate={reportEndDate}
                        placeholderText='Select start date'
                        minDate={moment(reportStartDate).toDate() > moment(orginalStartDate).toDate() ? moment(reportStartDate).toDate() : moment(orginalStartDate).toDate()}
                        // maxDate={moment().toDate() > moment(originalEndDate).toDate() ? moment(originalEndDate).toDate() : moment().toDate()}
                        // maxDate={moment().toDate() <= moment(originalEndDate).toDate() ?
                        // moment(reportStartDate).add(90,'days').toDate() : moment(originalEndDate).toDate()}
                        //the max date range for the end date is 3 months in case of historic report
                        maxDate={moment(reportStartDate).add(90,'days').toDate()}
                        onChange={date => {
                        setReportEndDate(date)
                        if(!reportStartDate) setReportStartDate(moment(date).subtract(30,'days').toDate())
                        }}
                        /> 
                        :
                        <Datepicker
                        dateFormat="dd/MM/yyyy"
                        selectedDate={reportEndDate}
                        placeholderText='Select start date'
                        minDate={moment(reportStartDate).toDate() > moment(orginalStartDate).toDate() ? moment(reportStartDate).toDate() : moment(orginalStartDate).toDate()}
                        // maxDate={moment(reportStartDate).add(30,'days').toDate() > moment(originalEndDate).toDate() ? moment(originalEndDate).toDate() : moment(reportStartDate).add(30,'days').toDate()}
                        maxDate={moment(reportStartDate).add(90,'days').toDate()}
                        onChange={date => {
                        setReportEndDate(date)
                        if(!reportStartDate) setReportStartDate(moment(date).subtract(30,'days').toDate())
                        }}
                        />
                    }
                  <div className="info">End Date</div>
                </div>
              </div>
              :
              <div className="date-report">
              <div name="startDate" onClick={() => triggerStartDatePicker()} id="startdate">
                <SingleDatePicker noBorder={true} id="startDateReport" date={startDate} focused={startDateFocussed} onFocusChange={(focused) => {
                    setStartDateFocussed(focused.focused)
                  }} placeholder="Select Start Date" onDateChange={(e) => setReportDate(e, 1)} displayFormat="DD/MM/YYYY" 
                 isOutsideRange={date => orginalStartDate ? (date.isBefore(orginalStartDate, 'day') || date.isAfter(originalEndDate, 'day')) : ''} 
                 numberOfMonths={1}/>
                <div className="info">Start Date</div>
              </div>
              <div className="endDate" onClick={() => triggerEndDatePicker()} id="enddate">
                <SingleDatePicker noBorder={true} id="endDateReport" date={endDate} focused={endDateFocussed} onFocusChange={(focused) => {
                    setEndDateFocussed(focused.focused)
                  }} placeholder="Select End Date" onDateChange={(e) => setReportDate(e, 0)} displayFormat="DD/MM/YYYY" 
                 isOutsideRange={date => orginalStartDate ? (date.isBefore(orginalStartDate, 'day') || date.isAfter(originalEndDate, 'day')) : ''} numberOfMonths={1}/>
                <div className="info">End Date</div>
              </div>
            </div>
            }
            <p>
             Remember to press the green plus-icon before you click on 'Send Report'
             <br />
                 If you enter no email the report will be sent to
                 the default email list.
             </p>
          </div>
         {['Project Report','Unprocessed Leads Report'].includes(props.data.reportType) ?
          <div className="multiselect-dropdown">
          {props.data.reportType == 'Project Report' ?
          <Multiselect 
             options={props.data.outcomes} 
             displayValue="title"
             closeIcon="cancel"
             onSelect={(e)=>{
                let value = e.map((item)=>item.title)
                setOutcomesArrayToSend(value)
             }}
             onRemove={(e) => {
                let value = e.map((item)=>item.title)
                setOutcomesArrayToSend(value)
             }} // Function will trigger on remove event
         />
         : 
         <Multiselect 
             options={callStatus} 
             placeholder='Select call status'
             closeIcon="cancel"
             displayValue="name"
             onSelect={(e)=>{
                let value = e.map((item)=> item.value)
                setCallStatusToSend(value)
             }}
         />
         }
          </div> : null}
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          {reportSent ? <div className="success-banner">
              Preparing your report - you will receive it on email shortly.Here is some <a href="http://www.allolo.ru/" target="_blank" rel="noopener noreferrer">
                Relaxing Music
              </a> to listen to till then.
              <FrappButton className="submit" id="download-report" type="submit" disabled={emailArray && emailArray.length > 0 ? false : true} 
              handler={() => setReportSent(false)} 
              ref={submitBtnReport}
               >
                Send Again
              </FrappButton>
            </div> : reportError.status ? <div className={styles['error-message']}>{reportError.msg}</div> : <FrappButton className="submit" id="download-report" type="submit"
            disabled={emailArray && emailArray.length > 0 && startDate !== ('' || null) && endDate !== ('' || null) ? false : true}
            handler={() => 
                chooseReport(props.data.reportType)
            } 
            ref={submitBtnReport}
            >
              Send Report
            </FrappButton>}
        </div>
        <ToastContainer draggable={true} />
      </div>
    )
}

export default DownloadReport