import React from 'react'
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'
import CustomTooltip from '../../../components/utilities/ToolTip'

const TrainingMeeting = ({ meetings }) => {
    return <ul>
        {
            meetings && meetings.length > 0 ?
            meetings.map((meeting) => {
                return <div className={styles.meeting_name_wrapper}>
                    <CustomTooltip placement='bottom' description={meeting.attendance === 'present'  ? 'Present' : meeting.attendance === 'absent' ? 'Absent' : 'Unmarked attendance'}
                    component={
                        <div className={[styles.dot,
                            meeting.attendance === 'present'  ? styles.blue : meeting.attendance === 'absent' ? styles.red : null
                        ].join(' ')}></div>
                    }
                    />
                    <li>{meeting.title}</li>
                </div>
            })
            : <span style={{textAlign: 'center'}}>NA</span>
        }
    </ul>
    
    
}

export default React.memo(TrainingMeeting)