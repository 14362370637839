import React, { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { TrainingSessionContext, TrainingSessioProvider } from './trainingSessionContext'
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'
import ProjectTitle from "../../../components/common/ProjectTitle";
import FutworkButton from "../../../uicomponents/button/Button";
import Table from "../../../uicomponents/table/Table";
import CreateSession from "./createSession";
import MarkAttendance from "./markAttendance";
import Popup from "../../../uicomponents/popup";
import moment from 'moment'
import { UserAttendance } from '../../../assets/images'
import TruncateString from "../../../helper/TruncateString";
import { ToastContainer, toast } from "react-toastify";
import { getSessionsOfProject, getTeleProject } from './service'
import useApi from "../../../hooks/useApi";
import CustomTooltip from "../../../components/utilities/ToolTip";
import handleErrorMessage from "../../../helper/handleErrorMessage";

const MainPage = (props) => {
    const getSessionsOfProjectApi = useApi(getSessionsOfProject)
    const getTeleProjectApi = useApi(getTeleProject)

    const [currentPage, setCurrentPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const {
        projectData, setProjectData,
        isCreateSession, setIsCreateSession,
        isMarkAttendance, setIsMarkAttendance,
        stepCount, setStepCount,
        selectedRow, setSelectedRow
    } = useContext(TrainingSessionContext)

    useEffect(() => {
        const { location } = props
        const { state } = location
        if(!state){
            getTeleProjectApi.request(props.match.params.id)
        }
        if (state && state.projectdata) {
            setProjectData(state.projectdata)
        }

        getSessionsOfProjectApi.request({
            teleprojectId: props.match.params.id
        })
    }, [])


    const updateList = (newList = '') => {
        getSessionsOfProjectApi.request({
            teleprojectId: props.match.params.id
        })
    }

    const renderCreateSession = () => {
        const { title } = selectedRow || {}
        return (
            <Popup
                show={isCreateSession}
                closePopup={() => {
                    setIsCreateSession(false)
                    setStepCount(1)
                    setSelectedRow([])
                }}
                size='md'
                heading={stepCount == 1 ? 'Create new training session' : `Invite for - ${title}`}
            >
                <CreateSession updateList={updateList} />
            </Popup>
        )
    }

    const renderMarkAttendance = () => {
        const { title } = selectedRow || {}
        return (
            <Popup
                show={isMarkAttendance}
                closePopup={() => {
                    setIsMarkAttendance(false)
                    setSelectedRow([])
                }}
                size='md'
                heading={`Mark Attendance for - ${title}`}
            >
                <MarkAttendance />
            </Popup>
        )
    }

    const handlePopups = () => {
        if (isCreateSession) return renderCreateSession()
        if (isMarkAttendance) return renderMarkAttendance()
    }

    const columns = [
        {
            Header: 'Session title',
            accessor: 'title',
            Cell: ({ cell }) => {
                const { value } = cell || {}
                return <span>{Number(cell.row.index) + 1}.&nbsp;&nbsp;{value || 'NA'}</span>
            },
            width: '30%',
            disableSortBy: true
        },
        {
            Header: 'Scheduled at',
            accessor: 'meetingDate',
            Cell: ({ cell }) => {
                const { value } = cell || {}
                return <span> {moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')} </span>
            },
            width: '15%',
            disableSortBy: true
        },
        {
            Header: 'Agents',
            accessor: 'count',
            Cell: ({ cell }) => {
                const { value } = cell || {}
                return <CustomTooltip placement={'bottom'} description={'Count of agent/s invited for the session'}
                    component={<span>{value || 0}</span>} />
            },
            width: '10%',
        },
        {
            Header: 'Meet URL',
            accessor: 'url',
            Cell: ({ cell }) => {
                const { value } = cell || {}
                return <CustomTooltip placement={'bottom'} description={'Click on this link to open the meeting link in a new tab'}
                    component={<a href={value} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>{value}</a>} />
            },
            width: '25%',
            disableSortBy: true
        },
        {
            Header: <div>{'Attendance'}</div>,
            accessor: 'attendance',
            Cell: ({ cell }) => {
                const { row: { original } } = cell || {}
                return <CustomTooltip placement={'bottom'} description={'Mark the attendance for the session'}
                    component={<span className={styles.session_column_center} onClick={() => {
                        setSelectedRow(original)
                        setIsMarkAttendance(true)
                    }}>
                        <img src={UserAttendance} className={styles.user_attendance_icon} />
                    </span>} />
            },
            width: '8%',
            disableSortBy: true
        },
        {
            Header: <div>{'Add invites'}</div>,
            accessor: 'add',
            Cell: ({ cell }) => {
                const { row: { original } } = cell || {}
                return <CustomTooltip placement={'bottom'} description={'Add agents for inviting them to the session'}
                    component={
                        <div className={styles.session_column_center} onClick={() => {
                            setSelectedRow(original)
                            setIsCreateSession(true)
                            setStepCount(2)
                        }}>
                            <FontAwesomeIcon icon={faPlus} className={styles.add_invite_icon} />
                        </div>
                    }
                />
            },
            width: '7%',
            disableSortBy: true
        }
    ]

    const { data, loading, error } = getSessionsOfProjectApi || {}

    const { data:projectDetails, loading: projectDetailsLoading, error: projectDetailsError } = getTeleProjectApi || {}

    useEffect(() => {
        if(projectDetailsLoading !== true && projectDetails !== null){
            setProjectData(projectDetails)
        }

        if(projectDetailsError){
            toast(handleErrorMessage(projectDetailsError))
        }
    },[projectDetails, projectDetailsLoading, projectDetailsError])

    return (
        <div className={styles.page_wrapper}>
            <div className={styles.training_session_top_row}>
                <ProjectTitle title={`Training sessions for ${projectData && TruncateString(projectData.title, 70)}`}
                    goBack={() => {
                        props.history.push({
                            pathname: `/hiring/applied-callers/${props.match.params.id}`,
                            state: {
                                projectdata: props && props.location && props.location.state ? props.location.state.projectdata : projectData
                            }
                        })
                    }
                    }
                />
                <FutworkButton buttonSize='sm' buttonStyle='primary--solid'
                    onClick={() => { setIsCreateSession(true) }}>+ Create session</FutworkButton>
            </div>

            <Table
                columns={columns}
                data={data}
                loading={loading}
                totalCount={data ? data.length : 0}
                limit={limit}
                type='client'
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                error={error}
                height='60'
                isGlobalFilter={true}
                searchBy={'session by title/url'}
                manualSortBy={false}
            />
            {handlePopups()}
        </div>
    )
}


const TrainingSession = (props) => {
    return <TrainingSessioProvider>
        <MainPage {...props} />
        <ToastContainer />
    </TrainingSessioProvider>
}

export default TrainingSession