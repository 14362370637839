import React, {useEffect, useState} from 'react'
import Tab from '../../uicomponents/tab'
import withTabGroup from '../../hocs/withTabGroup'
import SmsTemplate from './SmsTemplate'
import WhatsappTemplate from './WhatsappTemplate'
import ApiActions from '../../actions/ApiActions';
import GoogleSheetTemplate from './GSheetTemplate'

const TABS = [
    'SMS template',
    'Whatsapp template',
    'G-Sheet template'
]

const buttonId = ['1', '2', '3']

const Communication = (props) => {
    const {formData} = props.location.state
    const TabGroup = withTabGroup(Tab, 'group');
    const [activeTab, setActiveTab] = useState(TABS[0])

    const [activeBtn, setActiveBtn] = useState(buttonId[0])

    const switchBtn = (number) => {
        setActiveBtn(buttonId[number-1])
    }
    // const [projectData, setProjectData] = useState('')

    // useEffect(() => {
    //     let id = props.match.params.id
    //     getTeleprojectdata(id)
    // }, [])

    // const getTeleprojectdata = async(id) => {
    //     try {
    //        let result = await ApiActions.getTeleProject(id)
    //        console.log('ressult', result)
    //        setProjectData(result)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    const handleBack = () => {
        props.history.push(`/project-qc/${formData && formData.id}`)
    }


    const renderTemplateType = () => {
        switch (activeBtn) {
            case '1':
                return <SmsTemplate {...props}/>
            case '2':
                return <WhatsappTemplate {...props}/>
            case '3':
                return <GoogleSheetTemplate {...props} />
            default:
                return <div>No form found</div>
        }
    }

    return(
        <div className="sms-template-wrapper" >
        <div className="heading">
            <div className="arrow" onClick={() => handleBack()}>
                <img src={require("../../assets/images/back-arrow.png")} />
                <div className="back">Back</div>
            </div>
            <div><h4></h4></div><h5>Step 6: Post call Communication Setup</h5>
        </div>

        {/* <div className='tabs-wrapper'>
        <TabGroup tabs={TABS} color='green-tab-outline' 
        onClick={(data) => setActiveTab(data)} activeTab={activeTab}/>
        </div> */}
        
        <div className='templates_wrapper'>
        <div className='template_type_select_row'>
            <button className={`template_btn ${activeBtn == buttonId[0] ? 'active' : ''}`} 
            onClick={() => switchBtn('1')}>SMS</button>
            <button className={`template_btn ${activeBtn == buttonId[1] ? 'active' : ''}`} 
            onClick={() => switchBtn('2')}>Whatsapp</button>
             <button className={`template_btn ${activeBtn == buttonId[2] ? 'active' : ''}`} 
            onClick={() => switchBtn('3')}>G-Sheet</button>
        </div>
        
        </div>

        {renderTemplateType()}
        {/* { activeTab === TABS[0] && <SmsTemplate {...props}/> }
        { activeTab === TABS[1] && <WhatsappTemplate {...props}/> } */}
            
        </div>
    )
}

export default Communication