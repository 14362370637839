import React from 'react'
import {AlertIcon} from '../../../assets/images'
import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import FutworkButton from '../../../uicomponents/button/Button'

const AlertDialog = (props) => {
    return(
        <div className={styles.alert_wrapper}>
        {props.error && <img src={AlertIcon} />}
        <div className={styles.bold_text}>{props.title}</div>
        <div className={styles.description}>{props.desc}</div>
        {props.cta && <FutworkButton buttonStyle={'primary--solid non-rounded'} buttonSize={'sm'}
        onClick={()=> props.onClick()}>{props.cta}</FutworkButton>}
        </div>
    )
}

export default AlertDialog