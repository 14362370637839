import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getProjectList = async(skip,limit, sort, search, type) => {
    let payload = generatePayload(skip,limit, sort, search)
    payload.filterStatus = type
    if(type === 'active') {
        return await ApiActions.getActiveTeleProjects(payload)
    }
    if(type === 'inactive') {
        return await ApiActions.getInactiveTeleProjects(payload)
    }
    if(type === 'active-stats') {
        return await ApiActions.getActiveTeleProjectsStats(payload)
    }
}

const getTotalProjects = async(search) => {
    return await ApiActions.getTeleProjectsForAuditCount(search)
}
const getProjectData = async(projectId) => {
    return await ApiActions.getTeleProject(projectId)
}
const updateProjectData = async(data, projectId) => {
    return await ApiActions.updateTeleProject(data, projectId)
}
const getSmsStats = async(projectId)=>{
    return await ApiActions.getSmsStats(projectId)
}
const generateSmsReport = async(payload) => {
    return await ApiActions.sendSmsReport(payload)
}

const getProcessUnprocessedTask = async(payload) => {
    return await ApiActions.getProcessUnprocessedTask(payload)
}

const uploadTeleTasks = async(payload) => {
    return await ApiActions.uploadTeleTasks(payload)
}

const uploadCsvtoSqs = async(payload) => {
    return await ApiActions.uploadCsvtoSqs(payload)
}

const getApiEnabledStats = async(payload) => {
    return await ApiActions.getApiEnabledStats(payload)
}

const getProjectReports = async(payload) => {
    return await ApiActions.getProjectReports(payload)
}

const getCallerReports = async(payload) => {
    return await ApiActions.getCallerReports(payload)
}

const getUnprocessedReports = async(payload) => {
    return await ApiActions.getUnprocessedReports(payload)
}

const getAttemptedLeadsReports = async(payload) => {
    return await ApiActions.getLiveLeadsReports(payload)
}

export{
    getProjectList,
    getTotalProjects,
    getProjectData,
    updateProjectData,
    getSmsStats,
    generateSmsReport,
    getProcessUnprocessedTask,
    uploadTeleTasks,
    uploadCsvtoSqs,
    getApiEnabledStats,
    getProjectReports,
    getCallerReports,
    getUnprocessedReports,
    getAttemptedLeadsReports
}