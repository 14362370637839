import React, { Component } from "react";

import {Row,Col} from 'react-bootstrap'

import constants from '../../util/constants';
import dayjs from 'dayjs';


class JsonView extends Component{
    constructor(props){
        super(props);
        var {data,fieldsMetaInfo} = this.props;
        
        this.state = {
            data,
            fieldsMetaInfo
        };
    }
    
    componentWillReceiveProps(nextProps){
        this.setState({data:nextProps.data})
    }
    render(){
        var data = this.state.data;
        var dataKeys = data&&Object.keys(data).reverse();
        
        return (
            <Row className="json-view" >
                {dataKeys&&dataKeys.map(this.renderItem)}
            </Row>
        )
    }

    renderItem = (key,i) => {
        var fieldsMetaInfo = this.state.fieldsMetaInfo;
        if(fieldsMetaInfo.fieldsToExclude.includes(key)){
            return null
        }
        
        var data = this.state.data;
        var value = data[key];
        
        return (
            <div className="item-wrapper col-md-12" key={i}>
                {this.renderField(key)}
                {this.renderValue(value,key)}
            </div>
        )

    }

    renderField = (key) => {
        var fieldsMetaInfo = this.state.fieldsMetaInfo;
        if (fieldsMetaInfo[key]){
            var fieldToShow = fieldsMetaInfo[key].label;
            return (
                <Col xs={12} sm={12} md={12} className="field">{fieldToShow}:</Col>
            )
        }
        
    }
    renderValue = (value,key) => {
        var fieldsMetaInfo = this.state.fieldsMetaInfo;
        if (!fieldsMetaInfo[key]){
            return null
        }
        var {type,callback} = fieldsMetaInfo[key];
        if (type === 'link'){
            return (
                <Col xs={12} sm={12} md={12} className="value">
                    <a href={value} target="_blank" rel="noopener noreferrer" >{value}</a>
                </Col>
            )
        }else if (type === 'image'){
            return (
                <Col xs={12} sm={12} md={12} className="value">
                    <img src={value} alt="" />
                </Col>
            )
        }else if (type === 'html'){
            return (
                <Col dangerouslySetInnerHTML={{__html:value}} xs={12} sm={12} md={12} className={`value ${this.checkHighlighted(key) ? "bg-yellow" :""}`} />
            )
        }else if (type === 'array'){
            if (typeof value[0] === 'object'){
                return (
                    <Col>
                        {
                            value.map((item,i) => 
                                <Col key={i} xs={12} sm={12} md={12} className="value">
                                    <h3 className="black-colour capitalize" >{key} {i+1}</h3>
                                    <JsonView data={item} fieldsMetaInfo={constants.fieldsMetaInfo[key]} />
                                </Col>
                            )
                        }
                    </Col>
                )
            }
            return (
                <Col>
                    {
                        value.map((item,i) => 
                            <Col key={i} xs={12} sm={12} md={12} className="value">
                                {i+1}){item}
                            </Col>
                        )
                    }
                </Col>
            )
        }else if ( type === 'function'){
            return (
                <Col xs={12} sm={12} md={12} className="value" >
                    {callback(value)}
                </Col>
            )
        }
        else if (type === 'date'){
            return (
                <Col xs={12} sm={12} md={12} className="value">{dayjs(value).format("DD MMM YYYY")}</Col>
            )
        }else if(type === 'object'){
            return (
                <Col xs={12} sm={12} md={12} className="value">
                    <JsonView data={value} fieldsMetaInfo={constants.fieldsMetaInfo[key]} />
                </Col>
            )
        }
        else {
            return (
                <Col xs={12} sm={12} md={12} className={`value ${this.checkHighlighted(key) ? "bg-yellow" :""}`}>{value}</Col>
            )
        }
    }

    checkHighlighted = (key) => {
        const {highlighted} = this.props;
        return highlighted && highlighted.indexOf(key) >= 0;
    }
}

export default JsonView