import React, { useState, useEffect } from "react";
import styles from '../../assets/stylesheets/pages/hiring/hiring.module.scss'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUpload} from '@fortawesome/free-solid-svg-icons'

import Upload from "../../uicomponents/upload/upload";
import FutworkButton from "../../uicomponents/button/Button";
import { TrashIcon } from "../../assets/images"
import useApi from '../../hooks/useApi'
import { refreshTrainingVideos, uploadVideo, saveVideoLater, saveVideoInitially } from './service'
import CustomTooltip from "../../components/utilities/ToolTip";

const RefresherTrainingVideo = (props) => {
    const [newVidoesList, setNewVideosList] = useState([
        {
            title: '',
            url: '',
            loading: false,
            error: ''
        }
    ])
    const [currentUpload, setCurrentUpload] = useState('')
    const [savedVideoList, setSavedVideoList] = useState([])

    const getVideoListApi = useApi(refreshTrainingVideos)
    const uploadVideoApi = useApi(uploadVideo)
    const saveVideoInitiallyApi = useApi(saveVideoInitially)
    const saveVideoLaterApi = useApi(saveVideoLater)

    useEffect(() => {
        getVideoListApi.request(props.id)
    }, [])

    const addVideo = () => {
        saveVideoInitiallyApi.reset()
        saveVideoLaterApi.reset()
        setNewVideosList([
            ...newVidoesList,
            {
                title: '',
                url: '',
                loading: false,
                error: ''
            }
        ])
    }

    const deleteVideo = (idx) => {
        let copyVideosList = [...newVidoesList]
        const indexToRemoved = copyVideosList.findIndex((i, index) => idx == index)
        copyVideosList.splice(indexToRemoved, 1)
        setNewVideosList(copyVideosList)
    }

    const addTitle = (e, idx) => {
        setNewVideosList(newVidoesList.map((video, id) => {
            if (id == idx) {
                video.title = e.target.value
            }
            return video
        }))
    }

    const checkForm = () => {
        if(newVidoesList && newVidoesList.length <= 0){
            return true
        }else{
            return newVidoesList.some(video => (video.url && video.title) ? false : true)
        }
    }

    const chunkUpload = (e, idx) => {
        // const file = e.target.files[0] ? e.target.files[0] : ''
        const file = e

        const metadata = {
            name: file.name,
            size: file.size,
            mimeType: 'video/mp4',
            // ... other metadata properties
        };

        // const formData = new FormData();
        if (file && file.name && file.name.match(/\.(mp4)$/)) {
            const CHUNK_SIZE = 5 * 1024 * 1024;
            // console.log('Chunk size in MB is', (CHUNK_SIZE/1024)/1024)
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const start = chunkIndex * CHUNK_SIZE;
                const end = Math.min(file.size, start + CHUNK_SIZE);
                const chunk = file.slice(start, end);
                const formData = new FormData();
                formData.append('teleproject', props.id);
                formData.append('metadata',  JSON.stringify(metadata));
                formData.append('file', chunk);
                uploadVideoApi.request(formData)
                // console.log(`Chunk ${chunkIndex + 1} of ${totalChunks} uploaded successfully.`);
            }
        } else {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.error = 'Only a file with .mp4 extension can be uploaded'
                }
                return video
            }))
        }
    }

    const setCsvFile = async (e, idx) => {
        // const file = e.target.files[0] ? e.target.files[0] :''
        const file = e
        if (file && file.name && file.name.match(/\.(mp4)$/)) {
            const formData = new FormData();
            formData.append('teleproject', props.id);
            formData.append('file', file);
            uploadVideoApi.request(formData)
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == idx) {
                    video.loading = isuploading
                }
                return video
            }))
        }
        else {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.error = 'Only a file with .mp4 extension can be uploaded'
                }
                return video
            }))
            return false
        }
    }


    const { data, loading, error } = getVideoListApi || {}

    const { data: uploadresult, loading: isuploading, error: uploaderror } = uploadVideoApi || {}

    const { data: createResult, loading: createLoading, error: createError } = saveVideoInitiallyApi || {}

    const { data: updateResult, loading: updateLoading, error: updateError } = saveVideoLaterApi || {}

    const sortByLatestUpload = (data) => {
        return data.sort((a, b) => -a.uploadedOn.localeCompare(b.uploadedOn))
    }


    useEffect(() => {
        if (data && data[0] && data[0].modules && Array.isArray(data[0].modules) && data[0].modules.length > 0) {
            const sortLatest = sortByLatestUpload(data[0].modules)
            setSavedVideoList([...sortLatest])
        }
    }, [data && !loading])


    useEffect(() => {
        if (createResult && createResult.data && createResult.data.modules && createResult.data.modules.length > 0) {
            const extractRes = createResult.data.modules.map(res => {
                return {
                    title: res.title,
                    videoUrl: res.videoUrl
                }
            })

            const sortLatest = sortByLatestUpload([...savedVideoList, ...extractRes])
            setSavedVideoList([...sortLatest])

            // setSavedVideoList([...savedVideoList, ...extractRes])
            setNewVideosList([])
        }

        if (updateResult && updateResult.data && updateResult.data.modules && updateResult.data.modules.length > 0) {
            const tempUpdateResult = updateResult.data.modules.map(res => {
                return {
                    title: res.title,
                    url: res.videoUrl
                }
            })
            const sortLatest = sortByLatestUpload(updateResult.data.modules)
            setSavedVideoList([...sortLatest])
            // setSavedVideoList(updateResult.data.modules)
            setNewVideosList([])
        }

    }, [createResult, updateResult])


    useEffect(() => {
        if (uploaderror) {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.error = (uploaderror && uploaderror.error) || 'Something went wrong'
                }
                return video
            }))
        }

        if (uploadresult) {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.error = ''
                    video.url = uploadresult.data && uploadresult.data.Location
                }
                return video
            }))
        }
    }, [uploaderror, uploadresult])


    useEffect(() => {
        if (isuploading) {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.loading = isuploading
                }
                return video
            }))
        } else {
            setNewVideosList(newVidoesList.map((video, id) => {
                if (id == currentUpload) {
                    video.loading = isuploading
                }
                return video
            }))
        }

    }, [isuploading])

    const saveVideo = () => {
        let payload = {
            teleproject: props.id,
            modules: newVidoesList.map(video => {
                video = {
                    "title": video.title,
                    "videoUrl": video.url
                }
                return video
            })
        }

        if (savedVideoList && savedVideoList.length > 0) {
            saveVideoLaterApi.request(payload)
        } else {
            saveVideoInitiallyApi.request(payload)
        }
    }

    return (
        <div className={styles.refresher_training_upload}>
            <div className={styles.wrapper}>
                <div className={styles.upload_form}>
                    <span className={styles.heading}>New Video</span>
                    {
                        newVidoesList && newVidoesList.map((video, idx) => {
                            return <div className={styles.upload_video}>
                                <div className={styles.upload_video_title}>
                                    <label className={styles.label}>{video.url ? 'Uploaded video url' : 'Upload video'}</label>
                                    <img src={TrashIcon} onClick={() => deleteVideo(idx)} />
                                </div>
                                {
                                    video.url ?
                                        <input type='text' className={styles.title} value={video.url} readOnly />
                                        :
                                        <div className={styles.upload_action}>
                                        <Upload placeholder='The video link will appear after upload' onChange={(e) => {
                                            setNewVideosList(newVidoesList.map((video, id) => {
                                                if (id == idx) {
                                                    video.error = ''
                                                    video.file = e.target.files[0] ? e.target.files[0] : ''
                                                }
                                                return video
                                            }))
                                            setCurrentUpload(idx)
                                            // setCsvFile(e, idx)
                                        }} />
                                        {/* <FontAwesomeIcon icon={faUpload} size="xl"/> */}

                                        {!video.url && <CustomTooltip placement={'bottom'} description={'Upload video'} component={
                                            <button onClick={(e) => {
                                                setCurrentUpload(idx)
                                                video.file && setCsvFile(video.file, idx)
                                            }}><FontAwesomeIcon icon={faUpload} size="xl"/></button>
                                        }></CustomTooltip>}
                                        
                                        {/* <button onClick={(e) => video.file && setCsvFile(video.file, idx)}><FontAwesomeIcon icon={faUpload} size="xl"/></button> */}
                                        </div>
                                }
                                {/* {!video.url && <div style={{ display: 'flex', justifyContent: 'cenetr', gap: '1em' }}>
                                    <button onClick={(e) => video.file && setCsvFile(video.file, idx)}>Upload</button>
                                </div>} */}


                                <div className={styles.info}>
                                    {
                                        video.loading && <div><b>Video link generation under progress...</b></div>
                                    }
                                    {
                                        video.error && <div className={styles.error_msg}>{video.error}</div>
                                    }
                                </div>
                                <label className={styles.label}>Video Title</label>
                                <input type='text' className={styles.title} placeholder="Enter title here"
                                    value={video.title} onChange={(e) => addTitle(e, idx)} />
                            </div>
                        })
                    }
                    <div className={styles.add_video} onClick={addVideo}>+ Add video</div>

                    {
                        (createResult && createResult.status == 200 || updateResult && updateResult.status == 200) &&
                        <div className={styles.sucess_msg}>Video is saved successfully</div>
                    }


                    <hr></hr>

                    <span className={styles.heading}>Uploaded videos</span>


                    {
                        savedVideoList && savedVideoList.length > 0 ?
                            savedVideoList.map(video => {
                                return <div className={styles.upload_video}>
                                    <div className={styles.timestamp}>Updated on: {moment(video.uploadedOn).format('DD-MM-YYYY')} | {moment(video.uploadedOn).format('hh:mm A')}</div>
                                    <label className={styles.label}>Uploaded video</label>
                                    <input type='text' className={[styles.title, styles.disabled].join(' ')} value={video.videoUrl} readOnly />
                                    <label className={styles.label}>Video Title</label>
                                    <input type='text' className={[styles.title, styles.disabled].join(' ')} value={video.title} readOnly />
                                </div>
                            })
                            :
                            <div className={styles.no_data}>No videos found</div>
                    }
                </div>
            </div>

            <div className={styles.action}>
                <FutworkButton buttonSize='sm w-100' buttonStyle='success--solid'
                    disabled={checkForm()}
                    onClick={saveVideo}
                >SAVE</FutworkButton>
            </div>

        </div>
    )
}

export default RefresherTrainingVideo