import React, {useState, createContext, useContext} from 'react'

export const RebuttalContext = createContext()

export const RebuttalProvider = (props) => {
    const [callDetails, setCallDetails] = useState('')
    const [rebuttalBehviourScoreCard, setRebuttalBehviourScoreCard] = useState([])

    const providerValue = {
        callDetails,
        setCallDetails,
        rebuttalBehviourScoreCard,
        setRebuttalBehviourScoreCard
    }

    return(
        <RebuttalContext.Provider value={providerValue}>
            {props.children}
        </RebuttalContext.Provider>
    )
}

 