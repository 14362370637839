import React, { useEffect, useState } from 'react'
import Table from '../../uicomponents/table/Table'
import { getSystemQC, getCallerQCdata } from './service'
import useApi from '../../hooks/useApi'

const QCInsights = ({details}) => {
    const getSystemQCApi = useApi(getSystemQC)
    const getCallerQCdataApi = useApi(getCallerQCdata)

    const [loading, setLoading] = useState(true)

    // const [statsData, setStatsData] = useState(details ? details.outcomes.map(({title}) => {
    //     return {
    //         title,
    //         sysavg: 0,
    //         callavg: 0
    //     }
    // }) : '')

    const [statsData, setStatsData] = useState([])
    const [totalCount, setTotalCount] = useState({
        sysavg: 0,
        callavg: 0
    })

    useEffect(()=>{
        getSystemQCApi.request(details.teleproject || details.teleprojectId)
        // getSystemQCApi.request('61b1ee91b48f5d00166b8f45')
        getCallerQCdataApi.request({
            user: details.user || details.userId,
            teleproject: details.teleproject || details.teleprojectId
            // user: '628e0bd19c9dfa00120acb69',
            // teleproject: '61b1ee91b48f5d00166b8f45'
        })
    }, [])
    
    const columns = [
        {
            Header: 'Outcome',
            accessor: 'title',
            width: "1%",
            width: '50%',
            disableSortBy: true
        },
        {
            Header: 'System Average',
            accessor: 'sysavg',
            Cell: ({value}) => <span>{value}%</span>,
            disableSortBy: true
        },
        {
            Header: 'Caller Average',
            accessor: 'callavg',
            Cell: ({value}) => <span>{value}%</span>,
            disableSortBy: true
        }
    ]


    let { data: systemQc, loading: systemQcloading, error: systemQcerror } = getSystemQCApi || {}
    let { data: callerQc, loading: callerQcloading, error: callerQcerror } = getCallerQCdataApi || {}

    useEffect(()=>{
        if(systemQc){
            setStatsData(systemQc.outcomes.map(({title}) => {
                    return {
                        title,
                        sysavg: 0,
                        callavg: 0
                    }
            }))
        }
    },[systemQcloading])


    useEffect(() => {
        if(systemQc) {
            if(systemQc.qc.count){
                let sum = Object.keys(systemQc.qc.count).reduce((accumulator, curValue) => {
                    return accumulator + systemQc.qc.count[curValue]
                }, 0)
                setTotalCount({...totalCount, sysavg: sum})
                let updateavg = statsData && statsData.map(it => {
                    if(it.title) it.sysavg = systemQc.qc.count[it.title] ? 
                    (((systemQc.qc.count[it.title])/sum) * 100).toFixed(0) : 0
                    // if(it.sum) it.sum.sysavg = sum
                    return it
                })
                setStatsData(updateavg)
            }
        }
        if(callerQc){
            if(callerQc[0].count){
                let sum = Object.keys(callerQc[0].count).reduce((accumulator, curValue) => {
                    return accumulator + callerQc[0].count[curValue]
                }, 0)
                setTotalCount({...totalCount, callavg: sum})
                let updateavg = statsData && statsData.map(it => {
                    if(it.title) it.callavg = callerQc[0].count[it.title] ? 
                    ((callerQc[0].count[it.title]/sum) * 100).toFixed(0) : 0
                    // if(it.sum) it.sum.callavg = sum
                    return it
                })
                setStatsData(updateavg)
            }
        }

        setLoading(false)
    },[!systemQcloading && !callerQcloading])
    
    return(
        <div>
            <Table
            columns={columns}
            data={statsData}
            loading={loading}
            type = 'client'
            />
        </div>
    )

    // return <span></span>
}

export default QCInsights