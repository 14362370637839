import React, { useState, useContext } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Heading from '../../../uicomponents/heading'
import FormInput from '../../../uicomponents/form-input/forminput';
import Dropdown from 'react-dropdown';
import CertifyAgent from './certifyAgent';
import AddBasePay from './addBasePay';
import MatchAgent from './matchAgent';
import OfferLetter from './offerLetter';
import BulkmoderationWithManualGrades from './bulkModerationWithGrades';
import { BulkActionProvider, BulkActionContext } from './bulkActionContext'

const BulkAction = () => {
    const { OFFER_LETTER, CERTIFY_AGENT, ADD_BASE_PAY, MATCH_AGENT, BULK_MODERATION_MANUAL_GRADES,
        actionsList, currentAction, setCurrentAction, setIsComplete
    } = useContext(BulkActionContext)



    const renderFormOnAction = () => {
        switch (currentAction.value) {
            case OFFER_LETTER:
                return <OfferLetter />
                break;
            case CERTIFY_AGENT:
                return <CertifyAgent />
            case ADD_BASE_PAY:
                return <AddBasePay />
            case MATCH_AGENT:
                return <MatchAgent />
            case BULK_MODERATION_MANUAL_GRADES:
                return <BulkmoderationWithManualGrades />
            default:
                return
        }
    }

    return <div className={styles.page_wrapper}>
        {/* <BulkActionProvider> */}
        <div className={styles['bulk-action']}>
            <div className={styles['text-center']}>
                <Heading text={'Onboarding Bulk Actions'} />
            </div>
            <div className={styles['select-action']}>
                <div className={styles.form_label}>Select action you want to perform in bulk</div>
                <Dropdown
                    options={actionsList}
                    onChange={(e) => {
                        setIsComplete(0)
                        setCurrentAction({
                            label: e.label, value: e.value
                        })
                    }}
                    // value={selectOutcome == '' ? 'All outcomes' : selectOutcome}
                    placeholder="Please choose an action" />

                {renderFormOnAction()}
            </div>
        </div>
        {/* </BulkActionProvider> */}
    </div>
}

export default BulkAction
