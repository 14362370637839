import React, { useState, createContext, useEffect, useContext } from 'react'


export const TrainingSessionContext = createContext();

export const TrainingSessioProvider = (props) => {
   
    const [projectData, setProjectData] = useState('')
    const [isCreateSession, setIsCreateSession] = useState(false)
    const [isMarkAttendance, setIsMarkAttendance] = useState(false)
    const [stepCount, setStepCount] = useState(1)
    const [selectedRow, setSelectedRow] = useState('')

    const providerValue = {
        projectData, setProjectData,
        isCreateSession, setIsCreateSession,
        isMarkAttendance, setIsMarkAttendance,
        stepCount, setStepCount,
        selectedRow, setSelectedRow
    }

    return (
        <TrainingSessionContext.Provider value={providerValue}>
            {props.children}
        </TrainingSessionContext.Provider>
    )
}