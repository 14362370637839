import ApiActions from "../../../actions/ApiActions";
import generatePayload from "../../../helper/generatePayloadForTable";

const getAllExophones = async() => {
    return await ApiActions.getListOfExophones()
}

const getBrandName = async(brandid) => {
    return await ApiActions.getBrandName(brandid)
}

const fetchAllBrands = async() => {
    return await ApiActions.getBrand()
}

const createUpdatePool = async(payload) => {
    return await ApiActions.createUpdatePool(payload)
}

const createUpdateExophones = async(payload) => {
    return await ApiActions.createUpdateExophones(payload)
}

const getProjectList = async(skip,limit, sort, search, type, otherFilters) => {
    let payload = generatePayload(skip,limit, sort, search)
    payload.filterStatus = type
    payload.otherFilters = otherFilters
    return await ApiActions.getActiveTeleProjects(payload)
}

const linkProjectToPool = async(payload) => {
    return await ApiActions.linkProjectToPool(payload)
}

const getProjectsLinked = async(skip,limit, sort, search, poolid) => {
    let payload = generatePayload(skip,limit, sort, search)
    payload.callingPool = poolid
    return ApiActions.projectLinkedToPool(payload)
}

export {
    getAllExophones,
    getBrandName,
    fetchAllBrands,
    createUpdatePool,
    createUpdateExophones,
    getProjectList,
    linkProjectToPool,
    getProjectsLinked
}