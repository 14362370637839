import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import MaterialTable, { MTablePagination } from "material-table";
import FrappButton from '../utilities/FrappButton';
import FrappModal from "../utilities/FrappModal";
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectTitle from "../common/ProjectTitle"
import CustomerInfo from '../utilities/CustomerInfo'
import CallDisconnectedBy from "../../helper/CallDisconnectedBy";

export default class QualityAudit extends Component {
    constructor(props){
        super(props)
        this.tableRef = React.createRef()
        this.state={
            skip:0,
            callauditlist:[],
            flaggedCalls:[],
            auditAction:"",
            callAuditAction:"", 
            isLoading:true,
            mht:{},
            checkboxFeedbackData: [],
            checkboxCallerAuditData: [],
            checkBanReasons: [],
            checkRetrainReasons: [],
            reason:"Cleared in QC",
            tableSorting: '',
            teletaskCallHistory: []
        }
    }
    componentDidMount() {
        this._isMounted = true;
         let {skip}=this.state;
         this.setState({mht:this.props.location.state.mht})
        if (this._isMounted) {
            if (this.props.match.params.id) {
                ApiActions.getCallAuditList(skip,this.props.match.params.id).then(resp => {
                    ApiActions.getTeleProject(this.props.match.params.id).then(resp2 => {
                        this.setState({ teleproject: resp2})
                    })
                    
                    skip=skip+50;
                    this.setState({ callauditlist: resp })
                    if(resp.length > 0) {
                        resp&&resp.map(obj=>{
                            obj.flaggedCalls&&obj.flaggedCalls.map(calls=>{
                                calls.mobile=obj.mobile;
                                calls.id=obj._id;
                                calls.name=obj.name;
                                this.setState({callauditlist:resp,isLoading:false})
                            })
                            this.extractFlaggedCalls()
                            this.extractOutcomes()
                        })
                    } else {
                        this.setState({callauditlist:resp,isLoading:false})
                    }
                })
            }
            this.setState(this.props.history.location.state);
        }

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    extractOutcomes=()=>{
        let {teleproject}=this.state;
        let outcomes=teleproject.outcomes;
        this.setState({outcomes})
    }
    extractFlaggedCalls=()=>{
        let {callauditlist}=this.state;
        let arr=[];
        callauditlist.map(obj=>{
            obj.flaggedCalls.map(obj2=>{
                arr.push(obj2)
            })
        })
        this.setState({flaggedCalls:arr})
    }
    fetchReasons = (rowData) => {
        ApiActions.getQAFeedbackReason()
            .then(response => {
                this.setState({
                    checkboxFeedbackData : response.callFeedbacks.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkBanReasons: response.banReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkRetrainReasons: response.retrainReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
            })
    }
    render() {
        let {callauditlist, mht, teleproject}=this.state;
        let {isLoading, activeCaller}=this.state
        const goBack = () => {
            this.props.history.push(`/audit/qmf/audit-project/${this.props.match.params.id}`,
                 {teleprojectAudit: this.props.location.state.teleprojectAudit})
        }

        if(!isLoading)
        return (
            <div className="call-audits">
               {teleproject ? <ProjectTitle title={teleproject.title} goBack={goBack} /> : null}
                <div className="mht">Min. Handling Time for winning outcome: &nbsp;
                {
                    Object.keys(mht).map((obj, i)=>{
                        return(
                           <div className="data" key={i}> {obj}:&nbsp;{mht[obj]}</div>
                        )
                    })
                }
                </div>
                {callauditlist?this.renderCallAuditTable():null}
                {this.renderCallAuditPopup()}
                {this.renderDataCollectionPopup()}
                {this.renderCallHistoryPopup()}
                <ToastContainer draggable={true} />
            </div>
        )
        else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
        
    }

    fetchData=()=>{
        let {skip,callauditlist}=this.state;
        let newData;
        ApiActions.getCallAuditList(skip,this.props.match.params.id).then(resp => {
            ApiActions.getTeleProject(this.props.match.params.id).then(resp2 => {
                this.setState({ teleproject: resp2})
            })
            skip=skip+50;
            this.setState({ callauditlist: resp })
            if(resp.length > 0) {
                resp&&resp.map(obj=>{
                    obj.flaggedCalls&&obj.flaggedCalls.map(calls=>{
                        calls.mobile=obj.mobile;
                        calls.id=obj._id;
                        calls.name=obj.name;
                        this.setState({callauditlist:resp,isLoading:false})
                    })
                    this.extractFlaggedCalls()
                    this.extractOutcomes()
                })
            } else {
                this.setState({callauditlist:resp,isLoading:false})
            }
        })
    }

    handleTableSorting = (order, direction) => { 
        // this.setState({tableSorting: direction})
    }

    renderCallAuditPopup= () => {
        let activeCallers = this.state.activeCallers;
        let {outcomes,auditAction,callAuditAction, checkboxFeedbackData, reason, checkboxCallerAuditData }=this.state;
        let options=[
           { label: "Approve",value:"approved"},
           { label: "Incorrect Handling",value:"incorrect handling"}
        ]
        outcomes&&outcomes.map((outcome,i)=>{
            let outcomeOption={
                "label":outcome.title,
                "value":outcome.title
            }
            options.push(outcomeOption)
        })

        let options_=[
            { label: "QC Pass",value:"qcpass"},
            { label: "Retrain",value:"training"}
        ]

        const handleChangeCheckboxFeedback = (event) => {
            // setChecked(event.target.checked);
            // this.setState({checked: event.target.checked})
            this.setState({
                checkboxFeedbackData: checkboxFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
            })
        };

        const handleChangeCheckboxCallerAction = (event) => {
            this.setState({
                checkboxCallerAuditData : checkboxCallerAuditData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
            })
        }

        const renderCheckboxFeedback = (checked, label) => {
            return (
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeCheckboxFeedback}
                        name={label}
                        color="primary"
                    />
                    }
                    label={<span style={{ fontSize: '13px', marginBottom: '0px !important' }}>{label}</span>}
                />
            )
        }

        const renderCheckboxCallerAction = (checked, label) => {
            return (
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeCheckboxCallerAction}
                        name={label}
                        color="primary"
                    />
                    }
                    label={<span style={{ fontSize: '13px' }}>{label}</span>}
                />
            )
        }

        const enterPress = (event) => {
            if(event.key === "Enter") {
                this.setState({
                    checkboxFeedbackData : [...checkboxFeedbackData, {label: event.target.value, checked: true}]
                })
                this.refs.customInput.value = '';
            }
        }

        return <FrappModal onCloseButton={true} className="call-audit-modal" // show={this.state.showCertifyModal}
            ref={(c) => (this.callAuditModal = c)} closeModal={() => this.callAuditModal.toggle(false)}>
            <div className="call-audit-modal-body">
              <div className="heading">Call audit</div>
              <CustomerInfo info={this.state.activeCaller}/>
              
              <div className='action-feedback'>
              <div className="heading">Call action</div>
              <div className="dropdown-call-audit">
                <Dropdown options={options} onChange={(val) => this.selectValueCallAudit(val)} value={callAuditAction} placeholder="Select an option" />
              </div>
              <div className="heading">Feedback (Optional)</div>
              <div className="call-audit-checkbox">
                {checkboxFeedbackData.map((item) => {
                  return renderCheckboxFeedback(item.checked, item.label, item.id);
                })}
                <input type="text" ref="customInput" placeholder="Custom Input" className="custom-input" onKeyDown={enterPress} />
              </div>
              </div>

              <div className='action-feedback'>
                  <div>  
                    <div className="heading">Caller Actions (Optional)</div>
                        <div className="dropdown-call-audit">
                            <Dropdown options={options_} 
                        onChange={(val)=>this.selectValueCallerActions(val)} 
                        value={auditAction} 
                        placeholder="Select an option" />
                    </div>
                        {this.state.auditAction==="ban"? <div className="call-audit-checkbox" >
                            {checkboxCallerAuditData.map((item) => {
                            return renderCheckboxCallerAction(item.checked, item.label);
                            })}
                        </div> : this.state.auditAction==="training" ? <div className="call-audit-checkbox" >
                            {checkboxCallerAuditData.map((item) => {
                            return renderCheckboxCallerAction(item.checked, item.label);
                            })}
                        </div> : null}
                    </div>
              </div>

              <div className="call_audit-submit-button">
                <FrappButton ref={(c) => this.submitBtn = c} className="submit" id="bulk-status-btn" handler={() => this.updateAction()}>
                  Submit
                </FrappButton>
              </div>
            </div>
          </FrappModal>;

    }

    renderDataCollectionPopup = () => {
        
        const { activeCaller } = this.state;
        let dataCollection =    activeCaller && ((activeCaller.outcome && activeCaller.outcome.responses) || activeCaller.responses)
                                ?   activeCaller.responses
                                    ?   activeCaller.responses
                                    :   activeCaller.outcome && activeCaller.outcome.responses
                                :   false;

        return <FrappModal onCloseButton={true} className="data-collection-popup" // show={this.state.showCertifyModal}
        ref={(c) => (this.dataCollectionModal = c)} closeModal={() => this.dataCollectionModal.toggle(false)}>
            <div className="data-collection-container">
                <h4 className="dc-heading">Data Collection</h4>
                {
                    !(dataCollection)
                    ? <p>No data available</p>
                    : <div className="questions-wrapper">
                        {
                            dataCollection.map((item, index) => {
                                return (
                                    <div key={index} className="question-div">
                                        <p className="question">{`Q.${index+1} ${item.question}`}</p>
                                        <p className="answer">Ans: {item.answer}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                
            </div>
        </FrappModal>
    }

    renderCallHistoryPopup = () => {
        
        const data = this.state.teletaskCallHistory

        return <FrappModal onCloseButton={true} className="data-collection-popup"
        ref={(c) => (this.callHistoryModal = c)} closeModal={() => {
                this.callHistoryModal.toggle(false)
                this.setState({
                    teletaskCallHistory:[]
                })
            }}>
            <div className="call-history-container">
                <h4 className="ch-heading">Call History</h4>
                    {
                        data.length>0 ? 
                        <table className='call-history'>
                                <tr>
                                    <th>Attempt</th>
                                    <th>Audio</th>
                                    <th>Outcome</th>
                                </tr>
                            {data.map(callhistory=>{
                                return <tr>
                                    <td>{callhistory.attempts ? callhistory.attempts:''}</td>
                                    <td><audio controls src={callhistory.recordingUrl?callhistory.recordingUrl:''}/></td>
                                    <td>{callhistory.outcome && callhistory.outcome.title ? callhistory.outcome.title:''}</td>
                                </tr>
                            })}
                        </table>
                        :
                    
                        <div className='no-data'>No record to display</div>
                    }
            </div>
        </FrappModal>
    }

    selectValueCallAudit=(val)=>{
        if(val.value==="approved"||val.value==="incorrect handling")
       this.setState({callAuditAction:val.value,outcomeChanged:false})
       else
       this.setState({callAuditAction:val.value,outcomeChanged:true})

    }
    selectValueCallerActions=(val)=> {
        this.setState({auditAction:val.value})
        if(val.value==="qcpass"){
            // QC Pass
            this.setState({reason:"Cleared in QC"})
            this.setState({ auditAction: "qcpass" })
        }
        else if(val.value==="training") {
            // retrain
            this.setState({reason:"Marked Retrain by agent"})
            this.setState({ auditAction: "training" })
            this.setState({
                checkboxCallerAuditData: this.state.checkRetrainReasons
            })
        }
        else {
            // Ban
            this.setState({reason:"Marked Ban by agent"})
            this.setState({ auditAction: "ban" })
            this.setState({
                checkboxCallerAuditData: this.state.checkBanReasons
            })
        }
    }
    selectReason=(val)=>{
        this.setState({reason:val.value})
    }
    updateAction = () => {
        this.submitBtn.startLoading()
        let { activeCaller, callAuditAction, outcomeChanged, teleproject, outcomes, flaggedCalls, skip, auditAction, checkboxCallerAuditData, checkboxFeedbackData, reason } = this.state;
        let dataToSend;

        if (outcomeChanged) {
            var selectedOutcome = outcomes.filter(o1 => {
                return o1.title === callAuditAction
            })
            dataToSend = {
                teletaskHistoryAuditId: activeCaller.teletaskhistoryAudit,
                action: "outcome changed",
                outcome: selectedOutcome[0]
            }
        } else {
            dataToSend = {
                teletaskHistoryAuditId: activeCaller.teletaskhistoryAudit,
                action: callAuditAction
            }
        }

        let checkSelectedFeedback = checkboxFeedbackData.some((item) => {
            return item.checked === true
        })
        if (checkSelectedFeedback) {

            dataToSend.feedbacks = checkboxFeedbackData.map((item) => {
                if (item.checked === true) {
                    return item.label
                }
            }).filter(i => i)
        }

        ApiActions.updateCallAuditAction(dataToSend).then(resp => {
            ApiActions.getCallAuditList(skip, this.props.match.params.id).then(resp => {
                ApiActions.getTeleProject(this.props.match.params.id).then(resp2 => {
                    this.setState({ teleproject: resp2 })
                })
                skip = skip + 50;
                this.setState({ callauditlist: resp })
                if (resp.length > 0) {
                    resp && resp.map(obj => {
                        obj.flaggedCalls && obj.flaggedCalls.map(calls => {
                            calls.mobile = obj.mobile;
                            calls.id = obj._id;
                            calls.name = obj.name;
                            this.setState({ callauditlist: resp, isLoading: false })
                        })
                        this.extractFlaggedCalls()
                        this.extractOutcomes()
                    })
                } else {
                    this.setState({ callauditlist: resp, isLoading: false })
                }
            })
            // this.submitBtn.stopLoading()
        }).then(() => {
            let checkSelectedCallerFeedback = checkboxCallerAuditData.some((item) => {
                return item.checked === true
            })
            if (auditAction !== "") {
                let dataToSendCallerAction = {
                    teleproject: teleproject.id,
                    user: activeCaller.id,
                    // status: auditAction,
                    actionBy: "agent",
                    "action": auditAction,
                    "reason": reason
                }
                if (auditAction === "training") {

                    let retrainReasons = checkboxCallerAuditData.map((item) => {
                        if (item.checked === true) {
                            return item.label
                        }
                    }).filter(i => i)
                    dataToSendCallerAction = {
                        ...dataToSendCallerAction,
                        retrainReasons: retrainReasons
                    }
                } else if (auditAction === "ban") {
                    dataToSendCallerAction = {
                        ...dataToSendCallerAction,
                        banReasons: checkboxCallerAuditData.map((item) => {
                            if (item.checked === true) {
                                return item.label
                            }
                        }).filter(i => i)
                    }
                }
                ApiActions.updateCallerStatus(dataToSendCallerAction).then((response) => {
                    this.setState({ callAuditAction: "" })
                    this.setState({ auditAction: "" })

                    // if (auditAction === "training" && process.env.NODE_ENV === 'production') {
                    //     // create ticket
                    //     // debugger;
                    //     ApiActions.createTicketFreshdesk({
                    //         "description": `Re-training session for tele-caller scheduled.\
                    //         Feedback from QA team: ${dataToSendCallerAction.retrainReasons.join(", ")}`,
                    //         "subject": teleproject.title,
                    //         "email": activeCaller.email ? activeCaller.email : null,
                    //         "phone": activeCaller.mobile,
                    //         "name": activeCaller.name ? activeCaller.name : activeCaller.firstname + " " + activeCaller.lastname,
                    //         "priority": 1,
                    //         "status": 2,
                    //         "custom_fields": {
                    //             "cf_brand_id": teleproject.brand,
                    //             "cf_project_id": teleproject.id,
                    //             "cf_user_id": activeCaller.id
                    //         },
                    //         "type": "Coaching 1 on 1"
                    //     })
                    // } else if(process.env.NODE_ENV === 'development') {
                    //     alert("Cant create freshdesk ticket on staging")
                    // }
                    // window.location.reload();
                    // this.setState({
                    //     checkboxFeedbackData : this.state.checkboxFeedbackData.map((item) => {
                    //         return { "label":item, "checked":false }
                    //     })
                    // })

                }).catch((error) => {
                    this.submitBtn.stopLoading()
                    this.callAuditModal.toggle(false);
                    toast("Error")
                })
            }
        }).then(() => {
            toast("Success")
            this.callAuditModal.toggle(false)
            this.submitBtn.stopLoading()
        }).catch((error) => {
            this.submitBtn.stopLoading()
            this.callAuditModal.toggle(false);
            toast("Error")
        })
    }

    auditPopup=(rowData)=>{
        let {outcomes,flaggedCalls, tableSorting}=this.state;
        if(this.tableRef.current){
            this.setState({tableSorting: this.tableRef.current.state.orderDirection})
        }
        this.setState({ callAuditAction: "" })
        this.setState({ auditAction: "" })
        this.callAuditModal.toggle(true);
        this.fetchReasons(rowData)
        this.setState({activeCaller:rowData})
    }
    dataCollectionPopup=(rowData)=>{
        if(this.tableRef.current){
            this.setState({tableSorting: this.tableRef.current.state.orderDirection})
        }
        this.dataCollectionModal.toggle(true);
        this.setState({activeCaller:rowData})
    }
    callHistoryPopup=(rowData)=>{
        this.setState({activeCaller:rowData})
        if(this.tableRef.current){
            this.setState({tableSorting: this.tableRef.current.state.orderDirection})
        }
        if (rowData.teletaskhistory){
            ApiActions.getTeletaskCallHistory({
            teletaskHistory: rowData.teletaskhistory
            }).then((response) => {
                this.setState({
                    teletaskCallHistory:response
                })
                return
            })
        }
        this.callHistoryModal.toggle(true);
    }

    renderCallAuditTable = () => {
       let { skip,flaggedCalls, tableSorting} = this.state;
        return (
            <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsPerPage: '25'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                    columns=
                    {[
                        {
                            title: "Caller phone",
                        field: "mobile",
                        
                            filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                        },
                        {
                            title: "Name",
                            field: "name",
                            defaultSort: tableSorting,
                        
                            filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                        },
                        {
                            title: "Outcome",   
                            field:"outcome.title",
                            // render: rowData => {
                            //     if(rowData.recordingUrl === "" || rowData.recordingUrl === null){
                            //         return(<p> No Audio File </p>)
                            //     }else{
                            //         return(<p>{rowData.outcome} </p>)
                            //     }
                            // },
                            filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                            title: "Duration",
                            field:"conversationDuration",
                            defaultSort: tableSorting,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black',
                                width: 50
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },
                        {
                            title: "Audio", 
                            // field:"recordingUrl",
                            render: rowData => {
                                return (
                                    <div>
                                    {/* <a href={rowData.testAudioUrl}> Audio </a> */}
                                    <audio controls src={rowData.recordingUrl}>  
                                    </audio>
                                    </div>
                                )
                            },
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering:false
                        },
                        {
                            title: 'Disconnected by',
                            render : rowData => {
                                return rowData && rowData.disconnectedBy ? CallDisconnectedBy(rowData.disconnectedBy) : CallDisconnectedBy('')
                            },
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering:false
                        },
                        {
                            title: "Data Collection",
                            // field: "",
                            render: rowData => {
                                return (
                                    <div className="data-collection-icon" onClick={() => this.dataCollectionPopup(rowData)}>
                                        <img width="30px" src={require("../../assets/images/data-collection.svg")} />
                                    </div>
                                )
                            },
                            filtering: true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'
                            },
                        },
                        {
                            title: "Call History",
                            // field: "",
                            render: rowData => {
                                return (
                                    <div className="data-collection-icon" onClick={() => {
                                        this.callHistoryPopup(rowData)
                                    }}>
                                        <img width="30px" src={require('../../assets/images/Icons_history.svg')} />
                                    </div>
                                )
                            },
                            filtering: true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'
                            },
                        },
                        {
                            title: "",
                        //  field: "",
                            render: rowData => {
                                return (
                                    <div className="audit-arrow" onClick={()=>this.auditPopup(rowData)}> 
                                        <img src={require("../../assets/images/Icons_right.svg")} />
                                    </div>
                                )
                            },           
                            filtering:true,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            },
                            headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            boxShadow: 'none'
                            },
                        }
                    ]}
                  data={flaggedCalls}
                //   onOrderChange={this.handleTableSorting}
                    // components={{
                    //     Pagination: props => (
                    //         <div style={{ fontSize: "30px" }}>
                    //             <MTablePagination rowsPerPage={50} {...props} />
                    //         </div>
                    //     )
                    // }}
                    onChangePage={()=>this.fetchData()}
                    tableRef={this.tableRef}
                    options={{
                        filtering: true,
                        sorting: true,
                        // grouping: true,
                        exportButton: true,
                        doubleHorizontalScroll:true,
                        exportAllData:true,
                        columnsButton:true,
                        pageSize:25,
                        emptyRowsWhenPaging:false,
                        pageSizeOptions:[25,50,75,100],
                        rowStyle: {
                            fontSize: "16px"
                        },
                        searchFieldStyle: {
                            boxShadow: 'none',
                            webkitboxShadow: 'none',
                        }
                    }}
                    title="Call Audits"
                      

                />



            </div>
        )
    }
}