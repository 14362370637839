import React, { useState } from 'react'
import FrappButton from '../../../components/utilities/FrappButton';
import { createDuplicateScript } from './service'

const DuplicateIS = (props) => {
    const [languages, setLanguages] = useState(props.from.languages)
    const [toScript, setToScript] = useState('')
    const [resultMsg, setResultMsg] = useState('')

    const selectLangToDuplicate = ({name}) => {
        let updatedLanguages = languages.map(elem => {
            if(elem.name === name){
                elem.checked = !elem.checked
                return elem
            }else{
                return elem
            }
        })
        setLanguages(updatedLanguages)
    }

    const createDuplicateIS = async () => {
        const getSelectedLanguages = languages.filter(lang => {
            if(lang.checked === true){
                return lang
            }
        }).map(lang => lang.name)
        let data = {
            languages: getSelectedLanguages,
            from: props.from.id,
            to: toScript
        }

        try{
            let result = await createDuplicateScript(data)
            if(result) setResultMsg(result)
        }catch(err){
            setResultMsg(err.response.data)
        }
    }

    const checkForValidation = () => {
        const lengthOfId = props.from.id.length
        const isLanguageSelected = languages.some(lang => lang.checked)
        const result = toScript.length >= lengthOfId && toScript ? false : true
        return toScript.length >= lengthOfId && toScript && props.from.id !== toScript && isLanguageSelected ? false : true
    }

    const showErrorMsg = () => {
        return(
            <div className="duplicate-si-result">
                <span>{resultMsg.msg}</span>
                {resultMsg && resultMsg.processedLanguages && resultMsg.processedLanguages.length > 0 ? <p>Successfully created script for: {resultMsg.processedLanguages && resultMsg.processedLanguages.length > 0 ? (resultMsg.processedLanguages.map((lang, index) =>  <span>{(index ? ', ': '') + lang}</span>) ) : ''}</p> : null}
                {resultMsg && resultMsg.unprocessedLanguages && resultMsg.unprocessedLanguages.length > 0 ? <p>Script for these languages already exists: {resultMsg.unprocessedLanguages && resultMsg.unprocessedLanguages.length > 0 ? (resultMsg.unprocessedLanguages.map((lang, index) =>  <span>{(index ? ', ': '') + lang}</span>) ) : ''}</p> : null}
            </div>
        )
    }

    return (
            <div  className="tele-payment-modal">
            <div className="pay-modal-wrapper">
                <div className="duplicate-si-input-wrapper">
                    <div className="label">From:</div>
                    <div className="field"><input type='text' id='read-only' value={props.from.id} style={{width: '100%'}} readOnly/></div>
                </div>
                <div className="duplicate-si-input-wrapper">
                    <div className="label">To:</div>
                    <div className="field"><input type='text' style={{width: '100%'}} value={toScript} onChange={(e)=>setToScript(e.target.value)}/></div>
                </div>
                <div className="duplicate-si-input-wrapper language">
                    <div className="label-custom">Languages:</div>
                    <div className="checkbox-group">
                            <form>
                            <div class="checkboxes">
                            {
                                languages && languages.map(lang=>{
                                    return <label><input type="checkbox" checked={!lang.checked ? false : lang.checked} onChange={()=>selectLangToDuplicate(lang)} /> <span>{lang.name}</span></label>
                                })
                            }
                            </div>
                            </form>
                    </div>
                </div>
                {resultMsg ? showErrorMsg() : null}
                <div style={{ display: "flex", justifyContent: "flex-end", padding: '5% 0 0 0' }}>  <FrappButton className="pay-btn" disabled={checkForValidation()} handler={()=>createDuplicateIS()}> Duplicate the script </FrappButton></div>
            </div>
            </div>
    )
}

export default DuplicateIS