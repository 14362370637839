import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getProjectList = async(skip,limit, sort, search) => {
    let payload = generatePayload(skip,limit, sort, search)
    return await ApiActions.getActiveTeleProjects(payload)
}

const getTotalProjects = async(search) => {
    return await ApiActions.getTeleProjectsForAuditCount(search)
}

const createDuplicateScript = async(payload)=>{
    return await ApiActions.createDuplicateScript(payload)
}

export{
    getProjectList,
    getTotalProjects,
    createDuplicateScript
}