import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions';
import RouteTab from '../utilities/RouteTab';
import MaterialTable, { MTablePagination } from "material-table";
import FrappModal from "../utilities/FrappModal";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from '../utilities/FrappButton';
import { ToastContainer, toast } from "react-toastify";
import BrandSearch from './Tools/BrandSearch';
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import ExportJsonToCsv from '../../helper/ExportJsonToCsv';
import Heading from '../../uicomponents/heading'

export default class TringBrandsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandList: [],
            credits:0,
            activeBrand:{},
            formData: {
                startDate: "",
                endDate: ""
            }
        }
    }
    componentDidMount() {
        ApiActions.getBrandCreditList().then(resp => {
            // let resp2 = resp.map(obj => {
            //     obj["actions"] = this.actionBtns();
            // })

            this.setState({ brandList: resp })
        })
    }
    actionBtns = (brand) => {

      this.setState({activeBrand:brand})
        this.brandCreditsModal.toggle(true);

    }
    render() {
        let tabs = [
            {
                "name": "Projects",
                "route": "/projects"
            },
            {
                "name": "Brands",
                "route": "/brands"
            }
        ]
        return (
            <div style={{ padding: "6% 3% 3% 3%" }}>

                

                <div className="brands-credits-header-btn">
                    {/* <RouteTab tabArray={tabs} activeTab={tabs[1]} /> */}
                    <div style={{display: 'flex', justifyContent: 'space-between', width:'100%', marginBottom: '1.5%'}}>
                    <Heading text={'Brands'}/>
                    <div className="btn-group">
                    {/* <div> */}
                        <FrappButton
                            ref={c => (this.getReportBtn = c)}
                            className="download-credits-report"
                            handler={() => this.creditsReportModal.toggle(true)}
                        >
                            Credit Consumed Report    
                        </FrappButton>
                    {/* </div> */}
                    
                    {/* <div> */}
                        <FrappButton 
                            className="add-new-credits" 
                            handler={() => this.newBrandCredits.toggle(true)}
                        >
                            +Add Credits to Brand
                        </FrappButton>
                    {/* </div> */}
                    </div>
                    </div>
                </div>
                

                {this.renderBrandTable()}
                {this.renderNewCreditsModal()}
                {this.renderCreditsModal()}
                {this.renderCreditsReportModal()}
                <ToastContainer draggable={true} />
            </div>
        )
    }
    renderNewCreditsModal=()=>{
        let {activeBrand}=this.state;


        return (

            <FrappModal
                onCloseButton={true}
                className="assign-caller-modal"
              //  show={this.state.assignModal}
                ref={c => (this.newBrandCredits = c)}
                closeModal={() => this.newBrandCredits.toggle(false)}

            >
               <div className="pay-modal-wrapper" >
                    <div className="heading">Add credits</div>
                    <div className="label">
                        {/* Show Brand Logo */}
                             
                         <div className="brand-and-credits" id="credits-brands">
                              <BrandSearch onBrandSelect={this.onBrandSelect} ref={(c) => this.brandsearch = c} />
                             <div className="brand-container">
                                  <div className="logo"><img src={activeBrand.asseturl} /></div>
                                        <div>{activeBrand.name} </div>
                             </div>
                        </div>
                    </div>
                    <div className="label" >
                        <FormGroup id="input-credits">
                            <ControlLabel id="credits-control">Input credits:</ControlLabel>
                            <FormControl type="number" onChange={this.setCredits} />
                        </FormGroup>
                    </div>
                    {/* <div className="label" id="notif">Are you sure you want to add credits to the brand?
                        
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                     <FrappButton className="pay-btn" handler={() => this.addCredits(activeBrand)}> Add </FrappButton></div>
                </div>
            </FrappModal>

        )

    }

    onBrandSelect = (brand) => {
       this.setState({activeBrand:brand})
    }

    setFormDate = (date, end) => {

        let { formData } = this.state;
        if (!end) 
        {     date.utcOffset(0);
              date.set({hour:0,minute:0,second:0,millisecond:0})
              formData.startDate = date.toISOString();
        }
      
        if (end) 
        {    date.utcOffset(0);
             date.set({hour:23,minute:59,second:0,millisecond:0})
             formData.endDate = date.toISOString();
        }
        this.setState({ formData })
    }


    renderCreditsReportModal = () => {
        let { formData } = this.state;
        const triggerStartDatePicker = () => {
            this.setState({ startDateFocussed: true });
        };
        const triggerEndDatePicker = () => {
            this.setState({ endDateFocussed: true });
        };
        var startDate = formData&&formData.startDate
            ? moment.utc(new Date(formData.startDate))
            : null;
       
        var endDate = formData&&formData.endDate
            ? moment.utc(new Date(formData.endDate))
            : null;
        return (

            <FrappModal
                onCloseButton={true}
                className="assign-caller-modal"
                ref={c => (this.creditsReportModal = c)}
                closeModal={() => this.creditsReportModal.toggle(false)}

            >
                <div className="pay-modal-wrapper" >
                    <div className="heading">Brands credit consumed report</div>
                    <br/>
                    <div className="date-picker-wrapper">
                    <FormGroup>
                                <ControlLabel>Select Start Date</ControlLabel>


                                <div
                                    name="startDate"
                                    onClick={() => triggerStartDatePicker()}
                                    className="campaign-application-end-date-input"
                                >
                                    <SingleDatePicker
                                        noBorder={true}
                                        id="startDate"

                                        date={startDate}
                                        focused={this.state.startDateFocussed}
                                        isOutsideRange={() => false}
                                        //   isOutsideRange={this.checkIsOutsideRange}
                                        onFocusChange={focused => {
                                            this.setState({ startDateFocussed: focused.focused });
                                        }}
                                        onDateChange={(e) => this.setFormDate(e, 0)}
                                    //displayFormat={this.dateDisplayFormat()}
                                    />
                                    {startDate
                                        ? <h5>{startDate.format("ll")}</h5>
                                        : <h5></h5>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Select End Date</ControlLabel>
                                <div
                                    name="endDate"
                                    onClick={() => triggerEndDatePicker()}
                                    className="campaign-application-end-date-input"
                                >
                                    <SingleDatePicker
                                        noBorder={true}
                                        id="Date"

                                        date={endDate}
                                        focused={this.state.endDateFocussed}
                                        isOutsideRange={() => false}
                                        //   isOutsideRange={this.checkIsOutsideRange}
                                        onFocusChange={focused => {
                                            this.setState({ endDateFocussed: focused.focused });
                                        }}
                                        onDateChange={(e) => this.setFormDate(e, 1)}
                                    // displayFormat={this.dateDisplayFormat()}
                                    />
                                    {endDate
                                        ? <h5>{endDate.format("ll")}</h5>
                                        : <h5></h5>}
                                </div>
                            </FormGroup>
                    </div> 
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    <FrappButton 
                        className="pay-btn" 
                        // handler={() => document.getElementById("download-csv").click()}
                        handler={() => this.getCreditReport()}
                        ref={(c) => this.getReportBtn = c}
                    > DOWNLOAD REPORT </FrappButton>
                    </div>
                </div>
            </FrappModal>

        )

    }

    renderCreditsModal = () => {
        let {activeBrand}=this.state;
        return (

            <FrappModal
                onCloseButton={true}
                className="assign-caller-modal"
              //  show={this.state.assignModal}
                ref={c => (this.brandCreditsModal = c)}
                closeModal={() => this.brandCreditsModal.toggle(false)}

            >
                <div className="pay-modal-wrapper" >
                    <div className="heading">Add credits</div>
                    <div className="label">
                        {/* Show Brand Logo */}
        <div className="amount" id="credits-brands"><div className="logo"><img src={activeBrand.asseturl} /></div><div>{activeBrand.name} </div></div>
                    </div>
                    <div className="label" >
                        <FormGroup id="input-credits">
                            <ControlLabel id="credits-control">Input credits:</ControlLabel>
                            <FormControl type="number" onChange={this.setCredits} />
                        </FormGroup>
                    </div>
                    {/* <div className="label" id="notif">Are you sure you want to add credits to the brand?
                        
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                     <FrappButton className="pay-btn" handler={() => this.addCredits(activeBrand)}> Add </FrappButton></div>
                </div>
            </FrappModal>

        )

    }

    isStartDateGreater = (startDate, endDate) => {
        if(new Date(startDate).getTime() > new Date(endDate).getTime()) {
            return true
        }
        else {
            return false
        }
    }

    getCreditReport = () => {
        let startDate = new Date(this.state.formData.startDate).toLocaleDateString()
        let endDate = new Date(moment(this.state.formData.endDate).subtract(1, "days")).toLocaleDateString()

        if(this.isStartDateGreater(this.state.formData.startDate, this.state.formData.endDate)) {
            toast("End date should always be greater than start date 😜 ")
            return false
        }

        this.getReportBtn.startLoading()
        ApiActions.downloadBrandsCreditReport(startDate, endDate).then((resp) => {
            if(resp.length > 1) {
                let headers = ["brandName", "credits"]
                let filename = `BrandCreditReport${new Date().toLocaleDateString()}`
                let jsondata = resp // in array
                ExportJsonToCsv(headers, jsondata, filename)
            }else{
                toast("No data to download")
            }

            this.getReportBtn.stopLoading()
        })
        .catch(() => {
            toast("Error in credit download API")
            this.getReportBtn.stopLoading()
        })
    }

    addCredits=(activeBrand)=>{
        
    let {credits}=this.state;
    let amount=credits*6;
    let data={
        amount: amount,
        credits: parseInt(credits,10),
        brand: activeBrand.id

    }
    ApiActions.addBrandCredits(data).then(resp=>{
        this.brandCreditsModal.toggle(false);
        toast("Done");
        window.location.reload();
    
    }).catch(err => {
        toast("Error");
    });
    }
    setCredits=(e)=>{
        this.setState({credits:e.target.value})

    }
    renderBrandTable = () => {
        let { brandList } = this.state;
        return (
            <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsPerPage: '50'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                    columns={[
                        {
                            title: "Brand Id", field: "brand.id", cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filtering:false

                        },
                        {
                            title: "Brand name", field: "brand.name", cellStyle: {
                                fontSize: '14px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                            title: "Projects posted", field: "projectsPosted",  headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            cellStyle: {

                                width: '200px',
                                fontSize: '14px',
                                color: '#023349',
                                fontWeight: '400'
                            }

                        },
                        {
                            title: "Credits", field: "credits", type: "numeric", headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            cellStyle: {

                                width: '200px',
                                fontSize:'14px',
                                fontColor:'#023349',
                                fontWeight:'400'
                            },
                            filtering: false
                        },
                        {
                            title: "Actions",
                            //  field: "actions",
                            render: rowData => <div className="plus-icon">
                                <img src={require("../../assets/images/Icons_connect.svg")} 
                                onClick={() => this.actionBtns(rowData.brand)} />
                                <div className="tooltip"> Add Credits</div>
                                </div>
                            ,
                            cellStyle: {
                                fontSize: '14px',
                                color: 'black',
                                paddingLeft:'5%'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                paddingLeft:'5%',
                                textAlign:'center'
                            },
                            filtering: false
                        },


                    ]}

                    data={brandList}
                    // components={{
                    //     Pagination: props => (
                    //         <div style={{ fontSize: "20px" }}>
                    //             <MTablePagination rowsPerPage={50} {...props} />
                    //         </div>
                    //     )
                    // }}
                    options={{
                        filtering: true,
                        // grouping: true,
                       exportButton: true,
                        exportAllData:true,
                        columnsButton:true,
                        doubleHorizontalScroll:true,
                        pageSize:25,
                        pageSizeOptions:[25,50,75,100],
                        rowStyle: {
                            fontSize: "16px"
                        },
                        searchFieldStyle: {
                            boxShadow: 'none',
                            webkitboxShadow: 'none',
                        }
                    }}
                    title="Brands"
                />



            </div>
        )
    }
}
