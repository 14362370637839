import React, {useState, useEffect} from "react";
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'

const Message = ({ variant, children, timer = true }) => {
    // the alert is displayed by default
    const [alert, setAlert] = useState(true);
        
    useEffect(() => {
      // when the component is mounted, the alert is displayed for 3 seconds
      if(timer){
      setTimeout(() => {
        setAlert(false);
      }, 3000);
      }
    }, []);     
    return (
      alert && <div className={[styles.session_message_wrapper, styles[variant]].join(' ')}>{children}</div>
    )
  }

  export default Message