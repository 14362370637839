import React from 'react'
import styles from './tab.module.scss'

const TAB_COLORS = [
    'green-tab-outline',
    'purple-tab-outline',
    'orange-tab-outline',
    'green-tab-solid',
    'purple-tab-solid',
    'orange-tab-solid',
    'purple-tab-bottom-border',
    'navy-blue-tab-bottom-border'
]

const Tab = (props) => {
    let {color} = props || {}
    const checkStyle = TAB_COLORS.includes(color) ? color : TAB_COLORS[0]

    const handleClick = (event) => {
        props.onClick(event.target.innerText)
    }

    const addActiveClass = () => {
        const getColorInArray = color.split('-')
        const isBorder = getColorInArray.includes('border')
        if(isBorder){
            return `${styles[`${color}`]} ${styles.active}`
        }
        const changeColor = `${getColorInArray[0]}-${getColorInArray[1]}-solid`
        return styles[changeColor]
    }

    const isActive = props.activeTab === props.children ? true : false

    return(
        <>
        <div className={`${styles.tab} ${isActive ? addActiveClass() : styles[checkStyle]}`}
        onClick={(e) => handleClick(e)}>
        {props.children}
        </div>
        </>
    )
}

export default Tab