import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import FrappButton from "../utilities/FrappButton";
import Switch from "react-switch";
import TaskProps from '../../pages/project/main/taskprops';
import {HeaderDeleteIcon} from '../../assets/images'

export default class DefineLeads extends Component {
    constructor(props){
        super(props);
        this.state={
            formData:{},
            isLoading:true,
            isAllowDuplicateTouched:false,
            errorMsg: ''
        }
    }

    componentDidMount() {
            this._isMounted = true;
            let taskPropsDummy=[{
                "name":"mobile",
                "optional":false,
                "unique": true,
                "visible": false
            }]
            if(this._isMounted){
                let id = this.props.match.params.id;
                ApiActions.getTeleProject(id).then(resp=>{
                    this.setState({formData:resp})
                    if(resp.taskProps){
                        resp.taskProps.map(taskProp => {
                            if(!taskProp.hasOwnProperty('visible') && taskProp.name !== 'mobile'){
                                taskProp['visible'] = true
                            }
                            return taskProp
                        })
                        this.setState({taskProps:resp.taskProps,isLoading:false})
                    }
                    else this.setState({isLoading:false,taskProps:taskPropsDummy})
                })
            }
    }

    componentWillUnmount() {
    this._isMounted = false;
    }

    render() {
        let {isLoading,formData,taskProps}=this.state;
        if(!isLoading)
        return (
            <div className="mock-form-wrapper">
                <div className="heading">
                    <div className="arrow" onClick={() => this.handleBack()}>  
                        <img src={require("../../assets/images/back-arrow.png")} /> 
                        <div className="back">Back</div>
                    </div>
                    <div><h4>{formData.title}</h4></div><h5>Step 4: Define Leads </h5> 
                </div>
                {this.rendertaskProps(taskProps)}
                <ToastContainer draggable={true} />
            </div>
        )
         else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
    }

    handleBack = () => {
        let {formData}=this.state;
        this.props.history.push(`/mock-test/${formData.id}`)
    }

    
    rendertaskProps=(taskProps)=>{
        let {formData, errorMsg}=this.state;
        return(
           <form onSubmit={this.onSubmit} style={{paddingLeft:"5%"}} className="defineLeads">
            <div style={{border:"2px solid #EAEAEA",borderRadius:"12px",boxSizing:"border-box",padding:"5%",marginBottom:"10px"}}>
                {/* <FormGroup style={{display:"flex",flexDirection:"row",marginBottom:"0",height:"maxContent"}}>
                    <ControlLabel className='duplicateNumbers'>Duplicate lead</ControlLabel>
                    <Switch 
                        checked={formData.allowDuplicateTasks}
                        onChange={(e) => {
                            this.setDuplicate(e)
                            this.setState({isAllowDuplicateTouched:true})
                        }} 
                    />
                </FormGroup> */}
                {/* <div className="horizontalDivider"></div> */}
                <ol>
               { taskProps && taskProps.map((data,index)=>{
                // const checkIfUnique = data.unique;
                // let checkIfOptional;
                // if(!checkIfUnique){
                //     checkIfOptional = data.optional || true
                // }
                   return <li >
                        <FormGroup className="each-form">
                                        <FormControl required value={data.name?data.name:''} onChange={(e) => {this.setFormData(e, index)}} name="name" type="text" placeholder={data.name||"Enter Text"} disabled={data.name == "mobile"}/>
                                        <div className="dropdown-wrapper">
                                            <span className='headerControls'>

                                            <div className='checkbox-group'>
                                                <div className="checkboxes">
                                                <label><input type="checkbox" 
                                                checked={data.optional == false ? true : false}
                                                name="optional" onChange={(e)=>{this.toggleState(e,index)}} 
                                                disabled={data.name && data.name.toLowerCase() == "mobile" ? true : false}/> <span>Mandatory</span></label>
                                                {/* <label><input type="checkbox" checked={data.optional} name="optional" onChange={(e)=>{this.setOptional(e,index)}} /> <span>Optional</span></label> */}
                                                <label><input type="checkbox" checked={data.unique} name="unique" onChange={(e)=>{this.changeState(e,index)}} 
                                                disabled={data.optional == true ? true : false}/> <span className={data.optional == true ? 'disabled' : null}>Unique</span></label>
                                                <label><input type="checkbox" checked={data.visible} name="visible" onChange={(e)=>{this.changeState(e,index)}} 
                                                disabled={data.name && data.name.toLowerCase() == "mobile" ? true : false}/> <span>Visible</span></label>
                                                
                                                {/* <FormControl 
                                                componentClass="select" 
                                                placeholder="select"
                                                disabled={data.name === 'mobile' ? true : false}
                                                value = {checkIfUnique ? 'unique' : 'optional'}
                                                onChange={(e) => this.changeType(e ,index, data.name) }
                                                >
                                                <option value="optional">Optional</option>
                                                <option value="unique">Unique</option>
                                                </FormControl> */}
                                                {index > 0 && <FrappButton className="delete-question" handler={(e) => this.deleteHeader(e,index)}>
                                                    <img src={HeaderDeleteIcon} alt='delete' className='del-icon'/>
                                                </FrappButton>}
                                                </div>
                                            </div>
                                            </span> 
                                        </div>
                        </FormGroup>
                   </li>

                })}
                </ol>
                <FrappButton className="add-question" handler={(e) => this.addHeader(e)}> Add Header </FrappButton>
            </div>
            <div className='failure-msg'>{errorMsg}</div>
            <div className="btn-wrapper">
                
                    <FrappButton type="submit" className="submit" ref={(c) => this.submitBtn = c} >Save</FrappButton>
                    <FrappButton className="submit" id="new-btn-2" 
                ref={(c) => this.nextBtn = c} 
                handler={() =>   this.props.history.push({
                pathname: `/project-qc/${formData.id}`,
                state: {
                    formData
                }
            })}> Skip</FrappButton>
            </div>
           </form>
        )

    }

    addHeader=(data,i)=>{
        let {taskProps}=this.state;
        let newtaskProps=[];
        let header={
            "name":"",
            "optional":true,
            "visible": true
        }
        if(taskProps)
        {
            taskProps&&taskProps.push(header)
            this.setState({taskProps})
        }
        else 
        {
            taskProps=[{"name":"mobile","optional":false, "visible": false}]
            taskProps.push(header)
            this.setState({taskProps})
        }
       
    }

    deleteHeader=(e,i)=>{
        let {taskProps}=this.state;
        taskProps.splice(i,1)
        this.setState({taskProps})

    }

    setFormCategory=(val,i)=>{
        let { taskProps } = this.state;
        let dataTemp = taskProps[i];
        dataTemp.questionType= val.value;
        this.setState({ taskProps});
    }

    setFormData = (e,i) => {
        let { taskProps } = this.state;
        let dataTemp = taskProps[i];
        dataTemp[e.target.name] = e.target.value.toLowerCase();
        this.setState({ taskProps});
    }

    // changeType = (e, i, name) => {
    //     let { taskProps } = this.state;
    //     const modifiedTaskProps = taskProps.map((task) => {
    //         if(task.name == name){
    //             task = {
    //                 name,
    //                 [e.target.value]: true
    //             }
    //         }
    //         return task
    //     })
    //     this.setState({ taskProps: modifiedTaskProps});
    // }

    // deprecate the old code
    setOptional = (e,i) => {
        let { taskProps } = this.state;
        let dataTemp = taskProps[i];
        dataTemp[e.target.name] = !e.target.checked;
        if(e.target.checked === false){
            dataTemp["unique"] = true
        }
        this.setState({ taskProps});
    }

    toggleState = (e,i) => {
        let { taskProps } = this.state;
        let dataTemp = taskProps[i];
        if(e.target.checked == false){
            dataTemp["unique"] = false;
        }
        dataTemp[e.target.name] = !e.target.checked;
        this.setState({ taskProps});
    }

    changeState = (e, i) => {
        let { taskProps } = this.state;
        let dataTemp = taskProps[i];
        dataTemp[e.target.name] = e.target.checked;
        this.setState({ taskProps});
    }

    setDuplicate = (e) => {
        let { formData } = this.state;
        formData.allowDuplicateTasks = e
        this.setState({ formData});
    }

    // keep the keywords in lowercase for comparison
    reservedKeyWords = [
        'leadid',
        'id',
        'attempt',
        'attempts',
        'status',
        'unmasked',
        'createdat',
        'updatedat'
    ]

    getInvalidFieldsFromSchema = (fields) => {

        const invalidFields = []
        for(let field of fields) {
            if(this.reservedKeyWords.includes(field['name'].replace(/\s/g, '').toLowerCase())) {
                invalidFields.push(field['name'])
            }
        }

        return invalidFields
    }

    onSubmit=(e)=>{
        e.preventDefault();
        this.setState({errorMsg: ''})
        let {taskProps,formData}=this.state;

        let invalidFields = this.getInvalidFieldsFromSchema(taskProps || [])
        if(invalidFields.length > 0) {
            this.setState({errorMsg: `${invalidFields.join(', ')} cannot be added as a schema`})
            return false
        }

        this.submitBtn.startLoading();

        let dataToSend={
            "teleproject":formData.id,
            "taskProps":taskProps
        }
        if (this.state.isAllowDuplicateTouched > 0) {

            ApiActions.updateTeleProject(formData,this.state.formData.id).then(() => {
                this.setState({isAllowDuplicateTouched:0})
            }).catch(err => {
                console.error(err.response)
            })
        }

        ApiActions.sendTaskProps(dataToSend).then(resp=>{
            this.props.history.push({
                    pathname: `/project-qc/${resp.data.id}`,
                    state: {
                        formData
                    }
            })
            this.submitBtn.stopLoading();
            toast("Mock Test Updated")    
            setTimeout(function () { window.location.reload(); }, 2000);
        }).catch(err => {
                toast("Error")
        })
    }

}
