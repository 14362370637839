import React, { useState } from "react";
import TruncateString from "../../helper/TruncateString";
import FrappButton from './FrappButton'
import CustomTooltip from "./ToolTip";

const SearchBarMaterialTable = (props) => {
    const [searchValue, setSearchValue] = useState('')
    return (
        <div>
        <div style={{ display: 'flex', gap: '2em', justifyContent: 'flex-end', padding: '2%', alignItems: 'baseline' }}>
            {
                props.searchMode ?
                <CustomTooltip placement={'top'} description={props.showFilter} 
                component={
                    <div className="pill-wrapper">
                    <div className="pill">{TruncateString(props.showFilter, 20)}
                        <div className="del-icon">
                            <img src={require("../../assets/images/del-icon-taskprops.svg")} 
                            onClick={()=>props.refreshTable()}/></div>
                    </div>
                    </div>
                }>
                </CustomTooltip>
                : null
            }
            <input type='search' placeholder={props.placeholder} 
                // defaultValue = {props.searchText}
                style={{paddingLeft: '1%'}}
                onChange={e => {
                setSearchValue(e.target.value)
                }} 
                onKeyUp={event => event.key == "Enter"
                ? searchValue.length > 0 ? props.searchBtnTriggered(searchValue) : null : null}
                />
            <FrappButton className='submit'
                handler={() => searchValue.length > 0 ? props.searchBtnTriggered(searchValue)
                : null}
            >Search</FrappButton>
        </div>

        {props.isGeneratingCSV ? <span className='loader-dots'>Generating the CSV....</span> : null}
        </div>
    )
}

export default SearchBarMaterialTable
