import React, { useState, useRef, useEffect, useContext } from "react";
import Papa from 'papaparse';

//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Upload from "../../../uicomponents/upload/upload";
import FutworkButton from "../../../uicomponents/button/Button";
import checkIsEmpty from "../../../helper/checkIsEmpty";
import { AlertIcon, RefreshIcon } from "../../../assets/images";
import handleErrorMessage from "../../../helper/handleErrorMessage";
import ShowResult from "./showResult";
import { BulkActionContext } from "./bulkActionContext";
import ApiActions from "../../../actions/ApiActions";
import useApi from "../../../hooks/useApi";
import CSVSchema from "./csvSchema";

const BulkmoderationWithManualGrades = () => {
    const [file, setFile] = useState('')
    const [inputValue, setInputValue] = useState({
        fileData: ''
    })
    const [errorMsg, setErrorMsg] = useState({
        fileData: '',
        api_error: ''
    })
    const [successMsg, setSuccessMsg] = useState({
        data: '',
        msg: ''
    })

    const { circularProg, checkComplete, isComplete, setIsComplete } = useContext(BulkActionContext)

    const bulkModerationApi = useApi(ApiActions.bulkModeration)

    const { data, loading, error } = bulkModerationApi || {}

    useEffect(() => {
        setErrorMsg({
            ...errorMsg,
            api_error: handleErrorMessage(error)
        })
    }, [error])

    useEffect(() => {
        if (loading == false && (data && data.data)) {
            setIsComplete(2)
            if (data && data.data && data.data.unprocessed && data.data.unprocessed.length > 0) {
                setSuccessMsg({
                    msg: `Failed to moderate the manual grade for ${data && data.data && data.data.unprocessed.length} agent${data.data.unprocessed.length > 1 ? `'s` : ''}`,
                    data: data && data.data, failed: true
                })
            } else {
                setSuccessMsg({ msg: `${data && data.data && data.data.processed.length} agent${data.data.processed.length > 1 ? `'s` : ''} were certified`, data: data && data.data })
                setTimeout(() => {
                    circularProg.current.classList.toggle(styles['load_complete'])
                    checkComplete.current.style.display = 'block'
                }, 1000);
            }
            // setSuccessMsg({msg: 'The agents are certified', data: data && data.data})
        }
    }, [loading, data])

    const resetData = () => {
        setErrorMsg({ fileData: '', api_error: '' })
        setInputValue({ fileData: '' })
        // setIsComplete(0)
        // circularProg.current.classList.toggle(styles['circle_loader'])
        // checkComplete.current.style.display = 'none'
    }

    //call the function on choose the file
    const changeHandler = (e) => {
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) { //check if the attached file is csv
            if (e.target.files.length > 0) {
                setFile(file)
                Papa.parse(file, {
                    header: true,
                    complete: function (results) {
                        const sanitizedResult = results.data.map(obj => {
                            if (obj.mobile) {
                                return obj
                            }
                        }).filter(Boolean)
                        setInputValue({
                            fileData: sanitizedResult
                        })
                    }
                }
                )
            }
            const temp = { ...errorMsg }
            temp.fileData = ''
            setErrorMsg(temp)
        } else {
            setFile('')
            const temp = { ...errorMsg }
            temp.fileData = 'Only a file with .csv extension can be uploaded'
            setErrorMsg(temp)
        }
    }

    //check if a file is attached
    const checkIfDataPresent = () => {
        const { fileData } = inputValue
        if (fileData) return false
        else return true
    }

    //validate the data  in csv
    const checkIfValid = (csvheaders) => {
        let validationErrors = {};
        const multipleMandatoryHeaders = []
        csvheaders && csvheaders.forEach(item => {
            if (item.toLowerCase() == 'mobile') multipleMandatoryHeaders.push(item)
        });

        // Simple validation for empty fields
        for (const key in inputValue) {
            // if (key == 'wa_from' && (inputValue[key].length < 10 || !isNumeric(inputValue[key]))) {
            //     validationErrors[key] = 'Please enter a valid 10 digit mobile number'
            // }
            // if (key == 'fileData' && inputValue[key].length > 5000) {
            //     validationErrors[key] = 'Please make sure the CSV contains less than 5000 rows of data.'
            // }
            // if (key == 'fileData' && csvheaders.find(head => (head.toLowerCase() == 'earnings' && head.toLowerCase() == 'mobile')) == undefined) {
            //     // validationErrors[key] = 'Please ensure that the csv includes the earnings & mobile.'
            // }
            if (key == 'fileData' && multipleMandatoryHeaders && multipleMandatoryHeaders.length < 1) {
                validationErrors[key] = `Please ensure that "mobile" is mentioned in the csv schema`
            }
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            return false;
        } else {
            return true
        }
    }

    //upload the csv with base pay for corresponding agents
    const triggerBulkModeration = () => {
        const [first] = inputValue.fileData
        const body_vars = first ? Object.keys(first).map(item => item) : []
        if (checkIfValid(body_vars)) {
            // const payload = {
            //     wa_from: inputValue.wa_from.trim(),
            //     wa_template_id: inputValue.wa_template_id.trim(),
            //     wa_body_vars: body_vars,
            //     leadsAr: inputValue.fileData
            // }
            const formData = new FormData();
            // formData.append('teleprojectId', projectData.id);
            formData.append('file', file);
            bulkModerationApi.request(formData)
            setIsComplete(1)
        }
    }

    const props = {
        resetData,
        errorMsg,
        loading,
        successMsg
    }

    return <div>
        <div className={styles.crud_box}>
            {isComplete <= 0 ? <div className={styles.crud_form}>
                <CSVSchema />
                <br/>
                <div><i>Please make sure to add the grade within range A-D in capital letter</i></div>
                <div><i>Note: The maximum limit is 100 agents per upload</i></div>
                <br/>
                <Upload placeholder='Select CSV file to upload' onChange={changeHandler} />
                {
                    errorMsg && errorMsg.fileData ? <span className={styles.csv_error}>{errorMsg.fileData}</span> : null
                }
            </div> : <ShowResult {...props} />}
        </div>
        <div className={styles['text-center']}>
            <FutworkButton buttonStyle="primary--solid" buttonSize="medium"
                disabled={checkIfDataPresent()}
                onClick={triggerBulkModeration}>SUBMIT</FutworkButton>
        </div>
    </div>
}

export default BulkmoderationWithManualGrades