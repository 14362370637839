import React, { useState, useEffect, useContext } from "react";
//custom imports
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import FutworkButton from "../../../uicomponents/button/Button";
import useApi from "../../../hooks/useApi";
import { createUpdatePool } from './service';
import { ExoContext } from "./exoContext";
import { AlertIcon } from "../../../assets/images";

const DeletePool = (props) => {
    const { activeTab: accountSelected,
        deletePool: poolDetails, setDeletePool
    } = useContext(ExoContext)

    const createUpdatePoolApi = useApi(createUpdatePool)


    const [isConfirm, setIsConfirm] = useState(false)
    const [inputText, setInputText] = useState('')


    const checkIfValid = () => {
        if (inputText !== 'DELETE') return true
        else return false
    }

    const deletePool = () => {
        const { data } = accountSelected
        const { data: { poolId } } = poolDetails
        const findPool = data && data.pool && data.pool.find(item => {
            if (item.poolId == poolId)
                return item
        })
        const payload = {
            accountId: data.id,
            poolId: findPool && findPool.poolId,
            isDeleted: !findPool.isDeleted
        }
        createUpdatePoolApi.request(payload)
    }

    const { data, loading, error } = createUpdatePoolApi || {}

    const { data: { linkedProjects, poolName } } = poolDetails


    useEffect(() => {
        if (data) {
            setInputText('')
            setIsConfirm(false)
            props.updateList(data.data)
            setDeletePool({
                status: false,
                data: {}
            })
        }
    }, [!loading && data !== null])

    return (
        <div className={styles.delete_pool}>
            {
                isConfirm ?
                    <div>
                        <p className={styles.header}>Type ‘DELETE’ below to delete the exophone</p>
                        <div className={styles.content}>
                            <input type="text" placeholder="Type here" onChange={(e) => setInputText(e.target.value)} />
                        </div>
                    </div>
                    :
                    <div>
                        {
                            linkedProjects && linkedProjects > 0 ?
                                <>
                                    <img src={AlertIcon} className={styles.alert} alt="alert icon" />
                                    <div className={styles.header}>
                                        <p>{linkedProjects} {linkedProjects > 1 ? 'projects' : 'project'} are already linked to the <i>"{poolName}"</i></p>
                                        <p>Please delink the {linkedProjects > 1 ? 'projects' : 'project'} before proceeding with the delete action</p>
                                    </div>
                                </>
                                :
                                <>
                                    <p className={styles.header}>Are you sure you want to delete this pool?</p>
                                    <div className={styles.content}>
                                        <p>All the exophones created in this pool will be deleted permanently</p>
                                    </div>
                                </>
                        }
                    </div>
            }

            {!linkedProjects &&
                <div className={styles.cta_footer}>
                    <FutworkButton buttonSize='sm' buttonStyle='primary--outline' onClick={() => setDeletePool({
                        status: false,
                        data: {}
                    })}>Cancel</FutworkButton>
                    {
                        isConfirm ?
                            <FutworkButton buttonSize='sm' buttonStyle='danger--solid' disabled={checkIfValid()} onClick={() => deletePool()}>Delete</FutworkButton>
                            :
                            <FutworkButton buttonSize='sm' buttonStyle='danger--solid' onClick={() => setIsConfirm(true)}>Delete</FutworkButton>
                    }
                </div>}
        </div>
    )
}

export default DeletePool