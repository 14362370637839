import React, { useState } from 'react'

const CustomTab = ({tabs, setActiveTabFn, className, currentTab = ''}) => {
    const [activeTab, setActiveTab] = useState(currentTab ? currentTab.value : tabs[0].value)
    return (
        <div className={`custom-tab-container ${className ? className : ''}`}>
            {
                tabs.map((tab,i) => {
                    return <div className={`custom-tab ${activeTab == tab.value ? 'active' : ''} ${className ? className : ''}`} key={i} onClick={() => {
                        setActiveTab(tab.value)
                        setActiveTabFn(tab)
                        }}>
                    {tab.label}
                    </div>
                })
            }
        </div>

    )
}

export default CustomTab