import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import FrappButton from "../utilities/FrappButton"


export default class MockTest extends Component {
     constructor(props){
        super(props);
        this.state={
            formData:{},
            isLoading:true
        }
    }

        componentDidMount() {
              this._isMounted = true;
              let mockFormDummy=[{
                  "questionText":"",
                  "questionType":"voice-recorder"
              }]
               if(this._isMounted){
                     let id = this.props.match.params.id;
                      ApiActions.getTeleProject(id).then(resp=>{
                        this.setState({formData:resp})
                        if(resp.mockForm){
                           this.setState({mockForm:resp.mockForm,isLoading:false})
                        }
                        else 
                        this.setState({isLoading:false,mockForm:mockFormDummy})
                      })
               }
        }

         componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        let {isLoading,formData,mockForm}=this.state;
        if(!isLoading)
        return (
            <div className="mock-form-wrapper">
                 <div className="heading">
                    <div className="arrow" onClick={() => this.handleBack()}>  
                      <img src={require("../../assets/images/back-arrow.png")} /> 
                     <div className="back">Back</div>
                     </div>
                    <div><h4>{formData.title}</h4></div><h5>Step 3: Add Mock </h5> </div>
               {this.renderMockForm(mockForm)}
                    <ToastContainer draggable={true} />
            </div>
        )
         else
        return <div className="loader-main" id="loader-size">  
            <img src={require("../../assets/images/loadernew.gif")}/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
    }
      handleBack = () => {
          let {formData}=this.state;
        this.props.history.push(`/add-quiz/${formData.id}`)
      //  this.setState({ createProject: false })
    }
    renderMockForm=(mockForm)=>{
        let {formData}=this.state;
         let options=[
           { label: "Voice Recorder",value:"voice-recorder"}
        ]

        return(
           <form onSubmit={this.onSubmit} style={{paddingLeft:"5%"}}>
               { mockForm&&mockForm.map((data,index)=>{
                   return <div >
                     <FormGroup className="each-form">
                                {/* <ControlLabel>Question {index + 1}</ControlLabel> */}
                                <FormControl required value={data.questionText || ""} onChange={(e) => this.setFormData(e, index)} name="questionText" type="text" placeholder="Enter Qs." />
                               <div className="dropdown-wrapper">
                                     <Dropdown options={options} 
                                          //onChange={(val)=>this.setFormCategory(val,index)} 
                                           value={data.questionType||"voice-recorder"} 
                                           placeholder="Select an option" />
                                           <div >
                                        
                                <FrappButton className="delete-question" handler={(e) => this.deleteQuestion(e,index)}>
                                        Delete Question
                                </FrappButton>
                               </div>
                                </div>
                            </FormGroup>
                   </div>

                
                })}
                 <FrappButton className="add-question" handler={(e) => this.addQuestion(e)}>
                                        Add a new question
                            </FrappButton>
              <div className="btn-wrapper">
                   
                     <FrappButton type="submit" className="submit" ref={(c) => this.submitBtn = c} >Save</FrappButton>
                      <FrappButton className="submit" id="new-btn-2" 
                   ref={(c) => this.nextBtn = c} 
                   handler={() =>   this.props.history.push({
                    pathname: `/define-leads/${formData.id}`,
                    state: {
                        formData
                    }
                })}> Skip</FrappButton>
              </div>
           </form>
        )

    }
    addQuestion=(data,i)=>{
        let {mockForm}=this.state;
        let newMockForm=[];
        let question={
            "questionText":"",
            "questionType": "voice-recorder"
        }
        if(mockForm)
        {
         mockForm&&mockForm.push(question)
         this.setState({mockForm})
        }
        else 
        {
            mockForm=[]
            mockForm.push(question)
         this.setState({mockForm})
        // this.setState({mockForm:newMockForm})
        }
       
    }
    deleteQuestion=(e,i)=>{
        let {mockForm}=this.state;
        mockForm.splice(i,1)
        this.setState({mockForm})

    }
    setFormCategory=(val,i)=>{
        let { mockForm } = this.state;
       let dataTemp = mockForm[i];
        dataTemp.questionType= val.value;
        this.setState({ mockForm});
    }
     setFormData = (e,i) => {
        let { mockForm } = this.state;
        let dataTemp = mockForm[i];
        dataTemp[e.target.name] = e.target.value;
        this.setState({ mockForm});
    }
    onSubmit=(e)=>{
        e.preventDefault();
        this.submitBtn.startLoading();
        let {mockForm,formData}=this.state;
        let dataToSend={
            "teleproject":formData.id,
            "mockForm":mockForm
        }
        ApiActions.addOrUpdateMockForm(dataToSend).then(resp=>{
             this.props.history.push({
                    pathname: `/define-leads/${resp.data.data.id}`,
                    state: {
                        formData
                    }
                })
            this.submitBtn.stopLoading();
            toast("Mock Test Updated")    
              setTimeout(function () { window.location.reload(); }, 2000);
        }).catch(err => {
                toast("Error")
            })
    }
}
