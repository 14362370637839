import React, { Component } from 'react';
import RouteTab from '../../utilities/RouteTab';
import ApiActions from '../../../actions/ApiActions';
import MaterialTable, { MTablePagination, MTableHeader, MTableToolbar } from "material-table";
import Checkbox from 'react-bootstrap/lib/Checkbox';
import cloneDeep from "lodash/cloneDeep";
import { ToastContainer, toast } from "react-toastify";
import FrappButton from '../../utilities/FrappButton';
import FrappModal from "../../utilities/FrappModal";
import Dropdown from 'react-dropdown';
import { Fragment } from 'react';
import CustomTab from '../../utilities/CustomTab';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Radio from 'react-bootstrap/lib/Radio'
import SearchBarMaterialTable from '../../utilities/SearchBarMaterialTable';
import ExportJsonToCsv from "../../../helper/ExportJsonToCsv";
import ProjectTitle from '../../common/ProjectTitle'
import jwt_decode from "jwt-decode";
import { userRoleReason, userRoleAction } from '../../pages/Hiring/HiringFunctions'
import {styleTheFeedback, tooltip} from './UIFunctions'
import JobsHistory from '../../../pages/caller/roaster/jobHistory.jsx'
import {TrackHistory, FeedbackIcon, AnswerIcon} from '../../../assets/images'
import ReactTable from '../../../uicomponents/table/Table';
import CustomTooltip from '../../utilities/ToolTip';
import moment from 'moment'
import Popup from '../../../uicomponents/popup'
import Panel from '../../../uicomponents/panel'
import {faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import capitalise from '../../../helper/Capitalise'
import CustomTable from '../../../uicomponents/table/Table'
import FutworkButton from '../../../uicomponents/button/Button.jsx';
import TrainingMeeting from '../../../pages/hiring/project-training-session/trainingMeeting.jsx';
class JobApplicationsApplied extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
        this.toastReportDownload = React.createRef()
        this.state = {
            skip: 0,
            appliedCallers: [],
            rejectedCallers: [],
            pendingCallers: "",
            pendingCallersRejected: "",
            callersExistingJobs: [],
            activeTab: {
                label: this.tabsForStage[0].label,
                value: this.tabsForStage[0].value
            },
            isTableLoadingApplied: false,
            isTableLoadingRejected: false,
            tableMode: 'applied',
            query: {
                page: 1,
                pageSize: 10,
            },
            totalCount: 0,
            pageChanged: true,
            skip: 0,
            limit: 10,
            searchMode: false,
            isGeneratingCSV: false,
            searchText: '',
            isManualFeedback: false,
            showCallerHistory: false,
            pastProjects:'',
            rowSelected: '',
            currentPage: 1,
            limit: 10,
            showIqcFdbk: false,
            currentPageIqcCallAudit: 1,
            showTrainingMeetings: false
        }
        this.loggedInUser = jwt_decode(localStorage.authToken)
    }
    componentDidMount() {
        // this.setState({projectData: this.props.location.state.projectdata})

        // let { skip } = this.state;
        if (this.props.match.params.id) {
            this.setState({ isTableLoadingApplied: true })
            ApiActions.getTeleProject(this.props.match.params.id).then(resp1 => {
                this.setState({ formData: resp1 })
            })
            ApiActions.getReapplyFeedbacks()
            .then(response => {
                this.setState({
                    reapplyFeedbacks : response[0].reapplyFeedbacks
                })
            }).catch(err => toast(err.message))
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getAppliedCallersList(this.props.match.params.id, skip, this.state.limit).then(resp2 => {
                //  skip=skip+50;
                //  this.setState({skip})
                this.setState({
                    appliedCallers: resp2.records,
                    pendingCallers: resp2.totalPendingCallers,
                    isTableLoadingApplied: false,
                    totalCount: resp2.totalPendingCallers
                }, () => this.addCheckedField())
            }).catch(err => toast(err.message))
        }
    }

    tabsForStage = [
        {
            label: 'Applied',
            value: 'applied'
        },
        {
            label: 'Rejected',
            value: 'rejected'
        }
    ]

    fetchCallersBasedOnStage = (id, activeTab) => {
        this.setState({ tableMode: activeTab.value })
        this.setState({
            query: {
                page: 1,
                pageSize: 10,
            },
            pageChanged: true,
            skip: 0,
            limit: 10
        })
        if (activeTab.value == 'rejected') {
            this.setState({ isTableLoadingRejected: true })
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getRejectedCallersList(id, skip, this.state.limit).then(resp => {
                this.setState({
                    rejectedCallers: resp.records,
                    pendingCallersRejected: resp.totalRejectedCallers,
                    totalCount: resp.totalRejectedCallers
                })
                this.setState({ isTableLoadingRejected: false })
            }).catch(err => {
                console.error(err)
                toast(err.message)
                this.setState({ isTableLoadingRejected: true })
            })
        } else {
            this.setState({ isTableLoadingApplied: true })
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getAppliedCallersList(id, skip, this.state.limit).then(resp2 => {
                this.setState({
                    appliedCallers: resp2.records,
                    pendingCallers: resp2.totalPendingCallers,
                    totalCount: resp2.totalPendingCallers
                }, () => this.addCheckedField())
                this.setState({ isTableLoadingApplied: false })
            }).catch(err => {
                this.setState({ isTableLoadingApplied: true })
                toast(err.message)
                console.error(err.message)
            })
        }
    }
    addCheckedField = () => {
        let { appliedCallers } = this.state;
        let dummyObj = cloneDeep(appliedCallers)
        dummyObj.map(function (datum) {
            datum.checked = false;
            return datum;
        });
        this.setState({ appliedCallers: dummyObj })
    }
    fetchData = () => {
        if (this.state.activeTab.value == 'applied') {
            let { appliedCallers, formData, limit } = this.state;
            let newData = [];
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getAppliedCallersList(formData.id, skip, limit).then(resp => {
                newData = appliedCallers.concat(resp.records)
                this.setState({ appliedCallers: newData }, () => this.addCheckedField())
            })
        }
    }

    fetchDataForTable = (additionalState) => {
        if (this.state.activeTab.value == 'applied') {
            this.setState({ isTableLoadingApplied: true })
            const { query, totalCount, formData } = this.state
            let skip = (query.page - 1) * 10
            this.setState({ skip: skip })
            ApiActions.getAppliedCallersList(formData.id, skip, query.pageSize).then(resp => {
                this.setState(
                    {
                        ...additionalState,
                        appliedCallers: resp.records,
                        totalCount: totalCount > 0 ? totalCount : this.totalPages
                    }, () => this.addCheckedField());
                this.setState({ isTableLoadingApplied: false })
            }).catch(err => {
                this.setState({ isTableLoadingApplied: false })
                toast("Error");
                console.error(err)
            })
        } else {

            this.setState({ isTableLoadingRejected: true })
            const { query, totalCount, formData } = this.state
            let skip = (query.page - 1) * 10
            this.setState({ skip: skip })

            ApiActions.getRejectedCallersList(formData.id, skip, this.state.limit, query.pageSize).then(resp2 => {
                this.setState({ ...additionalState, rejectedCallers: resp2.records, totalCount: totalCount > 0 ? totalCount : this.totalPages });
                this.setState({ isTableLoadingRejected: false })
            }).catch(err => {
                this.setState({ isTableLoadingRejected: true })
                toast(err.message)
                console.error(err.message)
            })
        }
    }
    render() {
        const {title} = this.props.location.state.projectdata
        let tabs = [

            {
                "name": "Applied Callers",
                "route": `/hiring/applied-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            },
            {
                "name": "Hiring",
                "route": `/hiring/${this.props.match.params.id}`,
                "state":  this.props.location.state
            },
            {
                "name": "Retraining",
                "route": `/hiring/for-training-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            }
        ]

        let selectedCallerTab = [
            {
                "name": "Selected Callers",
                "route": `/hiring/selected-callers/${this.props.match.params.id}`,
                "state":  this.props.location.state
            }
        ]
        const setActiveTabFn = (data) => {
            this.setState({searchMode: false,})
            this.setState({ activeTab: { label: data.label, value: data.value } })
            //reset the page index to start
            //this.tableRef.current.onChangePage(null, 0);
            this.setState({currentPage: 1, rowSelected: '', searchText: ''}, () => {
                this.fetchCallersBasedOnStage(this.props.match.params.id, data)
            })
        }
        const goBack = () => {
            this.props.history.push("/hiring")
        }

        return (
            <div className="job-applications">
            <ProjectTitle title={title} goBack={goBack} />
                {/* <div style={{display: 'flex'}}>
                        <RouteTab tabType='multi' tabArray={tabs} activeTab={tabs[0]}/>
                        <RouteTab tabType='single' tabArray={selectedCallerTab} activeTab={''}/>
                  </div> */}
                <div className="bulk-status-row">
                    <div className="section-1">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <RouteTab tabType='multi' tabArray={tabs} activeTab={tabs[0]} />
                            <RouteTab tabType='single' tabArray={selectedCallerTab} activeTab={''} />
                        </div>
                    </div>
                    <div className="section-2">
                        <FutworkButton buttonSize='sm' buttonStyle='primary--solid'
                        onClick={() => {
                            this.props.history.push({
                                pathname: `/hiring/training-session/${this.props.match.params.id}`,
                                state: {
                                    projectdata: this.state.formData || this.props.location.state.projectdata
                                }
                            })
                        }}
                        >Training session</FutworkButton>

                        < FrappButton className="bulk-status-btn"
                            handler={() => this.handleBulkClick()}>Bulk Status Update </FrappButton>
                    </div>

                </div>
                <CustomTab tabs={this.tabsForStage} setActiveTabFn={setActiveTabFn} />
                {this.renderTable(this.state.activeTab.value)}
                {/* {this.renderAppliedCallersTable()} */}
                {this.renderBulkStatusPopup()}
                {this.reapplyFeedback()}
                {this.mockFormModal()}
                {this.trainingModal()}
                {this.state.showTrainingMeetings && this.trainingMeetingModal()}
                {this.renderCallerHistoryPopup()}
                {this.renderPastProjectsPopup()}
                {this.renderAllFeedbackPopup()}
                {this.state.showIqcFdbk ? this.renderIqcFdbk() : null}
                <ToastContainer draggable={true} />
            </div>
        )
    }
    trainingModal = () => {
        let { trainingData } = this.state;
        if (trainingData)
            return (
                <FrappModal
                    onCloseButton={true}
                    className="tele-payment-modal"
                    // show={this.state.showCertifyModal}
                    ref={c => (this.trainingPopup = c)}
                    closeModal={() => this.trainingPopup.toggle(false)}
                >
                    <div className="mock-form-modal">
                        <div className="heading">Training Data </div>
                        {trainingData && trainingData.map((form, i) => {
                            return (
                                <div className="wrapper-audio">
                                    <div className="fields">
                                        <div className="each-field">
                                            <div className="label-training">Manual Score</div>
                                            {form.manualScore}
                                        </div>
                                        <div className="each-field">
                                            <div className="label-training">Audio</div>
                                            <div className="audio"><audio controls src={form.testAudioUrl}></audio> </div>
                                        </div>
                                        <div className="each-field">
                                            <div className="label-training">Manual Grade Result</div>
                                            <div className="grades">
                                                <div className="grade-wrapper"> <div className="label-grades"> Pronounciation</div><div> {form.manualGradeResult.pronunciation}</div></div>
                                                <div className="grade-wrapper"> <div className="label-grades"> Tonality</div><div> {form.manualGradeResult.tonality}</div></div>
                                                <div className="grade-wrapper"> <div className="label-grades"> Script Preparedness</div> <div>{form.manualGradeResult.scriptPreparedness}</div></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </FrappModal >
            )
        else
            return (
                <FrappModal
                    onCloseButton={true}
                    className="tele-payment-modal"
                    // show={this.state.showCertifyModal}
                    ref={c => (this.trainingData = c)}
                    closeModal={() => this.trainingData.toggle(false)}
                >
                    <div className="mock-form-modal">
                        <h4> No Data Found</h4>
                    </div>

                </FrappModal >
            )

    }
    trainingMeetingModal = () => {
        let { trainingMeetings } = this.state;
            return (
            <Popup
            show={this.state.showTrainingMeetings}
            closePopup={() => {
                this.setState({
                    trainingMeetings: '',
                    showTrainingMeetings: false
                })
            }}
            size='sm'
            heading={'Training Meetings'}
            >
            <div className='training-meetings-wrapper'>
                <TrainingMeeting meetings = {trainingMeetings} />
            </div>
            </Popup>
            )
    }
    mockFormModal = () => {
        let { mockFormData } = this.state;
        if (mockFormData)
            return (
                <FrappModal
                    onCloseButton={true}
                    className="tele-payment-modal"
                    // show={this.state.showCertifyModal}
                    ref={c => (this.mockTestModal = c)}
                    closeButtonClicked={() => {
                        this.mockTestModal.toggle(false)
                        this.setState({ mockFormData: '' })
                    }
                    }
                >
                    <div className="mock-form-modal">
                        <div className="heading">Mock Test </div>
                        {mockFormData && mockFormData.map((form, i) => {
                            return (
                                <div className="wrapper-audio">
                                    <div className="question">{i + 1}.&nbsp;{form.question} </div>
                                    <div className="audio"><audio controls src={form.audioUrl}></audio> </div>
                                </div>
                            )
                        })}
                    </div>

                </FrappModal >
            )
        else
            return (
                <FrappModal
                    onCloseButton={true}
                    className="tele-payment-modal"
                    // show={this.state.showCertifyModal}
                    ref={c => (this.mockTestModal = c)}
                    closeModal={() => this.mockTestModal.toggle(false)}
                >
                    <div className="mock-form-modal">
                        <h4> No Data Found</h4>
                    </div>

                </FrappModal >
            )

    }

    renderReapplyFeedbackOptions = () => {
        let appliedCallers = this.state.appliedCallers;
        let { feedback, isManualFeedback, reapplyFeedbacks } = this.state;
        return (
            <Fragment>
                <div className="bulk-status-modal">
                    <div className="heading">Reapply_</div>
                    <div className="radio-btn-reapply">
                        <FormGroup className="reapply-form-group">
                            {
                                reapplyFeedbacks && reapplyFeedbacks.map((item, index) => {
                                    return (
                                        <Radio
                                            key={`reapply-fdbk-${index}`}
                                            className="reapply-radio"
                                            name="upload_radio"
                                            checked={feedback === item}
                                            value={item}
                                            onChange={e => this.setState({ feedback: e.target.value, isManualFeedback: false })}
                                        >
                                            {item}
                                        </Radio>
                                    )
                                })
                            }
                            
                            <Radio
                                className="reapply-radio"
                                name="upload_radio"
                                checked={isManualFeedback}
                                value="other"
                                onChange={e => this.setState({ isManualFeedback: true, feedback: "" })}
                            >
                                Other
                            </Radio>
                        </FormGroup>
                        
                    </div>
                    {
                        isManualFeedback
                        ? <input type="text" value={feedback}
                            onChange={(e) => this.setState({ feedback: e.target.value })} />
                        : ""
                    }
                </div>
            </Fragment>
        )
    }
    reapplyFeedback = () => {
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.reapplyModal = c)}
                closeModal={() => this.reapplyModal.toggle(false)}
            >
                {this.renderReapplyFeedbackOptions()}
                <div className="bulk-submit-button">
                    <FrappButton className="submit" id="bulk-status-btn"
                        handler={() => this.changeUserStatus()}>
                        Submit
                    </FrappButton>
                </div>
            </FrappModal >
        )

    }
    changeUserStatus = () => {
        let { formData, feedback, userId, appliedCallers } = this.state;
        const role = this.loggedInUser.auth  
        ApiActions.changeUserStatus(
            {
                "teleproject": formData.id,
                "user": userId,
                "status": "reapply",
                "feedback": feedback,
                "reason": `Reapply by ${userRoleReason(role)}`,
                "actionBy": userRoleAction(role)
            }
        ).then(resp => {
            // this.telemodal.toggle(true);

            if (this.state.activeTab.value == 'applied') {
                this.reapplyModal.toggle(false)
                if(resp.data.status == ("success" || 200)){
                    toast(resp.data.msg)
                }
                const updatedCallerList = appliedCallers.filter(caller =>
                    caller.id !== userId
                )
                this.setState({ appliedCallers: [] })
                this.setState({ appliedCallers: updatedCallerList })
            }
    
            this.setState({ totalCount: this.state.totalCount - 1 })

        }).catch(err => {
            toast("error");
        });

    }
    renderBulkStatusPopup = () => {
        let appliedCallers = this.state.appliedCallers;
        const { tableMode } = this.state
        let options;
        if (tableMode == 'applied') {
            options = [
                { label: "Shortlist", value: "training" },
                { label: "Reapply ", value: "reapply" },
                { label: "Reject", value: "rejected" },
            ]
        } else {
            options = [
                { label: "Shortlist", value: "training" },
                { label: "Select", value: "inprogress" }
            ]
        }
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.bulkModal = c)}
                closeModal={() => this.bulkModal.toggle(false)}
            >
                <div className="bulk-status-modal">
                    <div className="heading">Bulk status update</div>
                    <div className="dropdown-bulk">
                        <Dropdown options={options}
                            onChange={(val) => this.selectBulk(val)}
                            value={this.state.bulkStatus}
                            placeholder="Select an option" />
                    </div>
                    {
                        this.state.bulkStatus === "reapply" 
                        ? this.renderReapplyFeedbackOptions()
                        : null
                    }
                    <div className="bulk-submit-button">
                        <FrappButton className="submit" id="bulk-status-btn"
                            handler={() => this.sendBulkStatusUpdate()}>
                            Submit
                        </FrappButton>
                    </div>

                </div>
            </FrappModal >
        )

    }
    selectBulk = (val) => {
        this.setState({ bulkStatus: val.value })
    }
    sendBulkStatusUpdate = async() => {
        let { appliedCallers, bulkStatus, formData, feedback } = this.state;
        const role = this.loggedInUser.auth
        let arrId = [];
        let filtered = this.state.rowSelected
        // appliedCallers.filter(caller => {
        //     return caller.checked === true
        // })
        filtered.forEach(data => {
            arrId.push(data.id)
        })
        if (feedback && feedback.length > 0 && bulkStatus === "reapply") {
            let result = []
            for (const user of filtered) {
                let dataToSend={
                    "teleproject":formData.id,
                    "user":user.id,
                    "status":bulkStatus,
                    "feedback": feedback,
                    "reason": `Reapply by ${userRoleReason(role)}`,
                    "actionBy": userRoleAction(role)
                }

                try{
                    const updateResult = await ApiActions.sendBulkStatusUpdate(dataToSend)
                    if(updateResult.status == "success" || 200)
                    result.push(`${user.mobile} -- ${updateResult.data.msg}`)
                }catch(err){
                    result.push(`${user.mobile} -- ${err.response.data.msg}`)
                }
            }
            this.bulkModal.toggle(false)
            let list = <div>
            {
                 result.map(item => <p>{item}</p>)
            }
            </div>
       
            if(result){
                toast(list)
                const updatedCallerList = appliedCallers.filter(caller =>
                    !arrId.includes(caller.id)
                )
                this.setState({ appliedCallers: updatedCallerList })
            }
        }
        else {
            let result = []
            for (const user of filtered) {
                let dataToSend={
                    "teleproject":formData.id,
                    "user":user.id,
                    "status":bulkStatus,
                    "reason": `${bulkStatus} by ${userRoleReason(role)}`, 
                    "actionBy": userRoleAction(role)
                }
                try{
                    const updateResult = await ApiActions.sendBulkStatusUpdate(dataToSend)
                    if(updateResult.status == "success" || 200)
                    result.push(`${user.mobile} -- ${updateResult.data.msg}`)
                }catch(err){
                    result.push(`${user.mobile} -- ${err.response.data.msg}`)
                }
            }
            this.bulkModal.toggle(false)
            let list = <div>
            {
                 result.map(item => <p>{item}</p>)
            }
            </div>

            if(result){
                toast(list)
                const updatedCallerList = appliedCallers.filter(caller =>
                    !arrId.includes(caller.id)
                )
                this.setState({ appliedCallers: updatedCallerList })
            }
        }

    }
    handleBulkClick = () => {
        this.bulkModal.toggle(true);
    }
    selectRowData = (rowData) => {
        let { appliedCallers } = this.state;
        let selected = rowData.checked;
        rowData.checked = !selected;
        this.setState({ appliedCallers })
    }
    selectAllRows = () => {
        let { appliedCallers, selectAll } = this.state;
        selectAll = !selectAll
        this.setState({ selectAll })
        if (selectAll) {
            let newObj = cloneDeep(appliedCallers)
            newObj.map(function (datum) {
                datum.checked = true;
                return datum;
            });
            this.setState({ appliedCallers: newObj })
        }
        else {
            let newObj = cloneDeep(appliedCallers)
            newObj.map(function (datum) {
                datum.checked = false;
                return datum;
            });
            this.setState({ appliedCallers: newObj })
        }

    }
    _onSelect = (data, val) => {
        const role = this.loggedInUser.auth 
        let formData = this.state.formData;
        if (val.value === "reapply") {
            this.setState({ userId: data.id }, () => this.reapplyModal.toggle(true))
        }
        else {
            ApiActions.changeUserStatus(
                {
                    "teleproject": formData.id,
                    "user": data.id,
                    "status": val.value,
                    "reason": `${val.value} by ${userRoleReason(role)}`,
                    "actionBy": userRoleAction(role)
                }
            ).then(resp => {
                if (resp.data.status == ("success" || 200)) {
                    toast(resp.data.msg || "Application status was changed successfully")
                    if (this.state.activeTab.value == 'rejected') {
                        const updatedCallerList = this.state.rejectedCallers.filter(caller =>
                            caller.id !== data.id
                        )
                        this.setState({ rejectedCallers: [] })
                        this.setState({ rejectedCallers: updatedCallerList })
                    }
                    if (this.state.activeTab.value == 'applied') {
                        const updatedCallerList = this.state.appliedCallers.filter(caller =>
                            caller.id !== data.id
                        )
                        this.setState({ appliedCallers: [] })
                        this.setState({ appliedCallers: updatedCallerList })
                    }

                    this.setState({ totalCount: this.state.totalCount - 1 })

                } 
            }).catch(err => {
                toast(err.response && err.response.data ? err.response.data.msg : 'Error' )
            });
        }
    }

    handleAudioClick = (rowData) => {
        if (rowData.mockFormData)
            this.setState({ mockFormData: rowData.mockFormData }, () => this.mockTestModal.toggle(true))
        else this.mockTestModal.toggle(true)
    }
    handleTrainingClick = (rowData) => {
        if (rowData.training)
            this.setState({ trainingData: rowData.training }, () => this.trainingPopup.toggle(true))
        else this.trainingPopup.toggle(true)
    }
    handleSessionClick = (rowData) => {
        if (rowData.trainingMeetings) {
            this.setState({showTrainingMeetings: true})
            this.setState({ trainingMeetings: rowData.trainingMeetings })
        }
    }

    showCallerHistoryPopup = (rowData) => {
        let data = {
            user: rowData.id
        }
        ApiActions.getCallersCurrentJobs(data).then(resp => {
            this.setState({ callersExistingJobs: resp.data })
        }).catch(err => console.error(err))
        this.callerAuditModal.toggle(true);
    }

    showAllTheFeedbacks = (rowData) => {
        this.setState({ currentFeedback: rowData.feedback.split(',') })
        this.feedbackModal.toggle(true)
    }

    renderCallerHistoryPopup = () => {
        const { callersExistingJobs } = this.state
        const customWordForStatus = (status) => {
            switch (status) {
                case 'training':
                    return 'Shortlisted'
                    break;
                case 'inprogress':
                    return 'Selected'
                    break;
                default:
                    return status
                    break;
            }
        }
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal existing-jobs-modal"
                ref={c => (this.callerAuditModal = c)}
                closeButtonClicked={() => {
                    this.setState({ callersExistingJobs: [] })
                    this.callerAuditModal.toggle(false)
                }}
            //  closeModal={() => }
            >
                <div>
                    <div className="heading">Existing jobs</div>
                    <div className="caller-history">
                        <div className='exisiting-job-container'>
                            {
                                callersExistingJobs.length > 0 ?
                                    callersExistingJobs.map(job => {
                                        return (
                                            <Fragment>
                                                <div className='existing-jobs-wrapper'>
                                                    <div className='title'>{job.title}</div>
                                                    <div className={`status ${job.status == 'training' ? 'shortlisted' : 'selected'}`}>{customWordForStatus(job.status)}</div>
                                                </div>
                                            </Fragment>
                                        )
                                    }) :
                                    <Fragment>
                                        <div className='existing-jobs-wrapper'>
                                            <span>No existing jobs present for the caller</span>
                                        </div>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </FrappModal >
        )
    }

    showPastProjectsPopup = (rowData) => {
        this.setState({pastProjects:rowData})
        this.showPastProjects.toggle(true);
    }

    renderPastProjectsPopup = () => {
        const {pastProjects} = this.state
        return(
            <FrappModal
                onCloseButton={true}
                ref={c => (this.showPastProjects = c)}
                closeButtonClicked={() => {
                    this.showPastProjects.toggle(false)
                    this.setState({pastProjects:''})
                }}
            >
                <JobsHistory rowData={pastProjects} />
            </FrappModal >
        )
    }

    renderAllFeedbackPopup = () => {
        const { currentFeedback } = this.state
        return (
            <FrappModal 
                onCloseButton={true}
                className="tele-payment-modal feedback-modal"
                ref={c => (this.feedbackModal = c)}
                closeModal={() => this.feedbackModal.toggle(false)}
            >
                <div>
                    <div className="heading">Feedback</div>
                    <div className="caller-history">
                        <div className='exisiting-job-container'>
                            {
                                currentFeedback && currentFeedback.length > 0 ?
                                    currentFeedback.map(job => {
                                        return (
                                            <Fragment>
                                                <div className='existing-jobs-wrapper'>
                                                    {job}
                                                </div>
                                            </Fragment>
                                        )
                                    }) :
                                    <Fragment>
                                        <div className='existing-jobs-wrapper'>
                                            <span>No Feedbacks</span>
                                        </div>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </FrappModal >
        )
    }

    downloadReport = () => {
        this.toastReportDownload.current = toast.info(" ⏱  Downoading Report...", {autoClose:false})
        ApiActions.getAppliedCallerListReport(this.props.match.params.id)
        .then(resp =>{
          if(resp && resp.records && resp.records.length > 0){
        
            let newData = []
            function get_question_answer(mockFormData) {
                let questionAnswerObj = {}
                try {
                    mockFormData.forEach((element, index) => {
                        questionAnswerObj[`Question_${index + 1}`] = element.question
                        questionAnswerObj[`AudioUrl_${index + 1}`] = element.audioUrl
                    })
                    return questionAnswerObj
                }
                catch (error) {
                    console.log("No Question Answer")
                    return questionAnswerObj
                }
            }
            function get_languages(data) {
                try{
                    return data.map((i) => i.language).join(",")
                }catch (error){
                    return "No language found"
                }
            }
            function get_work_pref(item) {
                try{
                    if((item && item.workPreferredDays) || (item && item.workPreferredSlots)) {
                        let day = item && item.workPreferredDays
                                    ? item.workPreferredDays.join(' ')
                                    : "NA"
                        let slots = item && item.workPreferredSlots
                                    ? item.workPreferredSlots.join(', ')
                                    : "NA"
                        return `${day} ${slots}`
                    } else {
                       return item && item.workPreference ? item.workPreference : "NA" 
                    }
                }catch (error) {
                    return "NA"
                }

            }
            function get_tarining_details(item) {
                let training_details = {}
                try {
                    training_details['Manual Score'] = item.training[0].manualScore
                    training_details['Training Audio Url'] = item.training[0].testAudioUrl
                    training_details['Pronounciation'] = item.training[0].manualGradeResult.pronunciation
                    training_details['Tonality'] = item.training[0].manualGradeResult.tonality
                    training_details['Script Preparedness'] = item.training[0].manualGradeResult.scriptPreparedness
                    return training_details
                } catch (error) {
                    return training_details
                }
            }

            resp.records.forEach((item) => {
                let mockFormData = get_question_answer(item.mockFormData)
                let training_details = get_tarining_details(item)
                let generalCallerData = {
                    "Name": item.firstname + " " + item.lastname,
                    "Mobile": item.mobile,
                    "Email": item.email,
                    "Status": item.status,
                    "Calls Attempts": item.callsAttemptedCount,
                    "Languages": get_languages(item.languages),
                    "Work Preference": get_work_pref(item),
                    "Total Min Spoken": item.totalDurationMinutes
                }
                newData.push({...generalCallerData, ...training_details, ...mockFormData})
            })

            let headers = Object.keys(newData[0])

            ExportJsonToCsv(headers, newData, `AppliedCallerReport${new Date().toLocaleDateString()}`)
          }else{
            toast.error('No data found')
          }
        })
        .then(() => {
            toast.update(this.toastReportDownload.current, {render: "Download Completed 📁 ", type: toast.TYPE.SUCCESS, autoClose: 5000 })
        })
        .catch(err => {
            console.error(err)
            toast.error('Something went wrong while generating CSV')
        })
    }

    downloadRejectedReport = () => {
        this.toastReportDownload.current = toast.info(" ⏱  Downoading Report...", {autoClose:false})
        ApiActions.getRejectedCallersListReport(this.props.match.params.id)
        .then(resp =>{
          if(resp && resp.records && resp.records.length > 0){
            let newData = []
            resp.records.forEach((item) => {
                let generalCallerData = {
                    "Name": item.firstname + " " + item.lastname,
                    "Mobile": item.mobile,
                    "Email": item.email,
                    "Calls Attempted": item.callsAttemptedCount,
                    "Status": item.status
                }
                newData.push({...generalCallerData})
            })

            let headers = Object.keys(newData[0])

            ExportJsonToCsv(headers, newData, `RejectedCallerReport${new Date().toLocaleDateString()}`)
          }else{
            toast.error('No data found')
          }
        })
        .then(() => {
            toast.update(this.toastReportDownload.current, {render: "Download Completed 📁 ", type: toast.TYPE.SUCCESS, autoClose: 5000 })
        })
        .catch(err => {
            console.error(err)
            toast.error('Something went wrong while generating CSV')
        })
    }


    filterChange = (searchInput) => {
        this.setState({searchText: searchInput})
        if(this.state.activeTab.value == 'applied'){
            this.setState({currentPage: 1}, () => {
                this.setState({ isTableLoadingApplied: true })
                const skip = (this.state.currentPage - 1) * this.state.limit
                ApiActions.getAppliedCallersList(this.props.match.params.id, skip, this.state.limit, searchInput)
                    .then(resp2 => {
                        this.setState({
                            appliedCallers: resp2.records,
                            pendingCallers: resp2.totalPendingCallers,
                            isTableLoadingApplied: false,
                            totalCount: resp2.totalPendingCallers
                        })
                    }).catch(err => {
                        console.error(err)
                        toast(err.message)
                        this.setState({ isTableLoadingApplied: false })
                    })
            })
        }

        if(this.state.activeTab.value == 'rejected'){
            this.setState({currentPage: 1}, () => {
                this.setState({isTableLoadingRejected: true})
                const skip = (this.state.currentPage - 1) * this.state.limit
                ApiActions.getRejectedCallersList(this.props.match.params.id, skip, this.state.limit, searchInput)
                .then(resp2 => {
                    this.setState({
                        rejectedCallers: resp2.records,
                        pendingCallersRejected: resp2.totalRejectedCallers,
                        totalCount: resp2.totalRejectedCallers,
                        isTableLoadingRejected: false
                    })
                }).catch(err => {
                    console.error(err)
                    toast(err.message)
                    this.setState({ isTableLoadingRejected: false })
                })
            })
        }
    }

    renderAppliedCallersTable = () => {
        let { totalCount, query, pageChanged } = this.state
        let { appliedCallers, tableMode } = this.state;
        let options = [
            { label: "Shortlist", value: "training" },
            //    { label: "Select",value:"inprogress"},
            { label: "Reapply ", value: "reapply" },
            { label: "Reject", value: "rejected" }
        ]

        const columns = [
            {
                Header: "Name",
                accessor: (row) => <div>
                <span style={{marginRight: '2px'}}>{row.firstname || 'NA'} {row.lastname || 'NA'}</span>
                </div>,
                width: '10%',
                disableSortBy: true
            },
            {
                Header: 'Phone',
                accessor: "mobile",
                Cell: ({value}) => ( value ? value : 'NA'),
                maxWidth: 110,
                minWidth: 100,
                width: 100,
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: "email",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '10%',
                disableSortBy: true
            },
            {
                Header: 'Score',
                accessor: "training[0].manualScore",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return value.training.length > 0 ? value.training[0].manualScore : 0
                },
                width: '2%'
            },
            {
                Header: 'Calls Attempted',
                accessor: "callsAttemptedCount",
                Cell: ({value}) => value || 0,
                width: '7%'
            },
            {
                Header: 'Total Mins Spoken',
                accessor: "totalDurationMinutes",
                Cell: ({value}) => value || 0,
                width: '8%'
            },
            {
                Header: 'Training Sessions',
                accessor: "trainingMeetings",
                Cell: ({cell}) => {
                    const { value } = cell || {}
                    const first = value && value.length > 0 ? [value[0]] : []
                    return <>
                    <TrainingMeeting meetings={first}/>
                    {value && value.length >= 2 && <div class='text-muted trainig-meeting'
                     onClick={() => this.setState({
                        trainingMeetings: value, showTrainingMeetings: true})}
                    >View more..</div>}
                    </>
                },
                width: '20%',
                disableSortBy: true
            },
            {
                Header: 'Training',
                accessor: "training",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column" onClick={() => this.handleTrainingClick(value)}>
                    <img src={require("../../../assets/images/Icons_training.svg")} style={{width: '25px'}}/>
                    </div>
                },
                width: '3%',
                disableSortBy: true
            },
            {
                Header: 'Languages',
                accessor: "language",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return value.languages ? value.languages.map((item) => {
                        return <span style={{ textDecoration: "undeline" }}>
                            {
                                `${item.language}${item.starred ? "(🌟)\n" : "\n"}`
                            }
                        </span>
                    }) : <p> NA</p>
                },
                width: '5%',
                disableSortBy: true
            },
            // {
            //     Header: 'Work Preference',
            //     accessor: "work_preference",
            //     Cell: ({cell}) => {
            //         const value = cell.row.original
            //         return <div>
            //         {
            //             (value && value.workPreferredDays) || (value && value.workPreferredSlots)
            //                 ? <div>
            //                     <div>
            //                         {
            //                             value && value.workPreferredDays
            //                                 ? value.workPreferredDays.join(' ')
            //                                 : "NA"
            //                         }
            //                     </div>
            //                     <div>
            //                         {
            //                             value && value.workPreferredSlots
            //                                 ? value.workPreferredSlots.join(', ')
            //                                 : "NA"
            //                         }
            //                     </div>
            //                 </div>
            //                 : value && value.workPreference
            //                     ? value.workPreference
            //                     : "NA"
            //         }
            //         </div>
            //     },
            //     width: '25%',
            //     disableSortBy: true
            // },
            {
                Header: 'Mock Form',
                accessor: "mockFormData",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column" onClick={() => this.handleAudioClick(value)}>
                        <img src={require("../../../assets/images/Icons_mocktest.svg")} style={{width: '25px'}}/>
                    </div>
                },
                width: '3%',
                disableSortBy: true
            },
            {
                Header: 'Jobs assigned',
                accessor: "jobs_assigned",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column">
                        <img src={require("../../../assets/images/Icons_assigned_jobs.svg")} style={{ width: '25px', cursor: 'pointer' }} onClick={() => this.showCallerHistoryPopup(value)} />
                    </div>
                },
                width: '3%',
                disableSortBy: true
            },
            {
                Header: 'Job History',
                accessor: "job_history",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column">
                    <img src={TrackHistory} alt='Job History' 
                        style={{ width: '25px', cursor: 'pointer' }}
                        onClick={() => this.showPastProjectsPopup(value)}/>
                    </div>
                },
                width: '3%',
                disableSortBy: true
            },
            // {
            //     Header: 'Feedback',
            //     accessor: "feedback",
            //     Cell: ({cell}) => {
            //         const value = cell.row.original
            //         return <div>
            //         <OverlayTrigger placement="bottom" overlay={tooltip('Click to view feeback')}>
            //             <span style={{ cursor: 'pointer' }} onClick={() => this.showAllTheFeedbacks(value)}>{styleTheFeedback(value.feedback)}</span>
            //         </OverlayTrigger>
            //         </div>
            //     },
            //     width: '20%',
            //     disableSortBy: true
            // },
            {
                Header: 'Status',
                accessor: "status",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return <div>
                <Dropdown options={options}
                onChange={(val) => this._onSelect(value, val)}
                value={value.status ? value.status : "Select"}
                placeholder="Select an option" />
                </div>
                },
                width: '2%',
                disableSortBy: true
            }
        ]

        return (
            <div style={{ maxWidth: "100%"}} id='project-table'>
                <ReactTable
                columns={columns}
                data={appliedCallers}
                totalCount={this.state.pendingCallers || 0}
                limit={this.state.limit}
                type = 'server'
                selectType='multi'
                selectRow = {true}
                updateRowSelect={(rows = []) => {this.setState({rowSelected: rows})}}
                currentPage={this.state.currentPage}
                setCurrentPage={(page) => this.setState({currentPage: page}, () => 
                this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                )}
                loading={this.state.isTableLoadingApplied}
                filterChange = {this.filterChange}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'mobile number'}
                search={this.state.searchText}
                serverReport={this.downloadReport}
                isExport={true}
                />
            </div>
        )
    }

    renderRejectedCallersTable = () => {
        let { totalCount, query, pageChanged } = this.state
        let { rejectedCallers, tableMode } = this.state;
        let options = [
            { label: "Shortlist", value: "training" },
            { label: "Select", value: "inprogress" }
        ]

        const columns = [
            {
                Header: "Name",
                accessor: "firstname",
                Cell: ({row}) => {
                const {original: {firstname, lastname}} = row
                return <div>
                <span style={{marginRight: '2px'}}>{firstname || 'NA'} {lastname || 'NA'}</span>
                </div>
                },
                width: '15%',
                disableSortBy: true,
                getCellExportValue: (row) => {
                    const {original: {firstname, lastname}} = row
                    return `${firstname} ${lastname}`
                }
            },
            {
                Header: 'Phone',
                accessor: "mobile",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '12%',
                disableSortBy: true
            },
            {
                Header: 'Email',
                accessor: "email",
                Cell: ({value}) => ( value ? value : 'NA'),
                width: '15%',
                disableSortBy: true
            },
            {
                Header: 'Calls Attempted',
                accessor: "callsAttemptedCount",
                Cell: ({value}) => value || 0,
                width: '11%'
            },
            {
                Header: 'Mock Form',
                accessor: "mockFormData",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column" onClick={() => this.handleAudioClick(value)}>
                        <img src={require("../../../assets/images/Icons_mocktest.svg")} style={{width: '25px', cursor: 'pointer'}}/>
                    </div>
                },
                width: '11%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Jobs assigned',
                accessor: "jobs_assigned",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column">
                    <img src={require("../../../assets/images/Icons_assigned_jobs.svg")} style={{ width: '25px', cursor: 'pointer' }} onClick={() => this.showCallerHistoryPopup(value)} />
                    </div>
                },
                width: '10%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Source',
                accessor: 'source',
                Cell: ({cell}) => cell.row.original.callWiseDetails ? 'IQC' : 'N/A',
                width: '7%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: ({cell}) => {
                    const value = cell.row.original
                    return <div className="training-column">
                        {
                            value.callWiseDetails ? 
                            <CustomTooltip placement={'middle'} description={'Feedbacks'}
                                component={
                                    <
                                        img src={FeedbackIcon}
                                        alt='Feedbacks' className='icon-btn'
                                        style={{width: '30px'}}
                                        onClick={() => {
                                            this.setState({
                                                activeRow: value,
                                                showIqcFdbk: true
                                            })
                                        }}
                                    />
                                }
                            ></CustomTooltip> 
                            : 
                            'N/A'
                        }
                    </div>
                },
                width: '5%',
                disableSortBy: true,
                disableExport: true
            },
            {
                Header: 'Status',
                accessor: "status",
                Cell: ({cell}) => {
                const value = cell.row.original;
                return <div>
                <Dropdown options={options}
                    onChange={(val) => this._onSelect(value, val)}
                    value={value.status ? value.status.charAt(0).toUpperCase() + value.status.slice(1) : "Select"}
                    placeholder="Select an option" />
                </div>
                },
                width: '10%',
                disableSortBy: true
            }
        ]
        return (
            <div style={{ maxWidth: "100%" }} id='project-table'>
                <ReactTable
                columns={columns}
                data={rejectedCallers}
                totalCount={this.state.totalCount || 0}
                limit={this.state.limit}
                // type = 'server'
                selectType='multi'
                selectRow = {true}
                updateRowSelect={(rows = []) => {this.setState({rowSelected: rows})}}
                currentPage={this.state.currentPage}
                setCurrentPage={(page) => this.setState({currentPage: page}, () => 
                this.fetchCallersBasedOnStage(this.props.match.params.id, this.state.activeTab)
                )}
                loading={this.state.isTableLoadingRejected}
                filterChange = {this.filterChange}
                isGlobalFilter = {true}
                manualSortBy = {false}
                searchBy = {'mobile number'}
                search={this.state.searchText}
                title='Rejected Callers'
                serverReport={this.downloadRejectedReport}
                isExport={true}
                />
            </div>
        )
    }

    refreshTable = () => {
        const { activeTab } = this.state
        this.setState({
            searchMode: false,
            searchText: '',
            skip: 0,
            limit: 10,
            query: {
                page: 1,
                pageSize: 10,
            }
        })
        this.fetchCallersBasedOnStage(this.props.match.params.id, activeTab)
    }

    searchBtnTriggered = (mobileNumberValue) => {
        if (this.state.activeTab.value === "applied") {
            this.setState({ searchText: mobileNumberValue })
            this.setState({ searchMode: true, isTableLoadingApplied: true })
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getAppliedCallersList(this.props.match.params.id, skip, this.state.limit, mobileNumberValue)
                .then(resp2 => {
                    this.setState({
                        appliedCallers: resp2.records,
                        pendingCallers: resp2.totalPendingCallers,
                        isTableLoadingApplied: false,
                        totalCount: resp2.totalPendingCallers
                    }, () => this.addCheckedField())
                }).catch(err => {
                    console.error(err)
                    toast(err.message)
                    this.setState({ isTableLoadingApplied: false })
                })
        } else {
            this.setState({ searchText: mobileNumberValue })
            this.setState({ searchMode: true, isTableLoadingRejected: true })
            const skip = (this.state.currentPage - 1) * this.state.limit
            ApiActions.getRejectedCallersList(this.props.match.params.id, skip, this.state.limit, mobileNumberValue)
                .then(resp2 => {
                    this.setState({
                        rejectedCallers: resp2.records,
                        pendingCallersRejected: resp2.totalRejectedCallers,
                        totalCount: resp2.totalRejectedCallers,
                        isTableLoadingRejected: false
                    }, () => this.addCheckedField())
                }).catch(err => {
                    console.error(err)
                    toast(err.message)
                    this.setState({ isTableLoadingRejected: false })
                })
        }
    }

    renderTable = (activeTab) => {
        switch (activeTab) {
            case 'applied':
                return this.renderAppliedCallersTable()
            case 'rejected':
                return this.renderRejectedCallersTable()
            default:
                break;
        }
    }

    renderIqcFdbk = () => {
        const { activeRow, currentPageIqcCallAudit } = this.state
        const currentData = activeRow && activeRow.callWiseDetails && activeRow.callWiseDetails.length > 0 ? 
        activeRow.callWiseDetails[currentPageIqcCallAudit-1] : {}
       
        const copyToClipboard = (recordingUrl = '') => {
            navigator.clipboard.writeText(recordingUrl);
        }

        return(
            <Popup
            show={this.state.showIqcFdbk}
            closePopup={() => {
                this.setState({
                    currentPageIqcCallAudit:1,
                    activeRow: '',
                    showIqcFdbk: false
                })
            }}
            size='lg'
            heading={'IQC Feedback'}
            >
            <div className='retraining-call-audited'>

            <div className='patition'>
            <div className='call_recording'>
                <div className='call_recording_row'>
                <span className='sub_heading'>Call Details</span>
                <span className='link'
                onClick={() => copyToClipboard(currentData && currentData.recordingUrl)}
                >Copy audio link</span>
                </div>
                <div className='audio'>
                    <audio controls src={currentData.recordingUrl} />
                </div>
                <p className='sub_heading'>Call Date: {<span>{moment(currentData.callDate).format("DD/MM/YYYY")} at {moment(currentData.callDate).format('HH:mm')}</span> || 0}</p>
                <p className='sub_heading'>Disconnected by: {currentData.disconnectedBy}</p>
                <p className='sub_heading'>Outcome marked by caller: {currentData && currentData.outcomeMarked || 'NA'}</p>
                <p className='sub_heading'>Correct outcome by QA agent: {currentData && currentData.correctedOutcome || 'NA'}</p>
            </div>

            <div className='panel_collection'>
                <Panel title={<span className='sub_heading'>Customer Info</span>}>
                        <div className='customer_info'>
                        {
                            currentData && currentData.data ? Object.entries(currentData.data).map(
                                ([key, value], idx) => (
                                    <div className='info' key={idx + 1}>
                                        {`${capitalise(key)}: ${value}`}
                                    </div>
                                )
                            ) : <div>⚠️ No Customer Data</div>
                        }
                        </div>

                </Panel>
                <Panel title={<span className='sub_heading'>Data collected</span>}>
                    {
                        currentData && currentData.responses && currentData.responses.length > 0 ? currentData.responses.map(data => {
                            return <div className='data_collected'>
                                <div className='qa_ans'>
                                    <p className='qa'>Q: {data ? data.question : 'NA'}</p>
                                    <p><img src={AnswerIcon} /> {data ? data.answer : 'NA'}</p>
                                </div>
                            </div>
                        }) : <div> ⚠️ No Data Collected</div>
                    }
                </Panel>
            </div>
            </div>
            {this.iqcFeedbkTable(currentData)}

            <div className='page-controls'>
            <button
                onClick={() => this.setState({currentPageIqcCallAudit: currentPageIqcCallAudit - 1})}
                disabled={currentPageIqcCallAudit == 1 ? true : false}
                className='page-arrow-btn'
              >
                <FontAwesomeIcon icon={faAngleLeft} className='angle-icons'/>
            </button>
            <div className='page-num'>{currentPageIqcCallAudit} &nbsp;&nbsp;of {'10'}</div>
            <button
                onClick={() => this.setState({currentPageIqcCallAudit: currentPageIqcCallAudit + 1})}
                disabled={activeRow && activeRow.callWiseDetails && activeRow.callWiseDetails.length == currentPageIqcCallAudit ? true : false}
                className='page-arrow-btn'
              >
                <FontAwesomeIcon icon={faAngleRight} className='angle-icons'/>
            </button>
            </div>
            </div>
            </Popup>
        )
    }

    iqcFeedbkTable = (currentData) => {
        const { qmfGrades } = currentData

        const columns = [
            {
                Header: "Category",
                accessor: 'title',
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.category ?
                    data.category || 'NA'
                    : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Parameter',
                accessor: 'behaviour',
                Cell: (cell) => {
                   let data = cell.row.original
                   return data && data.behaviour ?
                   data.behaviour : 'NA'
                },
                disableSortBy: true
            },
            {
                Header: 'Feedback',
                accessor: "feedback",
                Cell: (cell) => {
                    let data = cell.row.original
                    return data && data.feedback ? data.feedback : 'NA'
                },
                disableSortBy: true
            }
        ]

        return(
            <CustomTable
            columns={columns}
            data={qmfGrades}
            loading={false}
            type = 'client'
            height = '50'
            />
        )
    }
}
export default JobApplicationsApplied