const userRoleReason = (role) => {
    switch (role) {
        case 'admin':
            return 'Admin'
            break;
        case 'project_manager':
            return 'Manager'
            break;
        case 'coach':
            return 'Coach'
            break;
        default:
            return 'Manager'
            break;
    }
}


const userRoleAction = (role) => {
    switch (role) {
        case 'project_manager':
            return 'manager'
            break;
        default:
            return role
            break;
    }
}

export {
    userRoleReason,
    userRoleAction
}