import React, { useState, useEffect, useContext } from 'react'
import Table from '../../../uicomponents/table/Table'
import ToggleSwitch from '../../../uicomponents/tab/slidingtab'
import capitalise from '../../../helper/Capitalise'
import { getSessionParticipantList, markAttendanceForAgent } from './service'
import useApi from '../../../hooks/useApi'
import { TrainingSessionContext } from './trainingSessionContext'
import FutworkButton from "../../../uicomponents/button/Button";
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import Message from './message'
import CustomTooltip from '../../../components/utilities/ToolTip'
import moment from 'moment'
import timeStamp from '../../../helper/timeStamp'

const TABS = [
    'Present', 'Absent'
]

const MarkAttendance = () => {
    const getSessionParticipantListApi = useApi(getSessionParticipantList)
    const markAttendanceForAgentApi = useApi(markAttendanceForAgent)

    const { projectData, selectedRow } = useContext(TrainingSessionContext)

    const [ agentsList, setAgentsList ] = useState([])
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ limit, setLimit ] = useState(10)

    const [ apiMessage, setApiMessage ] = useState({
        status: '',
        msg: ''
    })

    useEffect(() => {
        const { id: trainingMeetingId } = selectedRow
        const { id: teleprojectId } = projectData

        const payload = {
                teleprojectId,
                trainingMeetingId
        }

        getSessionParticipantListApi.request(payload)
    },[])

    const columns = [
        {
            Header: 'Agent name',
            accessor: 'firstname',
            Cell: ({cell}) => {
                const { row:{ original } } = cell || {}
                return <span>{Number(cell.row.index) + 1}.&nbsp;&nbsp;{original.firstname} {original.lastname}</span>
            },
            width: '25%',
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: 'mobile',
            width: '10%',
            disableSortBy: true
        },
        {
            Header: 'Attendance',
            accessor: 'attendance',
            width: '40%',
            Cell: ({cell}) => {
                const { row:{ original } } = cell || {}
                return <div>
                <div>
                    <ToggleSwitch tabs={TABS} activeTab={capitalise(original.attendance) || {}}
                    color={original.attendance == '' ? '' : 
                    original.attendance == 'present' ? 'blue-tab-solid' : 'red-tab-solid'}
                    onClick={(tab) => {
                    let tmp = [...agentsList]
                    if(capitalise(tmp[cell.row.id].attendance) == tab){
                    tmp[cell.row.id].attendance = ''
                    tmp[cell.row.id].edit = false
                    }else{
                    tmp[cell.row.id].attendance = tab.toLowerCase()
                    tmp[cell.row.id].edit = true
                    }
                    setAgentsList(tmp)
                    }} />
                </div>
                {original.attendance && original.attendanceDate &&
                <div>Marked on: {moment(original.attendanceDate).format("DD/MM/YYYY")} | {moment(original.attendanceDate).format('hh:mm A')}</div>}
                </div>
            },
            width: '20%',
            disableSortBy: true
        },
    ]

    const checkIfValid = () => {
        const isEditedArray = agentsList && agentsList.filter(list => list.edit)
        return isEditedArray && isEditedArray.length > 0 ? false : true
    }

    const markAttendance = () => {
            setApiMessage({status: '', msg: ''})

            const attendanceData = agentsList && agentsList.map(({_id, attendance}) => {
                if(attendance)
                return {
                    _id, attendance
                }
            }).filter(Boolean)


            const { id: trainingMeetingId } = selectedRow
            const { id: teleprojectId } = projectData
    
            const payload = {
                    teleprojectId,
                    trainingMeetingId,
                    attendanceData
            }

            markAttendanceForAgentApi.request(payload)
    }

    let { data: inviteList, loading, error } = getSessionParticipantListApi || {}

    let { data: markedAttendanceResult, loading: markAttendanceLoading, 
        error: markAttendanceError} = markAttendanceForAgentApi || {}

    
    const showMessage = () => {
        const { status, msg} = apiMessage || {}

        const checkStatus = () => {
            switch (status) {
                case 'pass':
                    return 'success'
                case 'fail':
                    return 'error'
                default:
                    return ''
            }
        }

        const variant = checkStatus()
        return msg && status && <Message variant={variant} children={msg} timer={false} />
    }

    useEffect(()=> {
        if(!loading && inviteList !== null){
            setAgentsList(inviteList && inviteList.map(list => {
                const { trainingMeetings } = list
                return {    ...list, 
                            attendance: trainingMeetings ? trainingMeetings.attendance ? trainingMeetings.attendance : '' : '', 
                            attendanceDate: trainingMeetings ? trainingMeetings.attendanceDate ? trainingMeetings.attendanceDate : '' : undefined,
                            edit: false
                        }
            }))
        }

        if(error){
            setApiMessage({
                status: 'fail',
                msg: handleErrorMessage(error)
            })
        }
    }, [inviteList, loading, error])

    useEffect(() => {
        if(!markAttendanceLoading && markedAttendanceResult !== null){

            if(markedAttendanceResult.status == 200){
                const {data: result} = markedAttendanceResult
                setApiMessage({
                    status: 'pass',
                    msg: result && result.msg
                })
            }
        }

        if(markAttendanceError){
            setApiMessage({
                status: 'fail',
                msg: handleErrorMessage(markAttendanceError)
            })
        }

    },[markedAttendanceResult, markAttendanceLoading, markAttendanceError])
 
    return (
        <>
            <Table 
            data={agentsList}
            columns={columns}
            loading={loading}
            totalCount={inviteList ? inviteList.length : 0}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            error={error}
            type = 'client'
            height='50'
            isGlobalFilter = {true}
            searchBy = {'by name / mobile'}
            title={`attendance-register-for-${selectedRow.title}-${timeStamp()}`}
            isExport={true}
            manualSortBy = {false}
            />
            <div className={styles.cta_center}>
            <FutworkButton buttonSize='sm' buttonStyle='primary--solid'
            onClick={() => markAttendance()}
            disabled={checkIfValid()}
            >Mark Attendance</FutworkButton>
            </div>
            {showMessage()}
        </>
    )
}

export default MarkAttendance