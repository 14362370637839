import React from 'react'
const CallDisconnectedBy = (disconnectedBy) => {
    switch (disconnectedBy.toLowerCase()) {
        case 'caller':
            return <span className='disconnectedBy caller'>{disconnectedBy == 'Caller' ? 'Tele-caller' : disconnectedBy}</span>
        case 'callee':
            return <span className='disconnectedBy customer'>{disconnectedBy == 'Callee' ? 'Customer' : disconnectedBy}</span>
        case 'operator':
            return <span className='disconnectedBy operator'>{disconnectedBy}</span>
        case 'exotel':
            return <span className='disconnectedBy exotel'>{disconnectedBy}</span>
        default:
            return 'NA'
    }
}

export default CallDisconnectedBy