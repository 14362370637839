import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthGuard from "../guards/authGuard";
import Header from '../components/common/Header';
import { privateRoutesConfig } from '../config'
import { intersection } from 'lodash';
import jwt_decode from "jwt-decode";
import Login from '../components/pages/Login';

const AuthRoutes = ({isAddNotFound = true}) => {
    let allowedRoutes = []

    const isLoggedIn = () => {
	/*
		* Note:
		*  This app assume if local storage have roles it means
		*  user is authenticated you can update this logic as per your app.
	*/
	return !!localStorage.authToken
    }

    const isArrayWithLength = (arr) => {
        return (Array.isArray(arr) && arr.length)
    }


    const getAllowedRoutes = (routes) => {
        const roles = localStorage.authToken ? [jwt_decode(localStorage.authToken).auth] : [];
        return routes.filter(({ permission }) => {
            if(!permission) return true;
            else if(!isArrayWithLength(permission)) return true;
            else return intersection(permission, roles).length;
        });
    }

    const getAllowedSubRoutes = (routes) => {
        const roles = localStorage.authToken ? [jwt_decode(localStorage.authToken).auth] : [];
        // check for permission in sub routes
        return routes.map(route => {
            if(route.children){
                let filteredChildrenArray = route.children.filter(it => {
                    if(it.permission){
                        return intersection(it.permission, roles).length
                    }
                    return it
                })
                route.children = filteredChildrenArray
                return route
            }
            return route
        })
    }

    if (isLoggedIn()) allowedRoutes = getAllowedRoutes(getAllowedSubRoutes(privateRoutesConfig));
    else{
        const nextURL = window.location.origin
        const nextTitle = 'My new page title';
        const nextState = { additionalInformation: 'Updated the URL with JS' };
        // This will create a new entry in the browser's history, without reloading
        window.history.pushState(nextState, nextTitle, nextURL);
        return <Login />
    }

    return(
        <>
       {allowedRoutes  && <Header routes={allowedRoutes}/>}
        <Switch>
        {allowedRoutes && allowedRoutes.length > 0 && allowedRoutes.map((route) => {

				const { path, component: Component, children, title, permission, ...rest } = route;
				return (
					Component && <Route path={`${path}`} render={(props) => <AuthGuard component={<Component {...props} children={children}/>} />}/>
				)
			})}
        {isAddNotFound && <Redirect to='/home' />}
        </Switch>
        </>
    )
}



export default React.memo(AuthRoutes)