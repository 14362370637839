import React from "react";

class Switch extends React.Component{
    render(){
        return (
            <div className="switch-wrapper" >
                <label className="switch">
                    <input onChange={this.props.onToggle} {...this.props} type="checkbox" />
                    <span className="slider round"></span>
                </label>
            </div>
        )
    }
}

export default Switch;