import { useEffect, useState } from "react"
import ApiActions from './../actions/ApiActions'

const useSystemExotelApi = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState('')

    useEffect(()=>{
        getSystemExotelData()
    },[])

    const getSystemExotelData = async () => {

        try {
            ApiActions.getExotelAccounts1().then(response => {
              if(response){
                ApiActions.getProjectListByExophone().then(projectList => {
                  setData({
                    ...response,
                    projectList
                  })
                }).catch(err => {
                  setData({
                    ...response
                  })
                })
                setIsLoading(prev => !prev)
              }
            })
          } catch (err) {
            console.log(err);
          }
    }

    return [isLoading, data, setIsLoading, getSystemExotelData]
}

export default useSystemExotelApi