import React, {useState, useEffect} from 'react'
import styles from '../../assets/stylesheets/pages/hiring/leads.module.scss'
import FutworkButton from '../../uicomponents/button'
import handleErrorMessage from '../../helper/handleErrorMessage'
import { assignLeads, statsForAssignAllLeads } from './main/service'
import checkIsEmpty from "../../helper/checkIsEmpty"
import ThreeDotLoader from "../../components/utilities/ThreeDotLoader"

const LeadsManagement = ({type, projectId, callerData, selectedCallers, updateAlpCallers}) => {
    const [assignStats, setAssignStats] = useState([
        {
            name: 'Total callers selected',
            value: selectedCallers && selectedCallers.length >= 0 ? selectedCallers.length : 0,
        },
        {
            name: 'Total leads unassigned',
            value: 0,
            key: 'totalUnassignedLeads'
        }
    ])
    const [payload, setPayload] = useState({
        teleproject: projectId,
        count: '',
        mobile: selectedCallers && selectedCallers.length > 0 ? selectedCallers.map(caller => caller.mobile) : []
    })
    const [apiMsg, setApiMsg] = useState({type: '', msg: ''})
    const [isStatsLoading, setStatsLoading] = useState(true)
    const [isInsufficientLeads, setInsufficientLeads] = useState({
        msg: '',
        status: false
    })

    
    let getStats = assignStats

    useEffect(()=> {
        fetchStats()
    },[])

    const fetchStats = async() => {
        let data = {
            "teleproject": projectId
        }
        try{
            let result = await statsForAssignAllLeads(data)
            assignStats.map(item => {
                if(Object.keys(result).includes(item.key)){
                    item.value = result[item.key]
                }
                return item
            })
            setStatsLoading(false)
         }catch(err){
             console.error((err.response && err.response.data)|| "Unexpected Error!")
         }
    }
    const checkLeadPayload = () => {      
        let isInValid = true
        if(payload.count && payload.count > 0 && selectedCallers.length > 0 &&
            !isInsufficientLeads.status)
        isInValid = false
        return isInValid
    }

    const updatePayload = (e) => {
        setInsufficientLeads({
            status: false,
            msg: ''
        })
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        })

        let result = selectedCallers && selectedCallers.length > 0 ? selectedCallers.length : 0
        let unassignedLeads = assignStats.filter(item => {
            if(item.key == 'totalUnassignedLeads'){
                return item
            }
        })[0].value
        if((e.target.value * result) > unassignedLeads){
            setInsufficientLeads({
                status: true,
                msg: 'Insufficient leads'
            })
        }
    }

    const assignLeadsToSelectedCallers = async() => {
        let data = payload
        try{
           let result = await assignLeads(data)
           if(result.data){
            if(result.status == 200){
                setApiMsg({
                    type: 'success',
                    msg: `${result.data.validUsers && result.data.validUsers.length} callers were assigned leads`
                })
                setTimeout(() => {
                    updateAlpCallers()
                }, 2000);
            }
           }
        }catch(err){
            setApiMsg({
                type: 'failure',
                msg: err.response ? (err.response && err.response.data)|| "Unexpected Error!"
                : err.message || "Unexpected Error!"
            })
        }
    }

    return(
        <div className={styles.leads_management}>
            <div className={styles.leads_stats}>
                {
                    getStats.map(({name, value}) => {
                        return (
                        <div className={styles.stats_item}>
                            <span className={styles.heading}>{name}</span>
                            {
                                isStatsLoading ? <ThreeDotLoader/> : <span className={styles.stats}>{value}</span>
                            }
                            
                        </div>
                        )
                    })
                }
            </div>

            <div className={styles.leads_assign} >
                <p>Assign leads</p>
                <input type="text" className='w-100' 
                name='count'
                placeholder={type == 'ALL' ? 'No. of leads / Caller' : 'No. of leads'}
                onChange={(e) => updatePayload(e)}/>
            </div>

            {
            !checkIsEmpty(apiMsg) ?
            <div className={apiMsg.type == 'success' ?
                'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
            : null
            }
            {
                selectedCallers && selectedCallers.length <= 0 ? <div className={'failure-msg'}>Please select a caller</div> : null
            }
            {
                isInsufficientLeads.status &&  <div className={'failure-msg'}>{isInsufficientLeads.msg}</div>
            }

            <FutworkButton buttonSize='sm w-100' buttonStyle='navy--solid'
			disabled={checkLeadPayload()}
			onClick={()=> assignLeadsToSelectedCallers()}>SAVE</FutworkButton>
        </div>
    )
}

export default LeadsManagement