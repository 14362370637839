import React, {useState, useEffect} from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import {getMatchedCallers} from './service'
import useApi from '../../../hooks/useApi'
import Table from '../../../uicomponents/table/Table'
import Popup from '../../../uicomponents/popup'
import JobsHistory from './jobHistory'
import CurrentJobs from './currentJob'
import {TrackHistory} from '../../../assets/images'

const MatchTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [load, setLoad] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')

    const [showCallerHistory, setShowCallerHistory] = useState(false)
    const [showActiveJobs, setShowActiveJobs] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')

    const getMatchedCallerApi = useApi(getMatchedCallers)

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getMatchedCallerApi.request(skip,limit,sort,search)
    },[currentPage, sort,search])

    const columns = [
        {
            Header: "Name",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: "Current jobs",
            accessor: "activeJobCount",
            Cell: ({cell}) => (
                <div className={styles.blue_link}
                onClick={() => {
                    setSelectedRowData(cell.row.original)
                    setShowActiveJobs(true)
                }}>
                Jobs ({cell.row.original.activeJobCount ?
                cell.row.original.activeJobCount : 0})
                </div>
            ),
        },
        {
            Header: "Job History",
            Cell: (cell) => (
                <img src={TrackHistory} alt='Job History' onClick={() => {
                    setShowCallerHistory(true)
                    setSelectedRowData(cell.row.original)
                }}/>
            ),
            disableSortBy: true
        },
        //to be unlocked in 2nd phase
        // {
        //     Header: '',
        //     accessor: "action",
        //     Cell: (cell) => (
        //     <div className={styles.red_link} onClick={() => console.log(cell.row.original)}>
        //     Uncertify
        //     </div>
        //     ),
        //     disableSortBy: true
        // }
    ]

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const {data, total} = getMatchedCallerApi.data || {}
    const {loading} = getMatchedCallerApi


    const renderJobHistory = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerHistory(false)
            }}
            size='lg'
            heading={`Job history of ${firstname} ${lastname}`}
            popupContent = {<JobsHistory rowData={selectedRowData} />}
            />
        )
    }

    const renderActiveJobs = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showActiveJobs}
            closePopup={()=>{
                setSelectedRowData('')
                setShowActiveJobs(false)
            }}
            size='lg'
            heading={`Current jobs of ${firstname} ${lastname}`}
            popupContent = {<CurrentJobs rowData={selectedRowData}/>}
            />
        )
    }


    const handlePopups = () => {
        if(showCallerHistory) return renderJobHistory()
        if(showActiveJobs) return renderActiveJobs()
        else
        return null
    }

    return(
        <>
        {getMatchedCallerApi.error && <p>{typeof getMatchedCallerApi.error == 'object' && 
        getMatchedCallerApi.error !== null &&
        !Array.isArray(getMatchedCallerApi.error)
        ? getMatchedCallerApi.error.error || getMatchedCallerApi.error.msg : getMatchedCallerApi.error}</p>}
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        isGlobalFilter = {true}
        />
        {handlePopups()}
        </>
    )
}

export default MatchTable