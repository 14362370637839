import React from 'react'
//custom imports
import styles from './card.module.scss';

const dynamicType = (type) => {
    switch (type) {
        case "standard":
            return 'standard';
        case "custom":
            return 'custom'
        default:
            return 'standard'
    }
}

const dynamicColor = (color) => {
    switch (color) {
        case 'voilet':
            return 'voilet'
        case 'sky-blue':
            return 'sky-blue'
        case 'peach':
            return 'peach'
        case 'aqua-green':
            return 'aqua-green'
        default:
            return 'white'
    }
}


const Card = ({info, onClick, type = 'standard', color = 'white'})=>{
    return info 
        ?
        <div className={[styles.card, styles[dynamicColor(color)], styles[dynamicType(type)]].join(' ')} onClick={onClick}>
        <div className={[styles.card_content].join(' ')}>
            <div className={styles.card_title}>
            {info.title}
            </div>
            <div className={styles.card_text}>
                {info.value}
            </div>
        </div>
        </div>
        :
        <SkeletonCard color={color} type={type}/>
}

const SkeletonCard = ({color, type}) => {
    return (
        <div className={[styles.card, styles[dynamicColor(color)], styles[dynamicType(type)]].join(' ')}>
        <div className={[styles.card_content].join(' ')}>
        <div className={[styles.skeleton, styles['skeleton-title']].join(' ')}></div>
        <div className={[styles.skeleton, styles['skeleton-text']].join(' ')}></div>
        </div>
        </div>
    )
}

export default Card