import React from 'react'
import styles from './radio.module.scss'

const Radio = (props) => {
    let {id = '', label = '', name = 'radioInline', value = '', checked = null, handleChange = null, selectedValue = ''} = props
    return(
    <div className={styles['custom_radio']}>
    <input type='radio' 
    id={`radio${id}`} 
    name={name} 
    value={value}
    onChange={handleChange}
    checked={
        selectedValue ?
        selectedValue == value ? "checked" : null
        :
        checked
    }
    />
    <label htmlFor={`radio${id}`}>{label}</label>
    </div>
    )
}

export default Radio