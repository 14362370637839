import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'

class FrappModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:false
        }
    }
    render(){
        return (
            <Modal
                bsSize={this.props.bsSize || "large"} 
                // bsSize={this.props.bsSize ? this.props.bsSize : 'sm'}
                show={this.state.show || this.props.show} 
                className={`frapp-modal ${this.props.className ? this.props.className : ''}`} 
                onHide={this.onHide} 
            >
            
                {
                    this.props.header !== undefined && this.props.header !== null ? 
                    <Modal.Header closeButton >
                        {this.props.header}
                    </Modal.Header> : null
                }
                <Modal.Body>
                    {this.renderModalCloseButton()}
                    {this.props.children}
                </Modal.Body>
            </Modal>
        )
    }
    
    closeButtonClicked = () => {
        if(this.props.closeButtonClicked){
            this.props.closeButtonClicked()
            this.toggle(false)
        }else{
            this.toggle(false)
        }
    }

    onHide = () => {
        if(this.props.onHide === false){
        }else{
            this.toggle(false)
        }
    }

    renderModalCloseButton = () => {
        return <img className="close-btn" onClick={this.closeButtonClicked} src={require("../../assets/images/cancel.svg")} alt=""/>
    }

    toggle = (show) => {
        this.setState({show})
    }
}

export default FrappModal;