import React, { useEffect, useState } from 'react'
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
import Tab from '../../uicomponents/tab'
import withTabGroup from '../../hocs/withTabGroup'
import useSystemExotelApi from '../../hooks/useSystemExotelApi'
import ThreeDotLoader from '../../components/utilities/ThreeDotLoader'
import ApiActions from '../../actions/ApiActions'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import Dropdown from 'react-dropdown';
import { cloneDeep } from 'lodash'
import handleErrorMessage from '../../helper/handleErrorMessage'
import { ToastContainer, toast } from "react-toastify";

const ExophoneProjectLink = (props) => {
    const TabGroup = withTabGroup(Tab, 'group');

    const [isLoading, data, setIsLoading, getSystemExotelData] = useSystemExotelApi()
    const [tabs, setTabs] = useState([])
    const [activeTab, setActiveTab] = useState(tabs && tabs.length > 0 ? tabs[0] : '')
    const [activeProjects, setActiveProjects] = useState('')
    const [exophoneOverview, setExophoneOverview] = useState('')

    useEffect(() => {
        let futworkExotelAccounts = data && data.exotelData && data.exotelData.map(({ account, sid }) => { return { account, sid } })
        setTabs([...futworkExotelAccounts])
        setActiveTab(futworkExotelAccounts && futworkExotelAccounts.length > 0 ? futworkExotelAccounts[0].account : '')
        formatData()
    }, [data])

    const formatData = () => {
        let manipulatedData = data && data.exotelData && data.exotelData.map(exo => {
            const { sid, account } = exo
            let phoneKeyNames = []
            Object.keys(exo).filter(function (propertyName) {
                if (propertyName.indexOf("phone") === 0) {
                    phoneKeyNames.push({
                        ...exo[propertyName],
                        callingNumber: propertyName
                    })
                }
            })

            let projectsObj = []
            data && data.projectList.map(project => {
                if (project.exophone && project.exophone.callingAccount == sid) {
                    let r = project.teleprojects.map(({ id, title }) => {
                        return {
                            id,
                            title,
                            callingNumber: project.exophone.callingNumber,
                            isEdit: false
                        }
                    })
                    projectsObj.push(...r)
                }
            })
            return {
                account,
                sid,
                callingNumberList: phoneKeyNames,
                projectsList: projectsObj && projectsObj.sort((a,b)=> a.title.localeCompare(b.title))
            }
        })
        setActiveProjects(manipulatedData)
        setExophoneOverview([...manipulatedData])
    }

    const filterActiveProjects = () => {
        const result = activeProjects && activeProjects.find(({ account }) => account == activeTab)
        return result
    }

    const filterExophoneOverview = () => {
        const result = exophoneOverview && exophoneOverview.find(({ account }) => account == activeTab)
        return result
    }

    const getCallerId = (project) => {
        let result = filterActiveProjects() && filterActiveProjects().callingNumberList &&
            filterActiveProjects().callingNumberList.find(i => i.callingNumber == project.callingNumber)
        return result && result.callerid ? result.callerid : ''
    }

    const getProjectsLinked = (calling) => {
        let result = filterExophoneOverview() && filterExophoneOverview().projectsList &&
            filterExophoneOverview().projectsList.filter(i => i.callingNumber == calling.callingNumber)
        return result && result.length > 0 ? result.length : 0
    }

    const manipulateArray = (project, key, value) => {
        let temp = cloneDeep(activeProjects)
        let act = temp && temp.find(({ account }) => account == activeTab)
        let result = act && act.projectsList &&
            act.projectsList.map(item => {
                if (item.id == project.id) {
                    item[key] = value
                }
                return item
            })
        let r2 = temp && temp.map((proj) => {
            if (proj.account == activeTab) {
                proj.projectsList = result
            }
            return proj
        })
        setActiveProjects(r2)
    }

    const editProject = (project) => {
        manipulateArray(project, 'isEdit', true)
        return false
    }

    const updateCallingNumber = (newValue, project) => {
        // const getNewPhoneNumber = filterActiveProjects().callingNumberList.find(item => item.callerid == newValue).callingNumber
        manipulateArray(project, 'callingNumber', newValue)
        return false
    }

    const saveCallingNumber = async (project) => {
        try {
            const result = await ApiActions.updateTeleProject({ callingNumber: project.callingNumber }, project.id)
            if (result.status == 200) {
                manipulateArray(project, 'isEdit', false)
                let act = exophoneOverview && exophoneOverview.find(({ account }) => account == activeTab)
                let result = act && act.projectsList &&
                    act.projectsList.map(item => {
                        if (item.id == project.id) {
                            item.callingNumber = project.callingNumber
                        }
                        return item
                    })
                let r2 = exophoneOverview && exophoneOverview.map((proj) => {
                    if (proj.account == activeTab) {
                        proj.projectsList = result
                    }
                    return proj
                })
                setExophoneOverview(r2)
            }
        } catch (error) {
            console.error(error)
            let msg = ''
            if (error.response)
                msg = error.response && error.response.data || "Unexpected Error!"
            else
                msg = error.message || "Unexpected Error!"
            toast(handleErrorMessage(msg))
        }
    }

    const defineOptions = () => {
        let result = filterActiveProjects().callingNumberList && filterActiveProjects().callingNumberList.map(({ callerid, callingNumber }) => {
            return { label: callerid, value: callingNumber }
        })
        return result
    }

    return (
        <div className={styles['page-wrapper']}>
            {isLoading ? <ThreeDotLoader /> :
                <div>
                    <TabGroup tabs={tabs.map(item => item.account)} color={'navy-blue-tab-bottom-border'}
                        onClick={(value) => setActiveTab(value)} activeTab={activeTab}
                    />
                    <div className={styles['exotel-project-container']}>
                        <div className={styles['crud-form']}>
                            <div className={[styles['grey-box-wrapper'], styles['w53'], styles['p-0'], styles['account-setting']].join(' ')}>
                                <div className={[styles['dotted-border'], styles['p-4']].join(' ')}>
                                <div className={styles['grid-row-exo']}>
                                    <div><b>Project id</b></div>
                                    <div><b>Project title</b></div>
                                    <div><b>Phone no</b></div>
                                    <div><b>Action</b></div>
                                </div>
                                   <div className={styles['grid-row-exo']}>
                                    {
                                        filterActiveProjects() && filterActiveProjects().projectsList &&
                                        filterActiveProjects().projectsList.map((project, idx) => {
                                            return <>
                                               
                                                <div className={styles['grid-group']}>
                                                    <span>{project.id}</span>
                                                </div>
                                                <div className={styles['grid-group']}>
                                                    <span>{project.title}</span>
                                                </div>
                                                <div className={styles['grid-group']}>
                                                    {project.isEdit ?
                                                        <div>
                                                            <Dropdown
                                                                options={defineOptions()}
                                                                onChange={(e) => updateCallingNumber(e.value, project)}
                                                                className={styles['tool_dropdown']}
                                                                value={defineOptions().find(({ value }) => project.callingNumber == value) ?
                                                                    project.callingNumber : ''}
                                                                placeholder="Select number" />
                                                        </div>

                                                        // <select name="callerid" id="callerid" style={{width: '185px'}}
                                                        // onChange={(e) => updateCallingNumber(e.target.value, project)}
                                                        // placeholder='Select the phone number'>
                                                        //     {
                                                        //     filterActiveProjects().callingNumberList && filterActiveProjects().callingNumberList.map((option => {
                                                        //     return <option value={option.callerid}
                                                        //     key={option.callerid} selected={option.callingNumber == project.callingNumber}>{option.callerid}</option>
                                                        //     }))
                                                        //     }
                                                        // </select>
                                                        :
                                                        <span>{getCallerId(project)}</span>
                                                    }
                                                </div>
                                                <div className={styles['grid-group']}>
                                                <div className={[styles['underline-cta-blue'], styles[project.isEdit ? 'op-0' : 'op-1']].join('')}
                                                    onClick={() => project.isEdit ? saveCallingNumber(project) : editProject(project)}
                                                >{project.isEdit ? 'Save' : 'Edit'}</div>
                                                </div>
                                                </>
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles['crud-form']} style={{width: '40%'}}>
                            <div className={[styles['grey-box-wrapper'], styles['w36'], styles['p-0'], styles['account-setting']].join(' ')}>
                                <div className={[styles['dotted-border'], styles['p-4']].join(' ')}>
                                    <div className={styles['grid-row']}>
                                        <div><b>Name</b></div>
                                        <div><b>Phone no</b></div>
                                        <div><b>Projects linked</b></div>
                                    </div>
                                    {
                                        filterExophoneOverview() && filterExophoneOverview().callingNumberList &&
                                        filterExophoneOverview().callingNumberList.map((num, idx) => {
                                            return <div className={styles['grid-row']} key={idx + 1}>
                                                <div>{num.label}</div>
                                                <div>{getCallerId(num)}</div>
                                                <div>{getProjectsLinked(num)}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        <ToastContainer draggable={true} />
        </div>
    )
}

export default ExophoneProjectLink