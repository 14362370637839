import React, { useEffect, useState } from 'react';
import ApiActions from '../../../actions/ApiActions';
import ThreeDotLoader from "../../utilities/ThreeDotLoader"
import FrappButton from '../../utilities/FrappButton'
import FrappModal from '../../utilities/FrappModal'
import constants from '../../../util/constants';
import ProjectTitle from '../../common/ProjectTitle'
import FormControl from 'react-bootstrap/lib/FormControl';
import Dropdown from 'react-dropdown';
import checkIsEmpty from '../../../helper/checkIsEmpty'
import cloneDeep from "lodash/cloneDeep";
import sanitizeInput from '../../../helper/sanitizeInput';

const READ_MODE = 'read'
const CREATE_MODE = 'create'
const EDIT_MODE = 'update'
const DELETE_MODE = 'delete'
const GRID_VIEW = 'grid'
const LIST_VIEW = 'list'

const dummyFormFields = [
    {
        label: 'First Name',
        name: 'firstname',
        value: '',
        placeholder: 'Enter first name',
        type: 'input'
    },
    {
        label: 'Last Name',
        name: 'lastname',
        value: '',
        placeholder: 'Enter last name',
        type: 'input'
    },
    {
        label: 'Email id',
        name: 'email',
        value: '',
        placeholder: 'Enter email address',
        type: 'input'
    },
    {
        label: 'Mobile No.',
        name: 'mobile',
        value: '',
        placeholder: 'Enter valid mobile number',
        type: 'input'
    },
    {
        label: 'Role',
        name: 'role',
        value: '',
        placeholder: 'Select role',
        type: 'select'
    },
    {
        label: 'Update Password',
        name: 'updatePassword',
        value: false,
        type: 'checkbox'
    }
]

const AccountCreation = (props) => {
    const [userList, setUserList] = useState([])
    const [originalUserList, setOriginalUserList] = useState([])
    const [viewType, setViewType] = useState(GRID_VIEW)
    const [isLoading, setIsloading] = useState(true)
    const [mode, setMode] = useState(READ_MODE)
    const [accountRoles, setAccountRoles] = useState(constants.roles)
    const [currentAccount, setCurrentAccount] = useState({})
    const [showDelete, setShowDelete] = useState(false)
    const [formFields, setFormFields] = useState(dummyFormFields)
    const [apiMsg, setApiMsg] = useState({
        type: '',
        msg: ''
    })
    const [search, setSearch] = useState('')
    const [isFormEdited, setIsFormEdited] = useState(false)

    const fetchUserAccountDetails = () => {
        ApiActions.getUserAccountDetails()
            .then(resp => {
                let sortedData = resp.sort((a, b) => (a.role > b.role) ? 1 : -1)
                setUserList([...sortedData])
                setOriginalUserList([...sortedData])
                setIsloading(false)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchUserAccountDetails()
    }, [])

    const displayUIBasedonMode = (mode) => {
        switch (mode) {
            case READ_MODE:
                return renderUserList()
                break
            case CREATE_MODE:
            case EDIT_MODE:
                return renderForm()
                break
        }
    }

    const renderAccountDeleteConfirm = () => {
        return (
            <FrappModal
                className="custom-modal modal-xs"
                onCloseButton={true}
                bsSize={'small'}
                show={showDelete}
                closeButtonClicked={() => {
                    setCurrentAccount({})
                    setShowDelete(false)
                    setApiMsg({
                        type: '',
                        msg: ''
                    })
                }}
            >
                <div className="notif-popup">
                    <div className="heading" style={{ marginBottom: '3%' }}>Delete Account</div>
                    <div className='sms-stats-popup-section'>
                        {checkIsEmpty(apiMsg) ?
                            <div style={{ color: '#454647', fontWeight: '600' }}>
                                Are you sure you want to delete {currentAccount.firstname} {currentAccount.lastname}’s account?
                        </div> : null}

                        <div style={{ width: "100%" }}>
                            {
                                !checkIsEmpty(apiMsg) ?
                                    <div className={apiMsg.type == 'success' ?
                                        'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
                                    : null
                            }
                        </div>

                        {
                            checkIsEmpty(apiMsg) ?
                                <div className='modal-btn-footer'>
                                    <FrappButton className="submit" id="new-btn"
                                        handler={() => deleteUserAccount()}
                                    >DELETE</FrappButton>
                                </div> : null}
                    </div>
                </div>
            </FrappModal>
        )
    }

    const formatRole = (role) => {
        const extractUser = constants.roles.find(({ value, label }) => {
            if (value == role)
                return label
        })
        return extractUser ? extractUser.label : 'Role not defined'
    }

    const renderUserList = () => {
        return <div className='user-grid-wrapper'>
            {
                userList && userList.length > 0 ?
                    userList.map((account, idx) =>
                        <div className="card" key={idx + 1}>
                            <div className='top-section'>
                                <div className='name'>{`${account.firstname} ${account.lastname}`}</div>
                                <div className='option'>
                                    <img src={require("../../../assets/images/editblue.svg")}
                                        onClick={() => {
                                            setCurrentAccount(account)
                                            let tempData = cloneDeep(formFields)
                                            Object.entries(account).map(([key, value]) => {
                                                tempData.map(item => {
                                                    if (item.name == key) {
                                                        item.value = value
                                                        return item
                                                    }
                                                    return item
                                                })
                                            })
                                            setFormFields(tempData)
                                            setMode(EDIT_MODE)
                                        }
                                        } />
                                    <img src={require("../../../assets/images/del-icon-taskprops.svg")}
                                        className='red-delete-img'
                                        onClick={() => {
                                            setShowDelete(true)
                                            setCurrentAccount(account)
                                        }
                                        } />
                                </div>
                            </div>
                            <div className='main-section'>
                                <div>{formatRole(account.role)}</div>
                                <div>{account.email}</div>
                            </div>
                        </div>
                    )
                    : <div style={{ display: "flex", justifyContent: "center" }}><p> No user account found in records</p></div>
            }
        </div>
    }

    const updateData = (key, value) => {
        if (!isFormEdited) {
            setIsFormEdited(true)
        }
        const tempData = cloneDeep(formFields)
        const result = tempData.filter(item => {
            if (!key) {
                if (item.name == 'updatePassword') {
                    item.value = value
                    return item
                }
                return item
            }
            if (item.name == key) {
                item.value = value
                return item
            }
            return item
        })
        setFormFields(result)
    }

    const handleChange = (option, key) => {
        if (!isFormEdited) {
            setIsFormEdited(true)
        }
        const tempData = cloneDeep(formFields)
        const result = tempData.filter(item => {
            if (item.name == key) {
                item.value = option.value
                return item
            }
            return item
        })
        setFormFields(result)
    }

    const createEditAccount = () => {
        let apiBody = ''
        formFields.forEach(({ name, value }) => {
            apiBody = {
                ...apiBody,
                [name]: sanitizeInput(value)
            };
        })

        const manipulateData = (resp) => {
            const { firstname, lastname } = resp.data
            if (firstname && lastname) {
                setApiMsg({
                    type: 'success',
                    msg: mode == CREATE_MODE ?
                        `${firstname} ${lastname}'s user account created successfully` :
                        `${firstname} ${lastname}'s user account updated successfully`
                })
            } else {
                setApiMsg({
                    type: 'success',
                    msg: mode == CREATE_MODE ?
                        `User account created successfully` : `User account updated successfully`
                })
            }
            setTimeout(() => {
                setIsloading(true)
                setTimeout(() => {
                    setFormFields(dummyFormFields)
                    setCurrentAccount({})
                    setMode(READ_MODE)
                    setApiMsg({
                        type: '',
                        msg: ''
                    })
                    setIsloading(false)
                }, 500);
            }, 1500);
        }

        if (mode == CREATE_MODE) {
            delete apiBody.updatePassword
            ApiActions.createUserAccount(apiBody)
                .then(resp => {
                    let sortedList = [...originalUserList, resp.data].sort((a, b) => (a.role > b.role) ? 1 : -1)
                    setUserList(sortedList)
                    let sortedOriginalList = [...originalUserList, resp.data].sort((a, b) => (a.role > b.role) ? 1 : -1)
                    setOriginalUserList(sortedOriginalList)
                    manipulateData(resp)
                    setSearch('')
                })
                .catch(err => {
                    setApiMsg({
                        type: 'failure',
                        msg: err.response.data.length > 0 ?
                            err.response.data[0].message : err.response.data.msg
                    })
                })
        } else {
            if (!apiBody.updatePassword) {
                delete apiBody.updatePassword
            }
            ApiActions.updateUserAccount(apiBody, currentAccount.id)
                .then(resp => {
                    const updatedUserList = originalUserList.map(user => {
                        if (user.id == currentAccount.id) {
                            return user = resp.data
                        }else{
                            return user
                        }
                    })
                    let sortedList = updatedUserList.sort((a, b) => (a.role > b.role) ? 1 : -1)
                    setUserList(sortedList)
                    let sortedOriginalList = [...updatedUserList].sort((a, b) => (a.role > b.role) ? 1 : -1)
                    setOriginalUserList(sortedOriginalList)
                    manipulateData(resp)
                    setSearch('')
                })
                .catch(err => {
                    console.error(err.response)
                    setApiMsg({
                        type: 'failure',
                        msg: err.response.data.length > 0 ?
                            err.response.data[0].message : err.response.data.msg
                    })
                })
        }

    }

    const deleteUserAccount = () => {
        const payload = {
                "accountid": currentAccount.id
        }
        ApiActions.deleteUserAccount(payload)
            .then(resp => {
                const updatedUserList = originalUserList.filter(user => user.id !== currentAccount.id)
                let sortedList = updatedUserList.sort((a, b) => (a.role > b.role) ? 1 : -1)
                setUserList(sortedList)
                setOriginalUserList([...sortedList])
                setApiMsg({
                    type: 'success',
                    msg: `${currentAccount.firstname} ${currentAccount.lastname}'s user account is deleted`
                })
                setSearch('')
            }).catch(err => {
                console.error(err.response)
                setApiMsg({
                    type: 'failure',
                    msg: err.response.data.length > 0 ?
                        err.response.data[0].message : err.response.data.msg
                })
            })
    }

    const checkIfFormValid = () => {
        const isFilled = formFields.every(item => {
            if (item.name == 'updatePassword')
                return true
            else
                return item.value
        })
        const disabled = isFilled && isFormEdited ? false : true
        return disabled
    }

    const conditionRenderFormElem = (field) => {
        if (field.type == 'input') {
            return <FormControl
                className="input-text w-35"
                name={field.name}
                value={field.value}
                onChange={(e) => updateData(field.name, e.target.value)}
                placeholder={field.placeholder}
            />
        } else if (field.type == 'checkbox' && mode == EDIT_MODE) {
            return <div className='checkbox-row'>
                <label><input type="checkbox" onChange={(e) => updateData(null, e.target.checked)} />Update Password</label>
            </div>
        } else if (field.type == 'select') {
            return <Dropdown
                className={`input-text w-50 ${field.value == '' ?
                    'default-placeholder' : ''}`}
                options={constants.roles}
                onChange={(opt) => handleChange(opt, field.name, field)}
                value={field.value}
                placeholder={field.placeholder}
            />
        }
    }

    const renderForm = () => {
        const goBack = () => {
            setCurrentAccount({})
            setFormFields(dummyFormFields)
            setApiMsg({
                type: '',
                msg: ''
            })
            setMode(READ_MODE)
            setSearch('')
            setUserList(originalUserList)
        }

        return (
            <div className='crud-form'>
                <ProjectTitle title={`Create / Update Account`} goBack={goBack} />
                <div className='form-data'>
                    {
                        formFields.map((field, idx) => {
                            return <div className='group-label-input' key={idx}>
                                {field.type !== 'checkbox' ?
                                    <div className='label-bold'>{field.label} :</div> : null}
                                <div className='input-value'>
                                    {conditionRenderFormElem(field)}
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='cta-section'>
                    {
                        !checkIsEmpty(apiMsg) ?
                            <div className={apiMsg.type == 'success' ?
                                'success-msg' : 'failure-msg'}>{apiMsg.msg}</div>
                            : null
                    }
                    <FrappButton className="submit" id="new-btn"
                        disabled={checkIfFormValid()}
                        handler={() => createEditAccount()}
                    >{mode == CREATE_MODE ? `Create` : `Update`}</FrappButton>
                </div>
            </div>
        )
    }

    const searchUser = (search) => {
        const getBackendKeyword = (inputKey) => {
            const lookUpWord = 'Account Manager'
            if (lookUpWord.toLocaleLowerCase().includes(inputKey)) {
                return 'project'
            }
            return inputKey
        }
        if (search) {
            const updatedList = [...originalUserList]
            const filterData = updatedList.filter((value) => {
                if (value.firstname && value.firstname.toLowerCase().search(search.toLowerCase()) > -1 ||
                    value.lastname && value.lastname.toLowerCase().search(search.toLowerCase()) > -1 ||
                    value.role && value.role.toLowerCase().search(getBackendKeyword(search).toLowerCase()) > -1 ||
                    value.role && value.role.toLowerCase().search(getBackendKeyword(search).toLowerCase()) > -1) {
                    return value
                }
            })
            return setUserList(filterData)
        } else{
            return setUserList(originalUserList)
        }
    }

    return (
        isLoading ? <ThreeDotLoader /> :
        <div className='tools'>
            <div className='container'>
            <ProjectTitle title={`Account creation`} goBack={() => props.history.push("/tools")} />
            <div className='account-wrapper'>
                {mode == READ_MODE ? <input placeholder="Search"
                    type="text" className='search-user-role'
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                        searchUser(e.target.value)
                    }}
                /> : null}
                {mode == READ_MODE ? <FrappButton className="submit" id="create"
                    handler={() => setMode(CREATE_MODE)}>Create</FrappButton> : null}
                {
                    displayUIBasedonMode(mode)
                }
                {renderAccountDeleteConfirm()}
            </div>
        </div>
        </div>
    )
}

export default AccountCreation