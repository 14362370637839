import React, { useEffect, useState } from 'react'
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

const FutworkSetTimer = ({label, time, parentFunction}) => {
    const [open, isOpen] = useState()
    const [value, setValue] = useState(moment(`${time}`, 'HH:mm'))
    
    const setOpen = ({ open }) => {
        isOpen(open)
    };

   useEffect(()=>{
     parentFunction(value, label)
   }, [value])

    const handleValueChange = (val) => {
      setValue(moment(val && val.format('HH:mm')))
    }

    return (
        <div className='set-timer'>
          <ControlLabel>{label}</ControlLabel>
          <TimePicker
            style={{
              position: 'relative',
              marginTop: '-11px'
            }}
            defaultValue={value}
            open={open}
            onOpen={setOpen}
            onClose={setOpen}
            // inputIcon={(useIcon && inputIcon) || undefined}
            // clearIcon={(useIcon && clearIcon) || undefined}
            focusOnOpen
            allowEmpty={false}
            use12Hours={true}
            showSecond={false}
            onChange = {handleValueChange}
            getPopupContainer={triggerNode => triggerNode.parentNode} //used to make the time panel scrollable in modal
          />
        </div>
      );
}

export default FutworkSetTimer