import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import ModerationContainer from '../components/containers/Moderation';
import Wallet from '../components/pages/Wallet';

const ModerationRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    return <Switch>
        <Route exact path={`${path}/brand`}
        render={() => <ModerationContainer {...customProps} />}
        />
        <Route exact path={`${path}/wallet`}
        render={() => <Wallet {...customProps} />}
        />
        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default ModerationRouter