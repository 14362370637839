import React, { useState } from 'react';
import ApiActions from '../../actions/ApiActions';
import FrappButton from '../utilities/FrappButton';
import jwt_decode from "jwt-decode";
import FrappModal from "../utilities/FrappModal"
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            show2FAMsgPop: {
                msg: '',
                status: false,
            },
            errors: {}
        }
    }

    render() {
        return (
            <div className="login" >
                <div className="container" >
                    <img src={require("../../assets/images/login.svg")} alt="" />
                    <img src={require("../../assets/images/futwork-logo.svg")} alt="" className="logo" />
                    <form onSubmit={this.login} >
                        <input placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} type="email" value={this.state.email} />
                        <span className='errMsg'>{this.state.errors.email}</span>
                        <input placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} type="password" value={this.state.password} />
                        <span className='errMsg'>{this.state.errors.password}</span>
                        <FrappButton type="submit" >SUBMIT</FrappButton>
                        <div className="forgot-password" >
                            <Link to="/forgot-password">
                                Forgot password?
                            </Link>
                        </div>
                    </form>
                </div>
                {this.renderMsgAbout2FA()}
            <ToastContainer draggable={true} />
            </div>
        )
    }

    renderMsgAbout2FA = () => {
        return (
            <FrappModal
                onCloseButton={true}
                className='email-verification'
                bsSize={'sm'}
                show={this.state.show2FAMsgPop.status}
                closeButtonClicked={() =>this.setState({show2FAMsgPop: { msg: '', status: false }})}
            >
                <div className="modal-wrapper">
                        <img src={require("../../assets/images/mail-verification.gif")} />
                        <div>{this.state.show2FAMsgPop.msg}</div>
                </div>
            </FrappModal>
        )
    }

    validate = () => {
        let errors = {};
        let isValid = true;

        if(!this.state.email){
            isValid =  false
            errors["email"] = "Please enter your email address";
        }else{
            let emailPattern = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (!emailPattern.test(this.state.email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        if(!this.state.password){
            isValid =  false
            errors["password"] = "Please enter your password";
        }else{
            let passwordPattern = new RegExp(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,20})/);
            if (!passwordPattern.test(this.state.password)) {
               isValid = false;
                errors["password"] = "Passwords must contain at least 8 characters, including uppercase, lowercase letters, numbers and special characters.";
             }
        }
        this.setState({
            errors: errors
          });
      
        return isValid;
    }

    login = (e) => {
        e.preventDefault();
        const isValid = this.validate()
        if(!isValid){
            return false
        }else
        var { email, password } = this.state;
        const base64Password =  Buffer.from(password, 'binary').toString('base64')
        localStorage.clear();

            ApiActions.sendLoginLink({ email, password: base64Password }).then(resp => {
                if(resp.status == 200){
                    this.setState({show2FAMsgPop: {msg:'Verification mail sent to your email address', status: true}})
                }
                setTimeout(()=>{
                    this.setState({show2FAMsgPop: {msg: '', status: false}})
                },2000)
            }).catch(err => {
            console.error(err.response)
            toast(err && err.response && err.response.data && err.response.data.error ? err.response.data.error: `Something went wrong`)
        })
    }

    //old login before 2FA
    // login = (e) => {
    //     e.preventDefault();
    //     var { email, password } = this.state;
    //     localStorage.clear();
    //     ApiActions.login({ email, password })
    //         .then(resp => {
    //             var authToken = resp[0].headers['x-authorization-token'];
    //             // var authTokenVortex = resp[1].headers['x-authorization-token'];
    //             localStorage.setItem('authToken', authToken)
    //             // localStorage.setItem('authTokenVortex', authTokenVortex)
    //             return ApiActions.getUserAcess()
    //         }).then((response) => {
    //             let modifiedTabs = response.tabs.map(obj => {
    //                 obj = Object.keys(obj).toString()
    //                 return obj
    //             })
               

    //             let modifiedTabs2 = response.tabs.filter(obj => {
    //                 if(Object.values(obj)[0].length > 0){
    //                 obj = Object.values(obj)[0].toString()
    //                 return obj
    //                 }
    //             })
    //             localStorage.setItem('accessSubTabs', JSON.stringify({tabs: modifiedTabs2}))
    //             localStorage.setItem('accessTabs', JSON.stringify({tabs: modifiedTabs}))
    //             return ApiActions.getUserName(jwt_decode(localStorage.authToken).base)
    //         }).then((response) => {
    //             localStorage.setItem('userName', `${response.firstname} ${response.lastname}`)
    //             window.location.reload()
    //         })
    // }
}

export default Login;