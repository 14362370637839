import React from "react";


class FrappTooltip extends React.Component{
    render(){
        const {hidden,direction} = this.props;
        return (
            <div className={`frapp-tooltip fade-in ${direction} ${hidden ? "hidden" : ""}`}>
                {this.props.children}
            </div>
        )
    }
}

export default FrappTooltip