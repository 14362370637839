import React, { useState } from "react";

const AttemptInput = ({item, idx, updateForm}) => {
  const [type, setType] = useState("min"); // Default type is 'day'
  const [value, setValue] = useState("");

  const handleTypeChange = (e, item = '') => {
    if(item) updateForm(e.target.value, "type", idx, "onwards")
    else updateForm(e.target.value, "type", idx)
    setValue(""); // Clear the input when the type changes
  };

  const handleValueChange = (e, item = '') => {
    setValue(e.target.value);
    if(item) updateForm(e.target.value, "value", idx, "onwards")
    else updateForm(e.target.value, "value", idx)
  };

  const checkForError = (inputvalue) => {
    const value = parseFloat(inputvalue);
    if (value === '-0') {
        return true;
    }
    if (value < 0 || Object.is(value, -0)) {
        return true
    }
        return false; 
  }

  return (
    <div>
        <input
            type="number"
            value={item.value !== '' ? item.value : ``}
            className={item.number == 1 ? 'read-only-value' : ''}
            disabled={item.number == 1 ? true : false}
            onChange={(e) => item.number == 'onwards' ? handleValueChange(e,item) : handleValueChange(e)}
            placeholder={`Enter ${item.type}(s)`}
            min="0"
            onKeyDown={(e) => {
                if (e.code === 'Minus') {
                   e.preventDefault();
                }
            }}
        />
        <select value={(item.type)} disabled={item.number == 1 ? true : false} 
        onChange={(e) => item.number == 'onwards' ?  handleTypeChange(e, item) : handleTypeChange(e)}>
            <option value="day">Day</option>
            <option value="hour">Hour</option>
            <option value="min">Min</option>
        </select>
        {checkForError(item.value)  ? <span className="input-error">Please provide a valid number</span> : null}
    </div>
  );
};

export default React.memo(AttemptInput);
