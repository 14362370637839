import React, { useContext, useRef, useState } from "react";
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import { BulkActionContext } from "./bulkActionContext";
import { AlertIcon, RefreshIcon } from "../../../assets/images";
import Popup from "../../../uicomponents/popup";
import Table from "../../../uicomponents/table/Table";
import timeStamp from '../../../helper/timeStamp'

const ShowResult = ({ errorMsg, resetData, loading, successMsg }) => {
    const { circularProg, checkComplete, isComplete, setIsComplete, currentAction } = useContext(BulkActionContext)
    const [showPopup, setShowPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const reset = () => {
        // setTimeout(() => {
        //     circularProg.current.classList.toggle(styles['circle_loader'])
        //     checkComplete.current.style.display = 'none'
        // }, 500);
        resetData()
        setIsComplete(0)
        if (!errorMsg && errorMsg.api_error) {
            circularProg.current.classList.toggle(styles['circle_loader'])
            checkComplete.current.style.display = 'none'
        }
    }

    const renderDetailedData = () => {
        const columns = [
            {
                Header: 'Success',
                accessor: 'processed',
                Cell: ({ value }) => {
                    return <div> {value || 'NA'}</div>
                },
                disableSortBy: true
            },
            {
                Header: 'Failure',
                accessor: 'unprocessed',
                Cell: ({ value }) => {
                    return <div> {value || 'NA'}</div>
                },
                disableSortBy: true
            },

        ]

        const { data } = successMsg
        const temp = []

        if (data) {
            data.processed.forEach(element => {
                if (typeof element == 'object' && element.hasOwnProperty('reason')) {
                    temp.push({ processed: `${element.mobile}- ${element.reason}` })
                } else temp.push({ processed: element })
            });
            data.unprocessed.forEach(element => {
                if (typeof element == 'object' && element.hasOwnProperty('reason')) {
                    temp.push({ unprocessed: `${element.mobile}- ${element.reason}` })
                } else temp.push({ unprocessed: element })
            });
        }

        return <Popup
            show={showPopup}
            closePopup={() => { setShowPopup(false) }}
            size='sm'
            heading={`Result for ${currentAction.label}`}
        >
            <Table
                data={temp}
                columns={columns}
                totalCount={temp ? temp.length : 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={10}
                // error={error}
                type='client'
                height='50'
                isGlobalFilter={false}
                // searchBy = {'by name / mobile'}
                title={`Result-of-${currentAction.label}-${timeStamp()}`}
                isExport={true}
            // manualSortBy = {false}
            />
        </Popup>
    }

    const detailedData = () => {
        setShowPopup(true)
    }

    return (
        <div className={styles.fallback_wrapper}>
            {
                errorMsg && errorMsg.api_error ?
                    <div className={styles.error_wrapper}>
                        <img src={AlertIcon} className={styles.error_icon} />
                        <h4>{errorMsg.api_error}</h4>
                    </div> :
                    successMsg && successMsg.failed ?
                        <div className={styles.error_wrapper}>
                            <img src={AlertIcon} className={styles.error_icon} />
                            <h4>{errorMsg.api_error}</h4>
                        </div>
                        :
                        <div className={styles['circle_loader']} ref={circularProg}>
                            <div className={[styles['checkmark'], styles['draw']].join(' ')} ref={checkComplete}></div>
                        </div>
            }
            <h4>{loading && isComplete == 1 ? 'Uploading the leads CSV...' : isComplete == 2 ? <>
                <span>{successMsg.msg}.</span>&nbsp;&nbsp;
                <span onClick={detailedData} className={styles.view_details}>Click here to view the detailed result</span>
            </> : null}</h4>
            {!loading && isComplete > 0 ?
                <div className={styles.reset_row}>
                    <img src={RefreshIcon} onClick={reset} className={styles.reset_icon} />
                    <span onClick={reset}>Reset</span>
                </div>
                : null}
            {showPopup ? renderDetailedData() : null}
        </div>
    )
}

export default ShowResult