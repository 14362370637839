import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import Dropdown from 'react-dropdown';
import moment from "moment";
import { SingleDatePicker,isInclusivelyBeforeDay } from "react-dates";

import styles from '../../../assets/stylesheets/pages/caller/payment.module.scss'
import FrappButton from '../../../components/utilities/FrappButton'
import Card from '../../../uicomponents/card'
import {getPaymentData, transformPaymentData, sendPaymentRequest, raiseBonusRequest} from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import Popup from '../../../uicomponents/popup'

const Payments = () => {
    const [showPaymentConfirm, setShowPaymentConfirm] = useState(false)
    const [paymentRequestRaised, setPaymentRequestRaised] = useState(false)
    const [paymentConfirmation, setPaymentConfirmation] = useState(false)
    const [showPayBonus, setShowPayBonus] = useState(false)
    const [bonusCategory, setBonusCategory] = useState("")
    const [bonusDate, setBonusDate] = useState(null)
    const [startDateFocussed, setStartDateFocussed] = useState(false)

    const getPaymentApi = useApi(getPaymentData, transformPaymentData)
    const sendPaymentRequestApi = useApi(sendPaymentRequest)
    const raiseBonusRequestApi = useApi(raiseBonusRequest)

    useEffect(()=>{
        getPaymentApi.request()
    },[])

    const { data } = getPaymentApi

    useEffect(()=>{
        if(sendPaymentRequestApi.data){
            setPaymentRequestRaised(true)
            setPaymentConfirmation(false)
        }
        if(sendPaymentRequestApi.error){
            toast("Error");
        }
    },[sendPaymentRequestApi.data, sendPaymentRequestApi.error])

    useEffect(()=>{
        if(raiseBonusRequestApi.data){
            toast("Success")
            closePayBonus()
        }
        if(raiseBonusRequestApi.error){
            toast(raiseBonusRequestApi.error.msg || "Does not exist")
        }
    },[raiseBonusRequestApi.data, raiseBonusRequestApi.error])

    const renderConfirmPayment = () => {
        const heading = () => {
            if(!paymentRequestRaised){
                if(!paymentConfirmation){
                    return 'Payments Confirmation'
                }else{
                    return 'Payments'
                }
            }else{
                return 'Payments'
            }
        }

        const ConfirmPayment = () => {
            if(!paymentRequestRaised){
                if(!paymentConfirmation){
                    return <span>Are you sure want to raise a payment request for amount Rs. {data.value}?</span>
                }else{
                    return <span>Amount unpaid: {data.value}</span>
                }
            }else{
                return <span>Payments Request Raised Succesfully</span>
            }
        }

        const Action = () => {
            if(!paymentRequestRaised){
                if(!paymentConfirmation){
                    return <FrappButton className="submit" id="new-btn" handler={()=>sendPaymentRequest()}>CONFIRM</FrappButton>
                }else{
                    return <FrappButton className="submit" id="new-btn" handler={()=>sendPaymentRequest()}>PAY</FrappButton>
                }
            }else{
                return ''
            }
        }

        const sendPaymentRequest = () => {
            sendPaymentRequestApi.request()
        }

        return(
            <Popup
            show={showPaymentConfirm}
            closePopup={()=>{
                setShowPaymentConfirm(false)
                setPaymentRequestRaised(false)
                sendPaymentRequestApi.reset()
            }}
            size='sm'
            heading={heading()}
            popupContent = {<ConfirmPayment/>}
            popupFooter = {<Action/>}
            footerAlign = 'center'
            />
        )
    }

    const closePayBonus = () => {
        setStartDateFocussed(false)
        setBonusCategory('')
        setShowPayBonus(false)
        setBonusDate('')
        raiseBonusRequestApi.reset()
    }

    const renderBonusPay = ()=>{
        let options = [
            { label: "Daily ",value:"daily"},
            { label: "Monthly ",value:"monthly"},
            { label: "Weekly",value:"weekly"},
        ]

        const triggerStartDatePicker = () => {
            setStartDateFocussed(true)
        };

        let startDate = bonusDate ? moment(new Date(bonusDate)) : null;

        const _onSelect=(val)=>{
            setBonusCategory(val.value)
        }

        const setFormDate = (date) => {
            let tempdate;
            tempdate=date.format('YYYY-MM-DD');
            setBonusDate(tempdate)
        }

        const BonusPay = () => {
            return (
            <div className={styles.reportWrapper}>
            <Dropdown options={options} 
            onChange={(val)=>_onSelect(val)} 
            value={bonusCategory} 
            placeholder="Select Bonus Category" />
            <div className='date-report'>
            <div
            name="startDate"
            onClick={() => triggerStartDatePicker()}
            id="startdate"
            >
            <SingleDatePicker
            noBorder={true}
            id="startDateReport"
            date={startDate}
            focused={startDateFocussed}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
            onFocusChange={focused => {
                setStartDateFocussed(focused.focused)
            }}
            placeholder="Select Date"
            onDateChange={(e) => setFormDate(e)}
            displayFormat="DD/MM/YYYY"
            numberOfMonths={1}
            />
            </div>
            </div>
            </div>
            )
        }

        const raiseBonusRequest = () => {
            let dataToSend={
                "startDate":bonusDate,
                "bonusCategory":bonusCategory
            }
            if(!bonusDate||!bonusCategory){
                alert("Please select all fields")
            }else{
                raiseBonusRequestApi.request(dataToSend)
            }
        }

        return(
            <Popup
            show={showPayBonus}
            closePopup={()=>{
                closePayBonus()
            }}
            size='sm'
            heading={'Bonus Pay Request'}
            popupContent = {<BonusPay/>}
            popupFooter = {
                <FrappButton className="submit" id="new-btn" handler={()=>raiseBonusRequest()}>SEND</FrappButton>
            }
            footerAlign = 'center'
            />
        )
    }

    return <div className={styles.page_wrapper}>
            {
             !getPaymentApi.loading && getPaymentApi.error ? (
                <span>Error in fetching data ...</span>
                ) : (
                    <>
                    <Heading text={'TMP Payments'}/>
                    <Card info={getPaymentApi.data}/>
                    <div className={styles.btn_wrapper}>
                    <FrappButton className='submit rounded' id='new-btn'
                    handler={() => setShowPaymentConfirm(true)}>
                    Pay AMOUNT
                    </FrappButton>
                    <FrappButton className='submit rounded'
                    handler={() => setShowPayBonus(true)}>
                    PAY BONUS
                    </FrappButton>
                    </div>
                    </> 
                )
            }
    {showPaymentConfirm ? renderConfirmPayment() : null}
    {showPayBonus ? renderBonusPay() : null}
    <ToastContainer draggable={true} />
    </div>
}

export default Payments