import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions';
import Dropdown from 'react-dropdown';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from '../utilities/FrappButton';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import { ToastContainer, toast } from "react-toastify";


export default class CreateQuiz extends Component {
    constructor(props){
        super(props)
            this.state={
                quizId:"",
                updatedQuestions:[],
                 formData:{
                        name:"",
                        category:"",
                        questions:[
                            {questionText:"",
                            questionType:"",
                            isQuestionCritical:false,
                            options:[{
                                optionText:"",
                                isAnswerCorrect:false,

                            }]
                        }
                        ],

                    }
            }
        
    }
    componentDidMount() {
        this.setState({createQuiz:this.props.location.state.createQuiz})
            if (this.props.match.params.id){
                ApiActions.getQuiz(this.props.match.params.id).then(resp=>{
                    this.setState({quizId:resp.id,formData:resp})
                })
            }
    }
    
    render() {
        let {formData}=this.state;
         let options=[
           { label: "Mission",value:"mission"},
           { label: "Teleproject",value:"teleproject"},
           { label: "Training",value:"training"}
        ]
        return (
            <div className="new-quiz-wrapper">
                  <ToastContainer draggable={true} />
                <div className="heading">Create or Update your Quiz here</div>
               <form onSubmit={this.submitQuiz}>
                    <div className="category" >
                        <label> Name</label>
                       <FormGroup  id="quiz-name"  >
                        <FormControl
                           required
                           name="name"
                         value={formData.name}
                         disabled={!this.state.createQuiz}
                            onChange={(e) => this.setFormName(e)}
                            placeholder="Quiz Name"
                        />
                    </FormGroup>
                     </div>
                    <div className="category">
                        <label> Category</label>
                                 <Dropdown 
                                 id="quiz-dropdown"
                                 options={options} 
                                      onChange={(val)=>this.setFormCategory(val)} 
                                      disabled={!this.state.createQuiz}
                                       value={formData.category} 
                                       placeholder="Select an option" />
                     </div>
                      <div className="questions-wrapper">
                          <label> Questions</label>
                    <div className="fields">
                        <FormGroup>
                             
                            {
                                formData.questions && formData.questions.map(
                                    (data, i) => {
                                        return (
                                            this.renderQuestions(data, i)
    
                                        )
                                    }
                                )
                            }
                         {this.state.createQuiz? 
                         <FrappButton className="grey-button" id="quiz-add-qs" handler={() => this.addQuestion(formData.questions, formData)}>
                                Add Question
                             </FrappButton>
                             :null}  
    
                        </FormGroup> 
                    </div>
                      
                     </div>
                     <FrappButton className="submit" type="submit" id="new-btn-quiz" ref={(c) => this.submitBtn = c}>
                        {this.state.createQuiz?"Create":"Update"}
                            </FrappButton>
               </form>
            </div>
        )
    }
   
     setQuestionType = (activeQuestion, i,type) => {
        let { formData,updatedQuestions } = this.state;
        activeQuestion.questionType = type;
       // formData.questions[i] = activeQuestion;
        this.setState({ formData })
        updatedQuestions.push(i)
        this.setState({updatedQuestions})
    }

      setQuestionText = (e, i) => {
        let { formData ,updatedQuestions} = this.state;
        let activeQuestion = formData.questions[i]
        activeQuestion[e.target.name] = e.target.value;
        updatedQuestions.push(i)
        this.setState({updatedQuestions})
        this.setState({ formData })
    }
    setCritical=(i)=>{
         let { formData ,updatedQuestions} = this.state;
        let activeQuestion = formData.questions[i]
        let critical=activeQuestion.isQuestionCritical
        activeQuestion.isQuestionCritical = !critical;
        this.setState({ formData })
          updatedQuestions.push(i)
        this.setState({updatedQuestions})
    }
    setCorrect=(i,i2)=>{
        let { formData,updatedQuestions } = this.state;
        let activeOption = formData.questions[i].options[i2]
        let correct=activeOption.isAnswerCorrect;
        activeOption.isAnswerCorrect = !correct;
      //  formData.questions[i] = activeQuestion;
        this.setState({ formData })
        updatedQuestions.push(i)
        this.setState({updatedQuestions})
    }
    renderQuestions = (data, i) => {
    let formData=this.state.formData;
      return(
         <div className="question-wrapper">
              <div className="delete-wrapper">
                  <FormGroup  className="quiz-question-text">
                            <ControlLabel> <h4>{i + 1}</h4></ControlLabel>
                            <FormControl
                               required
                               name="questionText"
                             value={data.questionText}
                                onChange={(e) => this.setQuestionText(e, i)}
                                placeholder="Question Text"
                            />
                        </FormGroup>
                {this.state.createQuiz? <FrappButton className="grey-button" handler={() => this.deleteQuestion(formData, i)}>
                         Delete Question
                </FrappButton>:null} 
              </div>
            <div className="radios">
                               {/* <Radio name="questionType" checked={data.questionType === 'singleselect'}  value="singleselect" onChange={(e)=>this.setQuestionType(e,data,i)}  >
                                   <div className="radio-label"> Single Select</div></Radio>
                               <Radio name="questionType" checked={data.questionType === 'multiselect'}  value="multiselect" onChange={(e)=>this.setQuestionType(e,data,i)} >
                                   <div className="radio-label"> Multi Select</div></Radio> */}
                                   <div className="question-type">
                                    <div  className={data.questionType==="singleselect"?"green-tab":"white-tab"} onClick={()=>this.setQuestionType(data,i,"singleselect")}> Single Select</div>   
                                    <div  className={data.questionType==="multiselect"?"green-tab":"white-tab"} onClick={()=>this.setQuestionType(data,i,"multiselect")}> Multi Select</div>   
                                     </div>
                        <Checkbox className="checkbox" checked={data.isQuestionCritical}  onChange={()=>this.setCritical(i)}
                            ><h5>This Question is Critical</h5></Checkbox>
                    </div>
            <div className="options-wrapper">
                          <label> Options</label>
                    <div className="fields">
                        <FormGroup>
                             
                            {
                                data.options && data.options.map(
                                    (datum, i2) => {
                                        return (
                                            this.renderOptions(datum, i,i2,data)
    
                                        )
                                    }
                                )
                            }
                         {this.state.createQuiz? 
                         <FrappButton className="grey-button" id="quiz-add" handler={() => this.addOption(data.options, data)}>
                                Add Option
                             </FrappButton>
                             :null}  
    
                        </FormGroup> 
                    </div>
                     
                     </div>
                
             
         </div>
      )

    }
     addOption = (optionArray, question) => {
        let formData = this.state.formData;
        let options =
        {
            optionText: "",
            isAnswerCorrect:false
        }
        if (optionArray) {
            optionArray.push(options);
            question.options = optionArray;
            this.setState({ formData })
        }
        else  {
            let optionArray = [];
            optionArray.push(options);
            question.options = optionArray;
            this.setState({ formData })
        }
    }
    renderOptions = (option, i, i2, questionArray) => {
        let optionArray = questionArray.options;
        return (
            <div className="options-wrapper">
                <div className="delete-wrapper">
                    <FormGroup className="option-text">
                        <ControlLabel > <h5>{i2 + 1}</h5> </ControlLabel>
                        <FormControl
                            required
                            name="optionText"
                            value={option.optionText}
                            onChange={(e) => this.setOptionText(e, i, i2)}
                            placeholder="Option "
                        />
                      
                    </FormGroup>
                     {this.state.createQuiz?  <FrappButton className="grey-button"  handler={() => this.deleteOption(i2, optionArray, questionArray)}>
                            Delete Option
                    </FrappButton>:null}
                </div>
                 <div id="correctoption">
                     <Checkbox className="checkbox" checked={option.isAnswerCorrect}  onChange={()=>this.setCorrect(i,i2)}
                                ><h5>Correct Answer</h5></Checkbox>
                 </div>
               
            </div>
        )
    }
     deleteOption = (index, optionArray, question) => {
        optionArray.splice(index, 1);
        let formData = this.state.formData;
        question.options = optionArray;
        this.setState({ formData })
    }
     setOptionText=(e,i,i2)=>{
        let { formData,updatedQuestions } = this.state;
        let activeOption = formData.questions[i].options[i2]
        activeOption.optionText = e.target.value;
      //  formData.questions[i] = activeQuestion;
        this.setState({ formData })
        updatedQuestions.push(i)
        this.setState({updatedQuestions})
    }
    deleteQuestion= (formData, i) => {
      formData.questions.splice(i, 1);
    this.setState({ formData })
    }

     addQuestion = (questionArray, quiz) => {
         let {formData}=this.state;
        let questions={
                            questionText:"",
                            questionType:"",
                            isQuestionCritical:false,
                            options:[{
                                optionText:"",
                                isAnswerCorrect:false,

                            }],
                        }
        if (questionArray) {
            questionArray.push(questions);
            quiz.questions = questionArray;
            this.setState({ formData })
        }
        else  {
            let questionArray = [];
            questionArray.push(questions);
            quiz.questions = questionArray;
            this.setState({ formData })
        }
    }

    setFormCategory=(val)=>{
    let { formData } = this.state;
       formData.category=val.value;

        this.setState({ formData })
    }
 setFormName=(e)=>{
     let { formData } = this.state;
       formData.name=e.target.value;
        this.setState({ formData })
    }

submitQuiz=(e)=>{
    
    e.preventDefault();
    
    let {formData,updatedQuestions}=this.state;
    function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
    }
     // updatedQuestions has the updatedIndex
    // formData is the whole form
     if(this.state.createQuiz){
          this.submitBtn.startLoading();
         ApiActions.postQuiz(formData).then((resp) => {
                toast("Quiz Created")
                 this.submitBtn.stopLoading();
                 setTimeout(() => {
                    this.props.history.goBack()
                 }, 3000);
                })
            .catch(() => {
                toast("Error:Check the form again")
                this.submitBtn.stopLoading();
            })
     }
    else if(updatedQuestions.length>0){
         this.submitBtn.startLoading();
    var unique = updatedQuestions.filter(onlyUnique);
   let updated= unique.map(item=>{
        return formData.questions[item]
    })
   updated.map(question=>{
     let dataToSend = {
                        "quiz": formData.id,
                        "question": question
                    }
    ApiActions.updateQuestions(dataToSend).then(resp=>{
        toast("Updated")
         this.submitBtn.stopLoading();
        //  setTimeout(() => {
        //     this.props.history.goBack()
        //  }, 3000);
    }).catch(err=>{
        toast("Error")
    })
   })
    }
    else 
    toast("You need to edit the quiz to update.")
  
}
}
