import React from 'react';
import Tools from '../pages/Tools';


class ToolsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return <Tools />
    }
}

export default ToolsContainer;