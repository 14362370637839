import React from 'react'
import PanelGroup from 'react-bootstrap/lib/PanelGroup'
import Panel from 'react-bootstrap/lib/Panel'
import styles from './panel.module.scss'

const FutworkPanel = (props) => {
    return(
        <div className={styles['custom-panel']}>
        {/* <PanelGroup accordion id="accordion-example"> */}
        <Panel eventKey={props.index + 1} key={props.index + 1}>
            <Panel.Heading className={props.arrowPosition ? styles.left_arrow : ''}>
                <Panel.Title toggle><div className="title">{props.title}</div></Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>{props.children}</Panel.Body>
        </Panel>
        {/* </PanelGroup> */}
        </div>
    )
}

export default React.memo(FutworkPanel)