import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from "react-toastify";
//custom imports
import { getApiEnabledStats } from './service'
import useApi from '../../../hooks/useApi'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader';
import handleErrorMessage from '../../../helper/handleErrorMessage'
import CustomTab from '../../../components/utilities/CustomTab';
import { v4 as uuidv4 } from 'uuid';
import ApiActions from '../../../actions/ApiActions';
import FrappButton from '../../../components/utilities/FrappButton';
import Switch from "react-switch";
import {AlertIcon} from '../../../assets/images'
import Datepicker from '../../../uicomponents/date-picker'
import moment from 'moment'


const ApiStats = (props) => {

    const allSubscriptionCheckboxes = [
        { label: 'All attempts', checked: false, id: 'all_attempts' },
        { label: 'Final attempt', checked: false, id: 'final_attempt' }
    ]
    const [activeTab, setActiveTab] = useState({
        label: 'Stats',
        value: 'stats'
    })
    const [title, setTitle] = useState('')
    const [schema,setSchema] = useState('')
    const [url, setUrl] = useState('')
    const [secretKey, setSecretKey] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [editable, setEditable] = useState(true)
    const [brandAppExists, setBrandAppExists] = useState(false)
    const [brandAppId, setBrandAppId] = useState('')
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [teleprojectId, setTeleprojectId] = useState('')
    const [isApiIntegrated, setIsApiIntegrated] = useState(false)
    const [subscriptionCheckboxes, setSubscriptionCheckboxes] = useState(allSubscriptionCheckboxes)
    const [defaultDate, setDefaultDate] = useState({
        from: moment().subtract(7, 'day').toDate(), 
        to: moment().toDate()
    })
    const [inputsValid, setInputsValid] = useState('valid')

    const getApiEnabledStatsApi = useApi(getApiEnabledStats)

    const tabsForStage = [
        {
            label: 'Stats',
            value: 'stats'
        },
        {
            label: 'API Settings',
            value: 'api_settings'
        }
    ]

    useEffect(()=>{
        setTitle(props.data.title)
        setTeleprojectId(props.data.id)
        if (props.data.brandapp && props.data.brandapp.length>0) 
        {
            getApiEnabledStatsApi.request({teleproject: props.data.id, startDate: defaultDate.from, endDate: defaultDate.to })
            ApiActions.getBrandApp(props.data.brandapp).then(res =>{
                if (Object.keys(res).length>0){
                    setBrandAppId(res.id)
                    setBrandAppExists(true)
                    setSchema(JSON.stringify(res.mapout,null,2))
                    setUrl(res.postbackurl)
                    setSecretKey(res.secretkey ? res.secretkey : secretKey)
                    setIsActive(res.isActive)
                    setEditable(false)
                    if(res && res.subscription) {
                        updateSubscription(res.subscription)
                    }
                    setIsApiIntegrated(true)
                }
            })
        } else {
            setIsApiIntegrated(false)
        }
    }, [])

    useEffect(()=>{
        const isSecretKeyValid = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(secretKey);
        const pattern = new RegExp('^(https?:\\/\\/)?'+'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ '((\\d{1,3}\\.){3}\\d{1,3}))'+ '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ '(\\?[;&a-z\\d%_.~+=-]*)?'+ '(\\#[-a-z\\d_]*)?$','i');
        const isUrlValid = pattern.test(url)
        const isValidJSON = () => {
            try {
                JSON.parse(schema);
                return true;
            } catch (e) {
                return false;
            }
        };
        const isSchemaValid = isValidJSON(schema);
        const checkedSubscription = subscriptionCheckboxes.filter(box => box.checked)

        if (!isUrlValid) setInputsValid('url')
        else if (!isSecretKeyValid) setInputsValid('secret-key')
        else if (!isSchemaValid) setInputsValid('schema')
        else if (checkedSubscription.length <= 0) setInputsValid('subscription boxes not checked')
        else setInputsValid('valid')
    },[url,secretKey,schema,subscriptionCheckboxes])

    const updateSubscription = (subscription) => {
        const newCheckboxes = allSubscriptionCheckboxes.map((checkbox) => ({
            ...checkbox,
            checked: subscription.includes(checkbox.id),
        }))
        setSubscriptionCheckboxes(() => [...newCheckboxes])
    }

    const handleSubscription = (index) => (event) =>  {
        const newCheckboxes = [...subscriptionCheckboxes]
        newCheckboxes[index].checked = event.target.checked
        setSubscriptionCheckboxes(newCheckboxes)
    }


    const renderContent = (activeTab) => {
        switch (activeTab.value) {
            case 'api_settings':
                return brand()
            case 'stats':
                return stats()
            default:
                break;
        }
        return stats()
    }

    const setActiveTabFn = (activeTab) => {
        setActiveTab({
            label: activeTab.label,
            value: activeTab.value
        })
    }

    const editForm = () => {
        if (window.confirm("Are you sure you want to edit ?")) setEditable(true)
    }

    const submitForm = async() => {
        try{
            if(brandAppExists){
                const payLoad = {
                teleproject: teleprojectId,
                mapout: JSON.parse(schema),
                postbackurl: url,
                secretkey: secretKey,
                isActive: isActive,
                updatedAt: new Date().toISOString(),
                subscription: subscriptionCheckboxes.filter((sub) => sub.checked).map((sub) => sub.id)
            }
            await ApiActions.updateBrandApp(brandAppId,payLoad).then(()=>{
                setEditable(false)
                setFeedbackMessage('success')
            })
        }
        else{
            const payLoad = {
                    teleproject: teleprojectId,
                    brand:props.data.brand,
                    mapout: JSON.parse(schema),
                    postbackurl: url,
                    secretkey: secretKey,
                    isActive: isActive,
                    subscription: subscriptionCheckboxes.filter((sub) => sub.checked).map((sub) => sub.id)
                }
                await ApiActions.postBrandApp(payLoad).then(res=>{
                    setBrandAppId(res.data.id)
                    setBrandAppExists(true)
                    setEditable(false)
                    setFeedbackMessage('success')
                    ApiActions.updateTeleProject({brandapp:res.data.id},teleprojectId)
                })
            }
        }catch(err){
            setFeedbackMessage('failed')
            console.log(err)
        }
    }

    const brand = () => {
        return <div className='api-popup'>
            <div className='first-row'>
                <div className='active-switch'>
                    <div className='brand-label'>Is Active</div>
                    <div>
                        <Switch 
                        checked={isActive}
                        onChange={(e) => {setIsActive(e)}} 
                        disabled = {!editable}
                        />
                    </div>
                </div>
                <FrappButton disabled={editable} className='edit-button' handler={()=>editForm()}>Edit</FrappButton>
            </div>
            <div className='brand-label'>Title</div>
            <input type='text' className='brand-textarea' value={title} disabled={true}/>
            <div className='brand-label'>Postback Schema</div>
            <textarea rows={10} className='brand-textarea' value={schema} disabled={!editable} onChange={(e)=>setSchema(e.target.value)}/>
            <div className='brand-label'>Postback URL</div>
            <input type='text' className='brand-textarea' value={url} disabled={!editable} onChange={(e)=>{setUrl(e.target.value)}}/>
            <div className='brand-label'>Event subscription</div>
            <div className='brand-subscription'>
            {
                subscriptionCheckboxes.map((sub, index) => {
                    return (
                        <label key={sub.id}>
                            <input 
                                checked={sub.checked} 
                                type='checkbox'
                                onChange={handleSubscription(index)}
                                disabled={!editable}
                            />
                            {sub.label}
                        </label>
                    )
                })
            }
            </div>

            <div className='brand-label'>Secret Key</div>
            <div className='secret-key-section'>
                <input type='text' className='secret-key' value={secretKey} disabled={!editable} onChange={(e)=>{setSecretKey(e.target.value)}}/>
                <FrappButton className='generate-button' handler={()=>{setSecretKey(uuidv4())}} disabled={!editable}>Generate</FrappButton>
            </div>
            {
                feedbackMessage == "success" ? 
                <div className='success-message'>Submitted Successfully !</div>
                : feedbackMessage == "failed" ? 
                <div className='failed-message'><img src={AlertIcon} alt='warning'/> Failed ! Please check the inputs.</div>
                : '' 
            }
            <FrappButton className='submit-button' disabled={!editable||(inputsValid!=="valid")} handler={()=>submitForm()}>Submit</FrappButton>
        </div>
    }

    const stats = () => {
        if(isApiIntegrated) {
            return        <div className='api-enabled-stats-modal'>
            <div className="api-enabled-stats-modal-body">
                <div className="heading" style={{marginBottom:"10px"}}>Postback for terminnal leads</div>
                <Datepicker
                        placeholderText="Click to select a date"
                        selectedDate={new Date()}
                        isDateRange = {true}
                        from={moment(defaultDate.from).toDate()}
                        to={moment(defaultDate.to).toDate()}
                        onChange={(daterange) => {
                            const [from, to] = daterange
                            if(from && to){
                                setDefaultDate({
                                    from: moment(from).format('YYYY-MM-DD'),
                                    to: moment(to).format('YYYY-MM-DD')
                                })
                                getApiEnabledStatsApi.reset()
                                getApiEnabledStatsApi.request({teleproject: props.data.id, startDate: moment(from).format('YYYY-MM-DD'), endDate: moment(to).format('YYYY-MM-DD') })
                            }
                        }}
                    />
                <br />
                {
                loading ? 
                <ThreeDotLoader/>
                :
                error ?
                <p>{typeof error == 'object' && error !== null && !Array.isArray(error) ? 
                error.error || error.msg : error}</p>
                :
                <div className="api-stats-wrapper">
                    <div className="stats-container green">
                        <div className="stats-item">
                            <p>Expected</p>
                            <p>{apiEnabledStats && (apiEnabledStats.expected || apiEnabledStats.expected === 0) ? apiEnabledStats.expected : "NA"}</p>  
                        </div>
                        <hr/>
                        <div className="stats-item">
                            <p>Successful</p>
                            <p>{apiEnabledStats && (apiEnabledStats.successfull || apiEnabledStats.successfull === 0) ? apiEnabledStats.successfull : "NA"}</p>  
                        </div>
                        <div className="stats-item">
                            <p>Rejected</p>
                            <p>{apiEnabledStats && (apiEnabledStats.rejected || apiEnabledStats.rejected === 0) ? apiEnabledStats.rejected : "NA"}</p>  
                        </div>
                        <div className="stats-item">
                            <p>Missing</p>
                            <p>{apiEnabledStats && (apiEnabledStats.missing || apiEnabledStats.missing === 0) ? apiEnabledStats.missing : "NA"}</p>  
                        </div>
                    </div>
                    <div>
                  
                    </div>
                </div>
                }
                <div className="stats-note">
                    <p>Missing and Rejected should be zero, contact tech team otherwise</p>
                </div>
            </div>
            </div>
        } else {
            return <div className="stats-note m-100">
                <p className='text-lg'>
                The project does not have postback API enabled. To configure this, please proceed to the "API Settings" section. </p>
            </div>
        }

    }

    let {data, loading, error} = getApiEnabledStatsApi
    // console.log({data, loading, error})
    if(error){
        toast(handleErrorMessage(error))
    }
    let apiEnabledStats = null
    if(!loading) {
        apiEnabledStats = data
    }
    return (
        <div className='api-wrapper'>
            <div><CustomTab tabs={tabsForStage} setActiveTabFn={(data) => setActiveTabFn(data)} className={'navy-blue'}/></div>
            {renderContent(activeTab)}
        </div>
    )
}

export default ApiStats