import React, { useContext, useEffect, useState } from "react";
import styles from '../../../assets/stylesheets/pages/hiring/hiring.module.scss'
import Datepicker from "../../../uicomponents/date-picker/datepicker";
import FutworkSetTimer from "../../../components/utilities/FutworkSetTimer";
import FutworkButton from "../../../uicomponents/button/Button";
import { TrainingSessionContext, TrainingSessioProvider } from './trainingSessionContext'
import moment from 'moment'
import InviteAgents from './inviteAgent'
import { createUpdateTrainingMeetings } from './service'
import useApi from "../../../hooks/useApi";
import handleErrorMessage from "../../../helper/handleErrorMessage";
import Message from "./message";

const CreateSession = ({ updateList }) => {
    const createUpdateTrainingMeetingsApi = useApi(createUpdateTrainingMeetings)
    const {
        stepCount, setStepCount,
        projectData, setProjectData,
        setSelectedRow
    } = useContext(TrainingSessionContext)

    const [formData, setFormData] = useState({
        title: '',
        meetingDate: moment().toDate(),
        url: ''
    })

    const [isLinkValid, setIsLinkValid] = useState(true)
    const [apiMessage, setApiMessage] = useState({
        status: '',
        msg: ''
    })

    const [sessionDate, setSessionDate] = useState(moment().toDate())

    const checkIfValid = () => {
        const { title, url, meetingDate } = formData

        if (!title || !url || !meetingDate) {
            return true
        } else {
            return false
        }
    }

    const isValidUrl = (urlString) => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    const updateForm = (type, value) => {
        const tempFormData = { ...formData }
        tempFormData[type] = value
        setFormData(tempFormData)
        if (type == 'url') {
            setIsLinkValid(true)
        }
    }

    const createProjectTrainingSession = () => {
        setApiMessage({ status: '', msg: '' })
        if (isValidUrl(formData.url)) {
            setIsLinkValid(true)
            let trainingMeeting = { ...formData }
            //convert to iso date
            trainingMeeting.meetingDate = moment(trainingMeeting && trainingMeeting.meetingDate).toISOString()

            const payload = {
                teleprojectId: projectData && projectData.id,
                trainingMeeting
            }
            createUpdateTrainingMeetingsApi.request(payload)
        } else {
            setIsLinkValid(false)
        }
    }

    const { data, loading, error } = createUpdateTrainingMeetingsApi || {}

    const showMessage = () => {
        const { status, msg } = apiMessage || {}

        const checkStatus = () => {
            switch (status) {
                case 'pass':
                    return 'success'
                case 'fail':
                    return 'error'
                default:
                    return ''
            }
        }

        const variant = checkStatus()
        return msg && status && <Message variant={variant} children={msg} />
    }

    useEffect(() => {
        if (loading == false && data !== null) {
            if (data.status == 200) {
                const { data: project } = data
                const tempProjectData = { ...project.data }
                tempProjectData.trainingMeetings = project.data && project.data.trainingMeetings
                setProjectData(tempProjectData)
                const { trainingMeetings } = tempProjectData
                const latest = trainingMeetings[trainingMeetings.length - 1]
                setSelectedRow(latest)
                setApiMessage({
                    status: 'pass',
                    msg: 'The session is created'
                })
                updateList(tempProjectData && tempProjectData.trainingMeetings)
                setTimeout(() => {
                    setStepCount(stepCount + 1)
                }, 3000)
            }
        }

        if (error) {
            setApiMessage({
                status: 'fail',
                msg: handleErrorMessage(error)
            })
        }
    }, [loading, data, error])

    return stepCount === 1 ?
        <div className={styles.training_session_form}>
            <div>
                <label className={styles.label}>Session title</label>
                <input type='text' className={styles.text} name="title" placeholder="Enter session title here"
                    value={formData.title} onChange={(e) => updateForm('title', e.target.value)} />
            </div>

            <div className={styles.date_time_select_label}>
                <label className={styles.label}>Training date & time</label>
            </div>

            <div className={styles.date_time_select_row}>
                <Datepicker
                    dateFormat="dd/MM/yyyy"
                    selectedDate={formData.meetingDate}
                    placeholderText='Select training date'
                    onChange={date => {
                        let now = date
                        updateForm('meetingDate', date)
                        setSessionDate(date)
                    }}
                    isDateTime={true}
                />
            </div>

            <div>
                <label className={styles.label}>Meeting link</label>
                <input type='text' className={styles.text} name="link" placeholder="Paste the meeting link here"
                    value={formData.url} onChange={(e) => updateForm('url', e.target.value)} />
                {!isLinkValid && <div className={styles.error_msg}>Please enter a valid link</div>}
            </div>


            <div className={styles.cta_center}>
                <FutworkButton buttonSize='sm' buttonStyle='primary--solid'
                    onClick={() => createProjectTrainingSession()}
                    disabled={checkIfValid() || loading}
                >Create session</FutworkButton>
            </div>
            {showMessage()}
        </div>
        :
        <div>
            <InviteAgents updateList={updateList} />
        </div>
}

export default React.memo(CreateSession)