import React, { useState, useContext, useEffect } from 'react'
import Papa from 'papaparse';

import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Upload from "../../../uicomponents/upload/upload";
import Dropdown from 'react-dropdown';

import BrandSearch from '../../../components/pages/Tools/BrandSearch';
import handleErrorMessage from "../../../helper/handleErrorMessage";
import { BulkActionContext } from './bulkActionContext';
import ApiActions from "../../../actions/ApiActions";
import useApi from "../../../hooks/useApi";
import CSVSchema from "./csvSchema";
import FutworkButton from "../../../uicomponents/button/Button";
import ShowResult from "./showResult";

const OfferLetter = () => {
    const [brandName, setBrandName] = useState()
    const [brandSelected, setBrandSelected] = useState('')
    const [file, setFile] = useState('')
    const [inputValue, setInputValue] = useState({
        fileData: '',
        brand: ''
    })
    const [errorMsg, setErrorMsg] = useState({
        fileData: '',
        brand: '',
        api_error: ''
    })
    const [successMsg, setSuccessMsg] = useState({
        data: '',
        msg: '',
        failed: false
    })

    const { circularProg, checkComplete, isComplete, setIsComplete } = useContext(BulkActionContext)

    const bulkAddOfferLetterApi = useApi(ApiActions.bulkAddOfferLetter)

    const { data, loading, error } = bulkAddOfferLetterApi || {}

    useEffect(() => {
        setErrorMsg({
            ...errorMsg,
            api_error: handleErrorMessage(error)
        })
    }, [error])

    useEffect(() => {
        if (loading == false && (data && data.data)) {
            setIsComplete(2)
            if (data && data.data && data.data.unprocessed && data.data.unprocessed.length > 0) {
                setSuccessMsg({
                    msg: `Failed to add offer letter for ${data && data.data && data.data.unprocessed.length} agent${data.data.unprocessed.length > 1 ? `'s` : ''}`,
                    data: data && data.data, failed: true
                })
            } else {
                setSuccessMsg({ msg: `The offer letter is added for ${data && data.data && data.data.processed.length} agent${data.data.processed.length > 1 ? `'s` : ''}`, data: data && data.data })
                setTimeout(() => {
                    circularProg.current.classList.toggle(styles['load_complete'])
                    checkComplete.current.style.display = 'block'
                }, 1000);
            }
        }
    }, [loading, data])


    const resetData = () => {
        setErrorMsg({ fileData: '', api_error: '', brand: '' })
        setSuccessMsg({ data: '', msg: '', failed: false })
        setInputValue({ fileData: '', brand: '' })
    }

    //call the function on choose the file
    const changeHandler = (e) => {
        const isValidUrl = (urlString) => {
            var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
            return !!urlPattern.test(urlString);
        }

        const isNumeric = (str) => {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str))
        }

        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) { //check if the attached file is csv
            if (e.target.files.length > 0) {
                setFile(file)
                Papa.parse(file, {
                    header: true,
                    complete: function (results) {
                        const sanitizedResult = results.data.map(obj => {
                            if (obj.mobile && obj.url) {
                                if (!isValidUrl(obj.url)) {
                                    const temp = { ...errorMsg }
                                    temp.fileData = 'An invaid url is mentioned in the CSV'
                                    setErrorMsg(temp)
                                }
                                if (!isNumeric(obj)) {

                                }
                                return obj
                            }
                        }).filter(Boolean)
                        setInputValue({
                            ...inputValue,
                            fileData: sanitizedResult
                        })
                    }
                }
                )
            }
            const temp = { ...errorMsg }
            temp.fileData = ''
            setErrorMsg(temp)
        } else {
            setFile('')
            const temp = { ...errorMsg }
            temp.fileData = 'Only a file with .csv extension can be uploaded'
            setErrorMsg(temp)
        }
        e.target.value = ''
    }

    //check if a file is attached
    const checkIfDataPresent = () => {
        const { fileData, brand } = inputValue
        if (fileData && brand) return false
        else return true
    }


    const onBrandSelect = (brand) => {
        setBrandSelected(brand.name)
        setBrandName('')
        setInputValue({
            ...inputValue,
            brand: brand.id
        })
    }

    //validate the data  in csv
    const checkIfValid = (csvheaders) => {
        let validationErrors = {};
        const multipleMandatoryHeaders = []
        csvheaders && csvheaders.forEach(item => {
            if (item.toLowerCase() == 'mobile' || item.toLowerCase() == 'url') multipleMandatoryHeaders.push(item)
        });

        // Simple validation for empty fields
        for (const key in inputValue) {
            if (key == 'brand' && inputValue[key] == '') {
                validationErrors[key] = 'Please select a brand'
            }
            if (key == 'fileData' && multipleMandatoryHeaders && multipleMandatoryHeaders.length < 2) {
                validationErrors[key] = `Please ensure that "mobile" & "url" is mentioned in the csv schema`
            }
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            return false;
        } else {
            return true
        }
    }
    //upload the csv with offer letter url for corresponding agents
    const triggerBulkOfferLetter = () => {
        const [first] = inputValue.fileData
        const body_vars = first ? Object.keys(first).map(item => item) : []
        if (checkIfValid(body_vars)) {
            const formData = new FormData();
            // formData.append('teleprojectId', projectData.id);
            formData.append('file', file);
            formData.append('brand', inputValue.brand)
            bulkAddOfferLetterApi.request(formData)
            setIsComplete(1)
        }
    }

    const props = {
        resetData,
        errorMsg,
        loading,
        successMsg
    }

    return isComplete <= 0 ? <div>
        <div className={styles.crud_box}>
            <div className={styles.crud_form}>
                <div className={styles.form_label}>Select Brand</div>
                <BrandSearch onBrandSelect={onBrandSelect}
                    placeholder={'Type to search the brand'}
                    searchValue={brandName}
                />
                {
                    errorMsg && errorMsg.brand ? <span className={styles.csv_error}>{errorMsg.brand}</span> : null
                }
            </div>
        </div>

        <div className={styles.crud_box}>
            <div className={styles.crud_form}>
                <CSVSchema />
                <Upload placeholder='Select CSV file to upload' onChange={changeHandler} />
                {
                    errorMsg && errorMsg.fileData ? <span className={styles.csv_error}>{errorMsg.fileData}</span> : null
                }
            </div>
        </div>
        <div className={styles['text-center']}>
            <FutworkButton buttonStyle="primary--solid" buttonSize="medium"
                disabled={checkIfDataPresent()}
                onClick={triggerBulkOfferLetter}>SUBMIT</FutworkButton>
        </div>
    </div> :
        <div className={styles.crud_box}>
            <ShowResult {...props} />
        </div>
}

export default OfferLetter