import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import Datepicker from '../../../uicomponents/date-picker'
import { getCallsOfCaller } from '../qmf/service'
import { getIqcFlaggedCalls, getTeleProject } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import { UserIcon, DataCollection, TrackHistory, AttachmentIcon, StatsIcon } from '../../../assets/images'
import CustomTooltip from '../../../components/utilities/ToolTip'
import Dropdown from 'react-dropdown';
import Popup from '../../../uicomponents/popup'
import CustomTab from '../../../components/utilities/CustomTab'
import CustomerInfo from '../../../components/utilities/CustomerInfo'
import ApiActions from "../../../actions/ApiActions"
import ThreeDotLoader from "../../../components/utilities/ThreeDotLoader"
import ProjectTitle from '../../../components/common/ProjectTitle';
import CallDisconnectedBy from "../../../helper/CallDisconnectedBy";
import QCInsights from '../qcInsights';

const popupTabs = [
    {
        label: 'Data collection',
        value: 'data_collection'
    },
    {
        label: 'Call history',
        value: 'call_history'
    },
    {
        label: 'Customer info',
        value: 'customer_info'
    }
]

const CallsListOfCaller = (props) => {
    const {state: {query, outcomeQuery}} = props.location
    const searchBy = query
    const outcome = outcomeQuery

    if(!props.location.state){
        props.history.push({
            pathname: `/audit/v2`,
        })
        return true
    }

    const prevIqcActionTakenDate = props.location.state.callerDetails.prevIqcActionTakenDate !== undefined ?
        moment(props.location.state.callerDetails.prevIqcActionTakenDate).toDate().toISOString()
        : props.location.state.callerDetails.createdAt !== '' ?
        moment(props.location.state.callerDetails.createdAt).toDate().toISOString() : ''
    
    const getCallsOfCallerApi = useApi(getCallsOfCaller)
    const getIqcFlaggedCallsApi = useApi(getIqcFlaggedCalls)

    const {state: {callPage}} = props.location
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(callPage ? callPage : 1);
    const [fromDate, setFromDate] = useState(prevIqcActionTakenDate ? moment(prevIqcActionTakenDate).toDate() : '')
    const [selectOutcome, setSelectOutcome] = useState(outcome ? outcome : '')
    const [showCallInfo, setShowCallInfo] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState()
    const [activeTab, setActiveTab] = useState({
        label: popupTabs[0].label,
        value: popupTabs[0].value
    })
    const [projectData, setProjectData] = useState('')
    const [qcinsightsModal, setqcinsightsModal] = useState({
        show: false,
        data: ''
    })

    const { title: projectName, name, userId } = props.location.state.callerDetails
    useEffect(() => {
        addParamToUrl()
        const skip = (currentPage - 1) * limit
        const data = {
            userId: props.location.state.callerDetails.user,
            // teleprojectId: props.location.state.callerDetails.teleprojectId,
            teleprojectId: props.location.state.callerDetails.teleproject,
            from: fromDate ? moment(fromDate).format("DD/MM/YYYY") : '',
            outcome: selectOutcome || undefined
        }
        if(props.location.state.callerDetails && !props.location.state.callerDetails.outcomes){
           projectDetails(props.location.state.callerDetails.teleproject)
        }
        getIqcFlaggedCallsApi.request(skip, limit, sort, search, data)
    }, [currentPage, sort, search, fromDate, selectOutcome])

    const isInteger = (value = '') => {
        return /^-?\d+$/.test(value)
    }

    const addParamToUrl = () => {
        if(selectOutcome){
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    callerDetails: props.location.state.callerDetails,
                    activeTab: props.location.state.activeTab,
                    page: props.location.state.page,
                    query: searchBy,
                    outcomeQuery: selectOutcome
                }
            })
        }else{
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    callerDetails: props.location.state.callerDetails,
                    activeTab: props.location.state.activeTab,
                    page: props.location.state.page,
                    query: searchBy,
                    outcomeQuery: selectOutcome
                }
            })
        }
    }

    const copyToClipboard = (recordingUrl) => {
        navigator.clipboard.writeText(recordingUrl);
    }

    const disabledRow = (children) => {
        return <div className={styles['disabled-row']}>{children}</div>
    }

    const columns = [
        {
            Header: 'S.No',
            width: "1%",
            Cell: (cell) => {
                let rowcomponent = <span>{((currentPage - 1) * limit) + (Number(cell.row.id) + 1)}</span>
                return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            disableSortBy: true
        },
        {
            Header: 'Audio',
            accessor: 'recordingUrl',
            Cell: (cell) => {
                let {recordingUrl: value } = cell.row.original || {}
                let rowcomponent = value ?
                    <div className={styles.audio_copy_link}>
                        <audio controls src={value} />
                        <CustomTooltip placement={'bottom'} description={'Copy audio link'}
                            component={
                                <img title="Copy link" className='mock-record-url' src={AttachmentIcon}
                                    onClick={() => copyToClipboard(value)}
                                    alt='copy audio link' />
                            }
                        />
                    </div>
                    : 'NA'
                return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Outcome',
            accessor: 'outcome',
            Cell: (cell) => {
                let {outcome: value} = cell.row.original || {}
                let rowcomponent = `${value && value.title || 'NA'}`
                return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "8%",
            disableSortBy: true
        },
        {
            Header: 'Call Date',
            accessor: 'callDate',
            Cell: (cell) => {
                let {callDate: value} = cell.row.original
                let rowcomponent = <span>{moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')}</span> || 0
                return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "9%",
            disableSortBy: true
        },
        {
            Header: 'Disconnected by',
            accessor: 'disconnectedBy',
            Cell: (cell) => {
                let {disconnectedBy: value} = cell.row.original
                let rowcomponent = CallDisconnectedBy(value || '')
                return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "9%",
            disableSortBy: true
        },
        {
            id: "expander",
            Header: 'Actions',
            accessor: "action",
            // expander: true,
            Cell: (row, rows, toggleRowExpanded) => {
                let rowcomponent = (
                    <div style={{ display: 'flex', gap: '2em' }}>
                        <CustomTooltip placement={'bottom'} description={'Data collection'}
                            component={<img src={DataCollection} width='18px'
                                alt='Data collection'
                                onClick={() => getRowData(row.cell.row.original, popupTabs[0].value)} />}
                        ></CustomTooltip>
                        <CustomTooltip placement={'bottom'} description={'Call history'}
                            component={<img src={TrackHistory} width='18px'
                                alt='Call history'
                                onClick={() => getRowData(row.cell.row.original, popupTabs[1].value)} />}>
                        </CustomTooltip>
                        <CustomTooltip placement={'bottom'} description={'Customer info'}
                            component={<img src={UserIcon} width='18px'
                                alt='Customer info'
                                onClick={() => getRowData(row.cell.row.original, popupTabs[2].value)} />}>
                        </CustomTooltip>
                        <CustomTooltip placement={'bottom'} description={'Caller vs System performance avg'}
                            component={<img src={StatsIcon} width='18px'
                                alt='Caller vs System performance avg'
                                onClick={() => {
                                    setqcinsightsModal({show: true, data: {
                                        teleproject: props.location.state && props.location.state.callerDetails && props.location.state.callerDetails.teleproject,
                                        userId: props.location.state && props.location.state.callerDetails && props.location.state.callerDetails.user
                                    }})
                                }} />}>
                        </CustomTooltip>     
                    </div>
                )
                return row.cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'QMF',
            accessor: 'qmf',
            Cell: (cell) => {
             let rowcomponent = <div onClick={() => cell.row.original.conversationDuration > 12 && checkToAudit(cell.row.original)} className={styles['arrow']}><FontAwesomeIcon icon={faCaretRight} /></div>
             return cell.row.original.conversationDuration > 12 ? rowcomponent : disabledRow(rowcomponent)
            },
            width: "2%",
            disableSortBy: true
        },
    ]

    const checkToAudit = async (rowData) => {
        const { teleproject, user } = props.location.state.callerDetails
        try {
            const result = await ApiActions.getApplicantList({teleproject,user})
            if (result && result[0].status !== 'iqchold') {
                toast('The caller is not in the IQC hold stage')
            } else {
                props.history.push({
                    pathname: `/audit/v2/iqc/qmf-form/${user}`,
                    state: {
                        callerDetails: props.location.state.callerDetails,
                        callDetails: rowData,
                        activeTab: props.location.state.activeTab,
                        page: props.location.state.page,
                        callPage: currentPage,
                        query: searchBy,
                        outcomeQuery: selectOutcome
                    }
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getRowData = async (rowData, tabName) => {
        if (selectedRowData) {
            setActiveTab(popupTabs.find(pop => pop.value == tabName))
            setShowCallInfo(true)
        }
        else {
            //get call history
            try {
                let result = await ApiActions.getTeletaskCallHistory({
                    teletaskHistory: rowData && rowData.teletaskhistory
                })
                rowData['callHistory'] = result
                setSelectedRowData(rowData)
            } catch (err) {
                console.error((err.response && err.response.data) || "Unexpected Error!")
            } finally {
                setSelectedRowData(rowData)
                setActiveTab(popupTabs.find(pop => pop.value == tabName))
                setShowCallInfo(true)
            }
        }
    }

    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if (sortArray !== '')
            setSort(sortArray)
        if (sortArray === '' && sort.length == 1)
            setSort([])
    }

    const renderCallInfo = () => {
        const setActiveTabFn = (activeTab, activeRow) => {
            setActiveTab({
                label: activeTab.label,
                value: activeTab.value
            })
        }

        const showDC = () => {
            let dataCollection = selectedRowData && ((selectedRowData.outcome && selectedRowData.outcome.responses) || selectedRowData.responses)
                ? selectedRowData.responses
                    ? selectedRowData.responses
                    : selectedRowData.outcome && selectedRowData.outcome.responses
                : false;
            return dataCollection && dataCollection.length > 0
                ? <div className='data-collection-question'>
                    {
                        dataCollection.map((item, index) => {
                            return (
                                <div key={index} className="question-div">
                                    <p className="question" style={{ fontWeight: "bold" }}>{`Q.${index + 1} ${item.question}`}</p>
                                    <p className="answer">Ans: {item.answer}</p>
                                </div>
                            )
                        })
                    }
                </div>
                : <span>No Data collection present</span>
        }

        const showCustInfo = () => {
            return <CustomerInfo info={selectedRowData} />
        }

        const showCallHistory = () => {
            return selectedRowData && selectedRowData.callHistory &&
                selectedRowData.callHistory.length > 0 ?
                <div className={styles["audio-wrap"]}>
                    {
                        selectedRowData.callHistory.map((ai, i) => {
                            return (
                                <div className={styles['call-attempted']}>
                                    <div className={styles['details']}>
                                        <span>Attempt {ai.attempts}: {
                                            ai.outcome && ai.outcome.title ? ai.outcome.title : ai.callstatus
                                        }</span>
                                        {
                                            ai.recordingUrl ? <audio controls src={ai.recordingUrl}></audio> : null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> : <h6>No audio files</h6>
        }

        return (
            <Popup
                show={showCallInfo}
                closePopup={() => {
                    setSelectedRowData('')
                    setActiveTab({
                        label: popupTabs[0].label,
                        value: popupTabs[0].value
                    })
                    setShowCallInfo(false)
                }}
                size='sm'
                heading={<CustomTab tabs={popupTabs} setActiveTabFn={(data) => setActiveTabFn(data, selectedRowData)}
                currentTab = {activeTab}
                className={'navy-blue'} />}
            >
                <div className={styles['call-detail-wrapper']}>
                <div className={styles['call-audio-info']}>
                    <div className={styles.audio_copy_link}>
                        <audio controls src={selectedRowData.recordingUrl} />
                        <CustomTooltip placement={'bottom'} description={'Copy audio link'}
                            component={
                                <img title="Copy link" className='mock-record-url' src={AttachmentIcon}
                                    onClick={() => copyToClipboard(selectedRowData.recordingUrl)}
                                    alt='copy audio link' />
                            }
                        />
                    </div>
                    <div className={styles['info']}>Outcome: {selectedRowData.outcome && selectedRowData.outcome.title} | Call Date: {<span>{moment(selectedRowData.callDate).format("DD/MM/YYYY")} at {moment(selectedRowData.callDate).format('HH:mm')}</span> || 0} | Disconnected by: {selectedRowData.disconnectedBy}</div>
                </div>
                <div className={styles['horizontal-divider']}></div>
                {
                    selectedRowData ? <div>
                        {activeTab.value == 'data_collection' ? showDC() : null}
                        {activeTab.value == 'customer_info' ? showCustInfo() : null}
                        {activeTab.value == 'call_history' ? showCallHistory() : null}
                    </div> : <ThreeDotLoader />
                }
                </div>
            </Popup>
        )
    }

    const renderQCInsights = () => {
        return(
            <Popup
            show={qcinsightsModal.show}
            closePopup={()=>{
                setqcinsightsModal({show: false, data: ''})
            }}
            size='lg'
            heading={'QC Insights'}
             >
            <QCInsights details={qcinsightsModal.data} />
            </Popup>
        )
    }

    const handlePopups = () => {
        if (showCallInfo) return renderCallInfo()
        if(qcinsightsModal.show) return renderQCInsights()
    }

    let { data, total, auditInfo } = getIqcFlaggedCallsApi.data || {}
    const { loading, error } = getIqcFlaggedCallsApi

    useEffect(() => {
        if (error)
            toast(handleErrorMessage(error))
    }, [error])


    const projectDetails = async(teleprojectId) => {
        try {
            let data = await getTeleProject(teleprojectId)
            setProjectData(data && data.outcomes.map(i => { return { label: i.title, value: i.title } }))
           } catch (error) {
               console.error(error)
           }
    }
    const defineoutcomes = () => {
        let dynamic = props.location.state.callerDetails.outcomes ? props.location.state.callerDetails.outcomes.map(i => {
            return { label: i.title, value: i.title }
        }) : projectData
        let result = [
            { label: 'All outcomes', value: 'All outcomes' },
            ... dynamic ? dynamic : []
        ]
        return result
    }

    return (
        <div className={styles.page_wrapper}>
            <Heading text={
                <ProjectTitle title={
                    <ul className={styles.breadcrumbs}>
                        <li>IQC</li>
                        <li>{projectName || 'NA'}</li>
                        <li>{name || 'NA'}</li>
                    </ul>
                }
                    goBack={() => {
                        props.history.push({
                            pathname: '/audit/v2',
                            state:{
                                activeTab: props.location.state.activeTab,
                                page: props.location.state.page,
                                query: searchBy
                            }
                        })
                    }} />
            } />

            <div className={styles.qmf_row}>
                <div className={styles.audit}>
                    <div className={styles.audit_info}>{auditInfo && auditInfo.auditCount || 0} / {auditInfo && auditInfo.maxAuditCount || 0}</div>
                    <span className={styles.text_muted}>audits done</span>
                </div>
                <div className={styles.right_end}>
                    <Dropdown
                        options={defineoutcomes()}
                        onChange={(e) => {
                            setCurrentPage(1)
                            setSelectOutcome(e.value == 'All outcomes' ? '' : e.value)
                        }}
                        value={selectOutcome == '' ? 'All outcomes' : selectOutcome}
                        placeholder="Select an outcome" />

                    <Datepicker
                        placeholderText="Click to select a date"
                        minDate={moment(prevIqcActionTakenDate).toDate()}
                        selectedDate={fromDate === '' ? moment().toDate() : fromDate}
                        onChange={date => {
                            if (prevIqcActionTakenDate && moment(date).isSameOrAfter(moment(prevIqcActionTakenDate))) {
                                setFromDate(date)
                            } else {
                                setFromDate(prevIqcActionTakenDate ?
                                    moment(prevIqcActionTakenDate).toDate() : date)
                            }
                        }}
                    />
                </div>
            </div>


            <div>
                <Table
                    columns={columns}
                    data={data}
                    totalCount={total}
                    limit={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    loading={loading}
                    sortChange={sortChange}
                    filterChange={filterChange}
                    searchBy='project title or mobile'
                    isGlobalFilter={false}
                />
            </div>
            <ToastContainer draggable={true} />
            {handlePopups()}
        </div>
    )
}

export default CallsListOfCaller