import React from 'react';

import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import jwt_decode from "jwt-decode";
import constants from '../../util/constants';

class Header extends React.Component {


    constructor(props) {

        super(props)
        this.state = {
            activeItem: "",
            activeFrappItem: "",
            route: "",
            userName: "",
            allowedTabs: [],
            allowedSubTabs: [],
            path:''
        }
        this.role = jwt_decode(localStorage.authToken).auth
    }

    checkIfAccessGranted = (tab, acceptedRole = '') => {
        const role = jwt_decode(localStorage.authToken).auth
        const storedRoles = constants.roles.map(({value}) => value)
        return role == 'admin' ? tab : null
    }

    componentDidMount() {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var c = url.pathname
        var d=c.split('/')[1];
        this.setState({path: d})
        this.setState({activeFrappItem: '', activeItem: ''})
        if(d==="tring"||d==="caller"||d==="dashboard"){
            this.setState({activeItem:d})
        }
        if(d==="database"||d==="wallet"){
            this.setState({activeFrappItem: d})
        }
        // Get Tabs
        this.setState({
            allowedTabs: localStorage.accessTabs ? JSON.parse(localStorage.accessTabs).tabs : null,
            allowedSubTabs: localStorage.accessSubTabs ? JSON.parse(localStorage.accessSubTabs).tabs : null
        })
        this.setState({
            userName: localStorage.userName 
            ? localStorage.userName
            : null
        })
    }
    render() {
        let activeItem = this.state.activeItem;
        let userName = this.state.userName;
        var url_string = window.location.href;
        var url = new URL(url_string);
        var c = url.pathname
        var d=c.split('/');
        let options=[
           { label: "Tring Dashboard ",value:"dashboard"},
           { label: "Project Management ",value:"tring"}     
        ]
        let defaultOption = options[0];
        if(d[1]==="interactive-script"&&d[2]!==undefined)
        return null

        return (
            <Navbar fluid className="header" fixedTop >
               <Nav>        
                   {
                    this.props.routes && this.props.routes.map(({title, children, path, isMenu = true}) => {
                        return children && isMenu ?
                        <NavItem>
                        <Dropdown options={children.map(item => {
                            return {label: item.title, value: item.path}
                        })}
                        onChange={(e)=>{
                            if(path){
                            this.setState({ activeItem: e, route: e.value })
                            window.location.href = e.value
                            }else{
                                if(e.label == 'Logout') this.logout()
                            }
                        }} 
                        value={title}
                        placeholder={title}
                        />
                        </NavItem>
                        :
                        title && <LinkContainer exact to={path} >
                        <NavItem>{title}</NavItem>
                        </LinkContainer> 
                    })
                   }
               </Nav> 
            </Navbar>
        )
    }

    logout = () => {
        localStorage.clear();
        window.location.href = '/'
    }

    _onSelect = (e) => {
         this.setState({ activeItem: e, route: e.value })
         window.location.href = `/${e.value}`
    }

    onFrappMenuSelect = (e) => {
        if(e.value == 'apify'){
            this.setState({ activeFrappItem: e, route: e.value })
            let newWnd = window.open('http://164.52.194.96:2019/', '_blank')
            newWnd.opener = null
        }
        this.setState({ activeFrappItem: e, route: e.value })
        window.location.href = `/${e.value}`
    }
}

export default React.memo(Header);