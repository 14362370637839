import React from 'react';

class Loader extends React.Component{
    render(){
        return (
            <div className={`loader-main ${this.props.marginTop ? "margin-top" : ""} `}>
                <img src={require("../../assets/images/loadernew.gif")} alt=""/>
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
        )
    }
}

export default Loader;