import React, { Component } from 'react'
import { HandWaving } from '../../assets/images'


class Welcome extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username : ''
        }
    }

    componentWillMount(){
        this.setState({
            username: localStorage && localStorage.getItem("userName") ? localStorage.getItem("userName")
            .replace(/\b\w/g, function(match) {
                return match.toUpperCase();
            }) 
            : 'User'
        })
    }

    render() {
        return (
            <div className='welcome-page'>
                <img src={HandWaving}/>
                <div className='welcome-message'>Hi <span style={{color:"#159B72"}}>{this.state.username}</span>,<br/>
                Welcome back</div>
            </div>
        )
    }
}
export default Welcome