import React from 'react'
import styles from './tag.module.scss'

const TAG_COLORS = [
    'green-tab-outline',
    'purple-tab-outline',
    'orange-tab-outline',
    'green-tab-solid',
    'purple-tab-solid',
    'orange-tab-solid',
]


const Tag = (props) => {
    let {color, content, onClick, noShadow = false} = props || {}
    const checkStyle = TAG_COLORS.includes(color) ? color : TAG_COLORS[0]

    return(
        <div className={[styles.tag_wrapper, styles[checkStyle], noShadow ? styles['no_shadow'] : ''].join(' ')} onClick={onClick}>
               {content}
        </div>
    )
}

export default Tag