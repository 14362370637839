import React, { Component } from 'react';
import ApiActions from '../../actions/ApiActions';
import MaterialTable, { MTablePagination } from "material-table";
// import cloneDeep from "lodash/cloneDeep";
import FrappButton from '../utilities/FrappButton';
import FrappModal from "../utilities/FrappModal";
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import Checkbox from 'react-bootstrap/lib/Checkbox';
import CheckboxMat from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-tabs/style/react-tabs.css';
import ProjectTitle from '../common/ProjectTitle'
import CustomerInfo from '../utilities/CustomerInfo'
import { Fragment } from 'react';
import CustomTooltip from '../utilities/ToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import CallDisconnectedBy from "../../helper/CallDisconnectedBy";
import CustomTab from '../utilities/CustomTab';
import OutcomeInsights from '../common/OutcomeInsights'
import moment from "moment";
import Capitalise from '../../helper/Capitalise'
import Table from '../../uicomponents/table/Table';
import ThreeDotLoader from '../utilities/ThreeDotLoader';
export default class CallerAudit extends Component{
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        this.state={
            skip:0,
            isLoading:true,
            outcomes:[],
            auditAction:"",
            allOutcomes:false,
            isLoadingPopup:true,
            reason:"Cleared in QC",
            coachFeedbacks: '',
            // outcomeLookup: {},
            checkboxFeedbackData: [],
            checkboxQCPassReasons: [],
            checkBanReasons: [],
            checkRetrainReasons: [],
            callerauditlist: [],
            fetchAllReasonsData: [],
            checkboxCallerAuditData: [],
            teletaskCallHistory: [],
            tabValue: 0,

            itemx: "",
            allOutcomesArray: [],
            audioInspectionsUI: [],
            inspectionCurrentPage: 1,
            brand: {},
            visitedCalls: [],
            activeTab: {
                label: this.tabsForStage[0].label,
                value: this.tabsForStage[0].value
            },
            outcomeInsights: [],
            qcHistory: [],
            tableSorting: '',
            qcFeedback: [],
            mht: 0,
            currentPage: 1
        }
    }
    
     componentDidMount() {
        this._isMounted = true;
         let {skip}=this.state;
        if (this._isMounted) {
            if (this.props.match.params.id) {
                ApiActions.getCallerAuditList(this.props.match.params.id).then(resp => {
                     ApiActions.getTeleProject(this.props.match.params.id).then(resp2 => {
                    this.setState({ teleproject: resp2})
                     skip=skip+50;
                    this.setState({ callerauditlist: resp ,isLoading:false})
                    this.setState({
                        allOutcomesArray: resp2.outcomes.map((item) => {
                            return item.title
                        })
                    })
                    const brandId = resp2.brand
                    ApiActions.getBrandName(brandId).then(resp => {
                        const {name, asseturl} = resp[0];
                        this.setState({brand: {name, asseturl}})
                    })
                })
                // ApiActions.getBrandName(resp2.brand)
                this.fetchReasons()
                })
              
            }
            this.setState(this.props.history.location.state);
        }
    }
 
    tabsForStage = [
        {
            label: 'Caller QC',
            value: 'caller_qc'
        },
        {
            label: 'Caller QC History',
            value: 'caller_qc_history'
        },
        {
            label: 'Caller QC Feedback',
            value: 'caller_qc_feedback'
        },
        {
            label: 'Outcome Insights',
            value: 'outcome_insights'
        }
    ]
    handleTableSorting = (order, direction) => { 
        this.setState({tableSorting: direction})
    }
    fetchApiCallsBasedOnStage = (id, activeRow, activeTab) => {
        if(activeTab.value == 'outcome_insights'){
            this.setState({isLoadingPopup: true})
            const data = {
                teleproject: id,
                user: activeRow.id
            }
            ApiActions.getCallOutcomeInsights(data).then(resp => {
                this.setState({outcomeInsights: resp, isLoadingPopup: false})
            }).catch(err => {
                console.error(err)
            })
        }else if(activeTab.value == 'caller_qc'){
            this.setState({audioInspectionsUI: []})
            this.setState({isLoadingPopup: true})
            this.setState({auditAction: ''})
            this.setState({allOutcomes:false})
            this.setState({itemx: ""})
            if(activeRow.autoHoldCounts==0&&activeRow.suspectCounts==0){
                this.setState({rules:false})
                this.fetchUnflaggedCalls(activeRow.id)
            }else{
                this.fetchRules(activeRow)
            }
        }else if(activeTab.value == 'caller_qc_history'){
            this.setState({isLoadingPopup: true})
            ApiActions.getQCHistory(id, activeRow.id).then(resp => {
                this.setState({qcHistory: resp, isLoadingPopup: false})
            }).catch(err => console.error(err))
        }else if(activeTab.value == 'caller_qc_feedback') {
            this.setState({isLoadingPopup: true})
            ApiActions.getApplicantList(
                {
                    teleproject:id,
                    user:activeRow.id
                }).then(resp => {
                this.setState({qcFeedback: resp, isLoadingPopup: false})
            }).catch(err => console.error(err))
        }
    }
    fetchReasons = () => {
        ApiActions.getQAFeedbackReason()
            .then(response => {
                Object.keys(response).map(function(key, index) {
                    this.setState({
                        fetchReasons: response[key].map((item)=>{
                            return { "label":item, "checked":false }
                        })
                    })
                });
                this.setState({
                    checkboxFeedbackData : response.callFeedbacks.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkboxQCPassReasons: response.qcPassReasons && response.qcPassReasons.map((item) => {
                        return {"label": item, "checked": false}
                    })
                })
                this.setState({
                    checkBanReasons: response.banReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkRetrainReasons: response.retrainReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
            })
    }
      render() {
        let {callerauditlist, mht, teleproject}=this.state;
        let {isLoading, brand}=this.state
        const goBack = () => {
            this.props.history.push(`/audit/qa-compliance`)
            // this.props.history.push(`/audit/qmf/audit-project/${this.props.match.params.id}`,
            // {teleprojectAudit: this.props.location.state.teleprojectAudit})
        }

        return (
            <div className="call-audits">
                {isLoading ?
                <div className="loader-wrapper"><ThreeDotLoader/></div> :
                <>
                {teleproject ? <ProjectTitle title={teleproject.title} goBack={goBack}/> : null}
                <div className='_header'>
                {brand ? (<div className='brand_details'>
                <img src={brand.asseturl} className='brand_logo'/>
                <h2 className='brand_name'>{brand.name}</h2>
                </div>) : null}
                <div className="mht_caller_audit">Min. Handling Time for winning outcome: &nbsp;
                {
                    Object.keys(mht).map(obj=>{
                        return(
                           <div className="data"> {obj}:&nbsp;{mht[obj]}</div>
                        )
                    })
                }
                
                </div>
                </div>
                {callerauditlist?this.renderCallerAuditTable():null}
                {/* {this.renderCallerAuditPopup()} */}
                {this.renderCallerAuditPopup1()}
                 <ToastContainer draggable={true} />
                 </>
                }
            </div>
        )
        
        // <div className="loader-main" id="loader-size">  
        //     <img src={require("../../assets/images/loadernew.gif")}/>
        //         <div className="text">
        //             We'll be right back. Here: listen to some awesome songs meanwhile 
        //             <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
        //         </div>
        //     </div>
        
    }

    renderCallerAuditPopup1 = () => {
        let {activeRow,rules,allOutcomesArray,itemx,teleproject,audioInspectionsUI,audioInspections,auditAction,reason,isLoadingPopup}=this.state;
        let options = [
            { label: "QC Pass",value:"qcpass"},
            { label: "Retrain",value:"training"}
        ]

        let outcomesOptions = allOutcomesArray.map(outcome => {
            return {label: outcome, value: outcome}
        })

        outcomesOptions = [...outcomesOptions, {label: 'All outcomes', value: ""}]


       

        const renderCallHistoryAudio = (audioRowData) => {
            ApiActions.getTeletaskCallHistory({
                teletaskHistory: audioRowData._id
            }).then((response) => {
                this.setState({
                    teletaskCallHistory: response
                })
            })            
        }
        const callPlayed = (data) => {
            let isThresholdObtained = false;
            let temp = [...this.state.visitedCalls, data._id]
            let audio = document.getElementById(`track-${data._id}`);
            const minThresholdTine = () => {
                let currentTimeMs = audio.currentTime*1000;
                if(currentTimeMs > 3000){
                isThresholdObtained = true
                audio.removeEventListener('timeupdate',minThresholdTine);
                if(isThresholdObtained){
                    // <---solution to use if we dont want to persist the visited calls--->
                    // let dupArray = [...this.state.audioInspectionsUI]
                    // let changeVisitKeyValue = dupArray.map(obj=>{
                    //     if(obj._id === data._id)
                    //     return {
                    //         ...obj,
                    //         visited: true,
                    //     }
                    //     return obj
                    // })
                    // this.setState({audioInspectionsUI: changeVisitKeyValue})
                    // <---end of solution --->
                    this.setState({visitedCalls: [...new Set(temp)]})
                    isThresholdObtained = false
                }
                }
            }
            audio.addEventListener('timeupdate',minThresholdTine,false);
        }

        const openExtraPanel = (item, tabName) => {
            this.setState({
                audioInspectionsUI: this.state.audioInspectionsUI.map(obj => {
                    if(obj._id == item._id) {
                        if(obj.expand && obj.expand.open){
                            if(obj.expand.name == tabName){
                                delete obj.expand
                                return obj
                            }else{
                                obj = {...obj, expand: {name: tabName, open: true}}
                                return obj
                            }
                        }else{
                            obj = {...obj, expand: {name: tabName, open: true}}
                            return obj
                        }
                    }else{
                        if(obj.expand)
                        delete obj.expand
                        return obj
                    }
                })
            })
        }

        const handleChangeCheckboxFeedback = (event) => {
            this.setState({
                checkboxFeedbackData: this.state.checkboxFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
            })
        };

        const enterPress = (event) => {     
            if(event.key === "Enter") {
                if(event.target.id == 'feedback-action'){
                    this.setState({
                        checkboxCallerAuditData: [...this.state.checkboxCallerAuditData, {label: event.target.value, checked: true} ]
                    })
                    event.target.value = "";
                }else{
                // 
                this.setState({
                    checkboxFeedbackData : [...this.state.checkboxFeedbackData, {label: event.target.value, checked: true}]
                })
                // this.refs.customInput.value = '';
                event.target.value = "";
                }
            }
        }

        const renderCheckboxCallerAction = (checked, label) => {
            return (
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeCheckboxCallerAction}
                        name={label}
                        color="primary"
                    />
                    }
                    label={<span style={{ fontSize: '13px' }}>{label}</span>}
                />
            )
        }

        const renderCheckboxFeedback = (checked, label, id) => {
            return (
                <FormControlLabel
                    key={id}
                    control={
                    <CheckboxMat
                        checked={checked}
                        onChange={handleChangeCheckboxFeedback}
                        name={label}
                        color="primary"
                    />
                    }
                    label={<span style={{ fontSize: '13px', marginBottom: '0px !important' }}>{label}</span>}
                />
            )
        }

        const handleChangeCheckboxCallerAction = (event) => {
            this.setState({
                checkboxCallerAuditData : this.state.checkboxCallerAuditData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
            })
        }

        const renderPanelContents = (rowData) => {
            let dataCollection = rowData && ((rowData.outcome && rowData.outcome.responses) || rowData.responses)
                    ?   rowData.responses
                        ?   rowData.responses
                        :   rowData.outcome && rowData.outcome.responses
                    :   false;
            if(rowData.expand.name == 'Call feedback'){
                return <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div className="call-audit-checkbox">
                {this.state.checkboxFeedbackData.map((item, id) => {
                return renderCheckboxFeedback(item.checked, item.label, id);
                })}
                </div>
                <div className="call-audit-actions">
                <input type="text" placeholder="Enter Custom Input & Press Enter" className="custom-input" onKeyDown={enterPress} />
                <FrappButton className="submit" id="bulk-status-btn-aqua-blue" handler={() => this.saveCallFeedback(rowData)} ref={(c) => this.saveBtn = c}>
                Save
                </FrappButton>
                </div>
                </div>
            }else if(rowData.expand.name == 'Call history') {
                return this.state.teletaskCallHistory.length > 0 ? 
                        <div className="audio-wrap">
                        {
                            this.state.teletaskCallHistory.map((ai,i)=>{
                            return(
                                    <div className='call-attempted'>
                                        <div className='details'>
                                            <span>Attempt {ai.attempts}: {
                                                ai.outcome && ai.outcome.title ? ai.outcome.title : ai.callstatus
                                        }</span>
                                        {
                                            ai.recordingUrl ? <audio controls src={ai.recordingUrl}></audio> : null
                                        }
                                        </div>
                                </div>
                            )
                        })
                    }
                    </div> :  <h6>no audio files</h6> 
            }else if(rowData.expand.name == 'Data Collection'){
                return dataCollection && dataCollection.length > 0
                    ?    <div className='data-collection-question'>
                            {
                                dataCollection.map((item,index)=>{
                                return (
                                    <div key={index} className="question-div">
                                        <p className="question" style={{fontWeight: "bold"}}>{`Q.${index+1} ${item.question}`}</p>
                                        <p className="answer">Ans: {item.answer}</p>
                                    </div>
                                )
                            })
                            } 
                        </div>
                    : <span>No Data collection present</span>
            }else {
                return <CustomerInfo info={rowData}/>
            }
        }

        const handleChangex = (event) => {
            if(event.value === ""){
                // this.setState({itemx: ""})
                this.setState({inspectionCurrentPage: 1})
                this.setState({
                    audioInspectionsUI: audioInspections
                })
            }else{
                // this.setState({itemx: event.target.value})
                this.setState({inspectionCurrentPage: 1})
                let temp = audioInspections.filter((item)=>{
                    return item.outcomeTitle === event.value
                })
                this.setState({
                    audioInspectionsUI: temp
                })
            }
        }

        const setActiveTabFn = (data, activeRow) => {
            this.setState({ activeTab: { label: data.label, value: data.value }})
            this.fetchApiCallsBasedOnStage(this.props.match.params.id, activeRow, data)
        }

        const callerQC = () => {
            const indexOfLastPost = this.state.inspectionCurrentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = this.state.audioInspectionsUI ? 
            this.state.audioInspectionsUI.slice(indexOfFirstPost, indexOfLastPost) : [];
            return <div className='caller_qc'>
            {rules? <div className="rules"> 
                
                    <div className="ai-table">
                            <div className="heading-table">
                            <div className="head">Rules</div>
                          <div className="head">  Remark</div>
                           <div className="head"> User Avg.</div>
                          <div className="head">  Sys Avg.</div>
                             </div>
                             <div className="data-wrap">
                                 {
                                    Object.keys(rules).map(rule=>{
                                        let ruleName="";
                                        if(rule==="rule2B")
                                        ruleName="Winning outcome performance";
                                        else if(rule==="rule3B")
                                        ruleName="Non winning outcome performance"
                                        else if(rule==="rule4B")
                                        ruleName=" Avg handling time of outcomes"
                                        return(
                                         <div>
                                            {
                                                
                                                rules[rule]&&rules[rule].map(r1=>{
                                                   return (
                                                     <div className="each-row">
                                                         <div className="cell">{ruleName}</div>
                                                         <div className="cell"><div className="title">{r1.outcome}</div>&nbsp;-&nbsp;{r1.userAvg>r1.sysAvg?"High":"Low"}</div>
                                                        <div className="cell">{r1.userAvg&&r1.userAvg.toFixed(2)} {rule==="rule2B"||rule==="rule3B"?"%":"secs"}</div>
                                                        <div className="cell">{r1.sysAvg&&r1.sysAvg.toFixed(2)} {rule==="rule2B"||rule==="rule3B"?"%":"secs"} </div>
                                                     </div>
                                                   )
                                                   
                                                  
                                                })
                                            }
                                        </div>
                                        )
                                    })
                                }
                             </div>

                    </div>
                    
                    </div>:
                    null}
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 1% 0 0'}}>
                    <div className="heading">Audio (inspections)</div>
                    <Dropdown options={outcomesOptions} 
                        onChange={(val)=>handleChangex(val)} 
                        value={'All outcomes'} 
                        placeholder="Select an option" />
                    </div>
                    {/* <div style={{display: 'flex', justifyContent: 'flex-end', padding: '1% 1% 0 0'}}>
                        <Dropdown options={outcomesOptions} 
                        onChange={(val)=>handleChangex(val)} 
                        value={'All outcomes'} 
                        placeholder="Select an option" />
                    </div> */}
                    {!isLoadingPopup ? <div className='new-outcomes-wrapper'>
                        {audioInspectionsUI && audioInspectionsUI.length > 0 ?
                             <div className='custom-audio-inspection-table'>
                                <div className='table-heading'>
                                <div className='first'>Outcome</div>
                                <div className='second'>Audio</div>
                                <div className='third'>Disconnected by</div>
                                <div className='fourth'></div>
                                </div>
                                {
                                    currentPosts.map((item, index) => {
                                        const dataCollectionComponent = <span className={item.expand && item.expand.open && item.expand.name == 'Data Collection' ? 'active' : 'inactive'} onClick={() => openExtraPanel(item, 'Data Collection')}><img style={{width: '20px'}} src={require('../../assets/images/data-collection.svg')}/></span>
                                        const callHistoryComponent = <span className={item.expand && item.expand.open && item.expand.name == 'Call history' ? 'active' : 'inactive'} onClick={() => {
                                            renderCallHistoryAudio(item)
                                            openExtraPanel(item, 'Call history')
                                            }}><img style={{width: '20px'}} src={require('../../assets/images/Icons_history.svg')}/></span>
                                        const callFeedbackComponent = <span className={item.expand && item.expand.open && item.expand.name == 'Call feedback' ? 'active' : 'inactive'} onClick={() => openExtraPanel(item, 'Call feedback')}><img style={{width: '20px'}} src={require('../../assets/images/Icons_call_feedback.svg')}/></span>
                                        const customerInfoComponent = <span className={item.expand && item.expand.open && item.expand.name == 'Customer info' ? 'active' : 'inactive'} onClick={() => openExtraPanel(item, 'Customer info')}><img style={{width: '18px'}} src={require('../../assets/images/userblue.svg')}/></span>

                                        return <Fragment>
                                            <div className={`table-row ${this.state.visitedCalls.some(call => call == item._id) ? 'visited' : ''}`}>
                                            <div className='first'>{item.outcomeTitle}</div>
                                            <div className='second'>
                                            <audio id={`track-${item._id}`} className={this.state.visitedCalls.some(call => call === item._id) ? 'audio_control_visited': ''} onPlay={()=> callPlayed(item)} controls src={item.recordingUrl}/>
                                            </div>
                                            <div className='third'>{item && item.disconnectedBy ? CallDisconnectedBy(item.disconnectedBy) : CallDisconnectedBy('')}</div>
                                            <div className='fourth'>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <CustomTooltip placement={'bottom'} description={'Data Collection'} component={dataCollectionComponent}></CustomTooltip>
                                                    <CustomTooltip placement={'bottom'} description={'Call history'} component={callHistoryComponent}></CustomTooltip>
                                                    <CustomTooltip placement={'bottom'} description={'Call feedback'} component={callFeedbackComponent}></CustomTooltip>
                                                    <CustomTooltip placement={'bottom'} description={'Customer info'} component={customerInfoComponent}></CustomTooltip>
                                                </div>
                                            </div>
                                            </div>
                                            {item.expand && item.expand.open ? <div className='table-row row-detail-panel'>
                                            {renderPanelContents(item)}
                                            </div> : null}
                                        </Fragment>
                                    })
                                }
                        </div> : <p className='text-center'>No audio present at the moment</p>}
                    </div> : <div className="loader-main" id="loader-size">  
                    <img src={require("../../assets/images/loadernew.gif")}/>
                    <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                    </div>
                    </div>}
                    {
                        Math.ceil(audioInspectionsUI.length / 10) > 1 ?
                        (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline', padding: '0.8em 0'}}>
                            <button
                            onClick={() => this.setState({inspectionCurrentPage: this.state.inspectionCurrentPage - 1})}
                            disabled={this.state.inspectionCurrentPage === 1 ? true : false}
                            // className={styles.pagArrowBtn}
                            style={{padding: '0 1%'}}
                            >
                            <FontAwesomeIcon icon={faAngleLeft}
                            style={{fontSize: '15px'}} 
                            // className={styles.angleIcons}
                            />
                            </button>
                            {<div 
                            // className={styles.pageNum}
                            >Page {this.state.inspectionCurrentPage} of {Math.ceil(audioInspectionsUI.length / 10)}</div>}
                            <button
                            onClick={() => this.setState({inspectionCurrentPage: this.state.inspectionCurrentPage + 1})}
                            disabled={Math.ceil(audioInspectionsUI.length / 10) === this.state.inspectionCurrentPage ? true : false}
                            // className={styles.pagArrowBtn}
                            style={{padding: '0 1%'}}
                            >
                            <FontAwesomeIcon icon={faAngleRight} 
                            // className={styles.angleIcons}
                            style={{fontSize: '15px'}} 
                            />
                            </button>
                            </div>
                        )
                        : null
                    }
                    <div className='actions-wrapper'>
                    <div className='first'>
                        <div className="heading">Caller Actions</div>
                        <div className='dropdown-actions'>
                                     <Dropdown options={options} 
                                    onChange={(val)=>this.selectValue(val)} 
                                    value={auditAction} 
                                    placeholder="Select an option" />
                        </div>  
                    </div>
                    <div className="divider"></div>
                    <div className='second'>
                    <div className="actions"> 
                                <p>Feedbacks</p>
                                {this.state.auditAction==="ban"? 
                                <div className="caller-action-checkbox" >
                                    {this.state.checkboxCallerAuditData.map((item) => {
                                    return renderCheckboxCallerAction(item.checked, item.label)
                                    })
                                    }
                                </div> : this.state.auditAction==="training" ? <div className="caller-action-checkbox" >
                                    {this.state.checkboxCallerAuditData.map((item) => {
                                    return renderCheckboxCallerAction(item.checked, item.label);
                                    })}
                                <input type="text" id='feedback-action' placeholder="Enter Custom Feedbacks & Press Enter" className="custom-input" onKeyDown={enterPress} />
                                </div> : this.state.auditAction==="qcpass" ? <div className="caller-action-checkbox" >
                                    {this.state.checkboxCallerAuditData && this.state.checkboxCallerAuditData.map((item) => {
                                    return renderCheckboxCallerAction(item.checked, item.label);
                                    })}
                                <input type="text" id='feedback-action' placeholder="Enter Custom Feedbacks & Press Enter" className="custom-input" onKeyDown={enterPress} />
                                </div> : null}
                                {/* check if the selected audit action is 'ban' or something else */}
                    </div>
                    </div>
                    </div>
                   {this.state.auditAction==="training" ? <div className='coach-feedback'>
                       <div className='first'>
                        <div className="heading">Feedback for coaches</div>
                       </div>
                       <div className='second'>
                       <textarea id="coachfeddback" name="w3review" rows="4" cols="50" onChange={(e)=> this.setState({coachFeedbacks: e.target.value})}/>
                       </div>
                   </div> : null}
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {this.state.auditAction !== "ban" ?
                                  (<FrappButton 
                                    className="submit" 
                                    id="bulk-status-btn" 
                                    ref={(c) => this.submitBtn = c}
                                    handler={()=>this.updateAction()}
                                >
                                   Submit
                                </FrappButton>) : 
                                (<FrappButton 
                                    className="submit" 
                                    id="bulk-status-btn" 
                                    ref={(c) => this.submitBtn = c}
                                    handler={()=>this.updateAction()}
                                    //check if atleast one of the reason is selected to ban a caller
                                    disabled={this.state.checkboxCallerAuditData.some(item => item.checked) ? false : true}
                                >
                                   Submit
                                </FrappButton>)
                    }
                    </div>
         </div>
        }

        const callerQCHistory = () => {
            const {qcHistory} = this.state

            const actionColor = (action) => {
                switch (action) {
                    case 'qcpass':
                        return 'qc_pass'
                        break;
                    case 'training':
                        return 'qc_training'
                    case 'ban':
                        return 'qc_ban'
                }
            }

            const readableQCTypeTerms = (type) => {
                if(type == 'qaCheckpoint'){
                    return 'QA CHECKPOINT'
                }else if(type == 'dailyQC'){
                    return 'DAILY QC'
                }else{
                    return type.toUpperCase()
                }
            }

            return <div className='caller_qc_history'>
                <ul className="events qc_history">
                        {
                            qcHistory && qcHistory.length > 0 ?
                            qcHistory.map(timeline => {
                                return(
                                    <Fragment>
                                    <li>
                                    <time data-after-content={`${moment(timeline.createdAt).format("DD-MM-YYYY")}      [${moment(timeline.createdAt).format('LT')}]`}></time>
                                    <span>
                                        <div className='desc-qc-history'>
                                        <table>
                                        <tbody>
                                        <tr>
                                        <td>Action</td>
                                        <td>{timeline && timeline.action ? <div className={actionColor(timeline.action)}>{Capitalise(timeline.action)}</div> : 'NA'}</td>
                                        </tr>
                                        <tr>
                                        <td>Reason</td>
                                        <td>{timeline && timeline.reason ? timeline.reason : 'NA'}</td>
                                        </tr>
                                        <tr>
                                        <td>Type</td>
                                        <td>{timeline && timeline.type ? readableQCTypeTerms(timeline.type) : 'NA'}</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        </div>
                                    </span>
                                    </li>
                                    </Fragment>
                                )
                            }) :
                            <Fragment>
                                <div>No history found</div>
                            </Fragment>
                        }
                    </ul>
            </div>
        }

        const callerQCFeedback = () => {
            const { qcFeedback } = this.state
           
            return <div className='qc_feedback'>
           <ul style={{padding:'0', listStyle: 'inside'}}>
            {
                qcFeedback && qcFeedback.length > 0 ?
                qcFeedback.map(feedbacks => {
                    const {qcPassReasons, retrainReasons, qc:{iqc: {feedback: iqcFeedback}} = {iqc: {}}} = feedbacks
                    return ( 
                            <Fragment>
                                <div className='retrain-feedbacks' style={{padding:'2% 2px'}}>
                                    <div className='sub-heading' style={{marginBottom: '1%'}}>IQC Feedback</div>
                                        <div className='feedback-for-callers'>
                                        {
                                            iqcFeedback ? <li>{iqcFeedback}</li> : <p>No IQC Feedbacks</p>
                                        }
                                        </div>
                                        <div className='divider'></div>
                                        <div className='sub-heading' style={{marginBottom: '1%'}}>QC Pass Feedbacks</div>
                                        <div className='feedback-for-callers'>
                                        {
                                            qcPassReasons && qcPassReasons.length > 0 ?
                                            qcPassReasons.map(feedback => <li>{feedback}</li>) : 
                                            <p>No QC Pass Feedbacks</p>
                                        }
                                        </div>
                                        <div className='divider'></div>
                                        <div className='sub-heading' style={{marginBottom: '1%'}}>Retrain Feedbacks</div>
                                        <div className='feedback-for-callers'>
                                        {
                                            retrainReasons && retrainReasons.length > 0 ?
                                            retrainReasons.map(feedback => <li>{feedback}</li>) : 
                                            <p>No Retrain Feedbacks</p>
                                        }
                                        </div>   
                                </div>
                            </Fragment>
                        )
                }
                )
                : 
                <Fragment>
                    <div>No Feedbacks found</div>
                </Fragment>
            }
            </ul>
            </div>
        }

        const outcomeInsights = (activeRow) => {
            const data = {
                teleproject: this.props.match.params.id,
                user: activeRow.id
            }
           const { outcomeInsights, isLoadingPopup } = this.state
            if(!isLoadingPopup){
                return <div className='outcome_insight'>
                <OutcomeInsights outcomeInsights={outcomeInsights}/>
                </div>
            }else{
                return <div className="loader-main" id="loader-size">  
                    <img src={require("../../assets/images/loadernew.gif")}/>
                    <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile 
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank"> Take me</a>
                    </div>
                    </div>
            }

        }
        const renderContent = (activeTab, activeRow) => {
            switch (activeTab) {
                case 'caller_qc':
                    return callerQC()
                case 'caller_qc_history':
                    return callerQCHistory()
                case 'outcome_insights':
                    return outcomeInsights(activeRow)
                case 'caller_qc_feedback':
                    return callerQCFeedback()
                default:
                    break;
            }
        }

        return(
            <FrappModal
                onCloseButton={true}
                className="caller-audit-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.callerAuditModal = c)}
                closeButtonClicked={() => {
                    this.callerAuditModal.toggle(false)
                    this.setState({
                        activeTab: {
                            label: this.tabsForStage[0].label,
                            value: this.tabsForStage[0].value
                        }
                    })
                    this.setState({outcomeInsights: [], qcHistory: []})
                }}
            >
                <div className="caller-qc">
                <CustomTab tabs={this.tabsForStage} setActiveTabFn={(data) => setActiveTabFn(data, activeRow)} className={'navy-blue'}/>
                <div className="heading"> {activeRow && activeRow.id } &bull; {activeRow && activeRow.name } &bull; {activeRow && activeRow.mobile } </div>                    
                {renderContent(this.state.activeTab.value, activeRow)}
                </div>
            </FrappModal>
        )
    }

    fetchAllOutcomes=()=>{
    //     let {teleproject,activeRow,allOutcomes}=this.state;
    //     let reverse=!allOutcomes
    //     this.setState({allOutcomes:reverse})
    //  let dataToSend={
    //             "teleproject":teleproject.id,
    //             "user":activeRow.id,
    //             "outcomes":[]
    //         }
    //         if(reverse)
    //         ApiActions.fetchAudioInspections(dataToSend).then(resp=>{
    //             this.setState({audioInspections:resp})
    //         })
    //         else
    //         this.fetchOutcomes(activeRow)


        let {outcomes,teleproject, activeRow}=this.state;
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
            }
            var b= outcomes.filter(onlyUnique)
            let temp = {};
            b.forEach((prop,index)=>temp[prop] = prop);
            // let temp2= {};
            // teleproject.outcomes.forEach((props) => temp2[props.title] = props.title)
        // this.setState({outcomeLookup: temp2})
        this.setState({outcomes:b})
        let dataToSend={
            "teleproject":teleproject.id,
            "user":activeRow.id,
            "outcomes":[]
        }
        ApiActions.fetchAudioInspections(dataToSend).then(resp=>{
            // <---solution to use if we dont want to persist the visited calls--->
            // resp = resp.map(each => {
            //     return {
            //         ...each, visited: false
            //     }
            // })
            // <---end of solution --->
            this.setState({audioInspections:resp,isLoadingPopup:false})
            this.setState({audioInspectionsUI:resp,isLoadingPopup:false})
        })
    }
    updateAction=()=>{
        let {teleproject,auditAction,activeRow,reason,coachFeedbacks}=this.state;
        this.submitBtn.startLoading();
        // let arr=[];
        // arr.push(activeRow.id)
        let dataToSend={
            "teleproject":teleproject.id,
            "user":activeRow.id,
            "action":auditAction,
            "reason":reason,
            "actionBy":"agent" 
        }

        if(auditAction == "qcpass"){
            let qcPassReasons = this.state.checkboxCallerAuditData.map((item) => {
                if(item.checked === true) {
                    return item.label
                }
            }).filter(i => i)

            dataToSend = {
                ...dataToSend, 
                qcPassReasons: qcPassReasons
            } 
        }
        if(auditAction === "training") {

            let retrainReasons = this.state.checkboxCallerAuditData.map((item) => {
                if(item.checked === true) {
                    return item.label
                }
            }).filter(i => i)

            dataToSend = {
                ...dataToSend, 
                coachFeedbacks: coachFeedbacks,
                retrainReasons: retrainReasons
            } 
        } else if(auditAction === "ban") {
            dataToSend = {
                ...dataToSend, 
                banReasons: this.state.checkboxCallerAuditData.map((item) => {
                    if(item.checked === true) {
                        return item.label
                    }
                }).filter(i => i)
            }
        }        
        ApiActions.updateCallerStatus(dataToSend).then(resp=>{
            this.submitBtn.stopLoading();
            if(resp.status === 200){
                toast("Success")
                // if(auditAction === "training" && process.env.NODE_ENV === 'production') {
                //     // create ticket
                //     // debugger;
                //     ApiActions.createTicketFreshdesk({
                //         "description": `Re-training session for tele-caller scheduled.\
                //         Feedback from QA team: ${dataToSend.retrainReasons.join(", ")}`,
                //         "subject": teleproject.title,
                //         "email": activeRow.email ? activeRow.email : null,
                //         "phone": activeRow.mobile,
                //         "name": activeRow.name ? activeRow.name : activeRow.firstname + " " + activeRow.lastname,
                //         "priority": 1,
                //         "status": 2,
                //         "custom_fields": {
                //             "cf_brand_id": teleproject.brand,
                //             "cf_project_id": teleproject.id,
                //             "cf_user_id": activeRow.id
                //         },
                //         "type": "Coaching 1 on 1"
                //     })
                // } else if(process.env.NODE_ENV === 'development') {
                //     alert("Cant create freshdesk ticket on staging")
                // }
            }
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch((err)=>{
            if(err.response.data.status === 400) {
                toast(err.response.data.msg)
            }
        })
    }

    saveCallFeedback = (audioRowData) => {
        // debugger
        let {checkboxFeedbackData} = this.state;

        this.saveBtn.startLoading();

        let dataToSendCallFeedback = {
            teletaskHistory: audioRowData._id,
            feedbacks: checkboxFeedbackData.map((item) => {
                if(item.checked === true) {
                    return item.label
                }
            }).filter(i => i)
        }
        ApiActions.addUpdateQCFeedback(dataToSendCallFeedback)
            .then((responseCallFeedback)=>{
                // this.setState({
                //     checkboxFeedbackData: this.state.checkboxFeedbackData.map((item) => {
                //         return {...item, ...item.checked = false}
                //     })
                // })
                if(responseCallFeedback.status = 200) {
                    toast("Feedback Saved")
                }else{
                    toast("Not Saved or Error")
                }  
                this.saveBtn.stopLoading();              
            })
    }



    selectValue=(val)=>{
        this.setState({auditAction:val.value})
        if(val.value==="qcpass"){
            // QC Pass
            this.setState({reason:"Cleared in QC"})
            this.setState({ auditAction: "qcpass" })
            this.setState({
                checkboxCallerAuditData: this.state.checkboxQCPassReasons
            })
        }
        else if(val.value==="training") {
            // retrain
            this.setState({reason:"Marked Retrain by agent"})
            this.setState({ auditAction: "training" })
            this.setState({
                checkboxCallerAuditData: this.state.checkRetrainReasons
            })
        }
        else {
            // Ban
            this.setState({reason:"Marked Ban by agent"})
            this.setState({ auditAction: "ban" })
            this.setState({
                checkboxCallerAuditData: this.state.checkBanReasons
            })
        }
    }

     selectReason=(val)=>{
        this.setState({reason:val.value})
    }
    showAuditPopup=(rowData)=>{
        
        this.setState({audioInspectionsUI: []})
        this.setState({isLoadingPopup: true})
        this.setState({auditAction: ''})
        this.callerAuditModal.toggle(true);
        this.setState({activeRow:rowData,allOutcomes:false})
        this.setState({itemx: ""})
        this.setState({activeTab: {
            label: this.tabsForStage[0].label,
            value: this.tabsForStage[0].value
        }})
        if(rowData.autoHoldCounts==0&&rowData.suspectCounts==0){
            this.setState({rules:false})
            this.fetchUnflaggedCalls(rowData.id)
        }
        else
        {
            this.fetchRules(rowData)
            // this.fetchOutcomes(res)
        }

        // this.fetchTeletaskCallkHistory(rowData)
        
    }

        fetchTeletaskCallkHistory = (rowData) => {
            let dataToSend = {
                teletaskHistory: rowData.id
            }
            ApiActions.getTeletaskCallHistory(dataToSend)
                .then((response) => {
                    this.setState({
                        teletaskCallHistory: response
                    })
                })
        }

        fetchUnflaggedCalls=(user)=>{
            let {teleproject}=this.state;

            ApiActions.getunFlaggedCalls(teleproject.id,user).then(resp=>{
                this.setState({audioInspections:resp,isLoadingPopup:false})
                this.setState({audioInspectionsUI:resp,isLoadingPopup:false})
                // let out = new Set();
                // resp.forEach((item) => {
                //     out.add(item.outcome)
                // })
                // out = Array.from(out)
                // this.setState({
                //     outcomes: Array.from(out)
                // })
                // let outLkp = out.reduce((x, y)=>(x[y] = y, x), {})
                // this.setState({
                //     outcomeLookup: outLkp
                // })
            })
        }
        fetchRules=(rowData)=>{
            let {teleproject}=this.state;
            let outcomes=[];
            ApiActions.getRules(teleproject.id,rowData.id).then(resp=>{
                this.setState({rules:resp})
                  Object.keys(resp).map(res=>{
                    resp[res]&&resp[res].map(data1=>{
                        outcomes.push(data1.outcome)
                    })
                   this.setState({outcomes})
                  })
                  this.fetchOutcomes(rowData);
                  this.fetchReasons(rowData);
            })
              

        }
        fetchReasons = (rowData) => {
            ApiActions.getQAFeedbackReason()
            .then(response => {
                    this.setState({
                    checkboxFeedbackData : response.callFeedbacks.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkboxQCPassReasons: response.qcPassReasons && response.qcPassReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkBanReasons: response.banReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
                this.setState({
                    checkRetrainReasons: response.retrainReasons.map((item) => {
                        return { "label":item, "checked":false }
                    })
                })
            })
        }
        fetchOutcomes=(user)=>{
            let {outcomes,teleproject}=this.state;
            function onlyUnique(value, index, self) {
             return self.indexOf(value) === index;
                }
               var b= outcomes.filter(onlyUnique)
               let temp = {};
               b.forEach((prop,index)=>temp[prop] = prop);
            //    let temp2= {};
            //    teleproject.outcomes.forEach((props) => temp2[props.title] = props.title)
            // this.setState({outcomeLookup: temp2})
            this.setState({outcomes:b})
            let dataToSend={
                "teleproject":teleproject.id,
                "user":user.id,
                "outcomes":[]
            }
            ApiActions.fetchAudioInspections(dataToSend).then(resp=>{
                // <---solution to use if we dont want to persist the visited calls--->
                // resp = resp.map(each => {
                //     return {
                //         ...each, visited: false
                //     }
                // })
                // <---end of solution --->
                this.setState({audioInspections:resp,isLoadingPopup:false})
                this.setState({audioInspectionsUI:resp,isLoadingPopup:false})
                // let temp = {}
                // resp.forEach((props) => temp[props.outcome] = props.outcome)
                // this.setState({outcomeLookup: {...this.state.outcomeLookup, ...temp}})
            })
        }
    //  fetchData=()=>{
    //     // debugger;
    //     let {skip,callerauditlist}=this.state;
    //     let newData = [];
    //     ApiActions.getCallAuditList(skip,this.props.match.params.id).then(resp => {
    //         // debugger;
    //         skip=skip+50;
    //         newData=callerauditlist.concat(resp)
    //         this.setState({skip})
    //         this.setState({ callerauditlist: newData })
    //     })
    // }
    columns = [
        {
            Header: "Caller phone",
            accessor: 'mobile',
            Cell: ({value}) => {
                return <div className="caller-phone">
                         <img src={require("../../assets/images/Icons_user_info.svg")}/> {value}
                </div>
            },
            disableSortBy: true
        },
        {
            Header: 'Name',
            accessor: "name",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Total calls made',
            accessor: "callsMade",
            Cell: ({value}) => ( value ? value : 0),
            disableSortBy: true
        },
        {
            Header: 'Autohold',
            accessor: "autohold",
            Cell: ({cell}) => {
                let rowData = cell && cell.row && cell.row.original
                return rowData.autoHoldCounts > 0 ? <div className="autohold"><img src={require("../../assets/images/autohold.svg")}/></div> : <div className="autohold">--</div>
            },
            width: '10%',
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "action",
            Cell: ({cell}) => {
                let rowData = cell && cell.row && cell.row.original
                let rowcomponent = <div className="audit-arrow-caller"  onClick={()=>this.showAuditPopup(rowData)}> 
                <img src={require("../../assets/images/Icons_right.svg")}/>
                </div>

                return rowcomponent
            },
            width: "3%",
            disableSortBy: true
        }
    ]

    renderCallerAuditTable = () => {
       let { skip,callerauditlist, tableSorting, isLoading} = this.state;
       
         return (
            <div style={{ maxWidth: "100%" }}>
                <Table
                columns={this.columns}
                data={callerauditlist}
                totalCount={callerauditlist ? callerauditlist.length : 0}
                limit={10}
                type = 'client'
                currentPage={this.state.currentPage}
                setCurrentPage={this.setState}
                loading={isLoading}
                // error={error}
                isGlobalFilter = {true}
                manualSortBy = {false}
                />
            </div>
        )
    }



}