import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getPaymentData = async() => {
    const result = await ApiActions.getTeleCallerNumbers('tmp')
    return result
}

const transformPaymentData = (result) => {
    const {currentOutstandingPayment} = result.data
    return {
        title: 'Outstanding Amount',
        value: currentOutstandingPayment.count
    }
}

const getCertifiedCallers = async(skip,limit, sort, search) => {
    let payload = generatePayload(skip,limit, sort, search)
    const result = await ApiActions.getCertifiedCallersList(payload)
    return result
}

const getIdleCallers = async(skip,limit,sort,search) => {
    let payload = generatePayload(skip,limit, sort, search)
    const result = await ApiActions.getIdleCallersList(payload)
    return result
}

const getMatchedCallers = async(skip,limit,sort, search) => {
    let payload = generatePayload(skip,limit, sort, search)
    const result = await ApiActions.getMatchedCallerList(payload)
    return result
}

const getWorkingCallers = async(skip,limit,sort,search) => {
    let payload = generatePayload(skip,limit, sort, search)
    const result = await ApiActions.getWorkingCallerList(payload)
    return result
}

const getAvailableJobs = async(skip,limit,sort,search) => {
    let payload = generatePayload(skip,limit, sort, search)
    let result = await ApiActions.getAvailableJobs(payload)
    result = result.map(item => {
    return{
        ...item,
        isSelected: false
    }
    })
    return result
}

const matchTelecaller = async(payload) => {
    const result = await ApiActions.matchTelecaller(payload)
    return result
}

const getCallerCurrentJobs = async(payload) => {
    const result = await ApiActions.getCallerCurrentJobs(payload)
    return result
}

const getCallerJobHistory = async(payload) => {
    const result  = await ApiActions.getCallerJobHistory(payload)
    return result
}

const getCallerQCData = async(payload) => {
    const result = await ApiActions.getCallerQCData(payload)
    return result
}

const getCallerProfile = async(payload) => {
    const result = await ApiActions.getCallerProfile(payload)
    return result
}

const getCallerTrainingData = async(payload) => {
    const result = await ApiActions.getCallerTrainingData(payload)
    return result
}

const banCallerAction = async(payload) => {
    const result = await ApiActions.directBanCaller(payload)
    return result
}

const updateBasePay = async(userId, data) => {
    const result = await ApiActions.updateBasePay(userId, data)
    return result
}

const bulkMatchAgentToProject = async(payload) => {
    return await ApiActions.bulkMatchAgentToProject(payload)
}

//code for json server api's
// if(sort && sort.length > 0){
//     // let sort, order = ''
//     let filterSort = sort.map(({id}) => id)
//     let filterOrder = sort.map((item)=> {
//         if(item.desc){
//             return 'desc'
//         }else{
//             return 'asc'
//         }
//     })
//     const result = await Axios.get(`http://localhost:3001/certified?_page=${skip}&_limit=${limit}${filterSort ? `&_sort=${filterSort}` : ''}${filterOrder ? `&_order=${filterOrder}` : ''}`)
//     return result
// }else{
//     const result = await Axios.get(`http://localhost:3001/certified?_page=${skip}&_limit=${limit}`)
//     return result
// }

export{
    getPaymentData,
    transformPaymentData,
    getCertifiedCallers,
    getIdleCallers,
    getMatchedCallers,
    getWorkingCallers,
    getAvailableJobs,
    matchTelecaller,
    getCallerCurrentJobs,
    getCallerJobHistory,
    getCallerQCData,
    getCallerProfile,
    getCallerTrainingData,
    banCallerAction,
    updateBasePay,
    bulkMatchAgentToProject
}
