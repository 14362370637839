import React from 'react';

import FrappModal from '../../utilities/FrappModal';


class DataSuccessScreen extends React.Component{
    render(){
        return (
            <FrappModal onHide={false} className="data-success-screen-modal" ref={(c) => this.modal = c} >
                <h1>{this.props.headerText}</h1>
                <div className="graphic-wrapper" >
                    <img src={require("../../../assets/images/success-graphic.svg")} alt=""/>
                </div>
                <div className="warning-box" >
                    Warning!
                    Please don't shut the browser or close this tab.
                </div>
                <h4 className="info-text" >
                    This is will shut automatically once the process done.
                </h4>
            </FrappModal>
        )
    }
}

export default DataSuccessScreen;