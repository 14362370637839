import React, { useState, useRef, useEffect } from "react";

const AutoComplete = ({ suggestions, value, updateData, placeholder }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");

  let cordinates = {
    top: '',
    left: '',
    triggerIdx: '',
    active: ''
  }

  const properties = [
    'direction',
    'boxSizing',
    'width',
    'height',
    'overflowX',
    'overflowY',

    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderStyle',

    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',

    'fontStyle',
    'fontVariant',
    'fontWeight',
    'fontStretch',
    'fontSize',
    'fontSizeAdjust',
    'lineHeight',
    'fontFamily',

    'textAlign',
    'textTransform',
    'textIndent',
    'textDecoration',

    'letterSpacing',
    'wordSpacing',

    'tabSize',
    'MozTabSize',
  ]

  const isFirefox = typeof window !== 'undefined' && window['mozInnerScreenX'] != null

  let menuRef = useRef()
  let inputRef = useRef()

  const resolveFn = prefix => prefix === ''
    ? suggestions
    : suggestions.filter(suggest => suggest.startsWith(`@${prefix}`))

  const replaceFn = (user, trigger) => user

  const makeOptions = async (query) => {
    const options = await resolveFn(query)
    if (options.length !== 0) {
      setFilteredSuggestions([...options])
      renderMenu()
    } else {
      closeMenu()
    }
  }

  const getCaretCoordinates = (element, position) => {
    const div = document.createElement('div')
    document.body.appendChild(div)

    const style = div.style
    const computed = getComputedStyle(element.current)
    style.whiteSpace = 'pre-wrap'
    style.wordWrap = 'break-word'
    style.position = 'absolute'
    style.visibility = 'hidden'

    properties.forEach(prop => {
      style[prop] = computed[prop]
    })

    if (isFirefox) {
      if (element.current.scrollHeight > parseInt(computed.height))
        style.overflowY = 'scroll'
    } else {
      style.overflow = 'hidden'
    }

    div.textContent = element.current.value.substring(0, position)
    const span = document.createElement('span')
    span.textContent = element.current.value.substring(position) || '.'
    div.appendChild(span)

    const coordinates = {
      top: span.offsetTop + parseInt(computed['borderTopWidth']),
      left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
      height: span.offsetHeight
    }

    div.remove()

    return coordinates
  }

  const selectItem = (active) => {
    return () => {
      let data = filteredSuggestions
      setFilteredSuggestions((prev => {
        data = prev
        return prev
      }))
      const preMention = inputRef.current.value.substr(0, cordinates.triggerIdx)
      const option = data[active]
      const mention = replaceFn(option, inputRef.current.value[cordinates.triggerIdx])
      const postMention = inputRef.current.value.substr(inputRef.current.selectionStart)
      const newValue = `${preMention}${mention}${postMention}`
      inputRef.current.value = newValue
      updateData(inputRef.current.value)
      const caretPosition = inputRef.current.value.length - postMention.length
      inputRef.current.setSelectionRange(caretPosition, caretPosition)
      closeMenu()
      inputRef.current.focus()
    }
  }
  const renderMenu = () => {
    if (cordinates.top == '') {
      menuRef.current.hidden = true
      return
    }
    // this can be used to show the menu on the caret postion for text area
    // menuRef.current.style.left = cordinates.left + 'px'
    // menuRef.current.style.top = cordinates.top + 'px'
    menuRef.current.innerHTML = ''
    let data = filteredSuggestions
    setFilteredSuggestions((prev => {
      data = prev
      return prev
    }))
    data.forEach((option, idx) => {
      menuRef.current.appendChild(menuItemFn(
        option,
        selectItem(idx),
        cordinates.active === idx
      ))
    })
    menuRef.current.hidden = false
  }

  const closeMenu = () => {
    setTimeout(() => {
      setFilteredSuggestions([])
      cordinates = {
        left: '',
        triggerIdx: '',
        top: '',
        active: ''
      }
      renderMenu()
    }, 0)
  }

  const menuItemFn = (user, setItem, selected) => {
    const div = document.createElement('div')
    div.setAttribute('role', 'option')
    div.className = 'menu-item'
    if (selected) {
      div.classList.add('selected')
      div.setAttribute('aria-selected', '')
    }
    div.textContent = user
    div.onclick = setItem
    return div
  }

  const onKeyPress = (e) => {
    if (e.key == '@') {
      setFilteredSuggestions(suggestions)
      renderMenu()
    }
  }

  const onInput = (ev) => {
    updateData(ev.target.value)
    const positionIndex = ev.target.selectionStart
    const textBeforeCaret = ev.target.value.slice(0, positionIndex)
    const tokens = textBeforeCaret.split(/\s/)
    const lastToken = tokens[tokens.length - 1]
    const triggerIdx = textBeforeCaret.endsWith(lastToken)
      ? textBeforeCaret.length - lastToken.length
      : -1
    const maybeTrigger = textBeforeCaret[triggerIdx]
    const keystrokeTriggered = maybeTrigger === '@'

    if (!keystrokeTriggered) {
      closeMenu()
      return
    }
    const query = textBeforeCaret.slice(triggerIdx + 1)
    makeOptions(query)
    const coords = getCaretCoordinates(inputRef, positionIndex)
    const { top, left } = inputRef.current.getBoundingClientRect()

    cordinates = {
      left: window.scrollX + coords.left + left + inputRef.current.scrollLeft,
      top: window.scrollY + coords.top + top + coords.height - inputRef.current.scrollTop,
      triggerIdx: triggerIdx,
      active: 0
    }
    renderMenu()
  }

  return (
    <>
      <div style={{ display: 'block' }}>
        <input
          className='auto-complete-input'
          type="text"
          onInput={onInput}
          onKeyPress={onKeyPress}
          // onChange={(e) => onChange(e)}
          // onKeyDown={onKeyDown}
          value={value}
          ref={inputRef}
          placeholder={placeholder}
        />
        {/* {showSuggestions && input && <SuggestionsListComponent />} */}
      </div>
      <div id="menu" class="auto-complete-menu" role="listbox" ref={menuRef}></div>
    </>
  );
};

export default AutoComplete;