import React, { useState, useEffect } from 'react'
import Dropdown from 'react-dropdown';
import moment from 'moment';
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
//custom imports
import ProjectTitle from '../../components/common/ProjectTitle'
import ApiActions from '../../actions/ApiActions';
import useApi from '../../hooks/useApi';
import FutworkButton from '../../uicomponents/button/Button';
import { RefreshIcon, ErrorIcon } from '../../assets/images'
import handleErrorMessage from '../../helper/handleErrorMessage';
import ThreeDotLoader from '../../components/utilities/ThreeDotLoader';

const DtdcLeadsUnmask = (props) => {

    const projectListApi = useApi(ApiActions.getActiveTeleProjects)
    const projectWithBrandsApi = useApi(ApiActions.getActiveTeleProjectsWithBrands)
    const unmaksLeadsApi = useApi(ApiActions.startUnMasking)
    const unmaskCountApi = useApi(ApiActions.unMaskedLeadsCount)
    const [projectSelected, setProjectSelected] = useState('')


    useEffect(() => {
        projectListApi.request({ filterStatus: 'active' })
        projectWithBrandsApi.request(JSON.stringify(moment().format("YYYY-MM-DD")))
    }, [])


    const defineProjects = () => {
        let filterByBrand = data && data.filter(({ brand }) => brand && brand.name && brand.name.toLowerCase().includes(`dtdc`))
        let result = []
        result = filterByBrand && filterByBrand.map(({ title, id }) => {
            return { label: title, value: id }
        })
        return result
    }

    const { data, loading, error } = projectWithBrandsApi || {}
    const { data: unmaskLeadsData, loading: unmaksLeadsLoading, error: unmaskLeadsError } = unmaksLeadsApi || {}
    const { data: unmaskCountData, loading: unmaskCountLoading, error: unmaskCountError } = unmaskCountApi || {}

    return (
        <div className='container'>
            <div className={styles['dtdc-unmask-leads']}>
                <ProjectTitle title={`DTDC leads unmasking`} goBack={() => props.history.push("/tools")} />
                <div className={styles['section']}>
                    {
                        loading ? <ThreeDotLoader /> : <div className={styles['select-project-row']}>
                            <span className={styles['label']}>Project</span>
                            <Dropdown
                                options={defineProjects()}
                                onChange={(e) => {
                                    setProjectSelected(e.value)
                                    unmaskCountApi.request({ teleprojectId: e.value })
                                }}
                                className={styles['tool_dropdown_projects']}
                                placeholder="Select project" />
                        </div>
                    }

                    {projectSelected && <div className={styles['unmask-action-row']}>
                        <FutworkButton
                            buttonSize='x-large'
                            onClick={() => {
                                unmaksLeadsApi.reset()
                                unmaksLeadsApi.request({ teleprojectId: projectSelected })
                            }}
                            disabled={unmaksLeadsLoading}
                        >Unmask leads</FutworkButton>
                        <div className={styles['label']}>Remaining leads to unmask: {unmaskCountLoading ? 'NA' : unmaskCountData && unmaskCountData.maskedTeletasksCount}</div>
                        <img src={RefreshIcon} alt='refresh' className={styles['refresh']} onClick={() => {
                            unmaksLeadsApi.reset()
                            unmaskCountApi.reset()
                            unmaskCountApi.request({ teleprojectId: projectSelected })
                        }} />
                    </div>}

                    {
                        unmaskLeadsError ? <span className={styles['error-msg']}>
                            <img src={ErrorIcon} alt='alert'/>{handleErrorMessage(unmaskLeadsError)}</span> : null
                    }

                    {
                        unmaskCountError ? <span className={styles['error-msg']}>
                            <img src={ErrorIcon} alt='alert'/>{handleErrorMessage(unmaskCountError)}</span> : null
                    }

                    {
                        unmaskLeadsData ? <span className={styles['success-msg']}>Leads unmasking is in progress...</span> : null
                    }

                </div>

            </div>
        </div>
    )
}

export default DtdcLeadsUnmask