import React, { forwardRef, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datepicker.scss'

const Datepicker = ({onChange, selectedDate, minDate, maxDate, isDateRange = false, isDateTime = false, from = null, to = null, placeholderText = ''}) => {
  const [dateRange, setDateRange] = useState([from, to]);
  // const [startDate, setStartDate] = useState();
  const [startDate, endDate] = dateRange;
  // const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className={"example-custom-input"}
  //     onClick={onClick}
  //     onChange={onChange}
  //     ref={ref}
  //   ></input>
  // ));
  return isDateRange ?
    <DatePicker
    className='example-custom-input'
    showPopperArrow={false}
    showIcon
    dateFormat="dd/MM/yyyy"
    selectsRange={true}
    startDate={startDate}
    endDate={endDate}
    onChange={(update) => {
      setDateRange(update);
      onChange(update)
    }}
    minDate={minDate}
    maxDate={maxDate}
    />
    :
    isDateTime ?
    <DatePicker
      showPopperArrow={false}
      showIcon
      selected={selectedDate}
      showTimeSelect
      dateFormat="dd/MM/yyyy h:mm aa"
      onChange={onChange}
      placeholderText={placeholderText}
    />
    :
    <DatePicker
      showPopperArrow={false}
      showIcon
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat="dd/MM/yyyy"
      // onChange={(date) => setStartDate(date)}
      onChange={onChange}
      // customInput={<ExampleCustomInput />}
      // dayClassName={() => "example-datepicker-day-class"}
      // popperClassName="example-datepicker-class"
      // todayButton="TODAY"
      placeholderText={placeholderText}
    />
}

export default Datepicker