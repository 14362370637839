import dayjs from 'dayjs'

/*     POLYFILLS   */

NodeList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}


/* ------------------------------------------------ */

window.UTIL = {
    capitalize:(string) => {
        let words = string.split(" ");
        words = words.map(word => {
            if(word === 'and'){
                return word;
            }
            
            word = word[0].toUpperCase() + word.substring(1,word.length);
            return word;
        })
        return words.join(" ");
    },
    formatDate:(date) => {
        const appendDaySuffix = (a) => {
            if(a === "11" || a === "12" || a === "13"){
                return "th"
            }
            return ["th","st","nd","rd"][ ( ( a = ~~(a<0?-a:a)%100) > 10 && a<14 ) || ((a%=10) > 3 ? 0 : a )];
        }

        var parts = dayjs(new Date(date)).format("DD MMMM YYYY").split(" ");
        var dayPart = parts[0];
        dayPart = dayPart+appendDaySuffix(dayPart);
        return `${dayPart} ${parts[1]} ${parts[2]}`
    },
    checkMongoId:(id) => {
    
        if(id.length !== 24){
            return false
        }

        const hexadecimalValues = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f"
        ];

        var idChars = id.split('');
        for(let char of idChars){
            if(hexadecimalValues.indexOf(char) === (-1)){
                return false
            }
        }
        return true
    }
}