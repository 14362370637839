import React, {useState, useEffect, useContext} from 'react'
import { getProjectList, linkProjectToPool, getProjectsLinked } from './service'
import useApi from '../../../hooks/useApi'
import Table from '../../../uicomponents/table/Table'
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import Tab from '../../../uicomponents/tab/tab'
import withTabGroup from '../../../hocs/withTabGroup'
import { ExoContext } from './exoContext'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import { ToastContainer, toast } from "react-toastify";

const AllProjects = () => {
    const getProjectListApi = useApi(getProjectList)
    const linkProjectToPoolApi = useApi(linkProjectToPool)
    const { viewExophones, setViewExophones, exophonesListApi, setExotelAcc, activeTab} = useContext(ExoContext)

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState([])
    const [limit, setLimit] = useState(10)

    

    const [link, setLink] = useState(true)

    const TabGroup = withTabGroup(Tab, 'group');

    const {data:{poolId, poolName}} = viewExophones


    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        const { data: {sid} } = activeTab
        const otherFilters = {"callingAccount": sid }
        getProjectListApi.request(skip,limit,sort, search, 'active', otherFilters)
    },[currentPage, sort, search])

    let {data, total} = getProjectListApi.data || {}
    const {loading, error} = getProjectListApi

    
    useEffect(() => {
        if (loading == false && error){
            toast(handleErrorMessage(error))
        }
    }, [loading, error])


    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
        getProjectListApi.reset()
        window.history.replaceState({}, document.title)
    }

    const linkProject = (row) => {
        const payload = {
            teleproject: row.id,
            poolId,
            linked: true
        }
        linkProjectToPoolApi.request(payload)  
    }

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "50%",
            disableSortBy: true
        },
        {
            Header: 'Actions',
            accessor: "action",
            Cell: ({row}) => {
                return <div className={[styles['underline_link'], styles['linked']].join(' ')}
                onClick={() => linkProject(row.original)}>
                    {'+link'}
                </div>
            },
            width: "5%",
            disableSortBy: true
        }
    ]

    let {data: linkedProjects, total: linedProjectsTotal} = linkProjectToPoolApi.data || {}
    const {loading: linkedProjectLoading, error: linkedProjectsError} = linkProjectToPoolApi

    const { data: exophonesData, loading: exophonesLoading, error: exophonesError } = exophonesListApi || {}
    

    useEffect(() => {
        if (linkedProjectsError){
            toast(handleErrorMessage(linkedProjectsError))
        }
    }, [linkedProjectsError])

    
    return <>
        {
            !linkedProjectLoading && linkedProjects && <div className={[styles['success-msg']].join(' ')}>{linkedProjects.title} is successfully linked to the pool "{poolName}"</div>
        }
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        // sortChange={sortChange}
        filterChange = {filterChange}
        searchBy='project id or title'
        isGlobalFilter = {true}
        search={search}
        height='50'
        />
    </>
   
}

const LinkedProjects = () => {
    const getProjectsLinkedApi = useApi(getProjectsLinked)
    const delinkProjectFromPoolApi = useApi(linkProjectToPool)

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState([])
    const [limit, setLimit] = useState(10)

    const { viewExophones } = useContext(ExoContext)


    const [link, setLink] = useState(true)

    const TabGroup = withTabGroup(Tab, 'group');

    const {data:{poolId, poolName, callingAccount}} = viewExophones

    useEffect(()=>{
        // const skip = (currentPage - 1) * limit
        getProjectsLinkedApi.request(0, 100, sort, search, poolId, callingAccount)
    },[])

    let {data, total} = getProjectsLinkedApi || {}
    const {loading, error} = getProjectsLinkedApi
    const [selectedRow, setSelectedRow] = useState('')
 
    useEffect(() => {
        if (error)
            toast(handleErrorMessage(error))
    }, [error])


    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
        getProjectsLinkedApi.reset()
        window.history.replaceState({}, document.title)
    }

    const delinkProjectFromPool = (row) => {
        const payload = {
            teleproject: row.id,
            poolId,
            linked: false
        }
        delinkProjectFromPoolApi.request(payload)
    }

    const columns = [
        {
            Header:'Project Id',
            accessor: "id",
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({value}) => `${value || 'NA'}`,
            width: "50%",
            disableSortBy: true
        },
        {
            Header: 'Actions',
            accessor: "action",
            Cell: ({row}) => {
                return <div className={[styles['underline_link'], styles['delink']].join(' ')}
                onClick={() => {
                    setSelectedRow(row.original)
                    delinkProjectFromPool(row.original)}
                }>
                    {'-delink'}
                </div>
            },
            width: "5%",
            disableSortBy: true
        }
    ]

   

    let {data: delinkedProject} = delinkProjectFromPoolApi.data || {}
    const {loading: delinkProjectLoading, error: delinkedProjectError} = delinkProjectFromPoolApi

    useEffect(() => {
        // console.log('data got for delinking projects', delinkedProject)
        // console.log('vieExophones', viewExophones)
    },[delinkedProject && !delinkProjectLoading])


    useEffect(() => {
        if (delinkedProjectError)
            toast(handleErrorMessage(delinkedProjectError))
    }, [delinkedProjectError])

    useEffect(()=>{
        if(delinkedProject){
            const filteredData = data.filter(each => {
                if(each.id !== selectedRow.id){
                    return each
                }
            })
            getProjectsLinkedApi.setData(filteredData)
        }
    
    },[delinkedProject !== undefined])

    return <>
        {
            !delinkProjectLoading && delinkedProject && <div className={[styles['success-msg']].join(' ')}>{delinkedProject.title} is successfully delinked from the pool "{poolName}"</div>
        }
        <Table
        columns={columns}
        data={data}
        totalCount={data && data.length > 0 ? data.length : 0}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        // sortChange={sortChange}
        // filterChange = {filterChange}
        searchBy='project id or title'
        isGlobalFilter = {true}
        // search={search}
        height='50'
        type='client'
        />
    </>
   
}


const ProjectList = (props) => {
    const TabGroup = withTabGroup(Tab, 'group');
    const { projectActiveTab, projectTabs } = useContext(ExoContext)

    const renderTable = () => {
        switch (projectActiveTab) {
            case projectTabs[0]:
                return <LinkedProjects />
            case projectTabs[1]:
                return <AllProjects />
        }
    }

    return (
        <div>
            {renderTable()}
            {/* <ToastContainer draggable={true} /> */}
        </div>
    )
}

export default ProjectList