import React, {useState, useContext, useEffect} from 'react'
import { useAPI } from "./ISContext";
import GraphDiagram from './GraphDiagram'

const IS = (props) => {
    // const { teleprojectdata, allScripts } = useAPI();
    return <div>
        <GraphDiagram {...props}/>
        </div>

}

export default IS