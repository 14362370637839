import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getProjectList = async(skip,limit, sort, search,type) => {
    let payload = generatePayload(skip,limit, sort, search)
    if(type === 'hiring projects') {
        return await ApiActions.getActiveTeleProjects(payload)
    }
    if(type === 'hiring projects - stats') {
        return await ApiActions.getActiveTeleProjectsForHiring(payload)
    }
}

const getTotalProjects = async(search) => {
    return await ApiActions.getTeleProjectsForAuditCount(search)
}

const getProjectData = async(projectId) => {
    return await ApiActions.getTeleProject(projectId)
}

const updateProjectData = async(data, projectId) => {
    return await ApiActions.updateTeleProject(data, projectId)
}

const coachMockCallStats = async(projectId) => {
    return await ApiActions.coachMockCallStats('', '', projectId)
}

const updateTelegramLink = async(data, projectId) => {
    return await ApiActions.updateTeleProject(data, projectId)
}

const assignLeads = async(data) => {
    return await ApiActions.assignLeads(data)
}

const statsForAssignAllLeads = async(data) => {
    return await ApiActions.statsForAssignAllLeads(data)
}

const statsForAssignTo = async(data) => {
    return await ApiActions.statsForAssignTo(data)
}

const getTeleProject = async (teleprojectId, title) => {
    return await ApiActions.getTeleProject(teleprojectId, null, ['Quiz','Audio test'].includes(title) ? 'quiz' : null)
}

const getQuizDetails = async(quizId) => {
    return await ApiActions.getQuiz(quizId)
}

export{
    getProjectList,
    getTotalProjects,
    getProjectData,
    updateProjectData,
    coachMockCallStats,
    updateTelegramLink,
    assignLeads,
    statsForAssignAllLeads,
    statsForAssignTo,
    getTeleProject,
    getQuizDetails
}