import React, { useState, useEffect } from 'react'
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faClone } from '@fortawesome/free-solid-svg-icons'
import CustomTooltip from '../utilities/ToolTip';
import FormInput from '../../uicomponents/form-input/forminput';
import { QuestionMarkCircle } from '../../assets/images';
import Select from 'react-select'
import Switch from "react-switch";
import Popup from '../../uicomponents/popup'
import FutworkButton from '../../uicomponents/button/Button';

const GSheetTemplateForm = ({ idx, item, getFormData, projectData, scriptHeaders, saveSheet }) => {
    const [data, setData] = useState('')
    const [options, setOptions] = useState(projectData && projectData.outcomes && projectData.outcomes.length > 0 ?
        projectData.outcomes.map((outcome, idx) => {
            return {
                id: idx, value: outcome.title, label: outcome.title,
                disabled: item && item.outcomesFilter && item.outcomesFilter.some(out => out == outcome.title)
            }
        })
        :
        [])

    // set value for default selection
    const [selectedValue, setSelectedValue] = useState(item && item.outcomesFilter ? item.outcomesFilter : []);
    const [allHeaders, setAllHeaders] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    
    const googlelink = <div>
        Ensure <strong>gcf-sheet-keeper@frapp-prod.iam.gserviceaccount.com</strong> has edit access to the G-Sheet
    </div>

    const viewOnly = <div>
        Ensure users have <strong>'VIEW ONLY'</strong> access to the G-Sheet as any changes made to it may disrupt the writing process
    </div>

    const [termsCondition, setTermsCondition] = useState([
        {
            label: 'Ensure headers in the G-Sheet match exactly with the headers defined in the available headers.',
            checked: false,
            value: 'ensure-headers',
            cta: true
        },
        {
            label: googlelink,
            checked: false,
            value: 'gcf-sheet-keeper"'
        },
        {
            label: viewOnly,
            checked: false,
            value: 'users'
        },
        {
            label: 'Ensure there are no duplicate headers in the G-Sheet',
            checked: false,
            value: 'duplicate-headers'
        }
    ])

    const [sheetLinkValid, setSheetLinkValid] = useState(true)
    const [sheetIdInvalid, setSheetIdInvalid] = useState(false)
    const [outcomesMissing, setOutcomeMissing] = useState(false)

    const removeDuplicates = (data) => {
        return [...new Set(data)]
    }

    useEffect(() => {
        setAllHeaders([
            ...projectData && projectData.taskProps && projectData.taskProps.length > 0 ?
            projectData.taskProps.map(props => {
                return props.name
            }) : [],
            ...removeDuplicates(scriptHeaders),
            'attempt',
            'callSid',
            'callstatus',
            'leadid',
            'callerid',
            'outcome',
            'isWinning',
            'recordingUrl',
            'conversationMinutes',
            'disconnectedBy',
            'phoneNumberSid',
            'startTime',
            'endTime',
            'entrydate',
            'entrytime'
        ])

    }, [projectData && projectData.taskProps && projectData.taskProps.length > 0, scriptHeaders])

    useEffect(() => {
        setOptions(projectData && projectData.outcomes && projectData.outcomes.length > 0 ?
            projectData.outcomes.map((outcome, idx) => {
                return {
                    id: idx, value: outcome.title, label: outcome.title
                    // disabled: outcomesAlreadyUsed && outcomesAlreadyUsed.some(out => out == outcome.title)
                }
            })
            :
            [])
    }, [projectData.outcomes])

    const renderHeaders = () => {
        return (
            <Popup
                show={showPopup}
                closePopup={() => {
                    setShowPopup(false)
                }}
                size='sm'
                heading={`Headers`}
            >
                {
                    allHeaders && allHeaders.length > 0 ?
                        <div className='headers_list'>
                            <ol>
                                {
                                    allHeaders.map(header => {
                                        return <li>{header}</li>
                                    })
                                }
                            </ol>
                        </div>
                        :
                        null
                }
            </Popup>
        )
    }

    const handleChange = (e) => {
        const tempList = Array.isArray(e) ? e.map(x => x.value) : []
        const newOptionAdded = tempList.filter(x => !selectedValue.includes(x)); // calculates diff
        let difference = selectedValue.filter(x => !tempList.includes(x)); // calculates diff
        const updateOptions = options && options.length > 0 ?
            options.map(opt => {
                if (difference.includes(opt.value)) {
                    opt.disabled = false
                }
                if (newOptionAdded.includes(opt.value)) {
                    opt.disabled = true
                }
                return opt
            }) : []

        if (updateOptions && updateOptions.length > 0) {
            setOutcomeMissing(false)
        }
        setOptions(updateOptions)
        setSelectedValue(Array.isArray(e) ? e.map(x => x.label) : []);
        getFormData('outcomesFilter', Array.isArray(e) ? e.map(x => x.label) : [])
    }

    const checkTerms = (list) => {
        let tempTerms = [...termsCondition]
        tempTerms.map(term => {
            if (term.label == list.label) {
                term.checked = !list.checked
            }
            return term
        })
        setTermsCondition(tempTerms)
    }

    const checkFromValidation = () => {
        let value = false
        const countChecks = termsCondition.filter(item => item.checked)
        if (!item.sheetId || (item.isActive && countChecks.length < 4) || !sheetLinkValid || sheetIdInvalid) {
            value = true
        }
        return value
    }

    const validateGoogleSheetLink = (input) => {
        const googleSheetLinkPattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+(\/edit)?(#gid=\d+)?$/;
        const googleSheetIdPattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]{40,})(\/.*|#gid=\d+)?$/;
        const match = input.match(googleSheetIdPattern);

        if (googleSheetLinkPattern.test(input)) {
            setSheetLinkValid(true)

        } else {
            setSheetLinkValid(false)
        }

        if (!match) {
            setSheetIdInvalid(true)

        } else {
            setSheetIdInvalid(false)
        }
    }

    return (
        <div className='template_container' key={idx}>
            <div className='action_row'>
                {/* <DropdownButton
            bsSize='small'
            title={<FontAwesomeIcon icon={faEllipsisVertical} />}
            id="action"
            >
            <MenuItem eventKey="1" 
            >Edit</MenuItem>
            </DropdownButton> */}
            </div>

            <div className='template_form'>
                <FormInput
                    label="G-Sheet URL"
                    helperIcon={true}
                    helperText={'Add the google sheet link'}
                    key={'title'}
                    onChange={(e) => {
                        validateGoogleSheetLink(e.target.value)
                        getFormData('sheetId', e.target.value)
                    }}
                    value={item.sheetId}
                />
                {!sheetLinkValid ? <span className='error'>Invalid Google Sheet link. Please ensure the link is in the correct format.</span> : null}
                {sheetIdInvalid ? <span className='error'>Sheet ID is invalid</span> : null}
                <div>
                    {item.sheetId && selectedValue && selectedValue.length > 0 ?
                        <div className='outcome_link_label'>
                            <label>Outcomes Linked</label>
                            <CustomTooltip placement={'right'} component={<img src={QuestionMarkCircle} className='helper_icon' />}
                                description={'List of outcomes that are linked to the template'} />
                        </div> :
                        <div className='outcome_link_label'>
                            <label>Link to outcome</label>
                            <CustomTooltip component={<img src={QuestionMarkCircle} className='helper_icon' />} placement={'right'}
                                description={'Click on the dropdown to get the list of outcomes'} />
                        </div>
                    }
                </div>

                {
                    <Select options={options}
                        key={item.id}
                        isOptionDisabled={(option) => option.disabled}
                        value={options && options.filter(obj => selectedValue.includes(obj.label))} // set selected values
                        isMulti
                        name="outcome-linked"
                        classNamePrefix="select"
                        onChange={handleChange}
                        disabled={true}
                    />

                }
                {
                    outcomesMissing ? <span className='error'>If the integration is active then atleast one outcome should be selected</span> : null
                }

                <div className={'template-active-row'}>
                    <label>G-sheet Active </label><Switch onChange={(e) => {
                        if (e == false) {
                            setOutcomeMissing(false)
                            let tempTerms = [...termsCondition]
                            tempTerms.map(term => {
                                term.checked = false
                                return term
                            })
                            setTermsCondition(tempTerms)
                        }
                        getFormData('isActive', !item.isActive)
                    }} checked={item.isActive}
                    />
                </div>

                {
                    item.isActive ?
                        <>
                            {
                                termsCondition && termsCondition.map(list => {
                                    return <div className='terms_condition'>
                                        <p className="comment-form-cookies-consent">
                                            <input id={list.value} name="headers" type="checkbox" value={list.value} checked={list.checked}
                                                onChange={() => checkTerms(list)} />
                                            {/* htmlFor={list.value} */}
                                            <label>{list.label}
                                                {list.cta && <span className={'cta_headers'} onClick={() => setShowPopup(true)}>Show available headers</span>}
                                            </label>
                                        </p>
                                    </div>
                                })
                            }
                        </>
                        : null
                }

                {/* <div className='alert_message'>
                     <ul>
                        <li>Please make sure that duplicate headers are not mentioned in the Google sheet</li>
                        <li>Please make sure the naming convention is followed for the headers as given in the 'Show available headers'</li>
                     </ul>   
                    </div> */}

                <div className='save_template_wrapper'>
                    <FutworkButton buttonStyle="primary--solid" buttonSize="sm"
                        disabled={checkFromValidation()}
                        onClick={() => {
                            if ((!selectedValue || selectedValue.length == 0) && item.isActive) {
                                setOutcomeMissing(true)
                                return
                            }
                            saveSheet()
                        }}>
                        SAVE
                    </FutworkButton>
                </div>

            </div>
            {showPopup ? renderHeaders() : null}
        </div>
    )

}

export default GSheetTemplateForm