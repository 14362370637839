import ApiActions from "../../actions/ApiActions";
import generatePayload from '../../helper/generatePayloadForTable'

const getFollowupCallsList = async(skip,limit, sort, search, obj) => {
    let payload = generatePayload(skip,limit, sort, search)
    const data = {
        ...obj,
        ...payload
    }
    return ApiActions.getFollowupCallsList(data)
}

const getTotalFollowupCallsList = async(data) => {
    return ApiActions.getTotalFollowupCallsList(data)
}

const getCallHistory = async(teletaskId) => {
    return ApiActions.getCallHistory(teletaskId)
}

const transferOpenLeadsToManyAgents = async(data) => {
    return ApiActions.transferOpenLeadsToManyAgents(data)
}

const refreshTrainingVideos = async (teleprojectId) => {
    return await ApiActions.getRefresherTrainingVideos(teleprojectId)
}

const uploadVideo = async(payload) => {
    return await ApiActions.uploadRefresherTrainingVideo(payload)
}

const saveVideoInitially = async(payload) => {
    return await ApiActions.createRefresherTrainingVideo(payload)
}

const saveVideoLater = async(payload) => {
    return await ApiActions.updateRefresherTrainingVideo(payload)
}

const transferLeadsById = async(payload) => {
    return await ApiActions.transferLeadsById(payload)
}

const transferLeadsBySpecificLeadId = async(payload) => {
    return await ApiActions.transferLeadsBySpecificLeadId(payload)
}

export{
    getFollowupCallsList,
    getTotalFollowupCallsList,
    getCallHistory,
    transferOpenLeadsToManyAgents,
    refreshTrainingVideos,
    uploadVideo,
    saveVideoInitially,
    saveVideoLater,
    transferLeadsById,
    transferLeadsBySpecificLeadId
}