import React, { useState } from "react"
import FormGroup from 'react-bootstrap/lib/FormGroup'
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import FormControl from 'react-bootstrap/lib/FormControl'
import FrappButton from '../utilities/FrappButton'
import ApiActions from '../../actions/ApiActions'
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
    let [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false)
    let [email, setEmail] = useState('')

    const submitForgotPassword = async (e) => {
      e.preventDefault()
      try {
        const resp = await ApiActions.forgotPassword(email)
        setForgotPasswordSuccess(true)
      }
      catch(error) {
         let msg = error && error.response && error.response.data && error.response.data.error ?
         error.response.data.error : 'Something went wrong'
         toast(msg)
      }
    }

    return (
      <div className="forgot-password">
        
        <img src={require("../../assets/images/futworklogo.png")} alt="" className="logo" />
        <h1>Forgot your password?</h1>
        <h3>Enter your email address below and we'll get you back on track.</h3>
        {forgotPasswordSuccess ? (
          <div className="info">Please check your email.</div>
        ) : (
            <form onSubmit={submitForgotPassword}>
              <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  required
                />
              </FormGroup>
              <div className="cta-wrapper">
                <FrappButton
                  className="submit"
                  id="new-btn"
                  disabled={!email}
                  type="submit"
                >
                get reset link
                </FrappButton>
              </div>
            </form>
          )}
          <ToastContainer draggable={true} />
      </div>
    )
}

export default ForgotPassword