import React, { useEffect, useState } from 'react'
import ApiActions from '../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from "../utilities/FrappButton";
import FrappModal from "../utilities/FrappModal";
import SMSTemplateForm from '../common/SMSTemplateForm'
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import checkIsEmpty from '../../helper/checkIsEmpty'
import TruncateString from '../../helper/TruncateString'
import AlwaysScrollToBottom from '../common/AlwaysScrollToBottom'

const SmsTemplate = (props) => {
    let linkOutcomeToTemplateBtn;

    const dummyTemplate = {
        title: '',
        flowId: '',
        dltTemplateId: '',
        templateText: '',
        active: false,
        teleproject: props.match.params.id
    }
    const dummyoutcomeTemplateLink = {
        outcome: '',
        templateId: ''
    }

    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({})
    const [templates, setTemplates] = useState([dummyTemplate])
    const [outcomeTemplate, setOutcomeTemplate] = useState([dummyoutcomeTemplateLink])
    const [templateDropdownList, setTemplateDropdownList] = useState([])
    const [templateLatestCount, setTemplateLatestCount] = useState(0)
    const [showSmsTestUi, setShowSmsTestUi] = useState(false)
    const [currentSmsData, setCurrentSmsData] = useState({})
    const [checkIfLatest, setCheckIfLatest] = useState(false)

    useEffect(() => {
        let id = props.match.params.id
        ApiActions.getTeleProject(id).then(resp => {
            setFormData({ ...resp })
            if (resp.sms)
                reArrangeOutcomeTemplate(resp.sms)
        }).then(() => {
            getSmsTemplatesList()
            setCheckIfLatest(true)
        }).then(() => {
            setLoading(false)
        }).catch(err => {
            console.error(err.response)
        })
    }, [])

    const getSmsTemplatesList = () => {
        let id = props.match.params.id
        ApiActions.findSmsTemplates(id).then(resp => {
            if (!checkIsEmpty(resp)) {
                setTemplateDropdownList([...resp])
                const filterData = resp.map((obj) => {
                    const { id, flowId, dltTemplateId, teleproject, templateText, title, active } = obj
                    return {
                        id,
                        flowId,
                        dltTemplateId,
                        teleproject,
                        templateText,
                        title,
                        active,
                        isEdited: false
                    }
                })
                setTemplates([...filterData])
            }
        }).catch(err => {
            console.error(err.response)
        })
    }

    useEffect(() => {
        if (templateLatestCount > 0)
            getSmsTemplatesList()
    }, [templateLatestCount])

    const reArrangeOutcomeTemplate = (sms) => {
        if (sms) {
            const tempArray = []
            for (const property in sms) {
                tempArray.push({
                    outcome: property,
                    templateId: sms[property]
                })
            }
            setOutcomeTemplate(tempArray)
        }
    }

    const fetchTemplateTitle = (id) => {
        const template = templates.find(obj => {
            if (obj.id == id)
                return obj.title
        })
        return template && template.title ? TruncateString(template.title, 15) : 'Choose Template'
    }

    const outcomeDropdownListClass = (option, selOption) => {
        const filterSelected = outcomeTemplate.map(item => {
            if (item.outcome)
                return item.outcome
        }).filter(Boolean)

        if (option == selOption)
            return `option-selected`

        if (filterSelected && filterSelected.length > 0) {
            const outcomeAlreadySelected = filterSelected.includes(option)
            return outcomeAlreadySelected ? `disable-option` : ''
        }
        else
            return ``
    }

    const templateDropdownListClass = (option, selOption) => {
        if (option == selOption)
            return `option-selected`
        else
            return ``
    }

    const addOutcomeClass = () => {
        const noOfOutcomes = formData.outcomes.length
        const noOfOutComeTemp = outcomeTemplate.length
        if (noOfOutComeTemp >= noOfOutcomes)
            return `add-cta flex-end disappear`
        else
            return `add-cta flex-end`
    }

    const handleBack = () => props.history.push(`/project-qc/${formData.id}`)

    const addTemplate = () => {
        let newTemplateCount = [...templates, dummyTemplate]
        setTemplates(newTemplateCount)
        setCheckIfLatest(true)
    }
    const addOutcomeTemplate = () => {
        let outcomeTemplateCount = [...outcomeTemplate, dummyoutcomeTemplateLink]
        setOutcomeTemplate(outcomeTemplateCount)
    }

    const removeTemplate = (index) => {
        let copyTemplates = [...templates]
        const indexToRemoved = templates.findIndex((i, idx) => idx == index)
        copyTemplates.splice(indexToRemoved, 1)
        setTemplates(copyTemplates)
        setCheckIfLatest(true)
    }

    const getFormData = (key, value, idx) => {
        setCheckIfLatest(false)
        const tmpArray = [...templates]
        let obj = tmpArray[idx]
        obj.isEdited = true
        obj = {
            ...tmpArray[idx],
            [key]: value
        }
        tmpArray[idx] = obj
        setTemplates(tmpArray)
    }

    const saveTemplate = (idx) => {
        setCheckIfLatest(false)
        let extractTemplate = templates[idx]
        extractTemplate.isSaving = true;
        const { id, active, dltTemplateId, flowId, teleproject, templateText, title } = extractTemplate
        const templateBody = {
            id,
            active,
            dltTemplateId,
            flowId,
            teleproject,
            templateText,
            title
        }

        //if id present template is created else it needs to be created
        const isNewTemplate = templateBody.id ? false : true

        if (isNewTemplate) {
            delete templateBody.id
            templateBody.dltTemplateId = `DLT${dltTemplateId}`
            ApiActions.createSmsTemplate(templateBody).then(resp => {
                setTemplateLatestCount(templateLatestCount + 1)
                toast("SMS template created");
            }).catch(err => {
                console.error(err.response)
                extractTemplate.isSaving = false;
                extractTemplate.errorMsg = err.response.data.msg ? err.response.data.msg : 'Error'
            })
        } else {
            ApiActions.updateSmsTemplate(templateBody.id, templateBody).then(resp => {
                setTemplateLatestCount(templateLatestCount + 1)
                toast("SMS template updated");
            }).catch(err => {
                console.error(err.response)
                const r = templates.map((item, index) => {
                    if (index == idx) {
                        return {
                            ...item,
                            isSaving: false,
                            errorMsg: err.response.data.msg ? err.response.data.msg : 'Error'
                        }
                    } else
                        return item
                })
                setTemplates([...r])
            })
        }
    }

    const selectOutcome = (value, idx) => {
        const tmpArray = [...outcomeTemplate]
        let obj = outcomeTemplate[idx]
        obj.outcome = value.title
        tmpArray[idx] = obj
        setOutcomeTemplate(tmpArray)
    }

    const selectTemplate = (value, idx) => {
        const tmpArray = [...outcomeTemplate]
        let obj = outcomeTemplate[idx]
        obj.templateId = value.id
        tmpArray[idx] = obj
        setOutcomeTemplate(tmpArray)
    }

    const removeOutcomeTempLink = (index) => {
        let copyOutcomeTemplates = [...outcomeTemplate]
        const indexToRemoved = outcomeTemplate.findIndex((i, idx) => idx == index)
        copyOutcomeTemplates.splice(indexToRemoved, 1)
        setOutcomeTemplate(copyOutcomeTemplates)
    }

    const addOutcomeToTemplate = (json) => {
        const data = {
            teleproject: props.match.params.id,
            templateData: json
        }
        ApiActions.linkOutcomeToTemplate(data).then(resp => {
            if (resp.data.sms) {
                let copyFormData = { ...formData }
                copyFormData.sms = resp.data.sms
                setFormData(copyFormData)
                toast("Outcome linked to SMS template");
                reArrangeOutcomeTemplate(resp.data.whatsapp)
            }
        }).catch(err => {
            console.error(err.response)
        })
    }

    const outcomeTemplVal = () => {
        const inValid = outcomeTemplate.some(obj => {
            if (!obj.outcome || !obj.templateId)
                return true
            else
                return false
        })
        return inValid
    }

    const showSMSTestingModal = (item) => {
        setCurrentSmsData(item)
        setShowSmsTestUi(true)
    }

    const renderSMSTest = () => {
        let data = {
            templateId: currentSmsData.id,
            mobile: ''
        }

        const testSms = () => {
            let result = {
                type: '',
                msg: ''
            }

            ApiActions.testSmsTemplate(currentSmsData.data).then(resp => {
                if (resp.data && resp.data.type && resp.data.type == 'success') {
                    result = {
                        type: 'success',
                        msg: 'SMS sent successfully'
                    }
                    const updatedData = { ...currentSmsData, result }
                    setCurrentSmsData(updatedData)
                } else {
                    result = {
                        type: 'failed',
                        msg: 'SMS testing failed. Please check if the project has leads in it'
                    }
                    const updatedData = { ...currentSmsData, result }
                    setCurrentSmsData(updatedData)
                }
            }).catch(err => {
                console.error(err.response)
                result = {
                    type: 'failed',
                    msg: 'SMS testing failed. Please check if the project has leads in it'
                }
                const updatedData = { ...currentSmsData, result }
                setCurrentSmsData(updatedData)
            })
        }

        const isMobileNumberAdded = () => {
            return currentSmsData.data && currentSmsData.data.mobile && currentSmsData.data.mobile.length == 10 ? false : true
        }
        const { result } = currentSmsData
        return <FrappModal
            className="custom-modal modal-xs"
            onCloseButton={true}
            bsSize={'sm'}
            show={showSmsTestUi}
            closeButtonClicked={() => {
                setShowSmsTestUi(false)
                setCurrentSmsData({})
            }}
        >
            <div className="heading">Test SMS</div>
            <div className='test-sms'>
                <div className='message-preview'>
                    <p>Title: {currentSmsData.title}</p>
                    <p>Message: {currentSmsData.templateText}</p>
                </div>
                <FormGroup>
                    <ControlLabel>Enter mobile number</ControlLabel>
                    <FormControl
                        name="mobile-number"
                        onChange={(e) => {
                            data.mobile = e.target.value
                            const updatedData = { ...currentSmsData, data }
                            setCurrentSmsData(updatedData)
                        }}
                        placeholder="Enter mobile number"
                    />
                </FormGroup>

                <div className='modal-btn-footer'>
                    <FrappButton className="submit" id="new-btn"
                        handler={() => testSms()}
                        disabled={isMobileNumberAdded()}>
                        SEND
            </FrappButton>
                </div>

                {result && result.type == 'failed' ? <div className='error-result'>{result.msg}</div> : null}
                {result && result.type == 'success' ? <div className='success-result'>{result.msg}</div> : null}
            </div>
        </FrappModal>
    }

    if (!loading) {
        return (
                <div>
                    <div className='templates_wrapper'>
                        {
                            templates.map((item, idx) => <SMSTemplateForm removeTemplate={removeTemplate}
                                getFormData={getFormData} idx={idx} item={item}
                                saveTemplate={saveTemplate} showSMSTestingModal={showSMSTestingModal}
                                addTemplate={addTemplate}
                                templatesCount={templates && templates.length > 0 ? templates.length - 1 : 0}
                                outcomeTemplate={outcomeTemplate}
                                addOutcomeToTemplate={addOutcomeToTemplate}
                                projectData={formData} />)
                        }
                        {checkIfLatest ? <AlwaysScrollToBottom /> : null}
                    </div>

                <div className="btn-wrapper">
                    <FrappButton type="submit" className="submit"
                        handler={() => props.history.push({
                            pathname: `/projects`,
                        })}
                    >Finish</FrappButton>
                    <FrappButton className="submit" id="new-btn-2"
                        handler={() => props.history.push({
                            pathname: `/projects`,
                        })}
                    > Skip</FrappButton>
                </div>
                <ToastContainer draggable={true} />
                {showSmsTestUi ? renderSMSTest() : null}
            </div>
        )
    } else
        return (
            <div className="loader-main" id="loader-size">
                <img src={require("../../assets/images/loadernew.gif")} />
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile
            <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
        )
}

export default SmsTemplate
