import React, { Component } from "react";
import ApiActions from "../../actions/ApiActions";
import MaterialTable, { MTablePagination } from "material-table";
import FrappModal from "../utilities/FrappModal";
import { ToastContainer, toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import "react-tabs/style/react-tabs.css";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FrappButton from "../utilities/FrappButton";
import Dropdown from 'react-dropdown';
import getOrdinal from "../../helper/Ordinal";
import Checkbox from '@material-ui/core/Checkbox';
import capitalise from "../../helper/Capitalise";
import { Fragment } from "react";
import ProjectTitle from "../common/ProjectTitle"
import CustomerInfo from '../utilities/CustomerInfo'
import CustomTab from '../utilities/CustomTab'
import CallDisconnectedBy from "../../helper/CallDisconnectedBy";
import moment from "moment";
export default class CallerIQC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			brand: {},

			// IQC
			iqcFlaggedCallerList: [],
			iqcRecordings: [],
			activeIqcRecording: {
				recordingUrl: "",
				outcomeName: "",
			},
			fadeDiv: false,
			iqcPrevAttemptCalls: [],
			iqcNumber: 0,
			index: 0,
			iqcParameters: {},
			scriptCheckResponse: {},
			checkboxFeedbackData: [],
			checkBanReasons: [],
			checkRetrainReasons: [],
			checkboxCallerAuditData: [],
			callerCheckResponse: {},
			activeRowData: {},
			showDiv: {
				'tenCallsDiv': true,
				'overallParameterDiv': false,
				'finalSubmitDiv': false
			},
			finalManualAuditBody: {
				teleproject: "",
				user: "",
				reason: "",
				action: "",
				actionBy: "agent"
			},
			loadingTenCalls: false,
			teletaskCallHistory: [],
			checkboxCallerAuditData: [],
			callFeedbackState: "",
			callerFeedbackState: "",
			activeTab: {
                label: this.tabsForStage[0].label,
                value: this.tabsForStage[0].value
            },
		};
	}

	fetchFlaggedCallers = () => {

		// Fetch IQC Flagged Callers
		ApiActions.getIqcFlaggedCallers(this.props.match.params.id).then(
			(resp) => {
				this.setState({ iqcFlaggedCallerList: resp, isLoading: false });
			},
		);
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ mht: this.props.location.state.mht });

		if (this._isMounted) {
			if (this.props.match.params.id) {


				this.fetchFlaggedCallers()

                // Get Teleproject Deatails
				ApiActions.getTeleProject(this.props.match.params.id).then(
					(teleprojectResponse) => {
						this.setState({ teleproject: teleprojectResponse });

						// Get brand logo
						const brandId = teleprojectResponse.brand;
						ApiActions.getBrandName(brandId).then((resp) => {
							const { name, asseturl } = resp[0];
							this.setState({ brand: { name, asseturl } });
						});
					},
				);

                // Fetching IQC Parameters from system
                // softSkillsChecks {}
                // scriptChecks []
				ApiActions.getIqcParameters().then((resp) => {
					this.setState({
						iqcParameters: resp[0],
					});
				});

                // Api for fetching the reason for caller level action at final stage of Popup
				ApiActions.getQAFeedbackReason()
					.then(response => {
						this.setState({
							checkboxFeedbackData: response.callFeedbacks.map((item) => {
								return { "label": item, "checked": false }
							})
						})
						let banReasons = response.banReasons.map((item) => {
							return { "label":item, "checked":false }
						})
						let retrainReasons = response.retrainReasons.map((item) => {
							return { "label":item, "checked":false }
						})
						this.setState({
							checkboxCallerAuditData: [...banReasons, ...retrainReasons]
						})
					})
			}
			this.setState(this.props.history.location.state);
		}
	}

	tabsForStage = [
        {
            label: 'Current Attempt',
            value: 'current_attempt'
        },
        {
            label: 'Previous Attempts',
            value: 'previous_attempts'
        }
    ]

	fetchApiCallsBasedOnStage = (activeRow, activeTab) => {
		if (!activeRow && !activeRow.user) {
			return
		}
		let data = {
			teleproject: this.state.teleproject.id,
			user: activeRow.user
		}
        if(activeTab.value == 'current_attempt'){
			this.setState({
				loadingTenCalls: true
			})
			this.callerIQCModal.toggle(true);
			data['previous'] = false
			ApiActions.getIqcFlaggedCalls(data)
			.then((resp) => {
				this.setState({
					iqcRecordings: resp,
				});
				this.setState({
					activeRowData: activeRow
				})
				this.setState({
					loadingTenCalls: false
				})
			}).then(() => {
				if(this.state.iqcRecordings.length > 0) {
					this.getCallHistory(this.state.iqcRecordings[0])
				}
			}).catch((err) => {
				console.log(err)
				toast("ERROR")
			})
        }else if(activeTab.value == 'previous_attempts'){
			data['previous'] = true
			ApiActions.getIqcFlaggedCalls(data)
			.then((resp) => {
				this.setState({
					iqcPrevAttemptCalls: resp
				});
			}).catch((err) => {
				console.log(err)
				toast("ERROR")
			})
        }
    }
    // Main render of the entire page. All components is in it.
	render() {
		let { iqcFlaggedCallerList, mht, teleproject } = this.state;
		let { isLoading, brand } = this.state;

		const goBack = () => {
            this.props.history.push(`/audit/qmf/audit-project/${this.props.match.params.id}`,
            {teleprojectAudit: this.props.location.state.teleprojectAudit})
        }

		if (!isLoading)
			return (
				<div className="call-audits">
					{teleproject ? <ProjectTitle title={teleproject.title} goBack={goBack} /> : null}
					<div className="_header">
						{brand ? (
							<div className="brand_details">
								<img src={brand.asseturl} className="brand_logo" />
								<h2 className="brand_name">{brand.name}</h2>
							</div>
						) : null}
						<div className="mht_caller_audit">
							Min. Handling Time for winning outcome: &nbsp;
							{Object.keys(mht).map((obj) => {
								return (
									<div className="data">
										{" "}
										{obj}:&nbsp;{mht[obj]}
									</div>
								);
							})}
						</div>
					</div>
					{iqcFlaggedCallerList ? this.renderCallerIQCTable() : null}
					{this.renderIqcCallerPopup()}
					<ToastContainer draggable={true} />
				</div>
			);
		else
			return (
				<div className="loader-main" id="loader-size">
					<img src={require("../../assets/images/loadernew.gif")} />
					<div className="text">
						We'll be right back. Here: listen to some awesome songs meanwhile
						<a
							href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0"
							target="_blank" rel="noopener noreferrer">
							Take me
						</a>
					</div>
				</div>
			);
	}

	renderIqcCallerPopup = () => {
		let {
			iqcRecordings,
			showDiv,
			activeRowData,
			loadingTenCalls
		} = this.state;
		

		const callerIQC = () => {
				return <div>
				{loadingTenCalls
					? <div>Loading data...</div>
					: <div className="caller-iqc">
						{/* Remember, console, debugger, dummy */}
						{/* { false ? null : this.renderFinalCheckDiv() } */}
					
						{
							iqcRecordings.length > 0 && showDiv.tenCallsDiv
							? this.renderTenCalls()
							: iqcRecordings.length < 1 && !showDiv.finalSubmitDiv
								? this.renderCallerCheckDiv()
								: null
						}

						{/* {this.renderFinalCheckDiv()} */}
						{/* {!showDiv.overallParameterDiv && iqcRecordings.length < 0 ? this.renderCallerCheckDiv() : null} */}
						{
							!showDiv.overallParameterDiv && !showDiv.tenCallsDiv && showDiv.finalSubmitDiv
							? this.renderFinalCheckDiv()
							: showDiv.finalSubmitDiv && !(showDiv.tenCallsDiv && showDiv.overallParameterDiv)
								? <div> Refresh page </div>
								: null }
					</div>
			}
			</div>
		}

		const callerPreviousAttempts = () => {
			let {
				iqcPrevAttemptCalls
			} = this.state;
			// Render Logic for the audio grades/scriptchecks
			return (
					<div className='iqc-attempt-body'>
						<div className="iqc-attempt-container">
							<div className="note">
								<p>Note: Here we show calls made by caller in their pervious attempts</p>
							</div>
							{
								iqcPrevAttemptCalls.length > 0 ?
								<Fragment>
									<div className="attempts-table">
									<div className="attempt-row heading-wrapper">
										<h2 className="heading">Outcome</h2>
										<h2 className="heading">Recording</h2>
										<h2 className="heading">Result</h2>
									</div>
									{
										iqcPrevAttemptCalls && iqcPrevAttemptCalls.map((item, i) => {
											return (
												<div className="attempt-row" key={`iqc-attempt-${i}`}>
													<p>{item.outcome.title}</p>
													<audio controls src={item.recordingUrl} />
													<p>{item.qc.iqc.pass === 1 ? 'Pass' : 'Fail'}</p>
												</div>
											)
										})
									}
									</div>
								</Fragment>
								:
								<div className="heading-wrapper" style={{"justifyContent": "center", "alignItems":"center","height" : "20vh"}}>
									<h1 className="heading"><span role="img" aria-label="warning">⚠️</span> Previous Attempts Not Present</h1>
								</div>
							}
						</div>
					</div>
			)
		}

		const setActiveTabFn = (data, activeRow) => {
            this.setState({ activeTab: { label: data.label, value: data.value }})
            this.fetchApiCallsBasedOnStage(activeRow, data)
        }

		const renderContent = (activeTab) => {
            switch (activeTab) {
                case 'current_attempt':
                    return callerIQC()
                case 'previous_attempts':
                    return callerPreviousAttempts()
                default:
                    break;
            }
        }
		// Render Logic for the audio grades/scriptchecks
		return (
			<FrappModal
				onCloseButton={true}
				className="caller-iqc-modal"
				ref={(c) => (this.callerIQCModal = c)}
				closeModal={() => {
					this.callerIQCModal.toggle(false)
					this.setState({
                        activeTab: {
                            label: this.tabsForStage[0].label,
                            value: this.tabsForStage[0].value
                        }
                    })
					this.setState({ iqcRecordings: [], iqcPrevAttemptCalls: []})
				}}
				>
				<div className="caller-iqc">
                <CustomTab tabs={this.tabsForStage} setActiveTabFn={(data) => setActiveTabFn(data, activeRowData)} className={'navy-blue'}/>
				<div className="heading-wrapper">
                <div className="heading"> {activeRowData && activeRowData.user } &bull; {activeRowData && activeRowData.name } &bull; {activeRowData && activeRowData.mobile } </div>
				</div>
                {renderContent(this.state.activeTab.value)}
                </div>
			</FrappModal>
		);
	}

	renderCallerCheckDiv = () => {

		let {
			iqcParameters,
			activeRowData, callerCheckResponse
		} = this.state;

		const handleChange = (data, title) => {
			let key = title
			let temp = {};
			temp[key] = data.value;

			this.setState({
				callerCheckResponse: {
					...callerCheckResponse,
					...temp
				}
			})
		};

		const saveCallerGrades = () => {
			this.submitBtnOverAllParameters.startLoading()
			let dataToSend = {
				"teleapplication": activeRowData.id,
				"softSkillsGrade": callerCheckResponse
			}

			// API Simulation
			// this.setState({
			// 	showDiv: {
			// 		finalSubmitDiv: true,
			// 		tenCallsDiv: false,
			// 		overallParameterDiv: false
			// 	}
			// })

			// Object.keys(myObj).length;
			if (Object.keys(callerCheckResponse).length === Object.keys(iqcParameters.softSkillsChecks).length) {
				ApiActions.postIqcCallerCheck(dataToSend).then((response) => {
					if (response.status === 200) {
						this.submitBtnOverAllParameters.stopLoading()
						this.setState({
							showDiv: {
								finalSubmitDiv: true,
								tenCallsDiv: false,
								overallParameterDiv: false
							},
							callerCheckResponse: response.data
						})
					}
				});
			} else {
				this.submitBtnOverAllParameters.stopLoading()
				toast("Enter all fields")
			}
		}

		return (
			<div className="audio-header">
				<h4>Overall parameters</h4>
				{/* {
					iqcRecordings.length < 1 && !showDiv.finalSubmitDiv
					? <p style={{color: "red"}}>10 calls are already audited. That why you are on this paramter page.</p>
					: null
				} */}
				{
					iqcParameters && iqcParameters.softSkillsChecks
					? (<form onSubmit={handleChange}>
							<div className="form-container">
								<h5>Soft skills</h5>
								<table>
									<tbody>
										{this.state.iqcParameters.softSkillsChecks.map((item, idx) => {
											return (
												<tr className="parameter-row" key={idx}>
													<td className="td_text">{item.title}</td>
													<td>
														<Dropdown
															options={[...Array(item.maxGrade + 1).keys()].slice(1)}
															onChange={(a) => handleChange(a, item.title)}
															// value="pass" 
															placeholder="Select"
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							<div className="bottom-div">
								<FrappButton
									// type="submit"
									handler={saveCallerGrades}
									className="iqc-btn"
									ref={(c) => this.submitBtnOverAllParameters = c}
								> Save & Next (Overall) </FrappButton>
							</div>
						</form>)
					: null
				}
			</div>
		)
	}

	renderFinalCheckDiv = () => {
		let {
			index,
			teleproject,
			iqcRecordings,
			showDiv,
			activeRowData,
			callerCheckResponse,
			finalManualAuditBody,
			checkboxFeedbackData,
			checkboxCallerAuditData,
			callerFeedbackState
		} = this.state;

		let options = [
			{ label: "Pass", value: "qcpass" },
			{ label: "Fail", value: "training" },
			{ label: "Additional IQC ", value: "additionalIQC" }
		]
		const handleSelect = (data) => {
			let _reason = "";
			if (data.value === "qcpass") {
				_reason = 'Cleared in IQC'
			} else if (data.value === "training") {
				_reason = 'Marked Retrain for IQC by agent'
			} else {
				_reason = 'Marked for additionalIQC by agent'
			}
			this.setState({
				finalManualAuditBody: {
					...finalManualAuditBody,
					action: data.value,
					reason: _reason
				}
			})
		}

		const handleChangeCallerFeedback = (event) => {
			// setChecked(event.target.checked);
			// this.setState({checked: event.target.checked})
			this.setState({
				checkboxCallerAuditData: checkboxCallerAuditData.map((item) => {
					if (item.label === event.target.name) {
						item.checked = event.target.checked
						return item
					}
					return item
				})
			})
		};

		const renderCallerFeedback = (checked, label) => {
			return (
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleChangeCallerFeedback}
							name={label}
							color="primary"
						/>
					}
					label={<span style={{ fontSize: '13px', marginBottom: '0px !important' }}>{label}</span>}
				/>
			)
		}

		const callerFeedbackInput = (event) => {
			this.setState({
				callerFeedbackState: event.target.value
			})
		}

		const finalSubmit = () => {
			this.finalSubmitBtn.startLoading()

			let feedbacksCallerAudit = []
			checkboxCallerAuditData.forEach((item) => {
				if (item.checked === true) {
					feedbacksCallerAudit = [...feedbacksCallerAudit, item.label]
				}
			})
			
			let feedbacks = []
				if(callerFeedbackState.trim() === "") {
					feedbacks = [ ...feedbacksCallerAudit ]
				}else{
					feedbacks = [ ...feedbacksCallerAudit, callerFeedbackState.trim() ]
				}
			
			let dataToSend = {}
			if(feedbacks.length >= 1) {
				dataToSend = {
					...finalManualAuditBody,
					teleproject: teleproject.id,
					user: activeRowData.user,
					iqcfeedback: feedbacks.filter(i => i).join(', ')
				}
			}else{
				dataToSend = {
					...finalManualAuditBody,
					teleproject: teleproject.id,
					user: activeRowData.user
				}
			}
			

			if (dataToSend.reason === "") {
				toast("Please select the manual verdict")
			} else {
				ApiActions.postFinalAuditIQC(dataToSend).then((response) => {
					if (response.status === 200) {
						this.finalSubmitBtn.stopLoading()
					setTimeout(() => {
							this.fetchFlaggedCallers()
							this.callerIQCModal.toggle(false);
							this.setState({
								showDiv: {
									'tenCallsDiv': true,
									'overallParameterDiv': false,
									'finalSubmitDiv': false
								},
								finalManualAuditBody: {
									teleproject: "",
									user: "",
									reason: "",
									action: "",
									actionBy: "agent"
								},
								index: 0,
								callerCheckResponse: {},
								callerFeedbackState: ""
							})
						}, 2000);
					} else if (response.status === 400) {
						toast("Error")
					}
				}).catch((err) => {
					console.log(err)
					toast("ERROR")
				})		
			}
		}

		let resultColor = callerCheckResponse.iqcResult === 'failed' ? 'red' : callerCheckResponse.iqcResult === 'pass' ? 'green' : 'gray'
		return (
			<div className="final__screen">
				<h4>10 IQC Completed</h4>

				<div className="stats__container">
					<div className="stats__div">
						<div className="stats__row">
							<h5 className="font-size-16" >System decided</h5>
							<h5 className={resultColor}>{callerCheckResponse.iqcResult && capitalise(callerCheckResponse.iqcResult)}</h5>
						</div>
						<hr />
						<div className="stats__row">
							<h5 className="font-size-16" >Manual Verdict</h5>
							<div style={{ width: "140px", marginTop: "8px" }}>
								<Dropdown options={options}
									onChange={handleSelect}
									// value="pass" 
									placeholder="Select" />
							</div>
						</div>
					</div>
				</div>

				<div className="feedback__div">
					<h4>Additional Feedback</h4>
					{checkboxCallerAuditData.map((item) => {
						return renderCallerFeedback(item.checked, item.label, item.id);
					})}
					{/* <textarea 
						className='feedback__textarea' 
						onChange={(e) => {
							this.setState({
								finalManualAuditBody: {
									...finalManualAuditBody,
									iqcfeedback: e.target.value
								}
							})
						}}
					/> */}
				</div>

				<div className="bottom-div">
					<input type="text" placeholder="Custom feedback" className="custom-input" onChange={callerFeedbackInput} />
					{/* <button>Final Submit</button> */}
					<FrappButton
						handler={finalSubmit}
						className="iqc-btn"
						ref={(c) => this.finalSubmitBtn = c}
					> Final Submit </FrappButton>
				</div>
			</div>
		)
	}

    // 10 call's div/wrapper in popup
	renderTenCalls = () => {
		let {
			index,
			teleproject,
			iqcParameters,
			iqcRecordings,
			activeRowData,
			scriptCheckResponse,
			checkboxFeedbackData,
			showDiv,
			teletaskCallHistory,
			callFeedbackState
		} = this.state;

		const onRadioGroupChange = (e) => {
			let key = e.target.name;
			let value = e.target.value;
			let temp = {};
			temp[key] = value;
			this.setState({
				scriptCheckResponse: {
					...scriptCheckResponse,
					...temp,
				},
			})
		}

        // Logic to change the next Audio in popup and post api call for submitting audio grades i.e ScriptChecks
		const goToNext = () => {
			// this.submitBtn.startLoading()
			if (Object.keys(iqcParameters.scriptChecks).length === Object.keys(scriptCheckResponse).length) {
				let newIndex = (this.state.index + 1) % (iqcRecordings.length + 1);
				this.setState({ index: newIndex });

				// let dataToSendQCFeedback = {
				// 	feedbacks: checkboxFeedbackData.map((item) => {
				// 		if (item.checked === true) {
				// 			return item.label
				// 		}
				// 	}).filter(i => i)
				// }

				let dataToSendQCFeedback = []
				checkboxFeedbackData.forEach((item) => {
					if (item.checked === true) {
						dataToSendQCFeedback = [...dataToSendQCFeedback, item.label]
					}
				})

				let feedbacks = []
				if(callFeedbackState.trim() === "") {
					feedbacks = [ ...dataToSendQCFeedback ]
				}else{
					feedbacks = [ ...dataToSendQCFeedback, callFeedbackState.trim() ]
				}
				
				let dataToSend = {}
				if(feedbacks.length >= 1) {
					dataToSend = {
						teletaskhistory: this.state.iqcRecordings[newIndex - 1].teletaskhistory,
						teleapplication: activeRowData.id,
						gradesheet: this.state.scriptCheckResponse,
						feedbacks: feedbacks
					}
				}else{
					dataToSend = {
						teletaskhistory: this.state.iqcRecordings[newIndex - 1].teletaskhistory,
						teleapplication: activeRowData.id,
						gradesheet: this.state.scriptCheckResponse
					}
				}

				this.setState({ fadeDiv: true })

				ApiActions.postIqcScriptCheck(dataToSend).then((response) => {
					if (response.status === 200) {
						// debugger
						this.setState({
							scriptCheckResponse: {},
							// teletaskCallHistory: [],
							fadeDiv: false,
							callFeedbackState: ""
						})
						this.setState({
							checkboxFeedbackData: checkboxFeedbackData.map((feedbackObj) => {
								return { "label": feedbackObj.label, "checked": false }
							})
						})
					}
				})

				


				//API Simulation	
				// setTimeout(() => {
				// 	this.setState({callFeedbackState: ""})
				// 	this.setState({ scriptCheckResponse: {} })
				// 	this.setState({teletaskCallHistory: []})
				// 	this.setState({ fadeDiv: false })
				// }, 1000);

				if(!(newIndex === iqcRecordings.length)) {
					this.getCallHistory(iqcRecordings[newIndex])
				}
				
				if (newIndex === iqcRecordings.length) {
					this.setState({
						iqcRecordings: [],
					})
					this.setState({
						showDiv: {
							finalSubmitDiv: false,
							tenCallsDiv: false,
							overallParameterDiv: true,
							checkboxFeedbackData: ""
						}
					})
				}


			} else {
				// this.submitBtn.stopLoading()
				toast("select all fields")
			}
		}

		const handleChangeCheckboxFeedback = (event) => {
			// setChecked(event.target.checked);
			// this.setState({checked: event.target.checked})
			this.setState({
				checkboxFeedbackData: checkboxFeedbackData.map((item) => {
					if (item.label === event.target.name) {
						item.checked = event.target.checked
						return item
					}
					return item
				})
			})
		};

		const renderCheckboxFeedback = (checked, label) => {
			return (
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleChangeCheckboxFeedback}
							name={label}
							color="primary"
						/>
					}
					label={<span style={{ fontSize: '13px', marginBottom: '0px !important' }}>{label}</span>}
				/>
			)
		}

		const callFeedbackInput = (event) => {
			this.setState({
				callFeedbackState: event.target.value
			})
		}

		const item = iqcRecordings[this.state.index];
		let dataCollection = item && ((item.outcome && item.outcome.responses) || item.responses)
													?   item.responses
															?   item.responses
															:   item.outcome && item.outcome.responses
													:   false;
		
		return (iqcRecordings && iqcRecordings.length > 0 ? (
			<Fragment>
				{
					this.state.fadeDiv === true
					// true
					? (
						<div className="loading-div">
							Fetching Data from Server 📡
						</div>
					) : (
						<div className={`audio-header ${this.state.fadeDiv ? 'fade' : ''}`}>

							<div className="user-wrappper">
								<div className='audio-container'>
								{
									this.state.teletaskCallHistory.length >= 0
									? (
										this.state.teletaskCallHistory.map((obj, index) => {
											let findDisconnectedBy = iqcRecordings.find(record => record.teletaskhistory == obj._id)
											let disconnectedBy = findDisconnectedBy ? findDisconnectedBy.disconnectedBy : ''
											return (
												<Fragment>
													<p>
													Calls {index + 1}: {
														obj.outcomeDraft ?
														'Outcome pending'
														:
														obj.outcome ? obj.outcome.title : obj.callstatus
													}
													</p>
													<span className='disconnect-div'>Disconnected by: {CallDisconnectedBy(disconnectedBy ? disconnectedBy : '')}</span>
													{
															obj.recordingUrl ? <audio controls src={obj.recordingUrl}></audio> : null
													}
												</Fragment>
											)
										})
									)
									: (
										<div>No Audio Found </div>
									)
								}
								</div>
								<Fragment>
									<CustomerInfo info={item}/>
									{/* <div className="user-infos">
										<p id="heading" > ☆ Customer Info</p >
										<div className="user-infos-div">
											{
												item && item.data 
												? Object.entries(item.data).map(
													([key, value]) => (
														<div>
															{`${capitalise(key)}: ${value}`}
														</div>
													)
												): <div> ⚠️ No Data  </div>
											}
											<div>
			
											</div>
										</div>
									</div> */}
								</Fragment>
							</div>
			
							{/* <hr /> */}
							<div className="data-collection-collapseable">
								{
									dataCollection && dataCollection.length > 0
									? <Accordion>
											<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1a-content"
													id="panel1a-header"
											>
													<Typography id="heading">Data Collections</Typography>
											</AccordionSummary>
											<AccordionDetails>
													<div className="questionContainer">
														{
															dataCollection.map((ques_answ, i) => {
																return (
																	<div className="quesAnsDiv">
																			<p className="question">{`${i+1}). ${ques_answ.question}`}</p>
																			<p className="answer">{`Ans. ${ques_answ.answer}`}</p>
																	</div>
																)
															}) 
														}
															
													</div>
											</AccordionDetails>
										</Accordion>
									: <div><p id="heading" >Data Collection: <span className="font-weight-light">⚠️  No Data  </span> </p> </div>
								}
			
							</div>
			
							<div className="iqc-form-wrapper">
								<div className="form-container">
									<p id="heading">Script related checks</p>
									<table>
										<tbody>
											{this.state.iqcParameters.scriptChecks.map((item, idx) => {
												return (
													<tr className="parameter-row" key={idx}>
														<td className="td_text">{item}</td>
														<td>
															<FormControl component="fieldset">
																<RadioGroup
																	row
																	aria-label="gender"
																	name="gender1"
																	onChange={onRadioGroupChange}>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio color="primary" />}
																		label="Yes"
																		name={item}
																		checked={this.state.scriptCheckResponse[item] === "Yes"}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio color="primary" />}
																		label="No"
																		name={item}
																		checked={this.state.scriptCheckResponse[item] === "No"}
																	/>
																	<FormControlLabel
																		value="NA"
																		control={<Radio color="primary" />}
																		label="NA"
																		name={item}
																		checked={this.state.scriptCheckResponse[item] === "NA"}
																	/>
																</RadioGroup>
															</FormControl>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
								<div className="feedback-form">
									<p id="heading">Feedbacks</p>
								{checkboxFeedbackData.map((item) => {
									return renderCheckboxFeedback(item.checked, item.label, item.id);
								})}
								<input type="text" placeholder="Custom feedback" className="custom-input" onChange={callFeedbackInput} />
								</div>
							</div> 
							
							<div>
								<div className="bottom-div">
									<div className="page-nav">{index + 1} / {iqcRecordings.length}</div>
									<FrappButton handler={goToNext} className="iqc-btn" ref={(c) => this.submitBtn = c} > Save & Next </FrappButton>
								</div>
							</div>
						</div>
					)
				}
			</Fragment>

	
		) : null )

	}

	// getTeletaskCallHistory
	getCallHistory = (item) => {
		ApiActions.getTeletaskCallHistory({
			teletaskHistory: item.teletaskhistory
		}).then((response) => {
				this.setState({
						teletaskCallHistory: response
				})
		})
	}

	// show IQC Popup
	showAuditPopup = (rowData) => {
		this.setState({
			loadingTenCalls: true
		})
		this.setState({activeTab: {
            label: this.tabsForStage[0].label,
            value: this.tabsForStage[0].value
        }})
		this.setState({ iqcPrevAttemptCalls: []})
		this.callerIQCModal.toggle(true);
		if (rowData && rowData.user) {
			ApiActions.getIqcFlaggedCalls({
				teleproject: this.state.teleproject.id,
				user: rowData.user,
			}).then((resp) => {
				this.setState({
					iqcRecordings: resp,
				});
				this.setState({
					activeRowData: rowData
				})
				this.setState({
					loadingTenCalls: false
				})	
			}).then(() => {
				if(this.state.iqcRecordings.length > 0) {
					this.getCallHistory(this.state.iqcRecordings[0])
				}
			})
		}
	}

	renderCallerIQCTable = () => {
		let { iqcFlaggedCallerList } = this.state;
		const cellStyle = {fontSize: '12px', color: 'black'}
        const headerStyle = {
            backgroundColor: '#E7FAF2',
            fontSize: '12px',
            fontWeight: 'bold',
            boxShadow: 'none'
        }
		return (
			<div style={{ maxWidth: "100%" }}>
				<MaterialTable
					localization={{
						pagination: {
							labelDisplayedRows: "{from}-{to} of {count}",
							labelRowsPerPage: "25",
						},
						toolbar: {
							nRowsSelected: "{0} row(s) selected",
						},
						header: {
							actions: "Actions",
						},
						body: {
							emptyDataSourceMessage: "No records to display",
							filterRow: {
								filterTooltip: "Filter",
							},
						},
					}}
					columns={[
						{
							title: "Caller phone",
							field: "mobile",
							render: (rowData) => (
								<div className="caller-phone">
									<img
										src={require("../../assets/images/Icons_user_info.svg")}
									/>{" "}
									{rowData.mobile}
								</div>
							),
							filtering: true,
							cellStyle: {
								...cellStyle,
								whiteSpace: "nowrap",
							},
							headerStyle: headerStyle,
						},
						{
							title: "Name",
							field: "name",

							filtering: true,
							cellStyle: {
								...cellStyle,
								whiteSpace: "nowrap",
							},
							headerStyle: headerStyle,
						},
						{
							title: "Total calls made",
							field: "totalCalls",
							cellStyle: {
								...cellStyle,
								whiteSpace: "nowrap",
							},
							headerStyle: headerStyle,
							filtering: false,
						},
						{
							title: "Calls completed",
							field: "",
							render: (rowData) => (
								rowData.qc && rowData.qc.count ? <div>
									{Object.keys(rowData.qc.count).reduce(
										(sum, key) => sum + parseFloat(rowData.qc.count[key] || 0),
										0,
									)}
								</div> : 'NA'
							),
							cellStyle: cellStyle,
							headerStyle: headerStyle,
							filtering: false,
						},
						{
							title: "IQC attempts",
							field: "",
							render: (rowData) => (
								<div>
									{rowData.iqcAttempts ? rowData.iqcAttempts + 1 : 1}
								</div>
							),
							cellStyle: cellStyle,
							headerStyle: headerStyle,
						},
						{
							title: "AIQC attempts",
							field: "",
							render: (rowData) => (
								<div className="caller-phone">
									{rowData.qc && rowData.qc.iqcHolds && rowData.qc.iqcHolds.length > 0 && rowData.qc.iqcHolds[0] && rowData.qc.iqcHolds[0].attempts ? rowData.qc.iqcHolds[0].attempts - 1 : 0}
								</div>
							),
							// render: (rowData) => <div>{rowData.qc.iqcHolds[0].attempts}</div>,
							cellStyle: cellStyle,
							headerStyle: headerStyle,
						},
						{
							title: "IQC Date",
							render: rowData => (
								rowData.qc && rowData.qc.iqcHolds && rowData.qc.iqcHolds.length > 0 &&
								rowData.qc.iqcHolds[0] && rowData.qc.iqcHolds[0].updatedAt ?
								<span>{moment(rowData.qc.iqcHolds[0].updatedAt).format("DD/MM/YYYY")}</span> 
								: 'NA'
							),
							cellStyle: cellStyle, 
							headerStyle: headerStyle,
							filtering: false,
							customSort: (a, b) => a.certifiedOn - b.certifiedOn
						},
						{
							title: "",
							field: "",
							render: (rowData) => (
								<div
									className="audit-arrow-caller"
									onClick={() => this.showAuditPopup(rowData)}>
									<img src={require("../../assets/images/Icons_right.svg")} />
								</div>
							),
							filtering: true,
							cellStyle: cellStyle,
							headerStyle: headerStyle,
						},
					]}
					data={iqcFlaggedCallerList}
					// components={{
					//     Pagination: props => (
					//         <div style={{ fontSize: "30px" }}>
					//             <MTablePagination rowsPerPage={50} {...props} />
					//         </div>
					//     )
					// }}
					// onChangePage={()=>this.fetchData()}
					options={{
						filtering: true,
						// grouping: true,
						exportButton: true,
						doubleHorizontalScroll: true,
						exportAllData: true,
						columnsButton: true,
						pageSize: 25,
						emptyRowsWhenPaging: false,
						pageSizeOptions: [25, 50, 75, 100],
						rowStyle: {
							fontSize: "16px",
						},
						searchFieldStyle: {
							boxShadow: "none",
							webkitboxShadow: "none",
						},
					}}
					title="Caller IQC"
				/>
			</div>
		);
	}
}
